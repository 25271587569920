import React from 'react';
import MetaTags from 'react-meta-tags';
const SuiteCompare = props => {
	const metaTagscontent = {
		title:'Unlock Your PDF Potential | Right PDF',
		desc:'The Portable Document Format, universally known as PDF, made its debut in 1993 and has been constantly evolving to meet latest demands for users from diverse backgrounds. '
	};
	const viewcontent =
		`<h1 id="p1">Introduction</h1>

<p>The Portable Document Format, universally known as PDF, made its debut in 1993 and has been constantly evolving to meet latest demands for users from diverse backgrounds. To date, PDF is one of the world&rsquo;s most popular formats with presence felt in governments and private sectors alike. However, while millions of businesses thrive on PDF as an integral part of workflow, its benefits still remain obscure to the average public.</p>

<p>&nbsp;</p>

<div id="p13">
<p><strong>PDF strengths</strong></p>

<ol>
	<li>
	<p>Strong compatibility</p>
	</li>
	<li>
	<p>Precise display &amp; printing</p>
	</li>
	<li>
	<p>Easy to secure</p>
	</li>
	<li>
	<p>Best for archival</p>
	</li>
	<li>
	<p>Read everywhere</p>
	</li>
	<li>
	<p>Smaller size</p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<p class="marginB100">The truth is you don&rsquo;t need to be an IT professional to understand the powers PDF can bring to your work or business. As a beginner, a few keywords that you should eternally associate with PDF are: ease, accuracy, and security. Imagine: your documents now stay the same on any device, can be searched, reviewed and organized with security in check&mdash;and the list goes on further. If millions of businesses around the globe are using PDF to their advantages, you should learn why and how they do it, too.</p>

<h1 id="p2">Who uses PDF?</h1>

<p class="marginB100">As early as 2000, the U.S. Federal Courts adopted PDF as their choice for archiving electronic document. Many people have seen PDF when downloading forms from the IRS or other organizations, such as US National Archives and the Australian government&rsquo;s SmartForm. Notably, the European Union also set PDF 1.7 and PDF/A as standard interchange formats for electronic transfer of documents. To this day, the U.S Federal Government remains the largest user of PDF technology.</p>

<h1 id="p3">Why PDF?</h1>

<p class="marginB100">In today&rsquo;s world, the omnipresence of electronic documents is the major factor that makes PDF the go-to-format. There could be hundreds or thousands of them circulating around the office, among collaborators, or over the Internet, and they all need to be opened with the correct application on the receiving end. Inevitably, problems emerge because users don&rsquo;t open it in an environment same as yours (e.g. an older version of software), resulting in more time and efforts to resolve differences; yet worst, certain people could possibly exploit unprotected documents and use the information for their purposes. To address the vulnerabilities stated, businesses choose PDF as the format to share and store electronic documents, saving considerable costs in the long run.</p>

<h1 id="p4">How do they use PDF?</h1>

<p>Here are 6 common challenges people face at work and how PDF helps to solve them.</p>

<p>&nbsp;</p>
<div class="box6Wrapper">
	<div class="box6">
	<p><strong>Security</strong></p>

	<p>You have no control over the document once it leaves your computer, company or corporate firewall.</p>

	<p><img alt="01_Security" src="${require(`../../assets/img/resources/Group 1337.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>Sharing</strong></p>

	<p>Large documents are difficult to share and the recipient might not have the right application to read them.</p>

	<p><img alt="02_Sharing" src="${require(`../../assets/img/resources/Group 1338.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>Portability</strong></p>

	<p>The user is unable to open the document or has troubles viewing it.</p>

	<p><img alt="03_Portability" src="${require(`../../assets/img/resources/Group 1339.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>Authentic</strong></p>

	<p>Display and layout errors often occur on the receiving end.</p>

	<p><img alt="04_Authentic" src="${require(`../../assets/img/resources/Group 1340.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>Standardization</strong></p>

	<p>There are too many files and too many different formats, making it hard to manage.</p>

	<p><img alt="05_Standardization" src="${require(`../../assets/img/resources/Group 1341.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>Review</strong></p>

	<p>Reviewing versions of documents takes too much time and resource.</p>

	<p><img alt="06_Review" src="${require(`../../assets/img/resources/Group 1342.svg`)}" /></p>
	</div>
</div>

<p>&nbsp;</p>

<p class="marginB100">Once turned into PDF, the look and feel are preserved on any device running any operating system in any language, be it Windows, macOS, Linux, UNIX, Android or iOS. Conversely, other formats might not be displayed correctly due to OS incompatibilities or missing a language pack. PDF is self-contained and reading is considered a standard capability on any computer, so you can rest assured that your documents will travel anywhere without unintended change. For example, you can use PDF/A, a subset designed specifically for archival purposes, to store your electronical documents.</p>

<h1 id="p5">Create &amp; Convert PDF</h1>

<p>It takes only a few mouse clicks to create PDF from files, images, Microsoft Office (Word, Excel, PowerPoint), OpenOffice or Microsoft Outlook, and vice versa. Every format has their specific use, but when it comes to delivery and storage, PDF is the best choice to produce secure electronic copies of your files&mdash;and it&rsquo;s fast and simple.</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>Here are examples of how to create &amp; convert PDF swiftly:</strong></p>

<ol>
	<li class="marginB10">
	<p>Create PDF or convert PDF to other formats with PDF software. (e.g. Right PDF Converter)</p>
	</li>
	<li class="marginB10">
	<p>Print to PDF from MS Word, Excel, PowerPoint or other programs that support printing.</p>
	</li>
	<li class="marginB10">
	<p>Use MS Office add-ins or the Save as function to convert current document</p>
	</li>
	<li class="marginB10">
	<p>Scan documents to PDF</p>
	</li>
	<li class="marginB10">
	<p>Convert emails to PDF from MS Outlook</p>
	</li>
	<li class="marginB10">
	<p>Combine, overlay or package multiple files into a PDF document</p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<p class="marginB100">Once PDF documents are created, you can share them in any way you want, such as email, cloud services and corporate networks. For files converted from PDF, it is easy to reuse the content, saving you the hassles of looking for the original copies or tedious manual key-in.</p>

<h1 id="p6">Interact with PDF</h1>

<p>PDF has been an extremely reliable format for display and printing, but one aspect of it is usually overlooked&mdash;the interactive PDF. From embedding audio tracks, video clips to adding checkboxes and hyperlinks, there are a lot of ways to enrich your PDF and impress your readers with contents far more appealing than just static documents. In comparison, PDF forms look better than those created in Microsoft Word, which is inherently unable to function like professional design software. So there is no need to sacrifice your brilliant design in exchange of compatibility&mdash;PDF simply does both for you.</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>For example, depending on the PDF solution you choose, you can add those to your PDF:</strong></p>

<ul class="list2">
	<li class="marginB10">
	<p>Hyperlink</p>
	</li>
	<li class="marginB10">
	<p>Markup</p>
	</li>
	<li class="marginB10">
	<p>File attachment</p>
	</li>
	<li class="marginB10">
	<p>Audio (e.g. recording)</p>
	</li>
	<li class="marginB10">
	<p>Movie</p>
	</li>
	<li class="marginB10">
	<p>Text note</p>
	</li>
	<li class="marginB10">
	<p>Button</p>
	</li>
	<li class="marginB10">
	<p>Check box</p>
	</li>
	<li class="marginB10">
	<p>Radio button</p>
	</li>
	<li class="marginB10">
	<p>Combo box</p>
	</li>
	<li class="marginB10">
	<p>Text field</p>
	</li>
	<li class="marginB10">
	<p>Barcode</p>
	</li>
</ul>
</div>

<div class="box1 marginB100">
<p><strong>Here are some great uses of interactive PDF:</strong></p>

<ul>
	<li class="marginB10">
	<p>Use a PDF reader to fill in forms and send back via email. No paper involved.</p>
	</li>
	<li class="marginB10">
	<p>Attach documents to PDF for further reading</p>
	</li>
	<li class="marginB10">
	<p>Insert recordings and/or movies to support your arguments</p>
	</li>
	<li class="marginB10">
	<p>Add hyperlinks to your designed PDF so that readers can simply click to visit</p>
	</li>
	<li class="marginB10">
	<p>Import and export forms data</p>
	</li>
</ul>
</div>

<h1 id="p7">Security</h1>

<p>PDF is designed to support a lot of security features, such as passwords, watermarks and encryption. The risks of overlooking the protection of confidential information, whether visible or invisible, are continuously on the rise due to the omnipresence of mobile devices and privacy policies that grow increasingly stringent. Simply put, a document without protection is a document that could be exploited and lead to unforeseeable damages. But don&rsquo;t worry just yet, there are PDF tools at your disposal that can help you build defense against potential breach.</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>Secure PDF documents in the following ways:</strong></p>

<ul>
	<li class="marginB10">
	<p><strong>Open or permissions password</strong>&mdash;set an open password so that only authorized users can open, or control the actions users can take with a permissions password, such as printing, copying, annotating or extracting pages.</p>
	</li>
	<li class="marginB10">
	<p><strong>Watermarks, headers and footers</strong>&mdash;put icons, logos, watermarks and graphical references on pages to strengthen visual support and increase credibility.</p>
	</li>
	<li class="marginB10">
	<p><strong>Inspect document</strong>&mdash;the removal of sensitive information from the document prior to release makes sure it won&rsquo;t give away details that might hurt business or infringe upon the privacy rights of individuals.</p>
	</li>
	<li class="marginB10">
	<p><strong>Electronic certificate</strong>&mdash;encrypt documents and verify digital signature so that only the recipients can view the contents and know the document came from you.</p>
	</li>
	<li class="marginB10">
	<p><strong>Redaction/ Whiteout</strong>&mdash;sensitive information can be permanently erased from documents.</p>
	</li>
	<li class="marginB10">
	<p><strong>Microsoft AIP</strong>&mdash;Right PDF supports Microsoft AIP(Azure Information Protection) so that you can restrict access to authorized users only and define the actions they can take.</p>
	</li>
	<li class="marginB10">
	<p><strong>Dynamic stamps</strong>&mdash;create stamps that contain details such as author, time/date, and email address to enhance approval workflow efficiency.</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p class="marginB100">Document authors and collaborators often need the security of knowing that their files can only be accessed by authorized individuals. With PDF and various built-in security features, you are in a comfortable position to safeguard information from those prying eyes.</p>

<h1 id="p8">Review Documents</h1>

<p>There are two forms of reviewing a document: one is applying digital signatures to ensure integrity and sender identity, and the other is using annotations so that a team can quickly produce the final version. For businesses that seek to reduce dependence on papers and improve work efficiency, both are valid replacement options to their old-time counterparts.</p>

<p>&nbsp;</p>

<h2 class="marginB20">Digital Signatures</h2>

<p>Digital signatures are widely employed to replace traditional ink-on-paper signatures. To companies with a constant need to collect signatures across multiple locations, whether for document review or contract signing, today&rsquo;s e-signing streamlines business process by making authentication easy. Moreover, once combined with PDF, they are secure, fit for archival and support legally binding electronic signatures*.</p>

<p>For instance, you can allow an individual to sign the PDF but prevent them from changing the content, e.g., contracts and agreements. Digital signature will indicate tampering if any data on any given page have been altered. You may also view signed versions and compare signed version to the current one.</p>

<p>&nbsp;</p>
<div class="part2Wrapper textAlignL">
	<div class="box1 box2">
	<p><strong>Sign on Paper</strong></p>

	<ol>
		<li class="marginB10">
		<p>Receive a document via email (e.g. contract)</p>
		</li>
		<li class="marginB10">
		<p>Save the contract in a folder</p>
		</li>
		<li class="marginB10">
		<p>Print and sign the contract</p>
		</li>
		<li class="marginB10">
		<p>Scan the contract</p>
		</li>
		<li class="marginB10">
		<p>Save the scanned copy on the computer</p>
		</li>
		<li class="marginB10">
		<p>Email the signed contract to the sender</p>
		</li>
	</ol>
	</div>

	<div class="box1">
	<p><strong>Digital Signature</strong></p>

	<ol>
		<li class="marginB10">
		<p>Open the contract</p>
		</li>
		<li class="marginB10">
		<p>Put your signature on the contract</p>
		</li>
		<li class="marginB10">
		<p>Save the contract to computer</p>
		</li>
		<li class="marginB10">
		<p>Email the signed contract to the sender</p>
		</li>
	</ol>
	</div>
</div>

<p>&nbsp;</p>

<h2 class="marginB20">Use annotations for review</h2>

<p class="marginB70">Contrary to conventional reviewing process, doing the same job with PDF makes it interactive, responsive and eco-friendly. Common solutions often involve a certain degree of system reform and database integration, taking time, resources and eventually giving rise to maintenance concerns in the wake of installation. In contrast, PDF does not only offer familiar tools such as highlight, note and cross-out, but also enable users to share annotations and track review status&mdash;all of which are easy to learn and require no assistance from IT personnel.</p>

<p style="text-align:center"><img alt="Use annotations for review" src="${require(`../../assets/img/resources/use_annotations for_review_en.jpg`)}" /></p>

<div class="box1 marginB100">
<p><strong>Here are what you can do to review:</strong></p>

<ul>
	<li class="marginB10">
	<p>Annotate PDF with tools such as note, typewriter, text box, highlight, insert text or pencil</p>
	</li>
	<li class="marginB10">
	<p>Attach other documents or sound files to PDF</p>
	</li>
	<li class="marginB10">
	<p>Track the person who created the annotation</p>
	</li>
	<li class="marginB10">
	<p>Create a comment summary with definable layout, size and page range</p>
	</li>
	<li class="marginB10">
	<p>Apply watermarks and stamps with adjustable rotation, opacity and content.</p>
	</li>
	<li class="marginB10">
	<p>Open comment panel to display all annotations on a list for easy tracking</p>
	</li>
	<li class="marginB10">
	<p>Use Compare Document to spot differences between two versions</p>
	</li>
</ul>
</div>

<h1 id="p9">File compression</h1>

<p>One key element that contributes to the prevalence of PDF is its smaller size and uncompromising quality. Image-intensive documents, such as presentation or field report, can easily lead to storage and sharing challenges. Take a repair report for example, the technicians have to take a multitude of photos and compile a report for further assessment, but the significant size of the file poses problems as they try to send it. By using PDF and &ldquo;Reduce file size&rdquo; feature, the file can be shrunk to manageable size, making sharing, archival and organization much easier.</p>

<p>&nbsp;</p>

<div class="box1 marginB100">
<p><strong>Here are the ways to reduce file size:</strong></p>

<ul>
	<li class="marginB10">
	<p>Convert the file to PDF with either a converter or virtual printer. For example, Right PDF Converter and &ldquo;Right PDF&rdquo; printer both provide the option to set a compression level.</p>
	</li>
	<li class="marginB10">
	<p>Further compress the images within documents by the &ldquo;reduce file size&rdquo; feature. You may also specify a reduction level and apply the compression to multiple documents.</p>
	</li>
	<li class="marginB10">
	<p>Use &ldquo;Inspect Document&rdquo; to remove unnecessary data, such as bookmarks, comments, links or attachments.</p>
	</li>
</ul>
</div>

<h1 id="p10">Easy Access &amp; Edit</h1>

<p>The P in PDF stands for portable, and portability means it will be displayed as intended whether on web browser or PDF reader. A common problem of using multiple formats at work is your recipients might not have the same version of application to read the file, and extra back-and-forth communication takes a lot of time. Therefore, it makes great sense to create PDF with just a few clicks and save you from further complications. You may even secure the PDF lest other people would use the content against your interests.</p>

<p>Another important feature of accessibility is PDF tags, which serves to deploy a document to mobile devices and help users with disabilities to read, as mandated by the authorities. Simply put, tags represent document structure, like which text is heading and which text is bookmark. The benefits of using tagged PDF also extend to text reflow on devices, reliable content extraction and better search engine detectability.</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>Important takeaways</strong></p>

<ul>
	<li class="marginB10">
	<p>Tagged PDF is accessible to users who require Assistive Technology (AT) to read</p>
	</li>
	<li class="marginB10">
	<p>Viewing PDF on devices such as tablets and smartphones becomes more reliable</p>
	</li>
	<li class="marginB10">
	<p>PDF tags make archival effective by keeping the documents retrievable at any given time</p>
	</li>
	<li class="marginB10">
	<p>Tags improve the conversion quality of PDF to other formats</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p>As an ideal final-form electronic document format, PDF is not known for editing capabilities and most users only regard it as a format for outbound documents. However, upon learning the benefits of using PDF, the next question from professionals should be: how do I edit a PDF file? After all, mistakes happen and users would need to correct the contents. The good news is choosing the right solution could save you valuable time changing the contents of PDF documents, such as Right PDF Pro.</p>

<p>&nbsp;</p>
<div class="part2Wrapper marginB100 part2Wrapper2 textAlignL">
	<div class="box1 box2">
	<p><strong>Typical scenario</strong></p>

	<ul>
		<li class="marginB10">
		<p>The author opens/edits a Word document and converts to PDF</p>
		</li>
		<li class="marginB10">
		<p>Recipient converts PDF back to Word for editing, risking possible layout change and missing content, or requests the original copy from the author</p>
		</li>
		<li class="marginB10">
		<p>Recipient converts the document to PDF again. This might repeat when there&rsquo;s another recipient or co-author</p>
		</li>
	</ul>
	</div>

	<div class="box1">
	<p><strong>Using the right PDF solution</strong></p>

	<ul>
		<li class="marginB10">
		<p>Instead of repeated conversions, a PDF can be created and edited directly within the application</p>
		</li>
		<li class="marginB10">
		<p>Edit PDF with the familiar Ribbon UI and rich word processing capabilities, such as fonts, numbering, alignment and style</p>
		</li>
		<li class="marginB10">
		<p>Insert tables, clip arts, links, screenshot; or set margins, page orientation and indent</p>
		</li>
	</ul>
	</div>
</div>

<h1 id="p11" class="marginB40">Conclusion</h1>

<p>PDF is a name that people often hear without understanding its benefits, which address some of the biggest concerns to today&rsquo;s businesses: the sharing, security and archiving of electronic documents. While different formats serve specific purposes, PDF has proved to be the one that can deliver predictable results and is hassle-free. It is also important to note that PDF is a non-proprietary technology managed by ISO (International Standards Organization) as ISO 32000 and no longer under the control of Adobe, meaning you can choose from a variety of standard-compliant PDF solutions, rather than bloatware that costs an arm and a leg. Now is your turn to make a change for the better.</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>Quick PDF facts:</strong></p>

<ul>
	<li class="marginB10">
	<p>Adobe ceded control over PDF in 2008.</p>
	</li>
	<li class="marginB10">
	<p>PDF is managed by diverse board members of ISO.</p>
	</li>
	<li class="marginB10">
	<p>The latest is PDF 2.0 (ISO 32000-2:2017).</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p style="text-align:center;margin-bottom:30px;"><img src="${require(`../../assets/img/point.png`)}"/></p>

<h1 class="font20" style="text-align:center"><span style="color:#37915c">Get in touch with Right PDF today to discover a better way for your business!</span></h1>

<p style="text-align:center"><a href="https://www.rightpdf.com/en/contactUs" target="_blank" style="width:210px;height:60px;background:#087A4F;display:inline-block;font-size:17px;color:#fff;line-height:60px;border-radius:4px;margin-top:40px;">Contact Right PDF</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>
<div id="p37">
<p><strong>About Zeon Corporation</strong></p>

<p><strong>Zeon Corporation is changing the way PDF interacts with users. Contrary to common expectations of PDF software, the company upholds the ideal that people shall freely create, write and edit PDF documents, without having to go through tedious maneuvering or look for original copies stored in other places. Incorporated with intuitive UI and a rich array of capabilities, Right PDF aims at augmenting efficiency and productivity for individuals and businesses alike around the world.</strong></p>
</div>
<p>&nbsp;</p>

<ul style="padding-left:20px;">
	<li>Adobe, the Acrobat logo and Acrobat are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries.</li>
	<li>Microsoft and Windows are either registered trademarks of Microsoft Corporation in the United States and/or other countries. All other trademarks are the property of their respective owners.</li>
</ul>`;
return (<div>
	<MetaTags>
	   <title>{metaTagscontent.title}</title>
	   <meta name="title" content={metaTagscontent.title} />
	   <meta name="description" content={metaTagscontent.desc} />
	   <meta property="og:title" content={metaTagscontent.title} />
	   <meta property="og:description" content={metaTagscontent.desc} />
   </MetaTags>
   <div dangerouslySetInnerHTML={{__html:viewcontent}}></div>
</div>);
}
export default SuiteCompare;
