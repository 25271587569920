const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我可以把原本分属两个账号的两组授权码 (序列号) 绑定到同一个账号下吗?</title></head><body>  <h1>我可以把原本分属两个账号的两组授权码 (序列号) 绑定到同一个账号下吗?</h1>  <p><span>是的，您可以将多组授权码(序号)系结到同一个账号下统一管理。</span></p>
<p> </p>
<p><span>请先把账号中不需要的序列号解除绑定：</span><br><span>1. 使用您的账号登录我们的<a href="https://store.rightpdf.cn/account/login.aspx" target="_blank" rel="noopener">网站</a></span></p>
<p><span>2. 点击「授权管理」</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (8).png" alt="mceclip3 (8).png" width="504" height="157"></p>
<p> </p>
<p> </p>
<p><span>3. 在列表中选择序订单，然后点击旁边的「U」来解除绑定</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (13).png" alt="mceclip2 (13).png"></p>
<p><span>4. 按照指示完成解除绑定的步骤</span></p>
<p><br><span>然后将该序列号<a href="https://support.rightpdf.com/hc/zh-cn/articles/201121713" target="_blank" rel="noopener">绑定</a>至您想要整合的账号下。</span></p></body></html>`;   export default html;