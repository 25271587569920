import React,{useState,useEffect,useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getPressList, getPressTypeList} from '../../api';
// import { useSelector, useDispatch} from 'react-redux'; 
import MetaTags from 'react-meta-tags';

const Media = props => {
    const {t,currentLang} = props;
    //const yearArray = ['all',2020,2019,2018,2017,2016,2015];
    // const pageArray = [1,2,3,4];
    const [currentYearId,setCurrentYearId] = useState('');
    // const [currentYear,setCurrentYear] = useState('');  
    const [pressAry,setPressAry] = useState([]);
    const [pressTypeAry,setPressTypeAry] = useState([]);
    // const [currentPage, setCurrentPage] = useState(pageArray[0]);
    const [show,setShow] = useState(false);
    const [isLoading, setLoading] = useState(true);

    //const yearArr = {};

    const toggleShow = e => {
        e.stopPropagation();
        if(!show){
            setShow(true);
        }else{
            setShow(false);
        }
    }
    const hideSelect = () => {
        setShow(false);
    }
    const switchYear = (value, text) => {
        setCurrentYearId(value);
        // setCurrentYear(text);
        // getPress(value);
    }
    const showYear = () => {
      return ""
       // return currentYear;
        // setCurrentYearNAme
    }
    
    // const getLang = (cLang) => {
    //     let lang = 'EN';
    //     if(cLang === 'zh-TW'){
    //         lang = 'CT';
    //     }else if(cLang === 'zh-CN'){
    //         lang = 'CS';
    //     }else{
    //         lang = cLang.toUpperCase();
    //     }
    //     return lang;
    // }

    // const getPress = (cYear) => {
    //     getPressList({LgeID:getLang(currentLang.type), TypeID:cYear, PageIndex:0, PageSize:100}).then(function (response) {
    //         setPressAry(response.data.List);
    //         setLoading(false);
    //     })
    //     .catch(function (error) {
    //         console.log('err',error);
    //     });
    // }

    const firstUpload = useRef(true);
    useEffect(()=>{
        if(firstUpload.current){
            firstUpload.current = false;
            window.addEventListener('click',hideSelect)
            return;
        }
        return function removeClickHide(){
            window.removeEventListener('click',hideSelect);
        }
    })

    
    useEffect(() => {

        setLoading(true);
        const fetchResource = cLang => {
            if(!currentYearId){
                return;
            }
            let lang = 'EN';
            if(cLang === 'zh-TW'){
                lang = 'CT';
            }else if(cLang === 'zh-CN'){
                lang = 'CS';
            }else{
                lang = cLang.toUpperCase();
            }
            getPressList({LgeID:lang, TypeID:currentYearId, PageIndex:0, PageSize:100}).then(function (response) {
                setLoading(false);
                setPressAry(response.data.List);
            })
            .catch(function (error) {
                console.log('err',error);
            });
        
        }

        fetchResource(currentLang.type);
    }, [currentYearId,currentLang.type]);

    useEffect(() => {
        setLoading(true);
        const fetchResource = cLang => {
            let lang = 'EN';
            if(cLang === 'zh-TW'){
                lang = 'CT';
            }else if(cLang === 'zh-CN'){
                lang = 'CS';
            }else{
                lang = cLang.toUpperCase();
            }   
            getPressTypeList({LgeID:lang}).then(function (response) {
                setLoading(false);
                setPressTypeAry(response.data.List);
                // setCurrentYear(pressTypeAry[0].TypeName);
            })
            .catch(function (error) {
                console.log('err',error);
            });
        
        }

        fetchResource(currentLang.type);
    }, [currentLang.type]);
    
    useEffect(() => {
        if(pressTypeAry.length > 0 ){
            setCurrentYearId(pressTypeAry[0].TypeID);
            // setCurrentYear(pressTypeAry[0].TypeName);
        }
    }, [pressTypeAry]);


    return (
        <div id="media" className="position-relative">
            <MetaTags>
                <title>{t('metaTag.media.title')}</title>
                <meta name="title" content={t('metaTag.media.title')} />
                <meta name="description" content={t('metaTag.media.desc')} />
                <meta name="keywords" content={t('metaTag.media.keywords')} />
                <meta property="og:title" content={t('metaTag.media.title')} />
                <meta property="og:description" content={t('metaTag.media.desc')} />
            </MetaTags>
            <div className="yearDropdown">
                <button type="button" className="btn dropDownBtn" onClick={(e)=>{toggleShow(e)}}>
                    <span className="inline-block infoBlock">{showYear()}</span>
                    <span className="inline-block arrowBlock">
                        <FontAwesomeIcon  icon="chevron-down" className="fa-sm" />
                    </span>
                </button>
                <ul className={"mediaYearList "+(show?'active':'')}>
                    {
                    
                        pressTypeAry.map((e)=>{
                      
                       
                            return (
                                <li key={e.TypeID} onClick={()=>{switchYear(e.TypeID, e.TypeName)}} className={e.TypeID === currentYearId?'active':''}>
                                    {e.TypeName}
                                </li>)
                        })
                    }
                </ul>
            </div>
            <div className="mediaContent">
                <h2 className="font-weight-bold">{showYear()}</h2>
                <ul className="mediaLinkList">
                    {   
                        isLoading ? "" : 
                        pressAry.map((data,i)=>{
                            return (
                                <li key={i}> 
                                    <div className="imgBlock"><img src={data.Img_pc} alt=""></img></div>
                                    <div className="infoBlock">
                                        <span className="d-block">{data.Date} / {data.Author}</span>
                                        <h3 className="font-weight-normal title_style">
                                            <a target="_blank" href={data.Url} className="greenlink" rel="noopener noreferrer">
                                                {data.Name}
                                            </a>
                                        </h3>
                                        <p>
                                            {data.Desc}
                                        </p>
                                    </div>
                                </li>
                    
                            )
                        })
                    }
                    {/* <li> 
                        <div className="imgBlock">
                            <img src={data.Img_pc}></img>
                        </div>
                        <div className="infoBlock">
                            <span className="d-block">2019-10-30 / 小科科恰爾斯</span>
                            <h3 className="font-weight-normal">
                                <a href="#" className="greenlink">
                                    棣南PDF文電通5專業版，讓你編輯PDF就像在使用Word一樣簡單又快速
                                </a>
                            </h3>
                            <p>
                                相信大家對於PDF一定不陌生，小編的就經常在工作上會用到，不過一直以來，如果遇到PDF檔案要編輯的話，都是上網找免費網站來使用，像是轉檔、檔案合併，頁次更改等等，簡單的話免費線上軟體就可以完成，可是要一直上傳、下載檔案，真的很麻煩，尤其是在趕時間的時候，更會有種想殺人的感覺！前陣子朋友介紹入手了一套專業PDF編輯軟體，棣南PDF文電通5專業版(Gaaiho)，真的很好用！軟體介面長的跟Word超級像，只要會使用Word就會使用PDF文電通。
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="imgBlock"></div>
                        <div className="infoBlock">
                            <span className="d-block">2019-11-14 / PCM</span>
                            <h3 className="font-weight-normal">
                                <a href="#" className="greenlink">
                                    Gaaiho PDF文電通 全方位的PDF處理方案
                                </a>
                            </h3>
                            <p>
                                相信不少朋友的日常工作，都已經傾向採用無紙化辦公，即是儘量使用電子郵件、電子化的表格，而一些表格，告示甚至合約等等亦會以 PDF 檔案去處理。雖然我們日常使用的 Microsoft Office 已經可以製作及閱讀 PDF 檔案，不過當收到一些 PDF 格式的表格，而身邊又沒有打印機的情況，大家又會怎樣處理呢？
                            </p>
                        </div>
                    </li> */}
                </ul>
                <div className='tips'>{t('tips')}</div>
                {/* <div className="pageContent">
                    <ul className="pageList d-inline-block align-middle">
                        {
                            pageArray.map((e,i)=>{
                                return <li key={i} className={e === currentPage?'active':''}>{e}</li>
                            })
                        }
                    </ul>
                    <span className="prvePage d-inline-block align-middle">
                        上一頁
                    </span>
                    <span className="nextPage d-inline-block align-middle">
                        下一頁
                    </span>
                </div> */}
            </div>
        </div>
    )
}

export default Media;