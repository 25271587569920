
import React, { useEffect } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import mixinWrapper from '../hoc/mixinWrapper';
import { Link } from 'react-router-dom';

import pdfSuite3 from '../assets/img/pdfSuite/icon_addnote.png';
import pdfSuite4 from '../assets/img/pdfSuite/icon_ocr.png';
import pdfSuite5 from '../assets/img/pdfSuite/icon_addtext.png';
//import pdfSuite7 from '../assets/img/pdfSuite/bg_driver_row.png';

import pdfSuite8 from '../assets/img/pdfSuite/icon_addnote.png';
import pdfSuite11 from '../assets/img/pdfSuite/icon_ocr.png';
import pdfSuite12 from '../assets/img/pdfSuite/icon_encryption.png';
import pdfSuite15 from '../assets/img/pdfSuite/icon_form.png';
import pdfSuite16 from '../assets/img/pdfSuite/icon_poweron.png';
import pdfSuite17 from '../assets/img/pdfSuite/icon_combine.png';
import pdfSuite18 from '../assets/img/pdfSuite/icon_pdfmanage.png';
import pdfSuite19 from '../assets/img/pdfSuite/icon_stamp.png';
import pdfSuite20 from '../assets/img/pdfSuite/icon_reducesize.png';
import MetaTags from 'react-meta-tags';

const PdfSuite = props => {
    const {t,currentLang,linkObj,i18n} = props;
    const {lang} = useParams();
    let history = useHistory();


    useEffect(() => {
        if(lang !== 'zh-TW' && lang !== 'zh-CN'){
            history.push("/");
        }
    }, [lang,history]);

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}			
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    return (
        
        <section id="pdfSuite" className="whiteBg">
            <MetaTags>
                <title>{t('metaTag.pdfSuite.title')}</title>
                <meta name="title" content={t('metaTag.pdfSuite.title')} />
                <meta name="description" content={t('metaTag.pdfSuite.desc')} />
                <meta name="keywords" content={t('metaTag.pdfSuite.keywords')} />
                <meta property="og:title" content={t('metaTag.pdfSuite.title')} />
                <meta property="og:description" content={t('metaTag.pdfSuite.desc')} />
            </MetaTags>
            <div className="topBlock contentBlock text-white">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                            <div className="firstTitle">{t('pdfSuite.name')}</div>
                            <div className="secondTitle">{t('pdfSuite.info')}</div>
                            <div className="btnGroup">
                                <a href={linkObj.downloadLink.suite.url()} className="cBtn w_text greenBg text-center" target="_blank" rel="noopener noreferrer">
                                    <p className="m-0 font-light">{t('pdfSuite.freeTrial')}</p>
                                </a>
                                <div className="desc text-center">{t('pdfSuite.support')}</div>
                            </div>
                            <div className="tp-img-area">
                                <img src={require(`../assets/img/pdfSuite/${currentLang.type}/ui_header.png`)}  className="img-fluid " alt="pdfSuite_01"/>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock block1 greenBg-light pt-10">
                <div className="customContainer">
                    <div className="cRow maxW1500">
                        <div className="f54-title font-light text-center d-100 b-text mt-80 mb-120">{t('pdfSuite.t1')}</div>
                        
                        <div className="pdfSuiteInfo align-top d-100">
                            <div className=" alignLeft d-50">
                                <ul>
                                    <li>
                                        <div className="align-middle maxd819">
                                            <img src={pdfSuite3}  className="img-fluid" alt="feature_01"/>
                                        </div>
                                        <div className="d-100 alignLeft align-middle">
                                            <h4 className="font-weight-bold b-text">{t('pdfSuite.t2')}</h4>
                                            <div className="m-text">{t('pdfSuite.t3')}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="align-middle maxd819">
                                            <img src={pdfSuite4}  className="img-fluid" alt="feature_01"/>
                                        </div>
                                        <div className="d-100 alignLeft align-middle">
                                            <h4 className="font-weight-bold b-text">{t('pdfSuite.t4')}</h4>
                                            <div className="m-text">{t('pdfSuite.t5')}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="align-middle maxd819">
                                            <img src={pdfSuite5}  className="img-fluid" alt="feature_01"/>
                                        </div>
                                        <div className="d-100 alignLeft align-middle">
                                            <h4 className="font-weight-bold b-text">{t('pdfSuite.t6')}</h4>
                                            <div className="m-text">{t('pdfSuite.t7')}</div>
                                        </div>
                                    </li>
                                </ul>    
                            </div>
                            <div className="alignRight maxd819 d-50">
                                <img src={require(`../assets/img/pdfSuite/${currentLang.type}/ui_01.png`)}  className="img-fluid" alt="pdfSuite_01"/>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <div className="contentBlock block2 greenBg-gradient pt-10">
                <div className="customContainer">
                    <div className=" maxW1500">
                        <div className="f54-title font-light text-center d-100 b-text mt-80  y_color">{t('pdfSuite.t8')}</div>
                        
                        <div className="pdfSuiteInfo">
                            <div className="m-text">{t('pdfSuite.t9')}</div>
                            
                        </div>
                        <img src={require(`../assets/img/pdfSuite/${currentLang.type}/ui_02.png`)}  className="img-fluid" alt="pdfSuite_01"/>
{/*                         
                        <div className="align-area">
                            <div className="aaa">
                                
                                <div className="align-middle alignLeft">
                                    <img src={pdfSuite6}  className="img-fluid" alt="pdfSuite_01"/>
                                </div>
                                <div className="pdfSuiteInfo  alignLeft">
                                    <div className="m-text">{t('pdfSuite.t9')}</div>
                                    
                                </div>
                            
                            </div>
                            <div className="alignRight fixed-img">
                                <img src={pdfSuite7}  className="img-fluid" alt="pdfSuite_01"/>
                            </div>
                        </div>
                        
                     */}
                    </div>
                </div>
            </div>
            <div className="contentBlock block3 yellowBg pt-10">
                <div className="customContainer">
                    <div className="cRow maxW1500">
                        <div className="f54-title font-light text-center d-100 b-text mt-80 mb-120">{t('pdfSuite.t10')}</div>
                        
                        <div className="pdfSuiteInfo align-top d-100">
                            <div className=" alignLeft d-50">
                                <ul>
                                    <li>
                                        <div className="align-middle maxd819">
                                            <img src={pdfSuite8}  className="img-fluid" alt="feature_01"/>
                                        </div>
                                        <div className="d-100 alignLeft align-middle">
                                            <div className="m-text">{t('pdfSuite.t11')}</div>
                                        </div>
                                    </li>
                                </ul>    
                                
                            </div>
                            <div className="alignRight maxd819 d-50">
                                <img src={require(`../assets/img/pdfSuite/${currentLang.type}/ui_03.png`)}  className="img-fluid" alt="pdfSuite_01"/>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <div className="contentBlock block4 pt-10">
                <div className="customContainer">
                    <div className="cRow maxW1500">
                        <div className="f54-title font-light text-center d-100 b-text mt-80 mb-120">{t('pdfSuite.t12')}</div>
                        
                        <div className="pdfSuiteInfo align-top d-100">
                        <div className="alignLeft maxd819 d-50">
                                <img src={require(`../assets/img/pdfSuite/${currentLang.type}/ui_04.png`)}  className="img-fluid" alt="pdfSuite_01"/>
                            </div>
                            <div className=" aligRight d-50">
                                <ul>
                                    <li>
                                        <div className="align-middle maxd819">
                                            <img src={pdfSuite11}  className="img-fluid" alt="feature_01"/>
                                        </div>
                                        <div className="d-100 alignLeft align-middle">
                                            <div className="m-text">{t('pdfSuite.t13')}</div>
                                        </div>
                                    </li>
                                </ul>    
                                
                            </div>
                            
                        </div>
                    </div>  
                </div>
            </div>
            <div className="contentBlock block5 pt-10">
                <div className="customContainer">
                    <div className="cRow maxW1500">
                        <div className="f54-title font-light text-center d-100 b-text mt-80 mb-120">{t('pdfSuite.t14')}</div>
                        
                        <div className="pdfSuiteInfo align-top d-100">
                            
                            <div className=" alignLeft d-50">
                                <ul>
                                    <li>
                                        <div className="align-middle maxd819">
                                            <img src={pdfSuite12}  className="img-fluid" alt="feature_01"/>
                                        </div>
                                        <div className="d-100 alignLeft align-middle">
                                            <div className="m-text">{t('pdfSuite.t15')}</div>
                                        </div>
                                    </li>
                                </ul>    
                                
                            </div>
                            <div className="alignRight maxd819 d-50">
                                <img src={require(`../assets/img/pdfSuite/${currentLang.type}/ui_05.png`)}  className="img-fluid" alt="pdfSuite_01"/>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <div className="contentBlock block6 pt-10">
                <div className="customContainer">
                    <div className="cRow maxW1500">
                        <div className="f54-title font-light text-center d-100 b-text mt-80 mb-120">{t('pdfSuite.t14')}</div>
                        
                        <div className="pdfSuiteInfo align-top d-100">
                            <div className="alignLeft maxd819 d-50">
                                <img src={require(`../assets/img/pdfSuite/${currentLang.type}/ui_06.png`)}  className="img-fluid" alt="pdfSuite_01"/>
                            </div>
                            <div className=" alignRight d-50">
                                <ul>
                                    <li>
                                        <div className="align-middle maxd819">
                                            <img src={pdfSuite15}  className="img-fluid" alt="feature_01"/>
                                        </div>
                                        <div className="d-100 alignLeft align-middle">
                                            <div className="m-text">{t('pdfSuite.t15')}</div>
                                        </div>
                                    </li>
                                </ul>    
                                
                            </div>
                            
                        </div>
                    </div>  
                </div>
            </div>
            <div className="contentBlock block7 pt-10">
                <div className="customContainer">
                    <div className="cRow maxW1500">
                        <div className="alignCenter d-100">
                            <img src={pdfSuite16}  className="img-fluid" alt="pdfSuite_01"/>
                        </div>
                        <div className="f54-title font-light text-center d-100 b-text ">{t('pdfSuite.t18')}</div>
                        <div className="f20-subtitle font-light text-center d-100 b-text mb-80">{t('pdfSuite.t19')}</div>
                        
                        <div className="pdfsuite-items align-top d-100">
                            
                            
                            <div className="item d-25">
                                <img src={pdfSuite17}  className="img-fluid" alt="detail2" />
                                <div className="text">
                                    <div className="f-26">{t('pdfSuite.t20')}</div>
                                    <div className="f-16">{t('pdfSuite.t21')}</div>
                                </div>
                            </div>
                            <div className="item d-25">
                                <img src={pdfSuite18} className="img-fluid" alt="detail2" />
                                <div className="text">
                                    <div className="f-26">{t('pdfSuite.t22')}</div>
                                    <div className="f-16">{t('pdfSuite.t23')}</div>
                                </div>
                            </div>
                            <div className="item d-25">
                                <img src={pdfSuite19} className="img-fluid" alt="detail2" />
                                <div className="text">
                                    <div className="f-26">{t('pdfSuite.t24')}</div>
                                    <div className="f-16">{t('pdfSuite.t25')}</div>
                                </div>
                            </div>
                            <div className="item d-25">
                                <img src={pdfSuite20} className="img-fluid" alt="detail2" />
                                <div className="text">
                                    <div className="f-26">{t('pdfSuite.t26')}</div>
                                    <div className="f-16">{t('pdfSuite.t27')}</div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <div className="contentBlock block8 pt-10">
                <div className="customContainer">
                    <div className="cRow maxW1500">
                        <div className="firstTitle">{t('pdfSuite.t28')}
                            <span className="bold">{t('pdfSuite.t29')}</span>
                            
                        </div>
                        <div className="btnGroup">
                            <a href={props.linkObj.downloadLink.suite.url()} className="cBtn w_text greenBgBtn" target="_blank" rel="noopener noreferrer">
                                {t('pdfSuite.t30')}
                            </a>
                            <a href={props.linkObj.buyLink.url()} className="cBtn w_text redBgBtn" target="_blank" rel="noopener noreferrer">
                                {t('pdfSuite.t31')}
                            </a>
                        </div>
                        <div className="f-13">{t('pdfSuite.t32')}</div>
                        <div className="btnGroup2">
                            <Link to={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`} className="contact-sales">
                                {t('pdfSuite.t33')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default mixinWrapper(PdfSuite);