import React from 'react';
import {Link} from 'react-router-dom';
const ProductBox = props => {
    const {t,linkObj,currentLang} = props;
    return (
        <div className={'productBlock hideMenuContent '+((currentLang.type === 'zh-TW' || currentLang.type === 'zh-CN')?'':'smtext')}> 
            <div className="customContainer">
                <div className="cRow justifyCenter">
					<div className="productLeftBlock">
						<div className="productMainBlock">
							<Link to={`/${props.currentLang.type}${linkObj.product.suite.path}`} className="d-inline-block averageBlock d-100 suite greenBg-Apple">
								<div className="titleInfo">
									<h2>{t(linkObj.product.header.block1.t1)}</h2>
									<p>{t(linkObj.product.header.block1.t2)}</p>
								</div>
								<i className="windows"></i>
								<i className="mac"></i>
							</Link>
							<Link to={`/${props.currentLang.type}${linkObj.product.converter.path}`} className="d-inline-block averageBlock d-100  converter orangeBg-Meteor">
								<div className="titleInfo">
									<h2>{t(linkObj.product.header.block2.t1)}</h2>
									<p>{t(linkObj.product.header.block2.t2)}</p>
								</div>
								<i className="windows"></i>
							</Link>
							<Link to={`/${props.currentLang.type}${linkObj.product.server.path}`} className="d-inline-block averageBlock d-100 server deepBlue">
								<div className="titleInfo">
									<h2>{t(linkObj.product.header.block3.t1)}</h2>
									<p>{t(linkObj.product.header.block3.t2)}</p>
									<p>{t(linkObj.product.header.block3.t3)}</p>
								</div>
								<i className="windows"></i>
							</Link>
						</div>
						<div className="d-10 horiLine"></div>
						<div className="productMainBlock productMainBlock1">
							<Link to={`/${props.currentLang.type}${linkObj.product.reader.pc.path}`} className="d-inline-block averageBlock d-100 reader">
								<div className="titleInfo">
									<h2>{t(linkObj.product.header.block4.t1)}</h2>
									<p>{t(linkObj.product.header.block4.t2)}</p>
								</div>
								<i className="windows"></i>
							</Link>
							<Link to={`/${props.currentLang.type}${linkObj.product.reader.app.path}`} className="d-inline-block averageBlock d-100  readerPhone">
								<div className="titleInfo">
									<h2>{t(linkObj.product.header.block4_1.t1)}</h2>
									<p>{t(linkObj.product.header.block4_1.t2)}</p>
								</div>
								<i className="ios"></i>
								<i className="android"></i>
							</Link>
							<Link to={`/${props.currentLang.type}${linkObj.product.sdk.path}`} className="d-inline-block averageBlock d-100 sdk">
								<div className="titleInfo">
									<h2>{t(linkObj.product.header.block5.t1)}</h2>
									<p>{t(linkObj.product.header.block5.t2)}</p>
								</div>
							</Link>
						</div>
						<div className="d-10 horiLine changehoriLine"></div>
					</div>
                    <div className="d-10 separateLine changeseparateLine"></div>
                    <div className="productSideBlock">
						<div>
							<a href={linkObj.product.addpdf.url()} className="d-inline-block btn-group titleInfo1" target="_blank">
								{t(linkObj.product.header.block7.t1)}
							</a>
						</div>
						<div>
							<a href={linkObj.product.addpdf.url_pdf2word()} className="d-inline-block titleInfo" target="_blank">
								{t(linkObj.product.header.block8.t1)}
							</a>
						</div>
						<div>
							<a href={linkObj.product.addpdf.url_pdf2jpg()} className="d-inline-block titleInfo" target="_blank">
								{t(linkObj.product.header.block9.t1)}
							</a>
						</div>
						<div>
							<a href={linkObj.product.addpdf.url_pdf2img()} className="d-inline-block titleInfo" target="_blank">
								{t(linkObj.product.header.block10.t1)}
							</a>
						</div>
						<div>
							<a href={linkObj.product.addpdf.url_removewatermark()} className="d-inline-block titleInfo" target="_blank">
								{t(linkObj.product.header.block11.t1)}
							</a>
						</div>
						<div>
							<a href={linkObj.product.addpdf.url_mergepdf()} className="d-inline-block titleInfo" target="_blank">
								{t(linkObj.product.header.block12.t1)}
							</a>
						</div>
						<div>
							<a href={linkObj.product.addpdf.url()} className="d-inline-block titleInfo2">
								{t(linkObj.product.header.block13.t1)}
							</a>
						</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductBox;
