import React,{useEffect, useState, useCallback} from 'react';   //eslint-disable-line no-unused-vars
import mixinWrapper from '../hoc/mixinWrapper';
import { Link } from 'react-router-dom';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {getFeatureContent, getFeatureList} from '../api';
import MetaTags from 'react-meta-tags';

const Readmore = props => {
    const {t,currentLang,linkObj, i18n,featureType} = props;
    const {lang, contentId} = useParams();
    const dispatch = useDispatch();
    const [featureContent, setFeatureContent] = useState({});
    const [currentId, setCurrentId] = useState(contentId);
    // const [isLoading, setLoading] = useState(true);
    // let currentFeatureList = featureListAry;
    const [featureList,setFeatureList] = useState([]);
    let history = useHistory();
    const returnFeatureTypeId = {
        'suite': 'bb376294-23b1-4fc7-898f-6465d1a6fb35',
        'converter': 'bd886fa8-c449-4570-9b05-04809681d24b',
        'server': '405aa1dc-f56f-4201-a39c-546bba0f6b1b',
        'sdk': 'ba6783f8-c3f2-42ad-857b-4458fa970659',
        'pc': '50645424-a5af-499b-821e-d33c2e454e2b',
        'app': 'aa305111-b83a-47d2-b1f5-9a849e3f500f',
        'mac': 'a54a6bde-9b24-4247-9df5-1423f3e267fb'
    }
    const returnFeatureLink = {
        "bb376294-23b1-4fc7-898f-6465d1a6fb35":linkObj.product.suite.path,
        "bd886fa8-c449-4570-9b05-04809681d24b":linkObj.product.converter.path,
        "405aa1dc-f56f-4201-a39c-546bba0f6b1b":linkObj.product.server.path,
        "50645424-a5af-499b-821e-d33c2e454e2b":linkObj.product.reader.pc.path,
        "aa305111-b83a-47d2-b1f5-9a849e3f500f":linkObj.product.reader.app.path,
        "ba6783f8-c3f2-42ad-857b-4458fa970659":linkObj.product.sdk.path,
        'a54a6bde-9b24-4247-9df5-1423f3e267fb':linkObj.product.macOS.path
    };
    const typeID =  returnFeatureTypeId[featureType];
    const getLang = (cLang) => {
        let lang = 'EN';
        if(cLang === 'zh-TW'){
            lang = 'CT';
        }else if(cLang === 'zh-CN'){
            lang = 'CS';
        }else{
            lang = cLang.toUpperCase();
        }
        return lang;
    }

    useEffect(() => {
        const fetchResource = () => {
            getFeatureList({LgeID:getLang(currentLang.type), TypeID:typeID, PageIndex:0, PageSize:100}).then(function (response) {
                dispatch({
                    type:'SEND_FEATURELIST',
                    featureListAry:response.data.List
                })
                setFeatureList(response.data.List);
            })
            .catch(function (error) {
                console.log('err',error);
            });
        }

        fetchResource(currentLang.type);
    }, [currentLang.type,featureType,dispatch,typeID]);

    useEffect(() => {
        const featureContentView = (data) => {
            let view = '';
            if(data.Status === "3"){//||data.IsMore !== "1"
                alert('404 Error');
                history.push(`/${currentLang.type}`);
            } else{
                view = data.List && data.List.length > 0 ? data.List.map((e,i)=>{
                
                    if(e.Class === 'C11') {
                        return ('<img src="' + e.Content + '" class="content-img" />');
                    } else if (e.Class === 'C31') {
                        return ('<iframe width="100%" height="600px" src="' + e.Content + '" frameborder="0" allowfullscreen></iframe>');
                    }
                    
                    return e.Content;
                    ;
                }) : '';
                document.getElementById("content-detail").innerHTML = view;
            }
        }
        getFeatureContent({LgeID:getLang(currentLang.type), ID:contentId}).then(function (response) {
            dispatch({
                type:'SEND_FEATURECONTENT',
                featureContent:response.data
            })
            setFeatureContent(response.data);
            featureContentView(response.data);
        })
        .catch(function (error) {
            console.log('err',error);
        });
    }, [currentLang.type,contentId,dispatch,history]);

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    const onClick = (id) => {
        setCurrentId(id);
    };


    
    const renderBannerImg = () => {
        if (props.featureType === 'suite') {
            return (<img src={require('../assets/img/feature/banner/bnr_icon_suite.png')} className="icon-img" alt=""></img>);
        } else if (props.featureType === 'converter') {
            return (<img src={require('../assets/img/feature/banner/bnr_icon_converter.png')} className="icon-img" alt=""></img>);
        } else if (props.featureType === 'server') {
            return (<img src={require('../assets/img/feature/banner/bnr_icon_server.png')} className="icon-img" alt=""></img>);
        } else if (props.featureType === 'pc') {
            return (<img src={require('../assets/img/feature/banner/bnr_icon_reader.png')} className="icon-img" alt=""></img>);
        } else if (props.featureType === 'app') {
            return (<img src={require('../assets/img/feature/banner/bnr_icon_reader_mobile.png')} className="icon-img" alt=""></img>);
        } else if (props.featureType === 'sdk') {
            return (<img src={require('../assets/img/feature/banner/bnr_icon_sdk.png')} className="icon-img" alt=""></img>);
        } else if (props.featureType === 'mac') {
            return (<img src={require('../assets/img/feature/banner/bnr_icon_suite.png')} className="icon-img" alt=""></img>);
        }
    }

    return (
        <section id="readmore" className="whiteBg paddingBottom200">
            <MetaTags>
                <title>{(featureContent.Name||'')+t('metaTag.readmore.title')}</title>
                <meta name="title" content={featureContent.Name + t('metaTag.readmore.title')} />
                <meta name="description" content={featureContent.Desc} />
                <meta name="keywords" content={t('metaTag.sdk.keywords')} />
                <meta property="og:title" content={featureContent.Name + t('metaTag.readmore.title')} />
                <meta property="og:description" content={featureContent.Desc} />
            </MetaTags>
            <div className="customContainer paddingWrap readmore-breadcrumb">
                <div className="cRow maxW1300 padding-0">
                    / {featureContent.TypeName}
                </div>
            </div>
            <div className="infoTitle grayBg-seashell">
                
                <div className="customContainer paddingWrap">
                    <div className="cRow maxW1300 padding-0">
                        <div className="icon-wrapper">
                            {renderBannerImg()}
                        </div>
                        <div className="text-wrapper">
                            <div className="name">{featureContent.Name}</div>
                            <div className="desc" dangerouslySetInnerHTML={{__html:featureContent.Desc}}></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="main-content customContainer paddingWrap">
                <div className="cRow maxW1300 padding-0">
                    <div className="left-content">
                        <div id="content-detail"></div>
                    </div>
                    <div className="right-menu">
                        <div>
                            <div className="typename">{featureContent.TypeName}</div>
                            {featureList.map((data,i)=>{
                                return (
                                <div className={["feature-list-title",currentId === data.ID?"active":""].join(' ')} key={data.ID}>
                                    <Link to={`/${currentLang.type}/suite/readmore/${data.ID}`}  onClick={onClick.bind(this,data.ID)}>
                                        <div className="b-text mb-3">{data.Name}</div>
                                    </Link>
                                </div>
                                )
                            })}
                        </div>
                        <Link to={`/${props.currentLang.type}${returnFeatureLink[returnFeatureTypeId[props.featureType]]}`} className="list-back">
                            <div className="back-img"></div>
                            <div className="back-text">{t('readmore.returnList')}</div>
                        </Link>
                    </div>
                    <Link to={`/${props.currentLang.type}${returnFeatureLink[returnFeatureTypeId[props.featureType]]}`} className="content-back">
                        <div className="back-img"></div>
                        <div className="back-text">{t('readmore.returnList')}</div>
                    </Link>
                </div>
            </div>
        </section>
    )
}


export default mixinWrapper(Readmore);