const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我的文电通在版本自动更新/升级失败后，软件也不见了？</title></head><body>  <h1>我的文电通在版本自动更新/升级失败后，软件也不见了？</h1>  <div>
<h2><strong>问题描述：</strong></h2>
</div>
<div>
<p>软件侦查到有新版本且自动升级后，升级失败，结果原本的软件不见了，重新安装旧版本软件又出现以下错误讯息：<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (11).png"></p>
<div>
<div>
<div>
<p> </p>
<h2><strong>原因：</strong></h2>
</div>
</div>
</div>
<div>
<p>有以下可能性： </p>
<ul>
<li>更新/升级时，系统上还有些程序正在执行中未关闭，导致部分档案无法删除（机率最大）</li>
<li>卸载不完全</li>
</ul>
<p class="guide-markup"> </p>
</div>
<div>
<div>
<div>
<div>
<h2 class="guide-markup"><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<h3 class="guide-markup">
<strong class="guide-markup"><span class="wysiwyg-color-blue130 "><strong>正确升级流程</strong></span></strong> </h3>
</div>
</div>
</div>
<div>
<ol>
<li>当系统侦测到有更新版本时，会自动跳出更新提示 &gt; 下载完成后点选「安装」：<img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (12).png" width="675" height="267">
</li>
<li>若显示以下提示讯息，请按照提示关闭所述应用程序后（一般都是文电通未关闭），点选「重试」，并进入一般安装流程：<br><span class="wysiwyg-color-red"><strong>注意：若此时选择「取消」将会安装失败，原旧版文电通也会遭到移除<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip14 (2).png" alt="mceclip14 (2).png" width="403" height="269"><br></strong></span>
</li>
<li class="guide-markup">更新完成<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip7 (3).png" alt="mceclip7 (3).png" width="433" height="331">
</li>
<li class="guide-markup">可至 「帮助」 &gt; 「授权信息」 查看版本信息 <br><img src="https://store.rightpdf.com/images/rtsupport/mceclip8 (2).png" alt="mceclip8 (2).png" width="478" height="387">
</li>
</ol>
<p> </p>
<h3><strong class="guide-markup"><span class="wysiwyg-color-blue130 "><strong>安装失败之解决办法</strong></span></strong></h3>
<ol>
<li>重新启动；</li>
<li>删除文件夹 </li>
</ol>
<ul>
<li>Right PDF: (<span class="wysiwyg-color-pink110">C:\\Program Files (x86)\\ZEON\\Right PDF\\Right PDF Pro\\bin</span>)</li>
<li>
<span class="wysiwyg-color-pink110 guide-markup"><font color="#2f3941">Gaaiho PDF: (</font></span><span class="guide-markup wysiwyg-color-pink110">C:\\Program Files (x86)\\Zeon\\Gaaiho\\Gaaiho PDF Suite\\bin</span>)</li>
</ul>
<ol>
<li style="list-style-type: none;">
<ol>
<li>如果旧版软件已被移除，最简单的方式就是<a href="https://store.rightpdf.cn/download/archive.aspx" target="_self" rel="undefined">直接至官网下载连结</a>，找到欲升级的版本后，下载安装：<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip4 (3).png" alt="mceclip4 (3).png"><br><br>
</li>
<li>由于您在更新时，已经下载过了一次更新档，此时，您也可以至以下路径，找到更新程序，双击来重新安装；</li>
</ol>
</li>
</ol>
<ul>
<li>Right PDF: (C:\\ProgramData\\Zeon\\RightPDF\\Update\\Right PDF Pro)</li>
<li>Gaaiho PDF: (C:\\ProgramData\\Zeon\\Gaaiho\\GaaihoUpdate\\Gaaiho PDF Suite)</li>
</ul>
<p> </p>
<ol>
<li style="list-style-type: none;">
<ol>
<li>如果保有旧版安装包，您也可以选择手动安装回原来旧版本，至「说明」&gt;「检查更新」重新操作 <span class="wysiwyg-color-blue120"><strong>「正确升级流程」</strong></span><br><img src="https://store.rightpdf.com/images/rtsupport/mceclip9.png" alt="mceclip9.png">
</li>
</ol>
</li>
</ol>
<p>如果以上步骤无法解决问题，请联系我们: <strong><a href="mailto:support@rightpdf.com" target="_self" rel="undefined">support@rightpdf.com</a> </strong>，将由专人为您服务！</p>
</div>
</div>
</div></body></html>`;   export default html;