import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MetaTags from 'react-meta-tags';

const CaseStudies = props => {
    const {t,currentLang} = props;  //eslint-disable-line no-unused-vars
    const  caseList = [
        {
            title:t('caseStudies.case1.title'),
            info:t('caseStudies.case1.info'),
            link:'/About/caseStudies/case1'
        },
        {
            title:t('caseStudies.case2.title'),
            info:t('caseStudies.case2.info'),
            link:'/About/caseStudies/case2'
        },
        {
            title:t('caseStudies.case3.title'),
            info:t('caseStudies.case3.info'),
            link:'/About/caseStudies/case3'
        },
        {
            title:t('caseStudies.case4.title'),
            info:t('caseStudies.case4.info'),
            link:'/About/caseStudies/case4'
        }
    ]
    const renderLi = () => {
        const returnLi = caseList.map((e,i)=>{
            return (
                <li key={i}>
                    <img src={require(`../../assets/img/caseStudies/logo${i+1}.png`)} className="img-fluid" alt="logoImg" />
                    <h3 className="font-weight-bold title">{e.title}</h3>
                    <p className="info">{e.info}</p>
                    <Link to={`/${props.currentLang.type}${e.link}`} className="greenlink">
                        {t('home.customers.btn')}
                        <FontAwesomeIcon  icon="chevron-right" className="fa-sm mr-2 ml-2"/>
                    </Link>
                </li>
            )
        });
        return returnLi;
    }
    return (
        <div className="caseStudiesContent">
            <MetaTags>
                <title>{t('metaTag.caseStudies.title')}</title>
                <meta name="title" content={t('metaTag.caseStudies.title')} />
                <meta name="description" content={t('metaTag.caseStudies.desc')} />
                <meta name="keywords" content={t('metaTag.caseStudies.keywords')} />
                <meta property="og:title" content={t('metaTag.caseStudies.title')} />
                <meta property="og:description" content={t('metaTag.caseStudies.desc')} />
            </MetaTags>
            <ul className="caseStudiesList" style={{paddingBottom:'180px'}}>
                {renderLi()}
            </ul>
        </div>
    )
}

export default CaseStudies;