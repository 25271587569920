import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MetaTags from 'react-meta-tags';

const CaseStudiesDetail = props => {
    const {t,linkObj,currentLang,pageId} = props;
    
    const caseDetailObj = {
        case1:{
            infolist1:[
                t('caseStudies.case1.detail.infoListInfo1'),
                t('caseStudies.case1.detail.infoListInfo2'),
                t('caseStudies.case1.detail.infoListInfo3'),
                t('caseStudies.case1.detail.infoListInfo4'),
                t('caseStudies.case1.detail.infoListInfo5')
            ],
            infolist2:[
                t('caseStudies.case1.detail.infoListInfo6'),
                t('caseStudies.case1.detail.infoListInfo7')
            ],
            summary:[
                t('caseStudies.case1.detail.summary.t1'),
                t('caseStudies.case1.detail.summary.t2')
            ],
            introductionAry:[
                {
                    title:t('caseStudies.case1.detail.infoBlock1.title'),
                    content:'<p>'+t('caseStudies.case1.detail.infoBlock1.t1').split("_")[0]+' <strong class="font-weight-bold">'+t('caseStudies.case1.detail.infoBlock1.t1').split("_")[1]+'</strong>'+t('caseStudies.case1.detail.infoBlock1.t1').split("_")[2]+' <strong class="font-weight-bold">'+t('caseStudies.case1.detail.infoBlock1.t1').split("_")[3]+'</strong> </p><p>'+t('caseStudies.case1.detail.infoBlock1.t2')+'</p><br/> <ul> <li>'+t('caseStudies.case1.detail.infoBlock1.t3')+'</li><li>'+t('caseStudies.case1.detail.infoBlock1.t4')+'</li><li>'+t('caseStudies.case1.detail.infoBlock1.t5')+'</li><li>'+t('caseStudies.case1.detail.infoBlock1.t6')+'</li></ul>'
                    
                },
                {
                    title:t('caseStudies.case1.detail.infoBlock2.title'),
                    content:'<p>'+t('caseStudies.case1.detail.infoBlock2.t1')+' </p><br/> <strong class="font-weight-bold">'+t('caseStudies.case1.detail.infoBlock2.t2')+'</strong> <p>'+t('caseStudies.case1.detail.infoBlock2.t3')+'</p><br/> <strong class="font-weight-bold"> '+t('caseStudies.case1.detail.infoBlock2.t4')+' </strong> <p>'+t('caseStudies.case1.detail.infoBlock2.t5')+'</p><br/> <strong class="font-weight-bold">'+t('caseStudies.case1.detail.infoBlock2.t6')+'</strong> <p>'+t('caseStudies.case1.detail.infoBlock2.t7')+'</p>'
                },
                {
                    title:t('caseStudies.case1.detail.infoBlock3.title'),
                    content:'<p>'+t('caseStudies.case1.detail.infoBlock3.t1')+'</p><div class="text-center imgBlock"> <img src="'+require(`../../assets/img/caseStudies/case1_img_${currentLang.type}.png`)+'" class="img-fluid" alt="caseImg"/> </div>'
                },
                {
                    title:t('caseStudies.case1.detail.infoBlock4.title'),
                    content:'<p>'+t('caseStudies.case1.detail.infoBlock4.t1')+'</p><p>'+t('caseStudies.case1.detail.infoBlock4.t2').split("_")[0]+'<strong class="font-weight-bold">'+t('caseStudies.case1.detail.infoBlock4.t2').split("_")[1]+'</strong></p>'
                },
                {
                    title:t('caseStudies.case1.detail.infoBlock5.title'),
                    content:'<p>'+t('caseStudies.case1.detail.infoBlock5.t1')+'</p>'
                }
            ]


        },
        case2:{
            infolist1:[
                t('caseStudies.case2.detail.infoListInfo1'),
                t('caseStudies.case2.detail.infoListInfo2'),
                t('caseStudies.case2.detail.infoListInfo3'),
                t('caseStudies.case2.detail.infoListInfo4')
            ],
            infolist2:[
                t('caseStudies.case2.detail.infoListInfo5')
            ],
            summary:[
                t('caseStudies.case2.detail.summary.t1'),
                t('caseStudies.case2.detail.summary.t2')
            ],
            introductionAry:[
                {
                    title:t('caseStudies.case2.detail.infoBlock1.title'),
                    content:'<p>'+t('caseStudies.case2.detail.infoBlock1.t1')+'</p><br/> <br/> <p>'+t('caseStudies.case2.detail.infoBlock1.t2')+'</p><p>'+t('caseStudies.case2.detail.infoBlock1.t3')+'</p><br/> <p>'+t('caseStudies.case2.detail.infoBlock1.t4').split("_")[0]+'<strong class="font-weight-bold">'+t('caseStudies.case2.detail.infoBlock1.t4').split("_")[1]+'</strong> '+t('caseStudies.case2.detail.infoBlock1.t4').split("_")[2]+' <strong class="font-weight-bold">'+t('caseStudies.case2.detail.infoBlock1.t4').split("_")[3]+'</strong> </p><br/> <br/> <div class="text-center imgBlock"> <img src="'+require(`../../assets/img/caseStudies/case2_img_${currentLang.type}.png`)+'" class="img-fluid" alt="caseImg"/> </div>'
                },
                {
                    title:t('caseStudies.case2.detail.infoBlock2.title'),
                    content:'<p>'+t('caseStudies.case2.detail.infoBlock2.t1')+'</p><p>'+t('caseStudies.case2.detail.infoBlock2.t2')+'</p>'
                },
                {
                    title:t('caseStudies.case2.detail.infoBlock3.title'),
                    content:' <p>'+t('caseStudies.case2.detail.infoBlock3.t1')+'</p><div class="text-center imgBlock"> <img src="'+require(`../../assets/img/caseStudies/case2_img2_${currentLang.type}.png`)+'" class="img-fluid" alt="caseImg"/> </div>'
                }
            ]
        },
        case3:{
            infolist1:[
                t('caseStudies.case3.detail.infoListInfo1'),
                t('caseStudies.case3.detail.infoListInfo2'),
                t('caseStudies.case3.detail.infoListInfo3'),
                t('caseStudies.case3.detail.infoListInfo4')
            ],
            infolist2:[
                t('caseStudies.case3.detail.infoListInfo5')
            ],
            summary:[
                t('caseStudies.case3.detail.summary.t1'),
                t('caseStudies.case3.detail.summary.t2')
            ],
            introductionAry:[
                {
                    title:t('caseStudies.case3.detail.infoBlock1.title'),
                    content:'<p>'+t('caseStudies.case3.detail.infoBlock1.t1').split("_")[0]+'<strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock1.t1').split("_")[1]+'</strong>'+t('caseStudies.case3.detail.infoBlock1.t1').split("_")[2]+'<strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock1.t1').split("_")[3]+'</strong></p><p>'+t('caseStudies.case3.detail.infoBlock1.t2')+'</p>'
                },
                {
                    title:t('caseStudies.case3.detail.infoBlock2.title'),
                    content:'<p>'+t('caseStudies.case3.detail.infoBlock2.t1')+'</p>'
                },
                {
                    title:t('caseStudies.case3.detail.infoBlock3.title'),
                    content:' <ul> <li>'+t('caseStudies.case3.detail.infoBlock3.t1')+'</li><li>'+t('caseStudies.case3.detail.infoBlock3.t2')+'</li></ul> <br/> <br/> <p>'+t('caseStudies.case3.detail.infoBlock3.t3').split("_")[0]+'<strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock3.t3').split("_")[1]+'</strong>'+t('caseStudies.case3.detail.infoBlock3.t3').split("_")[2]+'<strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock3.t3').split("_")[3]+'</strong> </p><p>'+t('caseStudies.case3.detail.infoBlock3.t4')+'</p><div class="text-center imgBlock"> <img src="'+require(`../../assets/img/caseStudies/case3_img_${currentLang.type}.png`)+'" class="img-fluid" alt="caseImg"/> </div>'
                },
                {
                    title:t('caseStudies.case3.detail.infoBlock4.title'),
                    content:'<ul> <li>'+t('caseStudies.case3.detail.infoBlock4.t1')+'</li><li>'+t('caseStudies.case3.detail.infoBlock4.t2')+'</li></ul> <br/> <br/> <p>'+t('caseStudies.case3.detail.infoBlock4.t3')+'</p><p> '+t('caseStudies.case3.detail.infoBlock4.t4').split("_")[0]+' <strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock4.t4').split("_")[1]+'</strong> '+t('caseStudies.case3.detail.infoBlock4.t4').split("_")[2]+'<strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock4.t4').split("_")[3]+'</strong> </p>'
                },
                {
                    title:t('caseStudies.case3.detail.infoBlock5.title'),
                    content:'<ul> <li>'+t('caseStudies.case3.detail.infoBlock5.t1')+'</li><li>'+t('caseStudies.case3.detail.infoBlock5.t2')+'</li></ul> <br/> <br/> <p>'+t('caseStudies.case3.detail.infoBlock5.t3').split("_")[0]+'<strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock5.t3').split("_")[1]+'</strong>'+t('caseStudies.case3.detail.infoBlock5.t3').split("_")[2]+'<strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock5.t3').split("_")[3]+'</strong></p>'
                },
                {
                    title:t('caseStudies.case3.detail.infoBlock6.title'),
                    content:'<p>'+t('caseStudies.case3.detail.infoBlock6.t1').split("_")[0]+'<strong class="font-weight-bold">'+t('caseStudies.case3.detail.infoBlock6.t1').split("_")[1]+'</strong> '+t('caseStudies.case3.detail.infoBlock6.t1').split("_")[2]+'</p>'
                }
            ]
        },
        case4:{
            infolist1:[
                t('caseStudies.case4.detail.infoListInfo1'),
                t('caseStudies.case4.detail.infoListInfo2'),
                t('caseStudies.case4.detail.infoListInfo3'),
                t('caseStudies.case4.detail.infoListInfo4')
            ],
            infolist2:[
                t('caseStudies.case4.detail.infoListInfo5')
            ],
            summary:[
                t('caseStudies.case4.detail.summary.t1'),
                t('caseStudies.case4.detail.summary.t2')
            ],
            introductionAry:[
                {
                    title:t('caseStudies.case4.detail.infoBlock1.title'),
                    content:' <p> <strong class="font-weight-bold">'+t('caseStudies.case4.detail.infoBlock1.t1')+'</strong> </p><p>'+t('caseStudies.case4.detail.infoBlock1.t2')+'</p><br/> <p> <strong class="font-weight-bold">'+t('caseStudies.case4.detail.infoBlock1.t3')+'</strong> </p><p>'+t('caseStudies.case4.detail.infoBlock1.t4')+'</p><br/> <p> <strong class="font-weight-bold">'+t('caseStudies.case4.detail.infoBlock1.t5')+'</strong> </p><p>'+t('caseStudies.case4.detail.infoBlock1.t6')+'</p><br/>'
                },
                {
                    title:t('caseStudies.case4.detail.infoBlock2.title'),
                    content:' <p>'+t('caseStudies.case4.detail.infoBlock2.t1')+'</p><div class="text-center imgBlock"> <img src="'+require(`../../assets/img/caseStudies/case4_img_${currentLang.type}.png`)+'" class="img-fluid" alt="caseImg"/> <p class="mt-2">'+t('caseStudies.case4.detail.infoBlock2.t2')+'</p></div>'
                },
                {
                    title:t('caseStudies.case4.detail.infoBlock3.title'),
                    content:' <p> <strong class="font-weight-bold">'+t('caseStudies.case4.detail.infoBlock3.t1')+'</strong> </p><p>'+t('caseStudies.case4.detail.infoBlock3.t2')+'</p><br/> <br/> <p> <strong class="font-weight-bold">'+t('caseStudies.case4.detail.infoBlock3.t3')+'</strong> </p><p>'+t('caseStudies.case4.detail.infoBlock3.t4')+'</p><br/> <br/> <p> <strong class="font-weight-bold">'+t('caseStudies.case4.detail.infoBlock3.t5')+'</strong> </p><p>'+t('caseStudies.case4.detail.infoBlock3.t6')+'</p>'
                }
            ]
        }

    }
    const renderDiscli = (listary,listIndex) => {
        let returnElement = null;
        if(listIndex === 1){
            returnElement = listary.map((e,i)=>{
               if(i === listary.length - 1){
                return <li key={i} style={{borderBottom:"1px solid #000",padding:"0 0 10px",marginBottom:"10px"}}>{e}</li>
               }else{
                return <li key={i}>{e}</li>
               }
            })
        }else if(listIndex === 2){
            returnElement = listary.map((e,i)=>{
                return <li key={i}>{e.split('_')[0]}<strong className="font-weight-bold">{e.split('_')[1]}</strong></li>
             })

        }
        return returnElement;
    }
    const renderContent = ()  => {
        let returnElement = null;
        returnElement = caseDetailObj[pageId].introductionAry.map((e,i)=>{
            return (
                <div key={i} className="d-100 mb-150 font-weight-light">
                    <h2 className="font-weight-bold mt-150 mb-5">{e.title}</h2>
                    <div dangerouslySetInnerHTML={{__html:e.content}}></div>
                </div>
            )
         })
         return returnElement;
    }
    return (
        <div className="caseStudiesContent text-size-16">
            <MetaTags>
                <title>{t(`metaTag.${pageId}.title`)}</title>
                <meta name="title" content={t(`metaTag.${pageId}.title`)} />
                <meta name="description" content={t(`metaTag.${pageId}.desc`)} />
                <meta name="keywords" content={t(`metaTag.${pageId}.keywords`)} />
                <meta property="og:title" content={t(`metaTag.${pageId}.title`)} />
                <meta property="og:description" content={t(`metaTag.${pageId}.desc`)} />
            </MetaTags>
            <div className="customContainer paddingWrap">
                <div className="cRow padding-0">
                    <div className="d-100">
                        <h1 className="mainTitle">{t('caseStudies.'+pageId+'.title')}</h1>
                    </div>
                    <div className="d-30 mobilefullWidth text-center">
                        <img src={require(`../../assets/img/caseStudies/${pageId}.png`)} className="img-fluid" alt="caseImg" />
                    </div>
                    <div className="d-65 mobilefullWidth">
                        <p className="font-weight-bold">{t('caseStudies.quickfact')}</p>
                        <ul  className="discList font-weight-light d-inline-block">
                            {renderDiscli(caseDetailObj[pageId].infolist1,1)}
                            {renderDiscli(caseDetailObj[pageId].infolist2,2)}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="customContainer grayBg-gallery">
                <div className="cRow padding-50 mtb-100">
                    <div className="d-33 text-center">
                        <img src={require(`../../assets/img/caseStudies/${pageId}_intr.png`)} className="img-fluid" alt="caseImg" />
                    </div>
                    <div className="d-65 font-weight-light">
                       {
                           caseDetailObj[pageId].summary.map((e,i)=>{
                            return <p key={i}>{e}</p>
                           })
                       }
                    </div>
                </div>
            </div>
            <div className="customContainer paddingWrap">
                <div className="cRow padding-0 mtb-100">
                    {
                        renderContent()
                    }
                    {/* download block */}
                    <div className={'d-100 downloadBlock mb-150 '+(pageId === 'case1'?'lightBlueBg-Foam':'greenBg-panache')}>
                        <p className="font-weight-bold">{t('caseStudies.relatedProduct')}</p>
                        <h1 className="font-weight-light">{t((pageId ==='case1'?'server':'suite')+'.name')}</h1>
                        <div className="btnGroup">
                            <a href={(pageId ==='case1')?linkObj.downloadLink.server.url():linkObj.downloadLink.suite.url()} target="_blank" rel="noopener noreferrer" className="cBtn w_text greenBg smallBtn">{t('home.compare.btn2')}</a>
                            <Link to={(pageId ==='case1')?`/${props.currentLang.type}${linkObj.product.server.path}`:`/${props.currentLang.type}${linkObj.product.suite.path}`} className="cBtn bg-white b_border smallBtn">
                                {t('caseStudies.moreinfo')}
                            </Link>
                        </div>
                    </div>
                    <div className="d-100">
                        <Link to={`/${props.currentLang.type}${linkObj.AboutZeon.linkAry[3].path}`} className="greenlink">
                            <FontAwesomeIcon  icon="chevron-left" className="fa-sm arrow mr-3"/>
                            {t('caseStudies.checkCaseList')}
                        </Link>
                    </div>
                    <div className='tips'>{t('tips')}</div>
                </div>
            </div>
        </div>
    )
}

export default CaseStudiesDetail;

