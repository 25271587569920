const initState = {
    show:false,
    popupContent:''
}

const popupReducer = (state = initState, action) => {
    switch(action.type){
        case 'CLOSE_POPUP':
          return state.show = false;
        case 'OPEN_POPUP':
            let newState = {
                show:true,
                popupContent:action.content
            }
            return {...state,...newState};
        default:
            return state
    }

}

export default popupReducer;