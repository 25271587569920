const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我无法反激活产品了，怎么办？</title></head><body>  <h1>我无法反激活产品了，怎么办？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。请记得更新您的网址与email地址。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><span><strong>问题描述：</strong></span></h2>
<p>无法反激活产品。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p><span>对于<strong>永久授权</strong>的用户而言，以下是几个会让您无法正常反激活产品(点击「反激活」按钮)的常见原因：</span></p>
<ol>
<li><span>悲剧性的电脑故障</span></li>
<li><span>未反激就升级/格式化/重装了操作系统</span></li>
<li>未反激就卸载了产品（文电通4以前之产品）</li>
</ol>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class=" wysiwyg-color-blue130">若没有妥善处理这个问题，受影响的授权份数将永远无法使用。不用担心，解决方法就在下面：</span></p>
<ol>
<li>
<h3><span class=" wysiwyg-color-blue130"><strong>最快的方法：<a href="https://support.rightpdf.com/hc/zh-cn/articles/200884858-%E5%A6%82%E4%BD%95%E5%9C%A8%E7%BA%BF%E6%B8%85%E9%99%A4%E6%BF%80%E6%B4%BB%E8%AE%B0%E5%BD%95-" target="_blank" rel="noopener">在线清除激活记录</a></strong></span></h3>
<p><span class=" wysiwyg-color-blue130">请遵照以上链接中的提示清空您的激活记录。在清除之后，原本正常的产品会在某个时间变成过期状态，而您必须重新激活一次。这个方法最适合只有少量授权或已经有大量授权无法使用的用户。如果您拥有的用户众多，请采用第二个解决方案。<br><strong><br><span class="wysiwyg-color-red">注意：<br></span></strong></span><span>1. 每个序列号只能执行「在线反激活」<strong>两次 。<br></strong></span><span>2. 在线反激活操作是不可逆的。如果您需要更多的帮助，请使用联系我们的支持队伍。<br><br></span></p>
</li>
<li>
<h3><span class=" wysiwyg-color-blue130"><strong>稍慢但同样有效：</strong><a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_blank" rel="noopener">联系客服</a></span></h3>
<span class=" wysiwyg-color-blue130">在<a href="https://support.rightpdf.com/hc/zh-cn" target="_self" rel="undefined">support.rightpdf.com</a>呈交一个客服请求，并附上受影响的序列号与简短的问题描述。在我们的上班时间内，您的这类问题通常会被快速地解决──当然，这要看该时段内的客服量。</span>
</li>
</ol>
<p><span> </span></p>
<p> </p>
<p> </p>
<p><br><br><br></p></body></html>`;   export default html;