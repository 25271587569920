import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MetaTags from 'react-meta-tags';

const ProductDocuments = props => {
    const {t,currentLang} = props;
    const productDocObj = {
        document:{
            name:t('resources.productDoc.productSpecs.name'),
            listAry:[
                // {
                    // title:t('resources.productDoc.productSpecs.block1.title'),
                    // info:t('resources.productDoc.productSpecs.block1.info'),
                    // img:'datasheet_suite_50_'+currentLang.type+'.jpg',
                    // file:currentLang.type === 'jp'?'GaaihoPDFSuite_4.1_Datasheet_jp':'GaaihoPDFSuite_5.0_Datasheet_'+currentLang.type,
                    // show:true
                // },
                {
                    img: 'RightPDF_brochure_'+currentLang.type+'.jpg',
                    title:t('resources.productDoc.productSpecs.block2.title'),
                    info:t('resources.productDoc.productSpecs.block2.info'),
                    list:[
                        {
                            btntext:t('resources.productDoc.readOnlineWindows'),
                            file:'RightPDF_Brochure_'+currentLang.type
                        },
                        {
                            btntext:t('resources.productDoc.readOnlineMac'),
                            file:'macBrochure_'+currentLang.type
                        }
                    ],
                    file:'RightPDF_Brochure_'+currentLang.type,
                    show:true
                },
                {
                    title:t('resources.productDoc.productSpecs.block3.title'),
                    info:t('resources.productDoc.productSpecs.block3.info'),
                    img: 'pdf6_acrobat_comparison_'+currentLang.type+'.jpg',
                    file:'RightPDF_6.0_AcrobatProDC_Comparison_'+currentLang.type,
                    show:true
                }
            ]
        },
        whitePaper:{
            name:t('resources.productDoc.whitePaper.name'),
            listAry:[
                {
                    title:t('resources.productDoc.whitePaper.block1.title'),
                    info:t('resources.productDoc.whitePaper.block1.info'),
                    img:'pdf_the_4th_force_zh-TW.png',
                    file:'PDF_The_Fourth_Force_CHT',
                    show:currentLang.type === 'zh-TW',
                    tofile:true
                },
                {
                    title:t('resources.productDoc.whitePaper.block2.title'),
                    info:t('resources.productDoc.whitePaper.block2.info'),
                    img: currentLang.type === 'zh-TW'|| currentLang.type === 'zh-CN'?'pdf_learning_guide_'+currentLang.type+'.png':'pdf_learning_guide_en.png',
                    file:'Unlock_Your_PDF_Potentials',
                    show:true
                },
                {
                    title:t('resources.productDoc.whitePaper.block3.title'),
                    info:t('resources.productDoc.whitePaper.block3.info'),
                    img:currentLang.type === 'zh-TW'|| currentLang.type === 'zh-CN'?'evaluation_guide_'+currentLang.type+'.jpg':'evaluation_guide_en.jpg',
                    file:'Know_Your_Choices_IT_Evaluation_Guide',
                    show:true
                },
                {
                    title:t('resources.productDoc.whitePaper.block4.title'),
                    info:t('resources.productDoc.whitePaper.block4.info'),
                    img:currentLang.type === 'zh-TW'|| currentLang.type === 'zh-CN'?'replacement_guide_'+currentLang.type+'.png':'replacement_guide_en.png',
                    file:'Is_Adobe_a_Must_for_You_by_Gaaiho',
                    show:true
                }
            ]
        }
    }
    
    
    const renderList = array => {
        return array.map((e,i)=>{
            if(e.show){
                return (
                    <li key={i}>
                        <img src={require(`../../assets/img/doc/${e.img}`)} className="img-fluid" alt="docImg" />
                        <h3 className="font-weight-bold title title_style">{e.title}</h3>
                        <p className="info">{e.info}</p>
                        {e.list&&e.list.length !== 0?<div className="arrayBtns">{renderBtns(e.list)}</div>:(<a href={require(`../../assets/productDoc/${e.file}.pdf`)} className="greenlink" target="_blank" rel="noopener noreferrer">
                            {t('resources.productDoc.readOnline')}
                            <FontAwesomeIcon  icon="chevron-right" className="fa-sm mr-2 ml-2"/>
                        </a>     )}
                                      
                    </li>
                )
            }else{
                return null;
            }
        })
    }
    const renderBtns = array => {
        return  array.map((e,i)=>{
                    return (<a key={i} href={require(`../../assets/productDoc/${e.file}.pdf`)} className="greenlink" target="_blank" rel="noopener noreferrer">
                        {e.btntext}
                        <FontAwesomeIcon  icon="chevron-right" className="fa-sm mr-2 ml-2"/>
                    </a>)
                })
    }
    const renderList1 = array => {
        return array.map((e,i)=>{
            if(e.show){
                return (
                    <li key={i}>
                        <img src={require(`../../assets/img/doc/${e.img}`)} className="img-fluid" alt="docImg" />
                        <h3 className="font-weight-bold title title_style">{e.title}</h3>
                        <p className="info">{e.info}</p>
                        
                        <a href={e.tofile?require(`../../assets/productDoc/${e.file}.pdf`):`/${currentLang.type}/Resources/${e.file}`} className="greenlink" target="_blank" rel="noopener noreferrer">
                            {t('resources.productDoc.readOnline')}
                            <FontAwesomeIcon  icon="chevron-right" className="fa-sm mr-2 ml-2"/>
                        </a>
                    </li>
                )
            }else{
                return null;
            }
        })
    }
    return (
        <div className="resourceContent">
            <MetaTags>
                <title>{t('metaTag.productDocuments.title')}</title>
                <meta name="title" content={t('metaTag.productDocuments.title')} />
                <meta name="description" content={t('metaTag.productDocuments.desc')} />
                <meta name="keywords" content={t('metaTag.productDocuments.keywords')} />
                <meta property="og:title" content={t('metaTag.productDocuments.title')} />
                <meta property="og:description" content={t('metaTag.productDocuments.desc')} />
            </MetaTags>
            <div className="productCompareInfo">
                <h2 className="title_style">{productDocObj.document.name}</h2>
            </div>
            <div className="pdListBlock" style={{paddingBottom:'120px'}}>
                <ul className="caseStudiesList li30">
                    {
                        renderList(productDocObj.document.listAry)
                    }
                </ul>
            </div>
            <div className="productCompareInfo">
           
                <h2>{productDocObj.whitePaper.name}</h2>
            
            </div>
            <div className="pdListBlock">
                <ul className="caseStudiesList li30">
                    {
                        renderList1(productDocObj.whitePaper.listAry)
                    }
                </ul>
            </div>
        </div>
    )
}

export default ProductDocuments;