import React from 'react';
import MetaTags from 'react-meta-tags';
const SuiteCompare = props => {
	const metaTagscontent = {
		title:'發揮PDF的無窮潛力 | 文電通 Right PDF',
		desc:'自1993年首次發表以來，可攜式文件格式PDF一直為滿足不同背景的使用者需求而不斷革新，至今已成最受政府單位與企業組織青睞的格式之一。'
	};
	const viewcontent =
		`<h1 id="p1">前言</h1>

<p>自1993年首次發表以來，可攜式文件格式PDF(Portable Document Format)一直為滿足不同背景的使用者需求而不斷革新，至今已成最受政府單位與企業組織青睞的格式之一。然而，即使眾多企業已將PDF立為工作流程中不可或缺的環結，多數人卻仍然不甚清楚PDF具備的優勢為何。</p>

<p>&nbsp;</p>

<div id="p13">
<p><strong>PDF的優點</strong></p>

<ol>
	<li>
	<p>高度的相容性</p>
	</li>
	<li>
	<p>精確的顯示與列印</p>
	</li>
	<li>
	<p>易於保全</p>
	</li>
	<li>
	<p>歸檔格式的首選</p>
	</li>
	<li>
	<p>可隨時閱讀</p>
	</li>
	<li>
	<p>減少檔案大小</p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<p class="marginB100">事實上，您無須成為專業的IT人員就能瞭解PDF為您的工作或企業所創造的價值。作為PDF的初學者，您只需要永遠記得幾個與PDF相關的關鍵字即可：簡單、準確、安全。同時您可以想像：所有檔案在任何裝置都能維持一致的文件格式，並且能夠兼顧搜尋、審閱、管理與安全，而這些還不是PDF全部的能耐。如果成千上萬的企業都已正在用PDF加強自身的競爭力，您也應該瞭解他們使用PDF的原因及方法。</p>

<h1 id="p2">PDF有那些使用者？</h1>

<p class="marginB100">早在西元2000年，美國聯邦法院即以PDF作為歸檔電子文件的格式。許多人從國稅局（IRS）或其他的政府機構，例如美國國家檔案館（US National Archives）或澳洲政府的SmartForm下載表單時便已接觸見過PDF。值得一提的是，歐盟更以PDF 1.7與PDF/A作為傳輸電子文件的標準交換格式。時至現今，美國聯邦政府仍然是最大的PDF技術使用者。2015年，台灣行政院即推動各機關網站提供下載的非可編輯文件一律採用PDF，2022年時更計畫將PDF/UA列為增進電子文件檔案可及性（accessibility）的國家標準，其重要性可見一斑。</p>

<h1 id="p3">為什麼使用PDF？</h1>

<p class="marginB100">現今無所不在的電子文件是提升PDF使用率的主要因素。在辦公室、協作者之間或網路中，可能有上百、上千個流傳往返的電子文件需要以相容的應用程式開啟，但卻無法完全避免因不同作業環境（例如使用舊版的軟體）而導致無法開啟文件的意外，還需進一步耗費額外的時間和精力才能解決問題；更有甚者，有心人士可能會從未受保護的文件取得敏感資訊，進而損害公司的權益。為了解決這些不利因素，以PDF作為分享和儲存電子文件的格式，長期而言能夠為企業節省非常可觀的成本。</p>

<h1 id="p4">他們如何使用PDF？</h1>

<p>以下是人們生活中經常碰到的六大挑戰以及PDF解決問題的方法：</p>

<p>&nbsp;</p>
<div class="box6Wrapper">
	<div class="box6">
	<p><strong>安全性</strong></p>

	<p>文件離開電腦或公司的網路之後，就無法確保文件安全</p>

	<p><img alt="01_Security" src="${require(`../../assets/img/resources/Group 1337.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>分享</strong></p>

	<p>難以分享大型檔案，收件者也可能沒有適合閱讀文件的應用程式</p>

	<p><img alt="02_Sharing" src="${require(`../../assets/img/resources/Group 1338.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>可攜性</strong></p>

	<p>使用者無法開啟或閱覽文件，造成溝通困難</p>

	<p><img alt="03_Portability" src="${require(`../../assets/img/resources/Group 1339.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>驗證</strong></p>

	<p>收件端經常發生顯示或版面配置錯誤的狀況</p>

	<p><img alt="04_Authentic" src="${require(`../../assets/img/resources/Group 1340.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>標準化</strong></p>

	<p>大量的檔案與多種檔案格式，增加了管理難度</p>

	<p><img alt="05_Standardization" src="${require(`../../assets/img/resources/Group 1341.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>審閱</strong></p>

	<p>審閱不同版本的文件耗費太多的時間與資源</p>

	<p><img alt="06_Review" src="${require(`../../assets/img/resources/Group 1342.svg`)}" /></p>
	</div>
</div>

<p>&nbsp;</p>

<p class="marginB100">轉換為PDF後，無論是以任何語言、任何作業系統（例如Windows、mac OS、Linux、UNIX、Android或iOS）都能維持原始文件的外觀與內容；然而其他格式的文件可能因為不相容的作業系統、缺少合適的語言包而無法正確顯示內容。PDF不但擁有卓越的獨立性，幾乎任何電腦也都具備了閱讀PDF的能力，讓您不用再擔心傳送後文件格式不正確的問題。舉例來說，您可以使用PDF/A子集合來歸檔需長期保存的電子文件。</p>

<h1 id="p5">建立和轉換PDF</h1>

<p>您只要按幾下滑鼠就能立即從檔案、影像、MS Office (Word、Excel、PowerPoint)、OpenOffice、MS Outlook建立PDF檔案，反之亦然。雖然不同的檔案格式各有專門用途，但論及傳送與儲存檔案，PDF不僅是建立安全的文件副本首選，而且操作上既快速又簡單。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>以下是迅速建立和轉換PDF的範例：</strong></p>

<ul>
	<li class="marginB10">
	<p>以PDF軟體建立PDF或從PDF轉換為別的格式（例如文電通PDF標準版）</p>
	</li>
	<li class="marginB10">
	<p>從MS Word、Excel、PowerPoint或其他支援列印的程式，透過列印的方式轉換為PDF</p>
	</li>
	<li class="marginB10">
	<p>使用MS Office增益集或另存為轉換目前的文件</p>
	</li>
	<li class="marginB10">
	<p>掃描文件為PDF</p>
	</li>
	<li class="marginB10">
	<p>從MS Outlook轉換電子郵件為PDF</p>
	</li>
	<li class="marginB10">
	<p>合併、重疊或包裝多個檔案為一個PDF文件</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p class="marginB100">建立PDF文件後，您便可以使用不同方式分享文件，例如電子郵件、雲端服務或企業網路；反之，則可以重新利用PDF文件中的內容，省去尋找原始文件或逐字輸入的麻煩。</p>

<h1 id="p6">與PDF互動</h1>

<p>在顯示與列印方面PDF已經是備受信賴的檔案格式，但它的另一項優點卻經常被忽視──互動式PDF。從嵌入音軌、視訊，到新增核取方塊與超連結，您擁有多種方式設計出比靜態文件更能加深閱讀者印象的出色PDF文件。相較於像MS Word等非專業的表單設計軟體，PDF表單能展現更出色的樣式，讓您不需要再為了相容性而犧牲精美的設計，正可謂兩全其美。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>例如，依據您選擇的PDF解決方案，可以在PDF文件中新增下列內容</strong></p>

<ul class="list2">
	<li class="marginB10">
	<p>超連結</p>
	</li>
	<li class="marginB10">
	<p>標記</p>
	</li>
	<li class="marginB10">
	<p>附件</p>
	</li>
	<li class="marginB10">
	<p>音訊檔案（如錄音）</p>
	</li>
	<li class="marginB10">
	<p>影片檔案</p>
	</li>
	<li class="marginB10">
	<p>文字附註</p>
	</li>
	<li class="marginB10">
	<p>按鈕</p>
	</li>
	<li class="marginB10">
	<p>核取方塊</p>
	</li>
	<li class="marginB10">
	<p>選項按鈕</p>
	</li>
	<li class="marginB10">
	<p>下拉式方塊</p>
	</li>
	<li class="marginB10">
	<p>文字欄位</p>
	</li>
	<li class="marginB10">
	<p>條碼</p>
	</li>
</ul>
</div>

<div class="box1 marginB100">
<p><strong>互動式表單還有這些絕佳的用途：</strong></p>

<ul>
	<li class="marginB10">
	<p>使用PDF閱讀器填寫表單並以電子郵件回傳，不必再使用紙本</p>
	</li>
	<li class="marginB10">
	<p>附加文件至PDF以便延伸閱讀</p>
	</li>
	<li class="marginB10">
	<p>插入錄音或影片，強化您的論點</p>
	</li>
	<li class="marginB10">
	<p>為PDF加上超連結，讓閱讀者能夠快速一點連至您指定的網站</p>
	</li>
	<li class="marginB10">
	<p>匯入和匯出表單資料</p>
	</li>
</ul>
</div>

<h1 id="p7">安全性</h1>

<p>PDF是非常重視文件安全性的檔案格式，能夠使用密碼、浮水印或加密工具確保文件安全。由於行動裝置的普及與日趨嚴謹的隱私規範，未受保護的資料所潛藏的資安風險也與日俱增；這些敏感資料可能白紙黑字寫在文件之上，或隱身於不為人知的中繼資料，隨時可能成為安全的漏洞。為了規避潛在的非法利用與損失，文件都必須要經過妥善加密，方能傳到外部人員手上。好消息是，PDF具備許多專業工具，能協助您抵禦文件可能造成的資安危機。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>以下是保護PDF文件的幾種方式：</strong></p>

<ul>
	<li class="marginB10">
	<p><strong>開啟或權限密碼</strong>：設定開啟密碼讓授權的使用者才能開啟，或以權限密碼控制使用者能夠操作的功能，例如列印、複製、加上註解或擷取頁面。</p>
	</li>
	<li class="marginB10">
	<p><strong>浮水印、頁首和頁尾</strong>：在頁面放置圖示、公司標誌、浮水印和影像以強化視覺並增加可信度。</p>
	</li>
	<li class="marginB10">
	<p><strong>檢查文件</strong>：發布文件前先移除內含的內層資訊，避免洩漏損害企業或違反個人隱私權。</p>
	</li>
	<li class="marginB10">
	<p><strong>電子憑證</strong>：使用加密與驗證電子簽章的機制，只允許收件者閱覽文件內容並能確認文件出於您手。</p>
	</li>
	<li class="marginB10">
	<p><strong>密文工具/立可白</strong>：手動標記並永久移除文件內的敏感資訊。</p>
	</li>
	<li class="marginB10">
	<p><strong>Microsoft AIP（Azure Information Protection）</strong>：企業可使用支援AIP的PDF文電通，確保文件只有授權人員才能存取與修改。</p>
	</li>
	<li class="marginB10">
	<p><strong>動態圖章</strong>：動態圖章包含各項詳細資訊，例如作者、時間/日期以及電子郵件地址，能夠提升審核流程的效率。</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p class="marginB100">文件的作者和參與者最想確認的不外乎是讓只有授權的人才能打開文件，而藉由PDF擁有的安全性功能，您可以輕鬆、自信的處理工作或生活中各式各樣的文件。</p>

<h1 id="p8">審閱文件</h1>

<p>審閱文件的方式有兩種：一種是套用數位簽章以確保資料的完整性與寄件者的身分；另一種則是使用註解功能，使團隊能快速製作文件的最終版本。對追求降低紙本依賴或增進工作效率的企業而言，都是取代傳統方式的最佳方案。</p>

<p>&nbsp;</p>

<h2 class="marginB20">數位簽章</h2>

<p>數位簽章正在逐漸取代傳統的油墨簽名。不論是審閱文件或簽署合約，這類工作通常需要從不同位置或人員反覆收集簽名，無形中耗費大量時間與紙本。電子簽名簡化了企業身分驗證的過程，而且結合PDF之後更不只加上了安全性，更適合用於歸檔，以及建立具法律效力的電子簽章。</p>

<p>您可以允許使用者簽署PDF但限制對方變更內容，例如簽署合約。若頁面遭到篡改，數位簽章將指出修改的部份，而您也可以比較目前簽署的版本與之前簽署的版本差異。</p>

<p>&nbsp;</p>
<div class="part2Wrapper textAlignL">
	<div class="box1 box2">
	<p><strong>紙本簽章</strong></p>

	<ol>
		<li class="marginB10">
		<p>經由電子郵件收到文件（以合約為例）</p>
		</li>
		<li class="marginB10">
		<p>儲存合約在資料夾</p>
		</li>
		<li class="marginB10">
		<p>列印和簽署合約</p>
		</li>
		<li class="marginB10">
		<p>掃描合約</p>
		</li>
		<li class="marginB10">
		<p>儲存掃描的合約副本於電腦</p>
		</li>
		<li class="marginB10">
		<p>傳送簽署的合約給寄件者</p>
		</li>
	</ol>
	</div>

	<div class="box1">
	<p><strong>數位簽章</strong></p>

	<ol>
		<li class="marginB10">
		<p>開啟合約</p>
		</li>
		<li class="marginB10">
		<p>在合約內放置您的簽名</p>
		</li>
		<li class="marginB10">
		<p>儲存合約至電腦</p>
		</li>
		<li class="marginB10">
		<p>傳送簽署的合約給寄件者</p>
		</li>
	</ol>
	</div>
</div>
<p>&nbsp;</p>

<h2 class="marginB20">使用註解進行審閱</h2>

<p class="marginB70">與傳統的審閱流程截然不同，PDF能夠使審閱的過程更具互動性，且更能呼應環保訴求。常見的解決方案通常需要某種程度的系統革新與資料庫整合，不僅耗時耗力，還需要考慮安裝之後的長遠維護問題。相反的，PDF非只提供了熟悉的審閱工具如醒目提示、附註和刪除線，更讓使用者能分享註解並追蹤審閱狀態──而這些技能都能輕鬆學會，無需任何IT人員的協助。</p>

<p style="text-align:center"><img alt="Use annotations for review" src="${require(`../../assets/img/resources/use_annotations for_review_tw.jpg`)}" /></p>

<div class="box1 marginB100">
<p><strong>您可以使用這些功能來進行審閱：</strong></p>

<ul>
	<li class="marginB10">
	<p>以附註、打字機、文字方塊、醒目提示、插入文字或鉛筆等註解工具標記PDF</p>
	</li>
	<li class="marginB10">
	<p>附加其他文件或聲音檔案至PDF</p>
	</li>
	<li class="marginB10">
	<p>追蹤附註的建立者</p>
	</li>
	<li class="marginB10">
	<p>建立註解小結並自訂版面配置、大小和頁面範圍</p>
	</li>
	<li class="marginB10">
	<p>套用浮水印和圖章，並可設定旋轉的角度、透明度和內容</p>
	</li>
	<li class="marginB10">
	<p>開啟註解面板列出所有註解，易於檢視與追蹤</p>
	</li>
	<li class="marginB10">
	<p>使用比較文件功能標示出版本之間的差異</p>
	</li>
</ul>
</div>

<h1 id="p9">壓縮檔案</h1>

<p>PDF盛行的其中一項關鍵因素在於減少檔案大小的同時也保持品質。像是簡報或現場報告這類包含大量影像的文件，極容易面臨儲存和傳送上的挑戰。以維修報告為例，技術人員需要在報告中彙整大量的圖片，但龐大的檔案大小卻影響了寄送。透過PDF減少檔案大小的功能，檔案可以縮減至適合的大小，更易於分享、歸檔與組織檔案。</p>

<p>&nbsp;</p>

<div class="box1 marginB100">
<p><strong>減少檔案大小的方式</strong></p>

<ul>
	<li class="marginB10">
	<p>使用轉換器或虛擬印表機轉換為PDF，例如PDF文電通專業版和Right PDF印表機，而兩者均提供設定壓縮等級的選項。</p>
	</li>
	<li class="marginB10">
	<p>以減少檔案大小功能進一步壓縮文件內的影像。您可以指定減少等級並套用壓縮設定至多個文件。</p>
	</li>
	<li class="marginB10">
	<p>使用「檢查文件」功能移除不必要的資料，例如書籤、註解、連結或附件。</p>
	</li>
</ul>
</div>

<h1 id="p10">易於存取和編輯</h1>

<p>PDF的P代表可攜性，可攜性意味著網頁瀏覽器或PDF閱讀器都能正確顯示文件內容。工作中使用多種檔案格式最常發生的問題，即收件者可能沒有相同版本的應用程式能夠閱讀檔案，並使得額外的來回溝通耗費更多的時間。因此，按幾個按鈕快速建立PDF後就能防止後續的複雜問題，可謂極具效益的作法。您甚至可以保護PDF，避免其他人竊取內容而損害了您的權益。</p>

<p>另一項非常重要的協助工具功能則是PDF標籤，作用是在行動裝置上支援更快的閱讀文件，並協助殘障使用者閱讀文件。大致上的說，標籤代表文件的架構，比如說將文字區分為標題與書籤兩類。PDF標籤的優點還有行動裝置上的「文字回流」、更可靠的內容擷取並易於搜尋引擎偵測。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>主要重點</strong></p>

<ul>
	<li class="marginB10">
	<p>輔助技術（AT）的使用者能利用PDF標籤以更方便的閱讀文件</p>
	</li>
	<li class="marginB10">
	<p>更易於在平板、智慧型手機等裝置上檢視PDF</p>
	</li>
	<li class="marginB10">
	<p>PDF標籤加快了調閱文件的過程，提升歸檔的效率</p>
	</li>
	<li class="marginB10">
	<p>將PDF轉換為其他格式時，標籤有助於增進轉換品質</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p>然而，作為一個理想的最終電子文件格式，PDF的盛名並非歸功於編輯能力，而多數的使用者也只視其為輸出文件的格式。在瞭解PDF的優點後，專業人士接下來可能的問題是：如何編輯PDF檔案？畢竟人非聖賢，文件上的錯誤有時難以避免。如果您擁有合適的PDF解決方案，即可全盤編輯PDF的內容方面，為您節省寶貴的時間。</p>

<p>&nbsp;</p>
<div class="part2Wrapper marginB100 part2Wrapper2 textAlignL">
	<div class="box1 box2">
	<p><strong>典型案例</strong></p>

	<ul>
		<li class="marginB10">
		<p>作者開啟/編輯Word文件並轉換為PDF</p>
		</li>
		<li class="marginB10">
		<p>為了編輯文件，收件者將PDF轉換為Word，可能發生版面格局變更、內容遺失，或需要向作者索取原始文件</p>
		</li>
		<li class="marginB10">
		<p>收件者再次將文件轉換為PDF。其他的收件者或協作者可能要再次重複相同步驟</p>
		</li>
	</ul>
	</div>

	<div class="box1">
	<p><strong>使用合適的PDF軟體</strong></p>

	<ul>
		<li class="marginB10">
		<p>能夠直接在應用程式裡建立和編輯PDF，不用再重複轉換</p>
		</li>
		<li class="marginB10">
		<p>以熟悉的操作介面和豐富的文字處理功能編輯PDF內容，例如字型、編碼、對齊方式和樣式</p>
		</li>
		<li class="marginB10">
		<p>插入表格、美工圖案、連結、螢幕擷取畫面或設定邊界、頁面方向和縮排</p>
		</li>
	</ul>
	</div>
</div>

<h1 id="p11" class="marginB40">結論</h1>

<p>PDF聲名遠播但鮮少人知道它的優點：分享、安全性以及電子文件歸檔，比比皆是現今企業最關注的生產力核心元素。雖然不同的格式都有其特定的用途，PDF卻已經證明自身的穩定性與降低溝通失誤的能力。另外值得注意的是，PDF現在是由國際標準化組織（ISO）所管理，並以ISO 32000-1標準所發行的一項非專利技術，不再是Adobe Systems&copy;獨家擁有的檔案格式。這意味著您可以自由挑選合乎標準的PDF解決方案，而不必再受限於舊時代的壟斷產品。如欲一舉從根本提昇企業的競爭力，現在正是您做出改變的時候。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>PDF小常識：</strong></p>

<ul>
	<li class="marginB10">
	<p>&shy;Adobe於2008年釋出PDF的控制權</p>
	</li>
	<li class="marginB10">
	<p>&shy;PDF是由ISO多元化的審查委員所管理</p>
	</li>
	<li class="marginB10">
	<p>&shy;最近發展是PDF 2.0（ISO 32000-2:2017）標準，具有更多標準化的PDF技術</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p style="text-align:center;margin-bottom:30px;"><img src="${require(`../../assets/img/point.png`)}"/></p>

<h1 class="font20" style="text-align:center"><span style="color:#37915c">立即聯絡棣南，瞭解適合您企業的最佳PDF軟體!</span></h1>

<p style="text-align:center"><a href="https://www.rightpdf.com/zh-TW/contactUs" target="_blank" style="width:210px;height:60px;background:#087A4F;display:inline-block;font-size:17px;color:#fff;line-height:60px;border-radius:4px;margin-top:40px;">聯絡我們</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>
<div id="p37">
<p><strong>關於棣南</strong></p>

<p><strong>棣南股份有限公司正在改變使用者與PDF的互動方式。迥異於常見的PDF軟體，我們的產品理念側重「自由編輯」，令使用者不須經過繁複操作或尋找原始檔案，就能輕鬆創造、書寫與修改PDF文件。文電通集編輯、直覺式介面、豐富功能於一身，賦予全球專業人士和企業組織無比的效率、生產力與想像力。</strong></p>
</div>
<p>&nbsp;</p>

<ul style="padding-left:20px;">
	<li>Adobe, the Acrobat logo and Acrobat are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries.</li>
	<li>Microsoft and Windows are either registered trademarks of Microsoft Corporation in the United States and/or other countries. All other trademarks are the property of their respective owners.</li>
</ul>`;
return (<div>
	<MetaTags>
	   <title>{metaTagscontent.title}</title>
	   <meta name="title" content={metaTagscontent.title} />
	   <meta name="description" content={metaTagscontent.desc} />
	   <meta property="og:title" content={metaTagscontent.title} />
	   <meta property="og:description" content={metaTagscontent.desc} />
   </MetaTags>
   <div dangerouslySetInnerHTML={{__html:viewcontent}}></div>
</div>);
}
export default SuiteCompare;
