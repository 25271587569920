const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>文电通专业版(Gaaiho PDF Suite)里面的Gaaiho Doc有快捷键列表可以参考吗？</title></head><body>  <h1>文电通专业版(Gaaiho PDF Suite)里面的Gaaiho Doc有快捷键列表可以参考吗？</h1>  <ol>
<li>
<h2><strong>文件相关</strong></h2>
<p>打开文件:  Ctrl+O</p>
<p>新建空白PDF (另外快速访问工具栏 新建PDF -&gt; 空白 PDF 按钮也一样): Ctrl+N</p>
<p>保存文件:  Ctrl+S</p>
<p>文件另存为:  Shift+Ctrl+S</p>
<p>打印:  Ctrl+P</p>
<p>关闭文件:  Ctrl+W</p>
<p>文档属性:  Ctrl+D</p>
<p>首选项: Ctrl+K</p>
<p>退出程序:  Ctrl+Q</p>
<p>快速访问工具栏 新建PDF-&gt; 空工作簿 按钮: Ctrl+E<br><br><img src="https://store.rightpdf.com/images/rtsupport/340071.png" alt="340071.png"><br><strong>快速访问工具栏</strong></p>
</li>
<li>
<h2><strong>基本操作</strong></h2>
<p>复制:  Ctrl+C</p>
<p>剪切: Ctrl+X</p>
<p>粘贴: Ctrl+V</p>
<p>撤消: Ctrl+Z</p>
<p>重做: Ctrl+Y</p>
<p>查找: Ctrl+F</p>
<p>搜索: Ctrl+Shift+F</p>
<p>拼写检查: F7</p>
</li>
<li>
<h2><strong>视图相关</strong></h2>
<h3>(1)底部视图栏</h3>
<p>顺时针旋转视图:  Ctrl+Shift++</p>
<p>逆时针旋转视图: Ctrl + Shift + -</p>
<p>上一视图: Alt+左箭头</p>
<p>下一视图: Alt+右箭头</p>
<p>首页: Ctrl+Home</p>
<p>尾页: Ctrl+End</p>
<p>上一页: Ctrl+Page Up</p>
<p>下一页: Ctrl+Page Down</p>
<p>适合页面并一次显示一页: Ctrl+Alt+P</p>
<p>适合宽度并连续滚动页面: Ctrl+Alt+W</p>
<p>放大: Ctrl+-</p>
<p>缩小: Ctrl++</p>
<p>缩放到: Ctrl+M</p>
<p>实际大小: Ctrl+1</p>
<p>适合页面: Ctrl+0</p>
<p>适合宽度: Ctrl+2</p>
<p>适合可见: Ctrl+3</p>
<p>适合高度: Ctrl+4</p>
<p>全屏模式: Ctrl+L<br><br><img src="https://store.rightpdf.com/images/rtsupport/340072.png" alt="340072.png"></p>
<p><strong>底部视图栏</strong></p>
<h3>(2)功能区</h3>
<p>网格: Ctrl+U</p>
<p>对齐到网格: Ctrl+Shift+U</p>
<p>显示标尺: Ctrl+R</p>
<p>层叠窗口: Ctrl+Shift+J</p>
<p>水平平铺窗口: Ctrl+Shift+K</p>
<p>垂直平铺窗口: Ctrl+Shift+L</p>
<p>全部关闭: Ctrl+Shift+W</p>
<p>阅读模式: Ctrl+7</p>
<p>文本查看器: Ctrl+6</p>
<p>显示/隐藏菜单栏: F9 </p>
<p>最小化功能区: Ctrl+F1<br><br><img src="https://store.rightpdf.com/images/rtsupport/340073.png" alt="340073.png"></p>
<strong>视图功能区</strong>
</li>
<li>
<h2><strong>文档相关</strong></h2>
<p>提取: Ctrl+Shift+X</p>
<p>从文件插入:  Ctrl+Shift+I</p>
<p>插入空白页面: Ctrl+Shift+N</p>
<p>从剪贴板插入: Ctrl+Shift+B</p>
<p>旋转: Ctrl+Shift+R</p>
<p>删除: Ctrl+Shift+D</p>
<p>替换: Ctrl+Shift+P</p>
<p>裁剪: Ctrl+Shift+C<br><br><img src="https://store.rightpdf.com/images/rtsupport/340074.png" alt="340074.png"><br><strong>文档功能区</strong></p>
</li>
<li>
<h2><strong>其他</strong></h2>
<p>添加书签: Ctrl+B</p>
<p>全选: Ctrl+A</p>
<p>取消全选: Ctrl+Shift+A</p>
</li>
</ol></body></html>`;   export default html;