import React ,{useEffect,useState} from 'react';
// import {Link, animateScroll as scroll} from 'react-scroll'
import * as Scroll from 'react-scroll';
import MetaTags from 'react-meta-tags';
import { useParams } from 'react-router-dom';

const Guide = props => {
    const {t} = props;
    let ScrollLink = Scroll.Link;
    const { lang } = useParams();
    const [langstr, setlangstr] = useState('');
    useEffect(() => {
        var language = lang;
        if(lang === 'zh-TW'){
            language = 'tw'
        }
        if(lang === 'zh-CN'){
            language = 'cn'
        }
        setlangstr(language);
    }, [lang]);

    let content_t9;
    if(lang === 'kr'){
        content_t9 = <li>[마침(Finish)]을 클릭하여 [컬렉션에 소프트웨어 배포 마법사(Distribute Software to Collection Wizard)]를 완료합니다.<p/></li>;
    }

    return(
        <div className="guideContent text-size-16">
            <MetaTags>
                <title>{t('metaTag.guide.title')}</title>
                <meta name="title" content={t('metaTag.guide.title')} />
                <meta name="description" content={t('metaTag.guide.desc')} />
                <meta name="keywords" content={t('metaTag.guide.keywords')} />
                <meta property="og:title" content={t('metaTag.guide.title')} />
                <meta property="og:description" content={t('metaTag.guide.desc')} />
            </MetaTags>
            <div className="guideBlock font-weight-light">
                <p className="m-0">{t('tutorials.guideBlock1.info1')}</p>
                <p>{t('tutorials.guideBlock1.info2')}</p>
                <ul className="guideLinkList">
                    <li>
                        <ScrollLink to="block1" offset={-200} smooth={true} className="greenlink">
                        • {t('tutorials.guideBlock1.link1')}
                        </ScrollLink>
                    </li>
                    <li>
                        <ScrollLink to="block2" offset={-200} smooth={true} className="greenlink">
                        • {t('tutorials.guideBlock1.link2')}
                        </ScrollLink>
                    </li>
                </ul>
            </div>
            <div id="block1" className="guideBlock">
                <h3>- {t('tutorials.guideBlock1.link1')}</h3>
                <ul className="guideList font-weight-light">
                    <li>
                      <span>{t('tutorials.guideBlock2.list1.title')}</span>
                      <ul>
                            <li>
                                <p>{t('tutorials.guideBlock2.list1.t11').split('_')[0]}<a href="https://store.rightpdf.com/account/register.aspx" className="greenlink">{t('tutorials.guideBlock2.list1.t11').split('_')[1]}</a>{t('tutorials.guideBlock2.list1.t11').split('_')[2]}<a href="mailto:sales@rightpdf.com" className="greenlink">{t('tutorials.guideBlock2.list1.t11').split('_')[3]}</a>{t('tutorials.guideBlock2.list1.t11').split('_')[4]}</p>
                                <img src={langstr?require(`../../assets/img/guide/msi_${langstr}.png`):''} className="img-fluid " alt=""/>
                            </li>
                      </ul>
                    </li>
                    <li>
                        <span>{t('tutorials.guideBlock2.list2.title')}</span>
                        <ul>
                            <li>
                                <p>{t('tutorials.guideBlock2.list2.t1')}</p>
                                <img src={require(`../../assets/img/guide/ads01.jpg`)} className="img-fluid " alt=""/>
                                <br/>
                                <br/>
                                <br/>
                                <p style={{'color':'#DC3C00'}}>{t('tutorials.guideBlock2.list2.t1-1')}</p>
                                <img src={require(`../../assets/img/guide/ads02.jpg`)} className="img-fluid " alt=""/>
                                <br/>
                                <br/>
                                <br/>
                                
                            </li>
                            <li>
                                {t('tutorials.guideBlock2.list2.t2')}
                                <p/>
                                <img src={require(`../../assets/img/guide/ads03.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock2.list2.t3')}
                            </li>
                            <li>
                                {t('tutorials.guideBlock2.list2.t4')}
                                <p/>
                                <img src={require(`../../assets/img/guide/ads04.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock2.list2.t5')}
                            </li>
                            <li>
                                {t('tutorials.guideBlock2.list2.t6')}
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div id="block2" className="guideBlock">
                <h3>- {t('tutorials.guideBlock1.link2')}</h3>
                <ul className="guideList font-weight-light">
                    <li>
                        <span>{t('tutorials.guideBlock3.list1.title')}</span>
                        <ul>
                            <li>
                                {t('tutorials.guideBlock3.list1.t1')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms01.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list1.t2')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms02.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list1.t3')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms03.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list1.t4')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms04.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list1.t5')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms05.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>{t('tutorials.guideBlock3.list2.title')}</span>
                        <ul>
                            <li>
                                {t('tutorials.guideBlock3.list2.t1')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms06.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list2.t2')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms07.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list2.t3')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms08.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                 {t('tutorials.guideBlock3.list2.t4')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms09.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list2.t5')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms10.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list2.t6')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms11.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list2.t7')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms12.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>
                            <li>
                                {t('tutorials.guideBlock3.list2.t8')}
                                <p/>
                                <img src={require(`../../assets/img/guide/sms13.jpg`)} className="img-fluid " alt="" />
                                <br/>
                                <br/>
                                <br/>
                            </li>

                            {content_t9}

                            

                               
                             
                          

                            
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Guide;