import React,{ useEffect,useState} from 'react';
// import ReactDOM from 'react-dom';
//import { Link, useParams } from 'react-router-dom';
import { getManualSearch } from '../../api';
import {useDispatch} from 'react-redux';
const SearchComponent = props => {
    let { currentLang ,featureAry,t,changeShow,leftContent,searchInputEvent} = props;
    leftContent = leftContent || t("help.t6");
	const [showList,setShowList] = useState([]);
	const [list1,setList1] = useState([]);
	const [list2,setList2] = useState([]);
	const [searchNull,setSearchNull] = useState(false);
	const [isShow,setIsShow] = useState(false);
	const [currentPage,setCurrentPage] = useState(1);
	const [totalPage,setTotalPage] = useState(0);
	const [selectedTypeID,setSelectedTypeID] = useState('all');
	const [selectedTypeLeft,setSelectedTypeLeft] = useState('');
	const [inputValue,setInputVal] = useState('');
	const [keyWords,setKeyWords] = useState('');
	const [searchListShow,setSearchListShow] = useState(false);
    // const [featureAry, setFeatureAry] = useState([]);
	useEffect(() => {
        if(selectedTypeID === 'all'){
			if(list2[0]){
				setSelectedTypeLeft(list2[0].TypeID)
			}
		}else{
			setSelectedTypeLeft(selectedTypeID);
		}
    }, [selectedTypeID,list2]);
	useEffect(() => {
        let data = list1.filter((item,index)=>{
			return index>=10*(currentPage-1)&&index<10*currentPage;
		})
		setShowList(data);
    }, [currentPage,list1]);
	let clang = currentLang.type;
	let lang = 'EN';
	if(clang === 'zh-TW'){
		lang = 'CT';
	}else if(clang === 'zh-CN'){
		lang = 'CS';
	}else{
		lang = clang.toUpperCase();
	}
    const dispatch = useDispatch();
	
    const filterbtnClick = (e) => {
        var content2_left = document.getElementsByClassName('content2_left')[0];
        if (content2_left.getAttribute( 'class' ).indexOf( 'selected' ) > -1) {
            content2_left.classList.remove('selected');
        }else{
            content2_left.classList.add('selected');
        }
    }
    const searchFocus = () => {
    	if(!searchInputEvent){
    		setSearchListShow(true);
    	}
	}
	const searchBlur = () => {
		setTimeout(()=>{
			if(!searchInputEvent){
	    		setSearchListShow(false);
	    	}
		},200)
	}
	const searchTypeidAll = () => {
		setKeyWords(inputValue);
		getManualSearch({LgeID:lang,Keyword:inputValue,TypeID:''}).then(function (response) {
			if(response.data.List.length === 0){
				if(response.data.Result.length === 0){
					setSearchNull(true);
				}
				for(var i=0;i<response.data.Result.length;i++){
					if(parseInt(response.data.Result[i].Count) !== 0){
						sendSearch(response.data.Result[i].TypeID,"all");
						return;
					}
				}
			}
			dispatch({
				type:'SEND_FEATURELIST',
				searchListAry:response.data.List
			})
			response.data.List = highLightStr(response.data.List);
			setList1(response.data.List);
			setList2(response.data.Result);
			var totalPage = (response.data.List.length%10 === 0 ? parseInt(response.data.List.length/10) : parseInt(response.data.List.length/10) + 1);
			setTotalPage(totalPage);
			setCurrentPage(totalPage === 0?0:1);
		})
		.catch(function (error) {
			console.log('err',error);
		});
	
	}
	const changeC1C2 = (type) => {
		if(type === 1){
			changeShow(true);
			setIsShow(false);
		}else{
			changeShow(false);
			setIsShow(true);
		}
	}
	const changeRadio = (typeid) => {
		setSelectedTypeID(typeid);
		setSearchListShow(false);
		changeC1C2(inputValue === ""?1:2);
		if(typeid === 'all'){
			searchTypeidAll();
		}else{
			sendSearch(typeid);
		}
	}
	const changeRadioLeft = (typeid) => {
		setSelectedTypeID(typeid);
		sendSearch(typeid);
	}
	const sendSearch = (typeid,from) => {
		setKeyWords(inputValue);
		getManualSearch({LgeID:lang,Keyword:inputValue,TypeID:typeid}).then(function (response) {
			dispatch({
				type:'SEND_FEATURELIST',
				searchListAry:response.data.List
			})
			if(response.data.List.length === 0){
				setSearchNull(true);
			}else{
				setSearchNull(false);
			}
			response.data.List = highLightStr(response.data.List);
			setList1(response.data.List);
			setList2(response.data.Result);
			var totalPage = (response.data.List.length%10 === 0 ? parseInt(response.data.List.length/10) : parseInt(response.data.List.length/10) + 1);
			setTotalPage(totalPage);
			setCurrentPage(totalPage === 0?0:1);
			if(from === "all"){
				setSelectedTypeID(typeid);
			}
		})
		.catch(function (error) {
			console.log('err',error);
		});
	}
    const prevPage = () => {
		if(currentPage<=1){
			return;
		}
		var page = currentPage - 1;
		page = page<=0?0:page;
		setCurrentPage(page);
		if(document.documentElement && document.documentElement.scrollTop){
			document.documentElement.scrollTop = 70;
		}
		else if(document.body){
			document.body.scrollTop = 70;
		}
	}
    const nextPage = () => {
		if(currentPage>=totalPage){
			return;
		}
		var page = currentPage+1;
		page = page>=totalPage?totalPage:page;
		setCurrentPage(page);
		if(document.documentElement && document.documentElement.scrollTop){
			document.documentElement.scrollTop = 70;
		}
		else if(document.body){
			document.body.scrollTop = 70;
		}
	}
	const highLightStr = (list) => {
		let keyword = document.getElementById('search_content_text').innerHTML;
		/*list.filter((value,index) => {
			var pattern = new RegExp(keyword,"gi");
			value.Desc = value.Desc.length > 150 ? (value.Desc.substring(0,150) + "...") : (value.Desc);
			value.Name = value.Name.replace(pattern,"<span style='background-color:#E1FDC3;'>" + keyword + "</span>");
			value.Desc = value.Desc.replace(pattern,"<span style='background-color:#E1FDC3;'>" + keyword + "</span>");
		})*/
		var pattern = new RegExp(keyword,"gi");
		for(var i=0;i<list.length;i++){
			list[i].Desc = list[i].Desc.length > 150 ? (list[i].Desc.substring(0,150) + "...") : (list[i].Desc);
			list[i].Name = list[i].Name.replace(pattern,"<span style='background-color:#E1FDC3;'>" + keyword + "</span>");
			list[i].Desc = list[i].Desc.replace(pattern,"<span style='background-color:#E1FDC3;'>" + keyword + "</span>");
		}
		var divs = document.getElementsByClassName("search_list_li");
		for(var j=0;j<divs.length;j++){
			divs[j].style.display = "block";
		}
		return list;
	}
	const returnIndex = () => {
		if(window.location.href.indexOf("helpcontent") !== -1){
			window.location.href = window.location.href.split("helpcontent")[0]+"help";
		}else if(window.location.href.indexOf("support") !== -1){
			window.location.href = window.location.href.split("support")[0]+"support";
		}else{
			changeC1C2(1);
		}
	}
    const searchbtnClick = () => {
        var mobile_search = document.getElementsByClassName('mobile_search')[0];
        var mobile_searchbtn = document.getElementsByClassName('mobile_searchbtn')[0];
        if (mobile_search.getAttribute( 'class' ).indexOf( 'show' ) > -1) {
            return;
        }
        mobile_searchbtn.classList.remove('show');
        mobile_search.classList.add('show');
    }
    const mobilesearchbtnClick = () => {
        var mobile_search = document.getElementsByClassName('mobile_search')[0];
        var mobile_searchbtn = document.getElementsByClassName('mobile_searchbtn')[0];
        mobile_searchbtn.classList.add('show');
        mobile_search.classList.remove('show');
    }
    const searchcontent = (e) => {
        e = e || window.event;
		setInputVal(e.target.value);
		if(e.keyCode === 13) {
			if(searchInputEvent){
				searchInputEvent(e.target.value);
			}else{
				changeC1C2(e.target.value === ""?1:2);
				if(e.target.value === ""){
					changeC1C2(1);
					setSelectedTypeID('all');
					return;
				}else{
					changeC1C2(2);
				}
				setMenuFixed();
				if(e.target.value === keyWords){
					return;
				}
				document.getElementsByClassName('searchcontent')[0].blur();
				if(selectedTypeID === "all"){
					searchTypeidAll();
				}else{
					sendSearch(selectedTypeID);
				}
			}
			
        }
    }
	const pageInput = (e) => {
		e = e || window.event;
		var inputValue = e.target.value.replace(/\D/g,"");
		if(inputValue === ''){
			inputValue = 1;
		}
		if(inputValue > totalPage){
			inputValue = totalPage;
		}
		if(inputValue === currentPage){
			setCurrentPage(inputValue);
			return;
		}
		setCurrentPage(inputValue);
		if(document.documentElement && document.documentElement.scrollTop){
			document.documentElement.scrollTop = 70;
		}
		else if(document.body){
			document.body.scrollTop = 70;
		}
	}
	const setMenuFixed = () => {//重置menu位置
		if(window.innerWidth < 1200){
			return;
		}
		var scrollTop = getScrollTop();
		var head_search = document.getElementsByClassName("head_search")[0];
		var content2_left = document.getElementsByClassName("content2_left")[0];
		var content2DOM = document.getElementsByClassName("content2")[0];
		if(!content2DOM){
			return;
		}
		var menu = document.getElementById('menu');
		if(menu){
			if(scrollTop >= 20){
				head_search.classList.add('fixed');
				content2_left.classList.add('fixed');
				menu.classList.add('fixed');
				if(document.documentElement.clientHeight + scrollTop - document.getElementsByTagName("footer")[0].offsetTop >= 0){
					var x = document.documentElement.clientHeight + scrollTop - document.getElementsByTagName("footer")[0].offsetTop;
					content2_left.style.top = (172 - x) + "px";
					menu.style.top = (172 - x) + "px";
				}else{
					content2_left.style.top = "172px";
					menu.style.top = "172px";
				}
			}else{
				head_search.classList.remove('fixed');
				content2_left.classList.remove('fixed');
				content2_left.style.top = 248 - scrollTop + "px";
				menu.classList.remove('fixed');
				menu.style.top = 248 - scrollTop + "px";
			}
			let toSelected = menu.getAttribute('toSelected');
			if(toSelected){
				let menu_selected = document.getElementsByClassName('menu_selected')[0]; 
				if(menu_selected){
					menu.scrollTop = (menu_selected.offsetTop+menu_selected.offsetHeight) - menu.offsetHeight;
					menu.setAttribute('toSelected',0);
				}
			}
		}else{
			var isSearch = false;
			if(content2DOM.style.display === 'block'){
				isSearch = true;
			}
			if(isSearch){
				if(scrollTop >= 20){
					head_search.classList.add('fixed');
					content2_left.classList.add('fixed');
					if(document.documentElement.clientHeight + scrollTop - document.getElementsByTagName("footer")[0].offsetTop >= 0){
						var x1 = document.documentElement.clientHeight + scrollTop - document.getElementsByTagName("footer")[0].offsetTop;
						content2_left.style.top = (158 - x1) + "px";
					}else{
						content2_left.style.top = "158px";
					}
				}else{
					head_search.classList.remove('fixed');
					content2_left.classList.remove('fixed');
					content2_left.style.top = 248 - scrollTop + "px";
				}
			}
		}
	}
	document.onscroll = function(){
		setMenuFixed();
	}
	window.onresize = function(){
		if(window.innerWidth < 1200){
			var head_search = document.getElementsByClassName("head_search")[0];
			var content2_left = document.getElementsByClassName("content2_left")[0];
			var menu = document.getElementById('menu');
			head_search.classList.remove('fixed');
			content2_left.classList.remove('fixed');
			if(menu){
				menu.classList.remove('fixed');
			}
		}
	}
	const getScrollTop = () => {
		var scrollTop = 0;
		if(document.documentElement && document.documentElement.scrollTop){
			scrollTop = document.documentElement.scrollTop;
		}
		else if(document.body){
			scrollTop = document.body.scrollTop;
		}
		return scrollTop;
	}
    return (
		<>
			<div className="head_search">
				<div>
					<div>
						<div className="s_left" onClick={returnIndex.bind(this)}>{leftContent}</div>
						<div className="s_right">
							<input className="searchcontent bigsearchcontent" type="text" defaultValue={inputValue} placeholder={t("help.t7")} onKeyUp = { searchcontent} onFocus = {searchFocus} onBlur = {searchBlur}/>
							<button className="mobile_searchbtn show" onClick={searchbtnClick.bind(this)}>{t("help.t8")}</button>
						</div>
					</div>
					<div className="mobile_search">
						<input className="searchcontent mobilesearchcontent" type="text" defaultValue={inputValue} placeholder={t("help.t7")} onKeyUp = { searchcontent} onFocus = {searchFocus} onBlur = {searchBlur}/>
						<button className="mobile_searchclosebtn" onClick={mobilesearchbtnClick.bind(this)}></button>
					</div>
					<div className="searchInputList" style={{'display':searchListShow?'block':'none'}}>
						<div className="title">{t("help.t9")}</div>
						<div typeid="all">
							<input type="radio" name="product" className="product" typeid="all" id="all" checked={selectedTypeID === 'all'?true:false} onChange = {changeRadio.bind(this,'all')}/>
							<label htmlFor="all">{t("help.t10")}</label>
						</div>
						{
						featureAry.map((item,index)=>{
							return (
								<div key={index} typeid={item.TypeID}>
									<input type="radio" name="product" className="product" checked={selectedTypeID === item.TypeID?true:false}  typeid={item.TypeID} id={item.TypeID} onChange = {changeRadio.bind(this,item.TypeID)}/>
									<label htmlFor={item.TypeID}>{item.TypeName}</label>
								</div>
							)
							
						})
					}</div>
				</div>
			</div>
			<div>
			<div className="content2" style={{'display':isShow?'block':'none'}}>
				<div className={window.innerWidth < 1200 ? 'content2_left' : 'content2_left fixed'}>
						<div className="content2_left_top">
							<div className="title">{t("help.t13")}<button className="filterbtn" onClick={filterbtnClick.bind(this)}></button></div>
						</div>
						<div className="content2_left_list">{
							list2.map((item,index)=>{
								return (
									<div key={index} typeid={item.TypeID}>
										<input type="radio" name="product1" className="product1" typeid={item.TypeID} checked={selectedTypeLeft===item.TypeID?true:false} id={"list1" + item.TypeID} onChange = {changeRadioLeft.bind(this,item.TypeID)}/>
										<label htmlFor={"list1" + item.TypeID}>{item.TypeName}</label>
										<span> ({item.Count}) </span>
									</div>
								)
							})
						}</div>
					</div>
					<div className="content2_right">
						<div className="search_content">{t("help.t11")}<span id="search_content_text">{keyWords}</span></div>
						<div className="search_list">
							<ul className="search_list_ul">
								{searchNull ? <div className="search_list_null">{t("help.t12")}</div> : ""}
								{showList.map((item,index)=>{
									return (
										<li key={index} id={item.ID} className="search_list_li" typeid={item.TypeID} style={{display:index<10?'block':'none'}}>
											<a href={"/"+ currentLang.type +"/helpcontent" + item.UrlName}>
												<div className="li_title" dangerouslySetInnerHTML = {{ __html:item.Name }}></div>
												<div className="li_content" dangerouslySetInnerHTML = {{ __html:item.Desc }}></div>
												<div className="li_product" typeid={item.TypeID}>/ {item.TypeName}</div>
											</a>
										</li>
									)
								})}
							</ul>
							<div className="page">
								<button className={['left',currentPage<=1?'disabled':''].join(' ')} onClick={prevPage.bind(this)}></button>
								<span><input className="pagenumber" type="text" value={currentPage} onChange = {pageInput}/><span className="margin15">/</span><span className="totalPage">{totalPage}</span></span>
								<button className={['right',currentPage>=totalPage?'disabled':''].join(' ')} onClick={nextPage.bind(this)}></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
    );
        
}

export default SearchComponent;