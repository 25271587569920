const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何在线/离线反激活 文电通6 (Right PDF 6)?</title></head><body>  <h1>如何在线/离线反激活 文电通6 (Right PDF 6)?</h1>  <h2><span class="wysiwyg-color-blue130 "><strong>在线反激活：</strong></span></h2>
<p><span class=" wysiwyg-color-blue130">1. 开启文电通产品，点选「说明」&gt; 「授权资讯」，或是</span></p>
<p><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">套装版</span>:</strong></span></p>
<div><span class=" wysiwyg-color-blue130">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Right PDF Pro」 &gt; 「授权信息」。弹出「授权信息」对话框</span></div>
<div> </div>
<p><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">转换器</span>:</strong></span></p>
<div>
<div><span class="wysiwyg-color-blue130 "><span class=" wysiwyg-color-blue130">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Right PDF Pro」 &gt; 「授权信息」</span>。弹出「授权信息」对话框</span></div>
<div> </div>
</div>
<p><span class="wysiwyg-color-blue130">2. 点击「反激活」开始反激活程序</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip0.png" alt="mceclip0.png"></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">3. 选择「在线」确定，开始反激活过程</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip1.png" alt="mceclip1.png"></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">4. 等待完成即可</span></p>
<p><span class=" wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/mceclip3.png" alt="mceclip3.png"></span></p>
<h2><span class="wysiwyg-color-blue130 "><strong>离线反激活：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">1. </span>开启文电通产品，点选「说明」&gt; 「授权资讯」，或是</p>
<p><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">套装版</span>:</strong></span></p>
<div><span class=" wysiwyg-color-blue130">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Right PDF Pro」 &gt; 「授权信息」。弹出「授权信息」对话框</span></div>
<div> </div>
<p><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">转换器</span>:</strong></span></p>
<div>
<div><span class="wysiwyg-color-blue130 "><span class=" wysiwyg-color-blue130">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Right PDF Pro」 &gt; 「授权信息」</span>。弹出「授权信息」对话框</span></div>
</div>
<p><br><span class="wysiwyg-font-size-medium wysiwyg-color-blue130">2. 点击「反激活」开始反激活程序</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (1).png" alt="mceclip0 (1).png"></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">3. 选择「离线」，将激活数据另存为一个扩展名为「htm」的文件</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (2).png" alt="mceclip2 (2).png"></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/220021.jpg" alt=""></p>
<p><br><span class="wysiwyg-color-blue130">4. 将您刚才所保存的「激活数据」文件复制到<span class="wysiwyg-underline">任意一台能连接到因特网的计算机上</span>，并以浏览器打开此文件</span></p>
<p> </p>
<p><span class=" wysiwyg-color-blue130">5. 在打开的网页文件中点击「上传」按钮</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/220022.jpg" alt=""><br><span class=" wysiwyg-color-blue130">6. 离线反激活完成<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (1).png" alt="mceclip3 (1).png"><br></span></p></body></html>`;   export default html;