const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>订阅版激活失败，错误码「127」账号或密码错误</title></head><body>  <h1>订阅版激活失败，错误码「127」账号或密码错误</h1>  <h2><strong>问题描述：</strong></h2>
<p>购买或续购文电通<strong>订阅版</strong>授权，以账号与密码激活授权失败，错误码「127」。</p>
<p>若不清楚该如何激活订阅版授权，请参考：<a href="https://support.rightpdf.com/hc/zh-cn/articles/201031766" target="_blank" rel="noopener">文电通PDF套装版订阅版(Right PDF Subscriptions)授权如何激活？</a></p>
<p> </p>
<h2><strong>原因：</strong></h2>
<ol>
<li>账号或密码输入错误</li>
<li>忘记账号或密码</li>
<li>账号与密码正确，但还是激活失败</li>
</ol>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<h3><span class="wysiwyg-color-blue130"><strong>1. </strong><strong>账号或密码输入错</strong><strong>误</strong></span></h3>
<p><span class="wysiwyg-color-blue130">请确认您输入的账号与密码正确无误。</span></p>
<p> </p>
<h3><span class="wysiwyg-color-blue130"><strong>2. </strong><strong>忘记账号或密</strong><strong>码</strong></span></h3>
<ol>
<li><span class="wysiwyg-color-blue130">忘记<strong>密码</strong>：请透过<strong><a href="https://store.rightpdf.cn/account/forgot-password.aspx" target="_blank" rel="noopener">以下连结</a></strong>重新设置你的密码，并以新的密码激活文电通。</span></li>
<li><span class="wysiwyg-color-blue130">忘记<strong>账号</strong>与<strong>密码</strong>：别担心，如果真的不幸账号与密码都忘记了，请<strong><a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_blank" rel="noopener">与我们联系</a></strong>，并提供个人信息方便我们为您查询！</span></li>
</ol>
<p> </p>
<h3><span class="wysiwyg-color-blue130"><strong>3. </strong><strong>相同的帐密可以登入官网账号，但无法</strong>激活<strong>授</strong><strong>权</strong></span></h3>
<p><span class="wysiwyg-color-blue130">请<strong><a href="https://store.rightpdf.cn/account/login.aspx" target="_blank" rel="noopener">登入</a></strong>官网账号，选择「<strong>变更密码</strong>」。变更完成后，请以新的密码激活文电通。</span></p></body></html>`;   export default html;