const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>生成的PDF突然出现了水印而且程序变成了试用版，怎么办？</title></head><body>  <h1>生成的PDF突然出现了水印而且程序变成了试用版，怎么办？</h1>  <div style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 100%; width: 100%; padding: 5px;"><span><span class="wysiwyg-color-red"><strong>重要提醒：</strong></span><span>文电通(DocuCom) 已于2009年停止更新，并无法支持Windows7及以上的版本。如果解决方案没有效果，请考虑<strong><a title="Link: http://pdf.gaaiho.com/Docucom_upgrade/intro.aspx" href="https://store.rightpdf.com/store/overview.aspx" target="_self" rel="undefined">升级到最新版Right PDF</a></strong>，因为我们已经无法再更新旧版的文电通了。</span></span></div>
<p> </p>
<h2><strong>问题描述：</strong></h2>
<p><span><span>生成的PDF突然出现了水印而且程序变成了试用版。</span></span></p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p><span><span>可能原因是license文件被意外的删除了</span>。</span></p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">碰上这种情况，请按照下列步骤重新安装程序：</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">请反激活并卸载电脑上所有的文电通产品( <strong> 如果产品未激活或无法正常反激活，请略过 </strong> )</span></li>
<li><span class="wysiwyg-color-blue130">卸载文电通*</span></li>
<li><span class="wysiwyg-color-blue130">重新启动电脑</span></li>
<li><span class="wysiwyg-color-blue130">前往这个路径「<strong>%SystemDrive%\\Documents and Settings\\All Users\\Application Data\\</strong>」（在Windows 7、Windows 8、Vista上则是「<strong>%SystemDrive%\\ProgramData</strong>」），然后删除「Zeon」文件夹</span></li>
<li><span class="wysiwyg-color-blue130">重新安装软件，最后使用您的序列号激活</span></li>
</ol>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div>
<p><span class="wysiwyg-color-red"><strong>注意：</strong></span><span class="wysiwyg-color-blue130">欲前往以下路径：「<strong>%SystemDrive%/Documents and Settings/All Users/Application Data</strong>」 或「<strong>%SystemDrive%/ProgramData</strong>」，请按照以下步骤。</span></p>
<p><br><span class="wysiwyg-color-blue130">「<strong>%SystemDrive%/Documents and Settings/All Users/Application Data</strong>」：</span><br><span class="wysiwyg-color-blue130"></span></p>
<ol>
<li><span class="wysiwyg-color-blue130">开启「我的电脑」</span></li>
<li><span class="wysiwyg-color-blue130">开启本地磁盘C</span></li>
<li><span class="wysiwyg-color-blue130">点击「Documents and Settings」</span></li>
<li><span class="wysiwyg-color-blue130">点击您的电脑名称</span></li>
<li><span class="wysiwyg-color-blue130">假若您没看见「Application Data」，请在上方路径輸入「C:\\Documents and Settings\\Your PC name\\Application Data」(如下图红框所示)</span></li>
<li><span class="wysiwyg-color-blue130">右键点击空白处，并选择「内容」</span></li>
<li><span class="wysiwyg-color-blue130">取消勾选「隐藏」</span></li>
<li>
<span class="wysiwyg-color-blue130">点选「应用」，并点击「确定」<br></span><img style="color: #000066;" src="https://store.rightpdf.com/images/rtsupport/22.png" alt="">
</li>
</ol>
<p> </p>
<p><span class="wysiwyg-color-blue130">「<strong>%SystemDrive%/ProgramData</strong>」：</span><br><span class="wysiwyg-color-blue130"></span></p>
<ol>
<li><span class="wysiwyg-color-blue130">开启「我的电脑」</span></li>
<li><span class="wysiwyg-color-blue130">开启本地磁盘C</span></li>
<li><span class="wysiwyg-color-blue130">在上方路径输入「ProgramData\\」</span></li>
</ol>
<p> </p>
</div>
<div><span class="wysiwyg-color-blue130">如果您无法通过正常的方式卸载程序（控制面板 &gt; 程序&gt; 卸载程序，选取「<strong>DocuCom PDF Gold *.x</strong>」然后按卸载），请使用附件中的「<strong>Remove Tool</strong>」替换上述的步骤2*。</span></div>
<div><span> </span></div></body></html>`;   export default html;