import React, {useEffect, useState,useRef} from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { useParams } from 'react-router-dom';
import {getMenuList,getContent,getManualType} from '../api';
import MetaTags from 'react-meta-tags';
import SearchComponent from '../components/search/searchComponent.js';


const SdkPage = props => {
    let {t,currentLang, i18n} = props;
    let {lang, urlName,urlID} = useParams();
    const [typeName,setTypeName] = useState('');
    const mData = [];
    const [menuData,setTodos] = useState(mData);
    const mmData = useRef(mData);
    const [isMenuLoaded,setMenuLoaded] = useState(false);
    const [featureAry, setFeatureAry] = useState([]);
    const [relativeArticle,setRelativeArticle] = useState([]);
    const [isArticleLoaded,setArticleLoaded] = useState(true);
    const [articleData,setArticle] = useState('');
    const [nextArticle,setNextArticle] = useState({});
    const [menuOpen,setMenuOpen] = useState(0);
    const [isShow,setIsShow] = useState(true);
    const [metaTitle,setMetaTitle] = useState(t('metaTag.userManuals.title'));
    const [metaDesc,setMetaDesc] = useState(t('metaTag.userManuals.desc'));
    const { hash } = window.location;
    const [AnchorList, setAnchorList] = useState('');

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    
    useEffect(() => {
        const addArticleHref = (arr,article) => {
            arr.forEach((item,index)=>{
                //let ID;
                if(item.SubType === 'A'&&item.ID !== article.ID){
                    item.href = '/'+currentLang.type+'/helpcontent'+item.UrlName;
                }
                if(arr[index+1]){
                    item.nextID = arr[index+1].ID;
                }
            })
        }
        const changeOpenByArticleID = (data,clickData)=>{//根据ID 判断isopen值
            let parentID = clickData.parentID;
            while(parentID !== 0){
                //eslint-disable-next-line no-loop-func
                let articleArr = data.filter((item)=>{
                    return item.ID === parentID;
                })
                let parent = articleArr[0];
                parent.isopen = 1;
                parentID = parent.parentID;
            }
            if(clickData.Children&&clickData.Children.length !== 0){
                let articleArr = data.filter((item)=>{
                    return item.ID === clickData.ID;
                })
                let article = articleArr[0];
                article.isopen = 1;
            }
    
        }
        const transferClickData = (data,clickData) => {//更新click menu数据
            let newData = flatArrFuc(data);
            newData.forEach((item)=>{
                item.isSelected = false;
                if(item.SubType === "A"&&item.ID === clickData.ID){
                    item.isSelected = true;
                }
            })
            changeOpenByArticleID(newData,clickData);
            data = reflatArrFuc(newData);
        }
        const replaceAtag = (text) => {//增加a标签target
            let  reg =/<a([\s]+|[\s]+[^<>]+[\s]+)href=("([^<>"']*)"|'([^<>"']*)')[^<>]*>/gi;
            text = text.replace(reg,'<a $1 target="_blank" href="$3">')
            return text;
        }
        const SetH2Anchor = (helpContent) => {//置換H2標籤(動態產生Anchor)
           
            let check = helpContent.match("<h2>");

            if (check !== null)
            {
                let newHelpContent = helpContent;

                //先置換全部h2
                newHelpContent = newHelpContent.replaceAll("<h2>", "<h2><a class='anchor' id='HCAnchor{i}'></a>"); 

                //找出有幾組h2
                let count = 0;
                let position = newHelpContent.indexOf("{i}");

                while (position !== -1) {
                    count++;
                    position = newHelpContent.indexOf("{i}", position + 1);
                }

                //置換{i}內字串
                for (var i = 0; i < count; i++) {
                    let newID = i+1;
                    newHelpContent = newHelpContent.replace("{i}", newID);
                }
            
                helpContent = newHelpContent;
            }

            return helpContent;
        }
        const GetH2Tag = (helpContent) => {//取得H2標籤(建立超連結)

             let check = helpContent.match("<h2>");

             if (check !== null)
             {
                //找出有幾組h2
                let count = 0;
                let position = helpContent.indexOf("<h2>");

                while (position !== -1) {
                    count++;
                    position = helpContent.indexOf("<h2>", position + 1);
                }

                //找出h2內字串
                let tempOP = "";
                let indexContent = 0;

                for (var i = 0; i < count; i++) {
                    let indexSearchHead = helpContent.indexOf("<h2>", indexContent);
                    let indexSearchTail = helpContent.indexOf("</h2>", indexContent);

                    tempOP += "<a href='#HCAnchor" + (i+1) + "'>[A]" + helpContent.substring(indexSearchHead + 4, indexSearchTail) + "</a>" + "<br/>";

                    indexContent +=  indexContent + (indexSearchTail + 5);
                }

                helpContent = tempOP;
            }
        else
            {
                helpContent = "";
            }
            
            return helpContent;
        }
        const renderTextContent = (articleItem) => {
            if(!articleItem){
                setArticleLoaded(true);
                setArticle('');
                return;
            }
            let cLang = currentLang.type;
            let plang = 'EN';
            if(cLang === 'zh-TW'){
                plang = 'CT';
            }else if(cLang === 'zh-CN'){
                plang = 'CS';
            }else{
                plang = cLang.toUpperCase();
            }
            getContent({LgeID:plang,ID:articleItem.ID}).then(function (response) {
                var title =response.data.Name;
                var desc = response.data.Desc;
                desc = desc.replace(/\n/g,'');
                desc = desc.replace(/\r/g,'');
                desc = desc.replace(/\t/g,'');
                desc = desc.substr(0,50);
                setMetaTitle(title);
                setMetaDesc(desc);
                let content = `<div style="font-size:29px;line-height:36px;color:#000000;font-weight:bold;margin-bottom:50px;"><h1>${response.data.Name}</h1></div>`;
                response.data.List.forEach(function(item,index){
                    content += item.Content;
                })
                let replaceRexp = /href="#(\S+)"/g;
                //暫時用不到先隱藏(FOX_20240517)
                //content =  content.replace(replaceRexp,'onClick="anchorTo(this)" toid="$1"')
                setArticleLoaded(true);
                //content = replaceAtag(content);

                //setAnchorList(GetH2Tag(content))

                //取得H2標籤超連結(FOX開發中)
                //let temp = "<div>" + GetH2Tag(content) + "</div>";


                //內容設定錨點
                content = SetH2Anchor(content);

                //content = content + temp;
                content = content;
                setArticle(content);

                

                //滾動至錨點位置(FOX)
                var screenWidth = window.innerWidth;
                var offset = 0;
                var gohash = hash?.replace("#", "");
                var elementToScroll = document.getElementById(hash?.replace("#", ""));

                if (elementToScroll != null) 
                {
                    //判斷螢幕寬度調整錨點高度
                    if (screenWidth > 1900)
                    {
                        offset = 115;
                    }
                    else if (screenWidth > 1200 && screenWidth <= 1900)
                    {
                        offset = 35;
                    }
                    else if (screenWidth < 1200)
                    {
                        offset = -20;
                    }

                    window.scrollTo({
                        top: elementToScroll.offsetTop - offset,
                        behavior: "smooth"
                    });
                }
            })
            .catch(function (error) {
                console.log('err',error);
            });
        }

        const anchorTo = (dom) => {
            let toid = dom.getAttribute('toid');
            let header = document.getElementsByTagName('header')[0];
            let headerSearch = document.getElementsByClassName('head_search')[0];
            let toDOM = document.querySelector("[name="+toid+"]");
            let toTop = toDOM.offsetTop;
            toTop = toTop - header.clientHeight - headerSearch.clientHeight;
            document.documentElement.scrollTop = toTop;
        } 
      
        const renderNextArticle = (article,data)=>{
            if(!article){
                return;
            } 
            let nextArticle = getNextArticle(article,data);
            if(nextArticle){
                let nextobj = {...nextArticle};
                nextobj['href'] = nextArticle.href;
                nextobj['name'] = nextArticle.TypeName;
                setNextArticle(nextobj);
            }
        }
        const getNextArticle = (article,data) => {//找到下一个article
            let newData = data;
            if(!newData){
                newData = flatArrFuc(mmData);
            }
            let nextID = article.nextID;
            let nextArticle = {};
            do{
                //eslint-disable-next-line no-loop-func
                let articleArr = newData.filter((item)=>{
                    return item.ID === nextID;
                })
                nextArticle = articleArr[0];
                nextID = nextArticle?nextArticle.nextID:'';
            }while(nextArticle&&nextArticle.SubType !== "A")
    
            return nextArticle;
        }
        const getUrlID = (urlID) => {
            return urlID.split('-')[0];
        }
        const flatArrFuc = (arr,parentID) => {//数据扁平
            parentID = parentID || 0;
            let newArr = [];
            arr.forEach(function(item,index){
                item.parentID = parentID;
                newArr.push(item);
                if(item.Children&&item.Children.length !== 0){
                    newArr = newArr.concat(flatArrFuc(item.Children,item.ID));
                }
            })
            return newArr;
        }
        const reflatArrFuc = (arr,ID) => {//反数据扁平
            ID = ID || 0;
            let newArr = [];
            arr.forEach((item)=>{
                if(item.parentID === ID){
                    let children = arr.filter((item1)=>{
                        return item1.parentID === ID;
                    })
                    let obj = item;
                    if(children.length !== 0){
                        obj.Children = reflatArrFuc(arr,item.ID);
                    }
                    newArr.push(obj);
                }
            })
            return newArr;
        }
        const setMenuScrollTop = () => {
            let menu = document.getElementById('menu');
            let menu_content = document.getElementsByClassName('menuContentContent')[0];
            let menu_selected = document.getElementsByClassName('menu_selected')[0];      
            let menu_select_top = menu_selected.offsetTop+menu_content.offsetTop;
            if(menu_select_top>menu.clientHeight){
                menu.setAttribute('toSelected',1);
                document.documentElement.scrollTop = 20;
            }
        }
        const fetchResource = cLang => {
            //切换语言或是重新渲染需要先清除数据
            setTypeName('');
            setMenuLoaded(false);
            setTodos([])
            setRelativeArticle([]);
            setArticleLoaded(true);
            setArticle('');
            setNextArticle({});
            let plang = 'EN';
            if(cLang === 'zh-TW'){
                plang = 'CT';
            }else if(cLang === 'zh-CN'){
                plang = 'CS';
            }else{
                plang = cLang.toUpperCase();
            }
            getManualType({LgeID:plang}).then(function(response){
                let list = response.data.List;
                setFeatureAry(list);
                let article = list.filter((item)=>{
                    let typeName = item.UrlName.split('/')[1];
                    return typeName === urlName;
                })
                let typeID = article[0]?article[0].TypeID:'';
                if(!typeID){//typeID不存在则跳转回help
                    window.location.href = '/'+cLang+'/help';
                }
                let newlist = [];
                for(let i = 0;i<list.length;i++){
                    let item = list[i];
                    if(item.TypeID !== typeID){
                        item.href = '/'+cLang+'/helpcontent'+item.UrlName;
                        newlist.push(item);
                    }
                    if(newlist.length>=3){
                        break;
                    }
                }
                setRelativeArticle(newlist);
                getMenuList({LgeID:plang,TypeID:typeID}).then(function (response) {
                    setTypeName(response.data.TypeName);
                    let resultData = response.data.List;
                    let flatArr = flatArrFuc(resultData);
                    let defaultItem;
                    let contentID = '';
                    if(urlID){
                        contentID = getUrlID(urlID);
                        let filterArr = flatArr.filter((item)=>{
                            return item.ID === contentID;
                        })
                        defaultItem = filterArr[0];
                    }
                    if(!defaultItem){
                        let filterArr = flatArr.filter(function(item,index){
                            return item.SubType === 'A';
                        })  
                        defaultItem = filterArr[0];
                    }
                    addArticleHref(flatArr,defaultItem);
                    if(defaultItem){
                        transferClickData(resultData,defaultItem);
                        setMenuLoaded(true);
                        setArticleLoaded(false);
                        setTodos(resultData);
                        setMenuScrollTop(defaultItem);
                    }
                    renderTextContent(defaultItem);
                    renderNextArticle(defaultItem,flatArr);
                })
                .catch(function (error) {
                    console.log('err',error);
                });
            })
        }
        document.documentElement.scrollTop = 0;
        fetchResource(currentLang.type);
    }, [currentLang.type,mmData,urlID,urlName]);
	const changeShow = (isShow) => {
		setIsShow(isShow);
	}
    const flatArrFuc = (arr,parentID) => {//数据扁平
        parentID = parentID || 0;
        let newArr = [];
        arr.forEach(function(item,index){
            item.parentID = parentID;
            newArr.push(item);
            if(item.Children&&item.Children.length !== 0){
                newArr = newArr.concat(flatArrFuc(item.Children,item.ID));
            }
        })
        return newArr;
    }
    const reflatArrFuc = (arr,ID) => {//反数据扁平
        ID = ID || 0;
        let newArr = [];
        arr.forEach((item)=>{
            if(item.parentID === ID){
                let children = arr.filter((item1)=>{
                    return item1.parentID === ID;
                })
                let obj = item;
                if(children.length !== 0){
                    obj.Children = reflatArrFuc(arr,item.ID);
                }
                newArr.push(obj);
            }
        })
        return newArr;
    }
    const menuClick = () => {
        setMenuOpen(menuOpen === 0?1:0);
    }
    const itemClick = (clickItem) => {
        if(clickItem.SubType === "A"){
            setMenuOpen(menuOpen === 1?0:1);
        }  
        let flatArr = flatArrFuc(menuData);
        flatArr.forEach((item)=>{
            if(item.ID === clickItem.ID){
                item.isopen = item.isopen===1?0:1;
            }
        })
        let reflatArr = reflatArrFuc(flatArr);
        setTodos(reflatArr);
    }
    const makeListTree = (treeData) => {
            const menuliss = treeData.map(function(item,index){
                if(item.Children&&item.Children.length !== 0){
                    return (
                        <li id={item.ID} className={item.isSelected?'menu_selected':''} isopen={item.isopen} key={index}>
                            {item.href?(
                                <div className="menuText" ><span className="menuIcon"><a href={item.href}>{item.TypeName}</a></span></div>
                            ):(
                                <div className="menuText" onClick={itemClick.bind(this,item)}><span className="menuIcon">{item.TypeName}</span></div>
                            )}
                            
                            <ul>{makeListTree(item.Children)}</ul>
                        </li>
                    )
                }else{
                    return (
                        <li id={item.ID} className={item.isSelected?'menu_selected':''} key={index}>
                            <div className="menuText"><a href={item.href}>{item.TypeName}</a></div>
                        </li>
                    )
                }
            })
            return menuliss;
    }
    const menulis = menuData.map((item,index)=>{
        if(item.Children&&item.Children.length !== 0){
            return (
                <li id={item.ID} className={item.isSelected?'menu_selected':''} isopen={item.isopen} key={index}>
                    {item.href?(
                        <div className="menuText" ><span className="menuIcon"><a href={item.href}>{item.TypeName}</a></span></div>
                    ):(
                        <div className="menuText" onClick={itemClick.bind(this,item)}><span className="menuIcon">{item.TypeName}</span></div>
                    )}
                    
                    <ul>{makeListTree(item.Children)}</ul>
                </li>
            )
        }else{
            return (
                <li id={item.ID} className={item.isSelected?'menu_selected':''} key={index}>
                    <div className="menuText"><a href={item.href}>{item.TypeName}</a></div>
                </li>
            )
        }
    })

    const renderContentView = () => {
        return (
            <div>
                <SearchComponent  currentLang = {currentLang} featureAry = {featureAry} t = {t} changeShow={changeShow} />
                <div className="content1" style={{'display':isShow?'block':'none'}}>
                    <div className={["container",isMenuLoaded?"":"contentLoading"].join(' ')}>
                        <div id="menu" className="fixed" isopen={menuOpen}>
                            {/* <div className="menuBar"></div> */}
                            <div className="menuContent">
                                <div className="menuContentTitlePC">{typeName}</div>
                                <div className="menuContentTitleMo paddingContainer" onClick={menuClick.bind(this)}><div>{typeName}</div></div>
                                <div className="menuContentContent paddingContainer">
                                    <ul>{menulis}</ul>
                                </div>
                            </div>
                        </div>
                        <div id="textContent">
                            <div className={["textContainer",isArticleLoaded?"":"contentLoading"].join(' ')} dangerouslySetInnerHTML = {{__html:articleData}}>
                            </div>
                                {nextArticle.name?<div className="nextArticle">
                                <a href={nextArticle.href}>{t('help.t1')+nextArticle.name}</a>
                            </div>:''}
                            {relativeArticle.length>0?<div className="relateArticle">
                                <div className="relateArticle_title">{t('help.t2')}</div>
                                <div className="relateArticle_content">
                                    <ul>
                                        {relativeArticle.map(function(item,index){
                                            return (
                                                <li key={index}><a href={item.href}>{item.TypeName}</a></li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>:''}

                        </div>
                        <div  className="clearBoth"></div>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <section id="helpcontent" className="productInfoContent">
            <MetaTags>
                <title>{metaTitle+ '-'+ t('metaTag.userManuals.title')} </title>
                <meta name="title" content={metaTitle+ '-'+ t('metaTag.userManuals.title')} />
                <meta name="description" content={metaDesc} />
                <meta name="keywords" content={t('metaTag.userManuals.keywords')} />
                <meta property="og:title" content={metaTitle+ '-'+ t('metaTag.userManuals.title')} />
                <meta property="og:description" content={metaDesc} />
            </MetaTags>
            <div className="contentBlock whiteBg">
                <div className="customContainer">
                     {renderContentView()}
                </div>
            </div>
        </section>
    )
}


export default mixinWrapper(SdkPage);






