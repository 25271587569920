const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我在网站上看到可购买终端服务器版 (TSL)，那是什么?</title></head><body>  <h1>我在网站上看到可购买终端服务器版 (TSL)，那是什么?</h1>  <p>Terminal Server License（终端服务器授权）是专为使用终端服务器部署方式的用户设计的，如Windows Server环境。优势在于不用在每个用户的电脑上安装软件，而只需要在服务器集中安装一次软件即可，所有用户通过远程方式接入和使用软件。</p>
<p> </p>
<p>TSL分为两种授权方式，您可以根据具体的使用使用情况选择：</p>
<h2><strong>授权套餐A: 每客户授权</strong></h2>
<ul>
<li>
<strong>文电通PDF套装版</strong><strong>  </strong><strong>(</strong><strong>Right PDF Editor+ Right PDF Converter</strong><strong>) -</strong> <strong>每客户授权</strong><strong>：</strong>本软件以单台电脑为授权单位，本软件的单个授权只允许用户在一台电脑上安装使用本软件。对于组织或机构用户，有多少台电脑需要使用本软件，就必须购买多少个本软件的单机版授权。「Seat」指：（1）在一台单独的电脑上安装并运行软件，或（2）使用一台单独的电脑访问软件。每一台安装、运行或访问本软件的电脑都需要一个单独的授权。一个Right PDF终端服务器授权每天最多只能创建100个文件与1000页面(两者皆达到)，如果您会超过这个限制，请考虑购买转换总量限制型授权或是更多授权拷贝数。</li>
</ul>
<p> </p>
<h2><strong>授权套餐B: 并发访问授权/转换总量限制型授权</strong></h2>
<ul>
<li>
<strong>Right PDF Editor - </strong><strong>并发访问授权：</strong>连接到网路的电脑都能访问软件，但同时使用软件的用户数不得超过授权的上限。</li>
<li>
<strong>Right PDF Converter - </strong><strong>转换总量限制型授权：</strong>如果PDF生成量达到了授权的上限(每24小时页数与文件两者皆是)，Right PDF Converter将停止运行。客户如果有更多需求，可以考虑购买更高级的授权。</li>
</ul>
<p> </p>
<h3><span class="wysiwyg-color-green130"><strong>延伸阅读</strong></span></h3>
<ul>
<li><a href="https://www.rightpdf.com/zh-CN/Resources/compared/remoteEnterprise" target="_blank" rel="noopener">远程桌面和企业授权方案</a></li>
</ul></body></html>`;   export default html;