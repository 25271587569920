import React, { useState,useContext,useEffect} from 'react';
import { useDispatch} from 'react-redux';
import { withRouter } from "react-router";
import {Link} from 'react-router-dom';
import headW from '../../assets/img/head_w.png';
import arrowR from '../../assets/img/arrow_r.png';
import MenuContext from './menuContext';
const MobileMenu = props => {
    const {t,linkObj,location,mobileMenu,hideBlockTop} = props;
    const [hideMenuShow, setHideMenuShow] = useState(false);
    const { block ,dispatch } = useContext(MenuContext);
    const [currentPath,setCurrentPath] = useState(location.pathname);
    const Dispatch = useDispatch();
    function toggleMenu(){
        Dispatch({
            type:'TOGGLE_MENU'
        })
    }
    function selectMenuBlock(e){
        setHideMenuShow(true);
        dispatch({type: 'SELECT_BLOCK',title:e.title, bType:e.type});
    }
    let menulist = props.ListData.map((e,i) => 
		i===0 || i===1 || i === 2 ?
			<li key={i} onClick={()=>{selectMenuBlock(e)}}>
				<span>{e.title}</span>
			</li>
		: (i===3 ? <li key={i}><a href={e.url} target="_blank" rel="noopener noreferrer">{e.title}</a></li> : <li key={i}><a href={e.url}>{e.title}</a></li>)
    )
    useEffect(()=>{
        const { pathname } = location;
        if(pathname !== currentPath){
            Dispatch({
                type:'CLOSE_MENU'
            })
        }
        setCurrentPath(pathname);
    },[location.pathname,Dispatch,currentPath,location]);
    return (
        <>
            <nav className="headMenu headerContent">
                <Link to={`/${props.currentLang.type}`} className="logoLink">
                    <img src={props.LogoPath} alt="right_logo"/>
                    <img src={props.VersionPath} style={{'marginLeft':'8px'}}  alt="right_logo"/>
                </Link>
            </nav>
			<div className={'width80 '+(mobileMenu?'active':'')} style={{"top":hideBlockTop+'px'}}></div>
            <div className={'mobileHideBlock '+(mobileMenu?'active':'')} style={{"top":hideBlockTop+'px'}}>
                <div className="mobileHideHead">
                    <div className={'toggleBtn '+(mobileMenu?'active':'')} onClick={()=>{toggleMenu()}}></div>
                    <div className="loginBlock">
                        <img src={headW} alt=""/>
                        <a href={linkObj.header.signIn.url()}  target="_blank" className="ml-3 mr-2" rel="noopener noreferrer">
                           { t(linkObj.header.signIn.name)}
                        </a>
                    </div>
                    <ul className="headInfoList">
                        <li><a href={linkObj.header.freetrail.url()} target="_blank" rel="noopener noreferrer">{t(linkObj.header.freetrail.name)}</a> </li>
                        <li><a href={linkObj.header.buy.url()} target="_blank" rel="noopener noreferrer">{t(linkObj.header.buy.name)}</a></li>
                        <li><a href={linkObj.header.upgrade.url()} target="_blank" rel="noopener noreferrer">{t(linkObj.header.upgrade.name)}</a></li>
                    </ul>
                </div>
                <div className="mobileHideContent ">
                    <div className={'menuListBlock '+(hideMenuShow?'hide':'')}>
                        <ul className="menuList">
                            {/* <li>
                                <Link to={`/${props.currentLang.type}${linkObj.newFeature.path}`}>
                                    {t(linkObj.newFeature.name)}
                                </Link>
                            </li> */}
                            {menulist}
                            <li onClick={()=>{selectMenuBlock({title:t(linkObj.contact.name),type:'contact'})}}>
                                <span>{t(linkObj.contact.name)}</span>
                            </li>
                        </ul>
                    </div>
                    <div className={'hideMenuContent '+(!hideMenuShow?'hide':'')}>
                        <div className="hideMenuContentTitle" onClick={()=>{setHideMenuShow(false)}}>
                            <h2 className="d-inline-block align-middle m-0">
                                {block.title}
                            </h2>
                            <img src={arrowR} className="ml-3" alt="arrow"/>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(React.memo(MobileMenu));