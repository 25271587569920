import axios from 'axios';

let path = window.config.API_URL;

export const getFeatureType = data => axios.get(path+'GetFeatureType',{params:data});

export const getFeatureList = data => axios.get(path+'GetFeatureList',{params:data});

export const getFeatureContent = data => axios.get(path+'GetFeatureContent',{params:data});

export const getPressTypeList = data => axios.get(path+'GetPressType',{params:data});

export const getPressList = data => axios.get(path+'GetPressList',{params:data});

export const setMessage = data => axios.post(path+'SetMessage', data);

export const getMenuList = data => axios.get(path+'GetManualSub', {params:data});

export const getManualType = data => axios.get(path+'GetManualType', {params:data});

export const getContent = data => axios.get(path+'GetManualContent', {params:data});

export const getManualSearch = data => axios.get(path+'GetManualSearch', {params:data});

export const SetEventPage = data => axios.post('https://cms.rightpdf.com/API/SetEventPage', data);

export const wb_loginout = data => axios.get("https://store.rightpdf.com/account/logout.aspx", {params:data});




