import React, {useEffect} from 'react';
import {useParams,useHistory} from 'react-router-dom';
import mixinWrapper from '../../hoc/mixinWrapper';
import MainComparePage from './mainComparePage';
import ProductDocuments from './productDocuments';
import SdkResources from './sdkResources';
import MainReleasePage from './mainReleasePage ';

const ResourcesPage = props => {
    let history = useHistory();
    const {t, currentLang, i18n} = props;
    const {lang,pathId,pageId} = useParams();
    const pathmap = {
        manual:t('tutorials.manual&Tutorials'),
        compared:t('resources.link1'),
        productDocuments:currentLang.type === 'zh-TW' || currentLang.type === 'zh-CN'?t('resources.link2').split('/')[0]:t('resources.link2'),
        sdkResources:t('resources.link3'),
        release:t('resources.link6')
    };


    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}		
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    
    const renderComponent = () => {
        
        switch(pathId){
            case 'compared':
                return <MainComparePage {...props} pageId={pageId} lang={lang}/>
            case 'productDocuments':
                return <ProductDocuments {...props} />
            case 'sdkResources':
                return <SdkResources {...props} />
            case 'release':
                return <MainReleasePage {...props} pageId={pageId} />
            default:
                history.push(`/${currentLang.type}`);
                return null;
        }
    }
    const showProductDocNote = () => {
        if(pathId === 'productDocuments'){
            return <p className="productDocInfo font-weight-light title_style">{t('resources.productDoc.mainTitle')}</p>
        }else{
            return null;
        }
    }
    return (
        <section id="resource" className="whiteBg paddingBottom200">
            <div className="infoTitle  grayBg-seashell">
                <div className="customContainer paddingWrap">
                    <div className="cRow maxW1300 padding-0">
                        <div className="d-100">
                            <h1 className="font-weight-normal title_style">{pathmap[pathId]}</h1>
                            {
                                showProductDocNote()
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="customContainer paddingWrap">
                <div className="cRow maxW1300 padding-0">
                    <div className="d-100">
                        {
                            
                            renderComponent()
                        }
                    </div>
                </div>
                {
                    pathId !== 'sdkResources'?(<div className="cRow maxW1300 padding-0 tips">
					{t('resources.productDoc.tips')}
				    </div>):''
                }
				
            </div>
        </section>
    )
}

export default mixinWrapper(ResourcesPage);