import React from 'react';
import MetaTags from 'react-meta-tags';
const SuiteCompare = props => {
	const metaTagscontent = {
		title:'真的非Adobe不可?-文电通PDF邀您一同思考5项观点-文电通官网',
		desc:'在软件预算、安全性与灵活性的考虑与日俱增的情况下，选择聪明又实在的解决方案，才是为您和您的企业增加竞争力的关键。'
	};
	const viewcontent =
		`<h1 id="p1">不定时炸弹：您的软件授权100%合乎规范吗?</h1>

<h2 class="marginB40"><span style="color:#c43e3e">企业长年购买累积的各种软件，其实就是一颗不定时炸弹</span></h2>

<p class="marginB50"><span style="color:#c43e3e">在受调查的企业中&hellip;</span></p>
<div class="box7Wrapper">
	<div class="box6 box7">
	<p><strong>38%的企业表示，至少11%的软件采购预算花在了不合规范的使用上。</strong></p>

	<p><img alt="11%" src="${require(`../../assets/img/resources/Group 1307.svg`)}" /></p>
	</div>

	<div class="box6 box7">
	<p><strong>56%的企业表示，约有11%以上的软件采购预算支付在使用率低的软件上。</strong><sup>(i)</sup></p>

	<p><img alt="11%" src="${require(`../../assets/img/resources/Group 1316.svg`)}" /></p>
	</div>

	<div class="box6 box7">
	<p><strong>63%的企业近18~24个月内遭到软件商的稽查。</strong></p>

	<p><img alt="63%" src="${require(`../../assets/img/resources/Group 1315.svg`)}" /></p>
	</div>

	<div class="box6 box7 marginB50">
	<p><strong>56%的企业选择赔偿结案；其中约21%的企业支付了百万元美金以上的费用。</strong><sup>(ii)</sup></p>

	<p><img alt="56%" src="${require(`../../assets/img/resources/Group 1330.svg`)}" /></p>
	</div>
</div>
<div class="redbox marginB40">
<p><span style="font-size:24px"><span style="color:#8e44ad"><img alt="client_logo_evergreen" src="${require(`../../assets/img/warning.png`)}" /></span></span></p>

<p><strong><span style="color:#c40000">Gartner在2014年发表一份名为「Surviving an Adobe Software License Audit</span></strong><span style="color:#c40000"><sup>(iii)</sup></span><strong><span style="color:#c40000">」</span></strong><strong><span style="color:#c40000">的报告中指出， Adobe稽查客户的比例不仅远高于其他获利相当的软件厂商，似乎还强化了稽查的程序。</span></strong></p>
</div>

<p class="marginB50">只要您公司里面有人下载并安装软件，厂商即有权追查您是否合乎授权条款。一旦发起突击检查，您就只能被迫接受昂贵、耗时又令人烦心的流程。</p>

<h2 class="marginB40 font21"><span style="color:#087a4f">或者，您可以了解文电通对此的看法：</span></h2>

<div class="box1 marginB70">
<ul>
	<li>
	<p><strong>我们不会突击检查：</strong><br />
	时下软件厂商经常采用突击策略，但棣南认为每位客户都应该有事先准备的机会。</p>
	</li>
	<li>
	<p><strong>超出合约规范并不罚款：</strong><br />
	即便发现用户人数不符规范，我们也不会索取罚款。</p>
	</li>
	<li>
	<p><strong>毫无压力的季度稽查：</strong><br />
	客户仅需自我检查并每季向文电通申报并补足超出的部分。</p>
	</li>
	<li>
	<p><strong>企业专属的弹性授权条款：</strong><br />
	企业授权方案非常简单，您只要签署几张文件就能完成。</p>
	</li>
</ul>
</div>

<h1 id="p2">友善对待用户、IT人员以及企业</h1>

<div class="box1 box2">
<h3 class="marginB40"><strong>/// Adobe</strong></h3>

<p class="marginB10"><strong>学习曲线</strong></p>

<p class="marginB30">Adobe一向墨守传统界面配置，但Microsoft Office的Ribbon式设计已在全球拥有<strong>12亿</strong><sup>(iv)</sup>用户，是现今最受欢迎的操作界面。因此，不难想象使哪一种界面配置会更容易被用户接受，同时也导致较少的适应问题。</p>

<p class="marginB10"><strong>软件大小</strong></p>

<p>即使在单一部门部署新软件都不见得轻松，更何况是整个企业。首先，来看看Acrobat需要的硬件空间：</p>

<p class="box3"><span style="color:#c43e3e"><strong>4.5 GB</strong></span><span style="color:#c43e3e"><i>Acrobat Pro DC,</i><i> Acrobat Standard DC</i></span></p><sup>(v)</sup>

</div>

<div class="box1 marginB50">
<h3 class="marginB30"><span style="color:#087a4f"><strong>/// 文电通的方式</strong></span></h3>

<p class="marginB10"><strong>熟悉的界面设计</strong></p>

<ul class="marginB20">
	<li class="marginB0">
	<p>易于操作与迅速适应</p>
	</li>
	<li class="marginB0">
	<p>降低技术支持与教育训练的依赖程度</p>
	</li>
	<li class="marginB0">
	<p>将省下的适应时间投注在更重要的工作</p>
	</li>
</ul>

<p class="marginB10"><strong>轻巧</strong></p>

<p class="box3 box4"><span style="color:#087a4f"><strong>700MB左右</strong></span><span style="color:#087a4f">减少84%的占用空间</span></p>

<p class="marginB10"><strong>灵活部署</strong></p>

<ul>
	<li class="marginB0">
	<p>永久授权</p>
	</li>
	<li class="marginB0">
	<p>订阅版授权</p>
	</li>
	<li class="marginB0">
	<p>终端服务器授权（Windows&reg; Terminal Server）</p>
	</li>
	<li class="marginB0">
	<p>企业VLP授权</p>
	</li>
</ul>
</div>

<h1 id="p3">创建、编辑PDF与OCR</h1>

<p>为了呈现更出色的作业质量以及提升生产力，棣南为文电通精心设计了创建、编辑PDF以及OCR（光学字符识别）功能。凭借文电通PDF套装版6，您可以：</p>

<div class="box1">
<h3><strong>转换为可编辑的PDF</strong></h3>

<p>将Excel或Word转换而来的PDF变为可修改的状态，让编辑变得既简单又直观。</p>

<ul>
	<li class="marginB0">
	<p>转换静态的PDF为可编辑的文件。</p>
	</li>
	<li class="marginB0">
	<p>一键切换审阅与编辑模式。</p>
	</li>
	<li class="marginB0">
	<p>以丰富完整的功能编辑文件，例如字体、段落、表格、图片和脚注。</p>
	</li>
	<li class="marginB0">
	<p>直接编辑Excel文档。</p>
	</li>
</ul>
</div>

<div class="box1">
<h3><strong>OCR（光学字符识别）</strong></h3>

<p>让全新的OCR功能助您将扫描版文件转换成可搜索的PDF文档。</p>

<ul>
	<li class="marginB0">
	<p>打开扫描版PDF文档并执行OCR功能。</p>
	</li>
	<li class="marginB0">
	<p>在转换文件格式时执行OCR功能。</p>
	</li>
	<li class="marginB0">
	<p>利用文电通检查并排除错误的识别结果。</p>
	</li>
</ul>
</div>

<div class="box1 marginB70">
	<h3><strong>双向批量转换</strong></h3>

	<p class="marginB30">文电通PDF的双向批量转换功能，不仅速度快 、压缩率高 、降低CPU占用率，还支持所有可打印的文件类型。</p>

	<div id="p35" class="marginB10">
		<div class="box7">
		<p class="font18"><strong>转换后档案大小</strong></p>

		<p class="font30"><span style="color:#35a562"><strong>少10.2%</strong></span></p>
		</div>

		<div class="box7">
		<p class="font18"><strong>转换速度</strong></p>

		<p class="font13">Word/Excel/PPT转换为PDF</p>

		<p class="font30"><span style="color:#35a562"><strong>快40.5%</strong></span></p>
		</div>

		<div class="box7">
		<p class="font18"><strong>CPU使用量</strong></p>

		<p class="font13">Word/Excel/PPT转换为PDF</p>

		<p class="font30"><span style="color:#35a562"><strong>少40%</strong></span></p>
		</div>
	</div>

	<p class="from">*数据源：棣南PDF技术研究测试中心</p>
</div>

<h1 id="p4">浅谈云端、订阅版对您的影响</h1>

<p class="marginB50">随着云端运算时代的来临，订阅版授权成为新兴的软件授权趋势。不过在您顺应潮流之前，不妨先了解软件授权的种类与优缺点。</p>

<h2 id="p41">A. 订阅版授权与永久型授权</h2>

<p class="marginB0">营销话术往往将订阅版授权塑造为更佳的选择。但是何不在购买之前仔细比较个中差异呢？</p>

<p>请您先询问自己下列三个问题：</p>

<div class="box1 marginB50">
<ol>
	<li class="marginB0">
	<p><strong>这套软件的用途是？</strong></p>
	</li>
	<li class="marginB0">
	<p><strong>是否需要频繁的更新软件？</strong></p>
	</li>
	<li class="marginB0">
	<p><strong>公司偏好将软件费用列为投资成本（CAPEX）还是营运成本（OPEX）？</strong></p>
	</li>
</ol>
</div>

<h3 class="marginB40"><strong>以Acrobat DC Pro订阅版为例：</strong></h3>

<p class="marginB30"><strong>软件费用</strong><br />
订阅版授权的总费用大约在第39个月与一套永久授权相同。之后，订阅版授权支付的费用将超过永久型授权。</p>

<p class="marginB30"><strong>更新频率</strong><br />
如果作业内容仅使用到PDF的基础功能，例如注释、签名和编辑，其实不太需要频繁更新/升级到最新的软件版本。</p>

<p class="marginB30"><strong>软件维护服务</strong><br />
永久授权也提供了软件维护服务，让升级变得更简易、更具成本效益，而不是只有订阅版才能享有免费升级。</p>

<p class="marginB50"><strong>投资成本或运营成本</strong><br />
相较于长期列为运营成本，资本密集型的企业为了获得递延税项的好处，倾向于将软件费用列为投资成本。</p>

<h2 id="p42" class="marginB40">B. 云端带来的风险</h2>

<p class="marginB50">在Adobe DC提供的「Document Cloud」服务中，您必须通过Adobe的服务器传递数据，但也别忘了十年来发生的大规模信息安全事件<sup>(vi)</sup>：</p>

<div class="redbox1 marginB50">
	<div>
	<p><strong><span style="color:#c40000">2013 - ADOBE</span></strong></p>

	<p><span style="color:#c40000">约<strong>1.53亿</strong>笔客户资料外流。</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2017 - Equifax</span></strong></p>

	<p><span style="color:#c40000">约<strong>1.48亿</strong>用户的生日、地址、信用卡号、电话遭到窃取，后续耗资7亿美金收尾。</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2018 - Mariott</span></strong></p>

	<p><span style="color:#c40000"><strong>5.0亿</strong>笔客户个资外泄，最终以2400万美元达成和解。</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2019 - Facebook</span></strong></p>

	<p><span style="color:#c40000"><strong>5.4亿</strong>笔纪录受到影响，包括了电话、名字、性别、地址等。</span></p>
	</div>
</div>

<h3 class="marginB50"><strong>加入云端行列之前，有三项需要慎重考虑的重要因素：</strong></h3>

<div class="box1 marginB50">
<ul>
	<li>
	<p><strong>云端安全性</strong><br />
	全方位防堵网络攻击是困难的任务，意味着将重要数据储存在云端，未必100%的安全。</p>
	</li>
	<li>
	<p><strong>访问权限</strong><br />
	用户的访问权限往往只受限于客户端，但运营商或黑客或许能直接存取在服务器上的重要数据。</p>
	</li>
	<li>
	<p><strong>司法管辖权</strong><br />
	若储存数据的服务器不在本国的司法管辖权内，可能会导致复杂的法律问题，例如无法要求永久移除您的数据。</p>
	</li>
</ul>
</div>

<h2 id="p43">C. 您真的需要连接云端的功能吗？</h2>

<p>您真的需要用到「在线发送并追踪文件」、「随时随地创建PDF」，或「在iPad新增、编辑或重排页面」这些功能吗？</p>

<p>同样的，请您先思考下列这三个问题：</p>

<div class="box1">
<ol>
	<li>
	<p><strong>您是用计算机完成绝大部份工作？</strong></p>
	</li>
	<li>
	<p><strong>公司可以接受通过公有云发送内部资料？</strong></p>
	</li>
	<li>
	<p><strong>移动设置是否具备足够的资安防护？</strong></p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<p>尽管移动设备相当便利，我们依旧习惯在办公室中用电脑完成作业。当然，某些情况下用移动设备处理工作确实必要，但不可否认鼠标、键盘和大屏幕的组合才是生产力的来源，而这并非移动设备的优势所在。</p>

<p>除此之外，即便您需要使用云端存储服务，您也可以通过各种常见的云端空间储存和发送文件。既然您有许多免费的选择，又何必付费购买？</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 id="p5">您软件的投资回报率？</h1>

<p>以庞杂功能、巨大安装空间闻名的Adobe Acrobat，单机授权费用一套 &yen;4000 (含税) 元。</p>

<p>现在，想想您的工作流程，再询问自己这几个问题：</p>

<div class="box1">
<ol>
	<li>
	<p><strong>究竟那些功能才是我工作时用到的？</strong></p>
	</li>
	<li>
	<p><strong>Acrobat的功能中，有多少您几乎从未使用过？</strong></p>
	</li>
	<li>
	<p><strong>一次付清4000元或月付101元，值得吗？</strong></p>
	</li>
</ol>

<p><span style="color:#800000">（*价格为换算价格，仅供参考，实际价格以官方报价为准。）</span></p>
</div>

<p>&nbsp;</p>

<h3><strong>以下是一般而言使用率极低的功能，但您却不自觉的一齐买单了</strong></h3>

<p style="text-align:center"><img alt="useless fuctions" src="${require(`../../assets/img/resources/useless_fuctions_cn.png`)}" /></p>

<p>&nbsp;</p>

<h2><strong>或者，您需要的是轻巧、高性价比，且表现毫不逊的产品？</strong></h2>

<div class="box5">
	<h2><span style="color:#ffffff">文电通PDF套装版</span></h2>
	<div class="marginR20">
	<p><span style="color:#ffffff">永久型授权</span></p>

	<p><span style="color:#ffe600">&yen; <strong class="strong48">1250</strong> *</span></p>
	</div>
	<div>
	<p><span style="color:#ffffff">订阅版授权(年付)</span></p>

	<p><span style="color:#ffe600">&yen; <strong class="strong48">168</strong></span></p>
	</div>

	<p><span style="color:#ffffff">*单价随购买数量增加而降低。例如：您购买5套Acrobat Pro的授权，相同的数量，文电通PDF可以为您省下人民币13750元。</span></p>
</div>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 id="p6">企业与您的最佳选择：文电通PDF</h1>

<p>我们希望这5项观点能协助您了解自身的需求，并做出最适合的采购决策。在软件预算、安全性与灵活性的考虑与日俱增的情况下，选择聪明又实在的解决方案，才是为您和您的企业增加竞争力的关键。如今Acrobat已不再是唯一的选项，是否要转换成更实用、更经济、更合理的软件，全然操之您手。</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 style="text-align:center"><span style="color:#37915c">立即联系棣南，了解文电通PDF如何成为轻巧、无缝接轨的替代方案!</span></h1>

<p style="text-align:center"><a href="https://www.rightpdf.com/zh-CN/contactUs" target="_blank" style="width:210px;height:60px;background:#087A4F;display:inline-block;font-size:17px;color:#fff;line-height:60px;border-radius:4px;margin-top:40px;">联系棣南文电通</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>
<div id="p37">
<p><strong>关于棣南</strong></p>

<p><strong>棣南新宇正在改变用户与PDF的交互方式。不同于常见的PDF软件，我们的产品理念侧重「自由编辑」，令用户不需经过繁复操作或寻找源文件，就能轻松创建、编辑与修改PDF文档。文电通集编辑、直观界面、丰富功能于一体，赋予全球专业人士和企业组织无比的效率、生产力与想象力。</strong></p>
</div>
<p>&nbsp;</p>

<p>数据源</p>

<ol style="list-style-type:lower-roman">
	<li><strong>IDC.</strong> http://www.slideshare.net/flexerasoftware/sw-licensing-and-pricing-results-ss11-konary</li>
	<li><strong>Business Wire</strong>. http://www.businesswire.com/news/home/20150224005807/en/Vendor-audits-rise-28-software-remains-unused</li>
	<li><strong>Gartner, Inc.</strong> https://www.gartner.com/doc/2669415/surviving-adobe-software-license-audit</li>
	<li><strong>Windows Central.</strong> https://www.windowscentral.com/there-are-now-12-billion-office-users-60-million-office-365-commercial-customers</li>
	<li><strong>Adobe Systems.</strong> https://helpx.adobe.com/acrobat/system-requirements.html</li>
	<li><strong>SecureWorld.</strong> https://www.secureworld.io/industry-news/top-10-data-breaches-of-all-time</li>
</ol>`;
return (<div>
	<MetaTags>
	   <title>{metaTagscontent.title}</title>
	   <meta name="title" content={metaTagscontent.title} />
	   <meta name="description" content={metaTagscontent.desc} />
	   <meta property="og:title" content={metaTagscontent.title} />
	   <meta property="og:description" content={metaTagscontent.desc} />
   </MetaTags>
   <div dangerouslySetInnerHTML={{__html:viewcontent}}></div>
</div>);
}
export default SuiteCompare;
