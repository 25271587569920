import React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ReleaseContentBlock = props => {
    const {dataObj} = props;

    const renderTextBlock = (obj,isLevel2) => {
        let returnSubDom = null;
    
        returnSubDom = Object.keys(obj).map((key,index)=>{
            //if(obj[key]){
                if(obj[key] instanceof Object){
                    return renderContentBlockList(obj[key],true);
                }else{
                    return obj[key] ? (
                        <div className={["textArea",isLevel2?"areaLevel2":""].join(' ')} key={index}>
                            <span className="label">{isLevel2?'○ ':'• '}</span>
                            <span className="blockText">{obj[key]}</span>
                        </div>
                    ):null;
                }
            //}
                
        });

        return returnSubDom;
    }
    const renderContentBlockList = (array,isLevel2) => {
        
        let returnDom = null;
        returnDom = array.map((e,i)=>{
            return (
                <div className="releaseContentBlock" key={i}>
                    {
                        isLevel2?(
                            <div className="textArea">
                                <span className="label">• </span>
                                <span className="blockText">{e.subtitle}</span>
                            </div>
                        ):(
                            <div className="blockSubTitle title_style">{e.subtitle}</div>
                        )
                     }
                        {renderTextBlock(e.text,isLevel2)}

                    <div class='cRow maxW1300 padding-0 tips'>{e.alert}</div>
                </div>
                
            )
        })
        return returnDom;
    }
    return (
        <div>
            {renderContentBlockList(dataObj)}
        </div>
    )
}

export default ReleaseContentBlock;