const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我该如何区分永久型授权、订阅版授权的差别？ </title></head><body>  <h1>我该如何区分永久型授权、订阅版授权的差别？ </h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。请记得更新您的网址与email地址。</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong> </strong></p>
<h3><strong>永久型授权：</strong></h3>
<ul>
<li>使用序列号来激活/反激活 </li>
<li>移到其他电脑前必须要反激活 </li>
<li>除非订阅了「软件维护服务」，不然无法免费升级到新的主要版本 </li>
<li>购买后同一个主要版本永久使用 </li>
<li>提供<a href="https://www.rightpdf.com/zh-CN/Resources/compared/remoteEnterprise" target="_blank" rel="noopener">终端服务器授权</a>（Terminal Server License）方案</li>
</ul>
<h3><strong>订阅版授权：</strong></h3>
<ul>
<li>以年或月为单位订阅一个PDF文电通产品 </li>
<li>较永久型授权价格低廉，随付即用 </li>
<li>必须要有联网环境 </li>
<li>以Right PDF帐号登入以使用产品，不受限于电脑 </li>
<li>在网站介面上管理，分配，添加或延长授权 </li>
<li>在订阅期间内免费升级至主要版本 </li>
</ul>
<p>如需更多信息，请访问<strong><a href="https://store.rightpdf.cn/store/overview.aspx" target="_blank" rel="noopener">https://store.rightpdf.cn/store/</a></strong>或联系 <strong><span class="wysiwyg-color-cyan130"><a href="mailto:sales@rightpdf.com" target="_self" rel="undefined">sales@rightpdf.com</a></span></strong></p>
<p> </p>
<h3><span class="wysiwyg-color-green130"><strong>延伸阅读：</strong></span></h3>
<ul>
<li><a href="https://www.rightpdf.com/zh-CN/Resources/compared/subscriptionCompare" target="_blank" rel="noopener">文电通套装版 订阅版与永久授权比较</a></li>
</ul></body></html>`;   export default html;