import React from 'react';
//import { useDispatch} from 'react-redux';
import ReleaseContentBlock from '../../components/release/releaseContentBlock';

const SuiteRelease = props => {
    const {t} = props;
    //const [selectList,setSelectList] = useState('');
    const pageDataObj = {
            info:{
                t1:t('resources.suiteRelease.name')
            },
            releaseTitleList:{
                releaseTitle24:t('resources.suiteRelease.releaseTitle24'),
                releaseTitle23:t('resources.suiteRelease.releaseTitle23'),
                releaseTitle22:t('resources.suiteRelease.releaseTitle22'),
                releaseTitle21:t('resources.suiteRelease.releaseTitle21'),
                releaseTitle20:t('resources.suiteRelease.releaseTitle20'),
                releaseTitle18:t('resources.suiteRelease.releaseTitle18'),
                releaseTitle19:t('resources.suiteRelease.releaseTitle19'),
                releaseTitle1:t('resources.suiteRelease.releaseTitle1'),
                releaseTitle2:t('resources.suiteRelease.releaseTitle2'),
                releaseTitle3:t('resources.suiteRelease.releaseTitle3'),
                releaseTitle4:t('resources.suiteRelease.releaseTitle4'),
                releaseTitle5:t('resources.suiteRelease.releaseTitle5'),
                releaseTitle6:t('resources.suiteRelease.releaseTitle6'),
                releaseTitle7:t('resources.suiteRelease.releaseTitle7'),
                releaseTitle8:t('resources.suiteRelease.releaseTitle8'),
                releaseTitle9:t('resources.suiteRelease.releaseTitle9'),
                releaseTitle10:t('resources.suiteRelease.releaseTitle10'),
                releaseTitle11:t('resources.suiteRelease.releaseTitle11'),
                releaseTitle12:t('resources.suiteRelease.releaseTitle12'),
                releaseTitle13:t('resources.suiteRelease.releaseTitle13'),
                releaseTitle14:t('resources.suiteRelease.releaseTitle14'),
                releaseTitle15:t('resources.suiteRelease.releaseTitle15'),
                releaseTitle16:t('resources.suiteRelease.releaseTitle16'),
                releaseTitle17:t('resources.suiteRelease.releaseTitle17')
            },
            releaseContentBlock:{
                releaseTitle24:[
                   {
                       subtitle:t('resources.suiteRelease.releaseContent.releaseTitle24.item1.subtitle'),
                       text: {
                           t1:t('resources.suiteRelease.releaseContent.releaseTitle24.item1.text.t1')
                       }
                   },
                   {
                       subtitle:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.subtitle'),
                       text: {
                           t1:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t1'),
                           t2:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t2'),
                           t3:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t3'),
                           t4:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t4'),
                           t5:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t5'),
                           t6:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t6'),
                           t7:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t7'),
                           t8:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t8'),
                           t9:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t9'),
                           t10:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t10'),
                           t11:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t11'),
                           t12:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t12'),
                           t13:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t13'),
                           t14:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t14'),
                           t15:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t15'),
                           t16:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t16'),
                           t17:t('resources.suiteRelease.releaseContent.releaseTitle24.item2.text.t17')
                       }
                    }
                ],
                releaseTitle23:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle23.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle23.item1.text.t1')
                        }
                    }
                ],
                releaseTitle22:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle22.item1.text.t10')
                        }
                    }
                ],
                releaseTitle21:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle21.item1.text.t8')
                        }
                    }
                ],
                releaseTitle20:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle20.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle20.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle20.item1.text.t2')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t8'),
                            t9:[
                                {
                                    subtitle:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t9.subtitle'),
                                    text:{
                                        t1: t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t9.text.t1'),
                                        t2: t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t9.text.t2'),
                                        t3: t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t9.text.t3'),
                                        t4: t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t9.text.t4')
                                    }
                            }
                            ],
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t13'),
                            t14:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t14'),
                            t15:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t15'),
                            t16:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t16'),
                            t17:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t17'),
                            t18:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t18'),
                            t19:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t19'),
                            t20:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t20'),
                            t21:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t21'),
                            t22:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t22'),
                            t23:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t23'),
                            t24:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t24'),
                            t25:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t25'),
                            t26:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t26'),
                            t27:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t27'),
                            t28:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t28'),
                            t29:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t29'),
                            t30:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t30'),
                            t31:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t31'),
                            t32:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t32'),
                            t33:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t33'),
                            t34:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t34'),
                            t35:t('resources.suiteRelease.releaseContent.releaseTitle20.item2.text.t35')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle20.item3.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle20.item3.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle20.item3.text.t2')
                        }
                    },
                ],
                releaseTitle18:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle18.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle18.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle18.item1.text.t2')
                        }
                    }
                ],
                releaseTitle19:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle19.item1.text.t10')
                        }
                    }
                ],
                releaseTitle1:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle1.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle1.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle1.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle1.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle1.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle1.item1.text.t5')
                        }
                    }
                ],
                releaseTitle2:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle2.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle2.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle2.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle2.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle2.item1.text.t4')
                        }
                    }
                ],
                releaseTitle3:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle3.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle3.item1.text.t1')
                        }
                    }
                ],
                releaseTitle4:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle4.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle4.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle4.item1.text.t2')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t13'),
                            t14:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t14'),
                            t15:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t15'),
                            t16:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t16'),
                            t17:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t17'),
                            t18:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t18'),
                            t19:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t19'),
                            t20:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t20'),
                            t21:t('resources.suiteRelease.releaseContent.releaseTitle4.item2.text.t21')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t13'),
                            t14:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t14'),
                            t15:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t15'),
                            t16:t('resources.suiteRelease.releaseContent.releaseTitle4.item3.text.t16'),
                        }
                    }
                ],
                releaseTitle5:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle5.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle5.item1.text.t1')
                        }
                    }
                ],
                releaseTitle6:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle6.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle6.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle6.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle6.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle6.item1.text.t4')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle6.item2.text.t13')
                        }
                    }
                ],
                releaseTitle7:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle7.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle7.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle7.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle7.item1.text.t3')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t13'),
                            t14:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t14'),
                            t15:t('resources.suiteRelease.releaseContent.releaseTitle7.item2.text.t15')
                        }
                    }
                ],
                releaseTitle8:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t13'),
                            t14:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t14'),
                            t15:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t15'),
                            t16:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t16'),
                            t17:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t17'),
                            t18:t('resources.suiteRelease.releaseContent.releaseTitle8.item1.text.t18'),
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle8.item2.text.t13')
                        }
                    }
                ],
                releaseTitle9:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle9.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle9.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle9.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle9.item1.text.t3')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle9.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle9.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle9.item2.text.t2')
                        }
                    }
                ],
                releaseTitle10:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle10.item1.text.t9')
                        }
                    }
                ],
                releaseTitle11:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle11.item1.text.t11')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle11.item2.text.t8')
                        }
                    }
                ],
                releaseTitle12:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t13'),
                            t14:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t14'),
                            t15:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t15'),
                            t16:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t16'),
                            t17:t('resources.suiteRelease.releaseContent.releaseTitle12.item1.text.t17')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t13'),
                            t14:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t14'),
                            t15:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t15'),
                            t16:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t16'),
                            t17:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t17'),
                            t18:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t18'),
                            t19:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t19'),
                            t20:t('resources.suiteRelease.releaseContent.releaseTitle12.item2.text.t20')
                        }
                    }
                ],
                releaseTitle13:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle13.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle13.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle13.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle13.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle13.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle13.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle13.item1.text.t6')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t13'),
                            t14:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t14'),
                            t15:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t15'),
                            t16:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t16'),
                            t17:t('resources.suiteRelease.releaseContent.releaseTitle13.item2.text.t17')
                        }
                    }
                ],
                releaseTitle14:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle14.item1.text.t8')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle14.item2.text.t8')
                        }
                    }
                ],
                releaseTitle15:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle15.item1.text.t9')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle15.item2.text.t13')
                        }
                    }
                ],
                releaseTitle16:[
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t3'),
                            t4:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t4'),
                            t5:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t5'),
                            t6:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t6'),
                            t7:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t7'),
                            t8:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t8'),
                            t9:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t9'),
                            t10:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t10'),
                            t11:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t11'),
                            t12:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t12'),
                            t13:t('resources.suiteRelease.releaseContent.releaseTitle16.item1.text.t13')
                        }
                    },
                    {
                        subtitle:t('resources.suiteRelease.releaseContent.releaseTitle16.item2.subtitle'),
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle16.item2.text.t1'),
                            t2:t('resources.suiteRelease.releaseContent.releaseTitle16.item2.text.t2'),
                            t3:t('resources.suiteRelease.releaseContent.releaseTitle16.item2.text.t3')
                        }
                    }
                ],
                releaseTitle17:[
                    {
                        subtitle: '',
                        text: {
                            t1:t('resources.suiteRelease.releaseContent.releaseTitle17.item1.text.t1'),
                        }
                    }
                ]
            }
        }
    
    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) { 
				//anchorElement.scrollIntoView({block: "start", behavior: "smooth" }); 
				document.documentElement.scrollTop = anchorElement.offsetTop - document.getElementsByTagName("header")[0].clientHeight;
			}
        }
    }

    const renderTitleList = obj => {
        let returnli = null;
        returnli = Object.keys(obj).map((key,index)=>{
            const onClick = () => {
                scrollToAnchor(key);
            };
            return obj[key] !== ''?(
                <li className="releaseTitleLink" key={index}>
                    <div onClick={onClick}>• {obj[key]}</div>
                </li>
            ):'';
        })
        return returnli;
    }

    const renderContentBlockList = obj => {
        let returDom = null;
        returDom = Object.keys(obj).map((key,index)=>{
            return pageDataObj.releaseTitleList[key] !== ''?(
                <div className="releaseContentBlockList" id={key} key={index}>
                    <div className="blockTitle title_style">{pageDataObj.releaseTitleList[key]}</div>
                    <ReleaseContentBlock {...props} dataObj={obj[key]} key={key} />
                    
                </div>
            ):'';
        })
        return returDom;
    }
       
    return (
        <div className="releaseContent">

            <div className="productCompareInfo suiteReleaseInfoWrap">
                <h2 className="font-weight-bold title_style">{pageDataObj.info.t1}</h2>
            </div>
            
            <div>
                <ul>
                {
                    renderTitleList(pageDataObj.releaseTitleList)
                }
                </ul>
            </div>
            <div className='tips'>{t('resources.productDoc.tips')}</div>
            {
                renderContentBlockList(pageDataObj.releaseContentBlock)
            }
            {/*
            <div className="compareTableNote">
                <span className="d-inline-block mr-3">
                    ●：{t('comparePage.note.support')}
                </span>
                <span className="d-inline-block mr-3">
                    ○：{t('comparePage.note.supportApart')}
                </span>
                <span className="d-inline-block">
                    ─：{t('comparePage.note.unsupport')}
                </span>
            </div> */}
        </div>
    )
}

export default SuiteRelease;