const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何找回Word里消失的Right PDF/ Gaaiho PDF工具栏（加载项/add-in）?</title></head><body>  <h1>如何找回Word里消失的Right PDF/ Gaaiho PDF工具栏（加载项/add-in）?</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>問題描述：</strong></h2>
<p>MS Office上方工具栏中找不到Right PDF/ Gaaiho PDF加载项（add-in）。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">以下列出三个可能的原因以及相应的解决方案。</span></p>
<h3>
<br><strong><span class="wysiwyg-color-cyan130">原因 A：Right PDF/ Gaaiho Word工具栏图标被隐藏了</span></strong>
</h3>
<h3><strong><span class="wysiwyg-color-blue130">解决方案：</span></strong></h3>
<p><span class="wysiwyg-color-blue130">如果启动Word 2003以后Right PDF/ Gaaiho菜单项可用，右键点击工具栏并且选中「Right PDF Word」/「Gaaiho PDF Word」</span></p>
<p> </p>
<h3><strong><span class="wysiwyg-color-cyan130">原因 B：附加项被关闭或者禁用</span></strong></h3>
<h3><strong><span class="wysiwyg-color-blue130">解决方案：</span></strong></h3>
<ul>
<li><strong><span class="wysiwyg-color-blue130">在Microsoft Word 2010 和 2013 中：</span></strong></li>
</ul>
<p class="wysiwyg-indent4"><span class="wysiwyg-color-blue130">1. 请到「文件&gt; 选项&gt; 加载项」。</span><br><span class="wysiwyg-color-blue130">2. 并在最下方选择「COM加载项」并点击「转到」</span><br><span class="wysiwyg-color-blue130">3. 然后在列表中选择<strong>「Right PDF Addin」</strong><span>和</span><strong>「Right PDF Export PDF Addin」或「Gaaiho Office Addin」</strong><span>和</span><strong>「Gaaiho Export PDF Addin」。</strong></span></p>
<ul>
<li><strong><span class="wysiwyg-color-blue130">若在「COM加载项」中无上述选项 或 在 Microsoft Word 2003 中：</span></strong></li>
</ul>
<p class="wysiwyg-indent4"><span class="wysiwyg-color-blue130">1. 选择「说明&gt; 关于Microsoft Office Word」</span><br><span class="wysiwyg-color-blue130">2. 点击「停用的项目...」按钮。如果 Right PDF/Gaaiho Office Addin 或 Right PDF/ Gaaiho Export PDF Addin 已列出，请选中它并点击「启用」按钮以成功显示Right PDF/ Gaaiho功能表项目和工具列图示</span><br><span class="wysiwyg-color-blue130">3. 否则，请点击「工具&gt;模板&gt; 安全性...」并且在「安全性层级」标签中选择「高」或者「中」</span><br><span class="wysiwyg-color-blue130">4. 在「受信任的发布者」标签中选中「信任所有已安装的加载项和模板」</span></p>
<p> </p>
<h3><strong><span class="wysiwyg-color-cyan130">原因 C：安装之后，PDF Word相关附加项没有注册成功</span></strong></h3>
<h3><strong><span class="wysiwyg-color-blue130">解决方案 1(建议)：</span></strong></h3>
<p><span class="wysiwyg-color-blue130">1.从「程序和功能」中，选择「Right PDF Pro」/ 「Gaaiho PDF Suite」</span><br><span class="wysiwyg-color-blue130">2. 并点击「修复」</span></p>
<p> </p>
<h3><strong><span class="wysiwyg-color-blue130">解决方案 2：</span></strong></h3>
<p><span class="wysiwyg-color-blue130">1. 点击「开始」并搜索「命令提示符」(在Windows 7或以上，请在“附件”中查找)</span><br><span class="wysiwyg-color-blue130">2. 右键单击「命令提示符」，并选择「以系统管理员身分运行」</span><br><span class="wysiwyg-color-blue130">3. 根据您Windows 操作系统和Office 的位元数，在「命令提示符」中输入相应命令</span></p>
<ul>
<li>Windows 64位元 + Office 32位元</li>
</ul>
<p><span class="wysiwyg-color-blue130">输入</span> regsvr32 "C:\\Program Files (x86)\\Zeon\\Right PDF(<em>Gaaiho</em>)\\Right PDF Pro<em>(Gaaiho PDF Suite)</em>\\PDF Driver\\OfficeAddIn\\ ZPDFOfficeAddin_x86.dll"</p>
<ul>
<li>Windows 64位元 + Office 64位元</li>
</ul>
<p><span class="wysiwyg-color-blue130">输入</span> regsvr32 "C:\\Program Files (x86)\\Zeon\\Right PDF(<em>Gaaiho</em>)\\Right PDF Pro<em>(Gaaiho PDF Suite)</em>\\PDF Driver\\OfficeAddIn\\ ZPDFOfficeAddin_x64.dll"</p>
<ul>
<li>Windows 32位元 + Office 32位元</li>
</ul>
<p><span class="wysiwyg-color-blue130">输入</span> regsvr32 "C:\\Program Files\\Zeon\\Right PDF(<em>Gaaiho</em>)\\Right PDF Pro<em>(Gaaiho PDF Suite)</em>\\PDF Driver\\OfficeAddIn\\ ZPDFOfficeAddin_x86.dll"</p>
<p><span class="wysiwyg-color-red">注意：如果产品没有安装在C盘，请使用合适的字符代替字符「C:」</span></p></body></html>`;   export default html;