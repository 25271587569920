import React from 'react';
import {Link} from 'react-router-dom';
const ProductBoxMoblie = props =>  {
    const {t,linkObj} = props;
    return (
        <div className="productMoblieBlock mobileScroll">
            <ul className="productBlocklist text-white">
                <li>
					<Link to={`/${props.currentLang.type}${linkObj.product.suite.path}`}>
						<div className="width120">
							<i className="windows"></i>
							<i className="mac"></i>
						</div>
						<div className="titleInfo">{t(linkObj.product.header.block1.t1)}</div>
					</Link>
                </li>
                <li>
					<Link to={`/${props.currentLang.type}${linkObj.product.converter.path}`}>
						<div className="width120">
							<i className="windows"></i>
						</div>
						<div className="titleInfo">{t(linkObj.product.header.block2.t1)}</div>
					</Link>
                </li>
                <li>
					<Link to={`/${props.currentLang.type}${linkObj.product.server.path}`}>
						<div className="width120">
							<i className="windows"></i>
						</div>
						<div className="titleInfo">{t(linkObj.product.header.block3.t1)}</div>
					</Link>
                </li>
                <li>
					<Link to={`/${props.currentLang.type}${linkObj.product.reader.pc.path}`}>
						<div className="width120">
							<i className="windows"></i>
						</div>
						<div className="titleInfo">{t(linkObj.product.header.block4.t1)}</div>
					</Link>
                </li>
                <li>
					<Link to={`/${props.currentLang.type}${linkObj.product.reader.app.path}`}>
						<div className="width120">
							<i className="ios"></i>
							<i className="android"></i>
						</div>
						<div className="titleInfo">{t(linkObj.product.header.block4.t1)}</div>
					</Link>
                </li>
                <li>
                    <Link to={`/${props.currentLang.type}${linkObj.product.sdk.path}`}>
						<div className="width120"></div>
						<div className="titleInfo">{t(linkObj.product.header.block5.t1)}</div>
					</Link>
                </li>
                <li>
					<a href={linkObj.product.addpdf.url()}>
						<div className="width120">
							<i className="online"></i>
						</div>
						<div className="titleInfo">{t(linkObj.product.header.block7.t1)}</div>
					</a>
                </li>
            </ul>
        </div>
    )
}

export default ProductBoxMoblie;