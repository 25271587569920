const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>双击PDF文件变直接打印 或 PDF文件的右键菜单变成「打印」而非「开启」</title></head><body>  <h1>双击PDF文件变直接打印 或 PDF文件的右键菜单变成「打印」而非「开启」</h1>  <h2><strong>问题描述：</strong></h2>
<p>双击PDF文件时，会直接打印该文件，而不是以文电通开启PDF文件，或是在PDF文件上方点击右键的菜单第一个选项变成了「打印」而非「开启」。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<ol>
<li>如果是特定文件，则可能是该PDF文件被嵌入了直接打印的JavaScript。</li>
<li>如果所有PDF文件都这样，则可能是你的「注册表(regedit)」中里少了特定信息。在特定操作系统上，有极小机率会发生此现象。</li>
</ol>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<h3>
<strong>方法</strong><strong>1</strong><strong>：</strong>
</h3>
<ol>
<li><span class="wysiwyg-color-blue130">开打开文电通PDF套装版编辑器 Right PDF Editor 或 Right PDF Reader <em>(Gaaiho Doc 或 Gaaiho Reader)</em></span></li>
<li><span class="wysiwyg-color-blue130">前往「文件」&gt;「首选项」&gt;「JavaScript」，取消勾选「启用JavaScript」</span></li>
<li><span class="wysiwyg-color-blue130">再次尝试开启该PDF文件</span></li>
</ol>
<p> </p>
<h3>
<strong>方法2</strong><strong>：</strong>
</h3>
<p><span class="wysiwyg-color-blue130">打开「注册表编辑器」进行以下设置：</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">执行或搜索「regedit」进入「注册表编辑器」</span></li>
<li>
<span class="wysiwyg-color-blue130"><span class="wysiwyg-color-blue130"><span class="wysiwyg-color-blue130">前往以下位置（以下以 RightPDFEditor 为例)，请根据不同产品更换示例路径中的<strong>RightPDFEditor.Document</strong><br></span></span></span><span class="wysiwyg-color-blue130"></span>
</li>
</ol>
<p><span class="wysiwyg-color-blue130">Right PDF文电通套裝版: <strong>RightPDFEditor.Document</strong></span></p>
<p><span class="wysiwyg-color-blue130">Right PDF Reader: <strong>RightPDFReader.Document</strong></span></p>
<p><span class="wysiwyg-color-blue130">Gaaiho PDF文电通套裝版: <strong>GaaihoDoc.Document</strong></span></p>
<p><span class="wysiwyg-color-blue130"><strong>Computer\\HKEY_LOCAL_MACHINE\\SOFTWARE\\Classes\\</strong></span><span class="wysiwyg-color-red"><strong>RightPDFEditor.Document</strong></span><span class="wysiwyg-color-blue130"><strong>\\shell\\open\\command<br><img src="https://store.rightpdf.com/images/rtsupport/330181.png"><br></strong></span></p>
<ol>
<li>
<span class="wysiwyg-color-blue130">单击右键，修改(默认)数据为<strong>"C:\\Program Files (x86)\\ZEON\\Right PDF\\Right PDF Pro\\bin\\RightPDFEditor.exe" "%1"</strong></span><br>​<img src="https://store.rightpdf.com/images/rtsupport/?330182.png"><br><br><strong><span class="wysiwyg-color-red">注意</span>：以上位置需与实际安装的路径对应<br><img src="https://store.rightpdf.com/images/rtsupport/330183.png"></strong>​</li>
</ol></body></html>`;   export default html;