const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我该如何⽤群组管理我的授权（企业⽤）？</title></head><body>  <h1>我该如何⽤群组管理我的授权（企业⽤）？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p>对于购买了许多产品的企业用户来说，群组管理功能是个好帮⼿。管理员可以根据自身需要，建立群组并分配产品授权以便后续管理，例如分为市场部、财务部、客户服务部门等。</p>
<p><span class="wysiwyg-color-red">请注意：⽬前此功能只对累计购买了10套以上永久授权的用户开放。</span></p>
<p> </p>
<p>1. 请先<a href="https://support.rightpdf.com/hc/zh-cn/articles/201121713-%E5%A6%82%E4%BD%95%E7%BB%91%E5%AE%9A%E6%88%91%E7%9A%84%E6%8E%88%E6%9D%83-%E5%BA%8F%E5%88%97%E5%8F%B7-" target="_self" rel="undefined">绑定Right PDF/ Gaaiho账号和授权（序列号）</a>才能使⽤此功能。</p>
<p>2. 绑定后请⾄<strong>授权管理</strong>页面，点击<strong>群组管</strong><strong>理</strong>。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip4 (2).png" alt="mceclip4 (2).png" width="611" height="363"></p>
<p> </p>
<p>3. 在群组管理页面中，点击<strong>新建群组</strong>，输入群组名如HR、财务等，点击<strong>创建</strong>。完成后即可在下⽅列表中管理各个群组。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip1 (10).png" alt="mceclip1 (10).png" width="598" height="371"></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (11).png" alt="mceclip2 (11).png" width="594" height="484"></p>
<p><strong><span class="wysiwyg-color-green120">重命名：</span></strong>重新命名该群组。</p>
<p><strong><span class="wysiwyg-color-green120">删除：</span></strong>删除之后，产品序列号会变回无所属群组的状态。</p>
<p> </p>
<p>4. 在各授权的<strong>所属群组</strong>列，点击<strong>编辑</strong>，将此授权序列号分发到该群组。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (7).png" alt="mceclip3 (7).png"></p>
<p> </p>
<p>5. 选择群组后，点击<strong>保存</strong>按钮保存修改。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip5 (3).png" alt="mceclip5 (3).png" width="616" height="423"></p>
<p><span class="wysiwyg-color-green120"><strong>新建群组：</strong></span>创建新分类群组。</p>
<p><span class="wysiwyg-color-green120"><strong>重置：</strong></span>清除已选择的群组选项（重置后需点击保存，才可保存此次修改)。</p>
<p> </p>
<p>6. 点击群组名称可以查看该群组下的产品序列号。点击<strong>全部</strong>，即可返回到所有授权序列号的页面。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip7 (2).png" alt="mceclip7 (2).png" width="645" height="175"></p>
<p> </p>
<p>若无序列号属于该群组，会显⽰<span class="wysiwyg-color-orange110">尚未分配序列号到该群组。您可以点击“所属群组”栏下的“编辑”按钮，分配产品序列号给该群组。</span></p>
<p><span class="wysiwyg-color-orange110"><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (12).png" alt="mceclip2 (12).png" width="642" height="167"></span></p>
<p> </p></body></html>`;   export default html;