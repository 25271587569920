const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>当我使用在线激活时候，出现错误「无法连接到激活服务器」?</title></head><body>  <h1>当我使用在线激活时候，出现错误「无法连接到激活服务器」?</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>问题描述：</strong></h2>
<p>当使用在线激活授权时，出现错误「无法连接到激活服务器」，授权激活失败。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>如果您浏览网页没有任何问题，则这个问题可能是由于您的代理服务器设置引起的。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">有两种方法解决这个问题：</span></p>
<h3><span class="wysiwyg-color-blue130"><strong>解决方案一：</strong></span></h3>
<p><span class="wysiwyg-color-blue130">1. 当弹出「网络错误」对话框时候，点击「高级设置」 </span><br><span class="wysiwyg-color-blue130">2. 选择「HTTP」，输入您的代理服务器的IP地址、端口、用户名、密码， 然后点「确定」 </span><br><span class="wysiwyg-color-blue130">3. 再试一次，应该就能成功激活了</span></p>
<h3><span class="wysiwyg-color-blue130"><strong>解决方案二：</strong></span></h3>
<p><span class="wysiwyg-color-blue130">1. 运行Internet Explorer然后选择「工具 &gt; <strong>Internet选项</strong>」</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/cn_cn_1.png" alt=""></p>
<p><br><span class="wysiwyg-color-blue130">2. 选择标签页「连接」，并且点击「<strong>局域网设置</strong>」 </span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/cn_cn_2.png" alt=""></p>
<p><br><span class=" wysiwyg-color-blue130">3. 选中「为LAN使用代理服务器」并输入您的代理服务器的IP地址和端口</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/cn_cn_3.png" alt=""></p>
<p> </p>
<p><span class=" wysiwyg-color-blue130">4. 在Gaaiho PDF套件3.0的激活过程中，将出现要求您的用户名和密码的对话框。完成它们并结束在线激活过程。</span></p>
<p><span class=" wysiwyg-color-red"><strong>注意</strong>：如果您不能连接到我们的网页，请选择</span><span class="wysiwyg-color-red">离线激活</span><span class=" wysiwyg-color-red"><a href="https://support.rightpdf.com/hc/zh-cn/articles/4462193952793" target="_self">Right PDF</a>/ <span><a href="https://support.rightpdf.com/hc/zh-cn/articles/360018792431" target="_self" rel="undefined">Gaaiho</a></span>。</span></p>
<p> </p>
<h3><span class="wysiwyg-color-green130"><strong>延伸阅读</strong><strong>：</strong></span></h3>
<ul>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/201342383-%E9%94%99%E8%AF%AF%E4%BB%A3%E7%A0%816001223%E6%98%AF%E4%BB%80%E4%B9%88%E6%84%8F%E6%80%9D-" target="_blank" rel="noopener">错误代码6001223是什么意思？</a></li>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/222884888-%E7%BA%BF%E4%B8%8A%E6%BF%80%E6%B4%BB%E6%8E%88%E6%9D%83%E6%97%B6%E5%87%BA%E7%8E%B0%E9%94%99%E8%AF%AF-7000000-" target="_blank" rel="noopener">线上激活授权时出现错误「-7000000」</a></li>
</ul></body></html>`;   export default html;