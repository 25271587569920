import React, {useEffect} from 'react';
import {NavLink,useHistory} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import SuiteRelease from './suiteRelease';
import ConvertRelease from './convertRelease';
import ServerRelease from './serverRelease';
import ReaderRelease from './readerRelease';
import MacOSRelease from './macOSRelease';
import NewMacOSRelease from './newMacOSRelease';

const MainReleasePage = props => {
    let history = useHistory();
    const { lang } = useParams();
    const {t,linkObj,pageId, i18n, currentLang} = props;

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    const linkAry = [
        {
            name:t('resources.suiteRelease.name'),
            link:`/${props.currentLang.type}${linkObj.resources.release.path}`
        },
        {
            name:t('resources.newMacOSRelease.name'),
            link:`/${props.currentLang.type}${linkObj.resources.newMacOSRelease.path}`
        },
        {
            name:t('resources.convertRelease.name'),
            link:`/${props.currentLang.type}${linkObj.resources.convertRelease.path}`
        },
        {
            name:t('resources.serverRelease.name'),
            link:`/${props.currentLang.type}${linkObj.resources.serverRelease.path}`
        },
        {
            name:t('resources.readerRelease.name'),
            link:`/${props.currentLang.type}${linkObj.resources.readerRelease.path}`
        },
        {
            name:t('resources.macOSRelease.name'),
            link:`/${props.currentLang.type}${linkObj.resources.macOSRelease.path}`
        }
    ]
    const renderComponent = () => {
        switch(pageId){
            case 'proRelease':
                return <SuiteRelease {...props} />
            case 'newMacOSRelease':
                return <NewMacOSRelease {...props} />   
            case 'convertRelease':
                return <ConvertRelease {...props} />
            case 'serverRelease':
                return <ServerRelease {...props} />
            case 'readerRelease':
                return <ReaderRelease {...props} />
            case 'macOSRelease':
                return <MacOSRelease {...props} />    
            default:
                history.push(`/${currentLang.type}`);
                return null;
        }
    }
    return (
        <div className="releaseMain">
            <MetaTags>
                <title>{t('metaTag.suiteRelease.title')}</title>
                <meta name="title" content={t('metaTag.suiteRelease.title')} />
                <meta name="description" content={t('metaTag.suiteRelease.desc')} />
                <meta name="keywords" content={t('metaTag.suiteRelease.keywords')} />
                <meta property="og:title" content={t('metaTag.suiteRelease.title')} />
                <meta property="og:description" content={t('metaTag.suiteRelease.desc')} />
            </MetaTags>
            <ul className="tabList">
                {
                    linkAry.map((e,i)=>{
                        return (
                            <li key={i}>
                                <NavLink activeClassName="active" to={e.link}>
                                    {e.name}
                                </NavLink>
                            </li>
                        )
                    })
                }
            </ul>
            {
                renderComponent()
            }
        </div>
    )
}

export default MainReleasePage;