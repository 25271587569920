import React from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch} from 'react-redux';

const FeatureTable = props => {
    const {t,linkObj,highLightIndex,currentLang} = props;  //eslint-disable-line no-unused-vars
    const dispatch = useDispatch();
    const  openDownloadPopup = () => {
        dispatch({
            type:'OPEN_POPUP',
            content:'download'
        })
    }
    const renderHighLight = (type,tablePart) =>{
        if(type === highLightIndex){
            switch(tablePart){
                case 'td':
                    return 'borderLine dash left right'
                case 'tdb':
                    return 'borderLine dash left right bottom'
                case 'th':
                    return 'text-white grayBg-corduroy borderLine dash left right top'
                case 'span':
                    return 'greenBg'
				default:
					return ''
            }
        }else{
            switch(tablePart){
                case 'span':
                    return 'blackBg'
				default:
					return ''
            }
        }
    }
    return (
        
        <div className="tableWrap">
             <table>
                <thead>
                    <tr>
                        <th></th>
                        <th className={"font-weight-light "+renderHighLight(1,'th')}>{t('home.compare.table.th1')}</th>
                        <th className={"font-weight-light "+renderHighLight(2,'th')}>{t('home.compare.table.th2')}</th>
                        <th className={"font-weight-light "+renderHighLight(3,'th')}>
                            {t('home.compare.table.th3')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-left">
                            <p>
                                {t('home.compare.table.td1')}
                            </p>
                        </td>
                        <td className={renderHighLight(1,'td')}>
                            <span className={"circle "+renderHighLight(1,'span')}></span>
                        </td>
                        <td className={renderHighLight(2,'td')}></td>
                        <td className={renderHighLight(3,'td')}>
                            <span className={"circle "+renderHighLight(3,'span')}></span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">
                            <p>
                                {t('home.compare.table.td2')}
                            </p>
                        </td>
                        <td className={renderHighLight(1,'td')}></td>
                        <td className={renderHighLight(2,'td')}>
                            <span className={"circle "+renderHighLight(2,'span')}></span>
                        </td>
                        <td className={renderHighLight(3,'td')}>
                            <span className={"circle "+renderHighLight(3,'span')}></span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">
                            <p>
                                {t('home.compare.table.td3')}
                            </p>
                        </td>
                        <td className={renderHighLight(1,'td')}></td>
                        <td className={renderHighLight(2,'td')}></td>
                        <td className={renderHighLight(3,'td')}>
                            <span className={"circle "+renderHighLight(3,'span')}></span>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className={renderHighLight(1,'tdb')}>
                            <button className="cBtn w_text greenBg" onClick={()=>{openDownloadPopup()}}>
                                {t('home.compare.btn1')}
                            </button>
                            {/* <Link to={`/${props.currentLang.type}${linkObj.product.reader.pc.path}`} className="cBtn b_border">
                                {t('home.compare.btn3')}
                            </Link> */}
                        </td>
                        <td className={renderHighLight(2,'tdb')}>
                            <a href={linkObj.downloadLink.converter.url()} target="_blank" className="cBtn w_text greenBg" rel="noopener noreferrer">
                                {t('home.compare.btn2')}
                            </a>
                            {/* <Link to={`/${props.currentLang.type}${linkObj.product.converter.path}`} className="cBtn b_border">
                                {t('home.compare.btn3')}
                            </Link> */}
                        </td>
                        <td className={renderHighLight(3,'tdb')}>
                            <a href={linkObj.downloadLink.suite.url()} target="_blank" className="cBtn w_text greenBg" rel="noopener noreferrer">
                                {t('home.compare.btn2')}
                            </a>
                            {/* <Link to={`/${props.currentLang.type}${linkObj.product.suite.path}`} className="cBtn b_border">
                                {t('home.compare.btn3')}
                            </Link> */}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FeatureTable;