const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何减少PDF的文件大小？</title></head><body>  <h1>如何减少PDF的文件大小？</h1>  <p><span class="wysiwyg-color-red"><strong>注意：</strong></span><span>文电通PDF转换器可以在Word、Excel、PowerPoint和图片转换为PDF时减少PDF文件大小，PDF转PDF除外。</span></p>
<p><strong>将Word、Excel、PowerPoint和图片转换为PDF时压缩PDF大小：</strong><br>1、打开“文电通PDF转换器”并点击“编辑”打开转换设置。<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip11.png" alt="mceclip11.png" width="608" height="422"></p>
<p> </p>
<p>2、选择“压缩”等级。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip12.png" alt="mceclip12.png" width="609" height="404"><br><br></p>
<p>3、点击橙色箭头图标开始转换。<br><br><strong>将PDF转换PDF时进行压缩（也可以压缩非PDF文件）：</strong><br>1、选择“打印” &gt; “打印机” &gt; "Right PDF"<em> (“Gaaiho PDF”)</em>。然后点击“属性...”。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip13.png" alt="mceclip13.png" width="518" height="516"></p>
<p><br>2、选择“一般” &gt; “解析度” &gt; “高”。<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip14.png" alt="mceclip14.png" width="513" height="548"></p>
<p> </p>
<p>3、选择“PDF设置” &gt; “压缩” &gt; [压缩等级]。<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip15.png" alt="mceclip15.png" width="510" height="549"></p>
<p>4、点击“确定”。</p>
<p> </p>
<p> </p></body></html>`;   export default html;