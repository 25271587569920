import React from 'react';
import close from '../../assets/img/close.png';
import {useDispatch} from 'react-redux';
import ReactPlayer from 'react-player/lazy';
const VideoPopup = props => {
    const dispatch = useDispatch();
    const closePopup = () => {
        dispatch({
            type:'CLOSE_POPUP'
        })
    }
    const {currentLang} = props;
    const renderVideoUrl = () => {
        if(currentLang.type === 'zh-CN'){
            
            return <iframe width='720' height='360' src='https://player.youku.com/embed/XMzgxMzMzMzA2MA==?autoplay=1' frameBorder='0' allowFullScreen={true} title="youkuVideo"></iframe>
        }else{
            
            return <ReactPlayer width="100%" controls={true} url='https://youtu.be/OjmGhBIUR1I' />;
        }
    }
    return (
        <div className="appDownload" style={{'top':props.scrollTop+'px'}}>
             <div className="close text-right">
                <img src={close} className="img-fluid" onClick={()=>{closePopup()}} alt="close"/>
            </div>
            <div className="customContainer">
                <div className="cRow justifyCenter">
                    <div className="d-100">
                        {
                            renderVideoUrl()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoPopup;
