import React,{useState} from 'react';
import CompareTableBlock from '../../components/compare/compareTableBlock';
import MetaTags from 'react-meta-tags';

const SuiteCompare = props => {
    const {t} = props;
    const [selectList,setSelectList] = useState('');
    const pageDataObj = {
        info:{
            t1:t('resources.suiteCompare.name'),
            t2:t('resources.suiteCompare.info')
        },
        blockTitle:[
            {
                t1:t('GaaihoPDF_old'),
                t2:t('home.compare.table.th3_old')+' 4'
            },
            {
                t1:t('GaaihoPDF_old'),
                t2:t('home.compare.table.th3_old')+' 5'
            },
            {
                t1:t('GaaihoPDF'),
                t2:t('home.compare.table.th3_suiteCompare')+' 6'
            }
        ],
        blockList:[
            {
                info:t('comparePage.createPdf.title'),
                listAry:[
                    {
                        info:t('comparePage.createPdf.t1'),
                        td1:'○',td2:'●',td3:'●',td4:t('comparePage.createPdf.t19')
                    },
                    {
                        info:t('comparePage.createPdf.t18'),
                        type:'newFunction',
                        td1:'─',td2:'─',td3:'●',td4:t('comparePage.createPdf.t20')
                    },
                    {
                        info:t('comparePage.createPdf.t2'),
                        td1:'○',td2:'●',td3:'●',td4:t('comparePage.createPdf.t20')
                    },
                    {
                        info:t('comparePage.createPdf.t4'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t4-1'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t4-2'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t4-3'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t4-4'),
                        type:'improve',
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t3'),
                        type:'improve',
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t11'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t12'),
                        td1:'●',td2:'●',td3:'●',td4:t('comparePage.createPdf.t21')
                    },
                    {
                        info:t('comparePage.createPdf.t5'),
                        td1:'○',td2:'●',td3:'●',td4:t('comparePage.createPdf.t21')
                    },
                    {
                        info:t('comparePage.createPdf.t17'),
                        type:'newFunction',
                        td1:'─',td2:'─',td3:'●'
                    }
                ]
            },
            {
                info:t('comparePage.editPdf.title'),
                listAry:[
                    {
                        info:t('comparePage.editPdf.t8'),
                        td1:'○',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t1-1'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t1-2'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t1-3'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t6'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t5'),
                        td1:'○',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t1-4'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t9'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t1-5'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t16'),
                        td1:'●',td2:'●',td3:'●',td4:t('comparePage.createPdf.t21')
                    },
                    {
                        info:t('comparePage.editPdf.t4'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t1-6'),
                        type:'improve',
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t11'),
                        type:'improve',
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t13'),
                        type:'newFunction',
                        td1:'─',td2:'─',td3:'●'
                    }
                ]
            },
            {
                info:t('comparePage.sign.title'),
                listAry:[
                    {
                        info:t('comparePage.sign.t1-1'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.organizeWithPdf.t4'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.sign.t4'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.sign.t5'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.sign.t6'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.sign.t10'),
                        type:'newFunction',
                        td1:'─',td2:'─',td3:'●'
                    }
                ]
            },
            {
                info:t('comparePage.protectPdf.title'),
                listAry:[
                    {
                        info:t('comparePage.protectPdf.t3'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.protectPdf.t4'),
                        type:'improve',
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.protectPdf.t1-1'),
                        type:'improve',
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.protectPdf.t1-2'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.protectPdf.t1-3'),
                        type:'improve',
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.protectPdf.t5'),
                        type:'improve',
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.organizeWithPdf.t6'),
                        td1:'─',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.organizeWithPdf.t7'),
                        td1:'─',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.organizeWithPdf.t12'),
                        td1:'─',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.protectPdf.t6'),
                        td1:'─',td2:'●',td3:'●'
                    }
                ]
            },
            {
                info:t('comparePage.deploy.title'),
                listAry:[
                    {
                        info:t('comparePage.deploy.t1'),
                        td1:'●',td2:'●',td3:'●',td4:t('comparePage.createPdf.t21')
                    },
                    {
                        info:t('comparePage.deploy.t2'),
                        td1:'●',td2:'●',td3:'●',td4:t('comparePage.createPdf.t21')
                    },
                    {
                        info:t('comparePage.deploy.t2-1'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.deploy.t3'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.deploy.t4'),
                        td1:'●',td2:'●',td3:'●'
                    }
                ]
            }
        ]
    }
    const selectBlockShowHide = (info,active) => {
        pageDataObj.blockList.forEach((e)=>{
            if(info === e.info){
                if(active){
                    setSelectList('');
                }else{
                    setSelectList(e.info);
                }
            }
       })
   }

   const renderBlockList = array => {
       let returnli = null;
       returnli = array.map((e,i)=>{
           return (
            <CompareTableBlock {...props} dataObj={e} key={i} MenuSelect={selectBlockShowHide} selectList={selectList} active={e.info === selectList} />
           )
       })
       return returnli;
   }
    return (
        <div className="compareContent">
            <MetaTags>
                <title>{t('metaTag.suiteCompare.title')}</title>
                <meta name="title" content={t('metaTag.suiteCompare.title')} />
                <meta name="description" content={t('metaTag.suiteCompare.desc')} />
                <meta name="keywords" content={t('metaTag.suiteCompare.keywords')} />
                <meta property="og:title" content={t('metaTag.suiteCompare.title')} />
                <meta property="og:description" content={t('metaTag.suiteCompare.desc')} />
            </MetaTags>
            <div className="productCompareInfo productCompareInfoWrap">
                <h2 className="font-weight-bold">{pageDataObj.info.t1}</h2>
                <p>{pageDataObj.info.t2}</p>
            </div>
            <div className="compareTableBlock">
                <table className="compareTable title">
                    <thead>
                        <tr>
                            <th></th>
                            {
                                pageDataObj.blockTitle.map((e,i)=>{
                                    return (
                                        <th key={i}>
                                            <span>{e.t1}</span>
                                            <h2 className="font-weight-light">{e.t2}</h2>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
					<tbody>
						<tr>
							<td>{t('supportSystem')}</td>
							<td>{t('system')}</td>
							<td>{t('system')}</td>
							<td>{t('system1')}</td>
						</tr>
					</tbody>
                </table>
            </div>
            {
                renderBlockList(pageDataObj.blockList)
            }
            <div className="compareTableNote">
                <span className="d-inline-block mr-3">
                    ●：{t('comparePage.note.support')}
                </span>
                <span className="d-inline-block mr-3">
                    ○：{t('comparePage.note.supportApart')}
                </span>
                <span className="d-inline-block">
                    ─：{t('comparePage.note.unsupport')}
                </span>
            </div>
            <br/>
            <br/>
            <p className="compareNoteInfo">{t('comparePage.note.info_suiteCompare1')}</p>
			{/* <p className="compareNoteInfo" dangerouslySetInnerHTML={{__html: t('comparePage.note.info_suiteCompare2').replace("$1$",`<a href="https://store.rightpdf.com/download/mac.aspx">${t('comparePage.note.info_suiteCompare3')}</a>`)}}></p> */}
        </div>
    )
}

export default SuiteCompare;