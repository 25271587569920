const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>应用安全设置时出错，用户不是有效的用户或组？(Gaaiho PDF 3)</title></head><body>  <h1>应用安全设置时出错，用户不是有效的用户或组？(Gaaiho PDF 3)</h1>  <h2><strong>问题描述：</strong></h2>
<p>在套用安全性设定时发生错误，无法正常套用安全性设定。</p>
<p> </p>
<p><strong>完整信息：</strong></p>
<p><em>「应用安全设置时出错。用户不是有效的用户 <font>或组。这可能是因为此包存在问题，或者在与网络上的域控制器连接时出现问题。」</font></em></p>
<p> </p>
<h2><strong><font>原因：</font></strong></h2>
<p><font>这个信息表示其名为「Users」的组不存在。您可以在窗口中设置</font> 安全权限让所有用户都能访问。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<div>
<span class="wysiwyg-color-blue130">PDF文电通3专业版(Gaaiho3.0套件专业版)使用者，请您<a href="https://store.rightpdf.cn/download/archive.aspx" target="_blank" rel="noopener">更新版本</a> 或 <a href="https://store.rightpdf.cn/download/archive.aspx" target="_self" rel="undefined">升级至最新版</a>。</span><br><span class="wysiwyg-color-blue130">或</span>
</div>
<div>
<font><span class="wysiwyg-color-blue130"> 通常，这个错误最可能的发生原因是因为使用者用的是</span><span class="wysiwyg-color-red"><strong>非英文</strong></span><span class="wysiwyg-color-blue130">版的操作系统， </span></font><span class="wysiwyg-color-blue130"> 所以系统找不到名为「Users」的组。您需要建立一个新的组并命名为「Users」。</span>
</div></body></html>`;   export default html;