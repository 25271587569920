import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import mixinWrapper from '../hoc/mixinWrapper';
//import Carousel, {consts} from 'react-elastic-carousel';
import {SetEventPage} from '../api';
//import { bool } from 'prop-types';
import MetaTags from 'react-meta-tags';

const ContactSales = props => {
    const {currentLang, i18n} = props;
    const {lang} = useParams();
    const name = useRef('');
    const email = useRef('');
    const companyName = useRef('');
    const job = useRef('');
	const tel = useRef('');
	const num = useRef('');
    const msg = useRef('');
    const [isBtnClick, setIsBtnClick] = useState(false);
    // const getNews = useRef('');
    const [mailError] = useState(false);//setMailError

    
    const getLang = (cLang) => {
        let lang = 'CT';
        if(cLang === 'zh-TW'){
            lang = 'CT';
        }else if(cLang === 'zh-CN'){
            lang = 'CT';
        }else{
            lang = cLang.toUpperCase();
        }
        return lang;
    }


    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);


    // const setContactMsg = () => {
    //     setMessage({MsgType:getLang(currentLang.type), MsgUserName:name.current, MsgUserEmail: email.current, MsgUserCom: companyName.current, MsgUserTitle: job.current, MsgContent: msg.current, MsgIsNews: getNews.current ? 1 : 0 }).then(function (response) {
    //         if(response.data.Status === "1") {
    //             alert("留言發送成功");
    //             window.location.reload(false);
    //         } else {
    //             setIsBtnClick(true);
    //             alert("留言發送失敗")
    //         }
    //     })
    //     .catch(function (error) {
    //         console.log('err',error);
            
    //     });
    // }

    // const setBtnClick = () => {
    //     if(name.current && checkEmail(email.current) && companyName.current && job.current && msg.current) {
    //         setIsBtnClick(true);
            
    //     } else {
    //         setIsBtnClick(false);
    //     }
        
    //     console.log('rian isBtnClick',isBtnClick);
        
    // }

    const onNameChange = (event) => {
        name.current = event.target.value;
        //setBtnClick();
        //console.log('rian name',name.current);
    }

    const onEmailChange = (event) => {
        email.current = event.target.value;
        //setBtnClick();
    }
    
    const onCompanyNameChange = (event) => {
        companyName.current = event.target.value;
        //setBtnClick();
    }
    
    const onJobChange = (event) => {
        job.current = event.target.value;
        //setBtnClick();
    }
    
    const onTelChange = (event) => {
        tel.current = event.target.value;
        //setBtnClick();
    }
	
	const onNumChange = (event) => {
		num.current = event.target.value;
	}
	
	const onOtherChange = (event) => {
		if(event.target.checked){
			document.getElementById("other").disabled = false;
		}else{
			document.getElementById("other").disabled = true;
		}
	}
    
    const onMsgChange = (event) => {
        msg.current = event.target.value;
        //setBtnClick();
    }
	const closewindow = () => {
		window.location.reload(false);
	}
    const onContactSalesSubmit = () => {
        /*console.log('rian submit',name, email, companyName, job, msg, getNews);
        if(isBtnClick) {
            setIsBtnClick(false);
            setContactMsg();
        }*/
        var sendFlag = true;
		if(companyName.current === ""){
			sendFlag = false;
			document.getElementById("error1").style.display = "block";
		}else{
			document.getElementById("error1").style.display = "none";
		}
		if(name.current === ""){
			sendFlag = false;
			document.getElementById("error2").style.display = "block";
		}else{
			document.getElementById("error2").style.display = "none";
		}
		if(job.current === ""){
			sendFlag = false;
			document.getElementById("error3").style.display = "block";
		}else{
			document.getElementById("error3").style.display = "none";
		}
		if(email.current === ""){
			sendFlag = false;
			document.getElementById("error4").style.display = "block";
			document.getElementById("error4").innerHTML = "請填寫Email";
		}else{
			if(!checkEmail(email.current)){
				//setMailError(true);
				sendFlag = false;
				document.getElementById("error4").style.display = "block";
				document.getElementById("error4").innerHTML = "Email格式不正確";
				document.getElementById("error4").style.display = "block";
			}else{
				//setMailError(false);
				document.getElementById("error4").style.display = "none";
			}
		}
		if(tel.current === ""){
			sendFlag = false;
			document.getElementById("error5").style.display = "block";
		}else{
			document.getElementById("error5").style.display = "none";
		}
		if(num.current === ""){
			sendFlag = false;
			document.getElementById("error7").style.display = "block";
		}else{
			document.getElementById("error7").style.display = "none";
		}
		if(msg.current === ""){
			sendFlag = false;
			document.getElementById("error8").style.display = "block";
		}else{
			document.getElementById("error8").style.display = "none";
		}
		if(!document.getElementById("product_1").checked && !document.getElementById("product_2").checked && !document.getElementById("product_3").checked){
			sendFlag = false;
			document.getElementById("error6").style.display = "block";
			document.getElementById("error6").innerHTML = "請勾選試用產品";
		}else{
			if(document.getElementById("product_3").checked && document.getElementById("other").value === ""){
				sendFlag = false;
				document.getElementById("error6").style.display = "block";
				document.getElementById("error6").innerHTML = " 請在「其它」欄填寫您的需求";
			}else{
				document.getElementById("error6").style.display = "none";
			}
		}
		var EvpTryProduct = '';
		var EvpTryOther = '';
		if(document.getElementById("product_1").checked){
			EvpTryProduct += 'PDF文電通專業版 (Windows)';
		}
		if(document.getElementById("product_2").checked){
			if(document.getElementById("product_1").checked){
				EvpTryProduct += ',';
			}
			EvpTryProduct += 'PDF文電通macOS';
		}
		if(document.getElementById("product_3").checked){
			EvpTryOther = document.getElementById("other").value;
		}
		if(sendFlag){
			var sendParams = {
				LgeID:getLang(currentLang.type),
				EvpUserCom:companyName.current,
				EvpUserName:name.current,
				EvpUserTitle:job.current,
				EvpUserEmail:email.current,
				EvpUserTel:tel.current,
				EvpTryProduct:EvpTryProduct,
				EvpTryOther:EvpTryOther,
				EvpTryPeople:num.current,
				EvpContent:msg.current
			};
			console.log(sendParams);
			SetEventPage(sendParams).then(function(response){
				//alert("留言發送成功");
				var popdom = document.getElementById("pop");
				popdom.style.display = "flex";
				document.getElementById("pop_success").style.display = "block";
				document.getElementById("pop_fail").style.display = "none";
			},function(error){
				setIsBtnClick(true);
	            //alert("留言發送失敗")
				var popdom = document.getElementById("pop");
				popdom.style.display = "flex";
				document.getElementById("pop_success").style.display = "none";
				document.getElementById("pop_fail").style.display = "block";
			})
		}
    }
	const jump = () => {
		var dom = document.getElementById("jumpLocation");
		//document.getElementsByTagName("html")[0].scrollTop = (dom.offsetTop - 50);
		animateScroll(dom,80);
	}
	const animateScroll = (element,speed) => {
		let rect = element.getBoundingClientRect();
		let top = window.pageYOffset + rect.top;
		let currentTop = 0;
		let requestId;
		function step(timestamp) {
		  currentTop += speed;
		  if(currentTop<=top){
			window.scrollTo(0,currentTop);
			requestId = window.requestAnimationFrame(step);
		  }else{
			window.cancelAnimationFrame(requestId);
		  }
		}
		window.requestAnimationFrame(step);
	}
    const checkEmail = (mail) => {
        let re = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/g;
        re.lastIndex = 0;
    　　if(re.test(mail)){
        　　　　return true;
        }else{
        　　　　return false;
        }
    }

    return (
        <section id="businesstrial" className="grayBg-EDECE">
            <MetaTags>
                <title>全方位PDF編輯軟體 兩個月試用方案 | PDF文電通 Right PDF</title>
                <meta name="title" content="全方位PDF編輯軟體 兩個月試用方案 | PDF文電通 Right PDF" />
                <meta name="description" content="您正在尋找超值的企業用PDF軟體嗎？PDF 文電通提供專業PDF 編輯轉檔功能，包括PDF 編輯、轉檔、註解、加密、合併、OCR 等，更提供永久、訂閱版、終端伺服器版等多種授權方案，任君挑選最符合公司需求的類型，最大化預算效益，並提供全功能試用期兩個月！" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="全方位PDF編輯軟體 兩個月試用方案 | PDF文電通 Right PDF" />
                <meta property="og:description" content="您正在尋找超值的企業用PDF軟體嗎？PDF 文電通提供專業PDF 編輯轉檔功能，包括PDF 編輯、轉檔、註解、加密、合併、OCR 等，更提供永久、訂閱版、終端伺服器版等多種授權方案，任君挑選最符合公司需求的類型，最大化預算效益，並提供全功能試用期兩個月！" />
            </MetaTags>
            <div className="customContainer grayBg-white block1">
			
				<div className="box-1">
					<div className="nav">
						
						<div className="nav-a">
							<h1>
							全方位PDF編輯轉檔軟體<br />企業兩個月試用方案
							</h1>            
						</div>
						<div className="nav-b">
							<img src={require(`../assets/img/businesstrial/banner_img.png`)} width="569" height="458" alt="" />
						</div>
						
						<div className="nav-0">
						<div className="nav-1">
							<h1>
							全方位PDF編輯轉檔軟體企業兩個月試用方案
							</h1>
							<h2>
							資深在地PDF品牌，專業PDF軟體的親民選擇！
							</h2>
							<h3>
							您正在尋找超值的企業用PDF軟體嗎？
							</h3>
							<p>
							PDF 文電通提供專業PDF 編輯轉檔功能，包括PDF 編輯、轉檔、註解、加密、合併、OCR 等，解決PDF 大小事，更提供永久版、訂閱版、終端伺服器版等多種授權方案，任君挑選最符合公司需求的類型，最大化預算效益，並提供全功能試用期兩個月！
							</p>
							 <button className="banner" onClick={jump}>立即申請企業試用</button>               
						</div>
						<div className="nav-2">
						<img src={require(`../assets/img/businesstrial/banner_img.png`)} width="569" height="458" alt="" />
						</div>
							</div>   
						<div className="nav-c">
							<button className="banner" onClick={jump}>立即申請企業試用</button>
						</div> 
					</div>
				</div>
				<div className="content1">
					<h1>
					為何上萬家客戶都選擇 PDF文電通？
					</h1>
				</div>
				<div className="content1-1">
					 <h1>
					*資料來源：文電通用戶問卷
					</h1>     
				</div>
				<div className="content2a">
					<div className="content2">
						<div className="item-1">
							<div className="item-a">
								<img src={require(`../assets/img/businesstrial/img01.png`)} width="217.753" height="170" alt="" />
							</div> 
							<p>多元PDF功能，實用又實惠</p>                
						</div>               
						   <div className="item-2">
							<div className="item-a">
								<img src={require(`../assets/img/businesstrial/img02.png`)} width="282.876" height="170" alt="" />
							</div> 
							<p>友善操作介面，簡單好上手</p>                
						</div>   
						   <div className="item-3">
							<div className="item-a">
								<img src={require(`../assets/img/businesstrial/img03.png`)} width="175.411" height="170" alt="" />
							</div> 
							<p>符合ISO規範，絕佳相容性</p>                
						</div>      
						 <div className="item-4">
							<div className="item-a">
								<img src={require(`../assets/img/businesstrial/img04.png`)} width="176.364" height="170" alt="" />
							</div> 
							<p>在地技術服務，服務有保障</p>                
						</div> 
							<div className="item-5">
							<div className="item-a">
								<img src={require(`../assets/img/businesstrial/img05.png`)} width="206.588" height="170" alt="" />
							</div> 
							<p>靈活授權方式，聰明省預算</p>                
						</div> 
						
							<div className="item-6">
							<div className="item-a">
								<img src={require(`../assets/img/businesstrial/img06.png`)} width="210.535" height="170" alt="" />
							</div> 
							<p>功能適用度高，90%用戶滿意</p>                
						</div> 
					</div>
				</div>
				<div className="content4">
					<h1>
					多元功能應用
					</h1>        
				</div>
				<div className="content5a">
					<div className="content5">
						<div className="item-1">
							<div className="item-a"><img src={require(`../assets/img/businesstrial/ico01.png`)} width="120" height="120" alt="" /></div>
							<div className="item-b">
								<p>
								編輯PDF
								</p>
								<span>
								直接修改PDF內文或是新增文字等，無須轉檔，簡單有效率，更不用煩惱轉換後易跑版的問題。
								</span>
							
							</div>    
						</div>  
							<div className="item-2">
							<div className="item-a"><img src={require(`../assets/img/businesstrial/ico02.png`)} width="120" height="120" alt="" /></div>
							<div className="item-b">
								<p>
								轉換與合併
								</p>
								<span>
								製作公司文件時，使用Right PDF Converter批次轉換、合併不同格式的檔案為PDF或文件夾，再加上浮水印、密碼，建立公司專業形象。
								</span>                
							</div>    
						</div>            
							 <div className="item-3">
							<div className="item-a"><img src={require(`../assets/img/businesstrial/ico03.png`)} width="120" height="120" alt="" /></div>
							<div className="item-b">
								<p>
								OCR 光學字元辨識
								</p>
								<span>
								辨識掃描文件和圖片上的文字，成為可搜尋和編輯的狀態，然後輕鬆選取並複製。
								</span>                
							</div>    
						</div>             
							 <div className="item-4">
							<div className="item-a"><img src={require(`../assets/img/businesstrial/ico04.png`)} width="120" height="120" alt="" /></div>
							<div className="item-b">
								<p>
								註解與審閱
								</p>
								<span>
								利用Right PDF Editor豐富多元的註解工具、比較文件、共同協作等功能，與客戶一同追蹤修訂PDF文件，快速又實用！
								</span>                
							</div>    
						</div> 
						
							<div className="item-5">
							<div className="item-a"><img src={require(`../assets/img/businesstrial/ico05.png`)} width="120" height="120" alt="" /></div>
							<div className="item-b">
								<p>
								互動式表單
								</p>
								<span>
								使用識別功能將PDF文件轉換為動態表單，客戶使用免費PDF閱讀器即可填寫表單。有別於線上表單可能造成資料外流風險，相對安全可靠。
								</span>                
							</div>    
						</div> 
							<div className="item-6">
							<div className="item-a"><img src={require(`../assets/img/businesstrial/ico06.png`)} width="120" height="120" alt="" /></div>
							<div className="item-b">
								<p>
								簽名加密
								</p>
								<span>
								保護公司文件的資安，加上密碼、權限密碼或是將機密部分用密文工具徹底移除，安心省事又安全。
								</span>                
							</div>    
						</div>   
					</div>
				</div>
				<div className="content6" id="jumpLocation">
					<div className="item-1">
						<h1>
							試用申請與諮詢
						</h1>
						<h2>
						申請送出後，將在3-5個工作天內與您聯繫，確認需求後，會再發送試用授權至您的信箱。
						</h2>
					</div>
					<form>
						<div className="form-group1">
							<div className="form-group">
								<div className="type">
									<div className="Name">
										<h1>公司名稱</h1>
									</div> 
									<div id="error1" className="Name-1 icon" style={{display:"none"}}>
										<h1>請填寫公司名稱</h1>
									</div>                        
								</div>
								<div className="input-1">
									<label>                      
										<input id="param1" type="text" name="name" placeholder="" onChange={onCompanyNameChange} />
									</label>
								</div>
							
								<div className="type">
									<div className="Name">
										<h1>姓名</h1>
									</div>
									<div id="error2" className="Name-1 icon" style={{display:"none"}}>
										<h1>請填寫姓名</h1>
									</div>                        
								</div>
							    <div className="input-1">
									<label>                      
										<input id="param2" type="text" name="name" placeholder="" onChange={onNameChange} />
									</label>
								</div>
								<div className="type">
									<div className="Name">
										<h1>職稱</h1>
									</div>
									<div id="error3" className="Name-1 icon" style={{display:"none"}}>
										<h1>請填寫職稱</h1>
									</div>                        
								</div>
								<div className="input-1">
									<label>                      
										<input id="param3" type="text" name="name" placeholder=""  onChange={onJobChange} />
									</label>
								</div>
								<div className="type">
									<div className="Name">
										<h1>Email</h1>
									</div>
									<div id="error4" className="Name-1 icon" style={{display:"none"}}>
									</div>
								</div>
								<div className="input-1">
									<label>
										<input id="param4" type="text" className={["b1Text","d-100","f-15",mailError?"mailError":""].join(' ')} onChange={onEmailChange}/>
									</label>
								</div>
								<div className="type">
									<div className="Name">
										<h1>聯絡電話</h1>
									</div>
									<div id="error5" className="Name-1 icon" style={{display:"none"}}>
										<h1>請填寫聯絡電話</h1>
									</div>
								</div>
								<div className="input-1">
									<label>                      
										<input id="param5" type="text" name="name" placeholder=""  onChange={onTelChange} />
									</label>
								</div>
								<div className="type-1">
									<div className="Name">
										<h2>欲申請的試用產品 (可複選)</h2>
									</div>  
									
									<div id="error6" className="Name-1 icon" style={{display:"none"}}></div>
									
								</div>
								<div className="dashed-line"> 
								</div>
								<div className="chack">
									<div className="chack-1">
										<label>                      
											<input type="checkbox" name="product" id="product_1"/>
										</label>
									</div>
									<div className="Name">
										<h2>PDF文電通專業版 (Windows)</h2>
									</div>                      
								</div>
								<div className="chack">
									<div className="chack-1">
										<label>                      
											<input type="checkbox" name="product" id="product_2"/>
										</label>
									</div>
									<div className="Name">
										<h2>PDF文電通專業版 (macOS)</h2>
									</div>                      
								</div>
								<div className="chack chack_low">
									<div className="chack-2">
										<label>                      
											<input type="checkbox" name="product" id="product_3" onChange={onOtherChange}/>
										</label>
									</div>
									<div className="Name-1">
										<h2>其他</h2>
									</div>
									<div className="input-3">
										<label>
											<input type="text" defaultValue="" disabled id="other"/>
										</label>
									</div>
								</div>
								<div className="type-1">
									<div className="Name">
										<h2>欲申請的試用人數</h2>
									</div>
									<div id="error7" className="Name-1 icon" style={{display:"none"}}>
										<h1>請填寫試用人數</h1>
									</div>                        
								</div>
								<div className="input-1">
									<label>                      
										<input id="param7" type="text" name="name" placeholder="" onChange={onNumChange} />
									</label>
								</div>
								<div className="type-1">
									<div className="Name">
										<h2>請簡述貴司的需求</h2>
									</div>
									<div id="error8" className="Name-1 icon" style={{display:"none"}}>
										<h1>請簡述貴司的需求</h1>
									</div>                        
								</div>
								<p>(例：想取代 Adobe® Acrobat®、找替代方案、預算縮減、需要PDF編輯轉檔功能等等)</p>
								<label>                      
								  <textarea id="param8" name="message" onChange={onMsgChange}></textarea>
								</label>
								<div className="btn-group">
									<div target="_blank" className={['button cBtn b1Btn sendContactSales', isBtnClick].join(' ')} onClick={onContactSalesSubmit}>送出</div>  
								</div>
								<div className="notice">
									<h1>注意事項：</h1>
									<p>
										1.此試用僅供公司、學校、社團、政府單位等組織申請，若您是個人用戶，歡迎直接 <a href="https://store.rightpdf.com/download/pro.aspx">免費試用14天</a>。<br />
										2.申請送出後，將在3-5個工作天內與您聯繫，若急需試用請備註說明或是直接聯繫我們：<a href="mailto:sales@rightpdf.com">sales@rightpdf.com</a> 。<br />
										3.棣南股份有限公司保留隨時變更活動內容與條款的權利。
									</p>                    
								</div>
							</div>
						</div>
					</form>
				</div>
            </div>
			
			<div id="pop" style={{display:"none"}}>
				<p id="pop_success" style={{display:"none"}}>已成功送出，感謝您的申請，<br/>我們會盡快與您聯繫！</p>
				<p id="pop_fail" style={{display:"none"}}>留言發送失敗</p>
				<div className="btn-group">
					<button className="button" onClick={closewindow}>確定</button>  
				</div>  
			</div>
        </section>
    )
}

export default mixinWrapper(ContactSales);