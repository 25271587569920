const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为何我碰上了「Valid license not found」错误？</title></head><body>  <h1>为何我碰上了「Valid license not found」错误？</h1>  <h2><strong>问题描述：</strong></h2>
<p>突然之间，错误发生了并让您的转换工作停止。一个笔记本文档出现且告诉您<strong>「Valid License not found」，</strong>但您的激活状态并无异常。</p>
<p> </p>
<p><strong><span class="wysiwyg-underline">Right PDF:</span></strong></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip1 (12).png" alt="mceclip1 (12).png" width="322" height="160"></p>
<p> </p>
<p><span class="wysiwyg-underline"><strong>Gaaiho PDF:</strong></span></p>
<p><strong><img src="https://store.rightpdf.com/images/rtsupport/Valid License not found.jpg" alt=""></strong></p>
<p> </p>
<h2>
<strong>原因</strong>：</h2>
<p>有3个可能的原因：</p>
<ol>
<li>我在<strong>授权停用</strong>的状态下执行了文件转换，之后虽然激活了授权，但仍出现此错误讯息；</li>
<li>
<strong>遗失授权文件</strong>：一个重要的授权文件意外的被删除了；</li>
<li>
<strong>Spooler错误</strong>：一个名为「spooler」在激活产品后没有重新运行，导致重读取打印机档案出错。</li>
</ol>
<h2>
<br><span class="wysiwyg-color-blue130"><strong>解决方法</strong>：</span>
</h2>
<p><span class="wysiwyg-color-blue130">因为从这个错误信息很难判断正确的的原因，请您将日志档案寄送到<a href="mailto:support@rightpdf.com" target="_self" rel="undefined">support@rightpdf.com</a>或在客服网站中建立一个新的<a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_blank" rel="noopener">客服案例</a>并将日志档案作为附件。按一下许可证资讯<span class="wysiwyg-color-purple130">(Start &gt; All Programs &gt; Right PDF Pro/Converter <em>(Gaaiho &gt; Gaaiho PDF Suite/Converter)</em>&gt; 许可证资讯)</span>中的「检视记录」按钮以开启日志档案。您仍可以直接尝试第1和2个解决方法以节省时间。</span></p>
<ol>
<li><span class="wysiwyg-color-blue130"><strong>重新启动您的计算机：</strong>可解决大部分问题</span></li>
<li><span class="wysiwyg-color-blue130"><strong>遗失授权档案</strong>：请重安装产品并重开机</span></li>
<li>
<span class=" wysiwyg-color-blue130"><strong>Spooler错误</strong>: 在我们的客服专员确认是spooler造成错误之后，您可以按照以下的流程解决问题。</span><br><span class=" wysiwyg-color-blue130">A. 反安装产品</span><br><span class=" wysiwyg-color-blue130">B. 启动「运行」并输入「<span class="wysiwyg-color-purple"><strong>net stop spooler</strong></span>」，然后点击「确定」以暂时停止服务</span><br><span class=" wysiwyg-color-blue130">C. 删​​除两个文件「<strong>znprnui.dll</strong>」和「<strong>znprngraf.dll</strong>」，其路径依照您的作业系统位数分为以下两种可能：<strong><span class="wysiwyg-color-black70">[SystemRoot]:\\WINDOWS\\system32\\spool\\drivers\\w32x86\\3</span><span class="wysiwyg-color-red">(32位)</span></strong>或<strong><span class="wysiwyg-color-black70">[SystemRoot]:\\WINDOWS\\system32\\spool\\drivers\\x64\\3</span><span class="wysiwyg-color-red">(64位元)</span></strong></span><br><span class=" wysiwyg-color-blue130">D. 启动「运行」并输入「<span class="wysiwyg-color-purple"><strong>net start spooler</strong></span>」，然后按一下「确定」以重新启动服务</span><br><span class="wysiwyg-color-blue130">E. 再次安装产品</span>
</li>
</ol>
<p><br><span class=""> </span></p>
<p><span class="wysiwyg-color-blue130">如果在过程中碰上了任何问题，欢迎随时询问我们。无论是建立一个新的客服案例或寄电子邮件至<a href="mailto:%20support@rightpdf.com" target="_self"><strong><span class="wysiwyg-color-green130">support@gaaiho.com</span></strong></a>都没有问题 :)</span></p></body></html>`;   export default html;