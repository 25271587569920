const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>安装了文电通后，原本在IE处理PDF相关的程序或服务出现错误 (Gaaiho PDF)</title></head><body>  <h1>安装了文电通后，原本在IE处理PDF相关的程序或服务出现错误 (Gaaiho PDF)</h1>  <h2><strong>问题描述：</strong></h2>
<p>有些机关或公司可能会使用IE (Internet Explorer)浏览器做一些PDF文件上的处理。在安装了文电通套装版（Gaaiho PDF Suite）或阅读器（Gaaiho Reader）后，导致这些服务异常（例如：PDF文件无法显示）。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>在安装文电通时，若勾选了「设置Gaaiho Doc为默认的PDF浏览器」，则会在IE浏览器安装文电通阅读器的plugin 功能（IE无法在浏览器内直接阅读PDF文件），这可能会跟您的原服务不兼容。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">由于微软已经停止支持IE浏览器，我们也将不再更新此功能，请遇到此问题的用户依照下方说明停用此plugin：</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">请至「应用和功能」中，找到「Gaaiho PDF Suite」或「Gaaiho Reader」，并点击「修改」</span></li>
<li>
<span class="wysiwyg-color-blue130">选择「修改」&gt;「下一步」&gt;「下一步」</span><br><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (17).png" alt="mceclip2 (17).png"></span><br><br>
</li>
<li>
<span class="wysiwyg-color-blue130">取消勾选「设置Gaaiho Doc为默认的PDF浏览器」&gt;「安装」</span><br><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (10).png" alt="mceclip3 (10).png">
</li>
</ol></body></html>`;   export default html;