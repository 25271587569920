const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何解除绑定我的授权?解除绑定后可以将我的授权转移给别人吗?</title></head><body>  <h1>如何解除绑定我的授权?解除绑定后可以将我的授权转移给别人吗?</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p><span>当然可以！解除绑定之后，您就可以让您的朋友将授权码 (序列号) 绑定到他/她的账号中。<br></span></p>
<p> </p>
<p><span>1. 使用您的账号登录我们的<a href="https://store.rightpdf.cn/account/login.aspx" target="_blank" rel="noopener">网站</a></span></p>
<p><span>2. 点击「授权管理」</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (9).png" alt="mceclip3 (9).png" width="504" height="157"></p>
<p> </p>
<p><span>3. 在列表中选择序订单，然后点击旁边的「U」来解除绑定</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip1 (11).png" alt="mceclip1 (11).png"></p>
<p><span>4. 按照指示完成解除绑定的步骤</span></p>
<p> </p>
<p><span><span class="wysiwyg-color-blue130">然后让您的朋友将序号系绑定到他/她的帐号中。如何绑定请参考: </span><a href="https://support.rightpdf.com/hc/zh-cn/articles/201121713">如何绑定我的授权？</a></span></p></body></html>`;   export default html;