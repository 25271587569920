const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>先后安装了不同的产品，导致出现浮水印或者转换出错的问题</title></head><body>  <h1>先后安装了不同的产品，导致出现浮水印或者转换出错的问题</h1>  <h2><strong>问题描述：</strong></h2>
<p>转换出现浮水印或者转换出错的问题。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>先后安装了不同的产品，导致出现浮水印或者转换出错。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决办法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">针对这个问题，有两种方法可以解决：</span></p>
<p><span class="wysiwyg-color-blue130">1.重新启动电脑</span></p>
<p><span class="wysiwyg-color-blue130">如果问题依然存在，那么请参考以下做法：</span></p>
<p><span class="wysiwyg-color-blue130">2.替换gznprnui.dll、gznprngraf.dll，并在执行此动作之前「net stop spooler」，之后「net start spooler」</span></p>
<p><span class="wysiwyg-color-blue130">步骤如下：</span></p>
<p><span class="wysiwyg-color-blue130">开始&gt; 搜索程序和文件&gt; 输入「运行」 &gt; 输入「net stop spooler」，按确定。</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/330211.png" alt="330211.png"></span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/330212.png" alt="330212.png"></span></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">以PDF Driver文件夹中的gznprnui.dll以及gznprngraf.dll替换spool文件夹中的gznprnui.dll以及gznprngraf.dll。</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/330213.png" alt=""></span></p>
<p><span class="wysiwyg-color-blue130"><strong>诀窍：</strong>搜索gznprnui.dll，就会跑出两个同名档案分别在不同文件夹(分别为PDF Driver以及Spool)。 gznprngraf.dll也是如此。将PDF Driver文件夹中的两个dll剪下并贴入spool文件夹中，即可完成替换动作。</span></p>
<p><span class="wysiwyg-color-blue130">[spool文件夹可能以类似这样样貌呈现：%systemroot%\\WINDOWS\\system32\\spool\\drivers\\x64\\3(64 bits) 或</span></p>
<p><span class="wysiwyg-color-blue130">%systemroot%\\WINDOWS\\system32\\spool\\drivers\\w32x86\\3(32 bits) ]</span></p>
<p> </p>
<p> </p>
<p><span class="wysiwyg-color-blue130">开始&gt; 搜索程序和文件&gt; 输入「运行」 &gt; 输入「net start spooler」，按确定。</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/330214.png" alt=""></span></p>
<p><span class="wysiwyg-color-blue130"> <img src="https://store.rightpdf.com/images/rtsupport/330215.png" alt=""></span></p>
<p><span class="wysiwyg-color-blue130"><strong>诀窍：</strong>先找到bin，以确认dll位置，可以确保自己找到正确的dll档。</span></p>
<p><span class="wysiwyg-color-blue130">右键点击程序(Right PDF/ Gaaiho PDF Converter)的快捷&gt; 属性&gt; 打开文件档案，即可找到bin。您可以在bin中找到两个dll档。</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/330216.png" alt=""></span></p>
<p><span class="wysiwyg-color-blue130"> <img src="https://store.rightpdf.com/images/rtsupport/330217.png" alt=""></span></p>
<p><span class="wysiwyg-color-blue130">若有任何疑问都欢迎联络<strong><a href="mailto:support@rightpdf.com" target="_self" rel="undefined">support@rightpdf.com</a></strong>喔！</span></p></body></html>`;   export default html;