import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import mixinWrapper from '../../hoc/mixinWrapper';
import CaseStudiesDetail from './caseStudiesDetail';
import LegalDetail from './legalDetail';
import {useHistory} from 'react-router-dom';

const AboutDetail = props  => {
    let history = useHistory();
    const {t, i18n, currentLang} = props;
    const pathmap = {
        aboutus:'1',
        media:'3',
        caseStudies:'4',
        legal:'5'
    };
    const {lang,pathId,pageId} = useParams();

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    const renderDetailTitle = () => {
        if(pathId === 'caseStudies'){
            let title = t(pathId+'.'+pageId+'.detail.title').split('_');
            return (
                <div className="detailTitle">
                    {title.map((e,i)=>{
                        return <h2 key={i} className="font-weight-normal">{e}</h2>
                    })}
                </div>
              
            );
        }else if(pathId === 'legal'){
            return <h1 className="font-weight-normal">{t(pathId+'.'+pageId+'.title')}</h1>
        }else{
            history.push(`/${currentLang.type}`);
            return null;
        }
    }
    const renderDetail = () => {
        if(pathId === 'caseStudies'){
            return <CaseStudiesDetail {...props} pageId={pageId} />;
        }else if(pathId === 'legal'){
           return <LegalDetail {...props} pageId={pageId} />;
        }else{
            return null;
        }
    }
    return (
        <section id="aboutDetail" className="whiteBg">
            <div className="customContainer paddingWrap">
                <div className="cRow maxW1300 padding-0">
                     <p className="breadCrumb d-100 font-weight-bold text-left">
                        / {t('AboutZeon.link'+pathmap[pathId])}
                    </p>
                </div>
            </div>
           
            <div className="infoTitle-detail grayBg-seashell text-size-16">
                <div className="customContainer paddingWrap">
                    <div className="cRow maxW1300 padding-0">
                        <div className="d-100">
                            {renderDetailTitle()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="customContainer paddingWrap">
                <div className="cRow maxW1300 padding-0">
                    <div className="d-100">
                        {renderDetail()}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default mixinWrapper(AboutDetail);