const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>全新发布：文电通PDF套装版/转换器/终端服务器版4</title></head><body>  <h1>全新发布：文电通PDF套装版/转换器/终端服务器版4</h1>  <p>大家好，我是文电通客服团队的经理Brad。我知道距离上次发布新版已经有一段时间了，不过等待结束了──文电通PDF 4已经正式发布，并且能在咱们的官网下载到了。然而，并不是我们所有的PDF产品都一起到了第4代。当套装版、转换器、终端服务器版已经以新版华丽现身时，其他的产品，像是我们深受喜爱的文电通PDF阅读器，暂时还停留在第3代。但别担心！我们已经有更新他们的计画了。</p>
<p>下边是我们新版的套装版/转换器/终端服务器logo设计，不错吧！</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/thumb_suite.png" alt="" width="155" height="149"><img src="https://store.rightpdf.com/images/rtsupport/thumb_driver.png" alt="" width="152" height="146"><img src="https://store.rightpdf.com/images/rtsupport/thumb_tsl_suite.png" alt="" width="156" height="150"></p>
<p> </p>
<p>您会在Gaaiho Doc（套装版中的PDF编辑器程序）中发现我们再度加入了与PDF编辑相关的革命性功能──Excel + PDF。许多年以前，我们就能让客户在文电通程序中直接编辑PDF文档，而不须转换文件格式。当时这在业界可是前无古人的创新。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/ui_3_1_5_en.png" alt="" width="396" height="331"></p>
<p> </p>
<p>现在我们能骄傲的说：您也能对Excel做同样的事了。只要开启一个从Excel扫描至PDF的文档并使用「转换为可编辑的Excel」功能，就能得到一个支持基本编辑与计算功能的Excel文档。当然您也可以在Gaaiho Doc中建立一个空白的Excel文档。</p>
<p> </p>
<p>另一个重大变化是OCR (Optical Character Recognition)。如果您已经听说过他了，您大概不需要我的说明。如果没有，好吧，OCR的意思就是光学字元识别，一项让您可以辨识出扫描版文档上文字的科技。假如您曾经因为渴望OCR而致电我们、寄信给我们或是虔诚祈祷过，请立刻<a href="https://store.gaaiho.cn/download/central.aspx">下载</a>并试试看吧！</p>
<p> </p>
<p> <img src="https://store.rightpdf.com/images/rtsupport/ui_4_5_5_en.png" alt="" width="402" height="347"></p>
<p> </p>
<p>如果有兴趣看详细的改进事项，请到我们的「<a href="http://www.gaaiho.cn/index.php/zh-cn/support/it-resources/release-notes">版本发布信息</a>」区瞧瞧。</p>
<p> </p>
<p>好了，大伙们，最后也请你记得到我们<a href="http://www.gaaiho.cn/index.php/zh-cn/">崭新的官网</a>逛一逛。我们下次再见！</p></body></html>`;   export default html;