import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['en','zh-CN','zh-TW','jp','kr'];
const getBrowserLanguage = (list)=>{
  let type = navigator.appName;
  let lang = '';
  if(type === 'Netscape'){
    lang = navigator.language;
  }else{
    lang = navigator.userLanguage;
  }
  if(lang.indexOf('en') !== -1){
    lang = 'en';
  }
  if(lang.indexOf('ja') !== -1){
    lang = 'jp';
  }
  if(lang.indexOf('ko') !== -1){
    lang = 'kr';
  }
  let arr = list.filter((item,index)=>{
      return item === lang;
  })
  return arr[0];
}
const getDefaultLang = () => {
  let href = window.location.href;
  let arrs = href.split('/');
  let lang = arrs[3];
  return lang;
}
const getCurrentLang = (list) => {
  let lang = getDefaultLang();
  lang = lang.toLowerCase();
  let plang = lang;
  if(lang === 'zh-tw'){
      plang = 'zh-TW';
  }else if(lang === 'zh-cn'){
      plang = 'zh-CN';
  }
  let arr = list.filter((item,index)=>{
      return item === plang;
  })
  return arr[0]||getBrowserLanguage(list)||list[0];
}
i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: false,
    lng:getCurrentLang(languages),
    debug: false,
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18next;