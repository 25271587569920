import React, {useEffect} from 'react';
import {useParams,useHistory} from 'react-router-dom';
import mixinWrapper from '../../hoc/mixinWrapper';
// import {NavLink} from 'react-router-dom';
import UserManuals from './userManuals';
import Guide from './guide';

const TutorialPage = props => {
    let history = useHistory();
    const {t,i18n, currentLang} = props;
    const {lang,pathId} = useParams();


    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}			
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    const renderComponent = () => {
        if(pathId === 'userManuals'){
            return  <UserManuals {...props} />
        }else if(pathId === 'guide'){
            return <Guide {...props} />
        }else{
            history.push(`/${currentLang.type}`);
            return null;
        }
    }
    return (
        <section id="tutorial" className="whiteBg paddingBottom200">
            <div className="infoTitle  grayBg-seashell">
                <div className="customContainer paddingWrap">
                    <div className="cRow maxW1300 padding-0">
                        <h1 className="font-weight-normal">{t('tutorials.guide')}</h1>
                    </div>
                </div>
            </div>
            <div className="customContainer paddingWrap">
                <div className="cRow maxW1300 padding-0">
                    <div className="d-100 mt-80">
                        {renderComponent()}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default mixinWrapper(TutorialPage);