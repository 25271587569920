const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>[旧版] 如何在线/离线反激活 文电通2和3?</title></head><body>  <h1>[旧版] 如何在线/离线反激活 文电通2和3?</h1>  <h2><strong>在线反激活：</strong></h2>
<p><span class=" wysiwyg-color-blue130">1. 选择「开始 &gt; 所有程序 &gt; Gaaiho &gt; Gaaiho PDF Suite &gt; <strong>授权信息</strong>」，弹出「授权信息」对话框</span></p>
<p><span class=" wysiwyg-color-blue130">或是，开启文电通产品，点选「说明&gt; 授权资讯」</span></p>
<p><br><span class=" wysiwyg-color-blue130">2. 点击「反激活」开始反激活程序</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/220101.jpg" alt=""></p>
<p> </p>
<p><span class=" wysiwyg-color-blue130">3. 选择「在线反激活」确定，开始反激活过程</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/220102.jpg" alt=""></p>
<p> </p>
<p><span class=" wysiwyg-color-blue130">4. 等待完成即可</span></p>
<p> </p>
<h2><strong>离线反激活：</strong></h2>
<p><span class="wysiwyg-color-blue130">1. 选择「开始  &gt;  所有程序 &gt; Gaaiho &gt;  Gaaiho PDF Suite &gt;  <strong>授权信息</strong>」，弹出「授权信息」对话框</span><br><span class="wysiwyg-color-blue130">2. 点击「反激活」开始反激活程序</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/220103.jpg" alt=""></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">3. 选择「通过上传反激活数据」，将激活数据另存为一个扩展名为「htm」的文件</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/220104.jpg" alt=""></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/220105.jpg" alt=""></p>
<p><br><span class=" wysiwyg-color-blue130">4. 将您刚才所保存的「激活数据」文件复制到任意一台能连接到因特网的计算机上，并以浏览器打开此文件</span></p>
<p> </p>
<p><span class=" wysiwyg-color-blue130">5. 在打开的网页文件中点击「上传」按钮</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/220106.jpg" alt=""><br><span class=" wysiwyg-color-blue130">6. 离线反激活完成</span></p></body></html>`;   export default html;