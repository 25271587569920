const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>线上激活授权时出现错误「-7000000」</title></head><body>  <h1>线上激活授权时出现错误「-7000000」</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>问题描述：</strong></h2>
<p>当在线激活授权时，启动失败，出现错误码「-7000000」。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>出现错误码「-7000000」代表传输启动资料时发生错误。可能是该电脑并无连上网路，亦或者防火墙并未让程式通过。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决办法:</strong></span></h2>
<p><span class="wysiwyg-color-blue130">1. 请检查该电脑是否有连上网路。（若于无网络环境，请尝试离线激活</span><a href="https://support.rightpdf.com/hc/zh-cn/articles/4462193952793" target="_self">Right PDF</a>/ <span><a href="https://support.rightpdf.com/hc/zh-cn/articles/360018792431" target="_self" rel="undefined">Gaaiho</a></span>）</p>
<p class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130">2. 检查防火墙是否有让程式通过，请至「控制台」&gt;「系统及安全性」&gt;「Windows防火墙」&gt;「允许程序通过防火墙通讯&gt;「更改设置」&gt;「允许另一程序运行」&gt;「浏览」，并在此路徑<span>「%SystemDrive%\\Program Files (x86) \\ZEON\\Right PDF </span><em>(Gaaiho)</em><span>\\Right PDF Pro</span><em>(Gaaiho PDF Suite)</em><span>\\bin」</span>新增<strong>GZNAbout、RightPDF Editor<em>(Gaaiho Doc)</em>、及GConvertToPDF (旧版本为GPDFDirect) </strong>三个程序。</span><br><span class="wysiwyg-color-blue130">尝试重新激活文电通产品。</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/320081.png" alt="" width="635" height="436"></span></p>
<p><span class="wysiwyg-color-blue130"> 3. 如果您有使用<strong>Proxy Server</strong>，请您的网络管理员将 <span>activation.</span><strong>rightpdf</strong><span>.com (port: 80, 443)设为白名单</span></span><span class="wysiwyg-color-blue130"><span>。 </span></span><span class="wysiwyg-color-blue130">若是文电通5（Gaaiho）及更旧的版本，请设定</span><span class="wysiwyg-color-blue130">activation.<strong>gaaiho</strong>.com (port: 80, 443) 。</span></p>
<p> </p>
<h3><span class="wysiwyg-color-green130"><strong>延伸阅读</strong><strong>：</strong></span></h3>
<ul>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/201342383-%E9%94%99%E8%AF%AF%E4%BB%A3%E7%A0%816001223%E6%98%AF%E4%BB%80%E4%B9%88%E6%84%8F%E6%80%9D-" target="_blank" rel="noopener">错误代码6001223是什么意思？</a></li>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/200864527-%E5%BD%93%E6%88%91%E4%BD%BF%E7%94%A8%E5%9C%A8%E7%BA%BF%E6%BF%80%E6%B4%BB%E6%97%B6%E5%80%99-%E5%87%BA%E7%8E%B0%E9%94%99%E8%AF%AF-%E6%97%A0%E6%B3%95%E8%BF%9E%E6%8E%A5%E5%88%B0%E6%BF%80%E6%B4%BB%E6%9C%8D%E5%8A%A1%E5%99%A8-" target="_blank" rel="noopener">当我使用在线激活时候，出现错误「无法连接到激活服务器」?</a></li>
</ul></body></html>`;   export default html;