const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为什么我使用Right PDF Editor/ Gaaiho Doc打印文档却没有反应？</title></head><body>  <h1>为什么我使用Right PDF Editor/ Gaaiho Doc打印文档却没有反应？</h1>  <h2><strong>问题描述：</strong></h2>
<p>如果您在Win 7/Win 8/ Win 10/ Win Server 2012或其它以上版本的Windows系统上打印尺寸过大的文件时，有可能会遇到此问题。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>这是Windows版本基于安全考量而增加的限制。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解決方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130"><strong>在指定的位置下新增DWORD值</strong></span><br><span class="wysiwyg-color-blue130">1. 开始 &gt; 搜索程序和文件 &gt; 输入「<strong>regedit</strong>」</span><br><span class="wysiwyg-color-blue130">2. 在「注册表编辑器」窗口，进入此路径:<strong>HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\Windows NT\\CurrentVersion\\Windows</strong></span><br><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/sim 1.png" alt="sim 1.png" width="816" height="407"></span><br><span class="wysiwyg-color-blue130">3. 右键单击右侧面板，选择「新建」&gt;「DWORD (32-位) 值」。并重命名此值为: <strong>UMPDSecurityLevel</strong></span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/sim 2.png" alt="sim 2.png" width="815" height="407"></span><br><br><span class="wysiwyg-color-blue130">4. 双击<strong>UMPDSecurityLevel</strong>，并在「编辑DWORD (32-位) 值」窗口中的「数值数据」下，按照您的打印处理器的数据类型来输入相对于的值:</span><br><span class="wysiwyg-color-blue130">*EMF类型，请输入:<span class="wysiwyg-underline"><strong>00000001</strong></span></span><br><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/sim 3.png" alt="sim 3.png"></span><br><span class="wysiwyg-color-blue130">*RAW (XPS) 类型，请输入<span class="wysiwyg-underline"><strong>00000002</strong></span></span></p>
<p><span class="wysiwyg-color-blue130"><strong>5. 重启系统</strong></span><br><span class="wysiwyg-color-blue130"> </span><br><span class="wysiwyg-color-blue130"> </span><br><span class="wysiwyg-color-blue130"> </span><br><span class="wysiwyg-color-red"><span class="wysiwyg-underline"><strong>*备注</strong></span></span><span class="wysiwyg-underline wysiwyg-color-blue130"><strong>: 如何知道您的打印处理器数据类型?</strong></span><br><span class="wysiwyg-color-blue130">1. 控制面板 &gt; 硬件和声音 &gt; 查看设备和打印机</span><br><span class="wysiwyg-color-blue130">2. 右键单击要使用的打印机并选择「<strong>打印机属性</strong>」</span><br><span class="wysiwyg-color-blue130">3. 高级 &gt; <strong>打印处理器</strong></span></p>
<p><br><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/sim 4.png" alt="sim 4.png" width="386" height="432"></span></p>
<p><br><span class="wysiwyg-color-blue130">4. 在「<strong>默认数据类型</strong>」下可知道类型</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/sim 5.png" alt="sim 5.png" width="388" height="299"></span></p></body></html>`;   export default html;