const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何将文电通PDF设置为默认开启PDF文件的程序？</title></head><body>  <h1>如何将文电通PDF设置为默认开启PDF文件的程序？</h1>  <p>以下两种是最简单的方法：</p>
<ol>
<li>在任一个PDF文件上点击鼠标右键 &gt;「属性」 &gt; 选择打开方式中的「更改」&gt; 选择「Right PDF Editor」或「Right PDF Reader」<em>(选择 Gaaiho Doc或 Gaaiho Reader)</em>&gt;「确定」&gt;「套用」</li>
<li>在新版文电通中，如果您的默认PDF阅读器不是文电通时，会跳出是否要将文电通设为开启PDF的默认程序的提醒，点击「是」会跳出以下引导画面，此时，点击「继续」将会开启上述「属性」页，依照提示设置完毕后，可点击右上角「X」或「取消」回到文电通程序的工作画面</li>
</ol>
<p><span class="wysiwyg-underline">Right PDF:</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/340061.png" alt="340061.png" width="630" height="451"></p>
<p> </p>
<p><span class="wysiwyg-underline">Gaaiho PDF:</span></p>
<p> </p>
<p><img src="https://store.rightpdf.com/images/rtsupport/340062.png" alt="340062.png"></p>
<p> </p>
<p><strong>进阶设置方法</strong></p>
<p>进入Windows「设置」&gt;「应用」&gt;「默认应用」&gt;「按文件类型指定默认应用」&gt; 找到.pdf 或其它您想要以文电通开启的文件类型，点击「选择默认应用」并选择「Right PDF Editor」或「Right PDF Reader」<em>(选择 Gaaiho Doc或 Gaaiho Reader)。</em></p></body></html>`;   export default html;