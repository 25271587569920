const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我的授权突然变成过期/试用版，而且重新激活时出现了「0x20020221」错误，怎么办？</title></head><body>  <h1>我的授权突然变成过期/试用版，而且重新激活时出现了「0x20020221」错误，怎么办？</h1>  <h2><strong>问题描述：</strong></h2>
<p>您100%确定已经激活了产品且它运行正常持续了一段时间。授权突然变成了过期/试用版而且您再也无法使用全功能的产品。当您尝试重新激活产品时出现了<strong><span class="wysiwyg-color-red">「0x20020221」</span></strong>错误信息。</p>
<p><span class=""> </span></p>
<h2><strong>原因：</strong></h2>
<p>如果是文电通PDF(<span>Right PDF/ </span>Gaaiho PDF)产品，有以下两个可能的原因：</p>
<ol>
<li>您使用了转换器(Converter/ Driver)的序列号来激活套装版(Pro/ Suite)，反之亦然</li>
<li>您交替安装转换器与套装版导致授权文件中的数据不正确</li>
</ol>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">如果是原因一，请您卸载当前产品并安装正确的产品与版本；如果是原因二，请参考以下步骤</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">卸载所有文电通PDF(<span>Right PDF/ </span>Gaaiho PDF)软件</span></li>
<li>
<span class="wysiwyg-color-blue130">删除路径下的文件夹</span><br><span class="wysiwyg-color-blue130">(Windows 7)<strong>「[systemdrive]:\\ProgramData\\Zeon\\RightPDF </strong><em>(Gaaiho)</em><strong>\\ZNSL」</strong>与<strong>「[systemdrive]:\\Program Files (x86)\\Common Files\\ZEON」<br></strong>(Windows 10)：<strong>「[systemdrive]:\\ProgramData\\Zeon\\RightPDF</strong> (<em>Gaaiho)</em><strong>\\ZNSL」與「[systemdrive]:\\Program Files (x86)\\Common Files\\ZEON」與「[systemdrive]:\\用户\\[用户名]\\AppData\\Roaming\\Zeon\\RightPDF</strong> <em>(Gaaiho)</em><strong>\\ZNSL」</strong></span>
</li>
<li><span class="wysiwyg-color-blue130">重启电脑</span></li>
<li><span class="wysiwyg-color-blue130">重新安装正确版本的产品</span></li>
<li><span class="wysiwyg-color-blue130">用同序列号激活产品。如果已经达到激活数量上限，请参考<strong><a href="https://support.rightpdf.com/hc/zh-cn/articles/200876098-%E6%88%91%E6%BF%80%E6%B4%BB%E4%BA%A7%E5%93%81%E6%97%B6%E8%BE%BE%E5%88%B0%E4%BA%86%E6%8E%88%E6%9D%83%E4%B8%8A%E9%99%90-%E8%AF%B7%E9%97%AE%E8%AF%A5%E6%80%8E%E4%B9%88%E5%8A%9E-" target="_self" rel="undefined">这篇文章</a></strong></span></li>
</ol>
<p> </p>
<p><span class="wysiwyg-color-blue130">*显示隐藏文件夹： 「查看」 &gt; 勾选「隐藏的项目」<img src="https://store.rightpdf.com/images/rtsupport/inline-111558674.png" data-original-height="490" data-original-width="842"></span><br><br><br></p></body></html>`;   export default html;