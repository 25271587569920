import React, {useState} from 'react';
import LanguageSelector from './languageSelector';
// import scrolltop from '../assets/img/scrolltop.png';
import FooterBlock from './footer/footerBlock';
// import {animateScroll as scroll} from 'react-scroll'
import {Link} from 'react-router-dom';
import mixinWrapper from '../hoc/mixinWrapper';
const Footer = props => {  
    const {t,currentLang,linkObj} = props;
    const [selectList,setSelectList] = useState('');
    const footerDataArray = [
        {
            title: t('footer.product.name'),
            linkArray: [
                {
                    name:t('suite.name'),
                    path:linkObj.product.suite.path
                },
                {
                    name:t('converter.name'),
                    path:linkObj.product.converter.path
                },
                {
                    name:t('server.name'),
                    path:linkObj.product.server.path
                },
                {
                    name:(currentLang.type === 'zh-TW' || currentLang.type === 'zh-CN')?t('readerPC.name').split(' ')[0]:t('readerPC.name'),
                    path:linkObj.product.reader.pc.path
                },
                {
                    name:t('readerMobile.name'),
                    path:linkObj.product.reader.app.path
                },
                {
                    name:t('sdk.name'),
                    path:linkObj.product.sdk.path
                },
                {
                    name:t(linkObj.rightPDFOnline.name_footer),
                    url:linkObj.product.addpdf.url()
                }
            ]
        },
        {
            title: t('tutorials.name'),
            linkArray: [
                {
                    name:t(linkObj.support.faq.name),
                    url:linkObj.support.faq.url()
                },
                {
                    name:t(linkObj.support.submitTicket.name),
                    url:linkObj.support.submitTicket.url()
                },
                {
                    name:t(linkObj.tutorials.pdfhome),
                    url:'https://www.pdfhome.com/zh-TW/home'
                },
                {
                    name:t(linkObj.tutorials.userManuals.name),
                    path:linkObj.tutorials.userManuals.path
                },
                {
                    name:t(linkObj.tutorials.guide.name),
                    path:linkObj.tutorials.guide.path
                }
            ]
        },
        {
            title: t(linkObj.resources.name),
            linkArray:[
                {
                    name:t(linkObj.resources.compare.name),
                    path:linkObj.resources.compare.path
                },
                {
                    name:currentLang.type === 'zh-TW' || currentLang.type === 'zh-CN'?t(linkObj.resources.documents.name).split('/')[0]:t(linkObj.resources.documents.name),
                    path:linkObj.resources.documents.path
                },
                {
                    name:t(linkObj.resources.sdk.name),
                    path:linkObj.resources.sdk.path
                },
                {
                    name:t(linkObj.resources.oldVersion.name),
                    url:linkObj.resources.oldVersion.url()
                },
                {
                    name:t(linkObj.resources.release.name),
                    path:linkObj.resources.release.path
                }
            ]
        },
        {
            title:t(linkObj.AboutZeon.name),
            linkArray:linkObj.AboutZeon.linkAry.map((e, index)=>{
                //判斷代理商頁面(只有韓文)
                if(currentLang.type !== 'kr' && index === 5) {
                    return '';
                } 

                let obj = {
                    name:t(e.name)
                }
                if(e.path){
                    obj.path = e.path
                }else{
                    obj.url = e.url();
                }
                return obj;
            })

        },
        {
            title: t(linkObj.contact.name),
            linkArray:[
                {
                    name:t(linkObj.contact.link1),
                    path:linkObj.contact.contactSales.path
                },
                {
                    name:t(linkObj.contact.link2),
                    url:linkObj.support.submitTicket.url()
                },
                {
                    name:t(linkObj.contact.link3),
                    path:linkObj.contact.contactUs.path
                }
            ]

        },
        {
            title:t('footer.socialTitle.name'),
            social:true
        }
    ];
    let footerMenuSelect = (title,active) => {
        let newArray = [...footerDataArray]
        newArray.forEach(e=>{
            if(title === e.title){
                if(active){
                    setSelectList('');
                }else{
                    setSelectList(e.title);
                }
            }
        });
    }
    let list = footerDataArray.map((e,i) => {
        return (
            e.title !== "" ? <FooterBlock key={i} MenuSelect={footerMenuSelect} active={selectList === e.title}   footerObj={e} currentLang={currentLang} />:null
        )
    })
    // let scrollToTop = () => {
    //     scroll.scrollToTop();
    // }
    const renderLi = () =>  {
        if(currentLang.type !== 'zh-CN'){
            return (
                <li>
                    <Link to={`/${props.currentLang.type}${linkObj.legal.refund_policy.path}`}>
                        {t('legal.refund-policy.title')}
                    </Link>
                </li>
            )
        }else{
            return null;
        }
    }

    const thisYear = new Date().getFullYear();
   
    return(
        <footer>
            <div className="footerContent">
                <div className="customContainer">
                    <div className="cRow alignStart maxW1180">
                        {list}
                    </div>
                </div>
                {/* <span className="scrollTopBtn" onClick={()=>{scrollToTop()}}>
                    <img src={scrolltop} alt="scroll" />
                </span> */}
            </div>
             <div className="footerBottom customContainer">
                    <div className="footerBottomContent cRow maxW1180">
                        <LanguageSelector {...props} />
                        <ul className="statementList">
                            <li>
                                <Link to={`/${props.currentLang.type}${linkObj.legal.terms_of_use.path}`}>
                                    {t('footer.terms')}
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${props.currentLang.type}${linkObj.legal.privacy_statement.path}`}>
                                    {t('footer.privacy')}
                                </Link>
                            </li>
                            {
                                renderLi()
                            }
                        </ul>
                        <p>{t('footer.copyright1')}{thisYear}{t('footer.copyright2')}</p>
						{
							currentLang.type === 'zh-CN' ? <p>{t('footer.license1')}  <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">{t('footer.license2')}</a></p> : ""
						}
                    </div>
                </div>
        </footer>
    );
}
export default mixinWrapper(Footer);