const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>错误代码6001223是什么意思？</title></head><body>  <h1>错误代码6001223是什么意思？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>问题描述：</strong></h2>
<div>激活授权（序号）时，出现错误码「6001223」。</div>
<div> </div>
<h2><strong>原因：</strong></h2>
<div>这个错误代表传送资料给激活服务器时出现了错误，可能是<strong>防火墙</strong>、<strong>代理</strong>（proxy）等网络设置造成的问题。您可以尝试「<span class="wysiwyg-color-blue130">离线激活</span><a href="https://support.rightpdf.com/hc/zh-cn/articles/4462193952793" target="_self">Right PDF</a>/ <span><a href="https://support.rightpdf.com/hc/zh-cn/articles/360018792431" target="_self" rel="undefined">Gaaiho</a></span>」的方式或根据以下步骤检查您的设定:</div>
<div> </div>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<div><span class="wysiwyg-color-blue130">首先，您可以尝试 ping activation.rightpdf.com/ activation.gaaiho.com<br>1. 启动「命令提示字符」(Command Prompt)<br>2. 从「开始」，在搜索框中输入 cmd。(Windows 8 用户可以在开始界面中输入 "cmd"。 按↵ Enter启动「命令提示符」。)<br>3. 输入<span>"ping activation.rightpdf.com"/ "ping activation.gaaiho.com" </span> 然后按 Enter 键<br>如果ping 响应(100% 丢失)，请您e-mail至support@rightpdf.com。 如果ping 沒有(100% 丢失)，请您跳至下一步网络设置。<br><br>您可以通过以下步骤检查您Windows 的网络设置：<br>1. 通过「开始」&gt;「控制面板」<br>2. 在右上角的搜索框中输入 「网络和共享中心」并打开「网络和共享中心」<br>3. 点击左侧的「更改适配器设置」<br>4. 右键单击您想要检查的链接(无线或有线)，选择「属性(R)」。<br>5. 选择「Internet协议版本 4 （TCP/TPv4）」并选择「属性」<br>6. 请确认「使用下面的DNS服务器地址」沒有输入任何SCS DNS。<br>7. 选择「自动获得DNS服务器地址」<br></span></div>
<div> </div>
<div>
<span class="wysiwyg-color-blue130">如果您有使用<strong>Proxy Server</strong>，请您的网络管理员将 <span>activation.</span><strong>rightpdf</strong><span>.com (port: 80, 443)设为白名单</span></span><span class="wysiwyg-color-blue130"><span>。 </span></span>
</div>
<div>
<span class="wysiwyg-color-blue130">若是文电通5（Gaaiho）及更旧的版本，请设定</span><span class="wysiwyg-color-blue130">activation.<strong>gaaiho</strong>.com (port: 80, 443) 。</span>
</div>
<div>
<span class="wysiwyg-color-blue130"><br></span><span class="wysiwyg-color-black">如果仍有问题，请您联系Right PDF 客服( <a href="mailto:support@rightpdf.com" target="_self" rel="undefined">support@rightpdf.com</a> )。</span>
</div></body></html>`;   export default html;