const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>必杀技：注释小结</title></head><body>  <h1>必杀技：注释小结</h1>  <p>        <img src="https://store.rightpdf.com/images/rtsupport/01.png" alt=""></p>
<p>PDF软件的注释功能已经推出很久了，大家都知道如何用注释工具来标注文档，使用起来也颇为顺手。无论您在组织中职位高低，总会有重要的信息或意见值得反馈，所以几乎无可避免要用到注释。如果您想和其他即将要审阅您正在处理文件的同事讨论的话，大可直接在文档中加个注释来询问、说明或标记您所作出的变更。</p>
<p>在Right PDF中您可以阅读所有的注释，不过要将电子版打印成纸质版时问题就来了：「怎样才能将注释和文档一起打印出来？」（您的老板超爱纸质版的，才不管什么节能减碳呢！）</p>
<p>让我们来看看如何在Right PDF中利用 <em>创建注释</em><em>小结</em> 功能，帮您将它们「一注不漏」地打印出来。</p>
<p> </p>
<h2><strong>选项１：</strong></h2>
<p>直接选择「文件」<strong>＞「打印」</strong>开启<strong>打印</strong>对话框，在此您可以指定打印内容和进一步进行打印设置。从<strong>打印内容</strong>下拉框中，您可选择打印：文档和注释、文档内容或表单域。不过本选项无法让您设置注释的显示方式。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (9).png" alt="mceclip2 (9).png" width="564" height="565"></p>
<p>选择文档和注释可打印文档内容与所有注释。</p>
<p> </p>
<h2><strong>选项２：</strong></h2>
<p><em>「<strong>创建注释</strong></em><em><strong>小结</strong>」</em>，本功能可产生PDF中所有相关注释的概要简介，比<strong>选项１</strong>更好的是它为输出的小结提供了四种不同的版式。当使用注释小结时，您可以按照指定的版式来创建PDF与注释，并进行相应的设置。请参见下列说明以了解如何使用它！</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/230081.jpg" alt=""></p>
<ol>
<li>打开内含注释的PDF文档并选择「<strong>注释」</strong><strong>＞「</strong><strong>注释</strong><strong>处理」＞「</strong><strong>创建注释</strong><strong>小结」</strong> 。</li>
<li>在注释<strong>小结</strong>对话框中，选择一种版式（Ａ）并按照下列步骤操作：</li>
</ol>
<ul>
<li>选择需要的页面大小和字体大小（Ｂ）。</li>
<li>指定是否要将所有注释都包含于小结中或仅限当前显示的注释，以及注释的排序类型（Ｃ）。</li>
<li>指定页面范围并设置是否将没有注释的页面也含括在输出的注释小结中。（Ｄ）</li>
<li>点击<strong>确定</strong>即可建立一个包含注释并且可打印的PDF文档；或者您可以点击<strong>打印</strong><strong>注释</strong><strong>小结</strong>（Ｅ）直接将小结打印出来。</li>
</ul>
<h3>
<strong>注释小结</strong><strong>四种</strong><strong>版式</strong>
</h3>
<p>为了符合您的需求，<em>创建注释小结 </em>提供了四种不同的版式：1.单独页面分别显示文档和带连接线的注释；2.相同页面显示文档和带连接线的注释；3.仅显示注释；4.单独页面分别显示文档和带数字序号的注释。其中，您可以对连接线的颜色和透明度进行设置。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/230082.jpg" alt=""></p>
<p><strong>注释</strong><strong>小结页面</strong><strong>设置</strong><strong>选项</strong></p>
<p><strong><img src="https://store.rightpdf.com/images/rtsupport/230083.jpg" alt="" width="565" height="282"></strong></p>
<p><strong><em>A. </em></strong><em>相同页面显示文档和带连接线的注释</em></p>
<p><em><img src="https://store.rightpdf.com/images/rtsupport/230084.jpg" alt="" width="566" height="301"></em></p>
<p><strong><em>B. </em></strong><em>单独页面分别显示文档和带连接线的注释</em></p>
<p><em><img src="https://store.rightpdf.com/images/rtsupport/230085.jpg" alt="" width="565" height="283"></em></p>
<p><strong><em>C. </em></strong><em>仅显示注释</em></p>
<p><em><img src="https://store.rightpdf.com/images/rtsupport/230084.jpg" alt="" width="566" height="283"></em></p>
<p><strong><em>D. </em></strong><em>单独页面分别显示文档和带数字序号的注释</em></p>
<p> </p>
<ul>
<li>
<strong>在线帮助文件：</strong><a href="https://www.pdfhome.com/zh-TW/home/articleview?AtcID=20408" target="_blank" rel="noopener">创建注释小结</a>
</li>
<li><a href="https://store.rightpdf.com/images/rtsupport/zh-cn/requests/new" target="_blank" rel="noopener">在线支持</a></li>
<li>
<strong>技术</strong><strong>支持</strong><strong>：<a href="mailto:support@rightpdf.com" target="_self" rel="undefined">support@rightpdf.com</a></strong>
</li>
<li><strong>业务咨询：<a href="mailto:sales@rightpdf.com" target="_self" rel="undefined">sales@rightpdf.com</a></strong></li>
<li><strong>官方网站：<a href="http://www.rightpdf.com/index.php/zh-cn/" target="_self" rel="undefined">www.rightpdf.com</a></strong></li>
</ul>
<p><strong><em> </em></strong></p></body></html>`;   export default html;