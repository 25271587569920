import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import mixinWrapper from '../../hoc/mixinWrapper';
import Carousel, {consts} from 'react-elastic-carousel';
import {setMessage} from '../../api';
//import { bool } from 'prop-types';
import MetaTags from 'react-meta-tags';

const ContactSales = props => {
    const {t, currentLang, i18n, linkObj} = props;
    const {lang} = useParams();
    // const [name, setName] = useRef('');
    // const [email, setEmail] = useRef('');
    // const [companyName, setCompanyName] = useRef('');
    // const [job, setJob] = useRef('');
    // const [msg, setMsg] = useRef('');
    // const [isBtnClick, setIsBtnClick] = useRef(false);
    // const [getNews, setGetNews] = useRef('');
    const name = useRef('');
    const email = useRef('');
    const companyName = useRef('');
    const job = useRef('');
    const msg = useRef('');
    const [isBtnClick, setIsBtnClick] = useState(false);
    const getNews = useRef('');
    const [mailError, setMailError] = useState(false);

    
    const getLang = (cLang) => {
        let lang = 'EN';
        if(cLang === 'zh-TW'){
            lang = 'CT';
        }else if(cLang === 'zh-CN'){
            lang = 'CS';
        }else{
            lang = cLang.toUpperCase();
        }
        return lang;
    }


    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    
    const breakPoints = [
        { width: 500, itemsToShow: 2, pagination: false  },
        { width: 576, itemsToShow: 3, pagination: false  },
        { width: 768, itemsToShow: 4, pagination: false  },
        { width: 1300, itemsToShow: 4, pagination: false  },
    ];

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <img src={require(`../../assets/img/contact/Layer 2 2.png`)}  className="" alt="detail2" /> : <img src={require(`../../assets/img/contact/Layer 2-1 2.png`)}  className="" alt="detail2" />;
        return (
            <button onClick={onClick} disabled={isEdge}>
                {pointer}
            </button>
        )
    }
    
    const getTitle = () => {
        if(currentLang.type === 'en'){
            return (
                <div>
                    <div className="f-80 b1L1">{t('contactSales.block1Left1')}</div>
                    <div className="f-80">
                        <span className="b1L2">{t('contactSales.block1Left2')}</span>
                        <span className="b1L1">{t('contactSales.block1Left2_1')}</span>
                    </div>
                </div>
            );
        } else if (currentLang.type === 'jp'){
            return (
                <div>
                    <div className="f-80 b1L1">{t('contactSales.block1Left1')}</div>
                    <div className="f-80">
                        <span className="b1L1">{t('contactSales.block1Left2')}</span>
                        <span className="b1L2">{t('contactSales.block1Left2_1')}</span>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="f-80 b1L1">{t('contactSales.block1Left1')}</div>
                    <div className="f-80 b1L2">{t('contactSales.block1Left2')}</div>
                </div>
            );
        }
    }

    const getSubTitle = () => {
        if(currentLang.type === 'en'){
            return (
                <div className="f-40 b1L3">
                    <span>{t('contactSales.block1Left3')}</span>
                    <span className="b1L4">{t('contactSales.block1Left4')}</span>
                </div>
            );
        } else if (currentLang.type === 'jp'){
            return (
                <div className="f-40 b1L3">
                    <span className="b1L4">{t('contactSales.block1Left3')}</span>
                    <span>{t('contactSales.block1Left4')}</span>
                </div>
            );
        } else {
            return (
                <div className="f-40 b1L3">
                    <span>{t('contactSales.block1Left3')}</span>
                    <span className="b1L4">{t('contactSales.block1Left4')}</span>
                    <span>{t('contactSales.block1Left5')}</span>
                </div>
            );
        }
    }

    const setContactMsg = () => {
        setMessage({MsgType:getLang(currentLang.type), MsgUserName:name.current, MsgUserEmail: email.current, MsgUserCom: companyName.current, MsgUserTitle: job.current, MsgContent: msg.current, MsgIsNews: getNews.current ? 1 : 0 }).then(function (response) {
            if(response.data.Status === "1") {
                alert("留言發送成功");
                window.location.reload(false);
            } else {
                setIsBtnClick(true);
                alert("留言發送失敗")
            }
        })
        .catch(function (error) {
            console.log('err',error);
            
        });
    }

    const setBtnClick = () => {
        if(name.current && checkEmail(email.current) && companyName.current && job.current && msg.current) {
            setIsBtnClick(true);
            
        } else {
            setIsBtnClick(false);
        }
        
        console.log('rian isBtnClick',isBtnClick);
        
    }

    const onNameChange = (event) => {
        name.current = event.target.value;
        setBtnClick();
        console.log('rian name',name.current);
    }

    const onEmailChange = (event) => {
        email.current = event.target.value;
        if(!checkEmail(email.current)){
            setMailError(true);
        }else{
            setMailError(false);
        }
        setBtnClick();
    }
    
    const onCompanyNameChange = (event) => {
        companyName.current = event.target.value;
        setBtnClick();
    }
    
    const onJobChange = (event) => {
        job.current = event.target.value;
        setBtnClick();
    }
    
    const onMsgChange = (event) => {
        msg.current = event.target.value;
        setBtnClick();
    }

    const onCheckboxChange = (event) => {
        getNews.current = event.target.checked;
    }

    const onContactSalesSubmit = () => {
        console.log('rian submit',name, email, companyName, job, msg, getNews);
        if(isBtnClick) {
            setIsBtnClick(false);
            setContactMsg();
        }
    }
    const checkEmail = (mail) => {
        let re = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/g;
        re.lastIndex = 0;
    　　if(re.test(mail)){
        　　　　return true;
        }else{
        　　　　return false;
        }
    }
	const renderClientList = () => {
        let renderlist = [];
        let clientNum = 4;
        let path = 'en';
        if(currentLang.type === 'zh-TW'){
            clientNum = 17;
            path = 'zh-TW';
        }else if(currentLang.type === 'zh-CN'){
            clientNum = 6;
            path = 'zh-CN';
        }else if(currentLang.type === 'kr'){
            clientNum = 8;
            path = 'kr';
        }
        for(let i=0;i<clientNum;i++){
            renderlist.push(<div key={i}><img className="img-fluid" src={require(`../../assets/img/home/${path}/client_logo_${(i+1)}.jpg`)} alt=""/></div>);
        }

        return renderlist;
    }

    return (
        <section id="contactSales" className="grayBg-EDECE">
            <MetaTags>
                <title>{t('metaTag.contactSales.title')}</title>
                <meta name="title" content={t('metaTag.contactSales.title')} />
                <meta name="description" content={t('metaTag.contactSales.desc')} />
                <meta name="keywords" content={t('metaTag.contactSales.keywords')} />
                <meta property="og:title" content={t('metaTag.contactSales.title')} />
                <meta property="og:description" content={t('metaTag.contactSales.desc')} />
            </MetaTags>
            <div className="customContainer paddingWrap grayBg-EDECEA block1">
                <div className="cRow maxW1300 whiteBg b1">
                    <div className="leftArea">
                        <img src={require(`../../assets/img/contact/Mask Group 28.png`)}  className="" alt="detail2" />
                        <div className="text">
                            {getTitle()}
                            {getSubTitle()}
                            {/* <div className="f-40">
                                <span>{t('contactSales.block1Left3')}</span>
                                <span className="b1L4">{t('contactSales.block1Left4')}</span>
                                <span>{t('contactSales.block1Left5')}</span>
                            </div> */}
                            <div className="f-16 b1L5 d-100">
                                <span>{t('contactSales.block1Left6')}</span>
                            </div>
                            <div className="f-16 b1L5 d-100">
                                <span>{t('contactSales.block1Left7')}</span>
                            </div>
                            <div className="f-16 b1L5 d-100">
                                <span>{t('contactSales.block1Left8')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="rightArea">
                        <div className="form">
                            <div className="f-24 b1F1">{t('contactSales.block1Form1')}</div>
                            <div className="textArea d-100">
                                <span className="b1Label d-100 f-14">{t('contactSales.block1Form2')}</span>
                                <input type="text" className="b1Text d-100 f-15" placeholder={t('contactSales.block1Form3')} onChange={onNameChange}/>
                            </div>
                            <div className="textArea d-100">
                                <span className="b1Label d-100 f-14">{t('contactSales.block1Form4')}</span>
                                <input type="text" className={["b1Text","d-100","f-15",mailError?"mailError":""].join(' ')} placeholder={t('contactSales.block1Form5')} onChange={onEmailChange}/>
                            </div>
                            
                            <div className="textArea">
                                <div className=" d-50">
                                    <span className="b1Label d-100 f-14">{t('contactSales.block1Form6')}</span>
                                    <input type="text" className="b1Text d-100 f-15" placeholder={t('contactSales.block1Form7')} onChange={onCompanyNameChange} />
                                </div>
                                <div className=" d-50">
                                    <span className="b1Label d-100 f-14">{t('contactSales.block1Form8')}</span>
                                    <input type="text" className="b1Text d-100 f-15" placeholder={t('contactSales.block1Form9')} onChange={onJobChange} />
                                </div>
                            </div>
                        
                            {/* <div className="textArea d-50">
                                <span className="b1Label d-100 f-14">{t('contactSales.block1Form8')}</span>
                                <input type="text" className="b1Text d-100 f-15" placeholder={t('contactSales.block1Form9')} />
                            </div> */}
                            
                            <div className="textArea d-100">
                                <span className="b1Label d-100 f-14">{t('contactSales.block1Form10')}</span>
                                <textarea className="b1Text d-100 f-15" placeholder={t('contactSales.block1Form11')}  onChange={onMsgChange}></textarea>
                            </div>
                            <div className="textArea check d-100">
                                <input type="checkbox" id="b1LabelForBtn" name="b1Btn1" value="check" onClick={onCheckboxChange} />
                                <label htmlFor="b1LabelForBtn" className="b1LabelForBtn f-14">{t('contactSales.block1Form12')}</label>
                                
                            </div>
                            <div className="d-100">
                                <div target="_blank" className={['cBtn b1Btn sendContactSales', isBtnClick].join(' ')} onClick={onContactSalesSubmit}>{t('contactSales.block1Form13')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="customContainer paddingWrap grayBg-EDECEA block2">
                <div className="cRow maxW1300 greenBg-F3F7EE b2">
                    <div className="item d-50">
                        <img src={require(`../../assets/img/contact/Layer 2.png`)}  className="img-fluid" alt="detail2" />
                        <div className="contactusListText">
                            <div className="f-25 title_style">{t('contactSales.block2T1')}</div>
                            <div className="f-15">{t('contactSales.block2T2')}</div>
                        </div>
                    </div>
                    <div className="item d-50">
                        <img src={require(`../../assets/img/contact/Layer 2-1.png`)}  className="img-fluid" alt="detail2" />
                        <div className="contactusListText">
                            <div className="f-25 title_style">{t('contactSales.block2T3')}</div>
                            <div className="f-15">{t('contactSales.block2T4')}</div>
                        </div>
                    </div>
                    <div className="item d-50">
                        <img src={require(`../../assets/img/contact/Layer 2-2.png`)}  className="img-fluid" alt="detail2" />
                        <div className="contactusListText">
                            <div className="f-25 title_style">{t('contactSales.block2T5')}</div>
                            <div className="f-15">{t('contactSales.block2T6')}</div>
                        </div>
                    </div>
                    <div className="item d-50">
                        <img src={require(`../../assets/img/contact/Layer 2-3.png`)}  className="img-fluid" alt="detail2" />
                        <div className="contactusListText">
                            <div className="f-25 title_style">{t('contactSales.block2T7')}</div>
                            <div className="f-15">{t('contactSales.block2T8')}</div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="customContainer paddingWrap grayBg-EDECEA block3">
                <div className="cRow maxW1300 whiteBg b3">
                <Carousel itemsToShow={4} enableAutoPlay autoPlaySpeed={1500} pagination={false} breakPoints={breakPoints} renderArrow={myArrow}>
					{renderClientList()}
				</Carousel>

                </div>
            </div>

            <div className="customContainer paddingWrap grayBg-EDECEA block4">
                <div className="cRow maxW1300 greenBg-F3F7EE b4">
                    <div className="f-45 b4t1">{t('contactSales.block4T1')}</div>
                    <div className="img-block">
                        <div className="overlap">
                            <div>{t('contactSales.block4T21')}</div>
                            <div>
                                <span>{t('contactSales.block4T22')}</span>
                                <span>{t('contactSales.block4T23')}</span>
                            </div>
                            <div>{t('contactSales.block4T24')}</div>
                            <img src={require(`../../assets/img/contact/fivestars_inverse.png`)} className="img-fluid" alt="detail6"></img>

                        </div>
                    </div>
                    <div className="items">
                        <div className="item d-50">
                            <div className="contactusListText f-15">
                                <div>{t('contactSales.block4T31')}</div>
                                <div>
                                    <span className="f-13">{t('contactSales.block4T32')}</span>
                                    <span className="f-12">{t('contactSales.block4T33')}</span>
                                </div>
                                <div>{t('contactSales.block4T34')}</div>
                                <img src={require(`../../assets/img/contact/fivestars.png`)} className="img-fluid" alt="detail6"></img>
                            </div>
                        </div>
                        <div className="item d-50">
                            <div className="contactusListText f-15">
                                <div>{t('contactSales.block4T41')}</div>
                                <div>
                                    <span className="f-13">{t('contactSales.block4T42')}</span>
                                    <span className="f-12">{t('contactSales.block4T43')}</span>
                                </div>
                                <div>{t('contactSales.block4T44')}</div>
                                <img src={require(`../../assets/img/contact/fivestars.png`)} className="img-fluid" alt="detail6"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="customContainer paddingWrap grayBg-EDECEA block5">
                <div className="cRow maxW1300 whiteBg b5">
                    <div className="b5t1">
                        <span className="f-52">
                            {t('contactSales.block5T1')}
                            <span className="b5t2">{t('contactSales.block5T2')}</span>
                        </span>
                    </div>
                    <div className="d-100">
                        <a href={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`}  className="cBtn b5Btn">{t('contactSales.block5Btn1')}</a>
                    </div>
                    
                </div>
            </div>

            
            
        </section>
    )
}

export default mixinWrapper(ContactSales);