const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何绑定我的授权（序列号）？ </title></head><body>  <h1>如何绑定我的授权（序列号）？ </h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p><span>如果您是在一个已注册的用户账号中购买Right PDF/ Gaaiho PDF系列产品，在线支付后授权将自动绑定到该账号下。您也可以手动绑定授权到您的账户：</span><br><br><span>1. 登陆我们的<a href="https://store.rightpdf.cn/account/login.aspx" target="_blank" rel="noopener">官方网站</a>。如果您没有免费账号，请先注册账号</span><br><span>2. 从下拉式选单中选择「绑定序列号」</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (10).png" alt="mceclip2 (10).png" width="466" height="186"></p>
<p> </p>
<p><span>3. 输入您的序列号與电子邮件，然后点击「<strong>立刻绑定</strong>」按钮</span><br><span><strong><span class="wysiwyg-color-red">注意：</span></strong><span class="wysiwyg-color-red">如果您是从我们的代理商处购买产品，您必须输入原始订单中的电子邮件地址</span></span></p>
<p><span><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (6).png" alt="mceclip3 (6).png" width="556" height="290"></span></p>
<p><span>4. 注册账号之后，您的授权信息将显示在「<strong>授权管理</strong>」中。产品注册有如下好处：</span></p>
<ul>
<li><span>如果意外丢失序列号可以找回</span></li>
<li><span>能在不影响已有使用者的情况下加买授权</span></li>
<li><span>在线清除启动纪录（详：<a href="https://store.rightpdf.com/images/rtsupport/hc/zh-cn/articles/200884858-%E5%A6%82%E4%BD%95%E5%9C%A8%E7%BA%BF%E6%B8%85%E9%99%A4%E6%BF%80%E6%B4%BB%E8%AE%B0%E5%BD%95-" target="_self" rel="undefined">如何在线清除激活纪录？</a>）</span></li>
</ul></body></html>`;   export default html;