const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>在卸载程序时反激活失败怎么办？</title></head><body>  <h1>在卸载程序时反激活失败怎么办？</h1>  <h2><strong>问题描述：</strong></h2>
<p>在卸载时自动停用失败，导致授权被占用。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>卸载「文电通3.x」及以上版本时，程序自动停用授权失败，导致授权被占用。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方案：</strong></span></h2>
<ol>
<li>
<span class="wysiwyg-color-blue130">卸载「文电通3.x」及以上版本时，程序会自行反激活。如果您在卸载程序时反激活失败，或者手动删除了程序，您可以下载并运行下面的<strong>「Deactivati​​on Tool」反激活工具</strong>来解除授权和该电脑的绑定关系。<br></span><strong style="color: #ff0000;">注意：</strong><span style="color: #ff0000;">在您更换了电脑设备或变更某些系统设置情况下，这个工具可能不起作用。<br><br></span>
</li>
<li>
<span class="wysiwyg-color-blue130">或参考：</span><a href="https://support.rightpdf.com/hc/zh-cn/articles/200892328-%E6%88%91%E6%97%A0%E6%B3%95%E5%8F%8D%E6%BF%80%E6%B4%BB%E4%BA%A7%E5%93%81%E4%BA%86-%E6%80%8E%E4%B9%88%E5%8A%9E-" target="_blank" rel="noopener">我无法停用(反启动)产品了，怎么办？</a>
</li>
</ol></body></html>`;   export default html;