const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我的转换器(Converter/Driver)无法转换Word与Excel文档?</title></head><body>  <h1>我的转换器(Converter/Driver)无法转换Word与Excel文档?</h1>  <h2><strong>问题描述：</strong></h2>
<p>安装文电通PDF并成功激活授权后，使用<strong>转换器</strong>无法转换Word与Excel文件，错误讯息：「无法新增xxx档案，因为PDF文电通转换器/转换为PDF无法将其转换成PDF档案」（但可以使用 Right PDF/ Gaaiho PDF虚拟打印机或 Office中的 Right PDF/ Gaaiho PDF Addin 加载项等其它方式转换PDF文件）。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>有以下两种可能原因：</p>
<ol>
<li>您没有安装微软Office（PDF文电通转换器在转换PDF文件时，会需要使用到微软Office组件）；</li>
<li>您有安装微软Office，但没有安装「<strong>Visual Basic for Application</strong>」这个组件(application)（此案例较少见，因为除非是自定义安装，正常安装Office时，会默认安装此组件）。</li>
</ol>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">若为上述<strong>1</strong>之情形，则转换器功能将受到限制，若需使用到Office组件的转换效果，请务必安装微软Office。从文电通6开始，安装 WPS Office或 Open Office 也可支持 Word文件转换成PDF文件。</span></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">若为上述<strong>2</strong>之情形，请先在「<strong>支持的文件类型</strong>」中查看是否包含这些格式，如果没有的话，请按照这个网页的指示修复Office：<strong><a title="Link: http://office.microsoft.com/zh-cn/powerpoint-help/HA010357402.aspx?CTT=1" href="http://office.microsoft.com/zh-cn/powerpoint-help/HA010357402.aspx?CTT=1" target="_blank" rel="noopener">http://office.microsoft.com/zh-cn/powerpoint-help/HA010357402.aspx?CTT=1</a></strong>。另外请务必记得在安装过程中选取「<strong>Visual Basic for Application</strong>」这个组件。</span></p>
<p> </p></body></html>`;   export default html;