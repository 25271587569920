const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>使用批处理功能提升工作效率</title></head><body>  <h1>使用批处理功能提升工作效率</h1>  <h2><span class="wysiwyg-color-green120"><strong>什么是批处理？</strong></span></h2>
<p><span class="wysiwyg-color-black">批处理是指套用于文档的一系列命令。这些命令会根据排定的顺序，自动执行各个指定功能。当您需要在较大的文档中执行多项功能，或是以同样的功能处理多份文档、文件夹时，就可以使用批处理。不但能省去重复的操作步骤，在批处理期间也可以进行其他操作，提高工作效率。</span></p>
<p> </p>
<h2><span class="wysiwyg-color-green120"><strong>批处理可以执行哪些功能？</strong></span></h2>
<p><span class="wysiwyg-color-black">文电通PDF套装版的批处理功能分为文档、注释与页面三大类：</span><br><span class="wysiwyg-color-black"><strong>文档：</strong>打印、检查文档、删除水印、搜索并标记密文、添加水印、图章、应用密文</span><br><span class="wysiwyg-color-black"><strong>注释：</strong>迁移注释</span><br><span class="wysiwyg-color-black"><strong>页面：</strong>插入页面、删除页面、提取页面</span></p>
<p> </p>
<h2><span class="wysiwyg-color-green120"><strong>如何操作批处理？</strong></span></h2>
<p><span class="wysiwyg-color-black"><strong>Step 1.</strong><strong>  </strong>开启Right PDF Editor/ Gaaiho Doc后，选择“高级”&gt;“文档处理”&gt;“批处理”。</span></p>
<p><span class="wysiwyg-color-black"><strong>Step 2.</strong><strong>  </strong>在弹出的“批处理顺序”对话框中，选择要执行的批处理顺序。</span></p>
<ol>
<li><span class="wysiwyg-color-black">如果第一次使用，请点击“新建顺序”，再按提示进行设置。</span></li>
<li><span class="wysiwyg-color-black">如果要变更批处理命令，请点击“编辑顺序”，在“编辑批处理顺序”对话框中进行修改。</span></li>
</ol>
<p><span class="wysiwyg-color-black"><strong>Step 3.</strong><strong> </strong>在“批处理顺序”对话框左侧点击“执行顺序”，打开“执行顺序确认”对话框。展开“+”号便能确认命令的设置细节；如果要修改设置，点击「取消」便能回到前一个对话框进行修改。</span></p>
<p><span class="wysiwyg-color-black"><strong>Step 4.</strong><strong>  </strong>确认无误后点击“确定”便开始执行批处理。执行完成后出现“警告和错误”对话框，会提示执行过程中的处理细节。</span></p>
<p> </p>
<p><span class="wysiwyg-color-black">如需了解更详细的操作步骤，您可以通过以下方式获得需要的信息：</span></p>
<ul>
<li><strong><a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_blank" rel="noopener">在线支持</a></strong></li>
<li>
<span class="wysiwyg-color-black"><strong>技术</strong><strong>支持</strong><strong>：</strong></span><strong><a href="mailto:support@rightpdf.com" target="_blank" rel="noopener">support@rightpdf.com</a></strong>
</li>
<li>
<span class="wysiwyg-color-black"><strong>业务咨询：</strong></span><strong><a href="mailto:sales@rightpdf.com" target="_blank" rel="noopener">sales@rightpdf.com</a></strong>
</li>
<li>
<span class="wysiwyg-color-black"><strong>官方网站：</strong></span><strong><a href="https://www.rightpdf.com/zh-CN" target="_blank" rel="noopener">rightpdf.cn</a></strong>
</li>
</ul></body></html>`;   export default html;