const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>订阅版激活失败，出现错误码“126”：您的账号无效。请使用已授权的账号激活</title></head><body>  <h1>订阅版激活失败，出现错误码“126”：您的账号无效。请使用已授权的账号激活</h1>  <h2><strong>问题描述：</strong></h2>
<p>购买文电通订阅版授权之后，用账号和密码激活软件失败，出现错误码“126”。</p>
<p>若不清楚应如何激活订阅版授权，请参考：<a href="https://support.rightpdf.com/hc/zh-cn/articles/201031766" target="_self">文电通PDF套装版订阅版(Right PDF Subscriptions)授权如何激活？</a></p>
<h2><strong>原因：</strong></h2>
<ul>
<li>输入的账号尚未在文电通官网注册</li>
<li>输入的账号已注册，但并未在该订阅版的授权用户列表中</li>
</ul>
<h2><strong>解决方法：</strong></h2>
<ul>
<li>若尚未注册，请到<a href="https://store.rightpdf.cn/account/register.aspx" target="_self" rel="undefined">官网</a>注册文电通账号</li>
<li>若已注册账号，请管理员到“<strong>授权管理</strong>”页面， 前往“<strong>订阅版</strong>”&gt;“<strong>操作</strong>”&gt; 并单击对应授权的“<strong>授权管理</strong>”按钮（如下图红框所示），最后将该账号加入“<strong>授权用户列表</strong>”中即可。</li>
</ul>
<p><img src="https://store.rightpdf.com/images/rtsupport/410071.png" alt="410071.png"></p>
<p>如何部署订阅版授权，请参考以下FAQ: <a href="https://support.rightpdf.com/hc/zh-cn/articles/201032226" target="_self" rel="undefined">我是公司IT部门负责人，我刚购买了10个文电通PDF套装版订阅版(Right PDF Subscription)授权，要如何开始部署？</a></p></body></html>`;   export default html;