import React from 'react';
import ReleaseContentBlock from '../../components/release/releaseContentBlock';

const newMacOSRelease = props => {
    const {t} = props;
    //const [selectList,setSelectList] = useState('');
    const pageDataObj = {
            info:{
                t1:t('resources.newMacOSRelease.name')
            },
            releaseTitleList:{
                releaseTitle2:t('resources.newMacOSRelease.releaseTitle2'),
                releaseTitle1:t('resources.newMacOSRelease.releaseTitle1')
            },
            releaseContentBlock:{
                releaseTitle2:[
                   {
                       subtitle:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item1.subtitle'),
                       text: {
                           t1:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item1.text.t1')
                       }
                   },
                   {
                       subtitle:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.subtitle'),
                       text: {
                           t1:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.text.t1'),
                           t2:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.text.t2'),
                           t3:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.text.t3'),
                           t4:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.text.t4'),
                           t5:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.text.t5'),
                           t6:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.text.t6'),
                           t7:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.text.t7')
                       },
                       alert:t('resources.newMacOSRelease.releaseContent.releaseTitle2.item2.alert.a1')
                   }
                ],
                releaseTitle1:[
                    {
                        subtitle:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item1.subtitle'),
                        text: {
                            t1:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item1.text.t1')
                        }
                    },
					{
                        subtitle:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.subtitle'),
                        text: {
                            t1:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t1'),
                            t2:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t2'),
                            t3:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t3'),
                            t4:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t4'),
                            t5:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t5'),
                            t6:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t6'),
                            t7:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t7'),
                            t8:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t8'),
                            t9:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item2.text.t9')
                        }
                    },
					{
                        subtitle:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item3.subtitle'),
                        text: {
                            t1:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item3.text.t1'),
                            t2:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item3.text.t2'),
                            t3:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item3.text.t3'),
                            t4:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item3.text.t4'),
                            t5:t('resources.newMacOSRelease.releaseContent.releaseTitle1.item3.text.t5')
                        }
                    }
                ]

            }
        }
    
    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) { 
				//anchorElement.scrollIntoView({block: "start",inline:"nearest", behavior: "smooth" });
				document.documentElement.scrollTop = anchorElement.offsetTop - document.getElementsByTagName("header")[0].clientHeight;
			}
        }
    }

    const renderTitleList = obj => {
        let returnli = null;
        returnli = Object.keys(obj).map((key,index)=>{
            const onClick = () => {
                scrollToAnchor(key);
            };
            return obj[key] !== ''?(
                <li className="releaseTitleLink" key={index}>
                    <div onClick={onClick}>• {obj[key]}</div>
                </li>
            ):'';
        })
        return returnli;
    }

    const renderContentBlockList = obj => {
        let returDom = null;
        returDom = Object.keys(obj).map((key,index)=>{
            return pageDataObj.releaseTitleList[key] !== ''?(
                <div className="releaseContentBlockList" id={key} key={index}>
                    <div className="blockTitle">{pageDataObj.releaseTitleList[key]}</div>
                    <ReleaseContentBlock {...props} dataObj={obj[key]} key={key} />
                </div>
            ):'';
        })
        return returDom;
    }
       
    return (
        <div className="releaseContent">
            <div className="productCompareInfo suiteReleaseInfoWrap">
                <h2 className="font-weight-bold">{pageDataObj.info.t1}</h2>
            </div>
            
            <div>
                <ul>
                {
                    renderTitleList(pageDataObj.releaseTitleList)
                }
                </ul>
            </div>
            <div className='tips'>{t('resources.productDoc.tips')}</div>
            {
                renderContentBlockList(pageDataObj.releaseContentBlock)
            }

        </div>
    )
}

export default newMacOSRelease;