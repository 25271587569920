const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>每次打开程序都要弹出登录窗口，怎么办？</title></head><body>  <h1>每次打开程序都要弹出登录窗口，怎么办？</h1>  <p>请参考以下步骤尝试重新激活授权，如果状况持续发生，请<a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_blank" rel="noopener">与我们联系</a>。</p>
<p><strong>Right PDF Editor:</strong></p>
<p>1. 您只需要从菜单里点击「说明」&gt;「授权信息」</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410211.png" alt="410211.png" width="536" height="153"></p>
<p> </p>
<p>2.「激活」</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410212.png" alt="410212.png" width="591" height="422"></p>
<p> </p>
<p>3.「帐号」&gt; 输入帐号与密码 </p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410213.png" alt="410213.png" width="590" height="421"></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410214.png" alt="410214.png" width="592" height="423"></p>
<p> </p>
<p>4. 登录激活成功!</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410215.png" alt="410215.png" width="593" height="424"></p>
<p> </p>
<p><strong>或 Right PDF Converter:</strong></p>
<p>1.「支持」&gt;「许可证信息」</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410216.png" alt="410216.png" width="593" height="412"></p>
<p> </p>
<p>2.「激活」</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410217.png" alt="410217.png" width="591" height="422"></p>
<p> </p>
<p>3.「帐号」&gt; 输入帐号与密码 </p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410218.png" alt="410218.png" width="590" height="421"></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/410219.png" alt="410219.png" width="592" height="423"></p>
<p> </p>
<p>4. 登录激活成功!</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/4102110.png" alt="4102110.png" width="593" height="424"></p>
<p> </p></body></html>`;   export default html;