import React from 'react';
import MetaTags from 'react-meta-tags';

const SdkResources = props => {
    const {t} = props;
    const downloadAry = [
        {
            name:t('resources.sdkResources.downloadLink1'),
            type:'download',
            downloadLink:'01.PDFCMDSampleC.rar'
        },
        {
            name:t('resources.sdkResources.downloadLink2'),
            type:'download',
            downloadLink:'02.PDFCmdSampleCsharp.rar'
        },
        {
            name:t('resources.sdkResources.downloadLink3'),
            type:'download',
            downloadLink:'03.PDF CMD Reference.pdf'
        },
        {
            name:t('resources.sdkResources.downloadLink4'),
            type:'download',
            downloadLink:'04.Server_API_On_WIN2003_IIS6_ASPNET_enu.pdf'
        }
    ]
    const renderList = array => {
        return array.map((e,i)=>{
            return (
                <li key={i}>
                    <span>{e.name}</span>
                    <a href={require(`../../assets/sdkFile/${e.downloadLink}`)} target="_blank" rel="noopener noreferrer" className={'downloadLinkBtn '+e.type}> </a>
                </li>
            )
        })
    }
    return (
        <div className="resourceContent">
            <MetaTags>
                <title>{t('metaTag.sdkResources.title')}</title>
                <meta name="title" content={t('metaTag.sdkResources.title')} />
                <meta name="description" content={t('metaTag.sdkResources.desc')} />
                <meta name="keywords" content={t('metaTag.sdkResources.keywords')} />
                <meta property="og:title" content={t('metaTag.sdkResources.title')} />
                <meta property="og:description" content={t('metaTag.sdkResources.desc')} />
            </MetaTags>
            <div className="productCompareInfo productCompareInfoWrap">
                <h2 className="font-weight-bold">{t('resources.sdkResources.title')}</h2>
                <br/>
                <p className="m-0">{t('resources.sdkResources.t1')}</p>
                <p className="m-0">{t('resources.sdkResources.t2')}</p>
                <p className="m-0">{t('resources.sdkResources.t3')}</p>
            </div>
            <div className="productCompareInfo">
                <h2 className="font-weight-bold">{t('download')}</h2>
            </div>
            <div className="downloadListContent text-size-16">
                <ul className="downloadList">
                   {
                       renderList(downloadAry)
                   }
                </ul>
            </div>
        </div>
    )
}

export default SdkResources;