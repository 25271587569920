const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何在批量授权中清除掉不再使用订阅版(Right PDF Subscription)的用户？</title></head><body>  <h1>如何在批量授权中清除掉不再使用订阅版(Right PDF Subscription)的用户？</h1>  <ol>
<li>登录你的文电通<a href="https://store.rightpdf.cn/account/login.aspx" target="_blank" rel="noopener">官网账号</a>，点击管理员的 「授权管理」；
<br><img src="https://store.rightpdf.com/images/rtsupport/410151.png" alt="410151.png">
</li>
<li>在 「订阅版」> 「操作」，点击「授权管理」图示；<br><img src="https://store.rightpdf.com/images/rtsupport/410152.png" alt="410152.png">

</li>
<li>从「授权管理分配」列表中，搜索账号或名称（需符合⼤⼩写），点击	「删除」</p>

<img src="https://store.rightpdf.com/images/rtsupport/410153.png" alt="410153.png" width="506" height="347"></li>

<p><br>点击「确定」后，则该用户计算机中的文电通软件即被停用。<br>
<img src="https://store.rightpdf.com/images/rtsupport/410154.png" alt="410154.png"></p>
<h3><span class="wysiwyg-color-green130">延伸阅读：</span></h3></ol>
<p><a href="https://support.rightpdf.com/hc/zh-cn/articles/201032226" target="_blank" rel="noopener">我是公司IT部门负责人，我刚购买了10个文电通PDF套装版订阅版(Right PDF Subscription)授权，要如何开始部署？</a></p></body></html>`;   export default html;