
import React from 'react';
import MetaTags from 'react-meta-tags';

const SubscriptionCompare = props => {
    const {t,linkObj} = props;
    const pageDataObj = {
        info:{
            t1:t('resources.subscriptionCompare.title1'),
            t2:t('resources.subscriptionCompare.title2')
        },
        blockList:[
            {
                td1:t('resources.subscriptionCompare.payment'),
                td2:{
                    name:t('resources.subscriptionCompare.table.t1'),
                    type:'green_tick'
                },
                td3:{
                    name:t('resources.subscriptionCompare.table.t2'),
                    type:'green_tick'
                }
            },
            {
                td1:t('resources.subscriptionCompare.functionality'),
                td2:{
                    name:t('resources.subscriptionCompare.table.t3'),
                    type:'green_tick'
                },
                td3:{
                    name:t('resources.subscriptionCompare.table.t3'),
                    type:'green_tick'
                }
            },
            {
                td1:t('resources.subscriptionCompare.licenseManagement'),
                td2:{
                    name:t('resources.subscriptionCompare.table.t4'),
                    type:'green_tick'
                },
                td3:{
                    name:t('resources.subscriptionCompare.table.t5'),
                    type:'green_tick'
                }
            },
            {
                td1:t('resources.subscriptionCompare.softwareUpdate'),
                td2:{
                    name:t('resources.subscriptionCompare.table.t6'),
                    type:'green_tick'
                },
                td3:{
                    name:t('resources.subscriptionCompare.table.t7'),
                    type:'red_cross'
                }
            },
            {
                td1:t('resources.subscriptionCompare.access'),
                td2:{
                    name:t('resources.subscriptionCompare.table.t8'),
                    type:'green_tick'
                },
                td3:{
                    name:t('resources.subscriptionCompare.table.t9'),
                    type:'red_cross'
                }
            },
            {
                td1:t('resources.subscriptionCompare.prioritySupport'),
                td2:{
                    name:t('resources.subscriptionCompare.table.t10'),
                    type:'green_tick'
                },
                td3:{
                    name:t('resources.subscriptionCompare.table.t11'),
                    type:'red_cross'
                }
            }
        ]
    }
    return (
        <div className="compareContent">
            <MetaTags>
                <title>{t('metaTag.subscriptionCompare.title')}</title>
                <meta name="title" content={t('metaTag.subscriptionCompare.title')} />
                <meta name="description" content={t('metaTag.subscriptionCompare.desc')} />
                <meta name="keywords" content={t('metaTag.subscriptionCompare.keywords')} />
                <meta property="og:title" content={t('metaTag.subscriptionCompare.title')} />
                <meta property="og:description" content={t('metaTag.subscriptionCompare.desc')} />
            </MetaTags>
            <div className="productCompareInfo productCompareInfoWrap">
                <h2 className="font-weight-bold">{pageDataObj.info.t1}</h2>
                <p>{pageDataObj.info.t2}</p>
            </div>
            <div className="subscriptionTableWrap">
                <table className="compareBasicTable subscription">
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <h3 className="font-weight-light">
                                    {t('resources.subscriptionCompare.subscription.title').split('_')[0]}-
                                    <strong>{t('resources.subscriptionCompare.subscription.title').split('_')[1]}</strong>
                                </h3>
                            </th>
                            <th>
                                <h3 className="font-weight-light">
                                    {t('resources.subscriptionCompare.perpetual.title').split('_')[0]}-
                                    <strong>{t('resources.subscriptionCompare.perpetual.title').split('_')[1]}</strong>
                                </h3>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('server.compareList.listTitle3')}</td>
                            <td>
                                <p className="font-weight-bold">{t('resources.subscriptionCompare.subscription.price')}</p><br/>
                                 <a href={linkObj.buyLink.url('#suite_sub')} className="cBtn w_text text-center redBg-fire" target="_blank" rel="noopener noreferrer">
                                    {t('header.buy')}
                                 </a>
                            </td>
                            <td>
                                <p className="font-weight-bold">{t('resources.subscriptionCompare.perpetual.price')}</p><br/>
                                <a href={linkObj.buyLink.url('#suite_per')} className="cBtn w_text text-center redBg-fire" target="_blank" rel="noopener noreferrer">
                                    {t('header.buy')}
                                 </a>
                            </td>
                        </tr>
                        {
                            pageDataObj.blockList.map((e,i)=>{
                                return (
                                    <tr key={i}>
                                        <td>
                                            {e.td1}
                                        </td>
                                        <td className={e.td2.type}>
                                            <p>{e.td2.name}</p>
                                        </td>
                                        <td className={e.td3.type}>
                                            <p>{e.td3.name}</p>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <br/>
            <br/>
            <p style={{fontSize:'15px'}}>{t('resources.subscriptionCompare.info')}</p>
        </div>
    )
}

export default SubscriptionCompare;