import React, {useEffect, useState} from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import feature1 from '../assets/img/server/pdt_feature_01.png';
// import feature2 from '../assets/img/server/pdt_feature_02.png';
// import feature3 from '../assets/img/server/pdt_feature_03.png';
import {getFeatureList} from '../api';
import {useDispatch} from 'react-redux';
import MetaTags from 'react-meta-tags';

const ServerPage = props => {
    const { lang } = useParams();
    const {t,currentLang,linkObj, i18n} = props;
    const dispatch = useDispatch();
    const [featureAry, setFeatureAry] = useState([]);
    const featureTypeId = '405aa1dc-f56f-4201-a39c-546bba0f6b1b';
    const renderFeatureImg = (imgNum) =>{  //eslint-disable-line no-unused-vars
        let returnElement = <img src={require(`../assets/img/server/funIcon${imgNum}.png`)} className="img-fluid" alt={`${imgNum}`} />;
        return returnElement;
    }
    // const renderpdf = () => {
    //     let returnElement = null;

    //     returnElement = currentLang.type === 'zh-TW'?  <li><a href="https://www.pdfhome.com/zh-TW/home" target="_blank"><span className="font-light">PDFhome 教學網</span></a></li>:null;
    //     return returnElement;
    // }
    const renderBusinessInfo = () => {
        if(currentLang.type === 'jp' || currentLang.type === 'kr'){
            return <><p className="mt-34">{t('productPage.business.info1')}</p> <p className="mb-34"><Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`}><b>{t('newfeature.upgrade.block2.title')}</b></Link> {t('productPage.business.info2')}</p></>
        }else{
            return <><p className="mt-34">{t('productPage.business.info1')}</p> <p className="mb-34">{t('productPage.business.info2')}<Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`}><b>{t('newfeature.upgrade.block2.title')}</b></Link></p></>       
        }
    }
    const renderPrice = () => {
        let returnPrice = 0;
        if(currentLang.type === 'zh-TW'){
            returnPrice = '132,490';
        }else if(currentLang.type === 'zh-CN'){
            returnPrice = '22,500';
        }else{
            returnPrice = '4,400';
        }
        return returnPrice;
    }
    
    const scrollToAnchor = () => {
        let anchorElement = document.getElementById('serverComparedContent');
        if (anchorElement) { 
			//anchorElement.scrollIntoView({block: "start", behavior: "smooth" });
			document.documentElement.scrollTop = anchorElement.offsetTop - document.getElementsByTagName("header")[0].clientHeight;
		}
    }
    
    useEffect(() => {
        const fetchResource = cLang => {
            let lang = 'EN';
            if(cLang === 'zh-TW'){
                lang = 'CT';
            }else if(cLang === 'zh-CN'){
                lang = 'CS';
            }else{
                lang = cLang.toUpperCase();
            }
            getFeatureList({LgeID:lang, TypeID:featureTypeId, PageIndex:0, PageSize:100}).then(function (response) {
                dispatch({
                    type:'SEND_FEATURELIST',
                    featureListAry:response.data.List
                })
                setFeatureAry(response.data.List);
                // renderSuiteList(response.data.List);
            })
            .catch(function (error) {
                console.log('err',error);
            });
        
        }

        fetchResource(currentLang.type);
    }, [currentLang.type,dispatch]);

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    const renderIsTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "1"){
		return data.IsTop === "1" ? 
            //if((i % 2)==0){
			((i % 2)===0 ? 
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3">{data.Name}</h3>
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {data.IsMore===1?<Link to={`/${props.currentLang.type}/server/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link>:''}
                        </div>
                    </div>

                ) : 
            //} else {
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-33 featureInfo alignRight align-middle">
                            <h3 className="font-weight-bold b-text mb-3">{data.Name}</h3>
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {data.IsMore===1?<Link to={`/${props.currentLang.type}/server/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link>:''}
                        </div>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                    </div>

                ))
            //}
		: null;
        //}
    });
    
    const renderIsNotTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "0"){
            return data.IsTop === "0" ? (
                <div className="d-50 mb-120" key={i}>
                    <img src={data.Img_pc} className="img-fluid isNotTop-img" alt="" />
                    <div className="featureInfo paddL35 align-middle">
                        <h3 className="font-weight-bold m-text title_style">{data.Name}</h3>
                        <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                        {data.IsMore===1?<Link to={`/${props.currentLang.type}/server/readmore/${data.ID}`} className="mt-20 b1Btn">
                            {t('learnMore')}
                        </Link>:''}
                    </div>
                </div>
                
            ) : null;
        //}
    });
    

    return (
        <section id="server" className="productInfoContent">
            <MetaTags>
                <title>{t('metaTag.server.title')}</title>
                <meta name="title" content={t('metaTag.server.title')} />
                <meta name="description" content={t('metaTag.server.desc')} />
                <meta name="keywords" content={t('metaTag.server.keywords')} />
                <meta property="og:title" content={t('metaTag.server.title')} />
                <meta property="og:description" content={t('metaTag.server.desc')} />
            </MetaTags>
            <div className="topBlock contentBlock text-white">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                             <h1 className="firstTitle title_style">{t('server.name')}</h1>
                              <p className="secondTitle title_style">{t('server.info')}</p>
                              <div className="btnGroup">
                                <a href={linkObj.downloadLink.server.url()} className="winIcon icontop1 cBtn w_text greenBg text-center" target="_blank" rel="noopener noreferrer">
                                    {t('downloadNow3')}
                                    <p className="m-0 font-light">{t('freeTrial')}</p>
                                </a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock whiteBg">
                <div className="customContainer">
                    <div className="cRow maxW1300 grayBg-gallery">
                        <div className="d-100 authorizeContent">
                            <h2 className="mb-title font-weight-bold title_style">{t('productPage.licensingOption')}</h2>
                            <ul className="authorizeList center">
                                <li>
                                    <div className="authorizeBlock">
                                        <div className="blockTitle grayBg-corduroy">
                                            {t('productPage.perpetual.title')}
                                        </div>
                                        <div className="prizeInfo borderLine dash bottom">
                                            <h2 className="font-light">
                                                <span>{t('productPage.money')} </span> {renderPrice()} <span> /{t('productPage.up')}</span>
                                            </h2>
                                            <p className="m-0">({t('productPage.perpetual.serverInfo')})</p>
                                        </div>
                                        <div className="btnGroup paddingBottom5">
                                            <a href={linkObj.buyLink.url('#svr_lm')} target="_blank" rel="noopener noreferrer" className="cBtn w_text redBg-fire text-center">{t('header.buy')}</a>
                                            {/* <a href={linkObj.upgradeLink.url()}  target="_blank" rel="noopener noreferrer" className="cBtn red_border text-center">{t('oldUpgrade')}</a> */}
                                        </div>
                                        <div className='updateContact'>
                                            <span dangerouslySetInnerHTML={{__html: t('oldUpgradeContact',{aTagStart:'<a href="/'+currentLang.type+'/contactUs" class="contactClick" target="_blank">',aTagEnd:'</a>'})}}></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="serverDesc">
                                <span>{t('server.desc')}</span>
                                <span className="descClick" onClick={(e)=>{scrollToAnchor()}}>{t('server.descLink1')}</span>
                                <span>{t('server.descLink2')}</span>
                                
                            </div>
                            <div className="authorizeBlock authorizeInfo">
                                <h2>{t('productPage.business.title')}</h2>
                                {renderBusinessInfo()}
                                <Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`} className="cBtn w_text greenBg text-center">
                                    {t('learnMore')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-100">
                            <h2 className="mb-title font-weight-bold mb-4 title_style">{t('productPage.featuresTitle')}</h2>
                            <p className="m-title m-0">{t('server.featuresInfo.t1')}</p>
                            <p className="m-title m-0">{t('server.featuresInfo.t2')}</p>
                        </div>
                    </div>
                    {/* <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-65 align-middle maxd819">
                            <img src={feature1}  className="img-fluid" alt="feature_01"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3">客戶端/伺服器結構</h3>
                            <p>開啟客戶端程式、連上伺服器並開始轉檔──就是這麼容易！</p>
                            <a href="#" className="mt-20">
                                {t('learnMore')}
                            </a>
                        </div>
                    </div>
                    <div className="cRow maxW1300 mtb-120 padding-0 column-reverse">
                        <div className="d-33 featureInfo alignRight align-middle">
                            <h3 className="font-weight-bold b-text mb-3">管理員權限設定</h3>
                            <p>面對大量轉換任務，伺服器版提供企業無與倫比的管控能力，例如為使用者建立轉換設定檔，或透過主控台控制客戶端連線狀態。</p>
                            <a href="#" className="mt-20">
                                {t('learnMore')}
                            </a>
                        </div>
                        <div className="d-65 align-middle maxd819">
                            <img src={feature2}  className="img-fluid" alt="feature_02"/>
                        </div>
                    </div>
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-65 align-middle maxd819">
                            <img src={feature3}  className="img-fluid" alt="feature_03"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3">提供SDK資源</h3>
                            <p>PDF文電通伺服器版的功能可由Windows API所實現，且具有完整的文件與範例原始碼。開發人員可以藉由修改原始碼，在數天內整合PDF伺服器，供文書作業為主的工作流程與企業用軟體運用。</p>
                            <a href="#" className="mt-20">
                                {t('learnMore')}
                            </a>
                        </div>
                    </div>
                     {/* feature list */}
                     {renderIsTopFeatureView}
                     <div className="cRow maxW1300 mtb-120 padding-0">
                        {renderIsNotTopFeatureView}
                        {/* <div className="d-50 mb-120">
                            {renderFeatureImg(1)}
                            <div className="featureInfo paddL35 align-middle">
                                <h3 className="font-weight-bold m-text">轉換Word、Excel或PowerPoint為 PDF</h3>
                                <p>使用Gaaiho PDF Converter批次功能轉換、合併不同格式的檔案為PDF或內容豐富的文件夾 。您更能調整浮水印、密碼、Word轉換設定，製作專業水準的文件。</p>
                                <a href="#">
                                    {t('learnMore')}
                                </a>
                            </div>
                        </div>
                        <div className="d-50 mb-120">
                            {renderFeatureImg(2)}
                            <div className="featureInfo paddL35 align-middle">
                                <h3 className="font-weight-bold m-text">監看資料夾</h3>
                                <p>所有放在監看資料夾中的檔案將自動被轉換為PDF文件。您也能為數個監看資料夾建立不同的轉換設定或是規劃工作時程。</p>
                                <a href="#">
                                    {t('learnMore')}
                                </a>
                            </div>
                        </div> */}
                     </div>
                     
                    
                    {/* server compared */}
                     <div className="cRow maxW1300 mtb-120 padding-0">
                            <div className="d-100 serverComparedContent" id="serverComparedContent">
                                <h2 className="mb-title font-weight-bold text-center">{t('server.compareList.title')}</h2>
                                <ul className="serverComparedList">
                                    <li>
                                        <div className="listBoxWrap">
                                            <div className="listBox font-weight-bold"><span>{t('server.compareList.listTitle1')}</span></div>
                                            <div className="listBox font-weight-bold"><span>{t('server.compareList.listTitle2')}</span></div>
                                            <div className="listBox font-weight-bold"><span>{t('server.compareList.listTitle3')}</span></div>
                                            <div className="listBox font-weight-bold"><span>{t('server.compareList.listTitle4')}</span></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="listBoxWrap">
                                            <div className="listBox">
                                                <h2>{t('server.compareList.level1.name')}</h2>
                                            </div>
                                            <div className="listBox">
                                                {t('server.compareList.level1.capacity')}
                                            </div>
                                            <div className="listBox">
                                            {t('productPage.money')} {t('server.compareList.level1.price')}
                                            </div>
                                            <div className="listBox">
                                                <p>{t('server.compareList.level1.recommendList.info1')}</p>
                                                <p>{t('server.compareList.level1.recommendList.info2')}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="active">
                                        <span className="bestSellInfo redBg-fire">{t('home.compare.table.bestsell')}！</span>
                                        <div className="listBoxWrap">
                                            <div className="listBox">
                                                <h2>{t('server.compareList.level2.name')}</h2>
                                            </div>
                                            <div className="listBox">
                                                {t('server.compareList.level2.capacity')}
                                            </div>
                                            <div className="listBox">
                                            {t('productPage.money')} {t('server.compareList.level2.price')}
                                            </div>
                                            <div className="listBox">
                                                <p>{t('server.compareList.level2.recommendList.info1')}</p>
                                                <p>{t('server.compareList.level2.recommendList.info2')}</p>
                                                <p>{t('server.compareList.level2.recommendList.info3')}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="listBoxWrap">
                                            <div className="listBox">
                                                <h2>{t('server.compareList.level3.name')}</h2>
                                            </div>
                                            <div className="listBox">
                                                 {t('server.compareList.level3.capacity')}
                                            </div>
                                            <div className="listBox">
                                            {t('productPage.money')} {t('server.compareList.level3.price')}
                                            </div>
                                            <div className="listBox">
                                                <p>{t('server.compareList.level3.recommendList.info1')}</p>
                                                <p>{t('server.compareList.level3.recommendList.info2')}</p>
                                                <p>{t('server.compareList.level3.recommendList.info3')}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="listBoxWrap">
                                            <div className="listBox">
                                                <h2>{t('server.compareList.level4.name')}</h2>
                                            </div>
                                            <div className="listBox">
                                                {t('server.compareList.level4.capacity')}
                                            </div>
                                            <div className="listBox">
                                            {t('productPage.money')}  {t('server.compareList.level4.price')}
                                            </div>
                                            <div className="listBox">
                                                <p>{t('server.compareList.level3.recommendList.info1')}</p>
                                                <p>{t('server.compareList.level4.recommendList.info2')}</p>
                                                <p>{t('server.compareList.level4.recommendList.info3')}</p>
                                            </div>
                                        </div>
                                       
                                    </li>
                                    <li>
                                        <div className="listBoxWrap">
                                            <div className="listBox">
                                                <h2>{t('server.compareList.level5.name')}</h2>
                                            </div>
                                            <div className="listBox">
                                                {t('server.compareList.level5.capacity')}
                                            </div>
                                            <div className="listBox">
                                                <a href={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`}>
                                                    {t('contact.name')}
                                                </a>
                                            </div>
                                            <div className="listBox">
                                                <p>{t('server.compareList.level5.recommendList.info1')} </p>
                                                <p>{t('server.compareList.level5.recommendList.info2')}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <a href={linkObj.buyLink.url('#svr_lm')} target="_blank" rel="noopener noreferrer" className="cBtn redBg-fire w_text">
                                    {t('header.buy')}
                                </a>
                                <div className="serverComparedInfo">
                                    <p>{t('server.compareInfo.info1')}</p>
                                    <p>{t('server.compareInfo.info2')}</p>
                                </div>
                            </div>
                     </div>
                     {/* feature detail */}
                     <div className="cRow maxW1300 grayBg-gallery">
                        <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block align-top" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.systemRequired.mainTitle')}
                            </h2>
                            <ul className="featureDetailList align-top">
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title1')}</p>
                                    <p>{t('converter.systemRequired.info1')}</p>
                                </li>
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title2')}</p>
                                    <p>{t('server.systemRequired.info2')}</p>
                                    <p>{t('suite.systemRequired.info3')}</p>
                                    <p>{t('suite.systemRequired.info4')}</p>
                                </li>
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title3')}</p>
                                    <p>{t('suite.systemRequired.info5')}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.resourceTitle')}
                            </h2>
                            <ul className="featureDetailList boxList align-top">
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.tutorials.userManuals.path}`}>
                                        <span className="font-light">{t('tutorials.userManuals')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.compare.path}`}>
                                        <span className="font-light">
                                            {t('resources.link1').split("/")[0]}/<br/>
                                            {t('resources.link1').split("/")[1]}
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.documents.path}`}>
                                         <span className="font-light">
                                             {t('resources.link2').split("/")[0]} /<br/>
                                             {t('resources.link2').split("/")[1]}
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.release.path}`}>
                                        <span className="font-light">{t('resources.link6')}</span>
                                    </Link>
                                </li>
                                {
                                    //renderpdf()
                                }
                            </ul>
                        </div>
                     </div>
                </div>
            </div>
            {/* feature bottom */}
            <div className="bottomBlock contentBlock text-white">
                    <div className="customContainer">
                        <div className="cRow maxW1300">
                            <div className="d-100"> 
                                <p className="secondTitle">{t('server.bottomInfo')}</p>
                                <h1 className="firstTitle">{t('server.name')}</h1>
                                <div className="btnGroup">
                                    <a href={linkObj.downloadLink.server.url()} className="cBtn w_text greenBg text-center" target="_blank" rel="noopener noreferrer">
                                        {t('downloadNow3')}
                                        <p className="m-0 font-light">{t('freeTrial')}</p>
                                    </a>
                                </div>

                                    <p dangerouslySetInnerHTML={{__html: t('oldUpgradeContact',{aTagStart:'<a href="/'+currentLang.type+'/contactUs" style="color: white;text-decoration: underline;" target="_blank">',aTagEnd:'</a>'})}}></p>

                            </div>
                        </div>
                    </div>            
             </div>
        </section>
    )
}

export default mixinWrapper(ServerPage);