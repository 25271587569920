const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>终端服务器 (TSL) 授权已达上限时该怎么办? (Right PDF)</title></head><body>  <h1>终端服务器 (TSL) 授权已达上限时该怎么办? (Right PDF)</h1>  <h2><strong>问题描述：</strong></h2>
<p>当您开启文电通(Right PDF)的时候，有时候可能会遇到以下画面：</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip4 (1).png" alt="mceclip4 (1).png" width="532" height="378"></p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>此情况表示软件目前的使用者数已达授权数上限。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决办法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">如需继续使用文电通，您或是管理员必须到<strong>管理授权</strong>，手动移除授权清单中的计算机。</span></p>
<p><span class="wysiwyg-color-blue130">1. 在开始选择<strong>所有程序 </strong>&gt; <strong>Right PDF Pro/ Right PDF Converter</strong><strong> </strong>&gt; <strong>授权信息</strong></span></p>
<p><span class="wysiwyg-color-blue130"><strong><img src="https://store.rightpdf.com/images/rtsupport/mceclip5 (2).png" alt="mceclip5 (2).png" width="237" height="401"></strong></span></p>
<p><span class="wysiwyg-color-blue130">2. 在<strong>Right PDF 授权信息</strong>对话框中点击<strong>管理授权</strong>。</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/mceclip6 (3).png" alt="mceclip6 (3).png" width="500" height="352"></span></p>
<p><span class="wysiwyg-color-blue130">3. 在管理授权窗口中，选择想要解除授权的计算机名称，接点击<strong>刪除</strong>并<strong>确定</strong>。</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/mceclip7 (1).png" alt="mceclip7 (1).png" width="463" height="327"></span></p>
<p><span class="wysiwyg-color-red"><strong>注意</strong></span><span class="wysiwyg-color-blue130">：购买<strong>concurrent</strong> (同时存取授权) 的客户还有另一种方法可以释放授权。当您关掉软件窗口时(不再使用软件)，即会自动将此使用者从授权清单中移除。</span></p>
<p> </p></body></html>`;   export default html;