import React, {useEffect, useState} from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { useParams } from 'react-router-dom';
import { getManualType } from '../api';
import { useDispatch } from 'react-redux';
import SearchComponent from '../components/search/searchComponent.js';
import MetaTags from 'react-meta-tags';

const SdkPage = props => {
    const { lang } = useParams();
    const {t,currentLang,linkObj, i18n} = props;
    const dispatch = useDispatch();
    const [featureAry, setFeatureAry] = useState([]);
    //const featureTypeId = 'ba6783f8-c3f2-42ad-857b-4458fa970659';
	const [isShow,setIsShow] = useState(true);
	const relateDataArray = [
		{
			name:t(linkObj.tutorials.pdfhome),
			url:'https://www.pdfhome.com/zh-TW/home',
			desc:t('help.t3')
		},
		{
			name:t(linkObj.support.faq.name),
			url:linkObj.support.faq.url(),
			desc:t('help.t4')
		},
		{
			name:t(linkObj.support.submitTicket.name),
			url:linkObj.support.submitTicket.url(),
			desc:t('help.t5')
		}
    ];
	const changeShow = (isShow) => {
		setIsShow(isShow);
	}


    useEffect(() => {
        const fetchResource = cLang => {
            let lang = 'EN';
            if(cLang === 'zh-TW'){
                lang = 'CT';
            }else if(cLang === 'zh-CN'){
                lang = 'CS';
            }else{
                lang = cLang.toUpperCase();
            }
        
			getManualType({LgeID:lang}).then(function (response) {
				dispatch({
					type:'SEND_FEATURELIST',
					featureListAry:response.data.List
				})
				setFeatureAry(response.data.List);
			})
			.catch(function (error) {
				console.log('err',error);
			});
        }
        fetchResource(currentLang.type);
    }, [currentLang.type,dispatch]);

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    const menulis = featureAry.map((item,index)=>{
		var Background = item.Img_pc;
		var backgroundStyle = {
		  backgroundImage: `url(${Background})` ,
		  backgroundPositionX:"center",
		  backgroundPositionY:"center",
		  backgroundRepeatX:"no-repeat",
		  backgroundRepeatY:"no-repeat",
		  backgroundSize:"cover"
		};
        return (
			<li key={index} typeid={item.TypeID}><a href={"./helpcontent" + item.UrlName}><div className="div1" style={backgroundStyle}></div><div className="div2">{item.TypeName}</div></a></li>
        )
    })
	
	
	const relatelis = relateDataArray.map((item,index) => {
		return (
			<li key={index}><a href={item.url}><div className="normal">{item.name}</div><div>{item.desc}</div></a></li>
		)
	})
    const renderContentView = () => {
        return (
            <div className="">
                <SearchComponent  currentLang = {currentLang} featureAry = {featureAry} t = {t} changeShow = {changeShow}/>
				<div className="content1" style={{'display':isShow?'block':'none'}}>
					<div className="manual_list" style={{background:"#fff"}}>
						<ul>{menulis}</ul>
					</div>
					<div className="resource_list">
						<div>{t("help.t2")}</div>
						<ul>
							{relatelis}
						</ul>
					</div>
				</div>
            </div>
        )
    }
    return (
        <section id="help" className="productInfoContent">
            <MetaTags>
				<title>{t('metaTag.userManuals.title')}</title>
                <meta name="title" content={t('metaTag.userManuals.title')} />
                <meta name="description" content={t('metaTag.userManuals.desc')} />
                <meta name="keywords" content={t('metaTag.userManuals.keywords')} />
                <meta property="og:title" content={t('metaTag.userManuals.title')} />
                <meta property="og:description" content={t('metaTag.userManuals.desc')} />
            </MetaTags>
            
            <div className="contentBlock whiteBg">
                <div className="customContainer">
					{renderContentView()}
                </div>
            </div>
        </section>
    )
}

export default mixinWrapper(SdkPage);