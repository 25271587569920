const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>新版上市：PDF文电通5专业版/转换器/终端服务器版/服务器版</title></head><body>  <h1>新版上市：PDF文电通5专业版/转换器/终端服务器版/服务器版</h1>  <p>大家好，距离上次发布新版已经有一段时间了，不过就在2018年的9月──PDF文电通5已正式发布，并且能在我们的官网上<a href="https://store.gaaiho.com/download/central.aspx">下载</a>到啰！走过了25个年头，棣南始终站在人与PDF互动的前沿思考、创新、进步。<br> <br> 在全新的文电通第5代中，您会体验到更快的速度、更人性的接口和更全面的功能。<strong>绝佳文书生产力，于斯而启。</strong></p>
<p>下边是我们《PDF文电通5》介绍影片，看我们如何再塑经典！</p>
<p> <iframe src="//www.youtube-nocookie.com/embed/OjmGhBIUR1I" width="560" height="315" frameborder="0" allowfullscreen=""></iframe></p>
<p> </p>
<p>在许多年以前，我们就能让客户在文电通程序中直接编辑PDF文件，而不须转换文件格式。当时这在业界可是前无古人的创新呢！看完了精采的影片，让我们来一探新版中究竟有哪些全新功能吧！</p>
<h3><span class="wysiwyg-color-blue"><strong>见过崭新的转换器</strong></span></h3>
<p>全新的转换神器不但取了新名字，还革新了传统接口，令转换格式变得更加简单、直率。</p>
<p><img src="https://www.gaaiho.com/images/b_products/v5_new/blk01_img03_cn.png"></p>
<h3><span class="wysiwyg-color-blue"><strong>云端共同编辑</strong></span></h3>
<p>除了局域网络(LAN)，现在还可以透过因特网进行多人协同编辑，使文书工作事半功倍。</p>
<h3 class="wysiwyg-text-align-center"><strong><img src="https://www.gaaiho.com/images/b_products/v5_new/blk01_img04.png"></strong></h3>
<h3><span class="wysiwyg-color-blue"><strong>支持更多文件格式</strong></span></h3>
<p>使用虚拟打印机转换文件为PDF/X、PDF/A、PDF/E等特殊格式。若是从扫描仪建立PDF文件，除了使用OCR功能辨认文字外，您还能够缩小档案大小或使其符合PDF/A规范。</p>
<h3 class="wysiwyg-text-align-center"><strong><img src="https://www.gaaiho.com/images/b_products/v5_new/blk01_img05.png"></strong></h3>
<h3><span class="wysiwyg-color-blue"><strong>更全面的</strong><strong>PDF</strong><strong>转换功能</strong></span></h3>
<ul>
<li>转换OpenOffice文件为PDF</li>
<li>转换PDF为PowerPoint</li>
<li>转换网页为PDF文件</li>
</ul>
<h3><strong><img src="https://www.gaaiho.com/images/b_products/v5_new/blk04_img01.png" width="205" height="151"><img src="https://www.gaaiho.com/images/b_products/v5_new/blk04_img02.png" width="210" height="155"><img src="https://www.gaaiho.com/images/b_products/v5_new/blk04_img03.png" width="209" height="154"></strong></h3>
<h3><span class="wysiwyg-color-blue"><strong>每日转换量计数器</strong></span></h3>
<p>新增转换数量计数器至授权信息页面，让您随时掌握目前的转换使用量。</p>
<p> <img src="https://www.gaaiho.com/images/b_products/v5_new/blk04_img04_cn.png"></p>
<p> </p>
<p>还有太多太多新功能，实在无法一一描述...</p>
<p>如果有兴趣看详细的信息，请到我们的「<a href="https://www.gaaiho.com/index.php/zh-tw/release-notes">版本发布讯息</a>」区瞧瞧。</p>
<p> </p>
<p>好了，大伙们，最后也请你记得到我们<a href="http://www.gaaiho.com/index.php/zh-tw/">崭新的官网</a>逛一逛喔。我们下次再见！</p></body></html>`;   export default html;