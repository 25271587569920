const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何以PDF为信息安全把关？</title></head><body>  <h1>如何以PDF为信息安全把关？</h1>  <p><span class="wysiwyg-color-black">谈到企业信息安全解决方案，公司大多配备权限设置或是网络监控，然而更值得我们考虑的是，当文件离开企业防火墙的范围，是否还具备足够的安全防护呢？</span></p>
<p><span class="wysiwyg-color-black">随着云端及移动设备的快速发展，信息安全的危害已不再只限于黑客入侵、病毒攻击，往常针对网络、服务器、存储设备的防御措施，必须尽可能的延伸至接收端，在防火墙及加密传输过程之外，增强文件本身的防御能力，以建立完整的信息安全保护。棣南身为PDF专业研发厂商，有自信将PDF软件，作为一道完善信息安全的最重要防线。</span></p>
<p> </p>
<h2><span class="wysiwyg-color-green120"><strong>PDF</strong><strong>如何保护文件？</strong></span></h2>
<p><span class="wysiwyg-color-black">PDF因为具备跨平台、跨装置的特性，成为广泛流通的文件格式，搭配多层次的安全性功能，足以成为延长信息安全防线的一项利器。PDF的安全性功能可分为检查／密文、签名／认证及加密设置三大层级，在发送文件之前，我们建议您基于信息安全分层防御，对文件附加多层次的保护以降低资料外流的风险：</span></p>
<ul>
<li><span class="wysiwyg-color-black"><strong>检查／密文：</strong>检查文件内所有可见与隐藏的内容，选择标记密文、删除或是保留信息。</span></li>
<li><span class="wysiwyg-color-black"><strong>签名／认证：</strong>签名文档，或是验证签名，并比较不同签名版本，以确认经过发送后的文件未被篡改。</span></li>
<li><span class="wysiwyg-color-black"><strong>加密设置：</strong>设置文件打开密码，识别信任的身份，或限制操作文件的权限，以防止文件在未经授权的情况下被打开、修改或打印。</span></li>
</ul>
<p><img src="https://store.rightpdf.com/images/rtsupport/230021.png" alt=""></p>
<h2>
<strong><br><span class="wysiwyg-color-green120">PDF文电通</span></strong><span class="wysiwyg-color-green120"><strong>专业版提供六大项安全性功能</strong></span>
</h2>
<ul>
<li><span class="wysiwyg-color-black"><strong>密文工具：</strong>搜索和标记文件内敏感信息，并可永久删除被标记的内容。</span></li>
<li><span class="wysiwyg-color-black"><strong>检查文档：</strong>移除书签、链接、注释或是附件等，并删除可能包含个人或组织信息的元数据或其他内嵌的隐藏数据。</span></li>
<li><span class="wysiwyg-color-black"><strong>签名／认证：</strong>选取数字身份证签名文档，并使用认证文档功能，指定对认证后的文档能进行哪些类型的操作。为文档加盖时间戳，并可验证签名，比较不同签名版本，确认传输后文件的完整性，防止被他人进行伪造。</span></li>
<li><span class="wysiwyg-color-black"><strong>加密：</strong>PDF文电通3专业版采用最高至256位元的AES加密算法。可以自定义加密等级，选取需要加密的内容；设置权限密码以限制使用者打印、修改、复制文件；设置打开密码以防止文件在未经授权的情况下被打开；以及建立可信任身份识别清单，管理可以开启安全性文件的收件人。</span></li>
<li><span class="wysiwyg-color-black"><strong>安全发送：</strong>提供完整的安全性流程，通过交互式机制建立安全性设置加密及签名文档，确保以电子邮件发送文件时的信息安全。</span></li>
<li><span class="wysiwyg-color-black"><strong>Microsoft Azure信息保护(AIP)：</strong>文电通PDF支持Microsoft Azure信息保护(AIP)，通过安全策略来保护重要文件。管理员可以为其他的Microsoft账号设置权限，只允许授权的账号访问受保护的文件。</span></li>
</ul>
<p><img src="https://store.rightpdf.com/images/rtsupport/230022.png" alt="230022.png" width="555" height="345"></p>
<p> </p>
<p><span class="wysiwyg-color-black">如欲了解更多信息，请登陆</span><strong><a href="https://www.rightpdf.cn/" target="_blank" rel="noopener">www.rightpdf.cn</a></strong><span class="wysiwyg-color-black">或联系</span><a href="mailto:sales@rightpdf.com" target="_self" rel="undefined">sales@rightpdf.com</a></p></body></html>`;   export default html;