var mdata = {
    "Status": "1",
    "TypeName": "",
    "List": [
        {
            "TypeName": "常见问题",
            "ID": "10581",
            "UrlName": "/support/10581",
            "SubType": "A",//A,有内容，c，可展开的节点
            "Children": []
        },
        {
            "TypeName": "第1章：公告",
            "ID": "10000",
            "UrlName": "/support/10000",
            "SubType": "C",
            "Children": [
                {
                    "TypeName": "公告",
                    "ID": "11000",
                    "UrlName": "/support/11000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "文电通（Right PDF）品牌更名政策已于2022年5月生效  ",
                            "ID": "11001",
                            "UrlName": "/support/11001",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "版本更新：PDF文电通5.4版 | Flash产品终止的因应 ",
                            "ID": "11002",
                            "UrlName": "/support/11002",
                            "Search":"Flash Player",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "DocuCom PDF停止销售",
                            "ID": "11003",
                            "UrlName": "/support/11003",
                            "Search":"DocuCom",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "全新发布：文电通PDF套装版/转换器/终端服务器版4 ",
                            "ID": "11004",
                            "UrlName": "/support/11004",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "新版上市：PDF文电通5专业版/转换器/终端服务器版/服务器版",
                            "ID": "11005",
                            "UrlName": "/support/11005",
                            "SubType": "A"
                        }
                    ]
                }
            ]
        },
        {
            "TypeName": "第2章：开始使用",
            "ID": "20000",
            "UrlName": "/support/20000",
            "SubType": "C",
            "Children": [
                {
                    "TypeName": "软件下载与安装",
                    "ID": "21000",
                    "UrlName": "/support/21000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "如何取得文电通PDF的试用版并安装?",
                            "ID": "21001",
                            "UrlName": "/support/21001",
                            "Search":"试用，下载",
                            "SubType": "A"
                        },
                        {
                            "TypeName": " Right PDF/ Gaaiho PDF系列产品与Windows 7、8 、10以及最新的Windows 11兼容吗?",
                            "ID": "21002",
                            "UrlName": "/support/21002",
                            "Search":"Windows，兼容",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "有提供MSI安装包供企业或组织进行大量部署吗？ ",
                            "ID": "21003",
                            "UrlName": "/support/21003",
                            "Search":"MSI",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何卸载文电通PDF套装版/转换器？",
                            "ID": "21004",
                            "UrlName": "/support/21004",
                            "Search":"卸载",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "Gaaiho PDF转换服务器(Right PDF/ Gaaiho PDF Server)如何部署？",
                            "ID": "21005",
                            "UrlName": "/support/21005",
                            "Search":"转换服务器",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "Right PDF/ Gaaiho PDF转换服务器2.0(Gaaiho PDF Server)与Windows Server 2008兼容吗?",
                            "ID": "21006",
                            "UrlName": "/support/21006",
                            "Search":"Windows Server 2008，兼容",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "Right Reader安装包会分32位和64位吗？",
                            "ID": "21007",
                            "UrlName": "/support/21007",
                            "Search":"安装包，32位，64位",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何知道我的系统是32位还是64位？",
                            "ID": "21008",
                            "UrlName": "/support/21008",
                            "Search":"系统，32位，64位",
                            "SubType": "A"
                        }
                    ]
                },
                {
                    "TypeName": "激活、反激活与试用",
                    "ID": "22000",
                    "UrlName": "/support/22000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "如果我想将软件(Right PDF) 转移到另外一台计算机上使用，该怎么做 ?",
                            "ID": "22001",
                            "UrlName": "/support/22001",
                            "Search":"转移，换电脑",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在线/离线反激活 文电通6 (Right PDF 6)?  ",
                            "ID": "22002",
                            "UrlName": "/support/22002",
                            "Search":"反激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在线/离线激活 文电通6 (Right PDF 6)？",
                            "ID": "22003",
                            "UrlName": "/support/22003",
                            "Search":"激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在线/离线反激活 文电通 5 (Gaaiho PDF 5)?",
                            "ID": "22004",
                            "UrlName": "/support/22004",
                            "Search":"反激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在线/离线激活 文电通5 (Gaaiho PDF 5)？",
                            "ID": "22005",
                            "UrlName": "/support/22005",
                            "Search":"激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如果我想将软件转移到另外一台计算机上使用，该怎么做?",
                            "ID": "22006",
                            "UrlName": "/support/22006",
                            "Search":"转移",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在线清除激活记录(反激活)？",
                            "ID": "22007",
                            "UrlName": "/support/22007",
                            "Search":"反激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "[旧版] 如何在线/离线激活PDF文电通(Gaaiho PDF)？",
                            "ID": "22008",
                            "UrlName": "/support/22008",
                            "Search":"激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "[旧版] 如何在线/离线反激活 文电通 (Gaaiho PDF )?  ",
                            "ID": "22009",
                            "UrlName": "/support/22009",
                            "Search":"反激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "[旧版] 如何在线/离线反激活 文电通2和3?",
                            "ID": "22010",
                            "UrlName": "/support/22010",
                            "Search":"反激活",
                            "SubType": "A"
                        }
                    ]
                },
                {
                    "TypeName": "功能聚光灯",
                    "ID": "23000",
                    "UrlName": "/support/23000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "如何在打开PDF文件时，自动恢复到上次浏览的位置与页数？",
                            "ID": "23001",
                            "UrlName": "/support/23001",
                            "Search":"恢复，视图",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何以PDF为信息安全把关？",
                            "ID": "23002",
                            "UrlName": "/support/23002",
                            "Search":"安全，保护，加密，密文，签名",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "使用批处理功能提升工作效率？",
                            "ID": "23003",
                            "UrlName": "/support/23003",
                            "Search":"批处理",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何减少PDF的文件大小？",
                            "ID": "23004",
                            "UrlName": "/support/23004",
                            "Search":"压缩，文件大小",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何改变PDF页面的纸张尺寸大小？  ",
                            "ID": "23005",
                            "UrlName": "/support/23005",
                            "Search":"页面大小",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何修改PDF文件的背景（透明度、颜色、图片）？",
                            "ID": "23006",
                            "UrlName": "/support/23006",
                            "Search":"编辑图形",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何验证表单内容?",
                            "ID": "23007",
                            "UrlName": "/support/23007",
                            "Search":"验证表单",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "必杀技：注释小结",
                            "ID": "23008",
                            "UrlName": "/support/23008",
                            "Search":"注释小结，注释",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何删除文件内的敏感性资料？",
                            "ID": "23009",
                            "UrlName": "/support/23009",
                            "SubType": "A"
                        }
                    ]
                },
                {
                    "TypeName": "授权管理",
                    "ID": "24000",
                    "UrlName": "/support/24000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "注册文电通账号与绑定序列号有什么好处？",
                            "ID": "24001",
                            "UrlName": "/support/24001",
                            "Search":"注册，账号",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何绑定我的授权（序列号）？",
                            "ID": "24002",
                            "UrlName": "/support/24002",
                            "Search":"绑定序列号，授权管理",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "什么是企业授权管理？",
                            "ID": "24003",
                            "UrlName": "/support/24003",
                            "Search":"企业，企业授权管理",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何解决无法手动反激活文电通软件授权问题(企业授权管理)？",
                            "ID": "24004",
                            "UrlName": "/support/24004",
                            "Search":"企业，企业授权管理",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何管理我的订阅版授权？  ",
                            "ID": "24005",
                            "UrlName": "/support/24005",
                            "Search":"管理授权，订阅版授权，",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "终端服务器 (TSL) 授权已达上限时该怎么办? (Right PDF)  ",
                            "ID": "24006",
                            "UrlName": "/support/24006",
                            "Search":"TSL，终端服务器授权，授权管理",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我该如何⽤群组管理我的授权（企业⽤）？ ",
                            "ID": "24007",
                            "UrlName": "/support/24007",
                            "Search":"群组，授权管理",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "终端服务器 (TSL) 授权已达上限时该怎么办? (Gaaiho PDF)  ",
                            "ID": "24008",
                            "UrlName": "/support/24008",
                            "Search":"TSL，终端服务器授权，授权管理，上限",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我可以把原本分属两个账号的两组授权码 (序列号) 绑定到同一个账号下吗?",
                            "ID": "24009",
                            "UrlName": "/support/24009",
                            "Search":"授权，授权管理",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何解除绑定我的授权?解除绑定后可以将我的授权转移给别人吗?",
                            "ID": "24010",
                            "UrlName": "/support/24010",
                            "Search":"授权，授权管理，解除绑定",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "授权码 (序列号) 被别人绑定了?",
                            "ID": "24011",
                            "UrlName": "/support/24011",
                            "Search":"授权，授权管理",
                            "SubType": "A"
                        }
                    ]
                },
                {
                    "TypeName": "软件升级与更新",
                    "ID": "25000",
                    "UrlName": "/support/25000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "Right Reader如何升级？",
                            "ID": "25001",
                            "UrlName": "/support/25001",
                            "Search":"Right Reader，升级",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何将文电通更新到最新版本?",
                            "ID": "25002",
                            "UrlName": "/support/25002",
                            "Search":"更新",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "升级文电通PDF套装版(Right PDF Pro)是免费的吗?",
                            "ID": "25003",
                            "UrlName": "/support/25003",
                            "Search":"升级",
                            "SubType": "A"
                        }
                    ]
                }
            ]
        },
        {
            "TypeName": "第3章：我遇到了一些麻烦",
            "ID": "30000",
            "UrlName": "/support/30000",
            "SubType": "C",
            "Children": [
                {
                    "TypeName": "下载、安装和升级问题",
                    "ID": "31000",
                    "UrlName": "/support/31000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "如何下载旧版文电通? ",
                            "ID": "31001",
                            "UrlName": "/support/31001",
                            "Search":"旧版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "Installer的权限不足，无法访问这个目录",
                            "ID": "31002",
                            "UrlName": "/support/31002",
                            "Search":"Installer的权限不足，无法访问这个目录",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "错误30005是什么? ",
                            "ID": "31003",
                            "UrlName": "/support/31003",
                            "Search":"旧版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么安装Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)时出现错误信息126？",
                            "ID": "31004",
                            "UrlName": "/support/31004",
                            "Search":"126",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为何安装Gaaiho PDF转换器(Gaaiho PDF Driver)时出现错误码216？",
                            "ID": "31005",
                            "UrlName": "/support/31005",
                            "Search":"216",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么安装过程中收到错误「此处理器类型不支持该安装包。请与您的产品供应商联系」?",
                            "ID": "31006",
                            "UrlName": "/support/31006",
                            "Search":"此处理器类型不支持该安装包",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "应用安全设置时出错，用户不是有效的用户或组？(Gaaiho PDF 3)",
                            "ID": "31007",
                            "UrlName": "/support/31007",
                            "Search":"应用安全设置时出错，用户不是有效的用户或组",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我的文电通在版本自动更新/升级失败后，软件也不见了？",
                            "ID": "31008",
                            "UrlName": "/support/31008",
                            "Search":"系统，32位，64位",
                            "SubType": "A"
                        }
                    ]
                },
                {
                    "TypeName": "激活与反激活问题",
                    "ID": "32000",
                    "UrlName": "/support/32000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "我无法反激活产品了，怎么办？",
                            "ID": "32001",
                            "UrlName": "/support/32001",
                            "Search":"反激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我激活产品时达到了授权上限，请问该怎么办 (错误代码12)？",
                            "ID": "32002",
                            "UrlName": "/support/32002",
                            "Search":"12",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "激活授权时出现错误代码「0x20020101」？",
                            "ID": "32003",
                            "UrlName": "/support/32003",
                            "Search":"0x20020101",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "激活授权码或序号时，出现错误码 115",
                            "ID": "32004",
                            "UrlName": "/support/32004",
                            "Search":"115",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "当我使用在线激活时候，出现错误「无法连接到激活服务器」?",
                            "ID": "32005",
                            "UrlName": "/support/32005",
                            "Search":"无法连接到激活服务器",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "激活授权时出现错误码-10152/-10152?",
                            "ID": "32006",
                            "UrlName": "/support/32006",
                            "Search":"-10151，10152",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "激活软件时出现错误码16？",
                            "ID": "32007",
                            "UrlName": "/support/32007",
                            "Search":"16",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "线上激活授权时出现错误「-7000000」",
                            "ID": "32008",
                            "UrlName": "/support/32008",
                            "Search":"-7000000",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "离线反激活时出现错误\"102\"",
                            "ID": "32009",
                            "UrlName": "/support/32009",
                            "Search":"102",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "离线激活授权时出现错误\"125\"",
                            "ID": "32010",
                            "UrlName": "/support/32010",
                            "Search":"125",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "错误码104是什么意思？",
                            "ID": "32011",
                            "UrlName": "/support/32011",
                            "Search":"104",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "激活时为何出现错误「0x20020233」或「87」？",
                            "ID": "32012",
                            "UrlName": "/support/32012",
                            "Search":"0x20020233，87",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "离线激活时出现错误「0x20020215」？",
                            "ID": "32013",
                            "UrlName": "/support/32013",
                            "Search":"0x20020215",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "在卸载程序时反激活失败怎么办？",
                            "ID": "32014",
                            "UrlName": "/support/32014",
                            "Search":"反激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "错误代码6001223是什么意思？",
                            "ID": "32015",
                            "UrlName": "/support/32015",
                            "Search":"6001223",
                            "SubType": "A"
                        },
                       {
                            "TypeName": "为何出现错误「0x20022022」？",
                            "ID": "32016",
                            "UrlName": "/support/32016",
                            "Search":"0x20022022",
                            "SubType": "A"
                        },
                       {
                            "TypeName": "Gaaiho文电通PDF 5 激活失败/无法激活 - Windows 10",
                            "ID": "32017",
                            "UrlName": "/support/32017",
                            "Search":"Gaaiho Doc 已经停止运作",
                            "SubType": "A"
                        },
                    ]
                },
                {
                    "TypeName": "功能与使用上的麻烦",
                    "ID": "33000",
                    "UrlName": "/support/33000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "无法在本页执行OCR，因为包含可渲染/可转译文字。",
                            "ID": "33001",
                            "UrlName": "/support/33001",
                            "Search":"OCR，无法在本页执行OCR",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为何我碰到「已经到达此时段允许的最大可转换文件数或页数/Limit is exceeded」？",
                            "ID": "33002",
                            "UrlName": "/support/33002",
                            "Search":"已经到达此时段允许的最大可转换文件数或页数，请稍后再试，Limit is exceeded",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为何我在系统还原后无法激活产品？",
                            "ID": "33003",
                            "UrlName": "/support/33003",
                            "Search":"系统还原后无法激活产品",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "当您还有授权，却出现以下错误讯息「你超出允许使用授权的客户端数量... 」",
                            "ID": "33004",
                            "UrlName": "/support/33004",
                            "Search":"你超出允许使用授权的客户端数量",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为何我碰上了「Valid license not found」错误？",
                            "ID": "33005",
                            "UrlName": "/support/33005",
                            "Search":"Valid License not found",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "无法显示「包装」的PDF文件内容，并出现Flash的提示讯息。",
                            "ID": "33006",
                            "UrlName": "/support/33006",
                            "Search":"Flash",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我PDF文件的窗口右侧滚动条不见了？",
                            "ID": "33007",
                            "UrlName": "/support/33007",
                            "Search":"滚动条",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为何我克隆(clone)硬盘后无法激活产品了？",
                            "ID": "33008",
                            "UrlName": "/support/33008",
                            "Search":"克隆",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "PDF文件打开后的名称与文件名不同？",
                            "ID": "33009",
                            "UrlName": "/support/33009",
                            "Search":"文件名",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我的文电通套装版编辑器的菜单栏不见了？",
                            "ID": "33010",
                            "UrlName": "/support/33010",
                            "Search":"菜单栏",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何找回Word里消失的Right PDF/ Gaaiho PDF工具栏（加载项/add-in）?",
                            "ID": "33011",
                            "UrlName": "/support/33011",
                            "Search":"加载项",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为何PowerPoint中的链接没有转到PDF中？",
                            "ID": "33012",
                            "UrlName": "/support/33012",
                            "Search":"PowerPoint，链接",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在文件夹/此电脑中显示PDF文件缩略图 ",
                            "ID": "33013",
                            "UrlName": "/support/33013",
                            "Search":"缩略图",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我的转换器(Converter/Driver)无法转换Word与Excel文档?",
                            "ID": "33014",
                            "UrlName": "/support/33014",
                            "Search":"转换器，无法新增",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "转换时出现「您还没有启动Zeon/Gaaiho License Service服务」？",
                            "ID": "33015",
                            "UrlName": "/support/33015",
                            "Search":"License Service",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么字体被错误地替代了?",
                            "ID": "33016",
                            "UrlName": "/support/33016",
                            "Search":"字体",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "打印PDF时，部分内容空白或出现乱码？",
                            "ID": "33017",
                            "UrlName": "/support/33017",
                            "Search":"打印，乱码",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "双击PDF文件变直接打印 或 PDF文件的右键菜单变成「打印」而非「开启」",
                            "ID": "33018",
                            "UrlName": "/support/33018",
                            "Search":"双击，打印",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "安装了文电通后，原本在IE处理PDF相关的程序或服务出现错误 (Gaaiho PDF)",
                            "ID": "33019",
                            "UrlName": "/support/33019",
                            "Search":"IE，Internet Explorer",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我的授权突然变成过期/试用版，而且重新激活时出现了「0x20020221」错误，怎么办？",
                            "ID": "33020",
                            "UrlName": "/support/33020",
                            "Search":"0x20020221",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "先后安装了不同的产品，导致出现浮水印或者转换出错的问题",
                            "ID": "33021",
                            "UrlName": "/support/33021",
                            "Search":"打印，水印",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为何我无法用Adobe Reader来签名？",
                            "ID": "33022",
                            "UrlName": "/support/33022",
                            "Search":"Adobe Reader，签名",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为何转换之后边距改变了？",
                            "ID": "33023",
                            "UrlName": "/support/33023",
                            "Search":"边距",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "Right PDF Reader支持填写表单吗，填写的表单是否可以保存？ ",
                            "ID": "33024",
                            "UrlName": "/support/33024",
                            "Search":"Right PDF Reader，表单",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "Converter/ Driver在使用中转换设置突然全部变成了空的，无法设置了，怎么办？",
                            "ID": "33025",
                            "UrlName": "/support/33025",
                            "Search":"Converter，Driver，转换设置",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "在打印Word文档时把Letter型的纸张打印成A4纸张，怎么办？",
                            "ID": "33026",
                            "UrlName": "/support/33026",
                            "Search":"打印，Letter，A4",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么我右键点击xls文件将它转换成PDF文件时Excel没有响应?",
                            "ID": "33027",
                            "UrlName": "/support/33027",
                            "Search":"Excel，xls",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "先运行Outlook然后运行微软Word 2003，Gaaiho图标不出现?",
                            "ID": "33028",
                            "UrlName": "/support/33028",
                            "Search":"Outlook",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么我使用Right PDF Editor/ Gaaiho Doc打印文档却没有反应？",
                            "ID": "33029",
                            "UrlName": "/support/33029",
                            "Search":"打印",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么页面大小与「一般＞页面尺寸」里面设置的不一样?",
                            "ID": "33030",
                            "UrlName": "/support/33030",
                            "Search":"页面大小",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么使用Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)从源文件生成的PDF文档变大了?",
                            "ID": "33031",
                            "UrlName": "/support/33031",
                            "Search":"文档变大，压缩",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "使用Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)转换PDF/A结果不成功？",
                            "ID": "33032",
                            "UrlName": "/support/33032",
                            "Search":"PDF/A",
                            "SubType": "A"
                        }
                    ]
                },
                {
                    "TypeName": "其它常见问题解答",
                    "ID": "34000",
                    "UrlName": "/support/34000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "如何寻找遗失的序列号？",
                            "ID": "34001",
                            "UrlName": "/support/34001",
                            "Search":"序列号",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何查看我的版本及授权信息？",
                            "ID": "34002",
                            "UrlName": "/support/34002",
                            "Search":"版本，授权信息",
                            "SubType": "A"
                        },
                        {
                            "TypeName": " 我找不到以前的授权邮件，怎么办?",
                            "ID": "34003",
                            "UrlName": "/support/34003",
                            "Search":"授权邮件",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我忘了我的Right PDF/ Gaaiho密码了，要怎样才能登录呢?",
                            "ID": "34004",
                            "UrlName": "/support/34004",
                            "Search":"忘记密码",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何计算转换上限？",
                            "ID": "34005",
                            "UrlName": "/support/34005",
                            "Search":"转换上限",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何将文电通PDF设置为默认开启PDF文件的程序？",
                            "ID": "34006",
                            "UrlName": "/support/34006",
                            "Search":"默认",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通专业版(Gaaiho PDF Suite)里面的Gaaiho Doc有快捷键列表可以参考吗？",
                            "ID": "34007",
                            "UrlName": "/support/34007",
                            "Search":"快捷键",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)可以支持PostScript语言吗?",
                            "ID": "34008",
                            "UrlName": "/support/34008",
                            "Search":"PostScript",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "在Windows 8.1 IE11上为什么没有Gaaiho的插件？",
                            "ID": "34009",
                            "UrlName": "/support/34009",
                            "Search":"插件，Windows 8.1，IE11",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通PDF套装版(Right PDF Pro/ Gaaiho PDF Suite)能否支持终端服务器环境？",
                            "ID": "34010",
                            "UrlName": "/support/34010",
                            "Search":"TSL，终端服务器授权",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我的注册信息有外流的危险吗?",
                            "ID": "34011",
                            "UrlName": "/support/34011",
                            "SubType": "A"
                        }
                    ]
                },
                {
                    "TypeName": "文电通(DocuCom)常见问题",
                    "ID": "35000",
                    "UrlName": "/support/35000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "生成的PDF突然出现了水印而且程序变成了试用版，怎么办？",
                            "ID": "35001",
                            "UrlName": "/support/35001",
                            "Search":"水印，试用",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "产品激活时，出现「请以管理员身分运行」提示？",
                            "ID": "35002",
                            "UrlName": "/support/35002",
                            "Search":"请以管理员身分运行",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么会出现错误代码0x20020221？",
                            "ID": "35003",
                            "UrlName": "/support/35003",
                            "Search":"0x20020221",
                            "SubType": "A"
                        }
                    ]
                }
            ]
        },
        {
            "TypeName": "第4章：文电通订阅版问题集",
            "ID": "40000",
            "UrlName": "/support/40000",
            "SubType": "C",
            "Children": [
                {
                    "TypeName": "文电通订阅版问题集",
                    "ID": "41000",
                    "UrlName": "/support/41000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "文电通PDF套装版订阅版(Right PDF Subscriptions)授权如何激活？",
                            "ID": "41001",
                            "UrlName": "/support/41001",
                            "Search":"订阅版，激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我可以在授权到期前续租吗？还是一定要在到期的那一天续费？",
                            "ID": "41002",
                            "UrlName": "/support/41002",
                            "Search":"订阅版，续租",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我原来购买过授权，可是现在使用人数/时间不够了，我要如何加买授权？",
                            "ID": "41003",
                            "UrlName": "/support/41003",
                            "Search":"订阅版，加购",
                            "SubType": "A"
                        },
                        {
                            "TypeName": " 如何将PDF文电通套装版订阅版(Right PDF Subscription)升级至最新版本，是否要付费？",
                            "ID": "41004",
                            "UrlName": "/support/41004",
                            "Search":"订阅版，升级",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通PDF套装版订阅版(Right PDF Subscription)能在终端服务器上使用吗？",
                            "ID": "41005",
                            "UrlName": "/support/41005",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "为什么我的订阅版授权还在有效期内，却出现许可证检查失败的错误信息? (Right PDF)",
                            "ID": "41006",
                            "UrlName": "/support/41006",
                            "Search":"许可证检查失败",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "订阅版激活失败，出现错误码“126”：您的账号无效。请使用已授权的账号激活",
                            "ID": "41007",
                            "UrlName": "/support/41007",
                            "Search":"126",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通PDF套装版订阅版(Right PDF Subscription)要如何退款？",
                            "ID": "41008",
                            "UrlName": "/support/41008",
                            "Search":"退款",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通PDF套装版 订阅版(Right PDF Subscription)能否支持离线激活？",
                            "ID": "41009",
                            "UrlName": "/support/41009",
                            "Search":"订阅版，离线激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通PDF套装版订阅版(Right PDF Subscription)购买的使用时间，是按30天/月或者365天/年来计算的吗？",
                            "ID": "41010",
                            "UrlName": "/support/41010",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通PDF套装版订阅版(Right PDF Subscription)授权是从使用的时候开始算还是从购买的时候开始？",
                            "ID": "41011",
                            "UrlName": "/support/41011",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "除了文电通PDF「套装版」以外，转换器与其它系列产品有订阅版吗？",
                            "ID": "41012",
                            "UrlName": "/support/41012",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通PDF套装版订阅版(Right PDF Subscription)授权能否多台计算机共用一个账号激活？",
                            "ID": "41013",
                            "UrlName": "/support/41013",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我是公司IT部门负责人，我刚购买了10个文电通PDF套装版订阅版(Right PDF Subscription)授权，要如何开始部署？",
                            "ID": "41014",
                            "UrlName": "/support/41014",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在批量授权中清除掉不再使用订阅版(Right PDF Subscription)的用户？",
                            "ID": "41015",
                            "UrlName": "/support/41015",
                            "Search":"订阅版，授权管理",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如果我的授权已经到期一阵子了，我却忘了续费，现在续费的话，这一阵子的费用需要补齐吗？",
                            "ID": "41016",
                            "UrlName": "/support/41016",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "加买的授权和原有的授权的到期时间不能一样吗？",
                            "ID": "41017",
                            "UrlName": "/support/41017",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通PDF订阅版 (Right PDF Subscription) 授权是否允许在多台设备上使用同一个（或不同）账号激活？",
                            "ID": "41018",
                            "UrlName": "/support/41018",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "订阅版激活失败，错误码「127」账号或密码错误",
                            "ID": "41019",
                            "UrlName": "/support/41019",
                            "Search":"127",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "激活订阅版授权时，出现错误「-10180」",
                            "ID": "410120",
                            "UrlName": "/support/41020",
                            "Search":"-10180",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "每次打开程序都要弹出登录窗口，怎么办？",
                            "ID": "41021",
                            "UrlName": "/support/41021",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如果我的电脑崩溃了，我还能在新的电脑上使用原来的订阅版(Right PDF Subscription)授权吗？",
                            "ID": "41022",
                            "UrlName": "/support/41022",
                            "Search":"订阅版",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何取得文电通PDF套装版订阅版(Right PDF Subscription)试用版并安装?",
                            "ID": "41023",
                            "UrlName": "/support/41023",
                            "Search":"订阅版",
                            "SubType": "A"
                        }
                    ]
                }
            ]
        },
        {
            "TypeName": "第5章：文电通macOS问题汇总",
            "ID": "50000",
            "UrlName": "/support/50000",
            "SubType": "C",
            "Children": [
                {
                    "TypeName": "激活、反激活与试用",
                    "ID": "51000",
                    "UrlName": "/support/51000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": "激活授权码或序列号时，出现错误码 0x73  ",
                            "ID": "51001",
                            "UrlName": "/support/51001",
                            "Search":"0x73",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在线/离线激活 文电通PDF macOS?",
                            "ID": "51002",
                            "UrlName": "/support/51002",
                            "Search":"macOS，激活",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何在线/离线反激活 文电通PDF macOS?",
                            "ID": "51003",
                            "UrlName": "/support/51003",
                            "Search":"macOS，反激活",
                            "SubType": "A"
                        }
                    ]
                }
            ]
        },
        {
            "TypeName": "第6章：购买与选择文电通系列产品",
            "ID": "60000",
            "UrlName": "/support/60000",
            "SubType": "C",
            "Children": [
                {
                    "TypeName": "购买与选择文电通系列产品",
                    "ID": "61000",
                    "UrlName": "/support/61000",
                    "SubType": "C",
                    "showList":true,
                    "Children": [
                        {
                            "TypeName": " 如何购买产品？",
                            "ID": "61001",
                            "UrlName": "/support/61001",
                            "Search":"购买",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我该如何区分永久型授权、订阅版授权的差别？",
                            "ID": "61002",
                            "UrlName": "/support/61002",
                            "Search":"永久型授权和订阅版授权的差别",
                            "SubType": "A"
                        },
                        {
                            "TypeName": " 我需要远程控制一台电脑打印PDF，需要什么授权？",
                            "ID": "61003",
                            "UrlName": "/support/61003",
                            "SubType": "A"
                        },
                        {
                            "TypeName": " 文电通PDF转换器和文电通PDF套装版，我需要选哪个？",
                            "ID": "61004",
                            "UrlName": "/support/61004",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "文电通转换器(Right PDF Converter)和文电通转换服务器(Right PDF Server)，哪个更加适合我们公司？",
                            "ID": "61005",
                            "UrlName": "/support/61005",
                            "SubType": "A"
                        },
                        {
                            "TypeName": " 终端服务器版授权有什么好处？",
                            "ID": "61006",
                            "UrlName": "/support/61006",
                            "Search":"终端服务器版授权",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "Right PDF Reader是免费的吗？",
                            "ID": "61007",
                            "UrlName": "/support/61007",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "在网站上直接购买如何开发票？",
                            "ID": "61008",
                            "UrlName": "/support/61008",
                            "Search":"发票",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "如何加买文电通PDF套装版(Right PDF Pro)授权?",
                            "ID": "61009",
                            "UrlName": "/support/61009",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我在网站上看到可购买终端服务器版 (TSL)，那是什么?",
                            "ID": "61010",
                            "UrlName": "/support/61010",
                            "Search":"TSL，终端服务器授权",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我应该购买「软件维护服务(SMS)」吗？",
                            "ID": "61011",
                            "UrlName": "/support/61011",
                            "Search":"SMS",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "我想在服务器端转换PDF文件，我应该购买转换服务器(PDF Server)还是转换器(PDF Driver)?",
                            "ID": "61012",
                            "UrlName": "/support/61012",
                            "SubType": "A"
                        },
                        {
                            "TypeName": "终端服务器群集要如何购买授权？",
                            "ID": "61013",
                            "UrlName": "/support/61013",
                            "SubType": "A"
                        }
                    ]
                }
            ]
        }
    ]
}

export default mdata;