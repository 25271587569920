
import '@babel/polyfill';

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './assets/style/notosanstc.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/custom.scss';
import './assets/style/article.css';
import App from './App';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import mainReducer from './reducers';

const myStore = createStore(mainReducer);

ReactDOM.render(
  <React.StrictMode>
        <Suspense fallback={(<div>Loading ~~</div>)}>
          <Provider store={myStore}>
            <App />
          </Provider>
        </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

