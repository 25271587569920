import React from 'react';
import MetaTags from 'react-meta-tags';
const SuiteCompare = props => {
	const metaTagscontent = {
		title:'真的非Adobe不可? - PDF文電通邀您一同思考5項觀點 | 文電通 Right PDF',
		desc:'在軟體預算、安全性與靈活性的考慮與日俱增的情況下，選擇聰明又實在的解決方案，才是為您和您的企業增加競爭力的關鍵。'
	};
	const viewcontent =
		`<h1 id="p1">不定時炸彈：您的軟體授權100%合乎規範嗎?</h1>

<h2 class="marginB40"><span style="color:#c43e3e">企業長年購買累積的各種軟體，其實就是一顆不定時炸彈</span></h2>

<p class="marginB50"><span style="color:#c43e3e">在受調查的企業之中&hellip;</span></p>
<div class="box7Wrapper">
	<div class="box6 box7">
	<p><strong>38%的企業表示至少11%的軟體採購預算花在了不合規範的使用上</strong></p>

	<p><img alt="11%" src="${require(`../../assets/img/resources/Group 1307.svg`)}" /></p>
	</div>

	<div class="box6 box7">
	<p><strong>56%的企業表示，約有11%以上的軟體採購預算支付在使用率低的軟體上</strong><sup>(i)</sup></p>

	<p><img alt="11%" src="${require(`../../assets/img/resources/Group 1316.svg`)}" /></p>
	</div>

	<div class="box6 box7">
	<p><strong>63%的企業近18~24個月內遭到軟體商的稽查</strong></p>

	<p><img alt="63%" src="${require(`../../assets/img/resources/Group 1315.svg`)}" /></p>
	</div>

	<div class="box6 box7 marginB50">
	<p><strong>56%的企業選擇賠償結案；其中約21%的企業支付了百萬元美金以上的費用</strong><sup>(ii)</sup></p>

	<p><img alt="56%" src="${require(`../../assets/img/resources/Group 1330.svg`)}" /></p>
	</div>
</div>
<div class="redbox marginB40">
<p><span style="font-size:24px"><span style="color:#8e44ad"><img alt="client_logo_evergreen" src="${require(`../../assets/img/warning.png`)}" /></span></span></p>

<p><strong><span style="color:#c40000">Gartner在2014年發表一份名為「Surviving an Adobe Software License Auditi</span></strong><sup><span style="color:#c40000">(iii)</span></sup><strong><span style="color:#c40000">」的報告中指出， Adobe稽查客戶的比例不僅遠高於其他獲利相當的軟體廠商，似乎還強化了稽查的程序。</span></strong></p>
</div>

<p class="marginB50">只要您公司裡面有人下載並安裝軟體，廠商即有權追查你是否合乎授權條款。一旦發起突擊檢查，您就只能被迫接受昂貴、耗時又令人煩心的流程。</p>

<h2 class="marginB40"><span style="color:#087a4f">或者，您可以瞭解文電通對此 的看法：</span></h2>

<div class="box1 marginB70">
<ul>
	<li>
	<p><strong>我們不會突擊檢查：</strong><br />
	時下軟體廠商經常採用突擊策略，但棣南認為每位客戶都應該有事先準備的機會。</p>
	</li>
	<li>
	<p><strong>超出合約規範並不罰款：</strong><br />
	即便發現使用者人數不符規範，我們也不會索取罰款。</p>
	</li>
	<li>
	<p><strong>毫無壓力的季度稽查：</strong><br />
	客戶僅需自我檢查並每季向文電通申報並補足超出的部分。</p>
	</li>
	<li>
	<p><strong>企業專屬的彈性授權條款：</strong><br />
	企業授權方案非常簡單，您只要簽署幾張文件就能完成。</p>
	</li>
</ul>
</div>

<h1 id="p2">友善對待使用者、IT人員以及企業</h1>

<div class="box1 box2">
<h3 class="marginB40"><strong>/// Adobe</strong></h3>

<p class="marginB10"><strong>學習曲線</strong></p>

<p class="marginB30">Adobe一向墨守傳統介面配置，但Microsoft Office的Ribbon式設計已在全球擁有<strong>12億</strong><sup>(iv)</sup>使用者，是現今最受歡迎的操作介面。因此，不難想像使哪一種介面配置會較受使用者接受，同時也導致較少的適應問題。</p>

<p class="marginB10"><strong>軟體大小</strong></p>

<p>既然在單一部門部署新軟體都不見得輕鬆，更何況是整個企業。首先，來看看Acrobat需要的硬體空間：</p>

<p class="box3"><span style="color:#c43e3e"><strong>4.5 GB</strong></span><span style="color:#c43e3e"><i>Acrobat Pro DC,</i><i> Acrobat Standard DC</i></span></p><sup>(v)</sup>

</div>

<div class="box1 marginB50">
<h3 class="marginB30"><span style="color:#087a4f"><strong>/// 文電通的方式</strong></span></h3>

<p class="marginB10"><strong>熟悉的介面設計</strong></p>

<ul class="marginB20">
	<li>
	<p>易於操作與迅速適應</p>
	</li>
	<li>
	<p>降低技術支援與教育訓練的依賴程度</p>
	</li>
	<li>
	<p>將省下的適應時間投注在更重要的工作</p>
	</li>
</ul>

<p class="marginB10"><strong>輕巧</strong></p>

<p class="box3 box4"><span style="color:#087a4f"><strong>700MB左右</strong></span><span style="color:#087a4f">減少84%的占用空間</span></p>

<p class="marginB10"><strong>靈活部署</strong></p>

<ul>
	<li class="marginB0">
	<p>永久授權</p>
	</li>
	<li class="marginB0">
	<p>訂閱版授權</p>
	</li>
	<li class="marginB0">
	<p>終端伺服器授權（Windows&reg; Terminal Server）</p>
	</li>
	<li class="marginB0">
	<p>企業VLP授權</p>
	</li>
</ul>
</div>

<h1 id="p3">建立、編輯PDF與OCR</h1>

<p>為了呈現更出色的作業品質以及提升生產力，棣南為文電通精心設計了建立、編輯PDF以及OCR(光學字元識別)功能。藉著PDF文電通專業版6，您可以：</p>

<div class="box1">
<h3><strong>轉換為可編輯的PDF</strong></h3>

<p>將Excel或Word轉換而來的PDF變為可修改的狀態，讓編輯變得既簡單又直覺。</p>

<ul>
	<li class="marginB0">
	<p>轉換靜態的PDF為可編輯的文件</p>
	</li>
	<li class="marginB0">
	<p>單鍵切換審閱與編輯模式</p>
	</li>
	<li class="marginB0">
	<p>以豐富完整的功能編輯文件。例如字型、段落、表格、圖片和註腳。</p>
	</li>
	<li class="marginB0">
	<p>直接編輯Excel檔案</p>
	</li>
</ul>
</div>

<div class="box1">
<h3><strong>OCR(光學字元識別)</strong></h3>

<p>讓全新的OCR功能助您將掃描版文件轉換成可搜尋的PDF檔案。</p>

<ul>
	<li class="marginB0">
	<p>開啟掃描版PDF文件並執行OCR功能</p>
	</li>
	<li class="marginB0">
	<p>在轉換檔案格式時執行OCR功能</p>
	</li>
	<li class="marginB0">
	<p>利用文電通檢查並排除錯誤的辨識結果</p>
	</li>
</ul>
</div>

<div class="box1 marginB70">
	<h3><strong>雙向批次轉換</strong></h3>

	<p class="marginB30">PDF文電通的雙向批次轉換功能，不但減少更多檔案大小，且更加降低CPU佔用率。它同時支援所有可列印的檔案類型。</p>

	<div id="p35" class="marginB10">
		<div class="box7">
		<p class="font18"><strong>轉換後檔案大小</strong></p>

		<p class="font30"><span style="color:#35a562"><strong>少10.2%</strong></span></p>
		</div>

		<div class="box7">
		<p class="font18"><strong>轉換速度</strong></p>

		<p class="font13">Word/Excel/PPT轉換為PDF</p>

		<p class="font30"><span style="color:#35a562"><strong>快40.5%</strong></span></p>
		</div>

		<div class="box7">
		<p class="font18"><strong>CPU使用量</strong></p>

		<p class="font13">Word/Excel/PPT轉換為PDF</p>

		<p class="font30"><span style="color:#35a562"><strong>少40%</strong></span></p>
		</div>
	</div>

	<p class="from">*資料來源：內部測試結果</p>
</div>

<h1 id="p4">淺談雲端、訂閱版對您的影響</h1>

<p class="marginB50">隨著雲端運算時代的來臨，訂閱版授權成為新興的軟體授權趨勢。不過在您順應潮流之前，不妨先瞭解軟體授權的種類與優缺點。</p>

<h2 id="p41">A. 訂閱版授權與永久型授權</h2>

<p class="marginB0">行銷話術往往將訂閱版授權塑造為更佳的選擇。但是何不在購買之前仔細比較箇中差異呢?</p>

<p>請您先詢問自己下列三個問題：</p>

<div class="box1 marginB50">
<ol>
	<li class="marginB0">
	<p><strong>這套軟體的用途是?</strong></p>
	</li>
	<li class="marginB0">
	<p><strong>是否需要頻繁的更新軟體?</strong></p>
	</li>
	<li class="marginB0">
	<p><strong>公司偏好將軟體費用列為資本支出（CAPEX）還是營運支出（OPEX）/或是政府的資本門或經常門?</strong></p>
	</li>
</ol>
</div>

<h3 class="marginB40"><strong>以Acrobat DC Pro訂閱版為例：</strong></h3>

<p class="marginB30"><strong>軟體費用</strong><br />
訂閱版授權的總費用大約在第36個月與一套永久授權相同(以美版價格計算)。之後，訂閱版授權支付的費用將超過永久型授權。</p>

<p class="marginB30"><strong>更新頻率</strong><br />
如果作業內容僅使用到PDF的基礎功能，例如註解、簽名和編輯，其實不太需要頻繁更新/升級到最新的軟體版本。</p>

<p class="marginB30"><strong>軟體維護服務</strong><br />
永久授權也提供了軟體維護服務，讓升級變得更簡易、更具成本效益，而不是只有訂閱版才能享有免費升級。</p>

<p class="marginB50"><strong>資本支出或營運支出</strong><br />
相較於長期列為營運支出，資本密集型的企業為了獲得遞延稅項的好處，偏好將軟體費用編列為資本支出；以政府部會而言的話則是資本門與經常門的差異。</p>

<h2 id="p42" class="marginB40">B. 雲端帶來的風險</h2>

<p class="marginB50">在Adobe DC提供的「Document Cloud」服務中，您必須透過Adobe的伺服器傳遞資料，但也別忘了十年來發生的大規模資安事件<sup>(vi)</sup>：</p>

<div class="redbox1 marginB50">
	<div>
	<p><strong><span style="color:#c40000">2013 - ADOBE</span></strong></p>

	<p><span style="color:#c40000">約<strong>1.53億</strong>筆客戶資料外流。</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2017 - Equifax</span></strong></p>

	<p><span style="color:#c40000">約<strong>1.48億</strong>使用者的生日、地址、信用卡號、電話遭到竊取，後續耗資7億美金收尾。</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2018 - Mariott</span></strong></p>

	<p><span style="color:#c40000"><strong>5.0億</strong>筆客戶個資外洩，最終以2400萬美元達成和解。</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2019 - Facebook</span></strong></p>

	<p><span style="color:#c40000"><strong>5.4億</strong>筆紀錄受到影響，包括了電話、名字、性別、地址等。</span></p>
	</div>
</div>

<h3 class="marginB50"><strong>加入雲端行列之前，有三項需要慎重考慮的重要因素：</strong></h3>

<div class="box1 marginB50">
<ul>
	<li>
	<p><strong>雲端安全性</strong><br />
	全方位防堵網路攻擊是困難的任務，意味著將重要資料儲存在雲端，未必100%的安全。</p>
	</li>
	<li>
	<p><strong>存取權限</strong><br />
	使用者的存取權限往往只受限於用戶端，但運營商或駭客或許能直接存取在伺服器上的重要資料。</p>
	</li>
	<li>
	<p><strong>司法管轄權</strong><br />
	若儲存資料的伺服器不在本國的司法管轄權內，可能會導致複雜的法律問題，例如無法要求永久移除您的資料。</p>
	</li>
</ul>
</div>

<h2 id="p43">C. 您真的需要連結雲端的功能嗎?</h2>

<p>您真的需要用到「線上傳送並追蹤文件」、「隨時隨地建立PDF」，或「在iPad新增、編輯或重排頁面」這些功能?</p>

<p>同樣的，請您先思考下列這三個問題：</p>

<div class="box1">
<ol>
	<li>
	<p><strong>您是以電腦完成絕大部份工作?</strong></p>
	</li>
	<li>
	<p><strong>公司可以接受透過公有雲傳送內部資料?</strong></p>
	</li>
	<li>
	<p><strong>行動裝置是否具備足夠的資安防護?</strong></p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<p>儘管行動裝置相當便利，我們依舊習慣在辦公室中用電腦完成文書作業。當然，某些情況下以行動裝置處理工作確實必要，但不可否認滑鼠、鍵盤和大螢幕的組合才是生產力的來源，而這並非行動裝置的優勢所在。</p>

<p>除此之外，即便您需要使用雲端存儲服務，您也可以透過OneDrive、Dropbox、Google Drive等常見的雲端空間儲存和傳遞檔案。既然您有許多免費的選擇，又何必付費購買?</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 id="p5">您軟體的投資報酬率?</h1>

<p>以龐雜功能、巨大安裝空間聞名的Adobe Acrobat，單機授權費用一套新台幣37170元（Pro 2020網路經銷商通路定價），訂閱版授權則是每月新台幣641元（Acrobat DC，必須簽約一年）。</p>

<p>現在，想想您的工作流程，再詢問自己這幾個問題：</p>

<div class="box1">
<ol>
	<li>
	<p><strong>究竟那些功能才是我工作時用到的?</strong></p>
	</li>
	<li>
	<p><strong>Acrobat的功能中，有多少您幾乎從未使用過?</strong></p>
	</li>
	<li>
	<p><strong>一次付清37170元或月付641元，值得嗎?</strong></p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<h3><strong>以下是一般而言使用率極低的功能，但您卻不自覺的一齊買單了</strong></h3>

<p style="text-align:center"><img alt="useless fuctions " src="${require(`../../assets/img/resources/useless_fuctions_tw.png`)}" /></p>

<p>&nbsp;</p>

<h2><strong>或者，您需要的是輕巧、高C/P值，且表現毫不遜色的產品?</strong></h2>

<div class="box5">
<h2><span style="color:#ffffff">PDF文電通專業版</span></h2>
<div class="marginR20">
<p><span style="color:#ffffff">永久型授權</span></p>

<p><span style="color:#ffe600">TWD <strong class="strong48">5800</strong> *</span></p>
</div>
<div>
<p><span style="color:#ffffff">訂閱版授權(年付)</span></p>

<p><span style="color:#ffe600">TWD <strong class="strong48">325</strong></span></p>
</div>

<p><span style="color:#ffffff">*單價隨購買數量增加而降低。例如：購買5套Adobe Acrobat Pro 2020的授權，相同的數量，PDF文電通可以為您省下新台幣159,850元。</span></p>
</div>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 id="p7">榮耀與肯定</h1>
<div class="part2Wrapper">
	<div class="box1 box8">
	<p><img alt="prize ICT innovative" src="${require(`../../assets/img/resources/prize_ICT_innovative.png`)}" /></p>

	<p><strong>104資訊月『百大創新產品獎』</strong></p>
	</div>
	<div class="box1 box8">
	<p><img alt="prize techbang " src="${require(`../../assets/img/resources/prize_techbang.png`)}" /></p>

	<p><strong>2014科技趨勢金獎─特別推薦金獎</strong></p>
	</div>
</div>
<div class="seprate"></div>
<div class="part4Wrapper">
	<div>
	<p><img alt="client_logo_academiasinica" src="${require(`../../assets/img/resources/client_logo_academiasinica.png`)}" /></p>

	<p>「PDF文電通提高了轉換後的檔案格式正確率，減少了修改時間。集中部署Server也幫我們省下很多資源。」</p>

	<p><strong>中央研究院</strong></p>
	</div>
	<div>
	<p><img alt="client_logo_taipeigov" src="${require(`../../assets/img/resources/client_logo_taipeigov.png`)}" /></p>

	<p>「PDF文電通將行政程序化繁為簡，讓團隊作業更具有生產力與協調性。」</p>

	<p><strong>臺北市政府主計處</strong></p>
	</div>
	<div>
	<p><img alt="client_logo_artesyn" src="${require(`../../assets/img/resources/client_logo_artesyn.png`)}" /></p>

	<p>「我們不僅需要能快速佈署的解決方案，還須以較低成本取得強大的PDF功能，而PDF文電通是個絕佳選擇。」</p>

	<p><strong>雅特生科技</strong></p>
	</div>
	<div>
	<p><img alt="client_logo_evergreen" src="${require(`../../assets/img/resources/client_logo_evergreen.png`)}" /></p>

	<p>「文電通不但簡化了航空業大量轉換與合併作業，終端伺服器版的授權、部署也完全符合我們的作業模式。」</p>

	<p><strong>長榮航太</strong></p>
	</div>
</div>

<h1 id="p6">企業與您的最佳選擇：PDF文電通</h1>

<p>我們希望這5項觀點能協助您瞭解自身的需求，並做出最適合的採購決策。在軟體預算、安全性與靈活性的考慮與日俱增的情況下，選擇聰明又實在的解決方案，才是為您和您的企業增加競爭力的關鍵。如今Acrobat已不再是唯一的選項，是否要轉換成更實用、更經濟、更合理的軟體，全然操之您手。</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 style="text-align:center"><span style="color:#37915c">立即聯絡棣南，瞭解適合您企業的最佳PDF軟體!</span></h1>

<p style="text-align:center"><a href="https://www.rightpdf.com/zh-TW/contactUs" target="_blank" style="width:210px;height:60px;background:#087A4F;display:inline-block;font-size:17px;color:#fff;line-height:60px;border-radius:4px;margin-top:40px;">聯絡我們</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>
<div id="p37">
<p><strong>關於棣南</strong></p>

<p><strong>棣南股份有限公司正在改變使用者與PDF的互動方式。迥異於常見的PDF軟體，我們的產品理念側重「自由編輯」，令使用者不須經過繁複操作或尋找原始檔案，就能輕鬆創造、書寫與修改PDF文件。文電通集編輯、直覺式介面、豐富功能於一身，賦予全球專業人士和企業組織無比的效率、生產力與想像力。</strong></p>
</div>
<p>&nbsp;</p>

<p>資料來源</p>

<ol style="list-style-type:lower-roman">
	<li><strong>IDC.</strong> http://www.slideshare.net/flexerasoftware/sw-licensing-and-pricing-results-ss11-konary</li>
	<li><strong>Business Wire</strong>. http://www.businesswire.com/news/home/20150224005807/en/Vendor-audits-rise-28-software-remains-unused</li>
	<li><strong>Gartner, Inc.</strong> https://www.gartner.com/doc/2669415/surviving-adobe-software-license-audit</li>
	<li><strong>Windows Central.</strong> https://www.windowscentral.com/there-are-now-12-billion-office-users-60-million-office-365-commercial-customers</li>
	<li><strong>Adobe Systems.</strong> https://helpx.adobe.com/acrobat/system-requirements.html</li>
	<li><strong>SecureWorld.</strong> https://www.secureworld.io/industry-news/top-10-data-breaches-of-all-time</li>
</ol>`;
return (<div>
	<MetaTags>
	   <title>{metaTagscontent.title}</title>
	   <meta name="title" content={metaTagscontent.title} />
	   <meta name="description" content={metaTagscontent.desc} />
	   <meta property="og:title" content={metaTagscontent.title} />
	   <meta property="og:description" content={metaTagscontent.desc} />
   </MetaTags>
   <div dangerouslySetInnerHTML={{__html:viewcontent}}></div>
</div>);
}
export default SuiteCompare;
