const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为何我在系统还原后无法激活产品？ </title></head><body>  <h1>为何我在系统还原后无法激活产品？ </h1>  <h2><strong>问题描述：</strong></h2>
<p>在系统还原后无法激活产品。</p>
<h2> </h2>
<h2><strong>解释：</strong></h2>
<p>系统还原很可能会删除产品的重要授权文件并损坏激活组件。 在还原之后，大部分授权状态都会成为「试用」。 如果您在还原之前忘记反激活产品，请参考文章最下方的延伸阅读区。 </p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方案：</strong> </span></h2>
<p><span class="wysiwyg-color-blue130">1. 遵照参考文章<span class="wysiwyg-color-blue120"><a href="https://support.rightpdf.com/hc/zh-cn/articles/201077173-%E5%A6%82%E4%BD%95%E5%8D%B8%E8%BD%BD%E6%96%87%E7%94%B5%E9%80%9APDF%E5%A5%97%E8%A3%85%E7%89%88-%E8%BD%AC%E6%8D%A2%E5%99%A8-" target="_blank" rel="noopener">如何卸载文电通PDF套装版/转换器</a></span>卸载产品 <br>2. 安装程序（如果找不到安装包，请前往<a href="https://store.rightpdf.cn/download/central.aspx" target="_blank" rel="noopener">官方网站</a>的下载页面 或 <a href="https://store.rightpdf.cn/download/archive.aspx" target="_blank" rel="noopener">下载旧版</a>） <br>3. 使用同样的序列号激活</span></p>
<p> </p>
<h3><span class="wysiwyg-color-green130"><strong>延伸阅读：</strong></span></h3>
<ul>
<li><span class="wysiwyg-color-black"><a href="https://support.rightpdf.com/hc/zh-cn/articles/200876098-%E6%88%91%E6%BF%80%E6%B4%BB%E4%BA%A7%E5%93%81%E6%97%B6%E8%BE%BE%E5%88%B0%E4%BA%86%E6%8E%88%E6%9D%83%E4%B8%8A%E9%99%90-%E8%AF%B7%E9%97%AE%E8%AF%A5%E6%80%8E%E4%B9%88%E5%8A%9E-" target="_self" rel="undefined">我激活产品时达到了授权上限，请问该怎么办？</a></span></li>
<li><span class="wysiwyg-color-black"><a href="https://support.rightpdf.com/hc/zh-cn/articles/200884858-%E5%A6%82%E4%BD%95%E5%9C%A8%E7%BA%BF%E6%B8%85%E9%99%A4%E6%BF%80%E6%B4%BB%E8%AE%B0%E5%BD%95-" target="_self" rel="undefined">如何在线清除激活记录？</a></span></li>
</ul>
<p><span class="wysiwyg-color-black"><a href="https://support.rightpdf.com/hc/zh-cn/articles/200876098-%E6%88%91%E6%BF%80%E6%B4%BB%E4%BA%A7%E5%93%81%E6%97%B6%E8%BE%BE%E5%88%B0%E4%BA%86%E6%8E%88%E6%9D%83%E4%B8%8A%E9%99%90-%E8%AF%B7%E9%97%AE%E8%AF%A5%E6%80%8E%E4%B9%88%E5%8A%9E-"> </a></span></p>
<p><span class="wysiwyg-color-black"> </span></p>
<p><span class="wysiwyg-color-black"> </span></p></body></html>`;   export default html;