const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何修改PDF文件的背景（透明度、颜色、图片）？</title></head><body>  <h1>如何修改PDF文件的背景（透明度、颜色、图片）？</h1>  <p>如何修改PDF文件的背景（透明度、颜色、图片）？</p>
<p> </p>
<p>当我们收到了一个含有背景图片的PDF文件时，要怎么修改背景，让PDF档焕然一新，变成自己喜欢的设计呢？</p>
<p>范例中，是一个从PPT产生的PDF檔，套用的是PPT的背景主题：</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip10.png" alt="mceclip10.png"></p>
<p> </p>
<p>现在，选择「编辑」<em>(Gaaiho Doc选择「编辑对象」)</em>，并双击背景图案来选取背景（背景将出现蓝色外框，此时单击「Delete」则可以删除掉背景）</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip11 (1).png" alt="mceclip11 (1).png"></p>
<p> </p>
<p>在编辑开启「编辑图形」窗口，<em>(Gaaiho Doc 在工具对象下)</em></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip12 (1).png" alt="mceclip12 (1).png" width="410" height="152"></p>
<p> </p>
<p>现在可以开始修改背景啰！</p>
<h3><strong>1. 透明度：</strong></h3>
<p>「填充效果」选择「颜色」，调整「不透明度」百分比，「基本颜色」选择「透明<img src="https://store.rightpdf.com/images/rtsupport/900007448026/mceclip4.png" alt="mceclip4.png" width="34" height="19">」，点击「查看」可以预览效果</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip13 (1).png" alt="mceclip13 (1).png" width="403" height="386"></p>
<p> </p>
<p>结果：</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip14 (1).png" alt="mceclip14 (1).png"></p>
<h3><strong>2. 背景颜色：</strong></h3>
<p>「填充效果」还是选择「颜色」，这次改变「基本颜色」</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip19.png" alt="mceclip19.png"></p>
<p> </p>
<p>是不是感觉焕然一新呢！</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip15 (1).png" alt="mceclip15 (1).png"></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip16.png" alt="mceclip16.png"></p>
<p> </p>
<h3><strong>3. 渐层：</strong></h3>
<p>接下来，试试将「填充效果」选择「斜度」，选择渐层的2个「颜色」</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip22.png" alt="mceclip22.png"></p>
<p> </p>
<p>是不是跟原来的背景很不一样呢？</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip17.png" alt="mceclip17.png"></p>
<p> </p>
<h3><strong>4. 图片：</strong></h3>
<p>最后，我们来试试将「填满效果」选择「图片」，稍微调整一下「不透明度」，点击「选择影像」来选取一个你中意的影像</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip24.png" alt="mceclip24.png"></p>
<p> </p>
<p>是不是突然之间质感爆棚了呢？</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip18.png" alt="mceclip18.png"></p>
<p>透过上面简单的几个方法，您可以快速将一份现成的PDF文件个人化、个性化，完全看不出原稿的模样！</p>
<p> </p></body></html>`;   export default html;