import React from 'react';
import {ReactComponent as Fb} from '../../assets/img/home/fb.svg';
import {ReactComponent as Blog} from '../../assets/img/home/blog.svg';
import {ReactComponent as Youtube} from '../../assets/img/home/youtube.svg';
import {ReactComponent as Weibo} from '../../assets/img/home/weibo.svg';
import {ReactComponent as QQ} from '../../assets/img/home/qq.svg';
import {Link} from 'react-router-dom';
const SocialListZH = () => {
    return (
        <li className="socialLink">
            <a href="https://www.facebook.com/rightpdf/" target="_blank" rel="noopener noreferrer">
                <Fb color="#515252" />
            </a>
            <a href="https://www.youtube.com/channel/UCvfgWH6r2Uc9IHKfKHUCbag" target="_blank" rel="noopener noreferrer">
                <Youtube color="#515252" />
            </a>
            <a href="https://blog.rightpdf.com/" target="_blank" rel="noopener noreferrer">
                <Blog color="#515252"/>
            </a>
        </li>
    )
}

const SocialListSM = () => {
    return (
        <li className="socialLink">
             <a href="https://weibo.com/zeongaaiho" target="_blank" rel="noopener noreferrer">
                <Weibo color="#777777" />
            </a>
            <a href="https://wpa.qq.com/msgrd?v=3&uin=2816214541&site=qq&menu=yes" target="_blank" rel="noopener noreferrer">
                <QQ color="#777777" />
            </a>
        </li>
    )
}
const FooterBlock = props => {

    const renderFooterList = (footerObj, currentLang) => {

        if(footerObj.social === true){
            if(footerObj.title === '与我们互动'){
                return <SocialListSM />
            }else{
                return <SocialListZH />
            }
        }else{
            return (
                footerObj.linkArray.map((e,i) => {
                    let liWrap = null;
                    if(e.name !== ''){
                        let link = null;
                        if(e.path){
                            link = <Link to={`/${currentLang.type}${e.path}`}>{e.name}</Link>;
                        }else{
                            link = <a href={e.url} target="_blank" rel="noopener noreferrer">{e.name}</a>;
                        }
						
						if(e.type && e.type === "title"){
							liWrap = <li key={i} className="title">{e.name}</li>
						}else{
							liWrap = <li key={i}>{link}</li>
						}
                        
                    }
                    return liWrap;
                })
            )
        }
       
    }
    return (
        <div className={'footerBlock d-20 '+(props.footerObj.social?'socialBlock':'')}>
            <p className={'footerTitle '+(props.active?'active':'')} onClick={()=>{props.MenuSelect(props.footerObj.title,props.active)}}>{props.footerObj.title}</p>
            <ul className={'footerLinkList '+(props.active?'active':'')}>
                {renderFooterList(props.footerObj, props.currentLang)}
            </ul>
        </div>
    );
}

export default FooterBlock;