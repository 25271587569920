import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const CompareTableBlock = props => {
    const {t,dataObj,selectList,active} = props;
    return (
        <div className="compareTableBlock">
                <h3 className="compareTitle" onClick={()=>{props.MenuSelect(dataObj.info,active)}}>
                    {dataObj.info}
                    <FontAwesomeIcon  icon="chevron-down" className={'fa-md arrow '+ (dataObj.info === selectList?'active':'')}/>
                </h3>
                <div  className={'compareTableWrap '+ (dataObj.info === selectList?'active':'')} >
                    <table className="compareTable content">
                        <tbody>
                            {
                                dataObj.listAry.map((child,ci)=>{
                                    return (
                                        <tr key={ci}>
                                            <td>
                                                {
                                                    (child.type)?<span className="compareNote">{t(child.type)}</span>:null
                                                }
                                                {
                                                    child.info
                                                }
                                            </td>
                                            <td>{child.td1}</td>
                                            <td>{child.td2}</td>
                                            <td>{child.td3}<br/>{child.td4}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    )
}

export default CompareTableBlock;