import React, {useEffect, useState} from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import SearchComponent from '../components/search/searchComponent.js';
import mdata from '../assets/support/menudata.js';


const SupportPage = props => {
    let {t,currentLang, i18n} = props;
    let {lang,urlID} = useParams();
    urlID = urlID || '11001';
    const [currentURLID,setcurrentURLID] = useState('');
    const menuData = mdata?mdata.List:[];
    const [mmData,setMmData] = useState(menuData);
    const [testNum,setTestNum] = useState(0);
    const [isMenuLoaded,setMenuLoaded] = useState(false);
    const [featureAry] = useState([]);
    const [isArticleLoaded,setArticleLoaded] = useState(true);
    const [menuOpen,setMenuOpen] = useState(0);
    const [isShow,setIsShow] = useState(true);
    const [metaTitle] = useState(t('metaTag.userManuals.title'));
    const [metaDesc] = useState(t('metaTag.userManuals.desc'));
    const [typeName] = useState('目录');
    const [articleContent,setArticleContent] = useState('');
    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    
    useEffect(() => {
        const getMenuItem = (datalist,id,id1,changeOpen) => {
            var resultItem,parentID;
            for(var i = 0;i<datalist.length;i++){
                var item = datalist[i];
                if(item.ID === id){
                    resultItem = item;
                    parentID = id1;
                }else{
                    if(item.Children && item.Children.length !== 0){
                        var result = getMenuItem(item.Children,id,item.ID,changeOpen);
                        resultItem = result.resultItem;
                        parentID = result.parentID;
                        if(resultItem&&changeOpen){
                            item.isopen = 1;
                        }
                    }
                }
                if(resultItem){
                    break;
                }
            }
            return {resultItem,parentID};
        }
        const setRightContent = (defaultItem) => {
            var templet = '';
            if(defaultItem.showList){
                var list = defaultItem.Children || [];
                for(var i = 0;i<list.length;i++){
                    var item = list[i];
                    templet += `<li><a href="/${currentLang.type}${item.UrlName}">${item.TypeName}</a></li>`;
                }
                templet = `<div class="menuListContainer"><div style="padding-left:39px;"><h5>${defaultItem.TypeName}</h5></div><ul>${templet}</ul><div>`;
            }else{
                var htmlObj = require(`../assets/support/html/${defaultItem.ID}.js`);
                templet = htmlObj&&htmlObj.default;
            }
            setArticleContent(templet);
        }
        const renderTextContent = (articleItem,flatArr) => {
            if(!articleItem){
                setArticleLoaded(true);
                return;
            }
            setRightContent(articleItem);
        } 
        const setMenuScrollTop = (defaultItem) => {
            let menu = document.getElementById('menu');
            let menu_content = document.getElementsByClassName('menuContentContent')[0];
            let menu_selected = document.getElementById(defaultItem.ID);
            if(menu_content&&menu_selected){
                if((menu_selected.offsetTop)>window.innerHeight){
                    menu.setAttribute('toSelected',0);
                    document.documentElement.scrollTop = 20;
                }
            }
        }
        const fetchResource = cLang => {
            //切换语言或是重新渲染需要先清除数据
            setMenuLoaded(true);
            setArticleLoaded(true);
            if(urlID){
                var {resultItem,parentID} = getMenuItem(menuData,urlID,0,true);
                if(resultItem){
                    setcurrentURLID(resultItem.showList?resultItem.ID:parentID);
                    renderTextContent(resultItem);
                    setTimeout(function(){
                        setMenuScrollTop(resultItem);
                    },10)
                }
            }
        }
        document.documentElement.scrollTop = 0;
        fetchResource(currentLang.type);
    }, [currentLang.type,urlID,menuData]);
	const changeShow = (isShow) => {
		setIsShow(isShow);
    }
	const getMenuItem = (datalist,id,id1,changeOpen) => {
        var resultItem,parentID;
        for(var i = 0;i<datalist.length;i++){
            var item = datalist[i];
            if(item.ID === id){
                resultItem = item;
                parentID = id1;
            }else{
                if(item.Children && item.Children.length !== 0){
                    var result = getMenuItem(item.Children,id,item.ID,changeOpen);
                    resultItem = result.resultItem;
                    parentID = result.parentID;
                    if(resultItem&&changeOpen){
                        item.isopen = 1;
                    }
                }
            }
            if(resultItem){
                break;
            }
        }
        return {resultItem,parentID};
    }
    const setRightContent = (defaultItem) => {
        var templet = '';
        if(defaultItem.showList){
            var list = defaultItem.Children || [];
            for(var i = 0;i<list.length;i++){
                var item = list[i];
                templet += `<li><a href="/${currentLang.type}${item.UrlName}">${item.TypeName}</a></li>`;
            }
            templet = `<div class="menuListContainer"><div style="padding-left:39px;"><h5>${defaultItem.TypeName}</h5></div><ul>${templet}</ul><div>`;
        }else{
            var htmlObj = require(`../assets/support/html/${defaultItem.ID}.js`);
            templet = htmlObj&&htmlObj.default;
        }
        setArticleContent(templet);
    }
    const menuClick = () => {
        setMenuOpen(menuOpen === 0?1:0);
    }
    const itemClick = (clickItem) => {
        if(clickItem.showList){
            setMenuOpen(menuOpen === 1?0:1);
        }
        if(!clickItem.showList){
            var {resultItem} = getMenuItem(menuData,clickItem.ID);
            resultItem.isopen = resultItem.isopen === 1?0:1;
            setMmData(menuData);
            setTestNum(testNum === 1?0:1);
        }
        if(clickItem.showList){
            setcurrentURLID(clickItem.ID);
            setRightContent(clickItem);
            document.documentElement.scrollTop = 20;
            var searchcontent = document.getElementsByClassName('searchcontent')[0];
            searchcontent.value = '';
        }
    }
    
    const transformSearchData = (data) => {
        var result = [];
        data.forEach(function(item,index){
            if(item.SubType === "A"){
                result.push(item);
            }
            if(item.Children&&item.Children.length !== 0){
                result = result.concat(transformSearchData(item.Children))
            }

        })
        return result;
    }
    const searchInputEvent = (inputValue) => {
        console.log(inputValue);
        if(inputValue !== ""){
            var list = [];
            var searchData = transformSearchData(mmData);
            searchData.forEach(function(item,index){
                var searchflag = false;
                if(item.Search){
                    var searchSplit = item.Search.split(',');
                    for(var i = 0;i<searchSplit.length;i++){
                        if(searchSplit[i].indexOf(inputValue) !== -1){
                            searchflag = true;
                            break;
                        }
                    }
                }
                if(item.TypeName.indexOf(inputValue) !== -1){
                    searchflag = true;
                }
                if(searchflag){
                    list.push(item);
                }
            })
            var templet = '';
            for(var i = 0;i<list.length;i++){
                var item = list[i];
                templet += `<li><a href="/${currentLang.type}${item.UrlName}">${item.TypeName}</a></li>`;
            }
            templet = `<div class="menuListContainer search_content">
                            <div class="search_content">${t("help.t11")}<span id="search_content_text">${inputValue}</span></div>
                            <ul>${templet}</ul>
                        </div>`;

            setArticleContent(templet);
        }else{
            var {resultItem} = getMenuItem(menuData,currentURLID);
            setRightContent(resultItem);
        }
        
    }
    const makeListTree = (treeData) => {
        const menuliss = treeData.map(function(item,index){
            if(item.Children&&item.Children.length !== 0){
                return (
                    <li id={item.ID} className={(currentURLID === item.ID)?'menu_selected':''} isopen={item.isopen} key={index}>
                        {item.href?(
                            <div className="menuText" ><span className="menuIcon"><a href={item.href}>{item.TypeName}</a></span></div>
                        ):(
                            <div className="menuText" onClick={itemClick.bind(this,item)}><span className={!item.showList?"menuIcon":""}>{item.TypeName}</span></div>
                        )}
                        
                        {(item.Children&&item.Children.length !== 0)?(<ul>{makeListTree(item.Children)}</ul>):''}
                    </li>
                )
            }else{
                return null;
            }
        })
        return menuliss;
    }
    const menulis = mmData.map((item,index)=>{
        if(item.SubType === "C"){
            return (
                <li id={item.ID} className={(currentURLID === item.ID)?'menu_selected':''} isopen={item.isopen} key={index}>
                    {item.href?(
                        <div className="menuText" ><span className="menuIcon"><a href={item.href}>{item.TypeName}</a></span></div>
                    ):(
                        <div className="menuText" onClick={itemClick.bind(this,item)}><span className={!item.showList?"menuIcon":""}>{item.TypeName}</span></div>
                    )}
                    
                    {(item.Children&&item.Children.length !== 0)?(<ul>{makeListTree(item.Children)}</ul>):''}
                </li>
            )
        } else {
            return null;
        }
    })
    const renderContentView = () => {
        return (
            <div>
                <SearchComponent  currentLang = {currentLang} featureAry = {featureAry} t = {t} changeShow={changeShow} searchInputEvent={searchInputEvent} leftContent={'常见问题'}/>
                <div className="content1" style={{'display':isShow?'block':'none'}}>
                    <div className={["container",isMenuLoaded?"":"contentLoading"].join(' ')}>
                        <div id="menu" isopen={menuOpen}>
                            {/* <div className="menuBar"></div> */}
                            <div className="menuContent">
                                <div className="menuContentTitlePC" style={{'display':'none'}}>{typeName}</div>
                                <div className="menuContentTitleMo paddingContainer" onClick={menuClick.bind(this)}><div>{typeName}</div></div>
                                <div className="menuContentContent paddingContainer">
                                    <ul>{menulis}</ul>
                                </div>
                            </div>
                        </div>
                        <div id="textContent">
                            <div style={{'fontSize':'16px'}} className={["textContainer",isArticleLoaded?"":"contentLoading"].join(' ')} dangerouslySetInnerHTML = {{__html:  articleContent}}>
                            </div>
                        </div>
                        <div  className="clearBoth"></div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <section id="helpcontent" className="productInfoContent">
            <MetaTags>
                <title>{metaTitle+ '-'+ t('metaTag.userManuals.title')} </title>
                <meta name="title" content={metaTitle+ '-'+ t('metaTag.userManuals.title')} />
                <meta name="description" content={metaDesc} />
                <meta name="keywords" content={t('metaTag.userManuals.keywords')} />
                <meta property="og:title" content={metaTitle+ '-'+ t('metaTag.userManuals.title')} />
                <meta property="og:description" content={metaDesc} />
                
               
            </MetaTags>
            <div className="contentBlock whiteBg">
                <div className="customContainer">
                </div>
                     {renderContentView()}
            </div>
        </section>
    )
}

export default mixinWrapper(SupportPage);