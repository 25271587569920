const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何寻找遗失的序列号？</title></head><body>  <h1>如何寻找遗失的序列号？</h1>  <h2><strong>1. 从文电通账号找回</strong></h2>
<p>如果您当初有<a href="https://store.rightpdf.cn/account/register.aspx" target="_self" rel="undefined">注册</a>文电通账号，并将授权码（序列号）<a href="https://support.rightpdf.com/hc/zh-cn/articles/201121713" target="_blank" rel="noopener">绑定</a>到您的账号中，则：</p>
<ol>
<li>
<span>进入「授权管理」页面<br></span><img src="https://store.rightpdf.com/images/rtsupport/340011.png" alt="340011.png" width="504" height="157"><span><br><br></span>
</li>
<li><span>在您授权后方的「操作」字段中点击「SN」，可显示您的授权码（序列号）<br><img src="https://store.rightpdf.com/images/rtsupport/340012.png" alt="340012.png"><br></span></li>
</ol>
<p> </p>
<p>如果您是通过文电通官网购买的授权，但没有将您的授权（序<span>列</span>号）绑定：</p>
<ol>
<li>
<a href="https://store.rightpdf.cn/account/login.aspx" target="_self" rel="undefined">登入</a>或<a href="https://store.rightpdf.cn/account/register.aspx" target="_self" rel="undefined">注册</a>文电通账号，进入「找回丟失的序<span>列</span>号」页面<br><img src="https://store.rightpdf.com/images/rtsupport/340013.png" alt="340013.png" width="338" height="188"><br><br>
</li>
<li>输入您购买时所留的Email，系统将会把您的授权码寄到您的Email信箱。</li>
</ol>
<p> </p>
<h2>
<strong>2. </strong><strong>从</strong><strong>Email</strong><strong>邮箱</strong><strong>中找回</strong>
</h2>
<p><span>请至当时您购买文电通或旧版DocuCom产品时所留下的邮箱，并搜寻主旨为「<strong>Thanks for buying from Zeon Online Store</strong>」的邮件，或是寄件人为以下任一的邮件：</span></p>
<p><span>文电通产品：<span style="color: #000066;">onlinestore@rightpdf.com/ </span>onlinestore@gaaiho.com</span><br><span>DocuCom产品：onlinestore@pdfwizard.com</span></p>
<p> </p>
<h2>
<strong>3. </strong><a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_blank" rel="noopener">与我们联系</a>
</h2>
<p><span>如果遺失了這封郵件，請提供當時購買所留下的資訊(如下)，並寄到我們的客服郵箱：support@rightpdf.com</span></p>
<p><span>姓名：</span><br><span>公司名称：</span><br><span>电子邮件地址：</span><br><span>电话号码：</span><br><span>地址：</span></p></body></html>`;   export default html;