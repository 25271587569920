const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我是公司IT部门负责人，我刚购买了10个文电通PDF套装版订阅版(Right PDF Subscription)授权，要如何开始部署？</title></head><body>  <h1>我是公司IT部门负责人，我刚购买了10个文电通PDF套装版订阅版(Right PDF Subscription)授权，要如何开始部署？</h1>  <ol>
<li>首先请为使用这些授权的用户注册新的<a href="https://store.rightpdf.cn/account/register.aspx" target="_blank" rel="noopener">Right PDF 账号</a>，因为每个用户需要属于自己的帐号登入以进行验证；<br><br>
</li>
<li>点击管理员的 「授权管理」<br><img src="https://store.rightpdf.com/images/rtsupport/410141.png" alt="410141.png" width="504" height="157"><br><br>
</li>
<li>在 「订阅版」&gt; 点选「授权管理」图标；<br><img src="https://store.rightpdf.com/images/rtsupport/410142.png" alt="410142.png"><br><br>
</li>
<li>从「授权分配管理」列表中，点击「新增用户」，加入用户账号和名称，点击「确定」。用户账号必须先在<a href="https://store.rightpdf.cn/account/register.aspx" target="_blank" rel="noopener">Right PDF 注册</a>）。<br><img src="https://store.rightpdf.com/images/rtsupport/410143.png" alt="410143.png" width="506" height="347">
</li>
</ol>
<p>  如何登入请参考以下FAQ： <a href="https://support.rightpdf.com/hc/zh-cn/articles/201037188" target="_blank" rel="noopener">每次打开程序都要弹出登录窗口，怎么办？</a></p></body></html>`;   export default html;