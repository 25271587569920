const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何在线/离线激活 文电通5 (Gaaiho PDF 5)？</title></head><body>  <h1>如何在线/离线激活 文电通5 (Gaaiho PDF 5)？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 100%; width: 100%;">
<tbody>
<tr>
<td>
<p><span class="wysiwyg-color-red "><strong>重点提示：</strong></span></p>
<ul>
<li><span class="">购买「永久型授权」的朋友，请选择「许可证代号」(许可证代号为32码)；</span></li>
<li><span class="">购买「订阅版授权」的朋友，请选择「账号激活」(于Gaaiho官网建立账号时的<strong>账号</strong>及<strong>密码</strong>)</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p> </p>
<h2><span><strong>在线激活：</strong></span></h2>
<div><span class="wysiwyg-color-blue130 ">1. 安装完成之后，执行<strong>Gaaiho Doc</strong>或<strong>Gaaiho PDF Driver</strong>会弹出关于激活对话框。或者</span></div>
<div> </div>
<div><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">套装版</span>:</strong></span></div>
<div><span class=" wysiwyg-color-blue130">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Gaaiho」 &gt; 「Gaaiho PDF Suite」 &gt; 「授权信息」并点击「激活」按钮。</span></div>
<div> </div>
<div><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">转换器</span>:</strong></span></div>
<div>
<div><span class="wysiwyg-color-blue130 ">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Gaaiho」 &gt; 「Gaaiho PDF Converter」 &gt; 「授权信息」并点击「激活」按钮。</span></div>
</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (8).png"></div>
<div> </div>
<div><span class=" wysiwyg-color-blue130"> </span></div>
<div><span class=" wysiwyg-color-blue130">2. 点击「<strong>许可证代号</strong>」</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip1 (4).png"></div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130">3. 选择「通过 Internet 网络」</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (6).png"></div>
<div> </div>
<div><span class="wysiwyg-color-blue130">4. 输入<span class=" wysiwyg-color-blue130"><strong>许可证代号</strong></span></span></div>
<div><span class="wysiwyg-color-blue130">5. 激活完成</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (3).png"></div>
<div> </div>
<h2><span class=""><strong>离线激活：</strong></span></h2>
<div>
<div><span class="wysiwyg-color-blue130 ">1. 安装完成之后，执行<strong>Gaaiho Doc</strong>或<strong>Gaaiho PDF Converter</strong>会弹出关于激活对话框。或者</span></div>
<div> </div>
<div><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">套装版</span>:</strong></span></div>
<div><span class=" wysiwyg-color-blue130">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Gaaiho」 &gt; 「Gaaiho PDF Suite」 &gt; 「授权信息」并点击「激活」按钮。</span></div>
<div> </div>
<div><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">转换器</span>:</strong></span></div>
<div>
<div><span class="wysiwyg-color-blue130 ">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Gaaiho」 &gt; 「Gaaiho PDF Converter」 &gt; 「授权信息」并点击「激活」按钮。</span></div>
</div>
</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (9).png"></div>
<div> </div>
<div> </div>
<div>2. <span class="wysiwyg-color-blue130">点击「<span class="wysiwyg-color-blue130"><strong>许可证代号</strong></span>」</span>
</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip1 (5).png"></div>
<div> </div>
<div> </div>
<div>3. <span class="wysiwyg-color-blue130">选择「<strong>离线</strong>」，输入<span class=" wysiwyg-color-blue130"><strong>许可证代号</strong></span>并生成「<strong>ActivationData.htm</strong>」</span>
</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip5 (1).png"></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip6 (1).png"></div>
<div> </div>
<div> </div>
<div><span class="wysiwyg-color-blue130">4. 用随身硬盘等存储设备将导出的文件移到在联网环境的电脑</span></div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130">5. 使用浏览器打开「Activati​​onData.htm」，并点击「上传」</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip7(1)" alt=""></div>
<div> </div>
<div> </div>
<div><span class="wysiwyg-color-blue130">6. 在「授权文件」上点击右键并保存</span></div>
<div><img src="https://support.rightpdf.com/hc/en-us/article_attachments/201145716/license_6_cncn.png" alt=""></div>
<div><img src="https://support.rightpdf.com/hc/en-us/article_attachments/201292163/license_7_cn_cn_.png" alt=""></div>
<div> </div>
<div> </div>
<div><span class="wysiwyg-color-blue130">7. 将.zna文件移回未联网的电脑</span></div>
<div><span class="wysiwyg-color-blue130"><span class="wysiwyg-color-red"><strong>注意</strong></span>：离线激活过程中，请勿中途结束文电通程序，以免导致激活失败。若中途关闭了程序，请重新执行离线激活程序，并汇入新的.zna文件。</span></div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130">8. 重复步骤1到2，选择「导入激活数据」，然后再选取刚才移动过来的.zna文件</span></div>
<div> <img src="https://store.rightpdf.com/images/rtsupport/mceclip6 (2).png">
<div> <img src="https://store.rightpdf.com/images/rtsupport/mceclip8 (1).png">
<div> </div>
</div>
<div> </div>
<div><span class="wysiwyg-color-blue130">9. 激活完成</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (4).png"></div></body></html>`;   export default html;