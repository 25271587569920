const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>离线激活时出现错误「0x20020215」？</title></head><body>  <h1>离线激活时出现错误「0x20020215」？</h1>  <h2><strong>问题描述：</strong></h2>
<p>离线激活时出现错误「0x20020215」，激活失败。</p>
<p> </p>
<h2><span><strong>原因：</strong></span></h2>
<p><span>这通常在用户尝试导入激活数据(.zna)时发生。 「0x20020215」代表程序无法将授权文件写入电脑。</span></p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方式：</strong></span></h2>
<ol>
<li><span class="wysiwyg-color-blue130">请卸载电脑上所有的文电通产品(<strong>如果产品未激活或无法正常反激活，请略过</strong>)</span></li>
<li><span class="wysiwyg-color-blue130">重启电脑</span></li>
<li><span class="wysiwyg-color-blue130">前往这个路径「<strong>%SystemDrive%\\Documents and Settings\\All Users\\Application Data\\</strong>」（在Windows 7或Vista上则是「<strong>%SystemDrive%\\ProgramData</strong>」），然后删除「<strong>Zeon</strong>」文件夹</span></li>
<li><span class="wysiwyg-color-blue130">安装原文电通产品</span></li>
<li><span class="wysiwyg-color-blue130">重新导出激活数据并上传，然后下载新的.zna文件并导入</span></li>
</ol></body></html>`;   export default html;