import React,{useState} from 'react';
import CompareTableBlock from '../../components/compare/compareTableBlock';
import MetaTags from 'react-meta-tags';

const ConverterCompare = props => {
    const {t} = props;
    const [selectList,setSelectList] = useState('');
    const pageDataObj = {
        info:{
            t1:t('resources.converterCompare.name'),
            t2:t('resources.converterCompare.info')
        },
        blockTitle:[
            {
                t1:t('GaaihoPDF_old'),
                t2:t('home.compare.table.th4')+' 4'
            },
            {
                t1:t('GaaihoPDF_old'),
                t2:t('home.compare.table.th2_converterCompare')+' 5'
            },
            {
                t1:t('GaaihoPDF'),
                t2:t('home.compare.table.th2_converterCompare')+' 6'
            }
        ],
        blockList:[
            {
                info:t('comparePage.createPdf.title1-1'),
                listAry:[
                    {
                        info:t('comparePage.createPdf.t1'),
                        td1:'○',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t4'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t4-1'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t5'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t6'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t7'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t8'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t4-2'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t4-3'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t3'),
                        td1:'○',td2:'○',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t11'),
                        td1:'●',td2:'●',td3:'●'
                    }
                ]
            },
            {
                info:t('comparePage.createPdf.title1-2'),
                listAry:[
                    {
                        info:t('comparePage.editPdf.t14'),
                        type:'newFunction',
                        td1:'─',td2:'─',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t15'),
                        type:'newFunction',
                        td1:'─',td2:'─',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t2'),
                        td1:'○',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t1-7'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.editPdf.t1-8'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t11'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.createPdf.t13'),
                        td1:'─',td2:'●',td3:'●'
                    }
                ]
            },
            {
                info:t('comparePage.protectPdf.title'),
                listAry:[
                    {
                        info:t('comparePage.protectPdf.t1'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.protectPdf.t2'),
                        td1:'●',td2:'●',td3:'●'
                    }
                ]
            },
            {
                info:t('comparePage.deploy.title'),
                listAry:[
                    {
                        info:t('comparePage.deploy.t1'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.deploy.t2'),
                        td1:'●',td2:'●',td3:'●'
                    },
                    {
                        info:t('comparePage.deploy.t4'),
                        td1:'●',td2:'●',td3:'●'
                    }
                ]
            }
        ]
    }
    const selectBlockShowHide = (info,active) => {
        pageDataObj.blockList.forEach((e)=>{
            if(info === e.info){
                if(active){
                    setSelectList('');
                }else{
                    setSelectList(e.info);
                }
            }
       })
   }

   const renderBlockList = array => {
       let returnli = null;
       returnli = array.map((e,i)=>{
           return (
            <CompareTableBlock {...props} dataObj={e} key={i} MenuSelect={selectBlockShowHide} selectList={selectList} active={e.info === selectList} />
           )
       })
       return returnli;
   }
    return (
        <div className="compareContent">
            <MetaTags>
                <title>{t('metaTag.converterCompare.title')}</title>
                <meta name="title" content={t('metaTag.converterCompare.title')} />
                <meta name="description" content={t('metaTag.converterCompare.desc')} />
                <meta name="keywords" content={t('metaTag.converterCompare.keywords')} />
                <meta property="og:title" content={t('metaTag.converterCompare.title')} />
                <meta property="og:description" content={t('metaTag.converterCompare.desc')} />
            </MetaTags>
            <div className="productCompareInfo productCompareInfoWrap">
                <h2 className="font-weight-bold">{pageDataObj.info.t1}</h2>
                <p>{pageDataObj.info.t2}</p>
            </div>
            <div className="compareTableBlock">
                <table className="compareTable title">
                    <thead>
                        <tr>
                            <th></th>
                            {
                                pageDataObj.blockTitle.map((e,i)=>{
                                    return (
                                        <th key={i}>
                                            <span>{e.t1}</span>
                                            <h2 className="font-weight-light">{e.t2}</h2>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                </table>
            </div>
            {
                renderBlockList(pageDataObj.blockList)
            }
            <div className="compareTableNote">
                <span className="d-inline-block mr-3">
                    ●：{t('comparePage.note.support')}
                </span>
                <span className="d-inline-block mr-3">
                    ○：{t('comparePage.note.supportApart')}
                </span>
                <span className="d-inline-block">
                    ─：{t('comparePage.note.unsupport')}
                </span>
            </div>
            <br/>
            <br/>
            <p className="compareNoteInfo">{t('comparePage.note.info')}</p>
        </div>
    )
}

export default ConverterCompare;