import React from 'react';
import {Link} from 'react-router-dom';
const SupportBlock = props => {
    const {t,linkObj,currentLang} = props;
    const returnPdfHome = () => {
        if(currentLang.type === 'zh-TW'){
            return (
                <li>
                    <a href="https://www.pdfhome.com/zh-TW/home" target="_blank" rel="noopener noreferrer">
                        {t(linkObj.support.header.block2.link1)}
                    </a>
                </li>
            )
        }else{
            return null
        }
    }
    return (
        <div className="supportBlock hideMenuContent mobileScroll">
                    <div className="d-inline-block supportBox">
                        <h2 className="hideMenuTitle">{t(linkObj.support.header.block1.name)}</h2>
                        <ul className="hideMenuList">
                            <li>
                                <a href={linkObj.support.faq.url()} target="_blank" rel="noopener noreferrer">
                                    {t(linkObj.support.header.block1.link1)}
                                </a>
                            </li>
                            <li>
                                <a href={linkObj.support.submitTicket.url()} target="_blank" rel="noopener noreferrer">
                                    {t(linkObj.support.header.block1.link2)}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="d-inline-block supportBox">
                        <h2 className="hideMenuTitle">{t(linkObj.support.header.block2.name)}</h2>
                        <ul className="hideMenuList">
                            {returnPdfHome()}
                            <li>
                                <Link to={`/${props.currentLang.type}${linkObj.tutorials.userManuals.path}`}>
                                    {t(linkObj.tutorials.userManuals.name)}
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${props.currentLang.type}${linkObj.tutorials.guide.path}`}>
                                    {t(linkObj.tutorials.guide.name)}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="d-inline-block supportBox">
                        <h2 className="hideMenuTitle">{t(linkObj.resources.name)}</h2>
                        <ul className="hideMenuList">
                            <li>
                                <Link to={`/${props.currentLang.type}${linkObj.resources.compare.path}`}>
                                    {t(linkObj.resources.compare.name)}
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${props.currentLang.type}${linkObj.resources.documents.path}`}>
                                    {currentLang.type === 'zh-TW' || currentLang.type === 'zh-CN'?t(linkObj.resources.documents.name).split('/')[0]:t(linkObj.resources.documents.name)}
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${props.currentLang.type}${linkObj.resources.sdk.path}`}>
                                    {t(linkObj.resources.sdk.name)}
                                </Link>
                            </li>
                            <li>
                                <a href={linkObj.resources.oldVersion.url()} target="_blank" rel="noopener noreferrer">
                                    {t(linkObj.resources.oldVersion.name)} 
                                </a>
                            </li>
                            <li>
                                <Link to={`/${props.currentLang.type}${linkObj.resources.release.path}`}>
                                    {t(linkObj.resources.release.name)} 
                                </Link>
                            </li>
                        </ul>
                    </div>
           
        </div>
    )
}

export default SupportBlock;