const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>DocuCom PDF停止销售</title></head><body>  <h1>DocuCom PDF停止销售</h1>  <div><span>亲爱的DocuCom顾客，</span></div>
<div><span> </span></div>
<div><span>我们很抱歉通知您，DocuCom PDF系列的所有产品已经停止销售。已购买的产品维护和授权管理服务不受影响。技术支持也会继续提供，不过很遗憾的是，我们将无法解决跟旧版代码相关的问题了。</span></div>
<div><span> </span></div>
<div>
<span>我们为您提供了方便的升级渠道，让您能快速升级到新一代的Right PDF系列产品。新的系列保持了原有的收费方式和授权方式，更增加和改进了多种功能，让您享受到更加优质的PDF体验。想了解更多，请参考<strong><a href="https://store.rightpdf.com/store/overview.aspx" target="_blank" rel="noopener">升级到最新Right PDF系列</a></strong></span>。</div>
<div> </div>
<div>若需进一步兼容信息，请查看下方的图表:</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/mceclip2.png" width="568" height="395"></div>
<div></div>
<div>
<strong>备注</strong>：在文电通「Final Updated」日期后发布的操作系统版本可能会有兼容性问题。</div>
<div></div>
<div> </div>
<div><span>感谢您多年以来对DocuCom系列的支持！希望您能升级到新的产品并收获更多。</span></div>
<div><span> </span></div>
<div><span>ZEON Corp.</span></div></body></html>`;   export default html;