const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为什么字体被错误地替代了?</title></head><body>  <h1>为什么字体被错误地替代了?</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>问题描述：</strong></h2>
<p>转换后的PDF文件，原始字体被错误的字体取代了。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>这是伴随Acrobat使用Type 1字体替代Truetype字出现的通常错误。例如「Arial斜体」看起来与「Type 1」版本很不同。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">您可以通过使用Right PDF/ Gaaiho PDF 转换器(Right PDF/ Gaaiho PDF Converter)将Truetype字体嵌入PDF文件来解决问题： </span><br><br><span class="wysiwyg-color-blue130">1. 在「Right PDF/ Gaaiho PDF Converter」的打印机属性中，转到「字体嵌入」卷标</span><br><span class="wysiwyg-color-blue130">2. 选中「<strong>嵌入所有字体</strong>」</span><br><span class="wysiwyg-color-blue130">3. 确定您的字体<strong>没有</strong>在「不嵌入的字体」框中列出</span><br><span class="wysiwyg-color-blue130">4. 点击"确定"按钮</span><br><br><span class="wysiwyg-color-blue130">从现在开始，您的字体将被嵌入并且Acrobat Reader将不会替代它们。您应当可以看到与Word打印相同的效果了。</span></p></body></html>`;   export default html;