const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我PDF文件的窗口右侧滚动条不见了？</title></head><body>  <h1>我PDF文件的窗口右侧滚动条不见了？</h1>  <h2><strong>问题描述：</strong></h2>
<p>当我开启一个PDF文件后，窗口右侧滚动条不见了？</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>在套装版的PDF编辑器中，作者可以客制化PDF文件开启的样式，也可以隐藏滚动条。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-red"><strong>注意</strong></span><span class="wysiwyg-color-blue130"><strong>：</strong>仅在文电通<strong>套装版</strong>中可以变更设定，免费版文电通阅读器不支持。</span></p>
<ol>
<li>
<span class="wysiwyg-color-blue130"><span class="wysiwyg-color-blue130">到「文件&gt;信息&gt;更多属性</span></span><span class="wysiwyg-color-blue130">」</span>
</li>
<li>
<span class="wysiwyg-color-blue130"><span class="wysiwyg-color-blue130">选择「初始视图</span></span><span class="wysiwyg-color-blue130">」，下方「隐藏」中，取消勾选「窗口控件」</span>
</li>
</ol>
<p><span class="wysiwyg-color-blue130">如此一来，开启此PDF文件时，窗口右侧的滚动条就又出现啰！</span></p>
<p> </p>
<h3><span class="wysiwyg-color-green120"><strong>延伸阅读</strong><strong>：</strong></span></h3>
<ul>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/900004748026" target="_blank" rel="noopener">PDF文件打开后的名称与文件名不同？</a></li>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/360027201531" target="_blank" rel="noopener">我的文电通套装版编辑器的功能列表不见了？</a></li>
</ul></body></html>`;   export default html;