const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何解决无法手动反激活文电通软件授权问题(企业授权管理)？</title></head><body>  <h1>如何解决无法手动反激活文电通软件授权问题(企业授权管理)？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p><br><br></p>
<h2><strong>问题描述：</strong></h2>
<p>计算机重灌、格式化，或报废，导致无法手动停用/反激活文电通软件授权(<span class="wysiwyg-color-red">目前这个功能只对10套以上的永久授权开放</span>)。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>有时候，文电通的永久授权版会带来一些令人困扰的问题，尤其是对于购买了许多套的客户来说。例如：用户在计算机重新安装或更换硬盘之前忘了反激活软件，或是因计算机突发故障而无法反激活。这种情况会让客户的授权永远卡在该台计算机上，传统上只能通过文电通售后客服清除所有激活记录的手段进行解决。</p>
<p> </p>
<h2><strong><span class="wysiwyg-color-blue130">解决办法</span></strong></h2>
<p><span class="wysiwyg-color-blue130">为了让客户能自行排除这样的问题，文电通团队特别开发了一个网站功能，只要通过简单的操作即可远程反激活故障计算机上的授权。</span><span class="wysiwyg-color-red">目前这个功能只对10套以上的永久授权开放。</span></p>
<h3>重要前提</h3>
<ul>
<li>需要<a href="https://support.rightpdf.com/hc/zh-cn/articles/201121713" target="_self" rel="undefined">绑定 Right PDF 账号和授权码（序列号）</a>，才能使用这项功能哦。</li>
<li>掌握目标设备名称(Win 10: 设置 → 系统 → 关于 → 设备规格)、IP、激活时间等信息，才能快速找出该计算机。<br><img src="https://store.rightpdf.com/images/rtsupport/240041.png" alt="240041.png">
</li>
</ul>
<h2><strong><span class="wysiwyg-color-purple130">步骤</span></strong></h2>
<ol>
<li><span class="wysiwyg-color-purple130">登录<a href="https://store.rightpdf.cn/account/login.aspx" target="_self" rel="undefined">Right PDF 在线商店</a>并选择「授权管理」</span></li>
<li><span class="wysiwyg-color-purple130">在永久授权列表下面找到想要管理的授权，点击右侧的授权管理按钮。<br><img src="https://store.rightpdf.com/images/rtsupport/240042.png" alt="240042.png"><br></span></li>
<li><span class="wysiwyg-color-purple130">在授权管理界面中，按照计算机名称、IP、激活时间三项信息，找到目标计算机并点击「反激活」按钮，并在随后的提示窗口中点击「确定」。<br><img src="https://store.rightpdf.com/images/rtsupport/240043.png" alt="240043.png"><br></span></li>
<li><span class="wysiwyg-color-purple130">反激活成功后，刷新页面将会移除目标计算机，如此一来就释放了一份授权。</span></li>
</ol>
<p>若有其他问题，欢迎利用客服中心<a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_self" rel="undefined">联系文电通客服团队</a>喔！</p></body></html>`;   export default html;