import React, {useEffect} from 'react';
import youtube_thumb from '../assets/img/newFeature/youtube_thumb.png';
import gaaiho_suite from '../assets/img/right_suite.png';
import gaaiho_driver from '../assets/img/right_driver.png';
import remoteIcon from '../assets/img/newFeature/ico_remote.png';
import upgradeIcon from '../assets/img/newFeature/ico_upgrade.png';
import mixinWrapper from '../hoc/mixinWrapper';
import { Link, useParams } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import MetaTags from 'react-meta-tags';

const NewFeature = props => {
    const { lang } = useParams();
    const {t,currentLang,linkObj, i18n} = props;
    const renderTitle = () => {
        let textarray = t('newfeature.top.banner1').split('_');
        let content = null;
        if(currentLang.type === 'en'){
            content = <>{textarray[0]}<span className='orangeText'>{textarray[1]}</span></>
        }else{
            content = <>{textarray[0]}<span className='orangeText'>{textarray[1]}</span>{textarray[2]}</>
        }
        return (
            
            <h1 className="firstTitle">
                {content}
            </h1>
        )
    }
    const renderImg = (imgType,imgNum) => {
        let imglength = 0;
        let returnElement = null;
        if(imgType === 'blk'){
            imglength = 11;
        }else{
            imglength = 8;
        }
        for(let i=0;i<imglength;i++){
            if((i+1) === imgNum){
                if( (imgNum === 2 || imgNum === 3 || imgNum === 6 || imgNum === 7 || imgNum === 11) &&  imgType === 'blk'){
                    returnElement = <img src={require(`../assets/img/newFeature/${imgType}/${imgType}${imgNum}_${currentLang.type}.png`)} className="img-fluid" alt={`${imgType}${imgNum}`} />
                }else{
                    returnElement = <img src={require(`../assets/img/newFeature/${imgType}/${imgType}${imgNum}.png`)} className="img-fluid" alt={`${imgType}${imgNum}`} />
                }
            }
        }
        return returnElement;
    }
    const dispatch = useDispatch();
    const  openVideoPopup = () => {
        dispatch({
            type:'OPEN_POPUP',
            content:'video'
        })
    }
    const renderFeatureList = () => {
        let returnElement = [];
        for(let i=0;i<8;i++){
            returnElement.push(<li key={i}>
                                {renderImg('detail',i+1)}
                                <div className="featureListBlock">
                                    <h3>{t('newfeature.explored.block'+(i+1)+'.title')}</h3>
                                    <p>{t('newfeature.explored.block'+(i+1)+'.info')}</p>
                                </div>
                            </li>)
        }
        return returnElement;
    }
    const titleAddBold = (typeNum) => {
        let title = t('newfeature.buy.block'+typeNum+'.title');
        if(currentLang.type === 'zh-TW' || currentLang.type === 'zh-CN'){
            return <h3 className="sm-title font-light"> {title.split('_')[0]} <b style={{fontWeight:'bold'}}>{title.split('_')[1]}</b></h3>
        }else{
            return <h3 className="sm-title font-light">{title}</h3>
        }
    }

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    
    return (
        <section id="newfeature">
            <MetaTags>
                <title>{t('metaTag.newFeature.title')}</title>
                <meta name="title" content={t('metaTag.newFeature.title')} />
                <meta name="description" content={t('metaTag.newFeature.desc')} />
                <meta name="keywords" content={t('metaTag.newFeature.keywords')} />
                <meta property="og:title" content={t('metaTag.newFeature.title')} />
                <meta property="og:description" content={t('metaTag.newFeature.desc')} />
            </MetaTags>
            <div className="topBlock">
                <div className="topBlockContent">
                    {renderTitle()}
                    <div className="btnGroup">
                        <a href={linkObj.header.upgrade.url()} className="cBtn w_text greenBg" target="_blank" rel="noopener noreferrer">
                            {t(linkObj.header.upgrade.name)}
                        </a>
                        <a href={linkObj.header.buy.url()} className="cBtn w_text redBg-fire" target="_blank" rel="noopener noreferrer">
                            {t('newfeature.top.buyNow')}
                        </a>
                    </div>
                    <a href={linkObj.downloadLink.suite.url()} target="_blank" className="downloadSuite text-white" rel="noopener noreferrer">
                        {t('newfeature.top.downloadSuite')}
                    </a>
                </div>
            </div>
            <div className="contentBlock greenBg-gradient w_color p90">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="titleBlock d-100">
                            <h2 className="sb-title mb-5">{t('newfeature.video.title')}</h2>
                            <p className="mb-0">{t('newfeature.video.info1')}</p>
                            <p>{t('newfeature.video.info2')}</p>
                        </div>
                        <div className="videoPreview d-100">
                            <img src={youtube_thumb} className="img-fluid"  alt="youtube_thumb" onClick={()=>{openVideoPopup()}}/>
                            <p className="info font-light m-title yellowText">{t('newfeature.video.info3')}</p>
                        </div>
                    </div>
                    <div className="cRow maxW1300 alignStart">
                        <div className="featureBlock height544 d-50 borderLine top  green dash">
                            {renderImg('blk',1)} 
                            <h3 className="yellowText font-light sm-title">{t('newfeature.video.block1.title')}</h3>
                            <p className="max340 font-light">{t('newfeature.video.block1.info')}</p>
                        </div>
                        <div className="featureBlock height544 d-50 borderLine top left mobileHide green dash">
                            {renderImg('blk',2)} 
                            <h3 className="yellowText font-light sm-title">{t('newfeature.video.block2.title')}</h3>
                            <p className="max340 font-light">{t('newfeature.video.block2.info')}</p>
                        </div>
                        <div className="featureBlock height544 d-100 borderLine top green dash">
                            {renderImg('blk',3)} 
                            <h2 style={{color:'#FFFD60'}} className="mm-title font-light">{t('newfeature.video.block3.title')}</h2>
                            <p className="font-light">{t('newfeature.video.block3.info')}</p>
                        </div>
                        <div className="featureBlock height449 d-50 borderLine top right mobileHide green dash">
                            {renderImg('blk',4)} 
                            <h3 className="yellowText font-light sm-title">{t('newfeature.video.block4.title')}</h3>
                            <p className="max340 font-light">{t('newfeature.video.block4.info')}</p>
                        </div>
                        <div className="featureBlock height449 d-50 borderLine top  green dash">
                            {renderImg('blk',5)} 
                            <h3 className="yellowText font-light sm-title">{t('newfeature.video.block5.title')}</h3>
                            <p className="max340 font-light">{t('newfeature.video.block5.info1')}</p>
                            <p className="max340 font-light m-0">{t('newfeature.video.block5.info2')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock whiteBg p90">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="titleBlock d-100">
                            <h2 className="sb-title mb-5">{t('newfeature.reader.title')}</h2>
                        </div>
                        <div className="featureBlock height449 d-50 borderLine right mobileHide dash">
                            {renderImg('blk',6)} 
                            <h3 className="font-light sm-title">{t('newfeature.reader.block1.title')}</h3>
                            <p className="font-light">{t('newfeature.reader.block1.info')}</p>
                        </div>
                        <div className="featureBlock height449 d-50 borderLine top mobileShow dash">
                            {renderImg('blk',7)} 
                            <h3 className="font-light sm-title">{t('newfeature.reader.block2.title')}</h3>
                            <p className="font-light">{t('newfeature.reader.block2.info')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock orangeBg-gradient w_color p90">
                <div className="customContainer">
                    <div className="cRow maxW1300 alignEnd">
                        <div className="titleBlock d-100">
                            <h2 className="sb-title mb-5">{t('newfeature.converter.title')}</h2>
                        </div>
                        <div className="featureBlock d-33 borderLine bottom right mobileHide green dash">
                            {renderImg('blk',8)} 
                            <h3 className="yellowText font-light sm-title">{t('newfeature.converter.block1.title')}</h3>
                            <p className="font-light max300">{t('newfeature.converter.block1.info')}</p>
                        </div>
                        <div className="featureBlock d-33 borderLine bottom green dash">
                            {renderImg('blk',9)} 
                            <h3 className="yellowText font-light sm-title">{t('newfeature.converter.block2.title')}</h3>
                            <p className="font-light max300">{t('newfeature.converter.block2.info')}</p>
                        </div>
                        <div className="featureBlock d-33 borderLine bottom left mobileHide green dash">
                            {renderImg('blk',10)} 
                            <h3 className="yellowText font-light sm-title">{t('newfeature.converter.block3.title')}</h3>
                            <p className="font-light max300">{t('newfeature.converter.block3.info')}</p>
                        </div>
                        <div className="featureBlock d-100 height449">
                            {renderImg('blk',11)}
                            <h2 style={{color:'#FFFD60',marginTop:'30px'}} className="mm-title font-light">{t('newfeature.converter.block4.title')}</h2>
                            <p className="font-light">{t('newfeature.converter.block4.info')}</p> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock whiteBg splendidBg p90">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="titleBlock d-100">
                            <h2 className="sb-title mb-5">{t('newfeature.explored.title')}</h2>
                        </div>
                        <div className="d-100">
                            <ul className="featureList">
                                {renderFeatureList()}
                            </ul>
                            <p className="featureLinkBtn">{t('newfeature.explored.conclusion')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock tradeWind w_color p90">
                <div className="customContainer">
                    <div className="cRow maxW1300 justifyCenter">
                        <div className="titleBlock d-100">
                            <h2 className="sb-title mb-5">{t('newfeature.buy.title')}</h2>
                        </div>
                        <div className="d-45 whiteBg roundBlock">
                            <img src={gaaiho_suite} className="img-fluid" alt="gaaiho_suite"/>
                            {titleAddBold(1)}
                            <p>{t('newfeature.buy.block1.info')}</p>
                            <Link to={`/${props.currentLang.type}${linkObj.product.suite.path}`} style={{display:'block',margin:'25px auto 40px',textDecoration:'underline',color:'#1B8744'}}>
                                {t('learnMore')}
                            </Link>
                            <div className="btn-group-vertical">
                                <a href={linkObj.buyLink.url()} className="cBtn w_text redBg-light" target="_blank" rel="noopener noreferrer">{t('newfeature.top.buyNow')}</a>
                                <a href={linkObj.downloadLink.suite.url()} className="cBtn w_text greenBg" target="_blank" rel="noopener noreferrer">{t('newfeature.top.tryFree')}</a>
                            </div>
                        </div>
                        <div className="d-45 whiteBg roundBlock">
                            <img src={gaaiho_driver} className="img-fluid" alt="gaaiho_driver"/>
                            {titleAddBold(2)}
                            <p>{t('newfeature.buy.block2.info')}</p>
                            <Link to={`/${props.currentLang.type}${linkObj.product.converter.path}`} style={{display:'block',margin:'25px auto 40px',textDecoration:'underline',color:'#1B8744'}}>
                                {t('learnMore')}
                            </Link>
                            <div className="btn-group-vertical">
                                <a href={linkObj.buyLink.url()} className="cBtn w_text redBg-light" target="_blank" rel="noopener noreferrer">{t('newfeature.top.buyNow')}</a>
                                <a href={linkObj.downloadLink.converter.url()} className="cBtn w_text greenBg" target="_blank" rel="noopener noreferrer">{t('newfeature.top.tryFree')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock whiteBg p90">
                    <div className="customContainer">
                        <div className="cRow maxW1300">
                            <ul className="upgradeList">
                                <li>
                                    <img src={upgradeIcon} className="img-fluid" alt="remoteIcon"/>
                                    <div className="upgradeInfo">
                                        <h2>{t('newfeature.upgrade.block1.title')}</h2>
                                        <p>{t('newfeature.upgrade.block1.info')}</p>
                                    </div>
                                    <a href={linkObj.header.upgrade.url()} target="_blank" className="cBtn b_border" rel="noopener noreferrer">
                                        {t('header.upgrade')}
                                    </a>
                                </li>
                                <li>
                                    <img src={remoteIcon} className="img-fluid" alt="upgradeIcon"/>
                                    <div className="upgradeInfo">
                                        <h2>{t('newfeature.upgrade.block2.title')}</h2>
                                        <p>{t('newfeature.upgrade.block2.info')}</p>
                                    </div>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`} className="cBtn b_border">
                                        {t('learnMore')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
            </div>
            <div className="contentBlock redBg-fire w_color p90">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                            <h2 className="sbm-title mb-5">{t('newfeature.contact')}</h2>
                            <a href={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`}  className="cBtn w_text w_border">
                                {t('contact.link1')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default mixinWrapper(NewFeature);