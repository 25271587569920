import React, {useEffect,useState}  from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { Link, useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

// import illu01 from '../assets/img/home/illu_01.png';
// import illu02 from '../assets/img/home/illu_02.png';
import blockimg1 from '../assets/img/home/v6new_ui.png';
import blockimg2 from '../assets/img/home/v6new_shield.png';
import blockimg3 from '../assets/img/home/v6new_file_formats.png';

import advantag1 from '../assets/img/home/advantage_1.png';
import advantag2 from '../assets/img/home/advantage_2.png';
import advantag3 from '../assets/img/home/advantage_3.png';
import advantag4 from '../assets/img/home/advantage_4.png';
import advantag5 from '../assets/img/home/advantage_5.png';
import advantag6 from '../assets/img/home/advantage_6.png';
import videomp4 from '../assets/video/rightpdf_540.mp4';

const Home = props => {
    const { lang } = useParams();
    const {t,currentLang,linkObj, i18n} = props;
    const [b3Index,setB3Index] = useState(0);
    const [b5Limit,setB5Limit] = useState(3);

    const [b5CurrentPage,setB5CurrentPage] = useState(1);
    const [b5TotalPage,setB5TotalPage] = useState([]);
    const [b5List,setB5List] = useState([]);
    const [block3ImgName,setBlock3ImgName] = useState('ui_01_edit_pdf_tw.jpg');
    const renderClientList = () => {
        let renderlist = [];
        let clientNum = 4;
        let path = 'en';
        if(currentLang.type === 'zh-TW'){
            clientNum = 17;
            path = 'zh-TW';
        }else if(currentLang.type === 'zh-CN'){
            clientNum = 6;
            path = 'zh-CN';
        }else if(currentLang.type === 'kr'){
            clientNum = 8;
            path = 'kr';
        }
        for(let i=0;i<clientNum;i++){
            renderlist.push(<li key={i}><img className="img-fluid" src={require(`../assets/img/home/${path}/client_logo_${(i+1)}.jpg`)} alt=""/></li>);
        }

        return renderlist;
    }
    // const dispatch = useDispatch();
    // const  openDownloadPopup = () => {
    //     dispatch({
    //         type:'OPEN_POPUP',
    //         content:'download'
    //     })
    // }
    const getLangeTypeBgByLang = (langType) => {
        let type = '';
        switch(langType){
            case 'zh-TW':
                type = 'tw';
                break;
            case 'zh-CN':
                type = 'cn';
                break;
            case 'en':
                type = 'en';
                break;
            case 'jp':
                type = 'jp';
                break;
            case 'kr':
                type = 'kr';
                break;
            default:
                type = 'tw';      
        }
        return type;
    }
    const block3Click = (index) => {
        if(b3Index === index){
            return;
        }
        setB3Index(index);
    }
    const block5Click = (type) => {
        if(type === 1){//left
            let currentPage = (b5CurrentPage - 1)<1?b5TotalPage.length:(b5CurrentPage - 1);
            setB5CurrentPage(currentPage);
        }
        if(type === 2){//right
            let currentPage = (b5CurrentPage + 1)>b5TotalPage.length?1: (b5CurrentPage + 1);
            setB5CurrentPage(currentPage);
        }
    }
    const block5ClickPoint = (page)=>{
        setB5CurrentPage(page);
    }
	// const qqClick = (e) => {
	// 	if(document.getElementById("qq").className.indexOf("toggled") === -1){
	// 		document.getElementById("qq").classList.add("toggled");
	// 	}
	// }
    const list1 =[
        {
            imgClass:'img1',
            title:t('home.home.block2.text1'),
            desc:t('home.home.block2.desc1')
        },
        {
            imgClass:'img2',
            title:t('home.home.block2.text2'),
            desc:t('home.home.block2.desc2')
        },
        {
            imgClass:'img3',
            title:t('home.home.block2.text3'),
            desc:t('home.home.block2.desc3')
        },
        {
            imgClass:'img4',
            title:'OCR',
            desc:t('home.home.block2.desc4')
        }
    ]
    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    useEffect(() => {
        const getLangeTypeBgByLang = (langType) => {
            let type = '';
            switch(langType){
                case 'zh-TW':
                    type = 'tw';
                    break;
                case 'zh-CN':
                    type = 'cn';
                    break;
                case 'en':
                    type = 'en';
                    break;
                case 'jp':
                    type = 'jp';
                    break;
                case 'kr':
                    type = 'kr';
                    break;
                default:
                    type = 'tw';      
            }
            return type;
        }
        let name = 'ui_';
        switch(b3Index){
            case 0:
                name += '01_edit_pdf_';
                break;
            case 1:
                name += '02_convert_';
                break;
            case 2:
                name += '03_add_notes_';
                break;
            case 3:
                name += '04_ocr_';
                break;
            default:
                name += '01_edit_';
        }
        let type = getLangeTypeBgByLang(lang);
        name = name+type+'.jpg';
        setBlock3ImgName(name);
    }, [lang,b3Index]);
    useEffect(() => {
        const list2 = [
            {
                img:require(`../assets/img/home/Group 1184.png`),
                name:t('home.home.block5.name1'),
                desc:t('home.home.block5.desc1')
            },
            {
                img:require(`../assets/img/home/Group 1223.png`),
                name:t('home.home.block5.name2'),
                desc:t('home.home.block5.desc2')
            },
            {
                img:require(`../assets/img/home/Group 1226.png`),
                name:t('home.home.block5.name3'),
                desc:t('home.home.block5.desc3')
            },
            {
                img:require(`../assets/img/home/Group 1184.png`),
                name:t('home.home.block5.name4'),
                desc:t('home.home.block5.desc4')
            },
            {
                img:require(`../assets/img/home/Group 1223.png`),
                name:t('home.home.block5.name5'),
                desc:t('home.home.block5.desc5')
            },
            {
                img:require(`../assets/img/home/Group 1226.png`),
                name:t('home.home.block5.name6'),
                desc:t('home.home.block5.desc6')
            },
            {
                img:require(`../assets/img/home/Group 1184.png`),
                name:t('home.home.block5.name7'),
                desc:t('home.home.block5.desc7')
            },
            {
                img:require(`../assets/img/home/Group 1223.png`),
                name:t('home.home.block5.name8'),
                desc:t('home.home.block5.desc8')
            },
            {
                img:require(`../assets/img/home/Group 1226.png`),
                name:t('home.home.block5.name9'),
                desc:t('home.home.block5.desc9')
            },
            {
                img:require(`../assets/img/home/Group 1184.png`),
                name:t('home.home.block5.name10'),
                desc:t('home.home.block5.desc10')
            },
            {
                img:require(`../assets/img/home/Group 1223.png`),
                name:t('home.home.block5.name11'),
                desc:t('home.home.block5.desc11')
            },
            {
                img:require(`../assets/img/home/Group 1226.png`),
                name:t('home.home.block5.name12'),
                desc:t('home.home.block5.desc12')
            }
        ]
        let arr = [];
        let totalPage = Math.ceil(list2.length/b5Limit);
        for(let i = 0;i<totalPage;i++){
            arr.push(1);
        }
        setB5TotalPage(arr);
        let data = list2.filter((item,index)=>{
			return index>=b5Limit*(b5CurrentPage-1)&&index<b5Limit*b5CurrentPage;
		})
        setB5List(data);
    }, [b5CurrentPage,b5Limit,t]);
    useEffect(() => {
        const changeLimitByWidth = () => {
            let winWidth = window.innerWidth;
            if(winWidth>=1500){
                setB5CurrentPage(1);
                setB5Limit(3);
            }else if(winWidth<1500&&winWidth>=1200){
                setB5CurrentPage(1);
                setB5Limit(2);
            }else if(winWidth<1499){
                setB5CurrentPage(1);
                setB5Limit(1);
            }
        }
        changeLimitByWidth();
        window.addEventListener('resize',function(){
            changeLimitByWidth();
        });
    }, []);
    const videoPlay = () => {
		document.getElementsByClassName("videoWrapper")[0].style.display = "block";
		var src = "https://www.youtube.com/embed/CRdDB_8kRr8?autoplay=1";
		if(currentLang.type === 'zh-CN'){
			src = "https://player.bilibili.com/player.html?aid=854328161&bvid=BV1C54y1d7rE&cid=727637468&page=1";
		}
		var video = document.getElementById("video-area");
		video.innerHTML = '<div style="max-width:800px;min-width:500px;"><iframe src="'+ src +'" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" style="width:80%;height:80%;z-index:9999;position:absolute;left:10%;top:10%;"> </iframe></div>';
    }
	const videoClose = (e) => {
		var event = e || window.event;
		if(event.target.attributes.id.value === "shadow" || event.target.attributes.id.value === "video-shut"){
			document.getElementsByClassName("videoWrapper")[0].style.display = "none";
		}
	}
    const ChristmasActivity = () => {
		let time = new Date();
		let year = time.getFullYear();
		let month = time.getMonth() + 1;
		let date = time.getDate();
		let activity = false;
		if(year === 2023){
			if(month === 12){
				if(date > 17){
					activity = true;
				}
			}
		}
		if(year === 2024){
			if(month === 1){
				if(date < 23){
					activity = true;
				}
			}
		}
		if(activity){
			return <div className={["homeBlock","block0","whiteBg",getLangeTypeBgByLang(lang)+'_bg',"christmas"].join(' ')}>
						<div className="block_btnGroup d-100">
							<a href={props.linkObj.downloadLink.christmas.details.url()} className="block_btn" target="_blank" rel="noopener noreferrer">
								{t('header.download')}
							</a>
						</div>
						<div className="block_btnGroup block_btnGroup1 d-100">
							<a href={props.linkObj.downloadLink.christmas.pro.url()} className="block_btn" target="_blank" rel="noopener noreferrer">
								{t('header.download')}
							</a>
							<a href={props.linkObj.downloadLink.christmas.mac.url()} className="block_btn" target="_blank" rel="noopener noreferrer">
								{t('header.download')}
							</a>
						</div>
						<div className="block_btnGroup block_btnGroup2 d-100">
							<a href={props.linkObj.downloadLink.christmas.buyLink_christmas.url("&pcode=Warm12W")} className="block_btn" target="_blank" rel="noopener noreferrer">
								{t('header.buy')}
							</a>
							<a href={props.linkObj.downloadLink.christmas.buyLink_christmas.url("&pcode=Warm12M")} className="block_btn" target="_blank" rel="noopener noreferrer">
								{t('header.buy')}
							</a>
						</div>
					</div>
		}else{
			return	<div className={["homeBlock","block0","whiteBg",getLangeTypeBgByLang(lang)+'_bg'].join(' ')}>
						<div className="block_btnGroup d-100">
							<a href={props.linkObj.downloadLink.suite.url()} className="block_btn" target="_blank" rel="noopener noreferrer">
								{t('header.download')}
							</a>
							<a href={props.linkObj.buyLink.url()} className="block_btn" target="_blank" rel="noopener noreferrer">
								{t('header.buy')}
							</a>
						</div>
					</div>
		}
	}
    return (
        <section id="Home">
            <MetaTags>
                <title>{t('metaTag.home.title')}</title>
                <meta name="title" content={t('metaTag.home.title')} />
                <meta name="description" content={t('metaTag.home.desc')} />
                <meta name="keywords" content={t('metaTag.home.keywords')} />
                <meta property="og:title" content={t('metaTag.home.title')} />
                <meta property="og:description" content={t('metaTag.home.desc')} />
            </MetaTags>
            {/* <div className="topBlock">
                <div>
                    <h1 className="firstTitle text-white">{t('home.top.banner1')}</h1>
                    <h2 className="secondTitle">{t('home.top.banner2')}</h2>
                </div>
                <div className="btnGroup">
                    <a href={props.linkObj.downloadLink.suite.url()} className="cBtn w_text greenBg" target="_blank" rel="noopener noreferrer">
                        {t('tryFree')}
                    </a>
                    <a href={props.linkObj.buyLink.url()} className="cBtn w_text redBg" target="_blank" rel="noopener noreferrer">
                        {t('buyNow')}
                    </a>
                </div>
            </div> */}
			{ChristmasActivity()}
            <div className="contentBlock homeBlock block1 greenBg">
                <div className='customContainer'>
                    <div className='cRow maxW1300'>
                        <div className='block1_title1 d-100 title_style' ><span className='block_logo mr17'></span><div className='block_inline' dangerouslySetInnerHTML={{__html:t('home.home.block0.title')}}></div></div>
                        <div className='d-100'>
                            <div className='videoBlock'>
                                <div className='videoTitle'>
                                    {t('home.home.block0.text4')}
								</div>
                                <div>
                                    <video muted="muted" width="100%" height="auto" id="media" autoPlay="autoPlay" loop="loop">
                                        <source src={videomp4} type="video/mp4" /> 
                                    </video>
                                </div>
                                <div className="video_btnGroup">
                                    <div className="video_btn block_btn" onClick={()=>{videoPlay()}}>
                                    {t('home.home.block0.btn1')}
									<span></span>
                                    </div>
                                </div>
                            </div>
							<div className="videoWrapper">
								<div id="shadow" onClick={()=>{videoClose()}}></div>
								<div id="video-btn">
									<div id="video-area"></div>
									<div id="video-shut" onClick={()=>{videoClose()}}></div>
								</div>
							</div>
                        </div>
                        <div className='d-100'>
                            <div className='block1_title title_style'>{t('home.home.block0.text1')}</div>
                            <div className='block1_img'>
                                <img src={blockimg1} className="img-fluid" alt="illu_02"/>
                            </div>
                        </div>
                        <div className='block_flex d-100'>
                            <div>
                                <div className='block1_title title_style'>{t('home.home.block0.text2')}</div>
                                <div className='block1_img'>
                                    <img src={blockimg2} className="img-fluid" alt="illu_02"/>
                                </div>
                            </div>
                            <div>
                                <div className='block1_title title_style'>{t('home.home.block0.text3')}</div>
                                <div className='block1_img'>
                                <img src={blockimg3} className="img-fluid" alt="illu_02"/>
                                </div>
                            </div>
                        </div>
                        <div className="block_btnGroup d-100">
                            <a href={'/'+lang+props.linkObj.resources.release.path} className="block_btn" target="_blank" rel="noopener noreferrer">
                            {t('home.home.block0.btn')}
                            </a>
                        </div>
                    </div>    
                </div>
            </div>
            <div className="contentBlock homeBlock block2 whiteBg">
                <div className='customContainer macContainer'>
                    <div className='cRow maxW1300'>
                        <div className='d-100 title_style'>
                            <h1 className="block2_title">{t('home.home.block1.title').split('_')[0]}<span className="macColor">{t('home.home.block1.title').split('_')[1]}</span>{t('home.home.block1.title').split('_')[2]}</h1>
                            <h2 className="block2_title1">{t('home.home.block1.title1')}</h2>
                        </div>
                        <div className="block_btnGroup d-100">
                            <a href={'/'+lang+props.linkObj.product.macOS.path} className="block_btn" target="_blank" rel="noopener noreferrer">
                            {t('home.home.block1.btn')}
                            </a>
                        </div>
                    </div>    
                </div>    
            </div>
            <div className="contentBlock homeBlock block3 grayBg">
                <div className='customContainer'>
                    <div className='cRow maxW1400'>
                        <div className="block3_top d-100 title_style">
                            <h1 className="block3_title">{t('home.home.block2.title')}</h1>
                            <h2 className="block3_title1">{t('home.home.block2.title1')}</h2>
                        </div>
                        <div className="block_flex d-100">
                            <div className='block3_point'>
                                <ul>
                                    {
                                        list1.map((item,index)=>{
                                            return <li key={index} className={b3Index===index?'active':''} onClick={block3Click.bind(this,index)}></li>
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="block3_left">
                                <ul>
                                    {
                                        list1.map((item,index)=>{
                                            return (
                                                <li key={index} className={b3Index===index?'active':''} onClick={block3Click.bind(this,index)}>
                                                    <div className={['block3_content',item.imgClass].join(' ')}>
                                                        <div className="title_style">{item.title}</div>
                                                        <div>{item.desc}</div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='block3_right'>
                                <div className='block3_content'>
                                    <img src={require(`../assets/img/home/${block3ImgName}`)} className="img-fluid" alt="illu_02" style={{'width':'100%'}}/>
                                </div>
                            </div>
                        </div>
                        <div className='block3_tips d-100'>{t('home.home.block2.tips')}</div>
                    </div>    
                </div>
            </div>
            <div className="contentBlock homeBlock block4 whiteBg">
                <div className='customContainer'>
                    <div className='cRow maxW1400'>
                        <div className='d-100 title_style'>
                            <h1 className="block4_title">{t('home.home.block3.title')}</h1>
                        </div>
                        <ul className='block_flex d-100'>
                            <li>
                                <div className="block4_img">
                                    <img src={advantag1} className="img-fluid" alt="illu_02"/>
                                </div>
                                <div className="block4_content">
                                    <div className="title_style">{t('home.home.block3.text1')}</div>
                                    <div>{t('home.home.block3.desc1')}</div>
                                </div>
                            </li>
                            <li>
                                <div className="block4_img">
                                    <img src={advantag2} className="img-fluid" alt="illu_02"/>
                                </div>
                                <div className="block4_content">
                                    <div className="title_style">{t('home.home.block3.text2')}</div>
                                    <div>{t('home.home.block3.desc2')}</div>
                                </div>
                            </li>
                            <li>
                                <div className="block4_img">
                                    <img src={advantag3} className="img-fluid" alt="illu_02"/>
                                </div>
                                <div className="block4_content">
                                    <div className="title_style">{t('home.home.block3.text3')}</div>
                                    <div>{t('home.home.block3.desc3')}</div>
                                </div>
                            </li>
                            <li>
                                <div className="block4_img">
                                    <img src={advantag4} className="img-fluid" alt="illu_02"/>
                                </div>
                                <div className="block4_content">
                                    <div className="title_style">{t('home.home.block3.text4')}</div>
                                    <div>{t('home.home.block3.desc4')}</div>
                                </div>
                            </li>
                            <li>
                                <div className="block4_img">
                                    <img src={advantag5} className="img-fluid" alt="illu_02"/>
                                </div>
                                <div className="block4_content">
                                    <div className="title_style">{t('home.home.block3.text5')}</div>
                                    <div>{t('home.home.block3.desc5')}</div>
                                </div>
                            </li>
                            <li>
                                <div className="block4_img">
                                    <img src={advantag6} className="img-fluid" alt="illu_02"/>
                                </div>
                                <div className="block4_content">
                                    <div className="title_style">{t('home.home.block3.text6')}</div>
                                    <div>{t('home.home.block3.desc6')}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="contentBlock homeBlock block5 whiteBg">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100 title_style">
                            <h1 className="block5_title">{t('home.customers.title')}</h1>
                            <h2 className="block5_title1">{t('home.customers.description')}</h2>
                            <ul className="customerList">
                                {renderClientList()}
                            </ul>
                            <Link to={`/${props.currentLang.type}${linkObj.AboutZeon.linkAry[3].path}`} className="block_btn">
                                {t('home.customers.btn')}
                            </Link>
                        </div>
                        <div className='exampleBlock d-100'>
                            <div className='exampleContent'>
                                <div className='exampleContainer'>
                                    <ul>
                                        {
                                            b5List.map((item,index)=>{
                                                return (
                                                    <li key={index}>
                                                        <div className='example_title'>
                                                            <img className="img-fluid" src={item.img} alt=""/>
                                                            <div className="title_style">{item.name}</div>
                                                        </div>
                                                        <div className='example_desc'>{item.desc}</div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <span className='leftarrow arrow' onClick={block5Click.bind(this,1)}></span>
                                <span className='rightarrow arrow' onClick={block5Click.bind(this,2)}></span>
                            </div>
                            <div className='examplepoint'>
                                <ul>
                                    {
                                        b5TotalPage.map((item,index)=>{
                                            return (
                                                <li key={index} className={[ b5CurrentPage=== (index+1)?'active':''].join(' ')} onClick={block5ClickPoint.bind(this,index+1)}></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock homeBlock block6 redBg w_color">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100 title_style">
                            <h1 className="block6_title">{t('home.download.title')}</h1>
                            <a href={props.linkObj.downloadLink.suite.url()} target="_blank" className="block_btn" rel="noopener noreferrer">
                                {t('home.download.btn')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
			{/* <div className={["qq",getLangeTypeBgByLang(lang)+'_bg'].join(' ')} id="qq" onClick={(e)=>{qqClick(e)}}><span>2816214541</span></div> */}
            {/* <div className="contentBlock lightGray r_color">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                            <h2 className="bm-title">{t('home.contact.title')}</h2>
                            <Link to={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`} className="cBtn b_border">
                                {t('home.contact.btn')}
                            </Link>
                        </div>
                    </div>
                </div>
               
            </div> */}
        </section>
    )
}

export default mixinWrapper(Home);
