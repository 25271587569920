import React from 'react';
import {Link} from 'react-router-dom';
const ContactBlock = props => {
    const {t,linkObj} = props;
    return (
        <div className="contactBlock hideMenuContent mobileScroll">
            <ul className="hideMenuList text-right">
                <li>
                    <Link to={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`}>
                        <h3>{t(linkObj.contact.link1)}</h3>
                        {t(linkObj.contact.link1_2)}
                    </Link>
                </li>
                <li>
                    <a href={linkObj.support.submitTicket.url()} target="_blank" rel="noopener noreferrer">
                        <h3>{t(linkObj.contact.link2)}</h3>
                        {t(linkObj.contact.link2_2)}
                    </a>
                </li>
                <li>
                    <Link to={`/${props.currentLang.type}${linkObj.contact.contactUs.path}`}>
                        {t(linkObj.contact.link3)}
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default ContactBlock;