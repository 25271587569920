import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import mixinWrapper from '../../hoc/mixinWrapper';
import {Link} from 'react-router-dom';
import MetaTags from 'react-meta-tags';

const ContactUs = props => {

    const {t, currentLang, linkObj, i18n} = props;
    const {lang, pathId} = useParams();  //eslint-disable-line no-unused-vars

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    
    const showBecomeParner = () => {
        if(currentLang.type !== 'kr'){
            return (
                <div className="customContainer paddingWrap grayBg-seashell block3">
                    <img src={require(`../../assets/img/contact/Group 654.png`)} className  ="img-fluid" alt="detail6"></img>
                    <div className="f-30 b3t1">{t('contactUs.block3T1')}</div>
                    <div className="f-16 b3t2">{t('contactUs.block3T2')}</div>
                    <div className="f-16 b3t3">{t('contactUs.block3T3')}</div>
                    <div className="f-16 b3t4">{t('contactUs.block3T4')}</div>
                    <div className="f-16 b3t5">{t('contactUs.block3T5')}<a className="b3t6" href = "mailto: kevin@rightpdf.com">{t('contactUs.block3T6')}</a></div>
                </div>
            );
        }
    }

    const showTaipeiOffice = () => {
        if(currentLang.type !== 'zh-CN'){
            return (
                <div className="cRow maxW1300 padding-0 align-top">
                    
                    <div className="d-33 mobilefullWidth t-left">
                        {/*<div>{t('contactUs.taipei')}</div>*/}
                        <div className="f-25">{t('contactUs.tOfficName')}</div>
                        <div className="content">
                            <div className="contentItem">
                                <div className="label">{t('contactUs.address')}</div>
                                <span className="text">{t('contactUs.tAddrContent')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.postalCode1')}</div>
                                <span className="text">{t('contactUs.tPostalCode')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.tel')}</div>
                                <span className="text">{t('contactUs.tTel')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.fox')}</div>
                                <span className="text">{t('contactUs.tFox')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-65 mobilefullWidth text-center">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.8792066373826!2d121.52129731572866!3d25.10594998393879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ae90db1d1c53%3A0x895a7ea3fc93bda3!2z5qOj5Y2X6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1550729764741" className="googleMaps" width="100%" height="502" title="navigation"></iframe>

                    </div>
                </div>
            );
        }else{
            return null;
        }
    }

    const showBeijingOffice = () => {
        if(currentLang.type === 'zh-CN'){
            return (
                <div className="cRow maxW1300 padding-0 align-top">
                    
                    <div className="d-33 mobilefullWidth t-left ">
                        <div>{t('contactUs.beijing')}</div>
                        <div className="f-25">{t('contactUs.bOfficName')}</div>
                        <div className="content">
                            <div className="contentItem">
                                <div className="label">{t('contactUs.address')}</div>
                                <span className="text">{t('contactUs.bAddrContent')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.postalCode2')}</div>
                                <span className="text">{t('contactUs.bPostalCode')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.tel')}</div>
                                <span className="text">{t('contactUs.bTel')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.fox')}</div>
                                <span className="text">{t('contactUs.bFox')}</span>
                            </div>
                        </div>
            
                    </div>
                    <div className="d-65 mobilefullWidth text-center">
						{/*<iframe width='100%' height='502' className="lbsAmap" frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0' src='https://surl.amap.com/brzLfMduY' title="navigation"></iframe>*/}
						<iframe width='100%' height='502' className="lbsAmap" frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0' src='https://lbs.amap.com/tools/showmap?0_821_502_116.321738_39.975617&=%E8%B1%AA%E6%99%AF%E5%A4%A7%E5%8E%A6A%E5%BA%A7&=%E7%9F%A5%E6%98%A5%E8%B7%AF108%E5%8F%B7%E8%B1%AA%E6%99%AF%E5%A4%A7%E5%8E%A6A%E5%BA%A71401%E5%AE%A4&=&=&=' title="navigation"></iframe>
                        {/* <iframe src="https://surl.amap.com/3yUNINafH7" className="lbsAmap" width="100%" height="502" scrolling="no"></iframe> */}

                    </div>
                </div>
            );
            }
        {/*僅簡中顯示北京辦公室(FOX)
            else{
            return (
                <div className="cRow maxW1300 padding-0 align-top">
                    
                    <div className="d-33 mobilefullWidth t-left ">
                        <div>{t('contactUs.beijing')}</div>
                        <div className="f-25">{t('contactUs.bOfficName')}</div>
                        <div className="content">
                            <div className="contentItem">
                                <div className="label">{t('contactUs.address')}</div>
                                <span className="text">{t('contactUs.bAddrContent')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.postalCode2')}</div>
                                <span className="text">{t('contactUs.bPostalCode')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.tel')}</div>
                                <span className="text">{t('contactUs.bTel')}</span>
                            </div>
                            <div className="contentItem">
                                <div className="label">{t('contactUs.fox')}</div>
                                <span className="text">{t('contactUs.bFox')}</span>
                            </div>
                        </div>
            
                    </div>
                    <div className="d-65 mobilefullWidth text-center">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.485913993656!2d116.3194321159925!3d39.97524517941878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35f05159c98111f7%3A0x2d03d0619337c3a7!2z6LGq5pmv5aSn5buI!5e0!3m2!1szh-TW!2stw!4v1550729183704" width="100%" height="502" className="googleMaps" title="navigation"></iframe>
                    </div>
                </div>
            );
        }*/}
    }


    const renderQQView = () => {
        if(currentLang.type === 'zh-CN'){
            return (
                <li>
                    <img src={require(`../../assets/img/contact/Group 631.png`)}  className="img-fluid" alt="detail2" />
                    <div className="contactusListText">
                        <div><a href={linkObj.qq.url()}  target="_blank" rel="noopener noreferrer">2816214541</a></div>
                    </div>
                </li>
            );
        } else {
            return '';
        }
    }

    return (
        <section id="contactUs" className="whiteBg">
            <MetaTags>
                <title>{t('metaTag.contactUs.title')}</title>
                <meta name="title" content={t('metaTag.contactUs.title')} />
                <meta name="description" content={t('metaTag.contactUs.desc')} />
                <meta name="keywords" content={t('metaTag.contactUs.keywords')} />
                <meta property="og:title" content={t('metaTag.contactUs.title')} />
                <meta property="og:description" content={t('metaTag.contactUs.desc')} />
            </MetaTags>
            <div className="infoTitle grayBg-seashell">
                <div className="customContainer paddingWrap">
                    <div className="cRow maxW1300 padding-0">
                        <h1>{t('contactUs.name')}</h1>
                    </div>
                </div>
            </div>

            <div className="customContainer paddingWrap whiteBg block1">
                <div className="cRow maxW1300">
                    <div className="subBlock d-50">
                        <div className="subBlock1 borderLine right">
                            <h2 className="f-30 b1title">{t('contactUs.block1Title1')}</h2>
                            <div className="b1text">{t('contactUs.block1Text1')}</div>
                            <Link to={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`}  className="cBtn b1Btn">
                                {t('contactUs.block1Btn1')}
                            </Link>
                            {/* <a href={linkObj.contact.contactSales.path} target="_blank" className="cBtn b1Btn">{t('contactUs.block1Btn1')}</a> */}
                            <ul className="contactusList">
                                <li>
                                    <img src={require(`../../assets/img/contact/Group 633.png`)}  className="img-fluid" alt="detail2" />
                                    <div className="contactusListText">
                                        <div>{t('contactUs.contactusListT1')}</div>
                                        {/*<div>{t('contactUs.contactusListT2')}</div>*/}
                                        <div>
                                          <div>{t('contactUs.contactusTime1')}</div>
                                          <div>{t('contactUs.contactusTime2')}</div>
                                          <div>{t('contactUs.contactusTime3')}</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <img src={require(`../../assets/img/contact/Group 632.png`)}  className="img-fluid" alt="detail2" />
                                    <div className="contactusListText">
                                        <div>{t('contactUs.contactusListT3')}</div>
                                    </div>
                                </li>
                                {renderQQView()}
                            </ul>
                        </div>
                    </div>
                    <div className="subBlock d-50">
                        <div className="subBlock2 borderLine top">
                            <h2 className="f-30 b1title">{t('contactUs.block1Title2')}</h2>
                            <div className="b1text">{t('contactUs.block1Text2')}</div>
                            <a href={linkObj.support.submitTicket.url()} target="_blank" rel="noopener noreferrer" className="cBtn b1Btn">{t('contactUs.block1Btn2')}</a>
                            <ul className="contactusList">
                                <li>
                                    <img src={require(`../../assets/img/contact/Group 633.png`)}  className="img-fluid" alt="detail2" />
                                    <div className="contactusListText">
                                        <div>{t('contactUs.contactusListT1')}</div>
                                        {/*<div>{t('contactUs.contactusListT2')}</div>*/}
                                        <div>
                                          <div>{t('contactUs.contactusTime1')}</div>
                                          <div>{t('contactUs.contactusTime2')}</div>
                                          <div>{t('contactUs.contactusTime3')}</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <img src={require(`../../assets/img/contact/Group 632.png`)}  className="img-fluid" alt="detail2" />
                                    <div className="contactusListText">
                                        <div>{t('contactUs.contactusListT4')}</div>
                                    </div>
                                </li>
                                {renderQQView()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>         

            {showBecomeParner()}
            
            <div className="customContainer paddingWrap block4">
                <div className="d-100">
                    <div className="d-100">
                        <div className="f-33 text-center title">{t('contactUs.office')}</div>
                    </div>
                </div>
                {showTaipeiOffice()}
                {showBeijingOffice()}
                
            </div>
        </section>
    )
}

export default mixinWrapper(ContactUs);