const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>产品激活时，出现「请以管理员身分运行」提示？</title></head><body>  <h1>产品激活时，出现「请以管理员身分运行」提示？</h1>  <div style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 100%; width: 100%; padding: 5px;">
<span class="wysiwyg-color-red"><span class="wysiwyg-color-red"><strong>重要提醒：</strong><span style="color: #2f3941;">旧版</span></span></span>DocuCom文电通已于2009年停止更新，并无法支持Windows7及以上的版本。如果解决方案没有效果，请考虑<strong><a title="Link: http://pdf.gaaiho.com/Docucom_upgrade/intro.aspx" href="https://store.rightpdf.cn/store/overview.aspx" target="_blank" rel="noopener">升级到最新版Right PDF</a></strong>，因为我们已经无法再更新旧版的文电通了。</div>
<p> </p>
<h2><strong>问题描述：</strong></h2>
<p>激活授权时，出现「请以管理员身分运行」的讯息，无法激活授权。</p>
<h2>
<br><strong>原因：</strong>
</h2>
<p>在Windows 7及以上操作系统，系统会对管理员或普通用户有些操作权限的限制，所以有时候会出现没有权限读取程序中license文件的情况。</p>
<p> </p>
<h2><strong><span class="wysiwyg-color-blue130">解决方法：</span></strong></h2>
<p><span class="wysiwyg-color-blue130">请尝试在电脑桌面右键单击程序的图示，选择「以管理员身份运行」，然后重新激活。</span></p>
<div><span class="wysiwyg-color-blue130"></span></div>
<div>
<span class="wysiwyg-color-blue130">另一个可能原因是license文件被意外的删除了，程序一样会提示用户没有权限读取该文件。碰上这种情况，请按照下列步骤重新安装：</span><br>
<ol>
<li><span class="wysiwyg-color-blue130">请反激活并卸载电脑上所有文电通产品(<strong>如果产品未激活或无法正常反激活，请略过</strong>)</span></li>
<li><span class="wysiwyg-color-blue130">卸载文电通</span></li>
<li><span class="wysiwyg-color-blue130">重新启动电脑</span></li>
<li>
<span class="wysiwyg-color-blue130">前往这个路径</span><span style="color: #000066;">「</span><span style="color: #000066;"><strong>%SystemDrive%\\Documents and Settings\\All Users\\Application Data\\</strong></span><span style="color: #000066;">」</span><span class="wysiwyg-color-blue130">（在Windows 7、Windows 8、Vista上则是</span><span style="color: #000066;">「</span><strong style="color: #000066;">%SystemDrive%\\ProgramData</strong><span style="color: #000066;">」）</span><span class="wysiwyg-color-blue130">，然后删除「Zeon」文件夹</span>
</li>
<li><span class="wysiwyg-color-blue"><span class="wysiwyg-color-blue130">重新安装软件，最后使用您的序列号激活</span></span></li>
</ol>
<div><span class="wysiwyg-color-blue"><br><span class="wysiwyg-color-blue130">如果您无法通过正常的方式卸载程序（控制面板 &gt; 程序 &gt; 卸载程序，选取「<strong>DocuCom PDF Gold *.x</strong>」然后按卸载），请使用附件中的「Remove Tool」替换上述的步骤2。</span><br></span></div>
<div> </div>
<div> </div>
</div></body></html>`;   export default html;