import React from 'react';
import {NavLink,useParams,useHistory} from 'react-router-dom';
import ProductCompare from './productCompare';
import SuiteCompare from './suiteCompare';
import ConverterCompare from './converterCompare';
import SubscriptionCompare from './subscriptionCompare';
import RemoteEnterprise from './remoteEnterprise';
import TslCompare from './tslCompare';

const MainComparePage = props => {
    let history = useHistory();
    const {t,linkObj,pageId} = props;
    const {lang} = useParams();
    const linkAry = [
        {
            name:t('resources.productCompare.name'),
            link:`/${props.currentLang.type}${linkObj.resources.compare.path}`
        },
        {
            name:t(linkObj.resources.suiteCompare.name),
            link:`/${props.currentLang.type}${linkObj.resources.suiteCompare.path}`
        },
        {
            name:t(linkObj.resources.converterCompare.name),
            link:`/${props.currentLang.type}${linkObj.resources.converterCompare.path}`
        },
        {
            name:t(linkObj.resources.subscriptionCompare.name),
            link:`/${props.currentLang.type}${linkObj.resources.subscriptionCompare.path}`
        },
        {
            name:t(linkObj.resources.remoteEnterprise.name),
            link:`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`
        },
        {
            name:t(linkObj.resources.tslCompare.name),
            link:`/${props.currentLang.type}${linkObj.resources.tslCompare.path}`
        }
    ]
    const renderComponent = () => {
        switch(pageId){
            case 'productCompare':
                return <ProductCompare {...props} />
            case 'proCompare':
                return <SuiteCompare {...props} />
            case 'converterCompare':
                return <ConverterCompare  {...props} />
            case 'subscriptionCompare':
                return <SubscriptionCompare {...props} />
            case 'remoteEnterprise':
                return <RemoteEnterprise {...props} />
            case 'tslCompare':
                return <TslCompare {...props} />
            default:
                history.push(`/${props.currentLang.type}`);
                return null;
        }
    }
    const tabclass = () => {
        let classArr = ["tabList"];
        if(lang === 'en'||lang === 'jp'||lang === 'kr'){
            classArr.push('tabList1');
        }
        return classArr.join(' ');
    }
    return (
        <div className="compareContent">
            <ul className={tabclass()}>
                {
                    linkAry.map((e,i)=>{
                        return (
                            <li key={i}>
                                <NavLink activeClassName="active" to={e.link}>
                                    {e.name}
                                </NavLink>
                            </li>
                        )
                    })
                }
            </ul>
            {
                renderComponent()
            }
        </div>
    )
}

export default MainComparePage;