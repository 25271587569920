const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何知道我的系统是32位还是64位？</title></head><body>  <h1>如何知道我的系统是32位还是64位？</h1>  <h3>Windows 10</h3>
<div>
<ol>
<li>在左下角Windows「<strong>开始</strong>」上方单击右键 &gt; 「<strong>系统</strong>」&gt;「<strong>系统类型</strong>」。</li>
</ol>
</div>
<div> </div>
<h3>Windows 7</h3>
<ol>
<li>在桌面「<strong>我的电脑</strong>」图标上点击右键，选择「<strong>属性</strong>」，打开系统属性对话框。</li>
<li>在「<strong>常规</strong>」选项卡「<strong>系统</strong>」信息中可以查看到系统是32位或64位。64位系统会在系统信息中特别说明是64位系统字样，32位系统则没有特别表示。<br><br><strong><span class="wysiwyg-color-red">注意</span>：</strong>购买盒装版的客户可能会发现安装包分为X64和X86。请将X86的安装包装在32位系统上，X64则装在64位系统上。</li>
</ol></body></html>`;   export default html;