const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>PDF文件打开后的名称与文件名不同？</title></head><body>  <h1>PDF文件打开后的名称与文件名不同？</h1>  <h2><strong>问题描述：</strong></h2>
<p>当我开启一个PDF文件后，其显示的名称与文件名不同？</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>PDF文件在开启后，可以选择以「文件名」或「文档标题」显示。当显示名称与文件名不同时，表示该文件被设定为以「文档标题」显示。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><strong><span class="wysiwyg-color-red">注意</span><span class="wysiwyg-color-blue130">：</span></strong><span class="wysiwyg-color-blue130">仅在文电通<strong>套装</strong><strong>版</strong>中可以变更设定，免费版文电通阅读器不支持。</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">到「文件 &gt; 信息&gt;更多属性」</span></li>
<li><span class="wysiwyg-color-blue130">选择「初始视图」，将「显示」改为「文件名」</span></li>
</ol>
<p><span class="wysiwyg-color-blue130">如此一来，PDF文件开启后显示的名称，就和文件名相同了。</span></p></body></html>`;   export default html;