
import LanguageReducer from './languageReducer';
import PopReducer from './popupReducer';
import menuReducer from './menuReducer';
import apiReducer from './apiReducer';
import {combineReducers} from 'redux';

const allreducers = combineReducers([LanguageReducer,PopReducer,menuReducer,apiReducer]);

export default allreducers;
