const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>使用Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)转换PDF/A结果不成功？</title></head><body>  <h1>使用Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)转换PDF/A结果不成功？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p>当PDF/A选项选中时，只有通过Right PDF (Gaaiho) Printer打印方式生成的PDF，才会生成符合PDF/A规范的PDF文档。然而，一些文件并不通过Right PDF (Gaaiho) Printer打印生成PDF，所以这些文件在转换成PDF时，PDF/A选项不起作用。<br><br>这些文件类型包括： <br><strong>.BMP;.EMF;.GIF;.JPG;.JPEG;.JP2;.JPX;.JPF;.J2C;.J2K; .PCD;.PCX;.PNG;.PSD;.TGA;.TAG;.TIFF;.TIF;.WMF;.WBMP;.PDF</strong></p></body></html>`;   export default html;