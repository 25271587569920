const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>[旧版] 如何在线/离线激活PDF文电通(Gaaiho PDF)？</title></head><body>  <h1>[旧版] 如何在线/离线激活PDF文电通(Gaaiho PDF)？</h1>  <table>
<tbody>
<tr>
<td>
<p><span class="wysiwyg-color-red "><strong>重点提示：</strong></span></p>
<ul>
<li>购买「永久型授权」的朋友，请选择「SN激活」(序号为32码)；</li>
<li>购买「订阅版授权」的朋友，请选择「账号激活」(于Gaaiho官网建立账号时的<strong>账号</strong>及<strong>密码</strong>)</li>
</ul>
</td>
</tr>
</tbody>
</table>
<p> </p>
<h2><strong>在线激活：</strong></h2>
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130 ">1. 安装完成之后，执行<strong>Gaaiho Doc</strong>或<strong>Gaaiho PDF Driver</strong>会弹出关于激活对话框。或者</span></div>
<div class="wysiwyg-text-align-left"> </div>
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">套装版</span>:</strong></span></div>
<div class="wysiwyg-text-align-left"><span class=" wysiwyg-color-blue130">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Gaaiho」 &gt; 「Gaaiho PDF Suite」 &gt; 「授权信息」并点击「激活」按钮。</span></div>
<div class="wysiwyg-text-align-left"> </div>
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">转换器</span>:</strong></span></div>
<div class="wysiwyg-text-align-left">
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130 ">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Gaaiho」 &gt; 「Gaaiho PDF Driver」 &gt; 「授权信息」并点击「激活」按钮。</span></div>
</div>
<div class="wysiwyg-text-align-left"><img src="https://store.rightpdf.com/images/rtsupport/activation step 1.png" alt=""></div>
<div class="wysiwyg-text-align-left"> </div>
<div class="wysiwyg-text-align-left"><span class=" wysiwyg-color-blue130"> </span></div>
<div class="wysiwyg-text-align-left"><span class=" wysiwyg-color-blue130">2. 点击「<strong>SN</strong> <strong>激活</strong>」</span></div>
<div class="wysiwyg-text-align-left"><img src="https://store.rightpdf.com/images/rtsupport/activation step 2.png" alt=""></div>
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130"> </span></div>
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130">3. 选择「透过 Internet 网络」</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/activation step 3 online SC.png" alt=""></div>
<div> </div>
<div><span class="wysiwyg-color-blue130">4. 输入序列号</span></div>
<div><span class="wysiwyg-color-blue130">5. 激活完成</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/license activated (1).png" alt=""></div>
<div> </div>
<h2><strong>離线激活：</strong></h2>
<div class="wysiwyg-text-align-left">
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130 ">1. 安装完成之后，执行<strong>Gaaiho Doc</strong>或<strong>Gaaiho PDF Driver</strong>会弹出关于激活对话框。或者</span></div>
<div class="wysiwyg-text-align-left"> </div>
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">套装版</span>:</strong></span></div>
<div class="wysiwyg-text-align-left"><span class=" wysiwyg-color-blue130">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Gaaiho」 &gt; 「Gaaiho PDF Suite」 &gt; 「授权信息」并点击「激活」按钮。</span></div>
<div class="wysiwyg-text-align-left"> </div>
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130"><strong><span class="wysiwyg-underline">转换器</span>:</strong></span></div>
<div class="wysiwyg-text-align-left">
<div class="wysiwyg-text-align-left"><span class="wysiwyg-color-blue130 ">您可以前往「开始」 &gt; 「所有程序」 &gt; 「Gaaiho」 &gt; 「Gaaiho PDF Driver」 &gt; 「授权信息」并点击「激活」按钮。</span></div>
</div>
</div>
<div class="wysiwyg-text-align-left"><img src="https://store.rightpdf.com/images/rtsupport/activation step 1 (1).png" alt=""></div>
<div class="wysiwyg-text-align-left"> </div>
<div class="wysiwyg-text-align-left"><span class=""> </span></div>
<div class="wysiwyg-text-align-left"><span class="">2. <span class="wysiwyg-color-blue130">点击「SN<strong>激活</strong>」</span></span></div>
<div class="wysiwyg-text-align-left"><img src="https://store.rightpdf.com/images/rtsupport/activation step 2 (1).png" alt=""></div>
<div class="wysiwyg-text-align-left"> </div>
<div class="wysiwyg-text-align-left"> </div>
<div>3. <span class="wysiwyg-color-blue130">选择「<strong>导出激活数据</strong>」，输入序列号并生成「<strong>ActivationData.htm</strong>」</span>
</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/activation_step_3_offline_SC.png" alt=""></div>
<div> </div>
<div> </div>
<div><span class="wysiwyg-color-blue130">4. 用随身硬盘等存储设备将导出的文件移到在联网环境的电脑</span></div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130">5. 使用浏览器打开「Activati​​onData.htm」，并点击「上传」</span></div>
<div> </div>
<div> </div>
<div> </div>
<div><span class="wysiwyg-color-blue130">6. 在「授权文件」上点击右键并保存</span></div>
<div> </div>
<div> </div>
<div><span class="wysiwyg-color-blue130">7. 将.zna文件移回未联网的电脑</span></div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130">8. 重复步骤1到2，选择「导入激活数据」，然后再选取刚才移动过来的.zna文件</span></div>
<div> <img src="https://store.rightpdf.com/images/rtsupport/activation step 4 offline SC.png" alt="">
</div>
<div> </div>
<div><span class="wysiwyg-color-blue130">9. 激活完成</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/license activated (1).png" alt=""></div></body></html>`;   export default html;