import React from 'react';
import MetaTags from 'react-meta-tags';

const UserManuals = props => {
    const {t,currentLang} = props;
    const useManualObj = {
        "zh-TW":{
            suite5:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_5.1_tw.pdf",
            suite4:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_4.1_tw.pdf",
            suite3:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_3.3_tw.pdf",
            converter5:"https://www.gaaiho.com/resources/user_manuals/converter/GaaihoPDFConverter_UserManual_5.1_tw.pdf",
            converter4:"https://www.gaaiho.com/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_4.1_tw.pdf",
            converter3:"https://www.gaaiho.com/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_3.3_tw.pdf",
            server5:"https://www.gaaiho.com/resources/user_manuals/server/GaaihoPDFServer_UserManual_5.0_tw_201811.pdf",
            server3:"http://www.gaaiho.com/Help_Files/cht/Gaaiho_PDF_server_Help/help.html",
            reader4:"https://www.gaaiho.com/resources/user_manuals/reader/GaaihoPDFReader_UserManual_4.0_tw.pdf",
            reader3:"http://www.gaaiho.com/Help_Files/cht/Gaaiho_Reader_3_Help/help.html",
            reader1:"http://www.gaaiho.com/Help_Files/cht/Gaaiho_Reader_help/help.html"

        },
        "zh-CN":{
            suite5:"http://www.gaaiho.cn/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_5.1_cn.pdf",
            suite4:"http://www.gaaiho.cn/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_4.1_en.pdf",
            suite3:"http://www.gaaiho.cn/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_3.3_en.pdf",
            converter5:"https://www.gaaiho.com/resources/user_manuals/converter/GaaihoPDFConverter_UserManual_5.0_en.pdf",
            converter4:"http://www.gaaiho.cn/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_4.1_en.pdf",
            converter3:"http://www.gaaiho.cn/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_3.3_en.pdf",
            server5:"http://www.gaaiho.cn/resources/user_manuals/server/GaaihoPDFServer_UserManual_5.0_cn.pdf",
            server3:"http://www.gaaiho.com/Help_Files/chs/Gaaiho_PDF_server_Help/help.html",
            reader4:"http://www.gaaiho.cn/resources/user_manuals/reader/GaaihoPDFReader_UserManual_4.0_en.pdf",
            reader3:"http://www.gaaiho.com/Help_Files/chs/Gaaiho_Reader_3_Help/help.html",
            reader1:"http://www.gaaiho.com/Help_Files/chs/Gaaiho_Reader_help/help.html"
        },
        "en":{
            suite5:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_5.0_en.pdf",
            suite4:"http://www.gaaiho.cn/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_4.1_en.pdf",
            suite3:"http://www.gaaiho.cn/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_3.3_en.pdf",
            converter5:"https://www.gaaiho.com/resources/user_manuals/converter/GaaihoPDFConverter_UserManual_5.0_en.pdf",
            converter4:"https://www.gaaiho.com/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_4.1_en.pdf",
            converter3:"https://www.gaaiho.com/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_3.3_en.pdf",
            server5:"https://www.gaaiho.com/resources/user_manuals/server/GaaihoPDFServer_UserManual_5.0_en_201811.pdf",
            server3:"http://www.gaaiho.com/Help_Files/enu/Gaaiho_PDF_server_Help/help.html",
            reader4:"https://www.gaaiho.com/resources/user_manuals/reader/GaaihoPDFReader_UserManual_4.0_en.pdf",
            reader3:"http://www.gaaiho.com/Help_Files/enu/Gaaiho_Reader_3_Help/help.html",
            reader1:"http://www.gaaiho.com/Help_Files/enu/Gaaiho_Reader_help/help.html"
        },
        "jp":{
            suite5:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_5.1_jp.pdf",
            suite4:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_4.1_jp.pdf",
            suite3:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_3.3_en.pdf",
            converter5:"https://www.gaaiho.com/resources/user_manuals/converter/GaaihoPDFConverter_UserManual_5.0_en.pdf",
            converter4:"https://www.gaaiho.com/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_4.1_en.pdf",
            converter3:"https://www.gaaiho.com/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_3.3_en.pdf",
            server5:"https://www.gaaiho.com/resources/user_manuals/server/GaaihoPDFServer_UserManual_5.0_jp.pdf",
            server3:"http://www.gaaiho.com/Help_Files/jpn/Gaaiho_PDF_Server_3.1_Help/help.html",
            reader4:"https://www.gaaiho.com/resources/user_manuals/reader/GaaihoPDFReader_UserManual_4.0_en.pdf",
            reader3:"http://www.gaaiho.com/Help_Files/enu/Gaaiho_Reader_3_Help/help.html",
            reader1:"http://www.gaaiho.com/Help_Files/enu/Gaaiho_Reader_help/help.html"
        },
        "kr":{
            suite5:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_5.1_kr.pdf",
            suite4:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_4.1_kr.pdf",
            suite3:"https://www.gaaiho.com/resources/user_manuals/suite/GaaihoPDFSuite_UserManual_3.3_en.pdf",
            converter5:"https://www.gaaiho.com/resources/user_manuals/converter/GaaihoPDFConverter_UserManual_5.0_kr.pdf",
            converter4:"https://www.gaaiho.com/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_4.1_en.pdf",
            converter3:"https://www.gaaiho.com/resources/user_manuals/driver/GaaihoPDFDriver_UserManual_3.3_en.pdf",
            server5:"https://www.gaaiho.com/resources/user_manuals/server/GaaihoPDFServer_UserManual_5.0_kr.pdf",
            server3:"http://www.gaaiho.com/Help_Files/enu/Gaaiho_PDF_server_Help/help.html",
            reader4:"https://www.gaaiho.com/resources/user_manuals/reader/GaaihoPDFReader_UserManual_4.0_en.pdf",
            reader3:"http://www.gaaiho.com/Help_Files/enu/Gaaiho_Reader_3_Help/help.html",
            reader1:"http://www.gaaiho.com/Help_Files/enu/Gaaiho_Reader_help/help.html"
        }
    }
    
    const newUserManualList = [
        {
            name:t('tutorials.userManualSuite.5'),
            img:'right_suite',
            downloadLink:useManualObj[currentLang.type].suite5
        },
        {
            name:t('tutorials.userManualConverter.5'),
            img:'right_driver',
            downloadLink:useManualObj[currentLang.type].converter5
        },
        {
            name:t('tutorials.userManualServer.5'),
            img:'right_server',
            downloadLink:useManualObj[currentLang.type].server5
        },
        {
            name:t('tutorials.userManualReader.4'),
            img:'right_reader',
            downloadLink:useManualObj[currentLang.type].server4
        },
        {
            name:t('tutorials.userManualSDK'),
            img:'right_sdk',
            downloadLink:'https://www.gaaiho.com/resources/user_manuals/sdk/GaaihoPDFSDK_DeveloperGuide.pdf'
        }
    ];

    const oldUserManualList = [
        {
            title:t('tutorials.userManualSuite.title'),
            linkArray:[
                {
                    name:t('tutorials.userManualSuite.4'),
                    type:'download',
                    link:useManualObj[currentLang.type].suite4
                },
                {
                    name:t('tutorials.userManualSuite.3'),
                    type:'download',
                    link:useManualObj[currentLang.type].suite3
                }
            ]
        },
        {
            title:t('tutorials.userManualConverter.title'),
            linkArray:[
                {
                    name:t('tutorials.userManualConverter.4'),
                    type:'download',
                    link:useManualObj[currentLang.type].converter4
                },
                {
                    name:t('tutorials.userManualConverter.3'),
                    type:'download',
                    link:useManualObj[currentLang.type].converter3
                }
            ]
        },
        {
            title:t('tutorials.userManualServer.title'),
            linkArray:[
                {
                    name:t('tutorials.userManualServer.3'),
                    type:'link',
                    link:useManualObj[currentLang.type].server3
                }
            ]
        },
        {
            title:t('tutorials.userManualReader.title'),
            linkArray:[
                {
                    name:t('tutorials.userManualReader.3'),
                    type:'link',
                    link:useManualObj[currentLang.type].reader3
                },
                {
                    name:t('tutorials.userManualReader.1'),
                    type:'link',
                    link:useManualObj[currentLang.type].reader1
                }
            ]
        }
    ]

    const renderList = array => {
        return array.map((e,i)=>{
            return (
                <li key={i}>
                    <a href={e.downloadLink} target="_blank" rel="noopener noreferrer">
                        <img src={require(`../../assets/img/${e.img}.png`)} className="img-fluid" alt="caseImg" />
                        <p>{e.name}</p>
                    </a>
                </li>
            )
        })
    }
    const renderBlock = array => {
        return array.map((e,i)=>{
            return (
                <div className="downloadListContent" key={i}>
                    <h4 className="downloadListTitle">{e.title}</h4>
                    <ul className="downloadList">
                        {
                            e.linkArray.map((child,ci)=>{
                                return (
                                    <li key={ci}>
                                        <span>{child.name}</span> 
                                        <a href={child.link} target="_blank" rel="noopener noreferrer" className={'downloadLinkBtn '+child.type}> </a>
                                    </li>
                                    )
                            })
                        }
                    </ul>
                </div>
            )
        })
    }
    return(
        <div className="userManualsContent text-size-16">
            <MetaTags>
                <title>{t('metaTag.userManuals.title')}</title>
                <meta name="title" content={t('metaTag.userManuals.title')} />
                <meta name="description" content={t('metaTag.userManuals.desc')} />
                <meta name="keywords" content={t('metaTag.userManuals.keywords')} />
                <meta property="og:title" content={t('metaTag.userManuals.title')} />
                <meta property="og:description" content={t('metaTag.userManuals.desc')} />
            </MetaTags>
            <h2> {t('tutorials.userManualsDownload')}</h2>
            <ul className="userManualDownloadList">
                {
                    renderList(newUserManualList)
                }
            </ul>
            <h3>{t('tutorials.earlierVersion')}</h3>
            {
                renderBlock(oldUserManualList)
            }
        </div>
    )
}

export default UserManuals;