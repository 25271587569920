const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何在文件夹/此电脑中显示PDF文件缩略图</title></head><body>  <h1>如何在文件夹/此电脑中显示PDF文件缩略图</h1>  <h2><strong>问题描述：</strong></h2>
<p>所有PDF文件都变成以<strong>图标</strong>显示，而非<strong>缩</strong><strong>略</strong><strong>图</strong>，但我想要以<strong>缩</strong><strong>略</strong><strong>图</strong>显示。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip1 (14).png" alt="mceclip1 (14).png"></p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>其实这并非PDF软件的问题，而是Windows系统设定上的结果。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<ol>
<li>
<span class="wysiwyg-color-blue130">请至文件夹下「查看」分页书签的「选项」</span><br><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (15).png" alt="mceclip2 (15).png"></span><br><br>
</li>
<li><span class="wysiwyg-color-blue130">在「查看」分页下的「高级设置」中，取消勾选「<strong>始终显示图标，从不显示缩略图</strong>」&gt;「应用」</span></li>
<li><span class="wysiwyg-color-blue130">您可以点击「应用到文件夹」将此设定应用到所有文件夹。</span></li>
</ol>
<p> </p></body></html>`;   export default html;