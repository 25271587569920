const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何改变PDF页面的纸张尺寸大小？</title></head><body>  <h1>如何改变PDF页面的纸张尺寸大小？</h1>  <p>有时候，您可能需要将不同尺寸大小的PDF文件合并在一起，但是如此一来，合并后的PDF文件尺寸大小将不一致。此时，您可以透过以下方法，先将其中一份PDF文件的尺寸转换成跟另一份文件相同后，再进行合并。如此一来，也能避免打印时，打印机选择不同纸张打印不同页面。</p>
<p> </p>
<ol>
<li>以Right PDF Editor/ Gaaiho Doc开启您欲改变尺寸的PDF文件，选择「文件」&gt;「打印」，并选择「Right PDF」/「Gaaiho PDF」虚拟打印机</li>
<li>取消勾选「仅保留纸张尺寸的页面内容」选项</li>
<li>进入「属性...」</li>
<li>将「页面大小」改成您希望的尺寸，「确定」</li>
</ol>
<p><span class="wysiwyg-underline"><strong>Right PDF:</strong></span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/06023.jpg" alt="06023.jpg" width="646" height="498"></p>
<p> </p>
<p><span class="wysiwyg-underline"><strong>Gaaiho PDF:</strong></span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (10).png" alt="mceclip0 (10).png" width="615" height="320"></p>
<p> </p>
<p>如此一来，您新打印出的PDF文件纸张尺寸大小，就会是您指定的新尺寸了。</p>
<p>接下来再与其它PDF文件进行合并即可。</p></body></html>`;   export default html;