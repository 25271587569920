import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import {faChevronDown,faChevronRight,faChevronLeft, faPlus} from '@fortawesome/free-solid-svg-icons';
import {far,faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  //eslint-disable-line no-unused-vars
//view and component
import Header from './components/header';
import Footer from './components/footer';
import PopupWrapper from './components/popup/popupWrapper';
import Home from './views/home';
import NewFeature from './views/newFeature';
import Suite from './views/suite';
import businesstrial from './views/businesstrial';
import Converter from './views/converter';
import ServerPage from './views/server';
import SdkPage from './views/sdk';
import ReaderPC from './views/readerPC';
import Help from './views/help';
import HelpContent from './views/helpcontent';
import Support from './views/support';
import ReaderMobile from './views/readerMobile';
import AboutPage from './views/about/index';
import AboutDetail from './views/about/aboutDetail';
import ResourcesPage from './views/resources/index';
import TutorialPage from './views/tutorials/index';
import PdfSuite from './views/pdfSuite';
import ContactUs from './views/contact/contactUs';
import ContactSales from './views/contact/contactSales';
import Readmore from './views/readmore';
import ProductDocumentsDetail from './views/resources/productDocumentsDetail';

// import Iaamfy from './views/resources/Is_Adobe_a_Must_for_You';
// import Kycieg from './views/resources/Know_Your_Choices_IT_Evaluation_Guide';
// import Uypp from './views/resources/Unlock_Your_PDF_Potentials';


import scrolltop from './assets/img/scrolltop.png';
import {animateScroll as scroll} from 'react-scroll';
import { useSelector } from 'react-redux';

// import Mac from './views/promac';

library.add(far,faChevronDown, faChevronRight , faChevronLeft ,faPlus, faQuestionCircle);
const App = () => {

  let currentLang = useSelector(state => state[0].currentLang);
  
  let scrollToTop = () => {
    scroll.scrollToTop();
  }
  const qqClick = (e) => {
		if(document.getElementById("qq").className.indexOf("toggled") === -1){
			document.getElementById("qq").classList.add("toggled");
		}
	}
  const getLangeTypeBgByLang = (langType) => {
    let type = '';
    switch(langType){
        case 'zh-TW':
            type = 'tw';
            break;
        case 'zh-CN':
            type = 'cn';
            break;
        case 'en':
            type = 'en';
            break;
        case 'jp':
            type = 'jp';
            break;
        case 'kr':
            type = 'kr';
            break;
        default:
            type = 'tw';      
    }
    return type;
}
  let routes  = [
    {
      path:'/:lang',
      component:Home,
      exact:true,
      render:true
    },
    {
      path:'/:lang/newFeature',
      component:NewFeature,
      exact:false,
      render:true
    },
    {
      path:'/:lang/pro',
      component:Suite,
      exact:true,
      render:true
    },
    // {
    //   path:'/:lang/mac',
    //   component:Mac,
    //   exact:true,
    //   render:true
    // },
    {
      path:'/:lang/converter',
      component:Converter,
      exact:true,
      render:true
    },
    {
      path:'/:lang/help',
      component:Help,
      exact:true,
      render:true
    },
    {
      path:'/:lang/helpcontent/:urlName/:urlID?',
      component:HelpContent,
      exact:true,
      render:true
    },
    {
      path:'/:lang/server',
      component:ServerPage,
      exact:true,
      render:true
    },
    {
      path:'/:lang/sdk',
      component:SdkPage,
      exact:true,
      render:true
    },
    {
      path:'/:lang/reader/pc',
      component:ReaderPC,
      exact:true,
      render:true
    },
    {
      path:'/:lang/reader/app',
      component:ReaderMobile,
      exact:true,
      render:true
    },
    {
      path:'/:lang/contactUs',
      component:ContactUs,
      exact:false,
      render:true
    },
    {
      path:'/:lang/contactSales',
      component:ContactSales,
      exact:false,
      render:true
    },
    {
      path:'/:lang/businesstrial',
      component:businesstrial,
      exact:false,
      render:true
    },
    {
      path:'/:lang/About/:pathId/:pageId',
      component:AboutDetail,
      exact:false,
      render:true
    },
    {
      path:'/:lang/About/:pathId',
      component:AboutPage,
      exact:false,
      render:true
    },
    {
      path:'/:lang/Resources/:pathId/:pageId',
      component:ResourcesPage,
      exact:true,
      render:true
    },
    {
      path:'/:lang/Tutorials/:pathId',
      component:TutorialPage,
      exact:false,
      render:true
    },
    {
      path:'/:lang/pdf-suite',
      component:PdfSuite,
      exact:false,
      render:true
    },
    {
      path:'/:lang/pro/readmore/:contentId',
      component:Readmore,
      exact:false,
      render:true,
      featureType:'suite'
    },
    {
      path:'/:lang/mac/readmore/:contentId',
      component:Readmore,
      exact:false,
      render:true,
      featureType:'mac'
    },
    {
      path:'/:lang/converter/readmore/:contentId',
      component:Readmore,
      exact:false,
      render:true,
      featureType:'converter'
    },
    {
      path:'/:lang/server/readmore/:contentId',
      component:Readmore,
      exact:false,
      render:true,
      featureType:'server'
    },
    {
      path:'/:lang/sdk/readmore/:contentId',
      component:Readmore,
      exact:false,
      render:true,
      featureType:'sdk'
    },
    {
      path:'/:lang/pc/readmore/:contentId',
      component:Readmore,
      exact:false,
      render:true,
      featureType:'pc'
    },
    {
      path:'/:lang/app/readmore/:contentId',
      component:Readmore,
      exact:false,
      render:true,
      featureType:'app'
    },
    {
      path:'/:lang/Resources/:contentId',
      component:ProductDocumentsDetail,
      exact:true,
      render:true
    },
    {
      path:'/:lang/Resources/:contentId/:level/:menuId',
      component:ProductDocumentsDetail,
      exact:true,
      render:true
    },
    {
        path:'/:lang/support/:urlID?',
        component:Support,
        exact:true,
        render:true
    }
  ];
  

  return (
    <Router>
      <div className="App" lang={currentLang.type}>
        <PopupWrapper />
        <Header path="/:lang"></Header>
        <Switch>
          {
            routes.map((item,index)=>{
              if(item.render){
                return (
                  <Route key={index} exact={item.exact} path={item.path} render={({match})=>{
                    //跳转路由前进行语言值校验
                    let lang = match.params.lang;
                    let langArr = ['zh-CN','zh-TW','en','jp','kr'];
                    if(langArr.indexOf(lang) === -1){
                      return <Redirect to="/"></Redirect>
                    }else{
                      if(item.featureType){
                        return <item.component featureType={item.featureType}></item.component>
                      }else{
                        return <item.component></item.component>
                      }
                      
                    }
                  }}></Route>
                )
              }else{
                return (
                  <Route key={index} exact={item.exact} path={item.path} component={item.component}></Route>
                )
              }
            })
          }
          <Redirect exact from="/:lang/suite" to="/:lang/pro" />
          <Redirect exact from="/:lang/mac" to="/:lang/pro" />
          <Redirect from="/:lang/suite/readmore/:contentId" to="/:lang/pro/readmore/:contentId" />
          <Redirect to={`/${currentLang.type}`} />
        </Switch>
        <Footer></Footer>
        <div className={["qq",getLangeTypeBgByLang(currentLang.type)+'_bg'].join(' ')} id="qq" onClick={(e)=>{qqClick(e)}}><span>2816214541</span></div>
        <span className="scrollTopBtn" onClick={()=>{scrollToTop()}}>
            <img src={scrolltop} alt="scroll" />
        </span>
        {/* <div className="clientSupport">
            <FontAwesomeIcon  icon={['far','question-circle']} className="fa-sm"/>
            <span>Support</span>
        </div> */}
      </div>
    </Router>
  );
}

export default App;
