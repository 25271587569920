import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
const Legal = props => {
    const {t,currentLang,linkObj} = props;

    const renderLi = type =>  {
        if(currentLang.type !== 'zh-CN'){
            if(type === 'sdk'){
                return (
                    <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.zeon_software_license_terms_for_gaaiho_sdk.path}`} className="greenlink font-weight-light">
                            {t('legal.zeon-software-license-terms-for-rightpdf-sdk.title')}
                        </Link>
                    </li>
                )
            }else if(type === 'refund-policy'){
                return (
                    <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.refund_policy.path}`} className="greenlink font-weight-light">
                            {t('legal.refund-policy.title')}
                        </Link>
                    </li>
                )
            }
        }else{
            return null;
        }
    }
    return (
        <div className="legalContent">
            <MetaTags>
                <title>{t('metaTag.legal.title')}</title>
                <meta name="title" content={t('metaTag.legal.title')} />
                <meta name="description" content={t('metaTag.legal.desc')} />
                <meta name="keywords" content={t('metaTag.legal.keywords')} />
                <meta property="og:title" content={t('metaTag.legal.title')} />
                <meta property="og:description" content={t('metaTag.legal.desc')} />
            </MetaTags>
            <div className="alignLeft">
                <h2 className="font-weight-bold title_style title_style">{t('legal.listTitle1')}</h2>
                <ul className="legalList discList">
                    <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.eula_for_gaaiho_products.path}`} className="greenlink font-weight-light">
                            {t('legal.eula-for-rightpdf-products.title')}
                        </Link>
                    </li>
                    {
                        renderLi('sdk')
                    }
                    <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.oem_license.path}`} className="greenlink font-weight-light">
                            {t('legal.oem-license.title')}
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="alignLeft">
                <h2 className="font-weight-bold title_style">{t('legal.listTitle2')}</h2>
                <ul className="legalList discList">
                    <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.maintenance_service.path}`} className="greenlink font-weight-light">
                            {t('legal.maintenance-service.title')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.support_policies.path}`} className="greenlink font-weight-light">
                            {t('legal.support-policies.title')}
                        </Link>
                    </li>
                    {/* <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.upgrade_licenses.path}`} className="greenlink font-weight-light">
                            {t('legal.upgrade-licenses.title')}
                        </Link>
                    </li> */}
                </ul>
            </div>
            <div className="alignLeft">
                <h2 className="font-weight-bold title_style">{t('legal.listTitle3')}</h2>
                <ul className="legalList discList">
                    <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.terms_of_use.path}`} className="greenlink font-weight-light">
                            {t('legal.terms-of-use.title')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${props.currentLang.type}${linkObj.legal.privacy_statement.path}`} className="greenlink font-weight-light">
                            {t('legal.privacy-statement.title')} 
                        </Link>
                    </li>
                   {
                       renderLi('refund-policy')
                   }
                </ul>
            </div>
        </div>
    )
}
export default Legal;

