const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>转换时出现「您还没有启动Zeon/Gaaiho License Service服务」？</title></head><body>  <h1>转换时出现「您还没有启动Zeon/Gaaiho License Service服务」？</h1>  <h2><strong>问题描述：</strong></h2>
<p>「Zeon/Gaaiho License Service服务没有启动。请在服务管理中启动该服务以保证客户端可以获得有效的授权信息。」有时候这个讯息会伴随着「<strong><span class="wysiwyg-color-red">Limit is exceeded</span></strong>」的讯息一起出现。</p>
<h2>
<br><strong>原因：</strong>
</h2>
<p>就如同字面上意思一样，不启动服务的话​​您就无法使用产品。</p>
<h2>
<br><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span>
</h2>
<p><span class="wysiwyg-color-blue130">1. 在「运行」中输入「<strong>services.msc</strong>」</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/330151.jpg" alt=""></span></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">2. 在服务列表中寻找「<strong>Zeon</strong>/<strong>Gaaiho License Service</strong>」并启动服务(</span><span class="wysiwyg-color-red"><strong>注意</strong></span><span class="wysiwyg-color-blue130">：如果在列表中找不到服务，请重安装产品)</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/330152.jpg" alt=""></span></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">3. 重开机</span></p></body></html>`;   export default html;