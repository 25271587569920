const initState = {
    moblieShow:false,
    desktopShow: false
}

const linkReducer = (state = initState,action) => {
    switch (action.type){
        case 'TOGGLE_MENU':
            if(state.moblieShow){
                return {...state,moblieShow:false};
            }else{
                return {...state,moblieShow:true};
            }
        case 'TOGGLE_DESKTOP_MENU':
            if(state.desktopShow){
                return {...state,desktopShow:false};
            }else{
                return {...state,desktopShow:true};
            }
        case 'CLOSE_DESKTOP_MENU':
            return {...state,desktopShow:false}; 
        case 'CLOSE_MENU':
            return {...state,moblieShow:false}; 
        default:
            return state
    }
}

export default linkReducer;