const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>打印PDF时，部分内容空白或出现乱码？</title></head><body>  <h1>打印PDF时，部分内容空白或出现乱码？</h1>  <h2><strong>问题描述：</strong></h2>
<p>打印PDF时，部分内容空白或出现乱码。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>這有可能是PDF原始檔案的問題，也有可能是文電通相容性問題，請嘗試以下解決方法 並 將該PDF文件寄到 <a href="mailto:support@rightpdf.com" target="_self" rel="undefined">support@rightpdf.com</a> 信箱，我們將會對該PDF檔案進行檢查。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">当打印内容出现空白或乱码时，您可以尝试在打印设定中勾选 "打印为圖片"，就可以改善打印时内容出现空白或乱码之情形。</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (16).png" alt="mceclip2 (16).png" width="451" height="448"></p>
<p> </p>
<p>使用上有任何问题或建议，欢迎您来信至 <a href="mailto:support@rightpdf.com" target="_self" rel="undefined">support@rightpdf.com</a>，我们会尽快为您解答！</p></body></html>`;   export default html;