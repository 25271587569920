import React from 'react';
import MetaTags from 'react-meta-tags';
const SuiteCompare = props => {
	const metaTagscontent = {
		title:'发挥PDF的无穷潜力-文电通官网',
		desc:'自1993年首次发布以来，便携式文件格式 PDF一直为满足不同背景的用户需求而不断革新，至今已成最受政府单位与企业组织青睐的格式之一。'
	};
	const viewcontent =
		`<h1 id="p1">前言</h1>

<p>自1993年首次发布以来，便携式文件格式 PDF（Portable Document Format）一直为满足不同背景的用户需求而不断革新，至今已成最受政府单位与企业组织青睐的格式之一。然而，虽然 PDF 在大多数企业的工作流程中已成为不可或缺的环节，多数人却仍然不甚清楚PDF具备的优势为何。</p>

<p>&nbsp;</p>

<div id="p13">
<p><strong>PDF的优点</strong></p>

<ol>
	<li>
	<p>高度的兼容性</p>
	</li>
	<li>
	<p>精确的显示与打印</p>
	</li>
	<li>
	<p>易于保护</p>
	</li>
	<li>
	<p>归档格式的首选</p>
	</li>
	<li>
	<p>可随时阅读</p>
	</li>
	<li>
	<p>减少文档大小</p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<p class="marginB100">事实上，您无须成为专业的IT人员就能了解PDF为您的工作或企业所创造的价值。作为PDF的初学者，您只需要记得几个与PDF相关的关键词即可：简单、准确、安全。同时您可以想象：所有文档在任何设备上都能维持一致的文件格式，并且能够兼顾搜索、审阅、管理与安全，而这些还不是PDF全部的能耐。如果成千上万的企业都已正在用PDF加强自身的竞争力，您也应该了解他们使用PDF的原因及方法。</p>

<h1 id="p2">PDF有哪些用户？</h1>

<p class="marginB100">早在公元2000年，美国联邦法院即以PDF作为归档电子文件的格式。许多人从国税局（IRS）或其他的政府机构，例如美国国家档案馆（US National Archives）或澳洲政府的SmartForm下载表单时便已接触到PDF。值得一提的是，欧盟更以PDF 1.7与PDF/A作为传输电子文件的标准交换格式。时至今日，美国联邦政府仍然是最大的PDF技术用户。</p>

<h1 id="p3">为什么使用PDF？</h1>

<p class="marginB100">现今无所不在的电子文件是提升PDF使用率的主要因素。在办公室、协作者之间或网络中，可能有成百上千个正在传输的电子文档需要以兼容的应用程序打开，但却无法完全避免因不同操作环境（例如使用旧版软件）而导致无法开打开文件的意外，还需进一步耗费额外的时间和精力才能解决问题；更有甚者，有心人士可能会从未受保护的文件中获取敏感信息，进而损害公司的权益。为了解决上述问题，以PDF作为分享和储存电子文件的格式，长期而言能够为企业节省非常可观的成本。</p>

<h1 id="p4">他们如何使用PDF？</h1>

<p>以下是人们生活中经常碰到的六大挑战以及PDF解决问题的方法：</p>

<p>&nbsp;</p>

<div class="box6Wrapper">
	<div class="box6">
	<p><strong>安全性</strong></p>

	<p>离开计算机或公司网络之后，难以确保文件安全。</p>

	<p><img alt="01_Security" src="${require(`../../assets/img/resources/Group 1337.svg`)}" /></p>
	</div>
	<div class="box6">
	<p><strong>分享</strong></p>

	<p>难以分享大型文件，收件人也可能没有适合阅读文件的应用程序。</p>

	<p><img alt="02_Sharing" src="${require(`../../assets/img/resources/Group 1338.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>可移植性</strong></p>

	<p>用户无法打开或查看文件，造成沟通困难。</p>

	<p><img alt="03_Portability" src="${require(`../../assets/img/resources/Group 1339.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>验证</strong></p>

	<p>接收方经常发生显示或版面配置错误的状况。</p>

	<p><img alt="04_Authentic" src="${require(`../../assets/img/resources/Group 1340.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>标准化</strong></p>

	<p>大量的文件与多种文件格式，增加了管理难度。</p>

	<p><img alt="05_Standardization" src="${require(`../../assets/img/resources/Group 1341.svg`)}" /></p>
	</div>

	<div class="box6">
	<p><strong>审阅</strong></p>

	<p>审阅不同版本的文件耗费太多的时间与资源。</p>

	<p><img alt="06_Review" src="${require(`../../assets/img/resources/Group 1342.svg`)}" /></p>
	</div>
</div>

<p>&nbsp;</p>

<p class="marginB100">转换为PDF后，无论是以任何语言、任何操作系统（例如Windows、mac OS、Linux、UNIX、Android或iOS）都能维持原始文件的外观与内容；然而其他格式的文件可能因为不兼容的操作系统、缺少合适的语言包而无法正确显示内容。PDF不但拥有卓越的独立性，几乎任何计算机也都具备了阅读PDF的能力，让您不用再担心发送后文件格式不正确的问题。举例来说，您可以使用PDF/A子集来归档需长期保存的电子文件。</p>

<h1 id="p5">创建和转换PDF</h1>

<p>您可以快速从文件、图片、MS Office (Word、Excel、PowerPoint)、OpenOffice、MS Outlook创建PDF文档，反之亦然。虽然不同的文件格式各有专门用途，但就发送和存储而言，PDF不仅是创建安全的文件副本的首选，而且操作快速简单。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>以下是快速创建和转换PDF的范例：</strong></p>

<ul>
	<li class="marginB10">
	<p>用PDF软件创建PDF或从PDF转换为别的格式（例如文电通PDF转换器）。</p>
	</li>
	<li class="marginB10">
	<p>从MS Word、Excel、PowerPoint或其他支持打印的程序，通过打印的方式转换为PDF。</p>
	</li>
	<li class="marginB10">
	<p>使用MS Office插件或另存为转换当前文档。</p>
	</li>
	<li class="marginB10">
	<p>扫描文件为PDF。</p>
	</li>
	<li class="marginB10">
	<p>从MS Outlook转换电子邮件为PDF。</p>
	</li>
	<li class="marginB10">
	<p>合并、叠加或打包多个文件为一个PDF文档。</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p class="marginB100">创建PDF文档后，您便可以使用不同方式分享文档，例如电子邮件、云端服务或企业网络；反之，则可以重新利用PDF文档中的内容，省去寻找原始文件或逐字输入的麻烦。</p>

<h1 id="p6">与PDF交互</h1>

<p>在显示与打印方面PDF已经是备受信赖的文件格式，但它的另一项优点却经常被忽视──交互性。从嵌入音频、视频，到新增复选框与超链接，您拥有多种方式设计出比静态文档更能加深阅读者印象的出色PDF文档。相较于像MS Word等非专业的表单设计软件，PDF表单能展现更出色的样式，让您不需要再为了兼容性而牺牲精美的设计，正可谓两全其美。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>例如，依据您选择的PDF解决方案，可以在PDF文件中新增下列内容</strong></p>

<ul class="list2">
	<li class="marginB10">
	<p>超链接</p>
	</li>
	<li class="marginB10">
	<p>标记</p>
	</li>
	<li class="marginB10">
	<p>附件</p>
	</li>
	<li class="marginB10">
	<p>音频文件（如录音）</p>
	</li>
	<li class="marginB10">
	<p>视频文件</p>
	</li>
	<li class="marginB10">
	<p>附注</p>
	</li>
	<li class="marginB10">
	<p>按钮</p>
	</li>
	<li class="marginB10">
	<p>复选框</p>
	</li>
	<li class="marginB10">
	<p>单选按钮</p>
	</li>
	<li class="marginB10">
	<p>列表框</p>
	</li>
	<li class="marginB10">
	<p>文本域</p>
	</li>
	<li class="marginB10">
	<p>条形码</p>
	</li>
</ul>
</div>

<div class="box1 marginB100">
<p><strong>交互式表单还有这些绝佳的用途：</strong></p>

<ul>
	<li class="marginB10">
	<p>使用PDF阅读器填写表单并以电子邮件回传，不必再使用纸张。</p>
	</li>
	<li class="marginB10">
	<p>附加文件到PDF以便延伸阅读。</p>
	</li>
	<li class="marginB10">
	<p>插入录音或视频，强化您的论点。</p>
	</li>
	<li class="marginB10">
	<p>为PDF添加超链接，让阅读者能够快速打开您指定的网站。</p>
	</li>
	<li class="marginB10">
	<p>导入和导出表单数据。</p>
	</li>
</ul>
</div>

<h1 id="p7">安全性</h1>

<p>PDF是非常重视安全性的文件格式，能够使用密码、水印或加密工具保障文档安全。由于移动设备的普及与日趋严谨的隐私规范，未受保护的数据所潜藏的安全隐患也与日俱增；这些敏感数据可能白纸黑字写在文档之上，或隐藏于不为人知的元数据，随时可能成为安全漏洞。为了规避潜在的非法利用和不必要的损失，文件最好经过妥善加密，才能发送给外部人员。值得庆幸的是，PDF拥有许多专业工具，能协助您防御文件可能造成的安全隐患。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>以下是保护PDF文档的几种方式：</strong></p>

<ul>
	<li class="marginB10">
	<p><strong>打开或权限密码</strong>：设置打开密码仅允许授权用户打开，或使用权限密码限制用户能够操作的功能，如打印、复制、添加注释或提取页面等。</p>
	</li>
	<li class="marginB10">
	<p><strong>水印、页首和页尾</strong>：在页面放置图标、公司标志、水印和图片以强化视觉并增加可信度。</p>
	</li>
	<li class="marginB10">
	<p><strong>检查文件</strong>：发布文档前先删除内嵌隐藏信息，避免泄漏企业信息或侵犯个人隐私。</p>
	</li>
	<li class="marginB10">
	<p><strong>数字签名</strong>：使用加密与验证电子签名的机制，只允许收件人查看文档内容并确定文档来源。</p>
	</li>
	<li class="marginB10">
	<p><strong>密文工具/擦除</strong>：手动标记并永久移除文件内的敏感信息。</p>
	</li>
	<li class="marginB10">
	<p><strong>Microsoft AIP（Azure Information Protection）</strong>：企业可使用支持AIP的文电通PDF，确保文件只有授权人员才能存取与修改。</p>
	</li>
	<li class="marginB10">
	<p><strong>动态图章</strong>：动态图章包含各项详细信息，如作者、时间 /日期以及电子邮件地址，能够提升审核流程的效率。</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p class="marginB100">文档的作者和参与者最想确认的不外乎是让只有授权的人才能打开文档，而借由PDF拥有的安全性功能，您可以轻松、自信的处理工作或生活中各式各样的文件。</p>

<h1 id="p8">审阅文档</h1>

<p>审阅文档的方式有两种：一种是应用数字签名以确保数据的完整性与发件人的身份；另一种则是使用注释功能，使团队能快速制作文档的最终版本。对追求降低纸张依赖或提高工作效率的企业而言，PDF都是取代传统方式的最佳方案。</p>

<p>&nbsp;</p>

<h2 class="marginB20">数字签名</h2>

<p>数字签名正在逐渐取代传统的手写签名。无论是审阅文档或签署合约，这类工作通常需要从不同位置或人员反复收集签名，无形中耗费大量时间与纸张。电子签名简化了企业身份验证的过程，而且结合PDF之后不仅更加安全，而且更方便归档，以及创建具有法律效力的电子签名。</p>

<p>您可以允许用户签署 PDF却限制对方更改内容，如签署合约。若页面已被修改， 数字签名将指出修改的部份，而您也可以比较目前签署的版本与之前签署的版本差异。</p>

<p>&nbsp;</p>
<div class="part2Wrapper textAlignL">
	<div class="box1 box2">
	<p><strong>传统手写签名</strong></p>

	<ol>
		<li class="marginB10">
		<p>经由电子邮件收到文件（以合同为例）</p>
		</li>
		<li class="marginB10">
		<p>将合同储存到文件夹</p>
		</li>
		<li class="marginB10">
		<p>打印和签署合同</p>
		</li>
		<li class="marginB10">
		<p>扫描合同</p>
		</li>
		<li class="marginB10">
		<p>储存扫描的合同副本到计算机</p>
		</li>
		<li class="marginB10">
		<p>发送签署的合同给发件人</p>
		</li>
	</ol>
	</div>

	<div class="box1">
	<p><strong>数字签名</strong></p>

	<ol>
		<li class="marginB10">
		<p>打开合同</p>
		</li>
		<li class="marginB10">
		<p>在合同内放置您的签名</p>
		</li>
		<li class="marginB10">
		<p>储存合同到计算机</p>
		</li>
		<li class="marginB10">
		<p>发送签署的合同给发件人</p>
		</li>
	</ol>
	</div>
</div>

<p>&nbsp;</p>

<h2 class="marginB20">使用注释进行审阅</h2>

<p class="marginB70">与传统的审阅流程截然不同，PDF能够使审阅的过程更具交互性，更加环保。常见的解决方案通常需要某种程度的系统革新与数据库整合，不仅耗时耗力，还需要考虑安装之后长期维护的问题。相比之下，PDF不仅提供了熟悉的审阅工具如高亮提示、附注和删除线等，还能让用户分享注释并跟踪审阅状态──而这些技能简单易学，无需任何IT人员的协助。</p>

<p style="text-align:center"><img alt="Use annotations for review" src="${require(`../../assets/img/resources/use_annotations for_review_cn.jpg`)}" /></p>

<div class="box1 marginB100">
<p><strong>您可以使用这些功能来进行审阅：</strong></p>

<ul>
	<li class="marginB10">
	<p>以附注、打字机、文本框、高亮提示、插入文本或铅笔等注释工具标注PDF。</p>
	</li>
	<li class="marginB10">
	<p>附加其它文档或声音文件到PDF。</p>
	</li>
	<li class="marginB10">
	<p>跟踪注释的创建者。</p>
	</li>
	<li class="marginB10">
	<p>创建注释小结并自定义版式配置、大小和页面范围。</p>
	</li>
	<li class="marginB10">
	<p>应用水印和图章模板，并可设置旋转的角度、透明度和内容。</p>
	</li>
	<li class="marginB10">
	<p>打开注释面板列出所有注释，便于查看与跟踪。</p>
	</li>
	<li class="marginB10">
	<p>使用比较文档功能标示出版本之间的差异。</p>
	</li>
</ul>
</div>

<h1 id="p9">压缩文档</h1>

<p>PDF流行的其中一项关键因素在于减少文档大小的同时也保持质量。诸如演示文稿或报告这类包含大量图片的文件，不易保存和上传。以维修报告为例，技术人员需要在报告中导入大量图片，导致文件变大，进而影响邮件发送。使用减少文件大小功能，文件可以压缩至合适大小，更容易分享、归档和组织。</p>

<p>&nbsp;</p>

<div class="box1 marginB100">
<p><strong>减少文件大小的方式</strong></p>

<ul>
	<li class="marginB10">
	<p>使用转换器或虚拟打印机转换为PDF，例如文电通PDF套装版和Right PDF打印机，而两者均提供设置压缩等级的选项。</p>
	</li>
	<li class="marginB10">
	<p>以减少文件大小功能进一步压缩文档内的图片。您可以指定压缩等级并可应用到多个文档。</p>
	</li>
	<li class="marginB10">
	<p>使用&ldquo;检查文档&rdquo;功能删除不必要的数据，如书签、注释、链接或附件。</p>
	</li>
</ul>
</div>

<h1 id="p10">易于存取和编辑</h1>

<p>PDF的P代表可移植性，可移植性意味着网页浏览器或PDF阅读器都能正确显示文档内容。工作中会经常用到多种文件格式，如果收件人没有相同版本的应用程序，则无法查看文件。因此，新建PDF可以有效解决此类问题，您还可以通过安全设置保护PDF，避免信息泄露。</p>

<p>另一项非常重要的辅助功能则是PDF标签，它不仅能提升移动端阅读效率，还能有效协助残障人士阅读文档。总体来说，标签可以代表文档的架构，如将文本区分为标题和书签两类。PDF标签的优点是移动端的「文字回流」、更可靠的内容提取和易于搜索引擎侦测。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>主要重点</strong></p>

<ul>
	<li class="marginB10">
	<p>辅助技术（AT）的用户能利用PDF标签以更方便的阅读文档。</p>
	</li>
	<li class="marginB10">
	<p>更易于在平板、手机等移动设备上查看PDF。</p>
	</li>
	<li class="marginB10">
	<p>PDF标签加快了查阅文档的过程，提升归档的效率。</p>
	</li>
	<li class="marginB10">
	<p>将PDF转换为其他格式时，标签有助于增进转换质量。</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p>然而，作为一种理想的最终电子文档格式，PDF的盛名并非归功于编辑能力，而多数用户也只视其为输出文件的格式。在了解PDF的优势后，接下来可能的问题是：如何编辑PDF文档？如果您拥有合适的PDF解决方案，即可全盘编辑PDF的内容，为您节省宝贵的时间。</p>

<p>&nbsp;</p>
<div class="part2Wrapper marginB100 part2Wrapper2 textAlignL">
	<div class="box1 box2">
	<p><strong>典型案例</strong></p>

	<ul>
		<li class="marginB10">
		<p>打开/编辑Word文档并转换为PDF。</p>
		</li>
		<li class="marginB10">
		<p>为了编辑文档，收件人将PDF转换为Word。可能发生版面布局改变、内容丢失，或需要作者重新发送原始文档。</p>
		</li>
		<li class="marginB10">
		<p>收件人再次将文档转换为PDF。其他收件人或协作成员可能要再次重复相同步骤。</p>
		</li>
	</ul>
	</div>

	<div class="box1">
	<p><strong>使用合适的PDF软件</strong></p>

	<ul>
		<li class="marginB10">
		<p>能够直接在应用程序里创建和编辑 PDF，无需重复转换。</p>
		</li>
		<li class="marginB10">
		<p>以熟悉的界面和丰富的文本处理功能编辑PDF 内容，例如字体、编号、对齐方式和样式。</p>
		</li>
		<li class="marginB10">
		<p>插入表格、插图、链接、屏幕截图或设置页边距、页面方向和缩排。</p>
		</li>
	</ul>
	</div>
</div>

<h1 id="p11" class="marginB40">结论</h1>

<p>虽然不同的文件格式都有其特定的用途，但PDF已经证明了自身的稳定性和降低 沟通失误的能力。另外，PDF现在是由国际标准化组织（ISO）管理，并以ISO 32000-1标准所发行的一项非专利技术，不再是Adobe Systems&copy;独家拥有的文件 格式。这也就意味着您可以自由选择符合标准的PDF解决方案，而不必再受限于旧 时代的垄断产品。若想从根本提升企业的竞争力，现在正是您做出改变的时候。</p>

<p>&nbsp;</p>

<div class="box1">
<p><strong>PDF小常识：</strong></p>

<ul>
	<li class="marginB10">
	<p>Adobe于2008年释出PDF的控制权。</p>
	</li>
	<li class="marginB10">
	<p>PDF是由ISO多元化的审查委员所管理。</p>
	</li>
	<li class="marginB10">
	<p>最近发展是PDF 2.0（ISO 32000-2:2017）标准，具有更多标准化的PDF技术。</p>
	</li>
</ul>
</div>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p style="text-align:center;margin-bottom:30px;"><img src="${require(`../../assets/img/point.png`)}"/></p>

<h1 class="font20" style="text-align:center"><span style="color:#37915c">立即联系棣南，了解适合您企业的最佳PDF软件!</span></h1>

<p style="text-align:center"><a href="https://www.rightpdf.com/zh-CN/contactUs" target="_blank" style="width:210px;height:60px;background:#087A4F;display:inline-block;font-size:17px;color:#fff;line-height:60px;border-radius:4px;margin-top:40px;">联系棣南文电通</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<div id="p37">
<p><strong>关于棣南</strong></p>

<p><strong>北京棣南新宇科技有限公司正在改变用户与PDF的交互方式。迥异于常见的PDF软件，我们的产品理念侧重「自由编辑」，使用户无需经过繁复操作或寻找源文件，就能轻松创建、书写与修改PDF。文电通集编辑、直观界面、丰富功能于一体，赋予全球专业人士和企业组织无比的效率、生产力与想象力。</strong></p>

</div>
<p>&nbsp;</p>

<ul style="padding-left:20px;">
	<li>Adobe, the Acrobat logo and Acrobat are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries.</li>
	<li>Microsoft and Windows are either registered trademarks of Microsoft Corporation in the United States and/or other countries. All other trademarks are the property of their respective owners.</li>
</ul>`;
return (<div>
	<MetaTags>
	   <title>{metaTagscontent.title}</title>
	   <meta name="title" content={metaTagscontent.title} />
	   <meta name="description" content={metaTagscontent.desc} />
	   <meta property="og:title" content={metaTagscontent.title} />
	   <meta property="og:description" content={metaTagscontent.desc} />
   </MetaTags>
   <div dangerouslySetInnerHTML={{__html:viewcontent}}></div>
</div>);
}
export default SuiteCompare;
