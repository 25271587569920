const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为什么使用Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)从源文件生成的PDF文档变大了?</title></head><body>  <h1>为什么使用Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)从源文件生成的PDF文档变大了?</h1>  <h2><strong>问题描述：</strong></h2>
<p>使用转换器(Right PDF/ Gaaiho PDF Converter)产生的PDF容量变大了。 </p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>或许您没有正确地应用压缩设置。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">您可以尝试减少分辨率、修改压缩率和图像取样缩小，请参考以下方法：</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">在「<strong>一般</strong>」卷标中选择较低分辨率。缺省的600dpi可能不合适。Windows图形设备接口为高分辨率生成更多的图像数据</span></li>
<li><span class="wysiwyg-color-blue130">选择<strong>JPEG</strong>——损失图像质量以减少图像大小 </span></li>
<li><span class="wysiwyg-color-blue130">选择<strong>取样缩小</strong>(downsample)及低分辨率</span></li>
</ol>
<p><span class="wysiwyg-color-blue130">您可以同时使用以上方法来减少图像大小，可能需要多次试验以获得最佳结果。</span></p></body></html>`;   export default html;