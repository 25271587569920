import React from 'react';
import close from '../../assets/img/close.png';
import lapTop from '../../assets/img/popup/laptop.png';
import mobile from '../../assets/img/popup/mobile.png';
import {useDispatch} from 'react-redux';
const DownloadPopup = props => {
    const dispatch = useDispatch();
    const closePopup = () => {
        dispatch({
            type:'CLOSE_POPUP'
        })
    }
    const {linkObj,t,currentLang} = props;
    return (
        <div className="appDownload" style={{'top':props.scrollTop+'px'}}>
            <div className="close text-right">
                <img src={close} className="img-fluid" onClick={()=>{closePopup()}} alt="close"/>
            </div>
            <div className="customContainer">
                <div className="cRow alignStart">
                    <div className="d-50">
                        <img src={lapTop} className="img-fluid" alt="laptop"/>
                        <ul className="appdownloadlist">
                            <li>
                                <span>PC</span>
                                <a href={linkObj.downloadLink.pc.url()} target="_blank" className="downloadBtn btn dark text-white" rel="noopener noreferrer">
                                    {t('download')}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="d-50">
                        <img src={mobile} className="img-fluid" alt="mobile"/>
                        <ul className="appdownloadlist">
                            <li>
                                <span style={{minWidth:'70px'}}>iOS</span>
                                <a href={linkObj.downloadLink.ios.url('iphone')} target="_blank" className="downloadBtn btn text-white ios" rel="noopener noreferrer"> </a>
                            </li>
                            <li>
                                <span style={{minWidth:'70px'}}>Android</span>
                                <a href={linkObj.downloadLink.android.url()} target="_blank" rel="noopener noreferrer" className={'downloadBtn btn text-white '+(currentLang.type === 'zh-CN'?'wando':'android')}> </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadPopup;