const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>授权码 (序列号) 被别人绑定了?</title></head><body>  <h1>授权码 (序列号) 被别人绑定了?</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p><span>您必须使用授权绑定者的Right PDF/ Gaaiho账号登录并<a href="https://support.rightpdf.com/hc/zh-cn/articles/200947526" target="_blank" rel="noopener">解除绑定</a>，然后才能将该授权绑定到您的账号上。如果您拥有该账号对应的邮箱，您也可以先通过<a href="https://store.rightpdf.cn/account/forgot-password.aspx" target="_blank" rel="noopener">重置密码</a>的方法来登录。<br></span><br><span>根据我们的保密条款，我们不能更改任何用户账号的注册信息，如果您可以提供必要的合法文件证明您确实为该序列号的购买者，我们可以帮助您解除该序列号的已有绑定。<br></span><br><span>更多详情，请参阅我们的保密条款：<strong> </strong></span><a href="https://www.rightpdf.com/zh-CN/About/legal/privacy-statement" target="_blank" rel="noopener">Right PDF 隐私权声明</a></p></body></html>`;   export default html;