const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为何安装Gaaiho PDF转换器(Gaaiho PDF Driver)时出现错误码216？</title></head><body>  <h1>为何安装Gaaiho PDF转换器(Gaaiho PDF Driver)时出现错误码216？</h1>  <h2><strong>问题描述：</strong></h2>
<p><span>当</span>安装<span>文电通3</span>及更旧的文电通时，安装失败，并出现错误码216。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p><span>当您看到这个错误，请确定您已安装了正确的位元版本。通常，您可能是将32位的应用程序安装到了64位操作系统上了。</span></p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">解决办法很简单，请去我们的下载頁<strong>，</strong>选取正确的位元版本即可。</span></p>
<p><strong><a href="https://support.rightpdf.com/hc/zh-cn/articles/218180298" target="_blank" rel="noopener">下载頁</a></strong></p>
<p> </p>
<h3><strong><span class="wysiwyg-color-green130">延伸阅读：</span></strong></h3>
<p><a href="https://support.rightpdf.com/hc/zh-cn/articles/200834987-%E5%A6%82%E4%BD%95%E7%9F%A5%E9%81%93%E6%88%91%E7%9A%84%E7%B3%BB%E7%BB%9F%E6%98%AF32%E4%BD%8D%E8%BF%98%E6%98%AF64%E4%BD%8D-">如何知道我的系统是32位还是64位？</a></p></body></html>`;   export default html;