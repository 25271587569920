import React from 'react';
import MetaTags from 'react-meta-tags';
const SuiteCompare = props => {
	const metaTagscontent = {
		title:'瞭解您的選擇_探索全新的PDF替代方案-IT 評估指南 | 文電通 Right PDF',
		desc:'介紹7項讓專業IT人士決定以PDF文電通系列取代Adobe Acrobat的特色。'
	};
	const viewcontent =
		`<h1 id="p1">前言：瞭解您在PDF軟體上有什麼選擇</h1>

<p>自1993年由Adobe&reg;公開規格與2008年成為ISO認證的開放標準以來，PDF在電子文件的共享與歸檔上佔有舉足輕重的地位。近年，雖然多數企業仍將Adobe Acrobat&reg;與PDF畫上等號，一波新的思維正在挑戰企業對於PDF的傳統觀念。</p>

<p id="p11">如今，專業IT人士們已經意識到PDF替代方案能帶來的巨大效益。在預算、成本效益和部署方式的多重考量下，企業開始重新評估採購軟體的選項，並尋找能符合需求的解決方案。本文即是介紹7項讓專業IT人士決定以PDF文電通系列取代Adobe Acrobat的特色。</p>

<p id="p12">&nbsp;</p>

<div id="p13" style="">
	<p><strong>PDF的主要優勢</strong></p>

	<ul style="margin:0px;padding-left:20px;">
		<li>
		<p style="margin-bottom:10px;margin-top:0px;"><strong>完整性</strong>：在不同的平台、作業系統和電腦，仍能維持原始文件的配置與格式。</p>
		</li>
		<li>
		<p style="margin-bottom:10px;margin-top:0px;"><strong>安全性</strong>：支援數位簽名和密碼保護。</p>
		</li>
		<li>
		<p style="margin-bottom:10px;margin-top:0px;"><strong>較小的檔案大小</strong>：鑑於PDF格式的品質，並且轉換後的檔案通常小於原始文件，因此容易移轉。</p>
		</li>
		<li>
		<p style="margin-bottom:0px;margin-top:0px;"><strong>易於建立</strong>：除了可以透過列印的方式轉換為PDF，還能選擇合併、重疊檔案，或組合為PDF資料夾。</p>
		</li>
	</ul>
</div>

<p>&nbsp;</p>

<h1 id="p2">PDF文電通成為最佳替代方案的7個理由</h1>

<h2 id="p21">1. 易於部署與維護</h2>

<p>每當組織內部引進新軟體時，IT人員常面臨諸多抉擇與挑戰。比起部屬上耗時、耗資皆鉅的軟體產品，大多數的企業只需要一個簡單、輕盈的安裝檔案，讓他們能在短時間內完成建置任務。PDF文電通的安裝所需硬碟空間不但在700 MB左右，更可提供MSI安裝程式與常見主控環境所使用的終端伺服器授權（TSL），如Windows&reg;終端伺服器和遠端桌面連線。</p>

<p id="p15">完成軟體部署後，維持版本一致性可能是另一項令IT人員感到困擾的任務。以Acrobat的更新機制為例，每次操作僅會取代程式內的特定檔案，但可能因為不同的更新頻率、網路限制導致企業內各成員使用的版本呈現落差。相反的，PDF文電通為每個新版本提供全新的安裝程式，使IT團隊不需事先徹查內部使用情況即能更新所有軟體。透過這樣的方式，使用者既能保持版本一致，也享受到軟體最新的改良項目。</p>

<p>&nbsp;</p>

<div id="p14">
<p><strong>「PDF文電通不但簡化了航空業一貫的大量轉換與合併作業，TSL的彈性授權選擇、軟體部署方式與集中使用概念，完全符合我們的作業模式。」</strong></p>

<p style="font-size:14px;margin-bottom:0px;">長榮航太科技 (EGAT) 電算部</p>
</div>

<p>&nbsp;</p>

<h2 id="p22">2. 微軟SharePoint和AD RMS</h2>

<p id="p16">現今的企業通常仰賴微軟SharePoint等軟體追蹤、儲存及保護大量文件；而在講究效率的工作流程中，具備整合能力的PDF解決方案扮演著重要的角色。PDF文電通讓您能夠在Windows平台開啟及編輯存放在SharePoint的檔案，並持續記錄不同使用者建立的不同版本。此外，專業版還支援微軟AD RMS，透過權限政策來防止文件遭到未經授權的存取，將資訊安全充分融合至文件管理流程中。</p>

<p>&nbsp;</p>

<h2 id="p23">3. 迅速上手的使用者介面</h2>

<p>PDF文電通採用易於學習與操作的簡潔UI設計，且有鑑於MS Office全球使用者總人數突破12億，您的使用者極可能非常熟悉Word、Excel或PowerPoint的「Ribbon」工具列操作方式。透過PDF文電通，使用者不需要重新適應非直觀的軟體介面以及複雜的功能，並於最短時間投入工作之中。</p>

<p>以最少的操作找到正確的功能是提升工作生產力的捷徑，而PDF文電通的UI提供無可比擬的靈活性，讓您不費吹灰之力就能找到需要的功能。除了「尋找工具」搜尋欄位，您還可以在程式頂端的快速存取工具列自訂常用功能，並可隨時新增或移除。另外，左側面板能夠自由移動位置，讓每位使用者量身訂做自己的工作環境，避免耗費過多時間學習適應軟體。</p>

<p id="p17">PDF文電通也是唯一主張從零開始書寫PDF文件的解決方案，其獨一無二、特別設計的編輯模式將提供您前所未見的自由編輯能力，包括隨心所欲的變更字型、段落、樣式、圖片與文字對齊等項目。與其為了修改文件而反覆的轉換檔案，您可以只使用一個應用程式完成編輯，不再需要擔心轉換後潛在的格式錯誤或失真。</p>

<p>&nbsp;</p>

<p style="text-align:center"><img alt="editing and review 1" src="${require(`../../assets/img/resources/editing_and_review_1_tw.jpg`)}" /></p>

<p style="text-align:center"><img alt="editing and review 2" src="${require(`../../assets/img/resources/editing_and_review_1_tw.jpg`)}" /></p>

<p id="p18" style="text-align:center">Right PDF Editor介面：透過兩種轉換模式進行PDF的編輯與審閱</p>

<p>&nbsp;</p>

<p id="p19">除了轉換PDF為可編輯的狀態或Excel之外，文電通還提供了OCR(光學字元識別)功能，可迅速確實的轉換影像中的文字為可搜尋的PDF文件。您不必再為掃描版的文件煩惱，或是翻閱大量的文件尋找資料了──有了OCR，紙本文件不再是影像，而是真正可以編輯的文件。</p>

<p>&nbsp;</p>

<div id="p31">
<p><strong>「我們不僅需要能快速佈署的解決方案，還須以較低成本取得強大的PDF功能，而PDF文電通是個絕佳選擇。」</strong></p>

<p style="font-size:14px;margin-bottom:0px;">雅特生科技 IT經理&nbsp;Nelson Lui</p>
</div>

<p>&nbsp;</p>

<h2 id="p24">4. 棣南嚴選的實用功能</h2>

<p id="p33">棣南文電通以超過29年的經驗與技術，用合理的價格為企業提供可靠、精實的解決方案；相反地，A牌廠商的產品卻讓企業為了多數使用者完全不需要的專業功能買帳。儘管不同產業對PDF有不同的需求，其核心價值仍是一些必要功能，例如編輯、轉換和註解。PDF文電通讓使用者能快速達成任務，而不必反覆橫越繁複的功能表與意義不明的功能。現在就重新審視您真正的需求，提高軟體的投資報酬率！</p>

<p>&nbsp;</p>

<div id="p32">
<p><strong>「PDF文電通長期提供非常多實用功能以及合理價格，不論對於工作效率與成本控制，都有很大的幫助。」</strong></p>

<p style="font-size:14px;margin-bottom:0px;">浦項工程建設公司 經理&nbsp;Ham Ju Han</p>
</div>

<p>&nbsp;</p>

<h2 id="p25">5. 超越桌面的生產力</h2>

<p id="p34">行動裝置的興起創造了跨平台互動與檢視PDF的需求，而知識工作者受到的影響尤其深遠。因此，APP是否具備友善介面與便捷功能實為左右生產力的關鍵。估且不論出色的顯示速度，免費的PDF文電通閱讀器APP支援在iOS和Android裝置上進行PDF作業，例如註釋、填寫表單和切換檢視模式等多項功能。PDF文電通擁有的跨平台服務，包括提供免費轉檔、加密、浮水印服務等的PDF文電通線上版（Right PDF Online）網站，將協助您的生產力更上一層樓。</p>

<p>&nbsp;</p>

<h2 id="p26">6. 速度就是效率</h2>

<p>採用PDF文電通，企業能夠以卓越的速度和精準度處理鉅量的文件。文件密集型企業中的使用者常要開啟、編輯和建立大量的檔案，因此速度在工作流程中是至關重要的一環。對個人用戶而言差異或許不是非常顯著，但對例行處理上千筆文件與大量工作流的企業，處理速度累計的加成效果卻能節省可觀的時間、空間和費用。依據棣南最近的測試顯示，PDF文電通不但占用的CPU資源少，更在轉換速度與減少檔案大小上一致表現優異。再輔以批次處理功能，您將能得到集速度與高效於一身的檔案轉換利器。</p>

<p>&nbsp;</p>

<h2 style="text-align:center;font-size:20px;margin-bottom:25px;">PDF文電通與A牌廠商的主要統計</h2>

<div id="p35">
	<div>
		<p class="font18"><strong>轉換後檔案大小</strong></p>
		<p class="font30 position"><span style="color:#35a562"><strong>少10.2%</strong></span></p>
	</div>
	<div>
		<p class="font18"><strong>轉換速度</strong></p>
		<p class="font13">Word/Excel/PPT轉換為PDF</p>
		<p class="font30"><span style="color:#35a562"><strong>快40.5%</strong></span></p>
	</div>
	<div>
		<p class="font18"><strong>CPU使用量</strong></p>
		<p class="font13">Word/Excel/PPT轉換為PDF</p>
		<p class="font30"><span style="color:#35a562"><strong>少40%</strong></span></p>
	</div>
</div>

<p style="text-align:center;font-size:13px;">*資料來源：內部測試結果</p>

<p>&nbsp;</p>

<p>此外，您可以透過PDF管理員掃描電腦的PDF檔案並進行全盤管理。除了迅速搜尋到正確的文件，您還能與原始複本進行同步刪除、移動，或開啟及編輯文件，在簡化個人的PDF文件管理之餘，進而提高全體的生產力。</p>

<p>&nbsp;</p>

<div id="p36">
<p><strong>「PDF文電通提高了轉換後的檔案格式正確率，減少了修改時間。而Server的集中部署模式不但減少IT耗費在軟體維護和管理的時間，還降低了採購成本。」</strong></p>

<p style="font-size:14px;margin-bottom:0px;">中央研究院 / 計算中心</p>
</div>

<p>&nbsp;</p>

<h2 id="p27">7. 價格與支援</h2>

<table cellspacing="0" style="border-spacing:0; height:114px; width:100%;border: 1px solid #C4C4C4;border-radius: 6px;border-collapse:separate;">
	<tbody>
		<tr>
			<td style="height:78px; text-align:center; width:14.2285%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;">&nbsp;</td>
			<td style="height:78px; text-align:center; width:28.2024%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;background:#087A4F;color:#fff;">文電通6 專業版</td>
			<td style="height:78px; text-align:center; width:28.4934%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;background:#EFEFEF;">Acrobat Standard 2020</td>
			<td style="height:78px; text-align:center; width:29.0757%;border-bottom: 1px solid #C4C4C4;background:#EFEFEF;">
			<p>Acrobat Pro 2020 (盒裝)</p>
			</td>
		</tr>
		<tr>
			<td style="height:18px; text-align:center; width:14.2285%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;">永久授權</td>
			<td style="height:18px; text-align:center; width:28.2024%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;background:#DFF9E5;color:#37915C;">NTD&nbsp;<strong class="strong30">5,800</strong></td>
			<td style="height:18px; text-align:center; width:28.4934%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;">
			<p>NTD&nbsp;<strong class="strong30">24,950</strong><br />
			&nbsp;<span style="font-size:14px;">(盒裝)</span></p>
			</td>
			<td style="height:18px; text-align:center; width:29.0757%;border-bottom: 1px solid #C4C4C4;">
			<p>NTD&nbsp;<strong class="strong30">37,107</strong><br />
			<span style="font-size:14px;">(盒裝)</span></p>
			</td>
		</tr>
		<tr>
			<td style="height:18px; text-align:center; width:14.2285%;border-right: 1px solid #C4C4C4;">訂閱版</td>
			<td style="height:18px; text-align:center; width:28.2024%;border-right: 1px solid #C4C4C4;background:#DFF9E5;color:#37915C;">
			<p>NTD&nbsp;<strong class="strong30">325</strong><br />
			<span style="font-size:14px;">(每月/年付)</span></p>
			</td>
			<td style="height:18px; text-align:center; width:28.4934%;border-right: 1px solid #C4C4C4;">
			<p>NTD&nbsp;<strong class="strong30">420</strong><br />
			<span style="font-size:14px;">(每月/年付)</span></p>
			</td>
			<td style="height:18px; text-align:center; width:29.0757%">
			<p>NTD&nbsp;<strong class="strong30">641</strong><br />
			<span style="font-size:14px;">(每月/年付)</span></p>
			</td>
		</tr>
	</tbody>
</table>

<p>&nbsp;</p>

<p>當企業評估軟體的時候，IT人員往往需要權衡採購成本與其帶來的效益，嘗試在預算之內讓員工都能使用軟體。相比之下，PDF文電通能以合適價位將PDF豐富完整的功能延伸到組織內的每一個角落。比起採用不同品牌的產品來滿足預算限制，您可以使用PDF文電通一舉解決所有使用者的PDF需求。與Acrobat Standard and Pro 2020的建議售價相比，使用PDF文電通能分別省下約77%和84%購買永久授權的費用，而且您甚至能經由大量採購省下更多成本。另外，文電通也提供了訂閱版的選項，而且價格更加經濟實惠。</p>

<p style="margin-bottom:0px;">根據Zendesk的評估報告，文電通在最近（2022年7月）100個客戶所提交的問題上達到了93.82%的客戶滿意度。個人或企業使用者能隨時於社群和支援中心尋找解答，或透過線上提問的方式尋求技術支援。PDF文電通另外還提供軟體維護服務，於有效訂閱期內使用者可獲得優先的技術支援，以及免費的版本升級服務。</p>

<p>&nbsp;</p>

<h1 id="p3">結論</h1>

<p>無論是政府部門或私營企業，選擇結合價值與效能的PDF解決方案已是必要的考量。過去能選擇的PDF軟體有限，企業往往需要付出高額費用方能大規模部署；然而隨著企業採購的新觀念於各行各業間逐步擴散，您也該瞭解到您可以選擇更節省時間、成本與精力的軟體，並在激烈競爭中取得先機。</p>

<p>在棣南，我們致力於提供能滿足不同規模企業的PDF軟體。從一般的電腦軟體授權、免費的PDF閱讀器、遠端環境到網路服務與伺服器級轉換工具，PDF文電通完整的PDF功能以及靈活的授權方式，讓您可以依照需求選擇，而不必再妥協於昂貴的解決方案。在PDF軟體展開全新篇章之際，現在正是您重新抉擇的時刻！</p>

<p>&nbsp;</p>

<p style="text-align:center;margin-bottom:30px;"><img src="${require(`../../assets/img/point.png`)}"/></p>

<h1 class="font20" style="text-align:center"><span style="color:#37915c">立即聯絡棣南，瞭解適合您企業的最佳PDF軟體!</span></h1>

<p style="text-align:center;"><a href="https://www.rightpdf.com/zh-TW/contactUs" target="_blank" style="width:210px;height:60px;background:#087A4F;display:inline-block;font-size:17px;color:#fff;line-height:60px;border-radius:4px;margin-top:40px;">聯絡我們</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<div id="p37">
<p style="font-size:20px;"><strong>關於棣南</strong></p>

<p style="margin:0px;"><strong>棣南股份有限公司正在改變使用者與PDF的互動方式。迥異於常見的PDF軟體，我們的產品理念側重「自由編輯」，令使用者不須經過繁複操作或尋找原始檔案，就能輕鬆創造、書寫與修改PDF文件。文電通集編輯、直覺式介面、豐富功能於一身，賦予全球專業人士和企業組織無比的效率、生產力與想像力。</strong></p>
</div>
<p id="p38">&nbsp;</p>

<ul style="font-size:13px;padding-left:20px;">
	<li>Adobe, the Acrobat logo and Acrobat are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries.</li>
	<li>Microsoft and Windows are either registered trademarks of Microsoft Corporation in the United States and/or other countries. All other trademarks are the property of their respective owners.</li>
</ul>

<p style="font-size:13px;">© 2022 ZEON Corporation. All rights reserved.</p>`;
return (<div>
	<MetaTags>
	   <title>{metaTagscontent.title}</title>
	   <meta name="title" content={metaTagscontent.title} />
	   <meta name="description" content={metaTagscontent.desc} />
	   <meta property="og:title" content={metaTagscontent.title} />
	   <meta property="og:description" content={metaTagscontent.desc} />
   </MetaTags>
   <div dangerouslySetInnerHTML={{__html:viewcontent}}></div>
</div>);
}
export default SuiteCompare;
