const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我的文电通套装版编辑器的菜单栏不见了？</title></head><body>  <h1>我的文电通套装版编辑器的菜单栏不见了？</h1>  <h2><strong>问题描述：</strong></h2>
<p>打开某一份PDF文件时，上方「菜单栏」没有显示。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (16).png" alt="mceclip0 (16).png"></p>
<h2> </h2>
<h2><strong>原因：</strong></h2>
<p>原作者可能隐藏了「初始视图」设定底下的「菜单栏」选项，请至「文挡属性」中设定。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决办法：</strong></span></h2>
<ol>
<li>
<span class="wysiwyg-color-blue130">选择右侧「文挡属性」</span><br><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/mceclip1 (13).png" alt="mceclip1 (13).png" width="728" height="65"></span>
</li>
<li>
<span class="wysiwyg-color-blue130">选择「初始视图」&gt; 取消勾选隐藏下的「菜单栏」&gt;「确定」</span><br><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (14).png" alt="mceclip2 (14).png" width="457" height="519"></span>
</li>
</ol>
<p><span class="wysiwyg-color-blue130"> 此份PDF文件的「功能列表」将会显示。</span></p></body></html>`;   export default html;