import React, {useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import mixinWrapper from '../../hoc/mixinWrapper';
import Company from './company';
import Legal from './legal';
import Media from './media';
import CaseStudies from './caseStudies';
import BecomePartner from './becomePartner';

const AboutPage = props => {
    let history = useHistory();
    const {t,currentLang,i18n} = props;
    const {lang,pathId} = useParams();


    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    const pathmap = {
        aboutus:'1',
        media:'3',
        caseStudies:'4',
        legal:'5',
        becomePartner:'6'
    };
    const renderPage = () => {
        if(currentLang.type === 'kr' && pathId === 'becomePartner'){
            return <BecomePartner {...props} />
        }else if(currentLang.type !== 'kr' && pathId === 'becomePartner'){
            history.push("/");
        }else{
            switch(pathId){
                case 'aboutus':
                    return <Company  {...props}/>
                case 'legal':
                    return <Legal  {...props}/>
                case 'media':
                    return <Media  {...props}/>
                case 'caseStudies':
                    return <CaseStudies {...props} />
                default:
                    history.push(`/${currentLang.type}`);
                    return null
            }
        }
    }
    return (
        <section id="about" className="whiteBg">
            <div className="infoTitle  grayBg-seashell">
                <div className="customContainer paddingWrap">
                    <div className="cRow maxW1300 padding-0">
                        <h1 className="font-weight-normal title_style">
                            {t('AboutZeon.link'+pathmap[pathId])}
                        </h1>
                    </div>
                </div>
            </div>
            <div className="customContainer paddingWrap">
                <div className="cRow maxW1300 padding-0">
                    <div className="d-100">
                        {renderPage()}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default mixinWrapper(AboutPage);