const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>文电通转换器(Right PDF Converter)和文电通转换服务器(Right PDF Server)，哪个更加适合我们公司？</title></head><body>  <h1>文电通转换器(Right PDF Converter)和文电通转换服务器(Right PDF Server)，哪个更加适合我们公司？</h1>  <p><strong>「<a href="https://rightpdf.com/zh-CN/converter" target="_self" rel="undefined">文电通转换器</a>(Right PDF Converter)」</strong>是单机版的PDF转换器，即每台电脑需要一个授权。仅适应于手动操作转换。<br><br>如果您的公司使用转换服务的员工较多，则可以选择「<strong>文电通<a href="https://rightpdf.com/zh-CN/server" target="_blank" rel="noopener">转换服务器</a>(Right PDF Server)」</strong>，其不限制使用服务的人数，只需选择合适的每小时转换量档位，即可满足您所有员工的转换需求。有灵活的交互方式，既可以用于个人交互，也可以用于无人操作的服务器端。<br><br>另外，如果您需要在您的系统里内嵌PDF的转换服务，请选择<strong>「文电通<a href="https://rightpdf.com/zh-CN/server" target="_blank" rel="noopener">转换服务器</a>(Right PDF Server)」</strong>，服务器版软件支持接口调用，并在网站有现成的<a href="https://www.rightpdf.com/zh-CN/Resources/sdkResources/main" target="_blank" rel="noopener">SDK说明文件</a>提供。</p>
<p> </p>
<h2><strong>转换服务器主要功能：</strong></h2>
<table style="border-collapse: collapse; width: 100%;" border="0">
<tbody>
<tr>
<td style="width: 33.3333%;"><img src="https://store.rightpdf.com/images/rtsupport/610051.png" alt="610051.png"></td>
<td style="width: 33.3333%;"><img src="https://store.rightpdf.com/images/rtsupport/610052.png" alt="610052.png"></td>
<td style="width: 33.3333%;"><img src="https://store.rightpdf.com/images/rtsupport/610053.png" alt="610053.png"></td>
</tr>
<tr>
<td style="width: 33.3333%;">
<p><strong>客户端/服务器结构</strong></p>
</td>
<td style="width: 33.3333%;">
<p><strong>监视文件夹</strong></p>
</td>
<td style="width: 33.3333%;">
<p><a href="https://www.rightpdf.com/zh-CN/Resources/sdkResources/main" target="_blank" rel="noopener">提供SDK资源</a></p>
</td>
</tr>
<tr>
<td style="width: 33.3333%;">利用浅显易懂的转换接口，用户只需要打开客户端程序并连上服务器，即可开始转换任务。</td>
<td style="width: 33.3333%;">所有放在监视文件夹中的文件将自动被转换为PDF文档。您也能为数个监视文件夹创建不同的转换设置或是自定义转换时间。</td>
<td style="width: 33.3333%;">PDF文电通转换服务器的功能可由Windows API所实现，且具有完整的文档与范例原码。开发人员可以藉由修改原码，在数天内整合PDF服务器，供文本任务为主的工作流程与企业用软件运用。</td>
</tr>
</tbody>
</table></body></html>`;   export default html;