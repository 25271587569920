const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何卸载文电通PDF套装版/转换器？</title></head><body>  <h1>如何卸载文电通PDF套装版/转换器？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p><font>请在卸载之前先做<strong>反激活</strong>操作（</font>文电通3及以上产品在卸载时，会自动反激活授权）<font>。</font></p>
<p>使用 Right PDF 产品，请至 <font>开始 &gt; 所有程序 &gt; Right PDF Pro/ Right PDF Converter&gt;<strong>「授权信息」</strong></font></p>
<p><font> 使用 Gaaiho PDF产品，请至 开始 &gt; 所有程序 &gt; Gaaiho &gt; Gaaiho PDF Suite / Gaaiho PDF Converter &gt; <strong>「授权信息」</strong>，选择 </font> <strong>「反激活」</strong> <font>。</font> <br><br>执行如下步骤：</p>
<p><span class="wysiwyg-color-blue130"><font>1. 选择「控制面板」 &gt; 「添加/删除程序」 &gt; 「卸载程序」 </font> </span><br><span class="wysiwyg-color-blue130">2. 选择「<font>Right PDF Pro</font>」/「<font>Right PDF Converter</font>」或「Gaaiho PDF Suite」/「Gaaiho PDF Converter」</span><br><span class="wysiwyg-color-blue130">3. 选择「卸载」开始卸载</span><br><span class="wysiwyg-color-blue130">4. 单击「完成」，成功完成卸载</span></p>
<p> </p>
<p> </p>
<h3><span class="wysiwyg-color-green130">延伸閱讀：</span></h3>
<ul>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/203435577-%E5%9C%A8%E5%8D%B8%E8%BD%BD%E7%A8%8B%E5%BA%8F%E6%97%B6%E5%8F%8D%E6%BF%80%E6%B4%BB%E5%A4%B1%E8%B4%A5%E6%80%8E%E4%B9%88%E5%8A%9E-" target="_blank" rel="noopener">在卸载程序时反激活失败怎么办？</a></li>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/4462412310425" target="_self" rel="undefined">如何在线/离线激活 文电通6 (Right PDF)</a></li>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/360018510912-%E5%A6%82%E4%BD%95%E5%9C%A8%E7%BA%BF-%E7%A6%BB%E7%BA%BF%E5%8F%8D%E6%BF%80%E6%B4%BB-%E6%96%87%E7%94%B5%E9%80%9A-5-Gaaiho-PDF-5-" target="_blank" rel="noopener">如何在线/离线反激活 文电通 5 (Gaaiho PDF 5)?</a></li>
</ul></body></html>`;   export default html;