const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何将文电通更新到最新版本?</title></head><body>  <h1>如何将文电通更新到最新版本?</h1>  <p><span class="wysiwyg-color-red"><strong>注意：</strong></span> 文电通针对同一版本进行免费更新(例:从5.0更新到5.1)。如果是要升级到最新的版本(从5.x到6.x)，我们提供升级的优惠方案，请洽<a href="https://store.rightpdf.cn/buy/upgrade-add-user.aspx" target="_self" rel="undefined">升级与加购页面</a>购买。</p>
<p> </p>
<p><strong>文电通</strong><strong>套装</strong><strong>版</strong><strong>/</strong><strong>转换器</strong><strong>/</strong><strong>服务器</strong> <strong>用户，可以用以下两种方法更新。</strong></p>
<ol>
<li>开启<strong>Right PDF Editor </strong>&gt; <strong>帮助</strong><strong>标签 </strong>&gt; 点选<strong>检查更新</strong>。<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip5 (4).png" alt="mceclip5 (4).png" width="577" height="166">
</li>
</ol>
<ol start="2">
<li>开启<strong>Right PDF </strong><strong>Converter </strong>&gt; 点选<strong>支持 </strong>&gt; 选择<strong>检查更新</strong>。<br>Right PDF Converter 6:<br><img src="https://store.rightpdf.com/images/rtsupport/mceclip6 (4).png" alt="mceclip6 (4).png" width="581" height="403"><br><br>
</li>
</ol>
<p><strong>针对要升级</strong> <strong>文电通</strong><strong>套装</strong><strong>版</strong><strong>/</strong><strong>转换器</strong><strong>/</strong><strong>服务器</strong><strong> 5</strong><strong>以及更旧版本的用户</strong></p>
<p>请至<a href="https://store.rightpdf.com/download/archive.aspx" target="_self" rel="undefined">下载页</a>下载安装您所购买的最新版本。</p></body></html>`;   export default html;