const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何在线/离线反激活 文电通PDF macOS?</title></head><body>  <h1>如何在线/离线反激活 文电通PDF macOS?</h1>  <h2><span class="wysiwyg-color-blue130 "><strong>在线反激活：</strong></span></h2>
<p><span class=" wysiwyg-color-blue130">1. 开启文电通产品，点选左上角「Right PDF」&gt; 「授权信息」。</span></p>
<div>
<div><span style="color: #000066;">2. 点击「反激活」开始反激活程序</span></div>
</div>
<p><img src="https://store.rightpdf.com/images/rtsupport/510031.png" alt="510031.png"></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">3. 选择「在线」确定，开始反激活过程</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/510032.png" alt="510032.png"></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">4. 等待完成即可</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/510033.png" alt="510033.png"></p>
<h2><span class="wysiwyg-color-blue130 "><strong>离线反激活：</strong></span></h2>
<div>
<div>
<p><span class=" wysiwyg-color-blue130">1. 开启文电通产品，点选左上角「Right PDF」&gt; 「授权信息」。</span></p>
<div>
<div><span style="color: #000066;">2. 点击「反激活」开始反激活程序</span></div>
</div>
</div>
</div>
<p><img src="https://store.rightpdf.com/images/rtsupport/510034.png" alt="510034.png"></p>
<p> </p>
<p><span class="wysiwyg-color-blue130">3. 选择「离线」，将激活数据另存为一个扩展名为「html」的文件</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/510035.png" alt="510035.png"></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/510036.png" alt="510036.png" width="80" height="70"></p>
<p><br><span class="wysiwyg-color-blue130">4. 将您刚才所保存的「激活数据」文件复制到<span class="wysiwyg-underline">任意一台能连接到因特网的计算机上</span>，并以浏览器打开此文件</span></p>
<p> </p>
<p><span class=" wysiwyg-color-blue130">5. 在打开的网页文件中点击「上传」按钮</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/510037.png" alt=""><br><span class=" wysiwyg-color-blue130">6. 离线反激活完成<br><img src="https://store.rightpdf.com/images/rtsupport/510038.png" alt="510038.png"><br></span></p></body></html>`;   export default html;