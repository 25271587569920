const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>激活授权时出现错误代码「0x20020101」？</title></head><body>  <h1>激活授权时出现错误代码「0x20020101」？</h1>  <h2><strong>问题描述：</strong></h2>
<p>激活授权时出现了错误代码「0x20020101」，授权激活失败。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>激活数据出现问题，导致授权激活失败，请参考以下解决方法。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<h3><span class="wysiwyg-color-blue130"><strong>方法一：</strong></span></h3>
<p><span class="wysiwyg-color-blue130">请尝试使用<strong><a href="https://support.rightpdf.com/hc/zh-cn/articles/4462193952793" target="_blank" rel="noopener">离线激活</a></strong>。</span></p>
<p> </p>
<h3><span class="wysiwyg-color-blue130"><strong>方法二：</strong></span></h3>
<ol>
<li><span class="wysiwyg-color-blue130">卸载您的文电通PDF(Right PDF/ Gaaiho PDF)软件</span></li>
<li><span class="wysiwyg-color-blue130">重新开机</span></li>
<li>
<span class="wysiwyg-color-blue130">删除路径底下的文件夹（若看不到隐藏文件夹，请详下方说明）</span><br><span class="wysiwyg-color-blue130">(Windows 7)<strong>「[systemdrive]:\\ProgramData\\Zeon\\RightPDF </strong><em>(Gaaiho)</em><strong>\\ZNSL」</strong>与<strong>「[systemdrive]:\\Program Files (x86)\\Common Files\\ZEON」<br></strong>(Windows 10)：<strong>「[systemdrive]:\\ProgramData\\Zeon\\RightPDF</strong> (<em>Gaaiho)</em><strong>\\ZNSL」與「[systemdrive]:\\Program Files (x86)\\Common Files\\ZEON」與「[systemdrive]:\\用户\\[用户名]\\AppData\\Roaming\\Zeon\\RightPDF</strong> <em>(Gaaiho)</em><strong>\\ZNSL」</strong></span>
</li>
<li><span class="wysiwyg-color-blue130"><strong>重新安装文电通</strong><strong>PDF</strong></span></li>
<li><span class="wysiwyg-color-blue130">用同序列号激活产品。如果已经达到激活次数上限，请参阅<strong><a href="(Windows%207)%E3%80%8C%5Bsystemdrive%5D:%5CProgramData%5CZeon%5CRightPDF%20(Gaaiho)%5CZNSL%E3%80%8D%E4%B8%8E%E3%80%8C%5Bsystemdrive%5D:%5CProgram%20Files%20(x86)%5CCommon%20Files%5CZEON%E3%80%8D%20(Windows%2010)%EF%BC%9A%E3%80%8C%5Bsystemdrive%5D:%5CProgramData%5CZeon%5CRightPDF%20(Gaaiho)%5CZNSL%E3%80%8D%E8%88%87%E3%80%8C%5Bsystemdrive%5D:%5CProgram%20Files%20(x86)%5CCommon%20Files%5CZEON%E3%80%8D%E8%88%87%E3%80%8C%5Bsystemdrive%5D:%5C%E7%94%A8%E6%88%B7%5C%5B%E7%94%A8%E6%88%B7%E5%90%8D%5D%5CAppData%5CRoaming%5CZeon%5CRightPDF%C2%A0(Gaaiho)%5CZNSL%E3%80%8D" target="_self" rel="undefined">这篇文章</a></strong>。</span></li>
</ol>
<p> </p>
<p><span class="wysiwyg-color-blue130">* <strong>ProgramData</strong>和<strong>AppData</strong>文件夹为隐藏文件夹，欲显示隐藏文件夹，请至「检视」 &gt; 勾选「隐藏的项目」。</span></p>
<p> </p>
<p> </p>
<p> </p></body></html>`;   export default html;