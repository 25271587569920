const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何删除文件内的敏感性资料？</title></head><body>  <h1>如何删除文件内的敏感性资料？</h1>  <h2><span class="wysiwyg-color-green120"><strong>敏感资料包含哪些内容？</strong></span></h2>
<p><span class="wysiwyg-color-black">一份文件包含不同等级的敏感性资料。常见的如书签、注释、链接或附件，这些资料通常包含关键字、结构性文字或导向特定目标。较隐密的如审阅过程记录、修订或变更记录、作者、审阅者、部门信息、服务器或网络名称，以及已删除的资料等，这些个人或公司内部的信息若外流，可能会造成个人隐私或公司内部工作流程的公开，影响公司的运营或声誉。</span></p>
<p> </p>
<h2><span class="wysiwyg-color-green120"><strong>分享文件前应考虑的事项</strong><strong>……</strong></span></h2>
<p><span class="wysiwyg-color-black">我们建议在分享文件前先考虑分享的对象、文件内容以及发送途径，便能降低不慎外流资料的风险。</span></p>
<p><span class="wysiwyg-color-cyan120"><strong>对象：</strong>信任的对象、特定的对象、一般大众</span><br><span class="wysiwyg-color-cyan120"><strong>内容：</strong>机密/隐藏信息、个人/公司信息、可复制或修改的内容</span><br><span class="wysiwyg-color-cyan120"><strong>途径：</strong>经过加密的管道、免费软件、个人/公共云端</span></p>
<p> </p>
<h2><span class="wysiwyg-color-green120"><strong>棣南提供的解决方案</strong> <strong>– </strong><strong>使用</strong><strong>PDF</strong><strong>文电通</strong><strong>专业版移除各种敏感资料！</strong></span></h2>
<p><span class="wysiwyg-color-black">PDF文电通3专业版支持移除敏感资料。通过「检查文档」功能，您可以选择要保留或删除的信息，确保公司内部的信息、个人信息以及敏感、重要的信息不会公开。</span></p>
<p><span class="wysiwyg-color-cyan120"><strong style="color: #0000ff;">建议移除的资料：</strong>已删除内容、元数据、其他内嵌的隐藏数据</span><br><span class="wysiwyg-color-cyan120"><strong style="color: #0000ff;">考虑保留的资料：</strong>注释、书签、附件、目标、表单域逻辑与动作、JavaScript、链接</span><br><span class="wysiwyg-color-cyan120"><strong style="color: #0000ff;">建议保留的资料：</strong>内嵌缩略图</span></p>
<p> </p>
<p><span class="wysiwyg-color-black">如需了解更多信息，请访问<strong><a href="https://www.rightpdf.com/zh-CN" target="_blank" rel="noopener">www.rightpdf.com</a></strong>或联系<strong><a href="mailto:sales@rightpdf.com" target="_self" rel="undefined">sales@rightpdf.com</a></strong></span></p></body></html>`;   export default html;