const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>文电通（Right PDF）品牌更名政策已于2022年5月生效</title></head><body>  <h1>文电通（Right PDF）品牌更名政策已于2022年5月生效</h1>  <p>想必大家应该已经注意到崭新的<a href="https://www.rightpdf.com/zh-CN" target="_self">官方网站</a>，以及软件中众多重新设计后的图标吧？这篇文章将会帮助大家了解这一次品牌更名政策的影响范围与来龙去脉。</p>
<p>首先要提的一点是，本次更名的核心意义在于<strong>用新的品牌统一棣南公司分布在各平台上的PDF产品</strong>。如下图所示，<strong>文电通（Right PDF）</strong>的旗下拥有Windows、macOS、Android、iOS、<a href="https://online.rightpdf.com/" target="_self">在线版</a>的PDF产品，功能与取向各不相同，打造棣南PDF科技的完整生态体系，而未来我们也会继续带来更多产品与服务。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/Products_all.png" alt="Products_all.png"></p>
<h2>品牌更名的范围与影响</h2>
<p>这一次品牌更名的影响范围仅限英文，也就是说「文电通」这一块招牌没有变化，变化的只有从原英文品牌名称 Gaaiho 改成现在的 Right PDF。只要 Gaaiho 或 Gaaiho PDF 出现的地方，如网站、文宣、软件界面等，都已经一律替换为 Right PDF （例外情况是与旧版直接相关的FAQ、教学与下载链接等）。</p>
<p>如果您是旧版用户，请不必担心此举会损害您既有的权益。就像普通的改版一样，您依然可以从文电通第五代升级到第六代，只是新版的英文名称不同而已。除了更名之外，其余网站功能与客户服务维持不变，而且我们已经尽可能设置好自动跳转链接，避免造成您太大的困扰。</p>
<p>新官网：<a href="https://www.rightpdf.com" target="_self">https://www.rightpdf.com</a><br>购买网站：<a href="https://store.rightpdf.cn" target="_self">https://store.rightpdf.cn</a><br>客户服务：<a href="https://support.rightpdf.com" target="_self">https://support.rightpdf.com</a></p>
<h2>介绍影片</h2>
<p><span>bilibili：<a href="https://www.bilibili.com/video/BV1C54y1d7rE?spm_id_from=333.999.0.0" target="_blank" rel="noopener">https://www.bilibili.com/video/BV1C54y1d7rE?spm_id_from=333.999.0.0 </a><br>YouTube:</span></p>
<h2>更名的幕后故事</h2>
<p>那么，为何我们会变更英文品牌名称呢？说来有趣， Gaaiho 这个名字源自我们过去推出的企业协作与物联网平台，与PDF可谓风马牛不相及。后来，我们将PDF产品线也放入这个平台，才让他的英文名称变成了 Gaaiho。</p>
<p>随着企业协作与物联网方面项目的告一段落和策略变更，我们决定为PDF品牌换一个更适合的名字，于是经过内部多次讨论，就在2022年5月实施这一次的品牌更名政策。</p>
<p>虽然我们对 Gaaiho 这个来自阿美族族语的名字难免有些依依不舍，但还是希望能在新的 Right PDF 延续“向世界说哈啰”的精神，为各地的人们与企业提供好用、实惠的PDF软件。</p>
<p>另外，有没有人觉得新版的logo长得很像尼斯湖水怪呢？</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/RightPDF_logo_300x300.png" alt="RightPDF_logo_300x300.png"></p></body></html>`;   export default html;