const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>无法在本页执行OCR，因为包含可渲染/可转译文字。</title></head><body>  <h1>无法在本页执行OCR，因为包含可渲染/可转译文字。</h1>  <h2><strong>问题描述：</strong></h2>
<p>当执行OCR 光学字符识别时，出现错误讯息：</p>
<p>于Right PDF Editor:「Right PDF 无法在本页执行OCR，因为包含可渲染文字。」或</p>
<p>于Gaaiho Doc:「Gaaiho Doc无法辨识此页面(OCR)，因为：这个页面含有可转译的文字。」</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p><span class="">您的PDF 文件已含有可编辑文字 或 水印等。可转译文字为 PDF 档案中可编辑的文字。文电通 无法在内含可转译文字的文件上执行 OCR。</span></p>
<p> </p>
<h2><span class="wysiwyg-color-blue120"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue120"><strong>解决方法</strong><strong> 1: </strong><strong>取得未含可编辑文字的文件版本 或 将可编辑部分移除。<br></strong>如果 PDF 文件已含可编辑文字，便会显示此讯息。请取得未含可编辑文字的文件副本 或 将可编辑部分移除。<strong><br><br></strong><strong>解决方法</strong><strong> 2: </strong><strong>将</strong><strong> PDF </strong><strong>转换为图像文件，再转换回</strong><strong> PDF</strong><strong>，然后重新执行</strong><strong> OCR</strong><strong>。</strong></span></p>
<ol>
<li>
<span class="wysiwyg-color-blue120"><strong>将</strong><strong> PDF </strong><strong>转换为图像文件：</strong><strong><br></strong><strong>以Right PDF Editor/ </strong><strong>Gaaiho Doc</strong><strong>将文件转换为图像文件：</strong><strong><br></strong>以Right PDF Editor/ Gaaiho Doc开启PDF文件，选择「档案 &gt; 导出」，选择影像格式 (如：JPEG, JPEG PNG, TIFF)，「另存新檔」；</span><br><span class="wysiwyg-color-blue120">或</span><br><span class="wysiwyg-color-blue120">以Right PDF Editor/ Gaaiho Doc开启PDF文件，选择「档案 &gt; 打印」，勾选「打印为影像」，「确定」。</span><br><br><span class="wysiwyg-color-blue120"><strong>以Right PDF Converter/ </strong><strong>Gaaiho PDF Converter</strong><strong>将文件转换为图像文件：</strong><strong><br></strong>开启Right PDF Converter/ Gaaiho PDF Converter，选择「从PD转换 &gt; 转换为影像」，选择「输出方式」 (如：JPEG, JPEG PNG, TIFF)，「开始转换」。</span>
</li>
<li>
<span class="wysiwyg-color-blue120"><strong>转换回</strong><strong> PDF</strong><strong>：</strong><strong><br></strong><strong>以Right PDF Editor/ </strong><strong>Gaaiho Doc</strong><strong>将影像转换为</strong><strong>PDF</strong><strong>：</strong><strong><br></strong>直接以Right PDF Editor/ Gaaiho Doc开启图像文件</span><br><br><span class="wysiwyg-color-blue120"><strong>以Right PDF Converter/ </strong><strong>Gaaiho PDF Converter</strong><strong>将影像转换为</strong><strong>PDF</strong><strong>：</strong><strong><br></strong>开启Right PDF Converter/ Gaaiho PDF Converter，选择「转换为PDF &gt; 转换」，选择图像文件，「开始转换」。</span>
</li>
<li><span class="wysiwyg-color-blue120"><strong>将新产出的</strong><strong>PDF</strong><strong>文件，重新执行</strong><strong>OCR</strong></span></li>
</ol>
<p> </p></body></html>`;   export default html;