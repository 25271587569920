const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>文电通PDF套装版订阅版(Right PDF Subscription)要如何退款？</title></head><body>  <h1>文电通PDF套装版订阅版(Right PDF Subscription)要如何退款？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。请记得更新您的网址与email地址。</span></p>
</td>
</tr>
</tbody>
</table>
<p><span>如果您是买错了授权，请在发现出错时候立刻与 <span><strong><a href="mailto:sales@rightpdf.com" target="_self" rel="undefined">sales@rightpdf.com</a></strong></span></span><span>联系。</span></p>
<p>详细规范，请参照我们的<strong><a href="https://www.rightpdf.com/zh-CN/About/legal/terms-of-use" target="_blank" rel="noopener">退款政策</a></strong>。</p></body></html>`;   export default html;