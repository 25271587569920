const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为何转换之后边距改变了？</title></head><body>  <h1>为何转换之后边距改变了？</h1>  <h2><strong>问题描述：</strong></h2>
<p>转换成PDF档之后，边界与原始文件设定不同。<span class=" wysiwyg-color-cyan130"><br></span></p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">通常PDF转换会完全遵从原始文档的设定，如果边距改变幅度太大，请检查以下设置：</span><br><br></p>
<div><span class=" wysiwyg-color-blue130"><strong>1. <span class="wysiwyg-underline">Right PDF属性</span></strong></span></div>
<div><span class=" wysiwyg-color-blue130">有几种方式可以打开<strong>「Right PDF属性」</strong>对话方块，其中之一如下：前往打印机列表，右击「Right PDF（或Gaaiho PDF/ DocuCom PDF Driver，如果您是旧版用户的话）」，选取<strong>「打印首选项」</strong>，然后检查<strong>页面尺寸</strong>、<strong>打印比例</strong>（默认为<strong>100</strong>）等设置。</span></div>
<div>
<img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (18).png" alt="mceclip2 (18).png" width="509" height="529"><br><span class=" wysiwyg-color-blue130"><img alt=""><img title="Image: /hc/en-us/article_attachments/200267746/Gaaiho_PDF__.JPG" src="https://support.rightpdf.com/hc/en-us/article_attachments/200267746/Gaaiho_PDF__.JPG" alt=""></span><br><br>
<div><span class=" wysiwyg-color-blue130"><strong>2. <span class="wysiwyg-underline">Microsoft Word选项</span></strong></span></div>
<div><span class=" wysiwyg-color-blue130">在<strong>Word 2003</strong>中，前往「<strong>工具</strong>」并选择「<strong>选项</strong>」。移至「<strong>打印</strong>」标签页，并取消选中「<strong>允许重调调A4/Letter纸型</strong>」。</span></div>
<div>
<span class=" wysiwyg-color-blue130">在<strong>Word 2010</strong>中，前往「<strong>文件</strong>」并选择「<strong>选项</strong>」。移至「<strong>高级</strong>」并在「<strong>打印</strong>」标签下找到「<strong>缩放内容以适应A4或8.5×11“纸张大小</strong>」。取消选中这个选项并查看有没有帮助。</span><br><br><span class="">如果上述的解決方案沒有效果，请在我们的<span class="wysiwyg-color-blue90"><a title="Link: http://support.gaaiho.com" href="http://support.rightpdf.com/" target="_self" rel="undefined">客戶服务网站</a></span>提交请求，并附上原始文档作为附件(如果可能的话)。我们將尽快帮您解決问题。</span>
</div>
</div>
<p><span class=""> </span></p></body></html>`;   export default html;