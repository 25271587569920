import React,{useState,useEffect,useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ReactComponent as Earth} from '../assets/img/Path.svg';
import { withRouter } from "react-router";
import { useSelector, useDispatch} from 'react-redux';
import {getFeatureType} from '../api';
import { useHistory, useLocation } from "react-router-dom";

const LanguageSelector = props => {
    const history = useHistory();
    const locationRoute = useLocation();
    // const { t,i18n } = useTranslation();
    const { i18n,location} = props;
    let currentLang = useSelector(state => state[0].currentLang);
    const langList = useSelector(state => state[0].langList);
    const [show,setShow] = useState(false);
    const toggleShow = e => {
        e.stopPropagation();
        if(!show){
            setShow(true);
        }else{
            setShow(false);
        }
    }
    const hideSelect = () => {
        setShow(false);
    }

    const selectCurrentLang = (e,type) =>  {
        changeLang(type);
        toggleShow(e);
    }

    let renderList = langList.map((el,i)=> <li key={i} onClick={(e)=>{selectCurrentLang(e,el.type)}}><span>{el.name}</span></li>);

    const dispatch = useDispatch();
     
    let changeLang = value => {
        i18n.changeLanguage(value);
        const newUrl = locationRoute.pathname.replace(currentLang.type, value);
        history.push(newUrl);
    }
    let onloadCheckLanguage = lang =>{
        let returnlang = '';
        langList.forEach(e => {
            if(e.type === lang){
                returnlang = e;
            }
        })
        loadFeatureType(lang);
        dispatch({
            type:'SEND_CURRENTLANG',
            lang:returnlang
        })

    }
    const loadFeatureType = cLang =>{
        //const res = ''
        let lang = 'EN';
        if(cLang === 'zh-TW'){
            lang = 'CT';
        }else if(cLang === 'zh-CN'){
            lang = 'CS';
        }else{
            lang = cLang.toUpperCase();
        }
        getFeatureType({LgeID:lang}).then(function (response) {
            dispatch({
                type:'SEND_TYPELIST',
                typeListAry:response.data.List
            })
        })
        .catch(function (error) {
            console.log('err',error);
        });
    }
    const firstUpload = useRef(true);
    const usePrevious = value =>{
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const preLocation  = usePrevious(location);
    useEffect(()=>{
        // var userLang = navigator.language || navigator.userLanguage;
        // console.log('userLang',userLang);
        if(firstUpload.current){
            firstUpload.current = false;
            onloadCheckLanguage(i18n.language);
            window.addEventListener('click',hideSelect)
            //loadFeatureType(i18n.language);
            return;
        }
        if(i18n.language !== props.currentLang.type){
            onloadCheckLanguage(i18n.language);
            window.scrollTo(0, 0);
        }
        if(location.pathname !== preLocation.pathname){
            window.scrollTo(0, 0);
        }
        //初始變更head title
        // document.title = t('webTitle');
        return function removeClickHide(){
            window.removeEventListener('click',hideSelect);
        }
    })

    return (
		currentLang.type !== 'zh-CN' ? 
        <div className="muiltLangBlock">
            <ul className={"langList "+(show?'active':'')}>{renderList}</ul>
             <Earth  width="24" height="24" viewBox="0 0 24 24" />
             <input type="button" id="langSelect" className="btn" value={currentLang.name} onClick={(e)=>{toggleShow(e)}} />
             <FontAwesomeIcon  icon="chevron-down" className={'fa-sm arrow '+(show?'active':'')}/>
        </div>
		: ""
    );
}

export default withRouter(LanguageSelector);