import React,{useState} from 'react';
import { useDispatch} from 'react-redux';
import CompareTableBlock from '../../components/compare/compareTableBlock';
import MetaTags from 'react-meta-tags';

const ProductTableBody = props => {
    const {t,linkObj,currentLang} = props;
    const dispatch = useDispatch();
    const  openDownloadPopup = () => {
        dispatch({
            type:'OPEN_POPUP',
            content:'download'
        })
    }
    const renderPrice = type =>{
        switch(currentLang.type){
            case 'zh-TW':
                return (type === 'converter')?'NT$ 3,000':'NT$ 5,800'
            case 'zh-CN':
                return (type === 'converter')?'RMB 500':'RMB 1250'
           default :
                return (type === 'converter')?'US$ 99':'US$ 179'
        }
    }
    return (
        <tbody>
            <tr>
                <td>{t('price')}</td>
                <td>{t('free')}</td>
                <td>{renderPrice('converter')}</td>
                <td>{renderPrice('suite')}</td>
            </tr>
            <tr>
                <td>{t('download')}</td>
                <td>
                    <button className="cBtn w_text greenBg" onClick={()=>{openDownloadPopup()}}>
                        {t('home.compare.btn1')}
                    </button>
                </td>
                <td>
                    <a href={linkObj.downloadLink.converter.url()} target="_blank" className="cBtn w_text greenBg" rel="noopener noreferrer">
                        {t('home.compare.btn2')}
                    </a>
                </td>
                <td>
                    <a href={linkObj.downloadLink.suite.url()} target="_blank" className="cBtn w_text greenBg" rel="noopener noreferrer">
                        {t('home.compare.btn2')}
                    </a>
                </td>
            </tr>
            <tr>
                <td>{t('supportSystem')}</td>
                <td>{t('system')}</td>
                <td>{t('system')}</td>
                <td>{t('system1')}</td>
            </tr>
        </tbody>
    )
}

const ProductCompare = props => {
    const {t} = props;
    const [selectList,setSelectList] = useState('');
    const pageDataObj = {
            info:{
                t1:t('resources.productCompare.name'),
                t2:t('resources.productCompare.info')
            },
            blockTitle:[
                {
                    t1:t('GaaihoPDF'),
                    t2:t('home.compare.table.th1'),
                    img:'right_reader'
                },
                {
                    t1:t('GaaihoPDF'),
                    t2:t('home.compare.table.th2'),
                    img:'right_driver'
                },
                {
                    t1:t('GaaihoPDF'),
                    t2:t('home.compare.table.th3'),
                    img:'right_suite'
                }
            ],
            blockList:[
                {
                    info:t('comparePage.organizeWithPdf.title'),
                    listAry:[
                        {
                            info:t('comparePage.organizeWithPdf.t1'),
                            td1:'○',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.organizeWithPdf.t2'),
                            td1:'●',td2:'─',td3:'◎'
                        },
                        {
                            info:t('comparePage.organizeWithPdf.t3'),
                            td1:'●',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.organizeWithPdf.t9'),
                            td1:'●',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.organizeWithPdf.t10'),
                            type:'newFunction',
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.organizeWithPdf.t5'),
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.organizeWithPdf.t6'),
                            td1:'●',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.organizeWithPdf.t7'),
                            td1:'○',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.organizeWithPdf.t8'),
                            td1:'○',td2:'─',td3:'●'
                        }
                    ]
                },
                {
                    info:t('comparePage.createPdf.title'),
                    listAry:[
                        {
                            info:t('comparePage.createPdf.t1'),
                            td1:'─',td2:'●',td3:'●',td4:t('comparePage.createPdf.t19')
                        },
                        {
                            info:t('comparePage.createPdf.t2'),
                            td1:'─',td2:'●',td3:'●',td4:t('comparePage.createPdf.t20')
                        },
                        {
                            info:t('comparePage.createPdf.t15'),
                            type:'newFunction',
                            td1:'─',td2:'●',td3:'●',td4:t('comparePage.createPdf.t20')
                        },
                        {
                            info:t('comparePage.createPdf.t3'),
                            td1:'─',td2:'●',td3:'●'
                        },
                        {
                            info:t('comparePage.createPdf.t4'),
                            td1:'─',td2:'●',td3:'●'
                        },
                        {
                            info:t('comparePage.createPdf.t5'),
                            td1:'─',td2:'○',td3:'◎'
                        },
                        {
                            info:t('comparePage.createPdf.t10'),
                            td1:'─',td2:'○',td3:'●'
                        },
                        {
                            info:t('comparePage.createPdf.t11'),
                            td1:'─',td2:'○',td3:'●'
                        },
                        {
                            info:t('comparePage.createPdf.t12'),
                            td1:'─',td2:'─',td3:'◎'
                        }
                    ]
                },
                {
                    info:t('comparePage.editPdf.title'),
                    listAry:[
                        {
                            info:t('comparePage.editPdf.t1'),
                            type:'improve',
                            td1:'○',td2:'○',td3:'●'
                        },
                        {
                            info:t('comparePage.editPdf.t2'),
                            type:'improve',
                            td1:'○',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.editPdf.t3'),
                            td1:'─',td2:'─',td3:'◎'
                        },
                        {
                            info:t('comparePage.editPdf.t4'),
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.editPdf.t5'),
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.editPdf.t6'),
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.editPdf.t7'),
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.editPdf.t8'),
                            td1:'─',td2:'○',td3:'●'
                        },
                        {
                            info:t('comparePage.editPdf.t10'),
                            type:'improve',
                            td1:'─',td2:'○',td3:'●'
                        },
                        {
                            info:t('comparePage.editPdf.t12'),
                            type:'newFunction',
                            td1:'○',td2:'─',td3:'●'
                        }
                    ]
                },
                {
                    info:t('comparePage.sign.title'),
                    listAry:[
                        {
                            info:t('comparePage.sign.t1'),
                            td1:'●',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.sign.t2'),
                            td1:'●',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.sign.t3'),
                            td1:'●',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.sign.t4'),
                            td1:'●',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.sign.t5'),
                            td1:'●',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.sign.t6'),
                            td1:'○',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.sign.t7'),
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.sign.t8'),
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.sign.t9'),
                            td1:'─',td2:'─',td3:'●'
                        }
                    ]
                },
                {
                    info:t('comparePage.protectPdf.title'),
                    listAry:[
                        {
                            info:t('comparePage.protectPdf.t1'),
                            td1:'○',td2:'○',td3:'●'
                        },
                        {
                            info:t('comparePage.protectPdf.t2'),
                            td1:'─',td2:'○',td3:'●'
                        },
                        {
                            info:t('comparePage.protectPdf.t3'),
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.protectPdf.t7'),
                            type:'improve',
                            td1:'○',td2:'○',td3:'●'
                        },
                        {
                            info:t('comparePage.protectPdf.t4'),
                            type:'improve',
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.protectPdf.t5'),
                            type:'improve',
                            td1:'─',td2:'─',td3:'●'
                        },
                        {
                            info:t('comparePage.protectPdf.t6'),
                            td1:'─',td2:'─',td3:'●'
                        }
                    ]
                },
                {
                    info:t('comparePage.deploy.title'),
                    listAry:[
                        {
                            info:t('comparePage.deploy.t1'),
                            td1:'●',td2:'●',td3:'◎'
                        },
                        {
                            info:t('comparePage.deploy.t2'),
                            td1:'─',td2:'●',td3:'◎'
                        },
                        {
                            info:t('comparePage.deploy.t3'),
                            td1:'─',td2:'─',td3:'●'
                        }
                    ]
                }
            ]
       }
       const selectBlockShowHide = (info,active) => {
            pageDataObj.blockList.forEach((e,i)=>{
                if(info === e.info){
                    if(active){
                        setSelectList('');
                    }else{
                        setSelectList(e.info);
                    }
                }
           })
       }

       const renderBlockList = array => {
           let returnli = null;
           returnli = array.map((e,i)=>{
               return (
                <CompareTableBlock {...props} dataObj={e} key={i} MenuSelect={selectBlockShowHide} selectList={selectList} active={e.info === selectList} />
               )
           })
           return returnli;
       }
    return (
        <div className="compareContent">
            <MetaTags>
                <title>{t('metaTag.productCompare.title')}</title>
                <meta name="title" content={t('metaTag.productCompare.title')} />
                <meta name="description" content={t('metaTag.productCompare.desc')} />
                <meta name="keywords" content={t('metaTag.productCompare.keywords')} />
                <meta property="og:title" content={t('metaTag.productCompare.title')} />
                <meta property="og:description" content={t('metaTag.productCompare.desc')} />
            </MetaTags>
            <div className="productCompareInfo productCompareInfoWrap">
                <h2 className="font-weight-bold">{pageDataObj.info.t1}</h2>
                <p>{pageDataObj.info.t2}</p>
            </div>
            <div className="compareTableBlock">
                <table className="compareTable title">
                    <thead>
                        <tr>
                            <th></th>
                            {
                                pageDataObj.blockTitle.map((e,i)=>{
                                    return (
                                        <th key={i}>
                                            <div className="imgWrap">
                                                <img src={require(`../../assets/img/${e.img}.png`)} className="img-fluid" alt="caseImg" />
                                            </div>
                                            <span>{e.t1}</span>
                                            <h2 className="font-weight-light">{e.t2}</h2>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <ProductTableBody {...props} />
                </table>
            </div>
            {
                renderBlockList(pageDataObj.blockList)
            }
            <div className="compareTableNote">
                <span className="d-inline-block mr-3">
                    ●：{t('comparePage.note.support')}
                </span>
                <span className="d-inline-block mr-3">
                    ◎：{t('comparePage.note.supportMac')}
                </span>
                <span className="d-inline-block mr-3">
                    ○：{t('comparePage.note.supportApart')}
                </span>
                <span className="d-inline-block">
                    ─：{t('comparePage.note.unsupport')}
                </span>
            </div>
        </div>
    )
}

export default ProductCompare;