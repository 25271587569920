const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为何我碰到「已经到达此时段允许的最大可转换文件数或页数/Limit is exceeded」？</title></head><body>  <h1>为何我碰到「已经到达此时段允许的最大可转换文件数或页数/Limit is exceeded」？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。请记得更新您的网址与email地址。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>问题描述：</strong></h2>
<ul>
<li>弹出式错误讯息：「已经到达此时段允许的最大可转换文件数或页数，请稍后再试。」</li>
<li>一个笔记本文件弹出，内述<strong>「Limit is exceeded」</strong>
</li>
</ul>
<p> </p>
<h2>
<strong>原因</strong>：</h2>
<p>以下有两个可能的原因： </p>
<ol>
<li><font size="" color=""> <strong>达到转换上限</strong>：文电通转换器(Right PDF Converter/ Gaaiho PDF Converter)皆可能出现这个信息，其原因是它有每天最高100个文件或1000页的转换上限。如果您需要更大的转换量，我们建议您采用<strong><a href="https://store.rightpdf.cn/store/overview.aspx" target="_blank" rel="noopener">Right PDF转换服务器(Right PDF Server)</a></strong>或<strong><a href="https://store.rightpdf.cn/store/overview.aspx" target="_blank" rel="noopener">转换器的终端服务器-转换上限授权(Right PDF Converter TSL per-capacity)</a></strong><br></font></li>
<li><font size="" color=""> <strong>系统时间遭到更改</strong>：您不小心更改了<span class="wysiwyg-color-red"><strong>系统时间</strong></span> </font></li>
</ol>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<ol>
<li><span class="wysiwyg-color-blue130"> <font size=""> <span class="wysiwyg-color-blue130"><strong>达到转换上限：</strong></span>根据我们的经验，这种事件发生的概率极低。如果您真的已经转换了巨量的文件，请等候24小时后再使用产品。<strong><br></strong> </font> </span></li>
<li><span class="wysiwyg-color-blue130"> <font size=""> <strong>系统时间遭到更改：</strong> </font> 只是把系统时间恢复正常并无法解除锁定。您必须下载并执行附件中的文件<strong>「RemoveTransLimit.exe」</strong>。执行之后，点击「RemoveTransLimit」按钮然后再点击「OK」，这个程序将自动卸载，随后请您重新安装并激活。 </span></li>
</ol>
<p> </p>
<p><span class="wysiwyg-color-black ">若有更多问题，我们也欢迎您在这个客服网站提交问题或联系<strong><a href="mailto:support@rightpdf.com%20" target="_self" rel="undefined">support@rightpdf.com</a></strong>，谢谢！</span></p></body></html>`;   export default html;