const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我激活产品时达到了授权上限，请问该怎么办 (错误代码12)？</title></head><body>  <h1>我激活产品时达到了授权上限，请问该怎么办 (错误代码12)？</h1>  <div>
<table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>問題描述： </strong></h2>
<p>启动产品失败，错误讯息授权已达上限，错误代码12。</p>
</div>
<div> </div>
<h2><span class="wysiwyg-color-black "><strong>原因：</strong></span></h2>
<div><span class="wysiwyg-color-black ">这代表着您所有的授权份数都用完了。</span></div>
<div> </div>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<div><span class="wysiwyg-color-blue130">如果您想要换到另外一台电脑使用，请先在一台电脑上反激活产品；若您需要增加用户数，请购买更多授权(<a href="https://support.rightpdf.com/hc/zh-cn/articles/200876517" target="_self" rel="undefined">永久授权</a>/ <a href="https://support.rightpdf.com/hc/zh-cn/articles/201184073" target="_self" rel="undefined">訂閱版</a>)。</span></div>
<div> </div>
<div><span class="wysiwyg-color-blue130">反激活(<a href="https://support.rightpdf.com/hc/zh-cn/articles/4462412310425" target="_self">Right PDF</a>/ <a href="https://support.rightpdf.com/hc/zh-cn/articles/360018510912-%E5%A6%82%E4%BD%95%E5%9C%A8%E7%BA%BF-%E7%A6%BB%E7%BA%BF%E5%8F%8D%E6%BF%80%E6%B4%BB-%E6%96%87%E7%94%B5%E9%80%9A-5-Gaaiho-PDF-5-" target="_blank" rel="noopener">Gaaiho</a>)步骤如下： </span></div>
<div><span class="wysiwyg-color-blue130">1. 前往开始 &gt; 所有程序 &gt; Right PDF Pro/ Right PDF Converter (Gaaiho &gt; Gaaiho PDF Suite) &gt; 「授权信息」</span></div>
<div><span class="wysiwyg-color-blue130">2. 点击「反激活」按钮。如果并未激活，则这个按钮会显示为「激活」</span></div>
<div><span class="wysiwyg-color-blue130">3. 到新电脑上输入序列号并激活</span></div>
<div><span class="wysiwyg-color-black "> </span></div>
<div>如果您的电脑发生了故障，比如说硬盘损坏、系统损坏或任何事件让你无法正常反激活产品，请参考:</div>
<ul>
<li><a style="background-color: #ffffff;" href="https://support.rightpdf.com/hc/zh-cn/articles/200884858" target="_blank" rel="noopener">如何在线清除激活记录？</a></li>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/900001028146-%E5%A6%82%E4%BD%95%E8%A7%A3%E5%86%B3%E6%97%A0%E6%B3%95%E6%89%8B%E5%8A%A8%E5%8F%8D%E6%BF%80%E6%B4%BB%E6%96%87%E7%94%B5%E9%80%9A%E8%BD%AF%E4%BB%B6%E6%8E%88%E6%9D%83%E9%97%AE%E9%A2%98-%E4%BC%81%E4%B8%9A%E6%8E%88%E6%9D%83%E7%AE%A1%E7%90%86-" target="_blank" rel="noopener">企业许可证管理（10套以上的永久授权）</a></li>
</ul></body></html>`;   export default html;