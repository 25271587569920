const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为何我克隆(clone)硬盘后无法激活产品了？</title></head><body>  <h1>为何我克隆(clone)硬盘后无法激活产品了？</h1>  <h2><strong>问题描述：</strong></h2>
<div>克隆(clone)硬盘后无法激活产品了。</div>
<div> </div>
<h2><strong>原因：</strong></h2>
<div>我们了解<span class="wysiwyg-color-red"><strong>克隆(clone)</strong></span> 硬盘在一些情况下是非常好用的，但很遗憾的是我们软件为了保护授权， <font size="">其激活机制被设计为不允许在曾经安装过同样产品的克隆</font> 后 <font size="">硬盘上激活。</font> 对此情况，我们非常抱歉！</div>
<div><span class="wysiwyg-color-black "> </span></div>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<div>
<span class="wysiwyg-color-blue130">如果您已经克隆了硬盘并希望再度使用我们的产品，请使用附件的「<strong>Remove Tool</strong>」来清除硬盘中的相关信息并重新尝试激活。请按照以下步骤进行：</span><br><br>
</div>
<div><span class=" wysiwyg-color-blue130"><strong>[DocuCom PDF的用户]</strong></span></div>
<div>
<div><span class=" wysiwyg-color-blue130">1. 请反激活并卸载电脑上所有文电通产品(<strong>如果产品未激活或无法正常反激活，请略过</strong>)</span></div>
<div><span class=" wysiwyg-color-blue130">2. 卸载文电通*</span></div>
<div><span class=" wysiwyg-color-blue130">3. 重新启动电脑</span></div>
<div><span class=" wysiwyg-color-blue130">4. 前往这个路径「<span class="wysiwyg-color-black70"><strong>%SystemDrive%\\Documents and Settings\\All Users\\Application Data\\</strong></span>」（在Windows 7、Windows 8、Vista上则是「<span class="wysiwyg-color-black70"><strong>%SystemDrive%\\ProgramData</strong>」</span>），然后删除「Zeon」文件夹</span></div>
<div><span class=" wysiwyg-color-blue130">5. 重新安装软件，最后使用您的序列号激活<br><br>如果您无法通过正常的方式卸载软件（控制面板 &gt; 程序 &gt; 卸载程序，选取「<strong>DocuCom PDF Gold *.x</strong>」然后卸载），请使用附件中的「<strong>Remove Tool</strong>」替换上述的步骤2*。</span></div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class=" wysiwyg-color-blue130"><strong>[Right PDF/<span> </span>Gaaiho PDF的用户]</strong></span></div>
</div>
<div>
<div><span class=" wysiwyg-color-blue130">1. 请反激活并卸载电脑上所有文电通产品(<strong>如果产品未激活或无法正常反激活，请略过</strong>)</span></div>
<div><span class=" wysiwyg-color-blue130">2. 卸载文电通*</span></div>
<div><span class=" wysiwyg-color-blue130">3. 重新启动电脑</span></div>
<div><span class=" wysiwyg-color-blue130">4. 前往这个路径「<span class="wysiwyg-color-black70"><strong>%SystemDrive%\\ProgramData」，然后删除「Zeon」文件夹。</strong></span></span></div>
</div>
<div><span class=" wysiwyg-color-blue130"></span></div>
<div><span class=" wysiwyg-color-blue130">如果您无法通过正常的方式卸载软件（<span>应用和功能，</span>选取<strong><span class="wysiwyg-color-black70">「Right PDF Pro」/「Gaaiho PDF Suite」</span></strong>然后卸载），请使用附件中的「<strong>Remove Tool</strong>」替换上述的步骤2*。</span></div>
<div><span class=" wysiwyg-color-blue130"></span></div>
<div></div>
<div><span class="wysiwyg-color-black ">如果有关于激活/反激活的问题，请参考以下的链接。</span></div>
<div><span class="wysiwyg-color-green130"><strong><span class="wysiwyg-color-black "><a title="Link: https://support.gaaiho.com/hc/zh-cn/sections/200220228-%E6%BF%80%E6%B4%BB-%E5%8F%8D%E6%BF%80%E6%B4%BB%E4%B8%8E%E8%AF%95%E7%94%A8" href="https://support.rightpdf.com/hc/zh-cn/sections/900000832786-%E6%BF%80%E6%B4%BB-%E5%8F%8D%E6%BF%80%E6%B4%BB%E4%B8%8E%E8%AF%95%E7%94%A8" target="_self" rel="undefined">激活、反激活与试用</a></span><br><br></strong></span></div></body></html>`;   export default html;