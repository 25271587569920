import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import MetaTags from 'react-meta-tags';

const RemoteEnterprise = props => {
    const {t,linkObj} = props;
    let ScrollLink = Scroll.Link;
    return (
        <div className="compareContent">
            <MetaTags>
                <title>{t('metaTag.remoteEnterprise.title')}</title>
                <meta name="title" content={t('metaTag.remoteEnterprise.title')} />
                <meta name="description" content={t('metaTag.remoteEnterprise.desc')} />
                <meta name="keywords" content={t('metaTag.remoteEnterprise.keywords')} />
                <meta property="og:title" content={t('metaTag.remoteEnterprise.title')} />
                <meta property="og:description" content={t('metaTag.remoteEnterprise.desc')} />
            </MetaTags>
            <div className="productCompareInfo productCompareInfoWrap">
                <h2 className="font-weight-bold">{t('comparePage.remoteEnterprise.title')}</h2>
                <p>{t('comparePage.remoteEnterprise.t1')}</p>
            </div>
            <br/>
            <br/>
            <div className="scrollTableWrap">
                <table className="remoteEnterpriseTable">
                    <thead>
                        <tr>
                            <th rowSpan="2">
                                {t('comparePage.remoteEnterprise.licenseType')}
                            </th>
                            <th rowSpan="2" colSpan="2">
                                <h3>{t('comparePage.remoteEnterprise.table.th1')}</h3>
                            </th>
                            <th colSpan="3">
                                <h3>{t('comparePage.remoteEnterprise.table.th2')}</h3>
                            </th>
                            <th rowSpan="2">
                                <h3 className="m-0">{t('comparePage.remoteEnterprise.table.th3')}</h3>
                                <Link to={`/${props.currentLang.type}${linkObj.product.suite.path}`}>
                                    {t('learnMore')}
                                </Link>
                            </th>
                            <th rowSpan="2" className='noRBorder'>
                                <h3>{t('comparePage.remoteEnterprise.table.th4')}</h3>
                            </th>
                        </tr>
                        <tr>
                            <th className="font-weight-light">{t('comparePage.remoteEnterprise.table.th2-1')}</th>
                            <th className="font-weight-light">{t('comparePage.remoteEnterprise.table.th2-2')}</th>
                            <th className="font-weight-light">{t('comparePage.remoteEnterprise.table.th2-3')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('comparePage.remoteEnterprise.suitable')}</td>
                            <td colSpan="2">{t('comparePage.remoteEnterprise.table.td1')}</td>
                            <td>{t('comparePage.remoteEnterprise.table.td2')}</td>
                            <td>{t('comparePage.remoteEnterprise.table.td3')}</td>
                            <td>{t('comparePage.remoteEnterprise.table.td4')}</td>
                            <td>{t('comparePage.remoteEnterprise.table.td5')}</td>
                            <td className='noRBorder'>{t('comparePage.remoteEnterprise.table.td6')}</td>
                        </tr>
                        <tr>
                            <td>{t('comparePage.remoteEnterprise.teamSize')}</td>
                            <td colSpan="2">
                                {t('comparePage.remoteEnterprise.table.td7').split('_')[0]}<br/>
                                <ScrollLink to="vlp" offset={-200} smooth={true}>{t('comparePage.remoteEnterprise.table.td7').split('_')[1]}</ScrollLink>
                            </td>
                            <td colSpan="3">
                                {t('comparePage.remoteEnterprise.table.td8')}
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td9')}
                            </td>
                            <td className='noRBorder'>
                                {t('comparePage.remoteEnterprise.table.td10')}
                            </td>
                        </tr>
                        <tr>
                            <td>{t('comparePage.remoteEnterprise.installWay')}</td>
                            <td colSpan="2">
                                {t('comparePage.remoteEnterprise.table.td11')}
                            </td>
                            <td colSpan="3">
                                {t('comparePage.remoteEnterprise.table.td12').split("_")[0]}<br/>
                                {t('comparePage.remoteEnterprise.table.td12').split("_")[1]}<br/>
                                {t('comparePage.remoteEnterprise.table.td12').split("_")[2]}
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td13').split("_")[0]}<br/>
                                {t('comparePage.remoteEnterprise.table.td13').split("_")[1]}<br/>
                                {t('comparePage.remoteEnterprise.table.td13').split("_")[2]}
                            </td>
                            <td rowSpan="3" className='noRBorder'>
                                {t('comparePage.remoteEnterprise.table.td14')}<br/>
                                <ScrollLink to="siteLicense" offset={-200} smooth={true}> {t('learnMore')}</ScrollLink>
                            </td>
                        </tr>
                        <tr>
                            <td>{t('comparePage.remoteEnterprise.licenseCount')}</td>
                            <td colSpan="2">
                                {t('comparePage.remoteEnterprise.table.td15')}
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td16')}
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td17')}
                            </td>
                            <td>
                                <p className="font-weight-bold text-left m-0">{t('comparePage.edit')}：</p>
                                <p className="text-left">{t('comparePage.remoteEnterprise.table.td18').split("_")[0]} </p>
                                <br/>
                                <p className="font-weight-bold text-left m-0">{t('comparePage.conversion')}：</p>
                                <p className="text-left">{t('comparePage.remoteEnterprise.table.td18').split("_")[1]}</p>
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td19')}
                            </td>
                        </tr>
                        <tr>
                            <td>{t('comparePage.remoteEnterprise.coversionCapacity')}</td>
                            <td colSpan="2">
                                {t('comparePage.remoteEnterprise.table.td20')}
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td20')}
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td21')}
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td20')} 
                                {t('comparePage.remoteEnterprise.table.td22')}
                            </td>
                            <td>
                                {t('comparePage.remoteEnterprise.table.td21')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3 className="font-weight-normal">{t('home.compare.table.th3')}</h3>
                                <Link to={`/${props.currentLang.type}${linkObj.product.suite.path}`}  >{t('learnMore')}</Link>
                            </td>
                            <td style={{textAlign:'center',minWidth:"120px"}}>
                                {t('comparePage.remoteEnterprise.table.td23_1')}<br/>
								{t('comparePage.remoteEnterprise.table.td23_2')}<br/>
								{t('comparePage.remoteEnterprise.table.td23_3')}
                            </td>
                            <td style={{textAlign:'center',minWidth:"130px"}}>
                                {t('comparePage.remoteEnterprise.table.td24_1')}<br/>
                                {t('comparePage.remoteEnterprise.table.td24_2')}<br/>
                                {t('comparePage.remoteEnterprise.table.td24_3')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td25_1')}<br/>
                                {t('comparePage.remoteEnterprise.table.td25_2')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td26')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td27_1')}<br/>
                                {t('comparePage.remoteEnterprise.table.td27_2')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td26')}
                            </td>
                            <td className='noRBorder' style={{textAlign:'center'}}>
                                {t('contact.link1')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3 className="font-weight-normal">{t('home.compare.table.th2')}</h3>
                                <Link to={`/${props.currentLang.type}${linkObj.product.converter.path}`} >{t('learnMore')}</Link>
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td28_1')}<br/>
                                {t('comparePage.remoteEnterprise.table.td28_2')}<br/>
                                {t('comparePage.remoteEnterprise.table.td28_3')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td29')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td30_1')}<br/>
                                {t('comparePage.remoteEnterprise.table.td30_2')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td31_1')}<br/>
                                {t('comparePage.remoteEnterprise.table.td31_2')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td26')}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td32_1')}<br/>
                                {t('comparePage.remoteEnterprise.table.td32_2')}
                            </td>
                            <td className='noRBorder' style={{textAlign:'center'}}>
                                {t('comparePage.remoteEnterprise.table.td26')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="remoteEnterpriseInfoContent text-center">
                 <a className="cBtn w_text redBg-fire" href={linkObj.header.buy.url()} style={{width:'250px',margin:'120px auto 36px'}} target="_blank" rel="noopener noreferrer">
                    {t('newfeature.top.buyNow')}
                 </a>
                 <div id="vlp" className="compareInfoBlock text-left">
                    <h2>
                        {t('comparePage.remoteEnterprise.block1.title')}
                    </h2>
                    <p>{t('comparePage.remoteEnterprise.block1.t1')}</p>
                    <ul className="text-size-16">
                        <li>{t('comparePage.remoteEnterprise.block1.list1')}</li>
                        <li>{t('comparePage.remoteEnterprise.block1.list2')}</li>
                        <li>{t('comparePage.remoteEnterprise.block1.list3')}</li>
                    </ul>
                    <span className="compareNoteInfo">{t('comparePage.remoteEnterprise.block1.note')}</span>
                 </div>
                 <div id="siteLicense" className="compareInfoBlock text-left">
                    <h2>
                        {t('comparePage.remoteEnterprise.block2.title')}
                    </h2>
                    <p>{t('comparePage.remoteEnterprise.block2.t1')}</p>
                    <ul className="text-size-16">
                        <li>{t('comparePage.remoteEnterprise.block2.list1')}</li>
                        <li>{t('comparePage.remoteEnterprise.block2.list2')} </li>
                        <li>{t('comparePage.remoteEnterprise.block2.list3')}</li>
                    </ul>
                    <span className="compareNoteInfo">
                        ＊ {t('comparePage.remoteEnterprise.block2.note')}
                        <Link to={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`} className="cBtn w_orange o_border ml-3">
                            {t('comparePage.remoteEnterprise.block2.contactSales')}
                        </Link>
                    </span>
                 </div>
                 <div className="compareInfoBlock text-left">
                    <h2>
                        {t('comparePage.remoteEnterprise.block3.title')}
                    </h2>
                    <p>{t('comparePage.remoteEnterprise.block3.t1')}</p>
                    <ul className="text-size-16">
                        <li>• <a href={linkObj.header.signIn.url()} target="_blank" className="cBtn b_border" rel="noopener noreferrer">{t('comparePage.remoteEnterprise.block3.link1')}</a> </li>
                        <li>• <a href={linkObj.resources.remoteEnterpriseFaq.url()} target="_blank" className="cBtn b_border" rel="noopener noreferrer">{t('comparePage.remoteEnterprise.block3.link2')}</a> </li>
                    </ul>
                 </div>
            </div>
        </div>
    )
}

export default RemoteEnterprise;