import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import mixinWrapper from '../../hoc/mixinWrapper';
// import {animateScroll as scroll} from 'react-scroll';

import IsAdobeaMustforYoubyGaaihocn from './Is_Adobe_a_Must_for_You_by_Gaaiho_cn.js';
import IsAdobeaMustforYoubyGaaihoen from './Is_Adobe_a_Must_for_You_by_Gaaiho_en.js';
import IsAdobeaMustforYoubyGaaihotw from './Is_Adobe_a_Must_for_You_by_Gaaiho_tw.js';
import KnowYourChoicesITEvaluationGuidecn from './Know_Your_Choices_IT_Evaluation_Guide_cn.js';
import KnowYourChoicesITEvaluationGuideen from './Know_Your_Choices_IT_Evaluation_Guide_en.js';
import KnowYourChoicesITEvaluationGuidetw from './Know_Your_Choices_IT_Evaluation_Guide_tw.js';
import UnlockYourPDFPotentialscn from './Unlock_Your_PDF_Potentials_cn.js';
import UnlockYourPDFPotentialsen from './Unlock_Your_PDF_Potentials_en.js';
import UnlockYourPDFPotentialstw from './Unlock_Your_PDF_Potentials_tw.js';

const SdkPage = props => {
    let {t,currentLang, i18n} = props;
    let {lang,contentId,level,menuId} = useParams();
    const [title,setTitle] = useState('');
    const [desc,setDesc] = useState('');
    const [menus,setMenus] = useState([]);
    const [menuOpen,setMenuOpen] = useState(0);
    const whitePaperurl = "/"+currentLang.type+"/Resources/productDocuments/main";
    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);
    useEffect(() => {
        const resourcesData = {
            "Unlock_Your_PDF_Potentials":{
                "cn":{
                    "title":"发挥PDF的无穷潜力",
                    "desc":"立刻了解如何PDF活化您的文件管理",
                    "menus":[
                        {"id":1,"name":"前言","ids":'p1',"isMenuSelected":true},
                        {"id":2,"name":"PDF有哪些用户","ids":'p2'},
                        {"id":3,"name":"为什么使用PDF","ids":'p3'},
                        {"id":4,"name":"他们如何使用PDF","ids":'p4'},
                        {"id":5,"name":"创建和转换PDF","ids":'p5'},
                        {"id":6,"name":"与PDF交互","ids":'p6'},
                        {"id":7,"name":"安全性","ids":'p7'},
                        {"id":8,"name":"审阅文档","ids":'p8'},
                        {"id":9,"name":"压缩文档","ids":'p9'},
                        {"id":10,"name":"轻松存取和编辑","ids":'p10'},
                        {"id":11,"name":"结论","ids":'p11'}
                    ]
                },
                "tw":{
                    "title":"發揮PDF的無窮潛力",
                    "desc":"立刻瞭解如何以PDF活化您的文件管理",
                    "menus":[
                        {"id":1,"name":"前言","ids":'p1',"isMenuSelected":true},
                        {"id":2,"name":"PDF有那些使用者","ids":'p2'},
                        {"id":3,"name":"為什麼使用PDF","ids":'p3'},
                        {"id":4,"name":"他們如何使用PDF","ids":'p4'},
                        {"id":5,"name":"建立和轉換PDF","ids":'p5'},
                        {"id":6,"name":"與PDF互動","ids":'p6'},
                        {"id":7,"name":"安全性","ids":'p7'},
                        {"id":8,"name":"審閱文件","ids":'p8'},
                        {"id":9,"name":"檔案壓縮","ids":'p9'},
                        {"id":10,"name":"輕鬆存取和編輯","ids":'p10'},
                        {"id":11,"name":"結論","ids":'p11'}
                    ]
                },
                "en":{
                    "title":"Unlock Your PDF Potentials",
                    "desc":"Explore How PDF Leads You to Successful Document Handling and Management",
                    "menus":[
                        {"id":1,"name":"Introduction","ids":'p1',"isMenuSelected":true},
                        {"id":2,"name":"Who use PDF","ids":'p2'},
                        {"id":3,"name":"Why PDF","ids":'p3'},
                        {"id":4,"name":"How do they use PDF","ids":'p4'},
                        {"id":5,"name":"Create & Convert PDF","ids":'p5'},
                        {"id":6,"name":"Interact with PDF","ids":'p6'},
                        {"id":7,"name":"Security","ids":'p7'},
                        {"id":8,"name":"Review documents","ids":'p8'},
                        {"id":9,"name":"File compression","ids":'p9'},
                        {"id":10,"name":"Easy access & edit","ids":'p10'},
                        {"id":11,"name":"Conclusion","ids":'p11'}
                    ]
                }
            },
            "Know_Your_Choices_IT_Evaluation_Guide":{
                "cn":{
                    "title":"了解您的选择",
                    "desc":"探索全新的PDF替代方案 - IT评估指南",
                    "menus":[
                        {"id":1,"name":"前言：了解您在PDF软件上有什么选择","ids":"p1","isMenuSelected":true},
                        {"id":2,"name":"文电通PDF成为最佳替代方案的7个理由","ids":'p2',"isopen":1,"Children":[
                            {"id":21,"name":"1.易于部署与维护","ids":'p21'},
                            {"id":22,"name":"2.微软SharePoint和AD RMS","ids":'p22'},
                            {"id":23,"name":"3.迅速上手的用户界面","ids":'p23'},
                            {"id":24,"name":"4.棣南严选的实用功能","ids":'p24'},
                            {"id":25,"name":"5.超越桌面的生产力","ids":'p25'},
                            {"id":26,"name":"6.速度就是效率","ids":'p26'},
                            {"id":27,"name":"7.价格与支持","ids":'p27'}
                        ]},
                        {
                            "id":3,"name":"结论","ids":'p3'
                        }
                    ]
                },
                "tw":{
                    "title":"瞭解您的選擇",
                    "desc":"探索全新的PDF替代方案 - IT 評估指南",
                    "menus":[
                        {"id":1,"name":"前言：瞭解您在PDF軟體上有什麼選擇","ids":"p1","isMenuSelected":true},
                        {"id":2,"name":"PDF文電通成為最佳替代方案的7個理由","ids":'p2',"isopen":1,"Children":[
                            {"id":21,"name":"1.易於部署與維護","ids":'p21'},
                            {"id":22,"name":"2.微軟SharePoint和AD RMS","ids":'p22'},
                            {"id":23,"name":"3.迅速上手的使用者介面","ids":'p23'},
                            {"id":24,"name":"4.棣南嚴選的實用功能","ids":'p24'},
                            {"id":25,"name":"5.超越桌面的生產力","ids":'p25'},
                            {"id":26,"name":"6.速度就是效率","ids":'p26'},
                            {"id":27,"name":"7.價格與支援","ids":'p27'}
                        ]},
                        {
                            "id":3,"name":"結論","ids":'p3'
                        }
                    ]
                },
                "en":{
                    "title":"Know Your Choices",
                    "desc":"Start Afresh With Alternate Pdf Solutions - IT Evaluation Guide",
                    "menus":[
                        {"id":1,"name":"Introduction: Learn the options you have for PDF software","ids":"p1","isMenuSelected":true},
                        {"id":2,"name":"7 reasons Right PDF makes an ideal alternative","ids":'p2',"isopen":1,"Children":[
                            {"id":21,"name":"1.Deploy and maintain with ease","ids":'p21'},
                            {"id":22,"name":"2.Microsoft SharePoint and AIP (Azure Information Protection)","ids":'p22'},
                            {"id":23,"name":"3.Friendly UI, Short Learning Curve","ids":'p23'},
                            {"id":24,"name":"4.Refined features","ids":'p24'},
                            {"id":25,"name":"5.Productivity beyond desktop","ids":'p25'},
                            {"id":26,"name":"6.Speed is efficiency","ids":'p26'},
                            {"id":27,"name":"7.Cost and support","ids":'p27'}
                        ]},
                        {
                            "id":3,"name":"Conclusion","ids":'p3'
                        }
                    ]
                }
            },
            "Is_Adobe_a_Must_for_You_by_Gaaiho":{
                "cn":{
                    "title":"醒醒：真的非Adobe®不可？",
                    "desc":"文电通PDF邀您一同思考5项观点",
                    "menus":[
                        { "id":0,"name":"不定时炸弹：您的软件授权100%合乎规范吗？","ids":"p1" ,"isMenuSelected":true},
                        { "id":1,"name":"友善的对待用户、IT人员与企业" ,"ids":"p2"},
                        { "id":2,"name":"创建、编辑PDF和OCR" ,"ids":"p3"},
                        { "id":3,"name":"浅谈云端、订阅版对您的影响","ids":"p4","isopen":1 ,"Children":[
                            {"id":31,"name":"A.订阅版授权与永久型授权","ids":'p41'},
                            {"id":32,"name":"B.云端带来的风险","ids":'p42'},
                            {"id":33,"name":"C.您真的需要连接云端的功能吗？","ids":'p43'}
                        ]},
                        { "id":4,"name":"您软件的投资回报率？" ,"ids":"p5"}
                    ]
                },
                "tw":{
                    "title":"醒醒：真的非Adobe®不可?",
                    "desc":"PDF文電通邀您一同思考5項觀點",
                    "menus":[
                        { "id":0,"name":"不定時炸彈：您的軟體授權100%合乎規範嗎?" ,"ids":"p1","isMenuSelected":true},
                        { "id":1,"name":"友善的對待使用者、IT人員與企業" ,"ids":"p2"},
                        { "id":2,"name":"建立、編輯PDF和OCR" ,"ids":"p3"},
                        { "id":3,"name":"淺談雲端、訂閱版對您的影響","ids":"p4","isopen":1 ,"Children":[
                            {"id":31,"name":"A.訂閱版授權與永久型授權","ids":'p41'},
                            {"id":32,"name":"B.雲端帶來的風險","ids":'p42'},
                            {"id":33,"name":"C.您真的需要連結雲端的功能嗎?","ids":'p43'}
                        ]},
                        { "id":4,"name":"您軟體的投資報酬率?" ,"ids":"p5"}
                    ]
                },
                "en":{
                    "title":"Think: Is Adobe® a Must for You?",
                    "desc":"5 perspectives by Right PDF",
                    "menus":[
                        { "id":0,"name":"A Loose Cannon: Your Software License Compliance" ,"ids":"p1","isMenuSelected":true},
                        { "id":1,"name":"Friendly to Users, IT Pros and Company" ,"ids":"p2"},
                        { "id":2,"name":"Create, Edit and OCR" ,"ids":"p3"},
                        { "id":3,"name":"Cloud, Subscription, and You (subscription confusion, cloud service providers)","ids":"p4",isopen:1 ,Children:[
                            {"id":31,"name":"A.Subscription vs. Perpetual","ids":'p41'},
                            {"id":32,"name":"B.Cloud risks","ids":'p42'},
                            {"id":33,"name":"C.Do you need cloud features","ids":'p43'}
                        ]},
                        { "id":4,"name":"Have You Invested in Bloatware" ,"ids":"p5"}
                    ]
                }
            }
        };
        const getRdata =  (docID,level,menuId) => {
            let plang = 'en';
            if(currentLang.type === 'zh-CN'){
                plang = 'cn';
            }else if(currentLang.type === 'zh-TW'){
                plang = 'tw';
            }
            var docdata = resourcesData[docID][plang];
            setTitle(docdata.title);
            setDesc(docdata.desc);

            var enDocData = resourcesData[docID]['en'];
            setEnName(docdata.menus,enDocData.menus);
            setMenus(docdata.menus);
            if(menuId){
                var enArr = [];
                docdata.menus.forEach((item,index) => {
                    enArr.push(item);
                    if(item.Children&&item.Children.length !== 0){
                        enArr = enArr.concat(item.Children);
                    }
                })
                var filterArr = enArr.filter((item,index)=>{
                    return item.enName === menuId;
                })
                var ids = filterArr[0].ids;
                var anchorDOM = document.getElementById(ids);
                var header = document.getElementsByClassName('havetips')[0];
                var search = document.getElementsByClassName('head_search_flex fixed')[0];
                var offsetTop = anchorDOM.offsetTop;
                if(search){
                    offsetTop = offsetTop - search.offsetHeight;
                }else{
                    search = document.getElementsByClassName('head_search_flex')[0];
                    var head_bread = document.getElementsByClassName('head_bread')[0];
                    offsetTop = offsetTop - search.offsetHeight - head_bread.offsetHeight;
                }
                var scrollTop = offsetTop - header.offsetHeight;
                scrollTop = scrollTop<0?0:scrollTop;
                document.documentElement.scrollTop = scrollTop;
            }
        }
        const setEnName = (menus,enDocData) => {
            var enArr = [];
            enDocData.forEach((item,index) => {
                enArr.push(item);
                if(item.Children&&item.Children.length !== 0){
                    enArr = enArr.concat(item.Children);
                }
            })
            menus.forEach((item) => {
                var filterItem = enArr.filter((v) => {
                    return v.id === item.id;
                })
                item.enName = filterItem[0].name;
                if(item.Children&&item.Children.length !== 0){
                    item.Children.forEach((v) => {
                        var filterItem = enArr.filter((v1) => {
                            return v1.id === v.id;
                        })
                        v.enName = filterItem[0].name;
                    })
                }
            })
        }
        const setMenuFixed = () => {//重置menu位置
            // if(window.innerWidth < 1200){
            //     return;
            // }
            var scrollTop = getScrollTop();
            var head_search = document.getElementsByClassName("head_search")[0];
            var menu = document.getElementById('menu');
            var header = document.getElementsByTagName('header')[0];
            if(head_search)
            {
              if(menu){
                  if(scrollTop >= 20){
                      head_search.classList.add('fixed');
                      head_search.style.top = header.offsetHeight+'px';
                      menu.classList.add('fixed');
                      var menuTop = header.offsetHeight + 50;
                      if(document.documentElement.clientHeight + scrollTop - document.getElementsByTagName("footer")[0].offsetTop >= 0){
                          var x = document.documentElement.clientHeight + scrollTop - document.getElementsByTagName("footer")[0].offsetTop;
                          menu.style.top = (menuTop - x) + "px";
                      }else{
                          menu.style.top = menuTop + 'px';
                      }
                  }else{
                      head_search.classList.remove('fixed');
                      head_search.style.top = 'auto';
                      menu.classList.remove('fixed');
                      menu.style.top ='auto';
                  }
              }else{
                  head_search.classList.remove('fixed');
              }
            }
        }
        // const setScrollFollow = () =>{
        //     var menu = document.getElementById('menu');
        //     var isClick = menu.getAttribute('isClick');
        //     if(isClick === "1"){
        //         return;
        //     }
        //     var idsdom = document.querySelectorAll('h1[id]');
        //     var idsdom1 = document.querySelectorAll('h2[id]');
        //     var selectids = '';
        //     var diffY = Infinity;
        //     var scrollTop = getScrollTop();
        //     for(var i = 0;i<idsdom.length;i++){
        //         var anchorDOM = idsdom[i];
        //         var offsetTop = anchorDOM.offsetTop;
        //         var anchorDiffy =  Math.abs(offsetTop - scrollTop);
        //         if(anchorDiffy < diffY){
        //             diffY = anchorDiffy;
        //             selectids = anchorDOM.getAttribute('id');
        //         }
        //     }
        //     for(var i1 = 0;i1<idsdom1.length;i1++){
        //         var anchorDOM1 = idsdom1[i1];
        //         var offsetTop1 = anchorDOM1.offsetTop;
        //         var anchorDiffy1 =  Math.abs(offsetTop1 - scrollTop);
        //         if(anchorDiffy1 < diffY){
        //             diffY = anchorDiffy1;
        //             selectids = anchorDOM1.getAttribute('id');
        //         }
        //     }
        //     var selectdom = document.getElementsByClassName('menu_selected')[0];
        //     if(selectdom){
        //         selectdom.className = '';
        //     }
        //     var currentdom = document.querySelector('[ids="'+selectids+'"]');
        //     if(currentdom){
        //         currentdom.className = 'menu_selected';
        //     }
        // }
        const addScrollEvent = () => {
            document.onscroll = () => {
                setMenuFixed();
                // setScrollFollow();
            }
            document.onresize = () => {
                setMenuFixed();
                // setScrollFollow();
            }
        } 
        const fetchResource = cLang => {
            getRdata(contentId,level,menuId);
        }
        document.documentElement.scrollTop = 0;
        addScrollEvent();
        fetchResource(currentLang.type);
    }, [currentLang.type,contentId,level,menuId]);
    const getContent = (docID) => {
        let plang = 'en';
        if(currentLang.type === 'zh-CN'){
            plang = 'cn';
        }else if(currentLang.type === 'zh-TW'){
            plang = 'tw';
        }
        let contentComponent = '';
        let docname = docID+'_'+plang;
        switch(docname){
            case 'Is_Adobe_a_Must_for_You_by_Gaaiho_cn':
                contentComponent = <IsAdobeaMustforYoubyGaaihocn></IsAdobeaMustforYoubyGaaihocn>;
                break;
            case 'Is_Adobe_a_Must_for_You_by_Gaaiho_en':
                contentComponent = <IsAdobeaMustforYoubyGaaihoen></IsAdobeaMustforYoubyGaaihoen>;
                break;
            case 'Is_Adobe_a_Must_for_You_by_Gaaiho_tw':
                contentComponent = <IsAdobeaMustforYoubyGaaihotw></IsAdobeaMustforYoubyGaaihotw>;
                break;
            case 'Know_Your_Choices_IT_Evaluation_Guide_cn':
                contentComponent = <KnowYourChoicesITEvaluationGuidecn></KnowYourChoicesITEvaluationGuidecn>;
                break;
            case 'Know_Your_Choices_IT_Evaluation_Guide_en':
                contentComponent = <KnowYourChoicesITEvaluationGuideen></KnowYourChoicesITEvaluationGuideen>;
                break;
            case 'Know_Your_Choices_IT_Evaluation_Guide_tw':
                contentComponent = <KnowYourChoicesITEvaluationGuidetw></KnowYourChoicesITEvaluationGuidetw>;
                break;
            case 'Unlock_Your_PDF_Potentials_cn':
                contentComponent = <UnlockYourPDFPotentialscn></UnlockYourPDFPotentialscn>;
                break;
            case 'Unlock_Your_PDF_Potentials_en':
                contentComponent = <UnlockYourPDFPotentialsen></UnlockYourPDFPotentialsen>;
                break;
            case 'Unlock_Your_PDF_Potentials_tw':
                contentComponent = <UnlockYourPDFPotentialstw></UnlockYourPDFPotentialstw>;
                break;
            default:
                contentComponent = <IsAdobeaMustforYoubyGaaihocn></IsAdobeaMustforYoubyGaaihocn>;
                break;    
        }
        return contentComponent;
    }
    const getScrollTop = () => {
		var scrollTop = 0;
		if(document.documentElement && document.documentElement.scrollTop){
			scrollTop = document.documentElement.scrollTop;
		}
		else if(document.body){
			scrollTop = document.body.scrollTop;
		}
		return scrollTop;
	}
    const menuClick = () => {
        setMenuOpen(menuOpen === 0?1:0);
    }
    // const itemClick = (clickItem) => {
    //     console.log(123);
    //     clickItem.isopen = clickItem.isopen === 1?0:1;
    // }
    // const anchorClick = (ids)=>{
    //     var menu = document.getElementById('menu');
    //     var isClick = menu.getAttribute('isClick');
    //     if(isClick === "1"){
    //         return;
    //     }
    //     menu.setAttribute('isClick',1);
    //     var selectdom = document.getElementsByClassName('menu_selected')[0];
    //     if(selectdom){
    //         selectdom.className = '';
    //     }
    //     var currentdom = document.querySelector('[ids="'+ids+'"]');
    //     currentdom.className = 'menu_selected';
    //     setMenuOpen(menuOpen === 0?1:0);

    //     var anchorDOM = document.getElementById(ids);
    //     var header = document.getElementsByClassName('havetips')[0];
    //     var search = document.getElementsByClassName('head_search_flex fixed')[0];
    //     var offsetTop = anchorDOM.offsetTop;
    //     if(search){
    //         offsetTop = offsetTop - search.offsetHeight;
    //     }else{
    //         search = document.getElementsByClassName('head_search_flex')[0];
    //         var head_bread = document.getElementsByClassName('head_bread')[0];
    //         offsetTop = offsetTop - search.offsetHeight - head_bread.offsetHeight;
    //     }
    //     var scrollTop = offsetTop - header.offsetHeight;
    //     scrollTop = scrollTop<0?0:scrollTop;
    //     scroll.scrollTo(scrollTop);
    //     menu.setAttribute('isClick',0);

    //     // document.querySelector('#'+ids).scrollIntoView(false);
    //     // menu.setAttribute('isClick',0);
    // }
    const makeListTree = (treeData) => {
        const menuliss = treeData.map((item,index) => {
            if(item.Children&&item.Children.length !== 0){
                return (
                    <li key={index} isopen={item.isopen} ids={item.ids} className={item.enName === menuId?'menu_selected':''}>
                        <div className="menuText"><span className="menuIcon"><a href={'/'+lang+'/Resources/'+contentId+'/1/'+item.enName}>{item.name}</a></span></div>
                        <ul>{makeListTree(item.Children)}</ul>
                    </li>
                )
            }else{
                return (
                    <li key={index} ids={item.ids} className={item.enName === menuId?'menu_selected':''}>
                        <div className="menuText"><a href={'/'+lang+'/Resources/'+contentId+'/1/'+item.enName}>{item.name}</a></div>
                    </li>
                )
            }
        })
        return menuliss;
    }
    const menulis = menus.map((item,index)=>{
        if(item.Children&&item.Children.length !== 0){
            return (
                <li key={index} isopen={item.isopen} ids={item.ids} className={item.enName === menuId?'menu_selected':''}>
                    <div className="menuText"><span className="menuIcon"><a href={'/'+lang+'/Resources/'+contentId+'/0/'+item.enName}>{item.name}</a></span></div>
                    <ul>{makeListTree(item.Children)}</ul>
                </li>
            )
        }else{
            return (
                <li key={index} ids={item.ids} className={item.enName === menuId?'menu_selected':''}>
                    <div className="menuText"><a href={'/'+lang+'/Resources/'+contentId+'/0/'+item.enName}>{item.name}</a></div>
                </li>
            )
        }
    })
    const renderContentView = () => {
        return (
            <div>
                <div className="head_bread"><div>/<a className="whitePaperurl" href={whitePaperurl}>{t('resources.productDoc.whitePaper.name')}</a></div></div>
                <div className="head_search head_search_flex">
                    <div>
                        <div className="productDetailHead">
                            <div className="normalHead">
                                <div>{title}</div>
                                <div>{desc}</div>
                            </div>
                            <div className='smallHead'><a className="whitePaperurl" href={whitePaperurl}>{t('resources.productDoc.whitePaper.name')}</a>/<span>{title}</span></div>
                            <div className='scrollHead'><a className="whitePaperurl" href={whitePaperurl}>{t('resources.productDoc.whitePaper.name')}</a>/<span>{title}</span></div>
                        </div>
                        <div isopen={menuOpen}>
                            <span className="bottomArrow" onClick={menuClick.bind(this)}></span>
                        </div>
                    </div>
                </div>
                <div className="content1 whitePaperContent">
                    <div className={["container"].join(' ')}>
                        <div id="menu"  isopen={menuOpen}>
                            <div className="menuContent">
                                <div className="menuContentContent paddingContainer">
                                    <ul>{menulis}</ul>
                                </div>
                            </div>
                        </div>
                        <div id="textContent">
                            <div className='textContainer'>
                                {getContent(contentId)}
                            </div>
                        </div>
                        <div  className="clearBoth"></div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <section id="helpcontent" className="productInfoContent">
            
            <div className="contentBlock whiteBg" style={{"paddingTop":"0"}}>
                <div className="customContainer">
                     {renderContentView()}
                </div>
            </div>
        </section>
    )
}

export default mixinWrapper(SdkPage);