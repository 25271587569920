const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>Converter/ Driver在使用中转换设置突然全部变成了空的，无法设置了，怎么办？</title></head><body>  <h1>Converter/ Driver在使用中转换设置突然全部变成了空的，无法设置了，怎么办？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>问题描述：</strong></h2>
<p>Converter/ Driver在使用中转换设置突然全部变成了空的，且无法设置了。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>如果 Converter/ Driver在之前出现了转换失败，可能导致注册表中记录的 Converter/ Driver配置文件路径发生错误，导致 Converter/ Driver上原来设置好的水印，加密等都变成空。</p>
<h2>
<br><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span>
</h2>
<p><span class="wysiwyg-color-blue130">关闭 Converter/ Driver进程，删除注册表中的</span><br><span class="wysiwyg-color-blue130"> <strong>HKEY_CURRENT_USER\\Software\\Zeon\\Right PDF<em> (Gaaiho)</em>\\PDF Driver <em>(Gaaiho PDF)</em></strong> <font>，然后再重启Converter/ Driver。</font> </span> <br><br><font> <strong><span class="wysiwyg-color-red">小提醒：</span></strong>请在「执行」或「搜索程序和文件」中输入「regedit」以访问注册表。 </font></p></body></html>`;   export default html;