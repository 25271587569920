const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>Installer的权限不足，无法访问这个目录</title></head><body>  <h1>Installer的权限不足，无法访问这个目录</h1>  <h2><strong>问题描述：</strong></h2>
<p>安装文电通的时候，出现以下错误讯息「Installer的权限不足，无法访问这个目录」：</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/310021.png" alt="310021.png"></p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>有以下三种可能：</p>
<ul>
<li>系统上还有些程序正在执行中，导致部分档案无法删除</li>
<li>卸载不完全</li>
<li>您并未以管理员身分登入Windows系统</li>
</ul>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法</strong></span></h2>
<ol>
<li><span class="wysiwyg-color-blue130">卸载文电通软件 (如果在之前的安装过程中已经卸载，则可忽略)</span></li>
<li><span class="wysiwyg-color-blue130">重新启动计算机</span></li>
<li>
<span class="wysiwyg-color-blue130">删除文件夹</span> (<span class="wysiwyg-color-pink120"><strong>C:\\Program Files (x86)\\Zeon\\Gaaiho\\Gaaiho PDF Suite\\bin</strong></span>)</li>
<li><span class="wysiwyg-color-blue130">重新尝试安装一次</span></li>
</ol>
<p>如果还有任何问题，请联系我们：<a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_self" rel="undefined">提交请求</a></p></body></html>`;   export default html;