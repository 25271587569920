import React from 'react';
import MetaTags from 'react-meta-tags';

const Company = props => {
    const {t,currentLang} = props;
    const historyAry = [
        {
            hObj:{
                date:t('company.block19.date'),
                title:t('company.block19.title'),
                info:[t('company.block19.info')],
                img:'htyImg19'
            },
            year:'2022',
            show:true
        },
        {
            hObj:{
                date:t('company.block1.date'),
                title:t('company.block1.title'),
                info:[t('company.block1.info')],
                img:'htyImg1'
            },
            year:currentLang.type !== 'zh-CN'?'2019':'',
            show:true
        },
        {
            hObj:{
                date:'2019年8月',
                title:'庆贺《文电通PDF》入围2019年中央企业软件联合采购项目！',
                info:['深耕PDF科技超过25年的北京棣南新宇科技有限公司，于8月底确定入选2019年度“中央企业软件联合采购项目”。通过严格的遴选程序，全国仅有三家PDF软件公司进入到最后的决选名单中。'],
                img:'htyImg1_zh-CN'
            },
            year:'2019',
            show:currentLang.type === 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block2.date'),
                title:t('company.block2.title'),
                info:[t('company.block2.info')],
                img: (currentLang.type === 'zh-TW' || currentLang.type === 'zh-CN')?'htyImg2_'+currentLang.type:'htyImg2_en'
            },
            year:'2018',
            show:true
        },
        {
            hObj:{
                date:t('company.block3.date'),
                title:t('company.block3.title'),
                info:[t('company.block3.info')],
                img: 'htyImg3'
            },
            year:'',
            show:currentLang.type !== 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block4.date'),
                title:t('company.block4.title'),
                info:[t('company.block4.info')],
                img: 'htyImg4'
            },
            year:'2017',
            show:currentLang.type !== 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block5.date'),
                title:t('company.block5.title'),
                info:[t('company.block5.info')],
                img: 'htyImg5'
            },
            year:'',
            show:currentLang.type !== 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block6.date'),
                title:t('company.block6.title'),
                info:[t('company.block6.info')],
                img:'htyImg6'
            },
            year:'2016',
            show:true
        },
        {
            hObj:{
                date:'2015年4月',
                title:'文电通 x 神州数码',
                info:['棣南新宇与中国最大的整合IT服务商神州数码合作，开始推广PDF文电通的产品。'],
                img: 'htyImg5_zh-CN'
            },
            year:'2015',
            show:currentLang.type === 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block7.date'),
                title:t('company.block7.title'),
                info:[t('company.block7.info')],
                img: 'htyImg7'
            },
            year:'',
            show:currentLang.type !== 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block8.date'),
                title:t('company.block8.title'),
                info:[t('company.block8.info')],
                img: 'htyImg8'
            },
            year:'2015',
            show:currentLang.type !== 'zh-CN'
        },
        {
            hObj:{
                date:'2014年6月',
                title:'PDF文电通3.0问世',
                info:['我们正式发布了被誉为里程碑式成就的PDF文电通3.0。除了更加平易近人的界面，更有革命性的直接编写模式，颠覆一般人对PDF的想像。'],
                img: 'htyImg6_zh-CN'
            },
            year:'2014',
            show:currentLang.type === 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block9.date'),
                title:t('company.block9.title'),
                info:[t('company.block9.info')],
                img: 'htyImg9'
            },
            year:'2014',
            show:currentLang.type !== 'zh-CN'
        },
        {
            hObj:{
                date:'2013年9月',
                title:'上海联合发布会',
                info:['PDF文电通2.0于上海举办的联合发布会斩露头角，获得了诸多媒体的注意。'],
                img: 'htyImg7_zh-CN'
            },
            year:'',
            show:currentLang.type === 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block10.date'),
                title:t('company.block10.title'),
                info:[t('company.block10.info')],
                img: 'htyImg10'
            },
            year:'2013',
            show:true
        },
        {
            hObj:{
                date:t('company.block11.date'),
                title:t('company.block11.title'),
                info:[t('company.block11.info')],
                img: 'htyImg11'
            },
            year:'2012',
            show:currentLang.type !== 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block12.date'),
                title:t('company.block12.title'),
                info:[t('company.block12.info')],
                img: 'htyImg12'
            },
            year:'2009',
            show:true
        },
        {
            hObj:{
                date:t('company.block13.date'),
                title:t('company.block13.title'),
                info:[
                    t('company.block13.info1'),
                    t('company.block13.info2'),
                    t('company.block13.info3'),
                    t('company.block13.info4')
                ],
                link:'https://www.teampel.com',
                img: 'htyImg13'
            },
            year:'2005',
            show:true
        },
        {
            hObj:{
                date:t('company.block14.date'),
                title:t('company.block14.title'),
                info:[t('company.block14.info')],
                img: 'htyImg14'
            },
            year:'2004',
            show:true
        },
        {
            hObj:{
                date:'2003年',
                title:'北京棣南新宇科技有限公司成立',
                info:[],
                img: 'htyImg20.gif',
                imgType:'gif'
            },
            year:'2003',
            show:currentLang.type === 'zh-CN'
        },
        {
            hObj:{
                date:t('company.block15.date'),
                title:t('company.block15.title'),
                info:[t('company.block15.info')],
                img: 'htyImg15'
            },
            year:'1997',
            show:true
        },
        {
            hObj:{
                date:t('company.block16.date'),
                title:t('company.block16.title'),
                info:[t('company.block16.info')],
                img: 'htyImg16'
            },
            year:'1995',
            show:true
        },
        {
            hObj:{
                date:t('company.block17.date'),
                title:t('company.block17.title'),
                info:[t('company.block17.info')],
                img: 'htyImg17'
            },
            year:'1993',
            show:true
        },
        {
            hObj:{
                date:t('company.block18.date'),
                title:t('company.block18.title'),
                info:[t('company.block18.info')],
                img: 'htyImg18'
            },
            year:'1989',
            show:currentLang.type !== 'zh-CN'
        }
    ]
    const getImageName = (hObj) => {
        if(hObj.imgType){
            return hObj.img;
        }else{
            return `${hObj.img}.png`;
        }
    }
    const renderInfo = obj => {
        let returnElement = null;
        returnElement =  obj.info.map((e,i)=>{
                            if(obj.link){
                                let infoAry = e.split('_');
                                return <p key={i}>{infoAry[0]}<a style={{color:'#000'}} href={obj.link} target="_blank" rel="noopener noreferrer">{infoAry[1]}</a>{infoAry[2]}</p>
                            }else{
                                return <p key={i}>{e}</p>
                            }
                        });
        return returnElement;
    }
    const renderHistory = () => {
        let renderli = null;
        renderli =  historyAry.map((e,i)=>{
            //if(e.show){
                return e.show ? (
                    <li key={i}>
                         <div className="historyBlock">
                            <div className="historyTitle">
                               <img src={require(`../../assets/img/company/${getImageName(e.hObj)}`)} style={{'maxWidth':'150px'}}  className="img-fluid" alt="htyImg" />
                                <div className="historyDate">
                                    <p>{e.hObj.date}</p>
                                </div>
                            </div>
							<h2 className="font-weight-bold title_style">{e.hObj.title}</h2>
                            {
                               renderInfo(e.hObj)
                            }
                         </div>
                        {e.year !== ''?<span className="yearCircle">{e.year}</span>:null}
                    </li>
                ): null
            //}
        });
        return renderli;
    }
    return (
        <div className="companyContent">
            
            <MetaTags>
                <title>{t('metaTag.aboutus.title')}</title>
                <meta name="title" content={t('metaTag.aboutus.title')} />
                <meta name="description" content={t('metaTag.aboutus.desc')} />
                <meta name="keywords" content={t('metaTag.aboutus.keywords')} />
                <meta property="og:title" content={t('metaTag.aboutus.title')} />
                <meta property="og:description" content={t('metaTag.aboutus.desc')} />
            </MetaTags>
            <h2 className="font-weight-bold title_style" style={{marginBottom:'80px'}}>{t('company.title')}</h2>
            <p>{t('company.info1')}</p>
            <p>{t('company.info2')}</p>
            {currentLang.type === 'zh-CN'?(<p style={{color:'#7F7F7F'}}>
                *1993年，北京科印棣南电子股份有限公司成立；2003年北京棣南新宇科技有限公司成立。
            </p>):""}
            <ul className="companyHistorylist">
                {renderHistory()}
            </ul>
        </div>
    )
}

export default Company;