const langList = [
    {
        name:'English',
        type:'en',
        typeNum:0
    },
    {
        name:'简体中文',
        type:'zh-CN',
        typeNum:1
    },
    {
        name:'繁體中文',
        type:'zh-TW',
        typeNum:2
    },
    {
        name:'日本語',
        type:'jp',
        typeNum:3
    },
    {
        name:'한국어',
        type:'kr',
        typeNum:4
    }
];
const getBrowserLanguage = (list)=>{
    let type = navigator.appName;
    let lang = '';
    if(type === 'Netscape'){
      lang = navigator.language;
    }else{
      lang = navigator.userLanguage;
    }
    if(lang.indexOf('en') !== -1){
      lang = 'en';
    }
    if(lang.indexOf('ja') !== -1){
      lang = 'jp';
    }
    if(lang.indexOf('ko') !== -1){
      lang = 'kr';
    }
    let arr = list.filter((item,index)=>{
        return item.type === lang;
    })
    return arr[0];
  }
const getDefaultLang = () => {
    let href = window.location.href;
    let arrs = href.split('/');
    let lang = arrs[3];
    return lang;
}
const getCurrentLang = (list) => {
    let lang = getDefaultLang();
    lang = lang.toLowerCase();
    let plang = lang;
    if(lang === 'zh-tw'){
        plang = 'zh-TW';
    }else if(lang === 'zh-cn'){
        plang = 'zh-CN';
    }
    let arr = list.filter((item,index)=>{
        return item.type === plang;
    })
    return arr[0]||getBrowserLanguage(list)||langList[0];
}
const initState = {
    langList:langList,
    currentLang:getCurrentLang(langList)
}

const languageReducer = (state = initState,action)=>{
    switch (action.type){
        case 'GET_CURRENTLANG':
            return state.currentLang;
        case 'GET_LANGLIST':
            return state.langList;
        case 'SEND_CURRENTLANG':
            return {...state,currentLang:action.lang};
        default:
            return state
    }

}
export default languageReducer;