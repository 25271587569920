const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何加买文电通PDF套装版(Right PDF Pro)授权?</title></head><body>  <h1>如何加买文电通PDF套装版(Right PDF Pro)授权?</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p>一般情况，您可以购买一个新的序列号给新增用户使用。如果您只希望对所有用户<strong>只保留一个序列号</strong>，那么可以按下面的步骤进行：<br><br style="font-family: 'Times New Roman'; font-size: medium;">1. 以原购买者的Right PDF (Gaaiho) 账号登录<strong><a title="Link: http://pdf.gaaiho.com" href="http://store.rightpdf.cn" target="_blank" rel="noopener">store.rightpdf.cn</a></strong>网站<br style="font-family: 'Times New Roman'; font-size: medium;">2. 从下拉式选单中选择「授权管理」 ，检查您的账号是否已经绑定了需要加买授权的序列号。如果还没有，请参考<a href="https://support.rightpdf.com/hc/zh-cn/articles/201121713">如何绑定我的授权？</a></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/610091.png" alt="610091.png" width="428" height="171"></p>
<p>3. 绑定后，从下拉式选单中选择「授权管理」。 并选择<img src="https://store.rightpdf.com/images/rtsupport/610092.png" alt=""></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/610093.png" alt="610093.png"></p>
<p> </p>
<p>4. 按照购买的步骤，填写需要加买的人数和其它信息并付款。</p>
<p>5. 在线支付之后，您的序列号就可以激活新购的数量。</p></body></html>`;   export default html;