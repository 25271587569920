const initState = {
    typeListAry:[],
    featureListAry: [],
    featureContent: [],
    pressTypeListAry: [],
    pressListAry: []
}

const apiReducer = (state = initState,action) => {
    switch (action.type){
        case 'GET_TYPELIST':
            return state.typeListAry;
        
        // case 'GET_SUITE_FEATURE_CONTENT':
        //     return state.suiteContent;
        case 'SEND_TYPELIST':
            return {...state,typeListAry:action.typeListAry};
        case 'GET_FEATURELIST':
            return state.featureListAry;
        case 'SEND_FEATURELIST':
            return {...state,featureListAry:action.featureListAry};
        case 'GET_FEATURECONTENT':
            return state.featureContent;
        case 'SEND_FEATURECONTENT':
            return {...state,featureContent:action.featureContent};
        case 'GET_PRESSTYPE':
            return state.pressTypeListAry;
        case 'SEND_PRESSTYP':
            return {...state,pressTypeListAry:action.pressTypeListAry};
        case 'GET_PRESSLIST':
            return state.pressListAry;
        case 'SEND_PRESSLIST':
            return {...state,pressListAry:action.pressListAry};
        default:
            return state;
    }
}

export default apiReducer;
