const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我想在服务器端转换PDF文件，我应该购买转换服务器(PDF Server)还是转换器(PDF Driver)?</title></head><body>  <h1>我想在服务器端转换PDF文件，我应该购买转换服务器(PDF Server)还是转换器(PDF Driver)?</h1>  <p>请通过以下几点来考虑您的需求：<br><br></p>
<ol>
<li>
<strong>交互方式</strong> ：<strong><a href="https://rightpdf.com/zh-CN/server" target="_blank" rel="noopener">Right PDF转换服务器(Right PDF Server)</a></strong>有灵活的交互方式，既可以用于个人交互，也可以用于无人操作的服务器端。而<strong><a href="https://rightpdf.com/zh-CN/converter" target="_blank" rel="noopener">转换器(Right PDF Converter)</a></strong>仅适应于手动操作转换。</li>
<li>
<strong>使用方式：</strong>您不希望每台机器安装软件，或者说您的客户已经配置了<strong>远程系统</strong>，<strong>虚拟桌面</strong>或<strong>虚拟应用系统。</strong>这种情况下您可以选择转换器 <strong><a href="https://www.rightpdf.com/zh-CN/Resources/compared/remoteEnterprise" target="_blank" rel="noopener">终端服务器授权</a></strong> 或者部署 <strong><a href="https://rightpdf.com/zh-CN/server" target="_blank" rel="noopener">转换服务器</a></strong> 的「监视文件夹」。</li>
<li>
<strong>您的预算是多少？</strong> 转换服务器(价格比较昂贵，如果您只是少数人使用或只需要少量转换，可以选择为每个用户购买一个 <a href="https://rightpdf.com/zh-CN/converter" target="_blank" rel="noopener"><strong>转换器</strong></a> 授权。</li>
<li>
<strong>您是否需要二次开发？</strong>有些功能我们并未直接在应用中开发，但是开发者可以在转换服务器的基础上通过自己写代码调用这些功能。具体可以查看我们提供的<a href="https://www.rightpdf.com/zh-CN/Resources/sdkResources/main" target="_blank" rel="noopener"><strong>SDK资料</strong></a>。<strong><br></strong>
</li>
</ol>
<p>请<strong><a href="https://support.rightpdf.com/hc/zh-cn/requests/new" target="_self" rel="undefined">连络我们</a></strong>的客服人员，以获得更多的建议。</p>
<p> </p>
<h3><span class="wysiwyg-color-green130"><strong>延伸阅读：</strong></span></h3>
<ul>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/200851767-%E6%96%87%E7%94%B5%E9%80%9A%E8%BD%AC%E6%8D%A2%E5%99%A8-Gaaiho-PDF-Converter-%E5%92%8C%E6%96%87%E7%94%B5%E9%80%9A%E8%BD%AC%E6%8D%A2%E6%9C%8D%E5%8A%A1%E5%99%A8-Gaaiho-PDF-Server-%E5%93%AA%E4%B8%AA%E6%9B%B4%E5%8A%A0%E9%80%82%E5%90%88%E6%88%91%E4%BB%AC%E5%85%AC%E5%8F%B8-" target="_blank" rel="noopener">文电通转换器(Right PDF Converter)和文电通转换服务器(Right PDF Server)，哪个更加适合我们公司？</a></li>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/200853097-%E6%88%91%E5%9C%A8%E7%BD%91%E7%AB%99%E4%B8%8A%E7%9C%8B%E5%88%B0%E5%8F%AF%E8%B4%AD%E4%B9%B0%E7%BB%88%E7%AB%AF%E6%9C%8D%E5%8A%A1%E5%99%A8%E7%89%88-TSL-%E9%82%A3%E6%98%AF%E4%BB%80%E4%B9%88-" target="_blank" rel="noopener">我在网站上看到可购买终端服务器版 (TSL)，那是什么?</a></li>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/200853607-%E7%BB%88%E7%AB%AF%E6%9C%8D%E5%8A%A1%E5%99%A8%E7%89%88%E6%8E%88%E6%9D%83%E6%9C%89%E4%BB%80%E4%B9%88%E5%A5%BD%E5%A4%84-" target="_blank" rel="noopener">终端服务器版授权有什么好处？</a></li>
</ul></body></html>`;   export default html;