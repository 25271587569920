const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>激活订阅版授权时，出现错误「-10180」</title></head><body>  <h1>激活订阅版授权时，出现错误「-10180」</h1>  <h2><strong>问题描述：</strong></h2>
<p>激活订阅版授权时，出现错误「-10180」，授权激活失败。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p><span>您使用了授权码 (序号) 来激活您的订阅版授权。</span></p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">请使用您的帐号与密码来激活产品(与登入<a href="https://store.rightpdf.cn/account/login.aspx" target="_blank" rel="noopener">Right PDF</a>的相同)。</span></p>
<p> </p>
<p>如何登入激活产品? 请参考<a href="https://support.rightpdf.com/hc/zh-cn/articles/201037188">如何登入</a></p>
<p>如何管理使用者清单? 请参考<a href="https://support.rightpdf.com/hc/zh-cn/articles/201032226">如何开始部署</a></p></body></html>`;   export default html;