import React from 'react';
//import { useDispatch} from 'react-redux';
import ReleaseContentBlock from '../../components/release/releaseContentBlock';

const SuiteRelease = props => {
    const {t} = props;
    //const [selectList,setSelectList] = useState('');
    const pageDataObj = {
            info:{
                t1:t('resources.serverRelease.name')
            },
            releaseTitleList:{
                releaseTitle7:t('resources.serverRelease.releaseTitle7'),
                releaseTitle1:t('resources.serverRelease.releaseTitle1'),
                releaseTitle2:t('resources.serverRelease.releaseTitle2'),
                releaseTitle3:t('resources.serverRelease.releaseTitle3'),
                releaseTitle4:t('resources.serverRelease.releaseTitle4'),
                releaseTitle5:t('resources.serverRelease.releaseTitle5'),
                releaseTitle6:t('resources.serverRelease.releaseTitle6')
            },
            releaseContentBlock:{
                releaseTitle7:[
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle7.item1.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle7.item1.text.t1')
                            // t2:t('resources.serverRelease.releaseContent.releaseTitle7.item1.text.t2')
                        }
                    },
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle7.item2.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle7.item2.text.t1'),
                            t2:t('resources.serverRelease.releaseContent.releaseTitle7.item2.text.t2')
                            // t3:t('resources.serverRelease.releaseContent.releaseTitle7.item2.text.t3')
                        }
                    },
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle7.item3.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle7.item3.text.t1')
                        }
                    }
                ],
                releaseTitle1:[
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle1.item1.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle1.item1.text.t1')
                        }
                    }
                ],
                releaseTitle2:[
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle2.item1.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle2.item1.text.t1'),
                            t2:t('resources.serverRelease.releaseContent.releaseTitle2.item1.text.t2'),
                            t3:t('resources.serverRelease.releaseContent.releaseTitle2.item1.text.t3'),
                            t4:t('resources.serverRelease.releaseContent.releaseTitle2.item1.text.t4'),
                            t5:t('resources.serverRelease.releaseContent.releaseTitle2.item1.text.t5')
                        }
                    },
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle2.item2.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle2.item2.text.t1'),
                            t2:t('resources.serverRelease.releaseContent.releaseTitle2.item2.text.t2'),
                            t3:t('resources.serverRelease.releaseContent.releaseTitle2.item2.text.t3')
                        }
                    }
                ],
                releaseTitle3:[
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle3.item1.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle3.item1.text.t1'),
                            t2:t('resources.serverRelease.releaseContent.releaseTitle3.item1.text.t2'),
                            t3:t('resources.serverRelease.releaseContent.releaseTitle3.item1.text.t3'),
                            t4:t('resources.serverRelease.releaseContent.releaseTitle3.item1.text.t4')
                        }
                    }
                ],
                releaseTitle4:[
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle4.item1.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle4.item1.text.t1'),
                            t2:t('resources.serverRelease.releaseContent.releaseTitle4.item1.text.t2'),
                            t3:t('resources.serverRelease.releaseContent.releaseTitle4.item1.text.t3'),
                            t4:t('resources.serverRelease.releaseContent.releaseTitle4.item1.text.t4'),
                            t5:t('resources.serverRelease.releaseContent.releaseTitle4.item1.text.t5'),
                            t6:t('resources.serverRelease.releaseContent.releaseTitle4.item1.text.t6')
                        }
                    },
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle4.item2.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle4.item2.text.t1'),
                            t2:t('resources.serverRelease.releaseContent.releaseTitle4.item2.text.t2')
                        }
                    }
                ],
                releaseTitle5:[
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle5.item1.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle5.item1.text.t1'),
                            t2:t('resources.serverRelease.releaseContent.releaseTitle5.item1.text.t2')
                        }
                    }
                ],
                releaseTitle6:[
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle6.item1.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle6.item1.text.t1'),
                            t2:t('resources.serverRelease.releaseContent.releaseTitle6.item1.text.t2'),
                            t3:t('resources.serverRelease.releaseContent.releaseTitle6.item1.text.t3'),
                            t4:t('resources.serverRelease.releaseContent.releaseTitle6.item1.text.t4'),
                            t5:t('resources.serverRelease.releaseContent.releaseTitle6.item1.text.t5'),
                            t6:t('resources.serverRelease.releaseContent.releaseTitle6.item1.text.t6'),
                            t7:t('resources.serverRelease.releaseContent.releaseTitle6.item1.text.t7')
                        }
                    },
                    {
                        subtitle:t('resources.serverRelease.releaseContent.releaseTitle6.item2.subtitle'),
                        text: {
                            t1:t('resources.serverRelease.releaseContent.releaseTitle6.item2.text.t1')
                        }
                    }
                ]
            }
        }
    
    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) { 
				//anchorElement.scrollIntoView({block: "start", behavior: "smooth" }); 
				document.documentElement.scrollTop = anchorElement.offsetTop - document.getElementsByTagName("header")[0].clientHeight;
			}
        }
    }

    const renderTitleList = obj => {
        let returnli = null;
        returnli = Object.keys(obj).map((key,index)=>{
            const onClick = () => {
                scrollToAnchor(key);
            };
            return (
                <li className="releaseTitleLink" key={index}>
                    <div onClick={onClick}>• {obj[key]}</div>
                </li>
            )
        })
        return returnli;
    }

    const renderContentBlockList = obj => {
        let returDom = null;
        returDom = Object.keys(obj).map((key,index)=>{
            
            return (
                <div className="releaseContentBlockList" id={key} key={index}>
                    <div className="blockTitle">{pageDataObj.releaseTitleList[key]}</div>
                    <ReleaseContentBlock {...props} dataObj={obj[key]} key={key} />
                    
                </div>
            )
        })
        return returDom;
    }
       
    return (
        <div className="releaseContent">
            <div className="productCompareInfo suiteReleaseInfoWrap">
                <h2 className="font-weight-bold">{pageDataObj.info.t1}</h2>
            </div>
            
            <div>
                <ul>
                {
                    renderTitleList(pageDataObj.releaseTitleList)
                }
                </ul>
            </div>
            <div className='tips'>{t('resources.productDoc.tips')}</div>
            {
                renderContentBlockList(pageDataObj.releaseContentBlock)
            }
            {/*
            <div className="compareTableNote">
                <span className="d-inline-block mr-3">
                    ●：{t('comparePage.note.support')}
                </span>
                <span className="d-inline-block mr-3">
                    ○：{t('comparePage.note.supportApart')}
                </span>
                <span className="d-inline-block">
                    ─：{t('comparePage.note.unsupport')}
                </span>
            </div> */}
        </div>
    )
}

export default SuiteRelease;