import React from 'react';
import {compose} from 'redux';
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const mixinWrapper = ChildComponent => {
    class ComposedComponent extends React.PureComponent{
        constructor(props){
            super(props);
            this.state = {
                linkObj:{
                    header:{
                        freetrail:{
                            name:'header.download',
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/download/central.aspx?lanID='+this.props.currentLang.typeNum
                                }
                                else{
                                    return 'https://store.rightpdf.com/download/central.aspx?lanID='+this.props.currentLang.typeNum
                                }
                            }
                        },
                        buy:{
                            name:'header.buy',
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/store/overview.aspx?lanID='+this.props.currentLang.typeNum
                                }
                                else{
                                    return 'https://store.rightpdf.com/store/overview.aspx?lanID='+this.props.currentLang.typeNum
                                }
                            }
                        },
                        upgrade:{
                            name:'header.upgrade',
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/buy/upgrade-add-user.aspx?lanID='+this.props.currentLang.typeNum
                                }
                                else{
                                    return 'https://store.rightpdf.com/buy/upgrade-add-user.aspx?lanID='+this.props.currentLang.typeNum
                                }
                            }
                        },
                        signIn:{
                            name:'header.signIn',
                            url:() => {
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/account/login.aspx?lanID='+this.props.currentLang.typeNum
                                }
                                else{
                                    return 'https://store.rightpdf.com/account/login.aspx?lanID='+this.props.currentLang.typeNum
                                }
                            }
                        }
                    },
                    downloadLink:{
                        suite:{
                            url:() =>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/Download/pro.aspx?lanID='+this.props.currentLang.typeNum
                                }
                                else{
                                    return 'https://store.rightpdf.com/Download/pro.aspx?lanID='+this.props.currentLang.typeNum
                                }
                            }
                        },
                        mac:{
                            url:() =>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/Download/mac.aspx?lanID='+this.props.currentLang.typeNum
                                }
                                else{
                                    return 'https://store.rightpdf.com/Download/mac.aspx?lanID='+this.props.currentLang.typeNum
                                }
                            }
                        },
						christmas:{
							details:{
								url:() =>{
									return 'https://r.email.rightpdf.com/mk/mr/sh/1t6AVsg9Ynm8rNOWCHsXKoqDy2HJEg/4QUNx_vNFxNb'
								}
							},
							pro:{
								url:() =>{
									return 'https://www.rightpdf.com/'+ this.props.currentLang.type +'/pro'
								}
							},
							mac:{
								url:() =>{
									return 'https://www.rightpdf.com/'+ this.props.currentLang.type +'/mac'
								}
							},
							buyLink_christmas:{
								url: type => {
									if(this.props.currentLang.type === 'zh-CN'){
										return 'https://store.rightpdf.cn/store/overview.aspx?lanID =1#suite_sub'
									}else{
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+this.props.currentLang.typeNum+(type?type:'')
									}
									
								}
							}
						},
						s11:{
							pro:{
								url:() =>{
									return 'https://www.rightpdf.com/'+ this.props.currentLang.type +'/pro'
								}
							},
							mac:{
								url:() =>{
									return 'https://www.rightpdf.com/'+ this.props.currentLang.type +'/mac'
								}
							},
							pro_buy:{
								url:() =>{
									if(this.props.currentLang.type === 'zh-CN'){
										return 'https://store.rightpdf.cn/store/overview.aspx?lanID =1#suite_sub'
									}else if(this.props.currentLang.type === 'zh-TW'){
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+ this.props.currentLang.typeNum +'&pcode=Warm12W'
									}else if(this.props.currentLang.type === 'en'){
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+ this.props.currentLang.typeNum +'&pcode=Warm12W'
									}else if(this.props.currentLang.type === 'jp'){
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+ this.props.currentLang.typeNum +'&pcode=Warm12W'
									}else if(this.props.currentLang.type === 'kr'){
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+ this.props.currentLang.typeNum +'&pcode=Warm12W'
									}
									
								}
							},
							mac_buy:{
								url:() =>{
									if(this.props.currentLang.type === 'zh-TW'){
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+ this.props.currentLang.typeNum +'&pcode=Warm12M'
									}else if(this.props.currentLang.type === 'en'){
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+ this.props.currentLang.typeNum +'&pcode=Warm12M'
									}else if(this.props.currentLang.type === 'jp'){
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+ this.props.currentLang.typeNum +'&pcode=Warm12M'
									}else if(this.props.currentLang.type === 'kr'){
										return 'https://store.rightpdf.com/store/overview.aspx?lanID='+ this.props.currentLang.typeNum +'&pcode=Warm12M'
									}
									
								}
							},
							coupon:{
								url:() =>{
									return 'https://b2b.ticketxpress.com.tw/Member/ProductDetail/828d8ee6-ec16-4478-ba7e-85ebfc793b90'
								}
							}
						},
                        converter:{
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/download/converter.aspx?lanID='+this.props.currentLang.typeNum
                                }else{
                                    return 'https://store.rightpdf.com/download/converter.aspx?lanID='+this.props.currentLang.typeNum
                                }

                            }
                        },
                        server:{
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/Download/server.aspx?lanID='+this.props.currentLang.typeNum
                                }else{
                                    return 'https://store.rightpdf.com/Download/server.aspx?lanID='+this.props.currentLang.typeNum
                                }

                            }
                        },
                        pc:{
                            url:() =>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/Download/reader.aspx?lanID='+this.props.currentLang.typeNum
                                }
                                else{
                                    return 'https://store.rightpdf.com/Download/reader.aspx?lanID='+this.props.currentLang.typeNum
                                }

                            }
                        },
                        ios:{
                            url:(type)=>{
                                if(this.props.currentLang.type === 'zh-TW' || this.props.currentLang.type === 'zh-CN'){
                                    return 'https://apps.apple.com/'+this.props.currentLang.type.split("-")[1].toLowerCase()+'/app/' + (type === 'ipad'?'gaaiho-pdf-hd/id667279313':'gaaiho-pdf/id690338821')
                                }else if(this.props.currentLang.type === 'en'){
                                    return 'https://apps.apple.com/us/app/' + (type === 'ipad'?'gaaiho-pdf-hd/id667279313':'gaaiho-pdf/id690338821');
                                }
                                else{
                                    return 'https://apps.apple.com/'+this.props.currentLang.type + '/app/'+(type === 'ipad'?'gaaiho-pdf-hd/id667279313':'gaaiho-pdf/id690338821')
                                }
                            }
                        },
                        android:{
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://www.wandoujia.com/apps/6565587';
                                }else{
                                    return 'https://play.google.com/store/apps/details?id=com.zeon.Gaaiho.Reader';
                                }
                            }
                        }
                    },
                    newFeature:{
                        name:'header.newFeature',
                        path:'/newFeature'
                    },
                    buyLink:{
                        url: type => {
                            if(this.props.currentLang.type === 'zh-CN'){
                                return 'https://store.rightpdf.cn/store/overview.aspx?lanID='+this.props.currentLang.typeNum+(type? type:'')
                            }else{
                                return 'https://store.rightpdf.com/store/overview.aspx?lanID='+this.props.currentLang.typeNum+(type?type:'')
                            }
                            
                        }
                    },
                    macdownloadLink:{
                        url: ()=> {
							if(this.props.currentLang.type === 'zh-TW' || this.props.currentLang.type === 'zh-CN'){
								return 'https://apps.apple.com/'+this.props.currentLang.type.split("-")[1].toLowerCase()+'/app/id1603170454'
							}else if(this.props.currentLang.type === 'en'){
                                return 'https://apps.apple.com/app/id1603170454'
                            }else{
                                return 'https://apps.apple.com/'+this.props.currentLang.type+'/app/id1603170454'
                            }
                            
                        }
                    },
                    upgradeLink:{
                        url: type => {
                            if(this.props.currentLang.type === 'zh-CN'){
                                return 'https://store.rightpdf.cn/buy/upgrade-add-user.aspx?lanID='+this.props.currentLang.typeNum+(type? type:'')
                            }else{
                                return 'https://store.rightpdf.com/buy/upgrade-add-user.aspx?lanID='+this.props.currentLang.typeNum+(type?type:'')
                            }
                            
                        }
                    },
                    product:{
                        name:'header.productNew',
                        path:'/product',
                        header:{
                            block1:{
                                t1:'header.hideMenu.product.block1.t1',
                                t2:'header.hideMenu.product.block1.t2',
                            },
                            block2:{
                                t1:'header.hideMenu.product.block2.t1',
                                t2:'header.hideMenu.product.block2.t2'
                            },
                            block3:{
                                t1:'header.hideMenu.product.block3.t1',
                                t2:'header.hideMenu.product.block3.t2',
                                t3:'header.hideMenu.product.block3.t3'
                            },
                            block4:{
                                t1:'header.hideMenu.product.block4.t1',
                                t2:'header.hideMenu.product.block4.t2',
                                btn1:'header.hideMenu.product.block4.btn1',
                                btn2:'header.hideMenu.product.block4.btn2'
                            },
                            block4_1:{
                                t1:'header.hideMenu.product.block4_1.t1',
                                t2:'header.hideMenu.product.block4_1.t2'
                            },
                            block5:{
                                t1:'header.hideMenu.product.block5.t1',
                                t2:'header.hideMenu.product.block5.t2'
                            },
                            block6:{
                                t1:'header.hideMenu.product.block6.t1',
                                t2:'header.hideMenu.product.block6.t2'
                            },
                            block7:{
                                t1:'header.hideMenu.product.block7.t1'
                            },
                            block8:{
                                t1:'header.hideMenu.product.block8.t1'
                            },
                            block9:{
                                t1:'header.hideMenu.product.block9.t1'
                            },
                            block10:{
                                t1:'header.hideMenu.product.block10.t1'
                            },
                            block11:{
                                t1:'header.hideMenu.product.block11.t1'
                            },
                            block12:{
                                t1:'header.hideMenu.product.block12.t1'
                            },
                            block13:{
                                t1:'header.hideMenu.product.block13.t1'
                            }
                        },
                        addpdf:{
                            url:() => {
                                if(this.props.currentLang.type === 'zh-TW'){
                                    return 'https://online.rightpdf.com/tw/home';
                                }else if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://online.rightpdf.com/cn/home';
                                }else if(this.props.currentLang.type === 'en'){
                                    return 'https://online.rightpdf.com/en/home'
                                }else{
                                    return 'https://online.rightpdf.com/'+this.props.currentLang.type+'/home';
                                }
                            },
                            url_pdf2word:() => {
                                if(this.props.currentLang.type === 'zh-TW'){
                                    return 'https://online.rightpdf.com/tw/pdf-to-word';
                                }else if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://online.rightpdf.com/cn/pdf-to-word';
                                }else if(this.props.currentLang.type === 'en'){
                                    return 'https://online.rightpdf.com/en/pdf-to-word'
                                }else{
                                    return 'https://online.rightpdf.com/'+this.props.currentLang.type+'/pdf-to-word';
                                }
                            },
                            url_pdf2jpg:() => {
                                if(this.props.currentLang.type === 'zh-TW'){
                                    return 'https://online.rightpdf.com/tw/pdf-to-jpg';
                                }else if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://online.rightpdf.com/cn/pdf-to-jpg';
                                }else if(this.props.currentLang.type === 'en'){
                                    return 'https://online.rightpdf.com/en/pdf-to-jpg'
                                }else{
                                    return 'https://online.rightpdf.com/'+this.props.currentLang.type+'/pdf-to-jpg';
                                }
                            },
                            url_pdf2img:() => {
                                if(this.props.currentLang.type === 'zh-TW'){
                                    return 'https://online.rightpdf.com/tw/pdf-to-img';
                                }else if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://online.rightpdf.com/cn/pdf-to-img';
                                }else if(this.props.currentLang.type === 'en'){
                                    return 'https://online.rightpdf.com/en/pdf-to-img'
                                }else{
                                    return 'https://online.rightpdf.com/'+this.props.currentLang.type+'/pdf-to-img';
                                }
                            },
                            url_removewatermark:() => {
                                if(this.props.currentLang.type === 'zh-TW'){
                                    return 'https://online.rightpdf.com/tw/remove-watermark';
                                }else if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://online.rightpdf.com/cn/remove-watermark';
                                }else if(this.props.currentLang.type === 'en'){
                                    return 'https://online.rightpdf.com/en/remove-watermark'
                                }else{
                                    return 'https://online.rightpdf.com/'+this.props.currentLang.type+'/remove-watermark';
                                }
                            },
                            url_mergepdf:() => {
                                if(this.props.currentLang.type === 'zh-TW'){
                                    return 'https://online.rightpdf.com/tw/merge-pdf';
                                }else if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://online.rightpdf.com/cn/merge-pdf';
                                }else if(this.props.currentLang.type === 'en'){
                                    return 'https://online.rightpdf.com/en/merge-pdf'
                                }else{
                                    return 'https://online.rightpdf.com/'+this.props.currentLang.type+'/merge-pdf';
                                }
                            }
                        },
                        suite:{
                            path:'/suite',
                            readmore:{
                                path:'/suite/readmore/:id'
                            }
                        },
                        converter:{
                            path:'/converter',
                            readmore:{
                                path:'/converter/readmore/:id'
                            }
                        },
                        server:{
                            path:'/server',
                            readmore:{
                                path:'/server/readmore/:id'
                            }
                        },
                        sdk:{
                            path:'/sdk',
                            readmore:{
                                path:'/sdk/readmore/:id'
                            }
                        },
                        reader:{
                            pc:{
                                path:'/reader/pc',
                                readmore:{
                                    path:'/pc/readmore/:id'
                                }
                            },
                            app:{
                                path:'/reader/app',
                                readmore:{
                                    path:'/pc/readmore/:id'
                                }
                            }
                        },
						macOS:{
							path:"/mac"
						}
                        // readmore:{
                        //     suite:{
                        //         path:'/suite/Readmore/:id'
                        //     }
                            
                        // }                            
                    },
					macOS:{
						name:'header.macOS'
					},
					Mobile:{
						name:'header.Mobile'
					},
					rightPDFOnline:{
						name:'header.rightPDFOnline',
						name_footer:'footer.rightPDFOnline'
					},
                    support:{
                        name:'header.support',
                        header:{
                            block1:{
                                name:'support.name',
                                link1:'support.faq',
                                link2:'support.submitTicket'
                            },
                            block2:{
                                name:'tutorials.name',
                                link1:'tutorials.pdfhome',
                                link2:'tutorials.userManuals',
                                link3:'tutorials.guide'
                            }
                        },
                        faq:{
                            name:'support.faq',
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-TW' || this.props.currentLang.type === 'zh-CN'){
                                    return 'https://support.rightpdf.com/hc/'+this.props.currentLang.type.toLowerCase();
                                }else{
                                    return 'https://support.rightpdf.com/hc/en-us';
                                }
                            }
                        },
                        submitTicket:{
                            name:'support.submitTicket',
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-TW' || this.props.currentLang.type === 'zh-CN'){
                                    return 'https://support.rightpdf.com/hc/'+this.props.currentLang.type.toLowerCase()+'/requests/new'
                                }else{
                                    return 'https://support.rightpdf.com/hc/en-us/requests/new';
                                }
                            }
                        },
                    },
                    tutorials:{
                        name:'tutorials.name',
                        pdfhome:'tutorials.pdfhome',
                        userManuals:{
                            name:'tutorials.userManuals',
                            path:'/help'
                        },
                        guide:{
                            name:'tutorials.guide',
                            path:'/Tutorials/guide'
                        }
                    },
                    resources:{
                        name:'resources.name',
                        compare:{
                            name:'resources.link1',
                            path:'/Resources/compared/productCompare'
                        },
                        suiteCompare:{
                            name:'resources.suiteCompare.name',
                            path:'/Resources/compared/proCompare'
                        },
                        converterCompare:{
                            name:'resources.converterCompare.name',
                            path:'/Resources/compared/converterCompare'
                        },
                        subscriptionCompare:{
                            name:'productPage.subscription.question',
                            path:'/Resources/compared/subscriptionCompare'
                        },
                        remoteEnterprise:{
                            name:'home.compare.remote.link',
                            path:'/Resources/compared/remoteEnterprise'
                        },
                        tslCompare:{
                            name:'resources.tslCompare.name',
                            path:'/Resources/compared/tslCompare'
                        },
                        documents:{
                            name:'resources.link2',
                            path:'/Resources/productDocuments/main'
                        },
                        sdk:{
                            name:'resources.link3',
                            path:'/Resources/sdkResources/main'
                        },
                        oldVersion:{
                            name:'resources.link5',
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://store.rightpdf.cn/download/archive.aspx?lanID='+this.props.currentLang.typeNum
                                }
                                else{
                                    return 'https://store.rightpdf.com/download/archive.aspx?lanID='+this.props.currentLang.typeNum
                                }
                            }
                        },
                        release:{
                            name:'resources.link6',
                            path:'/Resources/release/proRelease'
                        },
                        newMacOSRelease:{
                            name:'resources.link10',
                            path:'/Resources/release/newMacOSRelease'
                        },
                        convertRelease:{
                            name:'resources.link7',
                            path:'/Resources/release/convertRelease'
                        },
                        serverRelease:{
                            name:'resources.link8',
                            path:'/Resources/release/serverRelease'
                        },
                        readerRelease:{
                            name:'resources.link9',
                            path:'/Resources/release/readerRelease'
                        },
                        macOSRelease:{
                            name:'resources.link10',
                            path:'/Resources/release/macOSRelease'
                        },
                        remoteEnterpriseFaq:{
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-TW'){
                                    return 'https://support.rightpdf.com/hc/zh-tw/sections/200217803-%E6%8E%88%E6%AC%8A%E7%AE%A1%E7%90%86';
                                }else if(this.props.currentLang.type === 'zh-CN'){
                                    return 'https://support.rightpdf.com/hc/zh-cn/sections/200217803-%E6%8E%88%E6%AC%8A%E7%AE%A1%E7%90%86';
                                }else{
                                    return 'https://support.rightpdf.com/hc/en-us/sections/200217803-License-Management';
                                }
                            }
                        },
                    },
                    AboutZeon:{
                        name:'AboutZeon.name',
                        linkAry:[
                            {
                                name:'AboutZeon.link1',
                                path:'/About/aboutus'
                            },
                            {
                                name:'AboutZeon.link2',
                                url:()=>{
                                 return this.props.currentLang.type === 'zh-CN' ? 'https://weibo.com/zeongaaiho':'https://blog.rightpdf.com/'
                                }
                            },
                            {
                                name:'AboutZeon.link3',
                                path:'/About/media'
                            },
                            {
                                name:'AboutZeon.link4',
                                path:'/About/caseStudies'
                            },
                            {
                                name:'AboutZeon.link5',
                                path:'/About/legal'
                            },
                            {
                                name:'AboutZeon.link6',
                                path:'/About/becomePartner'
                            }
                        ]
                    },
                    legal:{
                        zeon_software_license_terms_for_gaaiho_sdk:{
                            path:'/About/legal/zeon-software-license-terms-for-rightpdf-sdk'
                        },
                        refund_policy:{
                            path:'/About/legal/refund-policy'
                        },
                        eula_for_gaaiho_products:{
                            path:'/About/legal/eula-for-rightpdf-products'
                        },
                        oem_license:{
                            path:'/About/legal/oem-license'
                        },
                        maintenance_service:{
                            path:'/About/legal/maintenance-service'
                        },
                        support_policies:{
                            path:'/About/legal/support-policies'
                        },
                        upgrade_licenses:{
                            path:'/About/legal/upgrade-licenses'
                        },
                        terms_of_use:{
                            path:"/About/legal/terms-of-use"
                        },
                        privacy_statement:{
                            path:"/About/legal/privacy-statement"
                        }
                    },
                    contact:{
                        name:'contact.name',
                        link1:'contact.link1',
                        link2:'contact.link2',
                        link1_2:'contact.link1_2',
                        link2_2:'contact.link2_2',
                        link3:'contact.link3',
                        contactSales:{
                            path:'/contactSales'
                        },
                        contactUs:{
                            name:'contactUs.name',
                            path:'/contactUs'
                        },
                        pdfhome:{
                            url:()=>{
                                if(this.props.currentLang.type === 'zh-TW' || this.props.currentLang.type === 'zh-CN'){
                                    return 'https://www.pdfhome.com/'+this.props.currentLang.type.toLowerCase()+'/home'
                                }
                            }
                        },
                    },
                    pdfSuite:{
                        name:'pdfSuite.name',
                        path:'/pdf-suite'
                    },
                    qq: {
                        url:()=>{
                            return 'https://wpa.qq.com/msgrd?v=3&uin=2816214541&site=qq&menu=yes';
                        }
                    }
                }
            }
        }
        renderLiLink = type => {
            const {t} = this.props;
            let linkList = this.state.linkObj[type].linkAry.map((e,i)=>{
                //判斷代理商頁面(只有韓文)
                if(this.props.currentLang.type !== 'kr' && type === 'AboutZeon' && i === 5) {
                    return '';
                } 
                
                if(e.url){
                    return (
                        <li key={i}>
                            <a href={e.url()} target="_blank" rel="noopener noreferrer">
                                {t(e.name)}
                            </a>
                        </li>
                    )
                }else{
                    return (
                        <li key={i}>
                            <Link to={`/${this.props.currentLang.type}${e.path}`}>
                                {t(e.name)}
                            </Link>
                        </li>
                    )
                }
            })
            return linkList;
        }
        componentDidMount(){}
        render(){
            return (
                <ChildComponent {...this.props} linkObj={this.state.linkObj} renderLiLink={this.renderLiLink} />
            )
        }
    }
    return withTranslation()(ComposedComponent);
}
const mapStateToProps = state => {
    return {
        currentLang:state[0].currentLang,
        popUp:{
            show:state[1].show,
            content:state[1].popupContent
        },
        mobileMenu:state[2].moblieShow,
        desktopMenu:state[2].desktopShow,
        typeListAry: state[3].typeListAry,
        featureListAry: state[3].featureListAry

    }
}
const composedFieldWrapper = compose(connect(mapStateToProps,null),mixinWrapper);
export default composedFieldWrapper;
