const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>激活授权码或序列号时，出现错误码 0x73</title></head><body>  <h1>激活授权码或序列号时，出现错误码 0x73</h1>  <p><img src="https://store.rightpdf.com/images/rtsupport/0x73CHS.jpg" alt="0x73CHS.jpg" width="526" height="366"></p>
<h2><strong>问题描述：</strong></h2>
<p>当激活授权码或序<span class="wysiwyg-color-blue130">列</span>号时，激活失败，出现错误码 0x73。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>激活时，出现错误码「0x73」代表您所输入的授权码或序列号不符合您目前安装的文电通产品版本（例如：尝试以「文电通PDF macOS 1.0」序列号启动「文电通PDF macOS 2.0」软件）。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">此时，您可以选择 (1) 下载安装符合您购买之序列号的文电通产品 或 (2) 购买升级到最新的文电通版本。</span></p>
<p><span class="wysiwyg-color-blue130">(1) 下载安装符合您购买之序列号的文电通产品：</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">卸载您目前文电通软件；</span></li>
<li>
<span class="wysiwyg-color-blue130"><span class="wysiwyg-color-blue130"><span class="wysiwyg-color-blue130">下载符合您当初购买之序列号的文电通版本（文电通PDF macOS 1.0 的用户，请联系 </span></span></span><span class="wysiwyg-color-blue130"><span class="wysiwyg-color-blue130"><a href="mailto:sales@rightpdf.com">sales@rightpdf.com</a></span></span><span class="wysiwyg-color-blue130">）；</span>
</li>
<li><span class="wysiwyg-color-blue130">安装正确版本之文电通产品</span></li>
<li><span class="wysiwyg-color-blue130">激活（<a href="https://support.rightpdf.com/hc/zh-cn/articles/4949022677273" target="_self" rel="undefined">如何在线/离线激活 文电通PDF macOS?</a>、<a href="https://support.rightpdf.com/hc/zh-cn/articles/4920801019417" target="_self" rel="undefined">如何在线/离线反激活 文电通PDF macOS?</a> ）</span></li>
</ol>
<p><span class="wysiwyg-color-blue130">(2) 购买升级到最新的文电通版本：</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">请洽<a href="https://store.rightpdf.cn/buy/upgrade-add-user.aspx" target="_self" rel="undefined">升级与加购页面</a>购买最新版文电通PDF！</span></li>
</ol>
<p> </p></body></html>`;   export default html;