const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何计算转换上限？</title></head><body>  <h1>如何计算转换上限？</h1>  <p>Right PDF/ Gaaiho PDF转换器有转换上限的限制，一般默认的转换上限为「每24小时 1000页/100个文档」，如果需要更高的转换量，可以参考「<a href="https://www.rightpdf.com/zh-CN/Resources/compared/remoteEnterprise" target="_blank" rel="noopener">终端服务器授权</a>」或「<a href="https://rightpdf.com/zh-CN/server" target="_blank" rel="noopener">文电通PDF转换服务器版</a>」。</p>
<p>但是转换上限该如何计算呢？</p>
<p>以「每24小时 1000页/100个文档」为例：</p>
<ul>
<li>
<strong>24</strong><strong>小时</strong>：转换器在第一次执行转换时，会记录下时间，并在24小时后将转换数归零。</li>
<li>
<strong>1000</strong><strong>页</strong><strong>/100</strong><strong>个文档</strong>：在这24小时内，只有当两个条件（「1000页」和「100档案」）都达到时，才会停止转换，并收到「<a href="https://support.rightpdf.com/hc/zh-cn/articles/201998916" target="_blank" rel="noopener">Limit is Exceeded</a>」的提示。</li>
<li>
<strong>计算方式</strong>：将其它文件类型转换成PDF时，才会计算（如果将多个PDF文件合并，或将PDF文件转换成其它文件类型，不予计算）。</li>
</ul>
<h3>
<strong>范例</strong><strong>1</strong><strong>：</strong>
</h3>
<p>小民使用<strong>永久版</strong>的<strong>PDF</strong><strong>文电通</strong><strong>套装</strong><strong>版</strong>中的<strong>转换器</strong>（预设「每24小时 1000页/100个文档」）将2个各自600页的Word文件和Excel文件转换成PDF，请问，小民是否到达转换上限？还能转换多少页或文件？</p>
<p>此时，小民总共转换了2个文件，共1,200页。</p>
<p><span class="wysiwyg-color-red"><strong>答案</strong></span>：小民只达到了「1000页」的上限，但在「100个文档」的限制上，只使用了2个文档，因此，<strong>未达</strong>转换上限，且<strong>还可以再转换</strong><strong>98</strong><strong>个文档，且不限页数</strong>。</p>
<p> </p>
<h3>
<strong>范例</strong><strong>2</strong><strong>：</strong>
</h3>
<p>小花使用<strong>订阅版</strong>的<strong>文电通</strong><strong>PDF</strong><strong>转换器</strong>（预设「每24小时 1000页/100个文档」）<strong>合并</strong>3个文件（1个500页的Word文件、1个400页的PDF文件，和1个300页的PowerPoint文件）。请问，小花是否到达转换上限？还能转换多少页或文件？</p>
<p>此时，小花共转换了2个文档，共800页（只计算500页的Word和300页的PowerPoint，500页的PDF<strong>合并</strong>不算转换）。</p>
<p><span class="wysiwyg-color-red"><strong>答案</strong></span>：小花还<strong>未达</strong>转换上限，并且还剩下「200页/98个文档」的转换量。</p></body></html>`;   export default html;