const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为什么安装Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)时出现错误信息126？</title></head><body>  <h1>为什么安装Right PDF/ Gaaiho PDF转换器(Right PDF/ Gaaiho PDF Converter)时出现错误信息126？</h1>  <h2><strong>问题描述：</strong></h2>
<p>安装PDF文电通转换器(Right PDF/ Gaaiho PDF Converter)时出现错误讯息126，安装失败。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>错误代码126是说当呼叫Windows API AddPrinterDriver函数时某些模块丢失。 </p>
<p>这是在某些Windows版本没有安装必要的补丁时出现的问题。最恰当的方式是用<strong>Windows Update</strong>为您的Windows安装所有的更新文件至最新状态，不仅可以避免安装标准版时出错，也可让您的Windows更为安全稳定。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">倘若您有其它考虑而无法安装Windows更新文件，在此为您提供两个替代解决方案：</span></p>
<h3><span class="wysiwyg-color-blue130"><strong>解决方案 1 ：</strong></span></h3>
<p><span class="wysiwyg-color-blue130">(一个简单的解决方案) </span><br><span class="wysiwyg-color-blue130">1. 请检查您的系统中「<strong>framedyn.dll</strong>」是否可用</span><br><span class="wysiwyg-color-blue130">2. 如果不可用，从其它Windows系统复制一个「framedyn.dll」到相应的系统文件夹(路径：<span class="wysiwyg-color-black70"><strong>Windows\\system32\\dllcache\\framedyn.dll</strong></span>) </span><br><span class="wysiwyg-color-blue130">3. 点击「开始 &gt; 所有程序 &gt; 附件 &gt; 运行」。输入「<span class="wysiwyg-color-black70"><strong>%SystemRoot%\\system32\\wbem</strong></span>」，点击「确定」</span><br><span class="wysiwyg-color-blue130">4. 右键点击文件夹wbem中的framedyn.dll文件并选择「属性」。如果文件大小不是185,856，看第5步</span><br><span class="wysiwyg-color-blue130">5. 点击「开始 &gt; 所有程序 &gt; 附件 &gt; 运行」。输入「%SystemRoot%\\system32\\dllcache」，点击「确定」</span><br><span class="wysiwyg-color-blue130">6. 复制文件夹中的framedyn.dll文件到wbem文件夹替换原始文件</span><br><span class="wysiwyg-color-blue130"><strong><br></strong></span></p>
<h3><span class="wysiwyg-color-blue130"><strong>解决方案 2 ：<br></strong></span></h3>
<p><span class="wysiwyg-color-blue130">1. 在「开始」中右键点击「我的电脑」选择「属性」 </span><br><span class="wysiwyg-color-blue130">2. 在高级标签中点击「<strong>环境变量</strong>」 </span><br><span class="wysiwyg-color-blue130">3. 在「系统变量」中选择「Path」，并点击「编辑」 </span><br><span class="wysiwyg-color-blue130">4. 添加「<span class="wysiwyg-color-black70"><strong>%SystemRoot%\\System32\\Wbem</strong></span>」到「变量值」中并点击「确定」 </span><br><span class="wysiwyg-color-blue130">5. 重启计算机并重新安装程序</span></p>
<p>如果问题仍然存在，我们推荐您为您的Windows操作系统安装最新的更新文件。</p></body></html>`;   export default html;