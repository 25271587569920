import React from 'react';
import ReleaseContentBlock from '../../components/release/releaseContentBlock';

const macOSRelease = props => {
    const {t} = props;
    //const [selectList,setSelectList] = useState('');
    const pageDataObj = {
            info:{
                t1:t('resources.macOSRelease.name')
            },
            releaseTitleList:{
                releaseTitle4:t('resources.macOSRelease.releaseTitle4'),
                releaseTitle3:t('resources.macOSRelease.releaseTitle3'),
                releaseTitle2:t('resources.macOSRelease.releaseTitle2'),
                releaseTitle1:t('resources.macOSRelease.releaseTitle1')
            },
            releaseContentBlock:{
                releaseTitle4:[
                    {
                        subtitle:t('resources.macOSRelease.releaseContent.releaseTitle4.item1.subtitle'),
                        text: {
                            t1:t('resources.macOSRelease.releaseContent.releaseTitle4.item1.text.t1')
                        }
                    }
                ],
                releaseTitle3:[
                    {
                        subtitle:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.subtitle'),
                        text: {
                            t1:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t1'),
                            t2:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t2'),
                            t3:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t3'),
                            t4:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t4'),
                            t5:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t5'),
                            t6:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t6'),
                            t7:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t7'),
                            t8:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t8'),
                            t9:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t9'),
                            t10:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t10'),
                            t11:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t11'),
                            t12:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t12'),
                            t13:t('resources.macOSRelease.releaseContent.releaseTitle3.item1.text.t13')
                        }
                    }
                ],
                releaseTitle2:[
                    {
                        subtitle:t('resources.macOSRelease.releaseContent.releaseTitle2.item1.subtitle'),
                        text: {
                            t1:t('resources.macOSRelease.releaseContent.releaseTitle2.item1.text.t1')
                        }
                    }
                ],
                releaseTitle1:[
                    {
                        subtitle:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.subtitle'),
                        text: {
                            t1:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.text.t1'),
                            t2:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.text.t2'),
                            t3:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.text.t3'),
                            t4:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.text.t4'),
                            t5:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.text.t5'),
                            t6:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.text.t6'),
                            t7:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.text.t7'),
                            t8:t('resources.macOSRelease.releaseContent.releaseTitle1.item1.text.t8')
                        }
                    }
                ]
            }
        }
    
    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) { 
				//anchorElement.scrollIntoView({block: "start",inline:"nearest", behavior: "smooth" });
				document.documentElement.scrollTop = anchorElement.offsetTop - document.getElementsByTagName("header")[0].clientHeight;
			}
        }
    }

    const renderTitleList = obj => {
        let returnli = null;
        returnli = Object.keys(obj).map((key,index)=>{
            const onClick = () => {
                scrollToAnchor(key);
            };
            return obj[key] !== ''?(
                <li className="releaseTitleLink" key={index}>
                    <div onClick={onClick}>• {obj[key]}</div>
                </li>
            ):'';
        })
        return returnli;
    }

    const renderContentBlockList = obj => {
        let returDom = null;
        returDom = Object.keys(obj).map((key,index)=>{
            return pageDataObj.releaseTitleList[key] !== ''?(
                <div className="releaseContentBlockList" id={key} key={index}>
                    <div className="blockTitle">{pageDataObj.releaseTitleList[key]}</div>
                    <ReleaseContentBlock {...props} dataObj={obj[key]} key={key} />
                    
                </div>
            ):'';
        })
        return returDom;
    }
       
    return (
        <div className="releaseContent">
            <div className="productCompareInfo suiteReleaseInfoWrap">
                <h2 className="font-weight-bold">{pageDataObj.info.t1}</h2>
            </div>
            
            <div>
                <ul>
                {
                    renderTitleList(pageDataObj.releaseTitleList)
                }
                </ul>
            </div>
            <div className='tips'>{t('resources.productDoc.tips')}</div>
            {
                renderContentBlockList(pageDataObj.releaseContentBlock)
            }

        </div>
    )
}

export default macOSRelease;