import React, {useEffect, useState} from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { Link, useParams } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import feature1 from '../assets/img/readerPC/pdt_feature_01.png';
// import feature2 from '../assets/img/readerPC/pdt_feature_02.png';
// import feature3 from '../assets/img/readerPC/pdt_feature_03.png';
import FeatureTable  from '../components/product/FeatureTable';
import {getFeatureList} from '../api';
import {useDispatch} from 'react-redux';
import MetaTags from 'react-meta-tags';

const ReaderPC = props => {
    const { lang } = useParams();
    const {t,currentLang,linkObj, i18n} = props;
    const dispatch = useDispatch();
    const [featureAry, setFeatureAry] = useState([]);
    const featureTypeId = '50645424-a5af-499b-821e-d33c2e454e2b';

    const renderFeatureImg = (imgNum) =>{  //eslint-disable-line no-unused-vars
        let returnElement = <img src={require(`../assets/img/readerPC/funIcon${imgNum}.png`)} className="img-fluid" alt={`${imgNum}`} />;
        return returnElement;
    }
    const renderpdf = () => {
        let returnElement = null;

        returnElement = currentLang.type === 'zh-TW'?  <li><a href="https://www.pdfhome.com/zh-TW/home" target="_blank" rel="noopener noreferrer"><span className="font-light">PDFhome 教學網</span></a></li>:null;
        return returnElement;
    }
    
    useEffect(() => {
        const fetchResource = cLang => {
            let lang = 'EN';
            if(cLang === 'zh-TW'){
                lang = 'CT';
            }else if(cLang === 'zh-CN'){
                lang = 'CS';
            }else{
                lang = cLang.toUpperCase();
            }
            getFeatureList({LgeID:lang, TypeID:featureTypeId, PageIndex:0, PageSize:100}).then(function (response) {
                dispatch({
                    type:'SEND_FEATURELIST',
                    featureListAry:response.data.List
                })
                setFeatureAry(response.data.List);
                // renderSuiteList(response.data.List);
            })
            .catch(function (error) {
                console.log('err',error);
            });
        
        }

        fetchResource(currentLang.type);
    }, [currentLang.type,dispatch]);

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    const renderIsTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "1"){
			return data.IsTop === "1" ? 
            //if((i % 2)===0){
				((i % 2)===0 ? 
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3">{data.Name}</h3>
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {data.IsMore===1?<Link to={`/${props.currentLang.type}/pc/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link>:''}
                        </div>
                    </div>

                )
            //} else {
                : (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-33 featureInfo alignRight align-middle">
                            <h3 className="font-weight-bold b-text mb-3">{data.Name}</h3>
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {data.IsMore===1?<Link to={`/${props.currentLang.type}/pc/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link>:''}
                        </div>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                    </div>

                ))
            //}
			: null;
        //}        
    });
    
    const renderIsNotTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "0"){
            return data.IsTop === "0" ? (
                <div className="d-50 mb-120" key={i}>
                    <img src={data.Img_pc} className="img-fluid isNotTop-img" alt="" />
                    <div className="featureInfo paddL35 align-middle">
                        <h3 className="font-weight-bold m-text">{data.Name}</h3>
                        <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                        {data.IsMore===1?<Link to={`/${props.currentLang.type}/pc/readmore/${data.ID}`} className="mt-20 b1Btn">
                            {t('learnMore')}
                        </Link>:''}
                    </div>
                </div>
                
            ) : null;
        //}
    });
    
    return (
        <section id="readerPC" className="productInfoContent">
            <MetaTags>
                <title>{t('metaTag.readerPc.title')}</title>
                <meta name="title" content={t('metaTag.readerPc.title')} />
                <meta name="description" content={t('metaTag.readerPc.desc')} />
                <meta name="keywords" content={t('metaTag.readerPc.keywords')} />
                <meta property="og:title" content={t('metaTag.readerPc.title')} />
                <meta property="og:description" content={t('metaTag.readerPc.desc')} />
            </MetaTags>

            <div className="topBlock contentBlock">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                             <h1 className="firstTitle">{t('readerPC.name')}</h1>
                              <p className="secondTitle">{t('readerPC.info')}</p>
                              <div className="btnGroup">
                                <a href={linkObj.downloadLink.pc.url()} className="winIcon icontop1 cBtn w_text greenBg text-center" target="_blank" rel="noopener noreferrer" style={{paddingTop:"22px"}}>
                                    {t('downloadNow2')}
                                </a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock whiteBg">
                <div className="customContainer">
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-100">
                            <h2 className="mb-title font-weight-bold mb-4">{t('productPage.featuresTitle')}</h2>
                            <p className="m-title m-0">{t('readerPC.featuresInfo.t1')}</p>
                        </div>
                    </div>
                    {/* <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-65 align-middle maxd819">
                            <img src={feature1}  className="img-fluid" alt="feature_01"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3">快速檢視PDF檔案</h3>
                            <p>閱讀PDF檔案時，享受飛快的啟動與顯示速度，讓各種內容一覽無遺，比如說文件、影像、試算表與報告。同時，您可以利用尺規、格線、多視窗、多頁面等瀏覽方式，大幅增進閱讀文件的效率。</p>
                            <a href="#" className="mt-20">
                                {t('learnMore')}
                            </a>
                        </div>
                    </div>
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-33 featureInfo alignRight align-middle">
                            <h3 className="font-weight-bold b-text mb-3">完整註解工具</h3>
                            <p>使用完整的PDF註解工具，為文件加上更豐富的內容，其中包括了附註、打字機、醒目提示、圖章、圖形等等。除此之外，您還能新增附件或是測量文件中的距離。 </p>
                            <a href="#" className="mt-20">
                                {t('learnMore')}
                            </a>
                        </div>
                        <div className="d-65 align-middle maxd819">
                            <img src={feature2}  className="img-fluid" alt="feature_02"/>
                        </div>
                    </div>
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-65 align-middle maxd819">
                            <img src={feature3}  className="img-fluid" alt="feature_03"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3">填寫表單</h3>
                            <p>收到PDF文件，可直接在閱讀器中填寫互動式表單、儲存然後遞回，過程輕鬆便利。 </p>
                            <a href="#" className="mt-20">
                                {t('learnMore')}
                            </a>
                        </div>
                    </div>
                    {/* feature list */}
                    {renderIsTopFeatureView}
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        {renderIsNotTopFeatureView}
                        {/* <div className="d-50">
                            {renderFeatureImg(1)}
                            <div className="featureInfo paddL35 align-middle">
                                <h3 className="font-weight-bold m-text">保護PDF檔案</h3>
                                <p>為PDF文件加入開啟密碼或簽名，鞏固重要資訊的安全性。</p>
                                <a href="#">
                                    {t('learnMore')}
                                </a>
                            </div>
                        </div>
                        <div className="d-50">
                            {renderFeatureImg(2)}
                            <div className="featureInfo paddL35 align-middle">
                                <h3 className="font-weight-bold m-text">組織PDF檔案</h3>
                                <p>運用全新的PDF管理員與雲端服務等功能，徹底掌握所有PDF檔案。</p>
                                <a href="#">
                                    {t('learnMore')}
                                </a>
                            </div>
                        </div> */}
                    </div>
                    
                     {/* feature detail */}
                     <div className="cRow maxW1300 mtb-120 grayBg-gallery">
                        <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block align-top" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.systemRequired.mainTitle')}
                            </h2>
                            <ul className="featureDetailList align-top">
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title1')}</p>
                                    <p>{t('suite.systemRequired.info1')}</p>
                                </li>
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title2')}</p>
                                    <p>{t('suite.systemRequired.info2')}</p>
                                    <p>{t('suite.systemRequired.info3')}</p>
                                    <p>{t('suite.systemRequired.info4')}</p>
                                </li>
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title3')}</p>
                                    <p>{t('suite.systemRequired.info5')}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.resourceTitle')}
                            </h2>
                            <ul className="featureDetailList boxList align-top">
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.tutorials.userManuals.path}`}>
                                        <span className="font-light">{t('tutorials.userManuals')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.compare.path}`}>
                                        <span className="font-light">
                                            {t('resources.link1').split("/")[0]} /<br/>
                                            {t('resources.link1').split("/")[1]}
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.documents.path}`}>
                                         <span className="font-light">
                                             {t('resources.link2').split("/")[0]} /<br/>
                                             {t('resources.link2').split("/")[1]}
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.release.path}`}>
                                        <span className="font-light">{t('resources.link6')}</span>
                                    </Link>
                                </li>
                                {
                                    renderpdf()
                                }
                            </ul>
                        </div>
                     </div>
                     {/* feature table */}
                     <div className="cRow maxW1300">
                        <div className="d-100">
                            <h2 className="mb-title font-weight-bold mb-120">{t('productPage.compareTitle')}</h2>
                            <FeatureTable t={t} linkObj={linkObj} highLightIndex={1} currentLang={currentLang} />
                            <p  className="font-light" style={{color:'black',marginBottom:'50px'}}>
                                {t('home.compare.remote.info')}
                                <Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`} style={{color:'black',fontWeight:'400'}}>
                                    {t('home.compare.remote.link')}
                                </Link>    
                            </p>
                            <Link to={`/${props.currentLang.type}${linkObj.resources.compare.path}`} className="cBtn b_border">
                                {t('home.compare.more')}
                            </Link>
                        </div>
                     </div>
                </div>
            </div>
            {/* feature bottom */}
            <div className="bottomBlock contentBlock text-white">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100"> 
                            <p className="secondTitle">{t('readerPC.bottomInfo')}</p>
                            <h1 className="firstTitle">{t('readerPC.name')}</h1>
                            <div className="btnGroup">
                                <a href={linkObj.downloadLink.pc.url()} className="cBtn w_text greenBg text-center" target="_blank" rel="noopener noreferrer" style={{paddingTop:"22px"}}>
                                    {t('downloadNow2')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>            
             </div>
        </section>
    )
}

export default mixinWrapper(ReaderPC);