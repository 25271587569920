const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何验证表单内容?</title></head><body>  <h1>如何验证表单内容?</h1>  <p>在众多表单相关问题当中，有个被反复询问的问题：如何验证表单内容呢？大多数使用者都知道可以设置域值来限制值的范围，但您知道Right PDF Editor/ Gaaiho Doc让您可以使用简单的Script （脚本）来验证表格吗？</p>
<p>不过首先让我们来复习一下如何不用Script就限制域值的范围。举例来说，只要在文本域属性对话框中的「验证」标签下设置域值最小为50 ，最大100之后，如果此文本域输入的值低于50或是高于100，就会出现错误信息显示此为无效值，并且提示此域值必须大于或等于50且小于或等于100。</p>
<p>1. 在Right PDF Editor/ Gaaiho Doc 中开启文件并选择「表单 &gt; 表单 &gt; 文本域」。</p>
<p>2. 在文件上拉出一个文本域。在跳出的文本域属性对话框中选择「验证」标签并勾选「验证域值」。如果已在「格式」标签设置此域值为「数字」，则可直接在「域值」设置数值的范围。</p>
<p>3. 输入域值。</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/2018-02-12_14-35-18.png" alt="2018-02-12_14-35-18.png" width="401" height="381"></p>
<p> </p>
<p>如果要执行其他自定义验证呢？举例来说，如果您想要建立一个只能让使用者输入文字与空白的文本框，可以参考以下的范例。请注意如果输入的域值包含数字，则会跳出错误信息提示使用者此域值的有效范围。</p>
<p>1. 以Right PDF Editor/ Gaaiho Doc开启文件并选择「表单 &gt; 表单 &gt; 文本域」。</p>
<p>2. 在文件上拉出文本域来开启文本域属性对话框。</p>
<p>3. 在对话框中选择「验证」标签并且勾选「验证域值」选项。接着点击「执行自定义验证脚本」及「编辑…」按钮。</p>
<p>4. 在「JavaScript 编辑器」对话框输入以下脚本:</p>
<p><img src="https://store.rightpdf.com/images/rtsupport/2018-02-12_14-35-39.png" alt="2018-02-12_14-35-39.png" width="404" height="425"></p>
<p>或是您可直接复制粘贴以下的脚本：</p>
<p> </p>
<p> </p>
<pre> var number = /[0-9]/;
if (number.test(event.value))
{
    app.alert("请只填写汉字、字母和空格");
    event.rc = false;
}

</pre>
<p> </p>
<p>接着我们来看看其他的例子。比如您想要建立只允许输入数字的「信用卡号码」文本框，则可参考以下范例。请注意如果输入的域值包含非数字的内容，则会跳出错误信息。</p>
<p> <img src="https://store.rightpdf.com/images/rtsupport/2018-02-08_17-13-42.png" alt="2018-02-08_17-13-42.png" width="609" height="37"></p>
<p>1. 选择「表单 &gt; 表单 &gt;文本域」并在文件上拉出文本域。小提醒：别忘了在「选项」标签下设置「组合4 个字符」。</p>
<p>2. 在「验证」标签下，点击「执行自定义验证脚本」及「编辑…」按钮。</p>
<p>3. 在「JavaScript 编辑器」对话框中输入以下脚本：</p>
<p> <img src="https://store.rightpdf.com/images/rtsupport/2018-02-12_14-35-55.png" alt="2018-02-12_14-35-55.png" width="400" height="412"></p>
<p> </p>
<p>此脚本不只验证域值是否只包含数字，也会检查每个值的范围是否都介于0跟9中间。您可直接复制粘贴下面的脚本：</p>
<p> </p>
<p> </p>
<pre>event.rc = true;
var regex = /^[0-9][0-9][0-9][0-9]$/;
if ( !regex.test(event.value) &amp;&amp; event.value)
{
    app.alert("请只填写数字");
    event.rc = false;
}

</pre>
<p> </p>
<p>在这个脚本中，「value」为当前的域值，而「rc」则会返回验证结果成功与否。您可能会注意到验证只在光标离开文本框时才执行，也就是说您必须要在输入完内容后点击Enter键或是点一下文本框外才能进行下去。当检查出错误时，无效值会被删除，让使用者必须得重新输入一次正确的域值才行。</p>
<p>然而，当您输入的内容又长又复杂的时候，这的确不是理想的验证方式。较理想的方式是检查出错误后，保留此错误值，让使用者可以直接修改错误之处然后按下Enter键，继续填写表单。</p>
<p>因此我们可以直接用颜色框出此文本框，让使用者知道此文本框的内容必须更正。请看以下的脚本：</p>
<p> </p>
<p> </p>
<pre> var number = /[0-9]/;
if (number.test(event.value))
{
    app.alert("请只填写汉字、字母和空格");
    getField("Text7").strokeColor = color.red;
}
else
{
    getField("Text7").strokeColor = color.black;
}

</pre>
<p> </p>
<p>您还可在表单的最末端建立一个「验证并打印」按钮，方便使用者填完表单后再次检查是否有遗漏的文本框，若检查无误再打印出来。此按钮按照此表单的文本框架构顺序来检查，如果发现空白，即会跳出错误信息提醒。请注意此验证功能关系到整份文档的JavaScript，因此在设置按钮的动作前必须要先设置文档JavaScript。</p>
<p>1. 建立「验证并打印」按钮且调整其外观。</p>
<p>2. 选择「高级 &gt; JavaScript &gt; 文档JavaScript」，并在JavaScript功能对话框中点击「添加…」按钮并为此文档JavaScript命名。点击「下一页」。</p>
<p>3. 在JavaScript编辑器对话框中，输入以下脚本：</p>
<p> <img src="https://store.rightpdf.com/images/rtsupport/2018-02-12_14-36-09.png" alt="2018-02-12_14-36-09.png" width="401" height="413"></p>
<p> </p>
<p>或是复制粘贴以下脚本：</p>
<p> </p>
<p> </p>
<pre> function isFieldEmpty(fieldname)
{
    var field = getField(fieldname);
    
    if ( field.type == "combobox" )
    {
        if (field.currentValueIndices == 0)
        {
            return true;
        }
    }
    else if ( field.type == "text" )
    {
        if (field.value.length == 0)
        {
            return true;
        }
    }
    return false;
}

</pre>
<p>此脚本会根据您想要验证的文本框类型而有不同。上面的脚本只验证文本域及组合框。</p>
<p>4. 点击确定并关闭。</p>
<p>5. 在此「验证并打印」按钮的按钮属性对话框中选择「动作」标签，并且从「选择触发器」下拉列表中选择可以触发指令的动作，然后从「选择动作」下拉列表中选择「运行JavaScript」。最后点击「添加…」</p>
<p>6. 在「JavaScript编辑器」对话框中输入或复制粘贴以下脚本并点击确定。请注意您必须一一列出所有希望被验证的文本框，并将文本框名称填写于「if (isFieldEmpty(「…」))」。错误信息文字也可自定义，只要加在每一个文本框的 「app.alert("…")」就好了。</p>
<p> <img src="https://store.rightpdf.com/images/rtsupport/2018-02-12_14-36-22.png" alt="2018-02-12_14-36-22.png" width="401" height="413"></p>
<p> </p>
<p>最后提醒大家，在验证表单的同时，别忘了清楚好懂的表单设计才是最重要的。</p>
<p> </p>
<p> </p></body></html>`;   export default html;