const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何在线清除激活记录(反激活)？</title></head><body>  <h1>如何在线清除激活记录(反激活)？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 23px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 23px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<h2> </h2>
<h2><strong>问题描述：</strong></h2>
<p>当激活产品时遇到错误「已经达到激活次数上限」，授权激活失败，但计算机中并没有使用中的文电通。 </p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>授权在某台不知名装置中激活着 或是该计算机已经损坏、系统重灌、格式化，导致无法手动反激活授权。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">当在线激活产品时提示已经达到激活次数上限，就必须在之前激活的计算机上进行反激活。但是之前的计算机可能不再使用而且无法在本地进行反激活。您就可以登录<a href="https://store.rightpdf.cn/account/login.aspx" target="_blank" rel="noopener">Right PDF网站</a>，在您的账戶下通过「清除激活纪录」来删除之前的激活记录，然后就可以再次激活产品了。</span><br><span class="wysiwyg-color-blue130"><font> <br></font> 1. 使用您的账号登录我们的<a href="https://store.rightpdf.cn/account/login.aspx" target="_blank" rel="noopener">网站</a></span><br><br></p>
<p><span class="wysiwyg-color-blue130">2. 从下拉式选单中选择「授权管理」（需要先完成<strong><a href="https://store.rightpdf.com/images/rtsupport/mceclip3 (5).png" target="_blank" rel="noopener">序列号绑定</a></strong>，您的序列号才会出现在授权管理中）</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/mceclip3 (5)" alt="mceclip3 (5).png" width="504" height="157"></p>
<p><span class="wysiwyg-color-blue130"> </span><br><span class="wysiwyg-color-blue130">3. 点击旁边的「<strong>X</strong>」，按照指示完成反激活步骤。这个操作将清除此序列号的所有激活记录，所有之前进行的激活将失效。删除激活记录之后，正在使用的产品可能需要重新激活</span></p>
<p><span class="wysiwyg-color-blue130"><img src="https://store.rightpdf.com/images/rtsupport/mceclip2 (8).png" alt="mceclip2 (8).png"></span></p>
<p><span class="wysiwyg-color-red"><strong>注意</strong>：如果您的序号有多个授权份数，清除启动纪录会一并影响所有的授权。您将可以继续使用它们，而一段时间后程式会变为试用版并要求您再度启动。</span><br> <br> <br>若您有这方面的问题，棣南的客服团队也非常乐意协助您解决。在<a href="https://support.rightpdf.com/hc/zh-tw/requests/new" target="_blank" rel="noopener">联络我们</a>时请别忘了提供订单编号或序号以利作业。</p>
<p> </p>
<p> </p>
<h3><span class="wysiwyg-color-green130"><strong>延伸阅读</strong><strong>：</strong></span></h3>
<ul>
<li><a href="https://support.rightpdf.com/hc/zh-cn/articles/900001028146-%E5%A6%82%E4%BD%95%E8%A7%A3%E5%86%B3%E6%97%A0%E6%B3%95%E6%89%8B%E5%8A%A8%E5%8F%8D%E6%BF%80%E6%B4%BB%E6%96%87%E7%94%B5%E9%80%9A%E8%BD%AF%E4%BB%B6%E6%8E%88%E6%9D%83%E9%97%AE%E9%A2%98-%E4%BC%81%E4%B8%9A%E6%8E%88%E6%9D%83%E7%AE%A1%E7%90%86-" target="_blank" rel="noopener">如何解决无法手动反激活文电通软件授权问题(企业授权管理)？</a></li>
</ul></body></html>`;   export default html;