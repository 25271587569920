import React from 'react';
import MetaTags from 'react-meta-tags';
const SuiteCompare = props => {
	const metaTagscontent = {
		title:'了解您的选择-探索全新的PDF替代方案-IT评估指南-文电通官网',
		desc:'介绍7项让专业IT人士决定以PDF文电通系列取代Adobe Acrobat的特色。'
	};
	const viewcontent =
		`<h1 id="p1">前言：了解您在PDF软件上有什么选择</h1>

<p>自1993年由Adobe&reg;公开规格与2008年成为ISO认证的开放标准以来，PDF在电子文件的共享与存档上占有举足轻重的地位。近年，虽然多数企业仍将Adobe Acrobat&reg;与PDF画上等号，但是一波新的思维正在挑战企业对于PDF的传统观念。</p>

<p id="p11">如今，专业IT人士们已经意识到PDF替代方案能带来的巨大效益。在预算、成本效益和部署方式的多重考虑下，企业开始重新评估采购软件的选项，并寻找能符合需求的解决方案。本文即是介绍7项让专业IT人士决定以PDF文电通系列取代Adobe Acrobat的特色。</p>

<p id="p12">&nbsp;</p>

<div id="p13">
	<p><strong>PDF的主要优势</strong></p>

	<ul style="margin:0px;padding-left:20px;">
		<li>
		<p style="margin-bottom:10px;margin-top:0px;"><strong>完整性</strong>：在不同的平台、操作系统和计算机上，仍能维持原始文件的属性与格式。</p>
		</li>
		<li>
		<p style="margin-bottom:10px;margin-top:0px;"><strong>安全性</strong>：支持数字签名和密码保护。</p>
		</li>
		<li>
		<p style="margin-bottom:10px;margin-top:0px;"><strong>较小的文档大小</strong>：鉴于PDF格式的质量，并且转换后的文档大小通常小于原始文件，因此容易移转。</p>
		</li>
		<li>
		<p style="margin-bottom:0px;margin-top:0px;"><strong>易于创建</strong>：除了可以通过打印的方式转换为PDF，还能选择合并、叠加文件或组合为PDF公事包。</p>
		</li>
	</ul>
</div>

<p>&nbsp;</p>

<h1 id="p2">文电通PDF成为最佳替代方案的7个理由</h1>

<h2 id="p21">1. 易于部署与维护</h2>

<p>每当组织内部引进新软件时，IT人员常面临诸多抉择与挑战。比起部属上耗时、耗资皆巨的软件产品，大多数的企业只需要一个简单、轻盈的安装文档，让他们能在短时间内完成部署。文电通PDF的安装所需硬盘空间不但在700 MB左右，更可提供MSI安装程序与常见主控机环境所使用的终端服务器授权（TSL），如Windows&reg;终端服务器和远程桌面联机。</p>

<p id="p15">完成软件部署后，维持版本一致性可能是另一项令IT人员感到困扰的任务。以Acrobat的更新机制为例，每次操作仅会取代程序内的特定文件，但可能因为不同的更新频率或网络限制导致企业内各成员使用的版本出现不同。与其相反，文电通PDF为每个新版本提供全新的安装程序，使IT团队不需事先彻查内部使用情况即能更新所有软件。通过这样的方式，用户既能保持版本一致，又能体验到软件的功能改进。</p>

<p>&nbsp;</p>

<div id="p14">
<p><strong>「PDF文电通不但简化了航空业一贯的大量转换与合并作业，TSL的弹性授权选择、软件部署方式与集中使用概念，完全符合我们的作业模式。」</strong></p>

<p style="font-size:14px;margin-bottom:0px;">长荣航天科技 (EGAT) 电算部</p>
</div>

<p>&nbsp;</p>

<h2 id="p22">2. 微软SharePoint和AD RMS</h2>

<p id="p16">现代企业通常比较依赖微软SharePoint等软件追踪、储存及保护大量文件；在追求效率的工作流程中，具备整合能力的PDF解决方案扮演着重要的角色。文电通PDF 能够让您在Windows平台打开和编辑存放在SharePoint的文档，并持续记录不同用户创建的不同版本。此外，套装版还支持微软AD RMS，通过权限政策来防止文件遭到未经授权的存取，将信息安全充分融合到文件管理流程中。</p>

<p>&nbsp;</p>

<h2 id="p23">3. 迅速上手的用户界面</h2>

<p>文电通PDF采用易于学习与操作的简洁UI设计，且基于MS Office全球总用户数突破12亿，您的用户极可能非常熟悉Word、Excel或PowerPoint的「Ribbon」工具栏操作方式。使用文电通PDF，用户不需要重新适应软件界面以及复杂的功能，并能够在最短时间内开展工作。</p>

<p>用最少的操作找到正确的功能是提升工作效率的快捷方式，而文电通PDF的UI提供无可比拟的灵活性，让您不费吹灰之力就能找到需要的功能。除了「查找工具」搜索关键词，您还可以在程序顶端的快速访问工具栏自定义常用功能，并可随时新增或移除。另外，左侧面板能够自由移动位置，让每位用户量身订做自己的工作环境，避免耗费过多时间学习适应软件。</p>

<p id="p17">文电通PDF也是唯一主张从零开始书写PDF文档的解决方案，其独一无二、特别设计的编辑模式将提供您前所未见的自由编辑能力，包括随心所欲的修改字体、段落、样式、图片与文字对齐等项目。与其为了修改文件而反复的转换文件，您可以只使用一个应用程序完成编辑，不再需要担心转换后潜在的格式错误或失真。</p>

<p>&nbsp;</p>

<p style="text-align:center"><img alt="editing and review 1" src="${require(`../../assets/img/resources/editing_and_review_1_cn.jpg`)}" /></p>

<p style="text-align:center"><img alt="editing and review 2" src="${require(`../../assets/img/resources/editing_and_review_1_cn.jpg`)}" /></p>

<p id="p18" style="text-align:center">Right PDF Editor界面：通过两种转换模式进行PDF的编辑与审阅</p>

<p>&nbsp;</p>

<p id="p19">除了转换PDF为可编辑的状态或Excel之外，文电通还提供了OCR（光学字符识别）功能，可以快速精准的的转换图片中的文字为可搜索的PDF内容。您不必再为扫描版的文件烦恼，或是翻阅大量的文件寻找数据了──有了OCR，扫描件不再是图片，而是能够搜索且编辑的文档</p>

<p>&nbsp;</p>

<div id="p31">
<p><strong>「我们不仅需要能快速布署的解决方案，还需以较低成本取得强大的PDF功能，而文电通PDF是个绝佳选择。」</strong></p>

<p style="font-size:14px;margin-bottom:0px;">雅特生科技 IT经理 Nelson Lui</p>
</div>

<p>&nbsp;</p>

<h2 id="p24">4. 棣南严选的实用功能</h2>

<p id="p33">棣南文电通以超过29年的经验与技术，用合理的价格为企业提供可靠、精实的解决方案；相反地，A牌厂商的产品却让企业为了多数用户完全不需要的专业功能买单。尽管不同产业对PDF有不同的需求，其核心价值仍是一些必要功能，例如编辑、转换和批注。文电通PDF让用户能快速达成任务，而不必反复横越繁复的菜单与意义不明的功能。现在就重新审视您真正的需求，提高软件的投资回报率！</p>

<p>&nbsp;</p>

<div id="p32">
<p><strong>「文电通PDF长期提供非常多实用功能以及合理价格，不论对于工作效率与成本控制，都有很大的帮助。」</strong></p>

<p style="font-size:14px;margin-bottom:0px;">浦项工程建设公司 经理 Ham Ju Han</p>
</div>

<p>&nbsp;</p>

<h2 id="p25">5. 超越桌面的生产力</h2>

<p id="p34">移动设备的兴起创造了跨平台互动与查看PDF的需求，而知识工作者受到的影响尤其深远。因此，APP是否具备友善界面与便捷功能实为左右工作效率的关键。估且不论出色的显示速度，免费的文电通PDF阅读器APP支持在iOS和Android设备上进行PDF操作，例如注释、填写表单和切换浏览模式等多项功能。文电通PDF拥有的跨平台服务，包括提供免费转档、加密、水印等服务的文电通PDF在线版（Right PDF Online）网站，将协助您的工作效率更上一层楼。</p>

<p>&nbsp;</p>

<h2 id="p26">6. 速度就是效率</h2>

<p>采用文电通PDF，企业能够以卓越的速度和精准度处理巨量的文件。文件密集型企业中的用户常要开启、编辑和创建大量的文档，因此速度在工作流程中是至关重要的一环。对个人用户而言差异或许不是非常显著，但对例行处理上千笔文件与大量工作流的企业，处理速度累计的加成效果却能节省可观的时间、空间和费用。依据棣南最近的测试显示，文电通PDF不但占用的CPU资源少，更在转换速度与减少文档大小上同样表现优异。再辅以批处理功能，您将能得到集速度与高效于一身的文件转换利器。</p>

<p>&nbsp;</p>

<h2 style="text-align:center;font-size:20px;margin-bottom:25px;">文电通PDF与A牌厂商的主要统计</h2>

<div id="p35">
	<div>
		<p class="font18"><strong>转换后文档大小</strong></p>
		<p class="font30 position"><span style="color:#35a562"><strong>少10.2%</strong></span></p>
	</div>
	<div>
		<p class="font18"><strong>平均转换速度</strong></p>
		<p class="font13">Word/Excel/PPT转换为PDF</p>
		<p class="font30"><span style="color:#35a562"><strong>快40.5%</strong></span></p>
	</div>
	<div>
		<p class="font18"><strong>平均CPU使用率</strong></p>
		<p class="font13">Word/Excel/PPT转换为PDF</p>
		<p class="font30"><span style="color:#35a562"><strong>少40%</strong></span></p>
	</div>
</div>

<p style="text-align:center;font-size:13px;">*数据源：內部测试結果</p>

<p>&nbsp;</p>

<p>此外，您可以通过PDF管理员扫描计算机的PDF文档并进行全盘管理。除了迅速搜索到正确的文件，您还能与原始文件进行同步删除、移动，或开启及编辑文件，在简化个人的PDF文件管理之余，进而提高全体的生产力。</p>

<p>&nbsp;</p>

<div id="p36">
<p><strong>「PDF文电通提高了转换后的文件格式正确率，减少了修改时间。而Server的集中部署模式不但减少IT耗费在软件维护和管理的时间，还降低了采购成本。」</strong></p>

<p style="font-size:14px;margin-bottom:0px;">中央研究院 / 计算中心</p>
</div>

<p>&nbsp;</p>

<h2 id="p27">7. 价格与支持</h2>

<table cellspacing="0" style="border-spacing:0; height:114px; width:100%;border: 1px solid #C4C4C4;border-radius: 6px;border-collapse:separate;">
	<tbody>
		<tr>
			<td style="height:78px; text-align:center; width:14.2285%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;">&nbsp;</td>
			<td style="height:78px; text-align:center; width:28.2024%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;background:#087A4F;color:#fff;">文电通PDF套装版6</td>
			<td style="height:78px; text-align:center; width:28.4934%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;background:#EFEFEF;">Acrobat Standard 2020</td>
			<td style="height:78px; text-align:center; width:29.0757%;border-bottom: 1px solid #C4C4C4;background:#EFEFEF;">
			<p>Acrobat Pro 2020</p>
			</td>
		</tr>
		<tr>
			<td style="height:18px; text-align:center; width:14.2285%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;">永久授权</td>
			<td style="height:18px; text-align:center; width:28.2024%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;background:#DFF9E5;color:#37915C;">&yen;&nbsp;<strong class="strong30">1250</strong></td>
			<td style="height:18px; text-align:center; width:28.4934%;border-right: 1px solid #C4C4C4;border-bottom: 1px solid #C4C4C4;">
			<p>&yen;&nbsp;<strong class="strong30">2500</strong></p>
			</td>
			<td style="height:18px; text-align:center; width:29.0757%;border-bottom: 1px solid #C4C4C4;">
			<p>&yen;&nbsp;<strong class="strong30">4000</strong></p>
			</td>
		</tr>
		<tr>
			<td style="height:18px; text-align:center; width:14.2285%;border-right: 1px solid #C4C4C4;">订阅版</td>
			<td style="height:18px; text-align:center; width:28.2024%;border-right: 1px solid #C4C4C4;background:#DFF9E5;color:#37915C;">
			<p>&yen;&nbsp;<strong class="strong30">14</strong><br />
			<span style="font-size:14px;">(每月/年付)</span></p>
			</td>
			<td style="height:18px; text-align:center; width:28.4934%;border-right: 1px solid #C4C4C4;">
			<p>&yen;&nbsp;<strong class="strong30">87</strong><br />
			<span style="font-size:14px;">(每月/年付)</span></p>
			</td>
			<td style="height:18px; text-align:center; width:29.0757%;">
			<p>&yen;&nbsp;<strong class="strong30">101</strong><br />
			<span style="font-size:14px;">(每月/年付)</span></p>
			</td>
		</tr>
	</tbody>
</table>

<p><span style="color:#800000">（*图示价格为换算价格，仅供参考，实际价格以官方报价为准。）</span></p>

<p>&nbsp;</p>

<p>当企业评估软件的时候，IT人员往往需要权衡采购成本与其带来的效益，尝试在预算之内让员工都能使用软件。相比之下，文电通PDF能以合适价位将PDF丰富完整的功能延伸到组织内的每一个角落。比起采用不同品牌的产品来满足预算限制，您可以使用文电通PDF一举解决所有用户的PDF需求。与Acrobat Standard and Pro 2020的建议售价相比，使用PDF文电通能分别省下约50%和69%购买永久授权的费用，而且您甚至能经由大量采购省下更多成本。另外，文电通也提供了订阅版的选项，而且价格更加经济实惠。与 Acrobat DC Standard 和 Pro 版本的建议售价相比，购买文电通 PDF 能分别省下约 84% 和 86% 的费用，而且您甚至能经由大量采购省下更多成本。另外，文电通也提供了订阅版的选项，而且价格更加经济实惠。</p>

<p style="margin-bottom:0px;">根据Zendesk的评估报告，文电通在最近（2022年7月）100个客户所提交的问题上达到了93.82%的客户满意度。个人或企业用户能随时在社区和支持中心寻找解答，或通过在线提问的方式寻求技术支持。文电通PDF另外还提供软件维护服务，在有效订阅期内用户可获得优先的技术支持，以及免费的版本升级服务。</p>

<p>&nbsp;</p>

<h1 id="p3">结论</h1>

<p>无论是政府部门或私营企业，选择兼具价值与效率的PDF解决方案已是必要的考量。过去能选择的PDF软件有限， 企业往往需要付出高额费用才能大规模部署； 然而随着企业采购的新观念在各行各业间逐渐扩散，您也该了解到您可以选择更节省时间、 成本与精力的软件，在激烈竞争中取得先机。</p>

<p>在棣南，我们致力于提供能满足不同规模企业的PDF软件。从一般的计算机软件授权、免费的PDF阅读器、远程环境到网络服务与服务器级转换工具，文电通PDF完整的PDF功能以及灵活的授权方式，让您可以按照需求选择，而不必再妥协于昂贵的解决方案。在PDF软件展开全新篇章之际，现在正是您重新抉择的时刻！</p>

<p>&nbsp;</p>

<p style="text-align:center;margin-bottom:30px;"><img src="${require(`../../assets/img/point.png`)}"/></p>
<h1 class="font20" style="text-align:center"><span style="color:#37915c">立即联系棣南，了解适合您企业的最佳PDF软件!</span></h1>

<p style="text-align:center"><a href="https://www.rightpdf.com/zh-CN/contactUs" target="_blank" style="width:210px;height:60px;background:#087A4F;display:inline-block;font-size:17px;color:#fff;line-height:60px;border-radius:4px;margin-top:40px;">联系棣南文电通</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<div id="p37">
<p style="font-size:20px;"><strong>关于棣南</strong></p>

<p style="margin:0px;"><strong>北京棣南新宇科技有限公司正在改变用户与PDF的交互方式。迥异于常见的PDF软件，我们的产品理念侧重「自由编辑」，使用户无需经过繁复操作或寻找源文件，就能轻松创建、书写与修改PDF。文电通集编辑、直观界面、丰富功能于一体，赋予全球专业人士和企业组织无比的效率、生产力与想象力。</strong></p>
</div>
<p id="p38">&nbsp;</p>

<ul style="font-size:13px;padding-left:20px;">
	<li>Adobe, the Acrobat logo and Acrobat are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries.</li>
	<li>Microsoft and Windows are either registered trademarks of Microsoft Corporation in the United States and/or other countries. All other trademarks are the property of their respective owners.</li>
</ul>

<p style="font-size:13px;"> © 2022 版权所有北京棣南新宇科技有限公司</p>`;
	return (<div>
			 <MetaTags>
                <title>{metaTagscontent.title}</title>
                <meta name="title" content={metaTagscontent.title} />
                <meta name="description" content={metaTagscontent.desc} />
                <meta property="og:title" content={metaTagscontent.title} />
                <meta property="og:description" content={metaTagscontent.desc} />
            </MetaTags>
			<div dangerouslySetInnerHTML={{__html:viewcontent}}></div>
		</div>);
}
export default SuiteCompare;
