const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何取得文电通PDF的试用版并安装?</title></head><body>  <h1>如何取得文电通PDF的试用版并安装?</h1>  <p>第一次使用Right PDF 的用户将获得<strong><span class="wysiwyg-color-red90">14天</span>的免费试用</strong>以评估产品。您只需要在电脑上安装该程序即可自动获得。</p>
<p> </p>
<p><strong>产品下载页面（点击移至网页）：</strong></p>
<p><strong><span class="wysiwyg-color-green120"><a href="https://store.rightpdf.cn/download/suite.aspx" target="_self" rel="undefined">文电通PDF套装版</a> </span></strong>(Right PDF Pro)</p>
<p><strong><a href="https://store.rightpdf.cn/download/driver.aspx" target="_self" rel="undefined">文电通PDF转换器 </a></strong>(Right PDF Converter)</p>
<p><strong><a href="https://store.rightpdf.cn/download/server.aspx" target="_self" rel="undefined">文电通PDF服务器</a> </strong>(Right PDF Server)</p>
<p><strong><a href="https://store.rightpdf.cn/download/mac.aspx" target="_self" rel="undefined">文电通PDF macOS</a> </strong>(Right PDF macOS)</p>
<ol>
<li>从<a href="https://store.rightpdf.cn/download/central.aspx" target="_blank" rel="noopener">官网下载</a>试用版的Right PDF，保存到指定目录中</li>
<li>双击安装文件图标，按照安装向导的步骤进行安装</li>
<li>单击完成结束安装程序</li>
<li>如果您的打印机列表中添加了Right PDF选项，表示您已经安装成功了</li>
</ol>
<p> </p>
<p> </p>
<h3><span class="wysiwyg-color-green130"><strong>相关链接：</strong></span></h3>
<ul>
<li><strong><a href="https://support.rightpdf.com/hc/zh-cn/articles/200874198-%E5%A6%82%E4%BD%95%E8%B4%AD%E4%B9%B0%E4%BA%A7%E5%93%81-">如何购买产品？</a></strong></li>
<li><strong><a href="https://support.rightpdf.com/hc/zh-cn/sections/200240283--%E8%B4%AD%E4%B9%B0%E8%88%87%E9%80%89%E6%8B%A9PDF%E6%96%87%E9%9B%BB%E9%80%9A2%E4%BA%A7%E5%93%81" target="_self" rel="undefined">购买与选择Right PDF系列产品</a></strong></li>
</ul></body></html>`;   export default html;