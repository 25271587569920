const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>为什么会出现错误代码0x20020221？</title></head><body>  <h1>为什么会出现错误代码0x20020221？</h1>  <div style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 100%; width: 100%; padding: 5px;"><span><span class="wysiwyg-color-red"><strong>重要提醒：</strong></span>文电通(DocuCom) 已于2009年停止更新，并无法支持Windows7及以上的版本。如果解决方案没有效果，请考虑<strong><a title="Link: http://pdf.gaaiho.com/Docucom_upgrade/intro.aspx" href="https://store.rightpdf.com/buy/upgrade-add-user.aspx" target="_self" rel="undefined">升级到Right PDF</a></strong>，因为我们已经无法再更新旧版的文电通了。</span></div>
<p> </p>
<h2><strong>问题描述：</strong></h2>
<p>当激活授权（序号）时，出现错误码「0x20020221」，授权激活失败。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>有两种可能原因：</p>
<ul>
<li>90％：系统中的资料不正确，导致激活系统认为安装的产品错误</li>
<li><span>10％：安装的产品与序列号不匹配</span></li>
</ul>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方案</strong>：</span></h2>
<div>
<span class="wysiwyg-color-blue130"><strong>重新安装：</strong></span><br>
<div><span class="wysiwyg-color-blue130">1. 请反激活并卸载电脑上所有的文电通产品(<strong>如果产品未激活或无法正常反激活，请略过</strong>)</span></div>
<div><span class="wysiwyg-color-blue130">2. 卸载文电通</span></div>
<div><span class="wysiwyg-color-blue130">3. 重新启动电脑</span></div>
<div><span class="wysiwyg-color-blue130">4. 前往这个路径「<strong>%SystemDrive%\\Documents and Settings\\All Users\\Application Data\\</strong>」（在Windows 7、Windows 8、Vista上则是「<strong>%SystemDrive%\\ProgramData</strong>」），然后删除「Zeon」文件夹</span></div>
<div>
<span class="wysiwyg-color-blue130">5. 重新安装软件，最后使用您的序列号激活</span><br><br><span class="wysiwyg-color-blue130">如果您无法用正常的方式卸载程序（控制面板 &gt; 程序 &gt; 卸载程序，选取「<strong>DocuCom PDF Gold *.x</strong>」然后卸载），请使用附件中的「Remove Tool」替换上述的步骤2。</span>
</div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div> </div>
</div></body></html>`;   export default html;