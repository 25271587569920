const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>在Windows 8.1 IE11上为什么没有Gaaiho的插件？</title></head><body>  <h1>在Windows 8.1 IE11上为什么没有Gaaiho的插件？</h1>  <h2><strong>问题描述：</strong></h2>
<p>在Windows 8.1 IE 11上没有出现Gaaiho的加载项选项插件。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>在IE工具栏上右键，无法勾选Gaaiho这个插件，而显示为「不相容」状态的原因是Windows 8.1版本默认勾选了「网际网路选项 」&gt; 安全性 &gt; 启用受保护模式，取消勾选即可恢复正常。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">点选「Internet 选项」</span></p>
<p><span class="wysiwyg-color-blue130"> <img src="https://store.rightpdf.com/images/rtsupport/340091.jpg" alt=""></span></p>
<p> </p>
<p><span class="wysiwyg-color-blue130"> 「安全性」 &gt; </span><span class="wysiwyg-color-red"><strong>取消选取</strong></span><span class="wysiwyg-color-blue130">「启用保护模式」</span></p>
<p><img src="https://store.rightpdf.com/images/rtsupport/340092.jpg" alt=""></p></body></html>`;   export default html;