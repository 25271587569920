import React from 'react';
const AboutBlock = props => {
    return (
        <div className="aboutBlock mobileScroll">
            <ul className="hideMenuList">
                {
                    props.renderLiLink('AboutZeon')
                }
            </ul>
        </div>
    );
}
export default AboutBlock;