import React, { useState, useEffect,useReducer,useRef} from 'react';
import logoPath from '../assets/img/right-logo.png';
import versionPath from '../assets/img/version_6.png'
import headB from '../assets/img/head_b1.png';
import ProductBlock from './menu/productBlock';
import ProductBlockMoblie from './menu/productBlock_moblie';
import SupportBlock from './menu/supportBlock';
import AboutBlock from './menu/aboutBlock';
import ContactBlock from './menu/contactBlock';
import DesktopMenu from './menu/desktopMenu';
import MoblieMenu from './menu/mobileMenu';
import MenuContext from './menu/menuContext';
import mixinWrapper from '../hoc/mixinWrapper';
import HeaderTips from './menu/headerTips';

const initialState = {
    title:null,
    type:null
}
const SELECT_BLOCK = 'SELECT_BLOCK';

function reducer(state, action) {
    switch(action.type){
        case SELECT_BLOCK:
            return {
                title:action.title,
                type:action.bType
            }
        default :
         return initialState
    }
}

const Header = props => {
    
    const { t,currentLang,linkObj,typeListAry} =  props;
    const [isMoblie,setIsMoblie] = useState(false);
    const [showProductM,setShowProductM] = useState(false);
    const [block, dispatch] = useReducer(reducer, initialState);
    const [hideBlockTop,sethideBlockTop] = useState(0);
    let listdata = [
        {
            title:t(linkObj.product.name),
            type:'product'
        },
        /*{
            title:t(linkObj.macOS.name),
            type:'macOS',
			url:`/${props.currentLang.type}${linkObj.product.macOS.path}`
        },
        {
            title:t(linkObj.Mobile.name),
            type:'Mobile',
			url:`/${props.currentLang.type}${linkObj.product.reader.app.path}`
        },
        {
            title:t(linkObj.rightPDFOnline.name),
            type:'online',
			url:linkObj.product.addpdf.url()
        },*/
        {
            title:t(linkObj.support.name),
            type:'support'
        },
        {
            title:t(linkObj.AboutZeon.name),
            type:'about'
        }
    ];
    // 偵測device寬度
    const handleDeviceResize = () =>{
        if(window.innerWidth > 899 ){
            setIsMoblie(false);
            setShowProductM(false);
        }else{
            setIsMoblie(true);
            //if(window.innerWidth < 740){
                setShowProductM(true);
            //}
            let headerTips = document.getElementsByClassName('headerTips')[0];
            let headerTipsHeight  = headerTips.offsetHeight;
            sethideBlockTop(headerTipsHeight); 
        }
        setSectionHeight();
    }
    //改变section高度
    const setSectionHeight = () => {
        let section = document.getElementsByTagName('section')[0];
        let header = document.getElementsByTagName('header')[0];
        section.style.paddingTop = header.offsetHeight + 'px';
    }
    //用useRef實踐componentWillMonut
    const firstUpload = useRef(true);
    useEffect(() => {
        if(firstUpload.current){
            firstUpload.current = false;
            handleDeviceResize();
            window.addEventListener('resize',handleDeviceResize);
            return;
        }
        setSectionHeight();
        return function removeResize(){
            window.removeEventListener('resize',handleDeviceResize);
        }
    });

    let renderMenuBlock = menuSelect => {
        
        if(menuSelect === 'product'){
            if(showProductM){
                return <ProductBlockMoblie {...props} />
            }else{
                return <ProductBlock  {...props} />
            }
            
        } else if(menuSelect === 'support'){
            return <SupportBlock {...props} />
        }else if(menuSelect === 'about') {
            return <AboutBlock {...props} />
        }else if(menuSelect === 'contact'){
            return <ContactBlock {...props} />
        }
    }
    useEffect(()=>{
        // console.log(currentLang)
        const sc = document.createElement('script');
        sc.type = 'text/javascript';
        sc.async = false;
        if(currentLang.type !=="zh-CN")
            {
            sc.innerHTML =                 
                `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NP84WRG');`
    }else{

        sc.innerHTML=`var _hmt = _hmt || [];
        (function() {
          var hm = document.createElement("script");
          hm.src = "https://hm.baidu.com/hm.js?262c330870f2f744c20e980d94938cd6";
          var s = document.getElementsByTagName("script")[0]; 
          s.parentNode.insertBefore(hm, s);
        })();`
    }
    var head = document.getElementsByTagName('head')[0];
    head.appendChild(sc);
    },[currentLang])

    let renderheaderMenu = blockType => {
        if(isMoblie){
            return <MoblieMenu {...props} hideBlockTop={hideBlockTop} LogoPath={logoPath} VersionPath={versionPath}  ListData={listdata} >{renderMenuBlock(blockType)}</MoblieMenu>
        }else{
            return <DesktopMenu {...props} ListData={listdata} LogoPath={logoPath} VersionPath={versionPath} HeadB={headB} typeListAry={typeListAry}>{renderMenuBlock(blockType)}</DesktopMenu>
        }
    }
    return(
        <header className="text-center havetips">
            <HeaderTips {...props}></HeaderTips>
            <MenuContext.Provider value={{block, dispatch}}>
                 {
                    renderheaderMenu(block.type)
                 }
            </MenuContext.Provider>
        </header>
    )
}

export default mixinWrapper(Header);
