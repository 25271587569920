const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>我应该购买「软件维护服务(SMS)」吗？</title></head><body>  <h1>我应该购买「软件维护服务(SMS)」吗？</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p>当软件需要升级与技术支持服务时，「软件维护服务(SMS, Software Maintenance Services)」将会是一个经济、高效的方式。从购买软件之日起，只要软件在维护服务期内，使用者就可以使用所有新版产品与享受所有技术支持服务，更能将任意版本的产品升级到最新版。使用者不必花费高昂金额购买大约每年推出一次的新版本，更可以在维护服务将届满时延长有效期，让软件持续地受到完善的保护与支持！</p>
<p> </p>
<p><strong>更多内容，请参考：</strong></p>
<ul>
<li><a href="https://www.rightpdf.com/zh-CN/About/legal/maintenance-service" target="_blank" rel="noopener">软件维护服务</a></li>
<li><a href="https://www.rightpdf.com/zh-CN/About/legal/upgrade-licenses" target="_blank" rel="noopener">授权升级说明</a></li>
</ul>
<p> </p>
<p>如果您对于「软件维护服务(SMS)」有进一步的疑问或需求，欢迎联系 <a href="mailto:sales@rightpdf.com" target="_self" rel="undefined">sales@rightpdf.com</a>，将有专人为您协助。</p></body></html>`;   export default html;