import React from 'react';
import MetaTags from 'react-meta-tags';
const SuiteCompare = props => {
	const metaTagscontent = {
		title:'Is Adobe a Must for You? - 5 perspectives by Right PDF | Right PDF',
		desc:'nstead of enduring budget, security and flexibility concerns, choosing a smart and capable solution can benefit both you and your organization by a great deal. '
	};
	const viewcontent =
		`<h1 id="p1">A Loose Cannon: Your Software License Compliance</h1>

<h2 class="marginB40"><span style="color:#c43e3e">Somewhere along the way, your software assets became a loose cannon, and you have no idea when an audit is coming your way.</span></h2>

<p class="marginB50"><span style="color:#c43e3e">Among organizations &hellip;</span></p>
<div class="box7Wrapper">
	<div class="box6 box7">
	<p><strong>38% said at least 11% of software spend was associated with out-of-compliance use</strong></p>

	<p><img alt="11%" src="${require(`../../assets/img/resources/Group 1307.svg`)}" /></p>
	</div>

	<div class="box6 box7">
	<p><strong>56% said 11% or more of software spend was for under-used software</strong><sup>(i)</sup></p>

	<p><img alt="11%" src="${require(`../../assets/img/resources/Group 1316.svg`)}" /></p>
	</div>

	<div class="box6 box7">
	<p><strong>63% were audited by their software vendors in the last 18-24 months</strong></p>

	<p><img alt="63%" src="${require(`../../assets/img/resources/Group 1315.svg`)}" /></p>
	</div>

	<div class="box6 box7 marginB50">
	<p><strong>56% ended up paying the auditor. 21% of the true-up payments exceed a million dollars</strong><sup>(ii)</sup></p>

	<p><img alt="56%" src="${require(`../../assets/img/resources/Group 1330.svg`)}" /></p>
	</div>
</div>
<div class="redbox marginB40">
<p><span style="font-size:24px"><span style="color:#8e44ad"><img alt="client_logo_evergreen" src="${require(`../../assets/img/warning.png`)}" /></span></span></p>

<p><strong><span style="color:#c40000">In 2014, Gartner Research published a guide named &ldquo;Surviving an Adobe Software License Auditi</span></strong><span style="color:#c40000"><sup>(iii)</sup></span><strong><span style="color:#c40000">,&rdquo; alerting businesses to the danger of paying a hefty true-up fee. Many years have passed since then, and though reported cases show signs of abatement, Adobe has not dropped the practice yet.</span></strong></p>
</div>

<p class="marginB50">Non-compliance might not be easily forgiven these days. No one knows when they will catch the scent, but when they do, be ready for time-consuming, costly and exasperating audits.</p>

<h2 class="marginB40"><span style="color:#087a4f">Or, look at Right PDF&rsquo;s take on compliance:</span></h2>

<div class="box1 marginB70">
<ul>
	<li>
	<p><strong>We don&rsquo;t enforce surprise audits</strong><br />
	While the audits often catch businesses off guard, we believe everyone should have a chance to prepare themselves.</p>
	</li>
	<li>
	<p><strong>No penalty for exceeded agreements</strong><br />
	You won&rsquo;t be penalized after discovering non-compliance with the license number.</p>
	</li>
	<li>
	<p><strong>No stress compliance self-check</strong><br />
	You are encouraged to conduct self-check and fill up the blank on a quarterly basis.</p>
	</li>
	<li>
	<p><strong>Simple VLP license terms for enterprises</strong><br />
	License terms are simple even for VLP. A couple of signed sheets can do it all.</p>
	</li>
</ul>
</div>

<h1 id="p2">Friendly to Users, IT Pros and Company</h1>

<div class="box1 box2">
<h3 class="marginB40"><strong>/// Adobe</strong></h3>

<p class="marginB10"><strong>Learning Curve</strong></p>

<p class="marginB30">y far, the Ribbon design in Microsoft Office applications is the most popular on earth, boasting over <strong>1.2 billion users</strong><sup>(iv)</sup> , while Adobe still clings on to their legacy layout. It&rsquo;s up to you which users would prefer and bring you the least worries.</p>

<p class="marginB10"><strong>Size Matters</strong></p>

<p>It&rsquo;s never an easy job to deploy new software across a department, not to mention an entire organization. Now, consider the required hard-disk space of Acrobat (Windows):</p>

<p class="box3"><span style="color:#c43e3e"><strong>4.5 GB</strong></span><span style="color:#c43e3e"><i>Acrobat Pro DC,</i><i> Acrobat Standard DC</i></span></p><sup>(v)</sup>

</div>

<div class="box1 marginB50">
<h3 class="marginB30"><span style="color:#087a4f"><strong>/// The Right PDF way:</strong></span></h3>

<p class="marginB10"><strong>A Familiar Face</strong></p>

<ul class="marginB20">
	<li>
	<p>Faster learning and user adoption</p>
	</li>
	<li>
	<p>Less support &amp; training requests related to product</p>
	</li>
	<li>
	<p>Free your hands for more important missions</p>
	</li>
</ul>

<p class="marginB10"><strong>Lightweight</strong></p>

<p class="box3 box4"><span style="color:#087a4f"><strong>Around 700MB</strong></span><span style="color:#087a4f">95% less space taken</span></p>

<p class="marginB10"><strong>Deploy Options</strong></p>

<ul>
	<li class="marginB0">
	<p>On desktop computers</p>
	</li>
	<li class="marginB0">
	<p>TSL for virtualized environments (Windows&reg; Terminal Server)</p>
	</li>
	<li class="marginB0">
	<p>Enterprise Licensing Program (site license)</p>
	</li>
</ul>
</div>

<h1 id="p3">Create, Edit and OCR</h1>

<p>Among Right PDF&rsquo;s rich array of functions, the 4 features -- create, convert, edit and OCR (Optical Character Recognition) are specifically designed to deliver high performance and enhance productivity.With Right PDF Pro 6, users can:</p>

<div class="box1">
<h3><strong>Writable PDF &amp; Excel</strong></h3>

<p>For PDF made from Excel or Word, convert it to a writable state that makes editing simple and intuitive.</p>

<ul>
	<li class="marginB0">
	<p>Convert PDF to Writable document</p>
	</li>
	<li class="marginB0">
	<p>Switch between Write and Review modes</p>
	</li>
	<li class="marginB0">
	<p>Edit PDF with robust functionality such as font, paragraph, table, graphic and footnote.</p>
	</li>
	<li class="marginB0">
	<p>Edit spreadsheets directly within Right PDF Editor</p>
	</li>
</ul>
</div>

<div class="box1">
<h3><strong>OCR</strong></h3>

<p>Let OCR help you with turning image documents into searchable PDFs.</p>

<ul>
	<li class="marginB0">
	<p>Run OCR to make &ldquo;image&rdquo; PDF documents searchable</p>
	</li>
	<li class="marginB0">
	<p>Run OCR during file-to-PDF and PDF-to-file conversion</p>
	</li>
	<li class="marginB0">
	<p>Eliminate incorrect results with Right PDF Editor</p>
	</li>
</ul>
</div>

<div class="box1 marginB70">
	<h3><strong>Two-way Batch Conversion</strong></h3>

	<p class="marginB30">Right PDF provides two-way batch conversion that features smaller file size and less CPU consumption. It supports all printable file types.</p>

	<div id="p35" class="marginB10">
		<div class="box7">
		<p class="font18"><strong>Output file size</strong></p>

		<p class="font30"><span style="color:#35a562"><strong>10.2% smaller</strong></span></p>
		</div>

		<div class="box7">
		<p class="font18"><strong>Conversion speed</strong></p>

		<p class="font13">Word/Excel/PPT to PDF:</p>

		<p class="font30"><span style="color:#35a562"><strong>40.5% faster</strong></span></p>
		</div>

		<div class="box7">
		<p class="font18"><strong>CPUusage</strong></p>

		<p class="font13">Word/Excel/PPT to PDF:</p>

		<p class="font30"><span style="color:#35a562"><strong>40% less</strong></span></p>
		</div>
	</div>

	<p class="from">*Based on internal test results</p>
</div>

<h1 id="p4">Cloud, Subscription, and You</h1>

<p class="marginB50">Subscription is the dominant way of accessing software these days, but is it fair to take other options away from us?</p>

<h2 id="p41">A. Subscription vs. Perpetual</h2>

<p class="marginB0">On advertisement, subscription is designed to be more attractive than perpetual, but you should be careful and understand their real differences.</p>

<p>Now, ask yourself 3 questions:</p>

<div class="box1 marginB50">
<ol>
	<li class="marginB0">
	<p><strong>What does the software do for you?</strong></p>
	</li>
	<li class="marginB0">
	<p><strong>Should it be updated on a frequent basis?</strong></p>
	</li>
	<li class="marginB0">
	<p><strong>Does your company prefer CAPEX or OPEX?</strong></p>
	</li>
</ol>
</div>

<h3 class="marginB40"><strong>Take Acrobat Pro DC subscription for example:</strong></h3>

<p class="marginB30"><strong>Your Expense</strong><br />
The break-even point is roughly 36 months and at that point subscription starts to cost more per unit.</p>

<p class="marginB30"><strong>Update Frequency</strong><br />
If your workflow involves only basic features such as annotating, signing and editing, you may not need to update regularly.</p>

<p class="marginB30"><strong>SMS Option</strong><br />
The perpetual model also offers Software Maintenance Services(SMS) to make upgrade easier and more cost-effective. It is not limited to subscription model.</p>

<p class="marginB50"><strong>CAPEX or OPEX</strong><br />
Capital-intensive industries prefer using capital budgets (CAPEX) to buy software in order to gain tax deferral benefits, instead of making it a long-term commitment (OPEX).</p>

<h2 id="p42" class="marginB40">B. Cloud risks</h2>

<p class="marginB50">DC stands for &ldquo;Document Cloud,&rdquo; which transfers your data through their servers, and they might not be hold accountable when something goes wrong.Let&rsquo;s not forget the massive data breaches in recent years<sup>(vi)</sup>:</p>

<div class="redbox1 marginB50">
	<div>
	<p><strong><span style="color:#c40000">2013 - ADOBE</span></strong></p>

	<p><strong><span style="color:#c40000">153 million</span></strong><span style="color:#c40000"> customers records leaked due to hacker attack.</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2017 - Equifax</span></strong></p>

	<p><strong><span style="color:#c40000">148 million</span></strong><span style="color:#c40000"> personal information like birth dates, addresses, credit card numbers stolen. Paid $700 million to make amends.</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2018 - Mariott</span></strong></p>

	<p><strong><span style="color:#c40000">500 million</span></strong><span style="color:#c40000"> customers&rsquo; personal information exposed. Cost $24 million in fine.</span></p>
	</div>
	<div>
	<p><strong><span style="color:#c40000">2019 - Facebook</span></strong></p>

	<p><strong><span style="color:#c40000">540 million</span></strong><span style="color:#c40000"> records, including phone numbers, user names, genders, locations, were compromised.</span></p>
	</div>
</div>

<h3 class="marginB50"><strong>Here are the 3 factors to consider before diving into the cloud:</strong></h3>

<div class="box1 marginB50">
<ul>
	<li>
	<p><strong>Cloud Security</strong><br />
	It&rsquo;s impossible to prevent all attacks, meaning the cloud is not 100% safe for storing your important data.</p>
	</li>
	<li>
	<p><strong>Who Has Access</strong><br />
	As a user, your control is limited to the client side. The service operator or hackers might access the data without your authorization.</p>
	</li>
	<li>
	<p><strong>Jurisdiction</strong><br />
	Servers outside your country&rsquo;s jurisdiction might lead to complicated legal issues, e.g. difficulty to remove your data permanently.</p>
	</li>
</ul>
</div>

<h2 id="p43">C. Do you need cloud features?</h2>

<p>Are the likes of &ldquo;Send and track documents online,&rdquo; &ldquo;Create PDF on the go&rdquo; and &ldquo;add, edit or rearrange pages in a PDF on iPad&rdquo; really what you need?</p>

<p>Again, 3 questions:</p>

<div class="box1">
<ol>
	<li>
	<p><strong>Are most of your works done on desktop computers?</strong></p>
	</li>
	<li>
	<p><strong>Does your organization accept transferring internal data via public cloud service?</strong></p>
	</li>
	<li>
	<p><strong>Do you have security measures over the use of mobile devices?</strong></p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<p>Despite a surge of mobile devices in our work environment, the bulk of paperwork is done with the help of computers in our office. Yes, working on the go is convenient in certain scenarios, but desktop remains the king of productivity; in other words, mobile devices are no match to the combination of mouse, keyboard and big screen.</p>

<p>Even if you decide to use the cloud, the popularity of cloud storage services such as OneDrive, Dropbox and Google Drive means there are a lot of free options to move files around, instead of paying for a service that isn&rsquo;t quite what you need.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 id="p5">Have You Invested in Bloatware?</h1>

<p>Characterized by a vast feature set and huge install footprint, Acrobat Pro 2020 asks for a whopping US$538 per desktop, with the option to subscribe at US$19.99 per month (annual commitment).</p>

<p>Now, take a look at your workflow and ask yourself a few questions:</p>

<div class="box1">
<ol>
	<li>
	<p><strong>What are the exact features I use to do my job, after all? </strong></p>
	</li>
	<li>
	<p><strong>How many features of my Acrobat are barely or never used?</strong></p>
	</li>
	<li>
	<p><strong>Is my $538 or recurring $19.99 investment worth it?</strong></p>
	</li>
</ol>
</div>

<p>&nbsp;</p>

<h3><strong>Here are some of the least used features you paid for. And you never know.</strong></h3>

<p style="text-align:center"><img alt="useless fuctions" src="${require(`../../assets/img/resources/useless_fuctions_en.png`)}" /></p>

<p>&nbsp;</p>

<h2><strong>Or do I need a smart, affordable solution that does the job just as good?</strong></h2>

<div class="box5">
<h2><span style="color:#ffffff">Right PDF Pro 6</span></h2>
<div class="marginR20">
<p><span style="color:#ffffff">Perpetual</span></p>

<p><span style="color:#ffe600">$<strong class="strong48">179</strong> *</span></p>
</div>
<div>
<p><span style="color:#ffffff">Subscription</span></p>

<p><span style="color:#ffe600">$<strong class="strong48">10.9</strong> per month (annual)</span></p>
</div>

<p><span style="color:#ffffff">*Unit price lowers with purchase amount. Example: if you&rsquo;re buying Acrobat Pro 2020 for 5 users, Right PDF saves you $1,840 for the same amount.</span></p>
</div>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 id="p6">Conclusion</h1>

<p>We hope the 5 perspectives here can help you make an informed decision on your software investment. Instead of enduring budget, security and flexibility concerns, choosing a smart and capable solution can benefit both you and your organization by a great deal. There are options out there, and the switch is yours to make.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h1 style="text-align:center"><span style="color:#37915c">A Smart Choice for You and Your Business: Right PDF. Make It Right.</span></h1>

<p style="text-align:center"><a href="https://www.rightpdf.com/en/contactUs" target="_blank" style="width:210px;height:60px;background:#087A4F;display:inline-block;font-size:17px;color:#fff;line-height:60px;border-radius:4px;margin-top:40px;">Contact Right PDF</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>
<div id="p37">
<p><strong>About ZEON Corporation</strong></p>

<p><strong>ZEON Corporation is changing the way PDF interacts with users. Contrary to common expectations of PDF software, the company upholds the ideal that people shall freely create, write and edit PDF documents, without having to go through tedious maneuvering or look for original copies stored in other places. Incorporated with intuitive UI and a rich array of capabilities, Right PDF aims at augmenting efficiency and productivity for individuals and businesses alike around the world.</strong></p>
</div>
<p>&nbsp;</p>

<p>Sources</p>

<ol style="list-style-type:lower-roman">
	<li><strong>IDC.</strong> http://www.slideshare.net/flexerasoftware/sw-licensing-and-pricing-results-ss11-konary</li>
	<li><strong>Business Wire</strong>. http://www.businesswire.com/news/home/20150224005807/en/Vendor-audits-rise-28-software-remains-unused</li>
	<li><strong>Gartner, Inc.</strong> https://www.gartner.com/doc/2669415/surviving-adobe-software-license-audit</li>
	<li><strong>Windows Central.</strong> https://www.windowscentral.com/there-are-now-12-billion-office-users-60-million-office-365-commercial-customers</li>
	<li><strong>Adobe Systems.</strong> https://helpx.adobe.com/acrobat/system-requirements.html</li>
	<li><strong>SecureWorld.</strong> https://www.secureworld.io/industry-news/top-10-data-breaches-of-all-time</li>
</ol>`;
return (<div>
	<MetaTags>
	   <title>{metaTagscontent.title}</title>
	   <meta name="title" content={metaTagscontent.title} />
	   <meta name="description" content={metaTagscontent.desc} />
	   <meta property="og:title" content={metaTagscontent.title} />
	   <meta property="og:description" content={metaTagscontent.desc} />
   </MetaTags>
   <div dangerouslySetInnerHTML={{__html:viewcontent}}></div>
</div>);
}
export default SuiteCompare;