import React from 'react';
//import {ReactComponent as Qmark} from '../../assets/img/Group 470.svg';
import MetaTags from 'react-meta-tags';

const TslCompare = props => {
    const {t, linkObj} = props;
    return (
        <div className="compareContent">
            <MetaTags>
                <title>{t('metaTag.tslCompare.title')}</title>
                <meta name="title" content={t('metaTag.tslCompare.title')} />
                <meta name="description" content={t('metaTag.tslCompare.desc')} />
                <meta name="keywords" content={t('metaTag.tslCompare.keywords')} />
                <meta property="og:title" content={t('metaTag.tslCompare.title')} />
                <meta property="og:description" content={t('metaTag.tslCompare.desc')} />
            </MetaTags>
            <div className="customContainer">
                <div className="cRow padding-0 productCompareInfoWrap">
                    <div className="productCompareInfo mobilefullWidth">
                        <h2 className="font-weight-bold">{t('resources.tslCompare.block1.title')}</h2>
                        <p className="m-0">{t('resources.tslCompare.block1.t1')}</p>
                        <p className="m-0">{t('resources.tslCompare.block1.t2')}</p>
                    </div>
                </div>
                <div className="cRow padding-0 tslInfoContent text-size-16 text-center">
                    <div className="d-32 mobilefullWidth">
                        <img src={require(`../../assets/img/compare/remoteDesktop.png`)} className="img-fluid" alt="tslIcon" />
                        <h3 className="font-weight-bold">{t('resources.tslCompare.block1.infoBlock1.title')}</h3>
                        <p>{t('resources.tslCompare.block1.infoBlock1.t1')}</p>
                    </div>
                    <div className="d-32 mobilefullWidth">
                        <img src={require(`../../assets/img/compare/centerManage.png`)} className="img-fluid" alt="tslIcon" />
                        <h3 className="font-weight-bold">{t('resources.tslCompare.block1.infoBlock2.title')}</h3>
                        <p>{t('resources.tslCompare.block1.infoBlock2.t1')}</p>
                    </div>
                    <div className="d-32 mobilefullWidth">
                        <img src={require(`../../assets/img/compare/identicalFeature.png`)} className="img-fluid" alt="tslIcon" />
                        <h3 className="font-weight-bold">{t('resources.tslCompare.block1.infoBlock3.title')}</h3>
                        <p>{t('resources.tslCompare.block1.infoBlock3.t1')}</p>
                    </div>
                </div>
                <div className="productCompareInfo">
                    <h2>{t('resources.tslCompare.block2.title')}</h2>
                </div>
                <div className="tslTableWrap">
                    <table className="compareBasicTable tsl">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="font-weight-normal">{t('resources.tslCompare.block2.table.t1')}</th>
                                <th className="font-weight-normal">{t('resources.tslCompare.block2.table.t2')}</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td className="title">
                                    {t('resources.tslCompare.block2.table.t3')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t4')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t5')}
                                </td>
                            </tr>
                            <tr>
                                <td className="title">
                                    {t('resources.tslCompare.block2.table.t6')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t7')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t8')}
                                </td>
                            </tr>
                            <tr>
                                <td className="title">
                                    {t('resources.tslCompare.block2.table.t9')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t10')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t11')}
                                </td>
                            </tr>
                            <tr>
                                <td className="title">
                                    {t('resources.tslCompare.block2.table.t12')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t13')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t14')}
                                </td>
                            </tr>
                            <tr>
                                <td className="title">
                                    {t('resources.tslCompare.block2.table.t15')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t16')}
                                    <ul>
                                        <li>• {t('resources.tslCompare.block2.table.t17')}</li>
                                        <li>• {t('resources.tslCompare.block2.table.t18')}</li>
                                        <li>• {t('resources.tslCompare.block2.table.t19')} </li>
                                    </ul>
                                </td>
                                <td>
                                    {t('resources.tslCompare.block2.table.t20')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="productCompareInfo tsl">
                    <h2>{t('resources.tslCompare.block3.title')}</h2>
                    <p>{t('resources.tslCompare.block3.t1')}</p>
                </div>
                <div className="tslTableWrap">
                    <table className="compareBasicTable tsl table2">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <h3 className="font-weight-light">
                                        {t('resources.tslCompare.block3.table.t1')}
                                    </h3>
                                </th>
                                <th>
                                    <h3 className="font-weight-light">
                                        {t('resources.tslCompare.block3.table.t2')}
                                    </h3>
                                </th>
                                <th>
                                    <h3 className="font-weight-light">
                                        {t('resources.tslCompare.block3.table.t3')}
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="row-title">
                                    {t('resources.tslCompare.block3.table.t4')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t5')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t6')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t7')}
                                </td>
                            </tr>
                            <tr>
                                <td className="row-title">
                                    {t('resources.tslCompare.block3.table.t8')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t9')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t10')}
                                </td>
                                <td>
                                    <h3>{t('resources.tslCompare.block3.table.t11')}</h3>
                                    <p>{t('resources.tslCompare.block3.table.t12')}</p>
                                    <h3>{t('resources.tslCompare.block3.table.t13')}</h3>
                                    <p>{t('resources.tslCompare.block3.table.t14')}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="row-title">
                                    {t('resources.tslCompare.block3.table.t15')}<br/>{t('resources.tslCompare.block3.table.t15-1')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t16')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t17')}{t('resources.tslCompare.block3.table.t18')}
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t19')}
                                </td>
                            </tr>
                            
                            <tr>
                                <td className="row-title" rowSpan="2">
                                    {t('resources.tslCompare.block3.table.t20')}
                                </td>
                                <td className="d-td">
                                    <div className='d-tableflex'>
                                        <div className="font-weight-bold suite d-icon">
                                            <span className="text">{t('resources.tslCompare.block3.table.t21')}</span>
                                        </div>
                                        <div className='d-item'>
                                            <div>{t('resources.tslCompare.block3.table.t22')}</div>
                                            <div>{t('resources.tslCompare.block3.table.t23')}</div>
                                        </div>
                                        <a href={linkObj.buyLink.url('#suite_ts')} className="cBtn cBtn1 w_text text-center redBg-fire" target="_blank" rel="noopener noreferrer">
                                            {t('resources.tslCompare.block3.table.t24')}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t25')}
                                </td>
                                <td className="d-td">
                                    <div className='d-tableflex'>
                                        <div className="font-weight-bold suite d-icon">
                                            <span className="text">{t('resources.tslCompare.block3.table.t21')}</span>
                                        </div>
                                        <div className='d-item'>
                                            <div>{t('resources.tslCompare.block3.table.t26')}</div>
                                            <div>{t('resources.tslCompare.block3.table.t23')}</div>
                                        </div>
                                        <a href={linkObj.buyLink.url('#suite_ts')} className="cBtn cBtn1 w_text text-center redBg-fire" target="_blank" rel="noopener noreferrer">
                                            {t('resources.tslCompare.block3.table.t24')}
                                        </a>
                                    </div>
                                </td>
                                
                            </tr>
                            <tr>
                                {/* <td>
                                    {t('resources.tslCompare.block3.table.t20')}
                                </td> */}
                                <td className="d-td" style={{borderLeft:'1px solid #D6D6D6'}}>
                                    <div className='d-tableflex'>
                                        <div className="font-weight-bold convert d-icon">
                                            <span className="text">{t('resources.tslCompare.block3.table.t27')}</span>
                                        </div>
                                        <div className='d-item'>
                                            <div>{t('resources.tslCompare.block3.table.t28')}</div>
                                            <div>{t('resources.tslCompare.block3.table.t23')}</div>
                                        </div>
                                        <a href={linkObj.buyLink.url('#cvt_ts')} className="cBtn cBtn1 w_text text-center redBg-fire" target="_blank" rel="noopener noreferrer">
                                            {t('resources.tslCompare.block3.table.t24')}
                                        </a>
                                    </div>
                                </td>
                                <td className="d-td">
                                    <div className='d-tableflex'>
                                        <div className="font-weight-bold convert d-icon">
                                            <span className="text">{t('resources.tslCompare.block3.table.t27')}</span>
                                        </div>
                                        <div className='d-item'>
                                            <div>{t('resources.tslCompare.block3.table.t29')}</div>
                                            <div>{t('resources.tslCompare.block3.table.t30')}</div>
                                        </div>
                                        <a href={linkObj.buyLink.url('#cvt_ts')} className="cBtn cBtn1 w_text text-center redBg-fire" target="_blank" rel="noopener noreferrer">
                                            {t('resources.tslCompare.block3.table.t24')}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    {t('resources.tslCompare.block3.table.t25')}
                                </td>
                                
                            </tr>
                            {/* {
                                pageDataObj.blockList.map((e,i)=>{
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {e.td1}
                                            </td>
                                            <td className={e.td2.type}>
                                                <p>{e.td2.name}</p>
                                            </td>
                                            <td className={e.td3.type}>
                                                <p>{e.td3.name}</p>
                                            </td>
                                        </tr>
                                    )
                                })
                            } */}
                        </tbody>
                    </table>
                </div>
                <p className="compareNoteInfo">{t('resources.tslCompare.block3.table.t31')}</p>
                <div className="compareInfoBlock text-left tsl">
                    <div className="q-mark-title">
                        <h2 className="text">{t('resources.tslCompare.block4.title')}</h2>
                    </div> 
                    <ul className="text-size-16 tsl-ul">
                        <li>
                            <span className="label">• </span>
                            <span className="blockText">{t('resources.tslCompare.block4.t1')}</span>
                        </li>
                        <li>
                            <span className="label">• </span>
                            <span className="blockText">{t('resources.tslCompare.block4.t2')}</span>
                        </li>
                        <li>
                            <span className="label">• </span>
                            <span className="blockText">{t('resources.tslCompare.block4.t3')}</span>
                        </li>
                    </ul>
                 </div>
                 <div className="compareInfoBlock text-left tsl">
                    <div className="q-mark-title">
                        <h2 className="text">{t('resources.tslCompare.block5.title')}</h2>
                    </div>
                    <ul className="text-size-16">
                        <li>{t('resources.tslCompare.block5.t1')} </li>
                        <li>{t('resources.tslCompare.block5.t2')} </li>
                    </ul>
                 </div>

            </div>
        </div>
    )
}

export default TslCompare;