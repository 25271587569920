const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何下载旧版文电通?</title></head><body>  <h1>如何下载旧版文电通?</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p>Gaaiho文电通 系列: <a href="https://store.rightpdf.cn/download/archive.aspx" target="_blank" rel="noopener">Gaaiho 1.X/2.X/3.X/4.X/5.X</a></p>
<p>DocuCom文电通 系列: <a href="http://www.pdfwizard.com/chs/product/downgold.asp" target="_blank" rel="noopener">Docucom Gold 9.x</a>、<a href="http://www.pdfwizard.com/chs/product/downdrv.asp" target="_blank" rel="noopener">DocuCom Driver 8/9</a></p></body></html>`;   export default html;