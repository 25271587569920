import React, { useState,useContext, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuContext from './menuContext';
import arrow from '../../assets/img/menu-arrow.png';
// import {wb_loginout} from '../../api';

const DesktopMenu = props => {
    const [show,setShow] = useState(false);
    const {t,linkObj} = props;
    const { block ,dispatch } = useContext(MenuContext);
    const locationRoute = useLocation();
    const [currentPath,setCurrentPath] = useState(locationRoute.pathname);
    const [username,setUserName] = useState('');
	const Dispatch = useDispatch();
    useEffect(()=>{
        document.addEventListener('click', event => {
            var hideMenuWrap = document.getElementsByClassName('hideMenuWrap')[0];
            if(!hideMenuWrap){
                return;
            }
            if(!hideMenuWrap.contains(event.target)){
                setShow(false);
				
				Dispatch({
					type:'TOGGLE_DESKTOP_MENU'
				})

				dispatch({type: 'SELECT_BLOCK',title:null, bType:null})
                //showhideMenu({title:null,type:null});
            }
        });           
    },[Dispatch,dispatch]);
    
    function toggleMenu(){
        Dispatch({
            type:'TOGGLE_DESKTOP_MENU'
        })
    }
    let menulist = props.ListData.map((e,i) => 
        // <li key={i}
        // className={e.title === block.title && show?'active':''} 
        // onMouseEnter={() => {
        //     showhideMenu(e);
        // }} 
        // onMouseLeave={() => setShow(false)}>
		i === 0 || i === 1 || i === 2 ?
			<li key={i}
			className={e.title === block.title && show?'active':''} 
				onClick={(event) => {
				event.nativeEvent.stopImmediatePropagation();//阻止合成事件与最外层document上的事件冒泡
				if(e.title === block.title) {
					setShow(false);
					showhideMenu({title:null,type:null});
				} else {
					toggleMenu();
					setShow(true);
					showhideMenu(e);
				}
			}}>
				{/* <span>{e.title}<FontAwesomeIcon  icon="chevron-down" className="ml-3 fa-sm"/></span> */}
				<span>{e.title}<img src={arrow}  className="ml-3 fa-sm" alt=""/></span>
			</li>
		: (i=== 3 ? <li key={i}><a href={e.url} target="_blank" rel="noopener noreferrer">{e.title}</a></li> : <li key={i}><a href={e.url}>{e.title}</a></li>)
    )
    
    /*function onContac1UsClick(event) {
        event.nativeEvent.stopImmediatePropagation();//阻止合成事件与最外层document上的事件冒泡
        if(t('contactUs.name') === block.title) {
            setShow(false);
            showhideMenu({title:null,type:null});
        } else {
            toggleMenu();
            setShow(true);
            showhideMenu({title:t('contactUs.name'),type:'contact'});
        }
        
    }*/
    function showhideMenu(e){
        Dispatch({
            type:'TOGGLE_DESKTOP_MENU'
        })

        dispatch({type: 'SELECT_BLOCK',title:e.title, bType:e.type})
    }
    
    // function loginout(){
    //    wb_loginout({r_url:window.location.href+'/'}).then(function (response) {
    //      setUserName('');
    //    })
    // }
    useEffect(()=>{
        const checkLoginStatus = () => {
            let username = getCookie('s_ume');
            setUserName(username);
        }
        const getCookie = (name) => {
            var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
            arr=document.cookie.match(reg)
            if(arr){
                return unescape(arr[2]);
            }else{
                return "";
            }
        }
        const { pathname } = locationRoute;
        if(pathname !== currentPath){
            setShow(false);
			Dispatch({
				type:'TOGGLE_DESKTOP_MENU'
			})

			dispatch({type: 'SELECT_BLOCK',title:null, bType:null})
            //showhideMenu({title:null,type:null});
            Dispatch({
                type:'CLOSE_DESKTOP_MENU'
            })
        }
        setCurrentPath(pathname);
        checkLoginStatus();
    },[locationRoute,Dispatch,currentPath,dispatch]);
    return (
        
        <>
            <div className="headInfo darkGreenBg">
                <div className="headerContent">
                    <ul className="headInfoList">
                        <li><a href={linkObj.header.freetrail.url()} target="_blank" rel="noopener noreferrer">{t(linkObj.header.freetrail.name)}</a> </li>
                        <li><a href={linkObj.header.buy.url()} target="_blank" rel="noopener noreferrer">{t(linkObj.header.buy.name)}</a></li>
                        <li><a href={linkObj.header.upgrade.url()} target="_blank" rel="noopener noreferrer">{t(linkObj.header.upgrade.name)}</a></li>
                    </ul>
                    <div className="loginBlock">
                        <img src={props.HeadB}  alt="headb"/>
                        {
                            username!==''?(<>
                                <a style={{marginLeft:'10px',marginRight:'10px',color:'#ffffff'}} href={"https://store.rightpdf.com/account/my-order.aspx"}>{username}</a><a href={"http://store.rightpdf.com/account/logout.aspx?r_url="+window.location.href}>{t('loginout')}</a>
                            </>):(<a href={linkObj.header.signIn.url()}  target="_blank" className="ml-3 mr-2" rel="noopener noreferrer">
                                    { t(linkObj.header.signIn.name)}
                                </a>)
                        }
                    </div>
                </div>
            </div>
            {/* 主選單 */}
            <nav className="headMenu headerContent">
                <Link to={`/${props.currentLang.type}`} className="logoLink">
                    <img src={props.LogoPath} alt="right_logo"/>
                    <img src={props.VersionPath} style={{'marginLeft':'8px'}}  alt="right_logo"/>
                </Link>
                <ul className="menuList">
                    {/* <li>
                        <Link to={`/${props.currentLang.type}${linkObj.newFeature.path}`}>
                            {t(linkObj.newFeature.name)}
                        </Link>
                    </li> */}
                    {menulist}
                </ul>
                <span className="contractUs greenBg"
                    // onMouseEnter={e =>{ 
                    //     showhideMenu( {title:t(linkObj.contact.name),type:'contact'})
                    // }}
                    // onMouseLeave={() => setShow(false)}
                    >
					<Link to={`/${props.currentLang.type}${linkObj.contact.contactUs.path}`}>
						{t('contactUs.name')}
					</Link>
                </span>
            </nav>
            {/* <div className={'hideMenuWrap'+(show ?' active':'')} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
               {props.children}1
            </div>          */}
            <div className={'hideMenuWrap'+(show?' active':'')}  >
               {props.children}
            </div>
        </>
    )
}

export default React.memo(DesktopMenu);