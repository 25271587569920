import React,{useState,useEffect,useRef} from 'react';
import mixinWrapper from '../../hoc/mixinWrapper';
import DownloadPopup from './downloadPopup';
import VideoPopup from './videoPopup';
import {useDispatch} from 'react-redux';
const PopupWrap = props => {
    const [wHeight,setWHeight] = useState(0);
    const [scrollTop,setScrollTop] = useState(0);
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();
    
    const getPopupPosition = () => {
        setWHeight(window.document.body.offsetHeight);
        setScrollTop(document.documentElement.scrollTop);
    }
    
    const renderPopup = popupType => {
        switch(popupType){
            case 'download':
                return <DownloadPopup scrollTop={scrollTop}  {...props} />
            case 'video':
                return <VideoPopup scrollTop={scrollTop}  {...props} />
            default:
            return null
        }
    }
    useEffect(()=>{
		const handleClickOutside = event => {
			if(wrapperRef.current === event.target){
				const closePopup = () => {
					dispatch({
						type:'CLOSE_POPUP'
					})
				}
				closePopup();
			}
		}
        if(props.popUp.show){
            getPopupPosition();
            window.addEventListener('resize',getPopupPosition);
            document.addEventListener("mousedown", handleClickOutside);
        }
        return ()=>{
            window.removeEventListener('resize',getPopupPosition);
            document.removeEventListener("mousedown", handleClickOutside);
        }
    },[wHeight,scrollTop,props.popUp.show,wrapperRef,dispatch]);
    return (
        <div style={{'height':wHeight+'px'}} className={'popupWrap '+(props.popUp.show?'active':'')} ref={wrapperRef}>
            {
                renderPopup(props.popUp.content)
            }
        </div>
    )
}

export default mixinWrapper(PopupWrap);