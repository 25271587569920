const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>如何在线/离线激活 文电通PDF macOS?</title></head><body>  <h1>如何在线/离线激活 文电通PDF macOS?</h1>  <h2><span><strong>在线激活：</strong></span></h2>
<div>
<span class="wysiwyg-color-blue130 "><span class="wysiwyg-color-blue130 ">1. 安装完成之后，执行 <strong>Right PDF </strong>会弹出关于激活对话框。或者</span></span>，点选左上角「Right PDF」&gt; 「授权信息」。</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510021.png" alt="510021.png"></div>
<div></div>
<div><span class="wysiwyg-color-blue130"><font color="#2f3941">2</font>. 选择「<strong>在线</strong>」</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510022.png" alt="510022.png"></div>
<div> </div>
<div><span class="wysiwyg-color-blue130">3. 输入<span class=" wysiwyg-color-blue130"><strong>序列</strong></span><span class=" wysiwyg-color-blue130"><strong>号</strong></span></span></div>
<div><span class="wysiwyg-color-blue130">4. 激活完成</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510023.png" alt="510023.png"></div>
<div> </div>
<h2><span class=""><strong>离线激活：</strong></span></h2>
<div>
<span class="wysiwyg-color-blue130 ">1. 安装完成之后，执行 <strong>Right PDF </strong>会弹出关于激活对话框。或者</span>，点选左上角「Right PDF」&gt; 「授权信息」。</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510024.png" alt="510024.png"></div>
<div></div>
<div>2. <span class="wysiwyg-color-blue130">选择「<strong>离线</strong>」，输入<span class=" wysiwyg-color-blue130"><strong>许可证代号</strong></span>并生成「<strong>Activate.html</strong>」</span>
</div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510025.png" alt="510025.png"></div>
<div> </div>
<div> <span class="wysiwyg-color-blue130">3. 用随身硬盘等存储设备将导出的文件移到在联网环境的电脑</span>
</div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130">. 使用浏览器打开「Activate.html」，并点击「上传」</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510026.png" alt="510026.png"></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510027.png" alt=""></div>
<div> </div>
<div> </div>
<div> </div>
<div><span class="wysiwyg-color-blue130">6. 在「授权文件」上点击右键并存儲</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510028.png" alt=""></div>
<div> </div>
<div> </div>
<div><span class="wysiwyg-color-blue130">7. 将.zna文件移回未联网的电脑</span></div>
<div><span class="wysiwyg-color-blue130"><span class="wysiwyg-color-red"><strong>注意</strong></span>：离线激活过程中，请勿中途结束文电通程序，以免导致激活失败。若中途关闭了程序，请重新执行离线激活程序，并汇入新的.zna文件。</span></div>
<div><span class="wysiwyg-color-blue130"> </span></div>
<div><span class="wysiwyg-color-blue130">8. 重复步骤1到2，选择「导入数据」，然后再选取刚才移动过来的.zna文件</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/510029.png" alt="510029.png"></div>
<div> </div>
<div><span class="wysiwyg-color-blue130">9. 激活完成</span></div>
<div><img src="https://store.rightpdf.com/images/rtsupport/5100210.png" alt="5100210.png"></div></body></html>`;   export default html;