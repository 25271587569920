const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>激活授权码或序号时，出现错误码 115</title></head><body>  <h1>激活授权码或序号时，出现错误码 115</h1>  <p><strong><img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (13).png" alt="mceclip0 (13).png" width="533" height="381"></strong></p>
<h2><strong>问题描述：</strong></h2>
<p>当激活授权码或序号时，激活失败，出现错误码 115。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>激活时，出现错误码「115」代表您所输入的 授权码 或 序号 不符合您目前安装的文电通产品版本（例如：尝试以「文电通3专业版」序号启动「文电通5专业版」软件）。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决方法：</strong></span></h2>
<p><span class="wysiwyg-color-blue130">此时，您可以选择 (1) 下载安装符合您购买之序号的文电通产品 或 (2) 购买升级到最新的文电通版本。</span></p>
<p><span class="wysiwyg-color-blue130">(1) 下载安装符合您购买之序号的文电通产品：</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">卸载您目前文电通软件；</span></li>
<li><span class="wysiwyg-color-blue130">下载符合您当初购买之序号的文电通版本（<a href="https://store.rightpdf.cn/download/archive.aspx" target="_self" rel="undefined">点此下载较旧版文电通产品</a>）；</span></li>
<li><span class="wysiwyg-color-blue130">安装正确版本之文电通产品（文电通4及更早的产品，有操作系统位之分，请<a href="https://support.microsoft.com/zh-cn/windows/%E6%88%91%E8%BF%90%E8%A1%8C%E7%9A%84%E6%98%AF%E5%93%AA%E4%B8%AA-windows-%E6%93%8D%E4%BD%9C%E7%B3%BB%E7%BB%9F%E7%89%88%E6%9C%AC-628bec99-476a-2c13-5296-9dd081cdd808" target="_self" rel="undefined">点我了解如何查看Windows操作系统位数</a>！）</span></li>
<li><span class="wysiwyg-color-blue130">激活（<a href="https://support.rightpdf.com/hc/zh-cn/articles/4462193952793-%E5%A6%82%E4%BD%95%E5%9C%A8%E7%BA%BF-%E7%A6%BB%E7%BA%BF%E6%BF%80%E6%B4%BB-%E6%96%87%E7%94%B5%E9%80%9A6-Right-PDF-6-" target="_self" rel="undefined">如何在线/离线激活 文电通6 (Right PDF 6)？</a>、<a href="https://support.rightpdf.com/hc/zh-cn/articles/4462412310425-%E5%A6%82%E4%BD%95%E5%9C%A8%E7%BA%BF-%E7%A6%BB%E7%BA%BF%E5%8F%8D%E6%BF%80%E6%B4%BB-%E6%96%87%E7%94%B5%E9%80%9A6-Right-PDF-6-" target="_self" rel="undefined">如何在线/离线反激活 文电通6 (Right PDF 6)? </a> ）</span></li>
</ol>
<p><span class="wysiwyg-color-blue130">(2) 购买升级到最新的文电通版本：</span></p>
<ol>
<li><span class="wysiwyg-color-blue130">请洽<a href="https://store.rightpdf.cn/buy/upgrade-add-user.aspx" target="_self" rel="undefined">升级与加购页面</a>购买最新版文电通PDF！</span></li>
</ol>
<p> </p></body></html>`;   export default html;