const html =  `<!DOCTYPE html><html lang="zh"><head>  <meta charset="UTF-8">  <meta name="viewport" content="width=device-width, initial-scale=1.0">  <meta http-equiv="X-UA-Compatible" content="ie=edge">  <title>Gaaiho文电通PDF 5 激活失败/无法激活 - Windows 10</title></head><body>  <h1>Gaaiho文电通PDF 5 激活失败/无法激活 - Windows 10</h1>  <table style="background-color: #f0f3f4; border: 1px solid #7b7d7d; height: 35px; width: 100%;">
<tbody>
<tr style="height: 85px;">
<td style="height: 35px; width: 100%;">
<p><span class=" wysiwyg-color-red"><strong>重要提醒：</strong></span><span class="wysiwyg-color-black">因应重塑品牌政策，第6版起文电通的英文品牌已从“Gaaiho”更名为“Right PDF”，</span></p>
<p><span class="wysiwyg-color-black">中文则维持“文电通”不变。请记得更新</span>我们<span class="wysiwyg-color-black">的网址与email地址。</span></p>
</td>
</tr>
</tbody>
</table>
<p> </p>
<p><strong><img src="https://store.rightpdf.com/images/rtsupport/crash.png" alt="crash.png"></strong></p>
<h2><strong>问题描述：</strong></h2>
<p>当Gaaiho PDF suite 5 的用户一尝试激活授权码或账号时，软件无反应 或 即出现 "Gaaiho Doc 已经停止运作" 并Crash。</p>
<p> </p>
<h2><strong>原因：</strong></h2>
<p>与Windows 10 "版本：1809" 兼容性问题。如果您的计算机系统为Windows 10 版本：1809 或 最近系统自行更新至版本：1809，则可能会遇到此问题。</p>
<p> </p>
<h2><span class="wysiwyg-color-blue130"><strong>解决办法：</strong></span></h2>
<ol>
<ol>
<li>
<span class="wysiwyg-color-blue130">先执行 "services.msc" &gt;「停止」Gaaiho License Service 服务：<img src="https://store.rightpdf.com/images/rtsupport/mceclip0 (14).png"></span><br><br><span class="wysiwyg-color-blue130"><img src="https://support.rightpdf.com/hc/article_attachments/360013712092/mceclip0.png"></span>
</li>
<li><span class="wysiwyg-color-blue130">将附件GZNSL9UI.dll文件替换既有GZNSL9UI.dll文件，放到bin下面(位置：C:\\Program Files (x86)\\ZEON\\Gaaiho\\Gaaiho PDF Suite\\bin)：<img src="https://store.rightpdf.com/images/rtsupport/dll.png" alt="dll.png"></span></li>
<li><span class="wysiwyg-color-blue130">重新启动服务 Gaaiho License Service。</span></li>
</ol>
</ol>
<p><span class="wysiwyg-color-blue130"> 这样就可以继续您的激活程序了！</span></p>
<p><strong> </strong><strong>如果按照上面步骤仍无法解决您的问题，请联系</strong> <strong><a href="mailto:support@rightpdf.com" target="_self" rel="undefined">support@rightpdf.com</a></strong><strong> </strong><strong>，将由专人为您服务！</strong></p>
<p>(此问题已在Gaaiho文电通PDF 5.2及以后版本中修正，请直接<a href="https://support.rightpdf.com/hc/zh-cn/articles/220079828-%E5%A6%82%E4%BD%95%E5%B0%86%E6%96%87%E7%94%B5%E9%80%9A%E6%9B%B4%E6%96%B0%E5%88%B0%E6%9C%80%E6%96%B0%E7%89%88%E6%9C%AC-" target="_blank" rel="noopener">更新至最新版文电通</a>)。</p>
<p> </p></body></html>`;   export default html;