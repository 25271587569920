import React from 'react';
//import {NavLink} from 'react-router-dom';
import MetaTags from 'react-meta-tags';

const BecomePartner = props => {
    const {t} = props;

    return (
        <div className="partnerContent">
            <MetaTags>
                <title>{t('metaTag.becomePartner.title')}</title>
                <meta name="title" content={t('metaTag.becomePartner.title')} />
                <meta name="description" content={t('metaTag.becomePartner.desc')} />
                <meta name="keywords" content={t('metaTag.becomePartner.keywords')} />
                <meta property="og:title" content={t('metaTag.becomePartner.title')} />
                <meta property="og:description" content={t('metaTag.becomePartner.desc')} />
            </MetaTags>
            <div className="partnerLogo d-33 align-middle">
                <img src={require(`../../assets/img/about/logo_nesgio.png`)} className="img-fluid" alt="" />
            </div>
            <div className="partnerBlock">
            <div className="title">{t('partner.title')}</div>
                <div className="contents">
                    <div className="contentItem">
                        <div className="label">{t('partner.label1')}</div>
                        <span className="text">{t('partner.text1')}</span>
                    </div>
                    <div className="contentItem">
                        <div className="label">{t('partner.label2')}</div>
                        <span className="text">{t('partner.text2')}</span>
                    </div>
                    <div className="contentItem">
                        <div className="label">{t('partner.label3')}</div>
                        <span className="text">{t('partner.text3')}</span>
                    </div>
                    <div className="contentItem">
                        <div className="label">{t('partner.label4')}</div>
                        <span className="text"><a href='mailto:sales@nesgio.com'>{t('partner.text4')}</a></span>
                    </div>
                    <div className="contentItem">
                        <div className="label">{t('partner.label5')}</div>
                        <span className="text"><a href='mailto:help@nesgio.com'>{t('partner.text5')}</a></span>
                    </div>
                    <div className="contentItem">
                        <div className="label">{t('partner.label6')}</div>
                        <div className="text">{t('partner.text6')}</div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default BecomePartner;